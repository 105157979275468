import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';

@Injectable()
export class faqsService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
 
  private UserManagementURL: string;

  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
   
    this.UserManagementURL = environment.apiURL.UserManagement;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  GetCIS_FAQs(userid:any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('Userid', 1);

    let response = this._http.get<ProcessResponse<any>>(this.UserManagementURL + 'GetCIS_FAQs',
      { headers: this.headers, params: Params });
    return response;
  }

}