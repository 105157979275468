import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { NavigationStart, Router } from '../../node_modules/@angular/router';
import { AuthenticationService } from './core/auth/authentication.service';
import { AppSharedService, isNullOrUndefined } from './shared/app.sharedservice';
import { authConfig } from './sso.config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'P2W-UI';

  private initialized = false;
  IsInternalUser: boolean = true;

  constructor(public _appSharedService: AppSharedService, public _authService: AuthenticationService,
    public translate: TranslateService, public oauthService: OAuthService, public router: Router) {
      // this.router.events.subscribe(event => {
      //   if (event instanceof NavigationStart) {
      //     if (!this.initialized) {
      //       // First navigation after application initialization
      //       this.initialized = true;
      //     } else {
      //       // Subsequent navigations
      //       this.handleNavigationStart(event);
      //     }
      //   }
      // });

    if (this.IsInternalUser == true) {

      this.configureSingleSignOn();

    }

  }

  ///added for controlling page refresh to dashboard/////////////

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: Event) {
  //   // Prevent default browser refresh behavior
  //   event.preventDefault();
  //   // Refresh the current route
  //   this.router.navigateByUrl(this.router.url);
  // }



  ngOnInit() { }


  configureSingleSignOn() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .then((hasReceivedTokens: any) => {
        // this would have stored all the tokens needed
        if (hasReceivedTokens) {

          // get claims on receiving the token successfully
          let claims: any = this.oauthService.getIdentityClaims();
        //  console.log('token >> ' + JSON.stringify(claims));

          if (!isNullOrUndefined(claims)) {

            this._appSharedService.IsAuthUser = true;
            this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

            this._appSharedService.setUserInfo(claims);

          this.router.navigate(['/app/home']);

            // Get the API token

          //  if(!isNullOrUndefined(this._appSharedService.UserInfo)){
              this._authService.getAPIToken(claims.email).then(result => {

                if (result) {
                  this.router.navigate(['app/home']);
                  // window.location.href = window.location.href;
                  // alert('Successfully switched user, Please refresh to proceed');
                }
                else {
               //   alert('Sorry, unable to complete the API token generation.');
                  // show as invalid mailid
                  this.router.navigate(['app']);
                }
  
              });
          //  }
           
          }

          // carry on with your app
          return Promise.resolve();
        }
        else {
          // may want to check if you were previously authenticated
          if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            return Promise.resolve();
          } else {
            // to safe guard this from progressing through the calling promise,
            // resolve it when it directed to the sign up page
            return new Promise(resolve => {
              this.oauthService.initLoginFlow();
              // example if you are using explicit flow
              window.addEventListener('unload', () => {
                resolve(true);
              });
            });
          }
        }
      });


  }

  logout() {
    this.oauthService.logOut();
  }
 

}

