<ng-container>
    <div class="h-100">
        <nav aria-label="breadcrumb" class="breadcrumbSection">
            <div class="d-flex w-100 justify-content-between">
                <!-- <h1 class="sr-only">Deal Parameters</h1>
                <h2 class="sr-only">Deal Parameters</h2> -->
                <ng-container>
                    <ng-container *ngIf="ModuleName == 'ModelBaseline'">

                    </ng-container>
                    <ul class="breadcrumb m-0 flex-fill">
                        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page"><a
                                routerLink="/app/create-deal/{{DealHeaderId}}">Deal Configuration</a>
                        </li>
                        <ng-container *ngIf="ModuleName != 'ModelConfig'">
                            <li class="breadcrumb-item" aria-current="page"><a
                                    routerLink="/app/model-config/{{DealHeaderId}}">Model Configuration</a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="ModuleName != 'ModelConfig'">
                            <li class="breadcrumb-item active" aria-current="page">Model Baselining
                            </li>
                        </ng-container>

                        <ng-container *ngIf="ModuleName != 'ModelConfig'">
                            <li class="breadcrumb-item" aria-current="page">
                                <a [routerLink]="['/app/lot-solution-modelling/DeliveryModel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                    [state]="{ selectedData: selecteddata }">Lot Solution Effort Modelling</a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="ModuleName != 'ModelConfig'">
                            <li class="breadcrumb-item" aria-current="page">
                                <a [routerLink]="['/app/lot-solution-pricing-model/pricingmodel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                    [state]="{ selectedData: selecteddata }">Lot Solution Price Modelling</a>

                            </li>
                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ModelConfig'">
                            <li class="breadcrumb-item active" aria-current="page">Model Configuration</li>


                        </ng-container>

                        <ng-container *ngIf="ModuleName == 'ModelConfig'">
                            <li class="breadcrumb-item" aria-current="page"><a
                                    routerLink="/app/model-config/ModelBaseline/{{DealHeaderId}}">Model Baselining </a>
                            </li>

                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ModelConfig'">

                            <li class="breadcrumb-item" aria-current="page">
                                <a [routerLink]="['/app/lot-solution-modelling/DeliveryModel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                    [state]="{ selectedData: selecteddata }">Lot Solution Effort Modelling</a>
                            </li>

                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ModelConfig'">

                            <li class="breadcrumb-item" aria-current="page">
                                <a [routerLink]="['/app/lot-solution-pricing-model/pricingmodel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                    [state]="{ selectedData: selecteddata }">Lot Solution Price Modelling</a>
                            </li>

                        </ng-container>


                    </ul>

                    <ul class="flex-fill m-0 navbar-nav p-3 breadcrumb" *ngIf="MainModule =='AM'">
                        <li>
                            <span class="progress headerProgress ms-auto">
                                <span *ngIf="dealProgressMeter!=0" class="progress-bar bg-warning headerProgressBar"
                                    [ngStyle]="{'width':dealProgressMeter+'%'}">{{dealProgressMeter}}%</span>
                                <span *ngIf="dealProgressMeter==0" class="progress-bar bg-secondary headerProgressBar"
                                    style="width: 100%;text-align:left;">0%</span>
                            </span>
                        </li>
                    </ul>

                </ng-container>
            </div>
        </nav>

        <div class="contentScroll p-3 simpleCard myDealCard">
            <div class="card h-100 m-0">
                <div class="p-1">
                    <div class="row mx-2 deal-details planInfoWraper filters">
                        <div class="col-2">
                            <label for="clientname" class="fw-bold">Client Name:&nbsp;&nbsp;
                            </label>{{selectedDeal.Client}}
                        </div>
                        <div class="col-2">
                            <label for="dealname" class="fw-bold">Deal Name:&nbsp;&nbsp;
                            </label>{{selectedDeal.DealName}}
                        </div>
                        <div class="col-2">
                            <label for="thorid" class="fw-bold">Thor Opportunity
                                ID:&nbsp;&nbsp;</label>{{selectedDeal.ThorId}}
                        </div>

                        <div class="col-2">
                            <label for="contracttype" class="fw-bold">Contract Type:&nbsp;&nbsp;
                            </label>{{selectedDeal.ContractTypeDesc}}
                        </div>
                        <div class="col-2">
                            <label for="contracttype" class="fw-bold">Model ID:&nbsp;&nbsp;
                            </label>{{selectedDeal.ModelID}}
                        </div>

                        <div class="col-2">
                            <label for="dealterm" class="fw-bold">Deal Term (in Years):&nbsp;&nbsp;
                            </label>{{selectedDeal.DealTerm}}
                        </div>

                        <div class="col-2">
                            <label for="dealterm" class="fw-bold">Model Configuration Approach:&nbsp;&nbsp;
                            </label>{{selectedDeal.AmApproachDesc}}
                        </div>

                        <div class="col-2">
                            <label for="dealcountry" class="fw-bold">Deal Contracting Country:&nbsp;&nbsp;
                            </label><span *ngIf="selectedDeal.CountryDesc">{{selectedDeal.CountryDesc}}</span><span
                                *ngIf="!selectedDeal.CountryDesc">{{selectedDeal.DealContractCountrydesc}}</span>
                        </div>
                        <div class="col-2">
                            <label for="currency" class="fw-bold">Currency:&nbsp;&nbsp;
                            </label>{{selectedDeal.Currency}}
                        </div>
                        <div class="col-2">
                            <label for="rateoption" class="fw-bold">Rate Modelling Option:&nbsp;&nbsp;
                            </label><span
                                *ngIf="selectedDeal.RateModellingOptionDesc">{{selectedDeal.RateModellingOptionDesc}}</span><span
                                *ngIf="!selectedDeal.RateModellingOptionDesc">{{selectedDeal.RateModellingDesc}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <h3>
                        <ng-container *ngIf="ModuleName == 'ModelConfig'">
                            <span>Model Configuration Profile</span>
                            <a href="javascript:void(0);" (click)="HelpIconClick()" class="HeaderHelpIconTooltip">
                                <i class="fa fa-question-circle fa-ques fa-md" data-toggle="tooltip"></i>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ModelBaseline'">
                            <span>Model Baselining</span>
                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ServiceProfile'">
                            <span> Model Baselining | Service Profile</span>
                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ClientProfile'">
                            <span> Model Baselining | Client Resource Profile</span>
                        </ng-container>

                        <ng-container *ngIf="ModuleName == 'AnticipatedFTE'">
                            <span> Model Baselining | Anticipated FTE Growth</span>
                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'DeliveryModel'">
                            <span> Model Baselining | Delivery Location Modelling</span>
                        </ng-container>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <ng-container
                        *ngIf="(ModuleName == 'ModelConfig' && ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0 && ModelConfigData != null && ModelConfigData != undefined && this.ModelConfigData.DealHeaderId > 0)">
                        <customform #ModelConfigForm [FormTemplate]="ModelConfigurationAM" [FormData]="ModelConfigData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [cellLock]="setCellLock"
                            [parent]="this">
                        </customform>
                    </ng-container>
                    <ng-container
                        *ngIf="(ModuleName == 'ModelConfig' && AdditionalAMTemplate != null && AdditionalAMTemplate != undefined && AdditionalAMTemplate.length > 0 && ModelConfigData != null && ModelConfigData != undefined && this.ModelConfigData.DealHeaderId > 0)">
                        <customform #ModelConfigForm [FormTemplate]="AdditionalAMTemplate" [FormData]="ModelConfigData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [cellLock]="setCellLock"
                            [parent]="this">
                        </customform>
                    </ng-container>
                    <ng-container
                        *ngIf="(ModuleName == 'ModelConfig' && Hiddenform == true && EnterAddAMTemplate != null && EnterAddAMTemplate != undefined && EnterAddAMTemplate.length > 0 && ModelConfigData != null && ModelConfigData != undefined && this.ModelConfigData.DealHeaderId > 0)">
                        <customform #ModelConfigForm [FormTemplate]="EnterAddAMTemplate" [FormData]="ModelConfigData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [cellLock]="setCellLock"
                            [parent]="this">
                        </customform>
                    </ng-container>

                    <ng-container
                        *ngIf="ModuleName == 'ModelConfig' && Metricsapproach == true && ItMetricsButton != null && ItMetricsButton != undefined && ItMetricsButton.length > 0">

                        <ng-container *ngFor="let actionBar of ItMetricsButton  | filterBy: {IsApplicable: true,HasPermission :true}
                                  | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                            <div class="col-auto px-2 ml-auto mb-3">
                                <button type="button" [ngClass]="actionBar.CustomClass" (click)="btnClick(actionBar)"
                                    [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
                                    translate
                                    }}
                                </button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="(ModuleName == 'ModelConfig' && ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0  && ModelConfigData != null && ModelConfigData != undefined && this.ModelConfigData.DealHeaderId > 0)">
                        <customform #ModelConfig_TowerSplit_Form
                            [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'ModelConfigurationTowerAM'})"
                            [FormData]="ModelConfigData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                        </customform>
                    </ng-container>

                    <!-- <ng-container *ngIf="ModuleName == 'ModelConfig' && showGrid == true">
                        <div>
                            <div class="gridContainer mb-3 mt-3">
                                <div class="table-responsive">
                                    <table class="table table-hover table-bordered tblGrid fixed">
                                        <tbody>
                                            <tr>
                                                <td>Tower Name <span class="required"> *</span></td>
                                                <td><input type="text" class="w-100 form-control form-control-sm"
                                                        name="Tname" id="Tower" placeholder="Tower Name"
                                                        [(ngModel)]="TowerName.value"
                                                        (change)="onGridChange(TowerName)" /></td>
                                            </tr>
                                            <tr>
                                                <td>Sub Tower Name <span class="required"> *</span></td>
                                                <td><input type="text" class="w-100 form-control form-control-sm"
                                                        placeholder="Sub Tower Name" [(ngModel)]="SubTowerName.value"
                                                        (change)="onGridChange(SubTowerName)" /></td>
                                            </tr>
                                            <tr>
                                                <td>Lot Name <span class="required"> *</span></td>
                                                <td><input type="text" class="w-100 form-control form-control-sm"
                                                        placeholder="Lot Name" [(ngModel)]="LotName.value"
                                                        (change)="onGridChange(LotName)" /></td>
                                            </tr>
                                            <ng-container *ngIf="showResourceLotDistribution">
                                                <tr>
                                                    <td> Resource Lot Distribution % <span class="required">
                                                            *</span></td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            [(ngModel)]="ResourceLotDistributionPer.value"
                                                            (change)="onGridChange(ResourceLotDistributionPer)"
                                                            placeholder="Resource Lot Distribution %" /></td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showLotResourceCount">
                                                <tr>
                                                    <td>Lot Resource Count <span class="required"> *</span></td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            placeholder="0" [(ngModel)]="LotResourceCount.value"
                                                            (change)="onGridChange(LotResourceCount)" [disabled]="isInputDisabled"/></td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showCurrentClientResource">
                                                <tr>
                                                    <td>Current Client Resource Countries Count <span class="required">
                                                            *</span></td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            [(ngModel)]="CurrentClientResourceCountriesCount.value"
                                                            (change)="onGridChange(CurrentClientResourceCountriesCount)"
                                                            placeholder="0" /></td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showTicketLotDistribution">
                                                <tr>
                                                    <td> Ticket Lot Distribution % <span class="required">
                                                            *</span></td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            [(ngModel)]="TicketsLotDistributionPer.value"
                                                            (change)="onGridChange(TicketsLotDistributionPer)"
                                                            placeholder="Ticket Lot Distribution %" /></td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showLotTicketsCount">
                                                <tr>
                                                    <td><span *ngIf="showLotTicketsDefaultTitle">Lot Tickets Count
                                                        </span><span
                                                            *ngIf="showLotsCountMonthly && !showLotTicketsDefaultTitle">Lot
                                                            Tickets Monthly Count </span>
                                                        <span
                                                            *ngIf="!showLotsCountMonthly && !showLotTicketsDefaultTitle">Lot
                                                            Tickets Yearly Count </span><span class="required">
                                                            *</span>
                                                    </td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            [(ngModel)]="LotTicketsCount.value"
                                                            (change)="onGridChange(LotTicketsCount)"
                                                            placeholder="Lots Ticket Count" [disabled]="isInputDisabled"/></td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showUserLotDistribution">
                                                <tr>
                                                    <td> Users Lot Distribution % <span class="required">
                                                            *</span></td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            [(ngModel)]="UsersLotDistributionPer.value"
                                                            (change)="onGridChange(UsersLotDistributionPer)"
                                                            placeholder="User Lot Distribution %" /></td>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showLotUsersCount">
                                                <tr>
                                                    <td> Lot Users Count <span class="required">
                                                            *</span></td>
                                                    <td><input type="number" class="w-100 form-control form-control-sm"
                                                            [(ngModel)]="LotUsersCount.value"
                                                            (change)="onGridChange(LotUsersCount)"
                                                            placeholder="Lot Users Count" [disabled]="isInputDisabled"/></td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->

                    <ng-container
                        *ngIf="ModuleName == 'ModelConfig' && showGrid == true && TowerSplitData != null && TowerSplitData != undefined && TowerSplitData.Tot_Tower != null && TowerSplitData.Tot_Tower != undefined && TowerSplitData.Tot_Tower.length > 0">
                        <div>
                            <div class="gridContainer mb-3">
                                <div class="table-responsive forzenGrid">
                                    <table class="table table-hover table-bordered tblGrid fixed freezLeft1 withSelect">
                                        <tbody>
                                            <tr>
                                                <th style="background-color: #e9ecef !important;color: #000000 !important;"
                                                    width="300">Tower Name <span class="required">*</span></th>
                                                <ng-container
                                                    *ngFor="let towerSplit of TowerSplitData.Tot_Tower ; let i = index">

                                                    <td scope="col" [attr.colspan]="towerSplit.TowerColSpan"
                                                        class="text-center">
                                                        <input type="text"
                                                            [(ngModel)]="towerSplit[towerSplit.TowerFieldName]"
                                                            [id]="fieldRandomId+'-'+towerSplit.TowerFieldName"
                                                            [value]="towerSplit[towerSplit.TowerFieldName]"
                                                            [ngClass]="towerSplit[towerSplit.TowerFieldName] != null && towerSplit[towerSplit.TowerFieldName] != undefined && towerSplit[towerSplit.TowerFieldName] != '' 
                                                    ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                            placeholder="Tower Name"
                                                            (change)="onTowerSplitChange(towerSplit,'Tower')"
                                                            [disabled]="towerSplit.towerdisable">
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th
                                                    style="background-color: #e9ecef !important;color: #000000 !important;">
                                                    Sub Tower Name<span class="required">*</span></th>
                                                <ng-container
                                                    *ngFor="let sub_towerSplit of TowerSplitData.Tot_Sub_Tower; let i = index">
                                                    <td scope="col" [attr.colspan]="sub_towerSplit.SubTowerColSpan"
                                                        class="text-center">
                                                        <input type="text"
                                                            [(ngModel)]="sub_towerSplit[sub_towerSplit.SubTowerFieldName]"
                                                            [id]="fieldRandomId+'-'+sub_towerSplit.SubTowerFieldName"
                                                            [value]="sub_towerSplit[sub_towerSplit.SubTowerFieldName]"
                                                            [ngClass]="sub_towerSplit[sub_towerSplit.SubTowerFieldName] != null && sub_towerSplit[sub_towerSplit.SubTowerFieldName] != undefined && sub_towerSplit[sub_towerSplit.SubTowerFieldName] != '' 
                                                    ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                            (change)="onTowerSplitChange(sub_towerSplit,'SubTower')"
                                                            placeholder="Sub Tower Name"
                                                            [disabled]="sub_towerSplit.subtowerdisable">
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th
                                                    style="background-color: #e9ecef !important;color: #000000 !important;">
                                                    Lot Name<span class="required">*</span></th>

                                                <ng-container
                                                    *ngFor="let lots_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                    <td class="text-center">
                                                        <input type="text"
                                                            [(ngModel)]="lots_towerSplit[lots_towerSplit.LotsFieldName]"
                                                            [id]="fieldRandomId+'-'+lots_towerSplit.LotsFieldName"
                                                            [value]="lots_towerSplit[lots_towerSplit.LotsFieldName]"
                                                            [ngClass]="lots_towerSplit[lots_towerSplit.LotsFieldName] != null && lots_towerSplit[lots_towerSplit.LotsFieldName] != undefined && lots_towerSplit[lots_towerSplit.LotsFieldName] != '' 
                                                    ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                            (change)="onTowerSplitChange(lots_towerSplit,'Lot')"
                                                            placeholder="Lot Name"
                                                            [disabled]="lots_towerSplit.itmetriclotnamedisable">
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <ng-container *ngIf="showResourceLotDistribution">
                                                <tr>
                                                    <th
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Resource Lot Distribution %<span class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let Resource_Lots_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="Resource_Lots_towerSplit[Resource_Lots_towerSplit.ResourceLotFieldName]"
                                                                [id]="fieldRandomId+'-'+Resource_Lots_towerSplit.ResourceLotFieldName"
                                                                [value]="Resource_Lots_towerSplit[Resource_Lots_towerSplit.ResourceLotFieldName]"
                                                                [ngClass]="Resource_Lots_towerSplit[Resource_Lots_towerSplit.ResourceLotFieldName] != null && Resource_Lots_towerSplit[Resource_Lots_towerSplit.ResourceLotFieldName] != undefined && Resource_Lots_towerSplit[Resource_Lots_towerSplit.ResourceLotFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(Resource_Lots_towerSplit,'ResourceLotDistribution')"
                                                                placeholder="Resource Lot Distribution %"
                                                                [disabled]="Resource_Lots_towerSplit.lotdisable">
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                            </ng-container>
                                            <ng-container *ngIf="showLotResourceCount">

                                                <tr>
                                                    <th
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Lot Resource Count<span class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let LotResource_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="LotResource_towerSplit[LotResource_towerSplit.LotResourceFieldName]"
                                                                [id]="fieldRandomId+'-'+LotResource_towerSplit.LotResourceFieldName"
                                                                [value]="LotResource_towerSplit[LotResource_towerSplit.LotResourceFieldName]"
                                                                [ngClass]="LotResource_towerSplit[LotResource_towerSplit.LotResourceFieldName] != null && LotResource_towerSplit[LotResource_towerSplit.LotResourceFieldName] != undefined && LotResource_towerSplit[LotResource_towerSplit.LotResourceFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(LotResource_towerSplit,'LotResource')"
                                                                placeholder="Lot Resource Count">
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                            </ng-container>
                                            <ng-container *ngIf="showCurrentClientResource">

                                                <tr>
                                                    <th
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Current Client Resource Countries Count<span
                                                            class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let ClientResource_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="ClientResource_towerSplit[ClientResource_towerSplit.ClientResourceFieldName]"
                                                                [id]="fieldRandomId+'-'+ClientResource_towerSplit.ClientResourceFieldName"
                                                                [value]="ClientResource_towerSplit[ClientResource_towerSplit.ClientResourceFieldName]"
                                                                [ngClass]="ClientResource_towerSplit[ClientResource_towerSplit.ClientResourceFieldName] != null && ClientResource_towerSplit[ClientResource_towerSplit.ClientResourceFieldName] != undefined && ClientResource_towerSplit[ClientResource_towerSplit.ClientResourceFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(ClientResource_towerSplit,'ClientResource')"
                                                                placeholder="Current Client Resource Countries Count">
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                            </ng-container>
                                            <ng-container *ngIf="showTicketLotDistribution">

                                                <tr>
                                                    <th
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Ticket Lot Distribution %<span class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let TicketLotDistribution_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="TicketLotDistribution_towerSplit[TicketLotDistribution_towerSplit.TicketLotDistributionFieldName]"
                                                                [id]="fieldRandomId+'-'+TicketLotDistribution_towerSplit.TicketLotDistributionFieldName"
                                                                [value]="TicketLotDistribution_towerSplit[TicketLotDistribution_towerSplit.TicketLotDistributionFieldName]"
                                                                [ngClass]="TicketLotDistribution_towerSplit[TicketLotDistribution_towerSplit.TicketLotDistributionFieldName] != null && TicketLotDistribution_towerSplit[TicketLotDistribution_towerSplit.TicketLotDistributionFieldName] != undefined && TicketLotDistribution_towerSplit[TicketLotDistribution_towerSplit.TicketLotDistributionFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(TicketLotDistribution_towerSplit,'TicketLotDistribution')"
                                                                placeholder="Ticket Lot Distribution %"
                                                                [disabled]="TicketLotDistribution_towerSplit.lotdisable">
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                            </ng-container>
                                            <ng-container *ngIf="showLotTicketsCount">

                                                <tr>
                                                    <th *ngIf="showLotTicketsDefaultTitle"
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Lot Tickets Count<span class="required">*</span></th>
                                                    <th *ngIf="showLotsCountMonthly && !showLotTicketsDefaultTitle"
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Lot Tickets Monthly Count<span class="required">*</span></th>
                                                    <th *ngIf="!showLotsCountMonthly && !showLotTicketsDefaultTitle"
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Lot Tickets Yearly Count<span class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let LotTickets_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="LotTickets_towerSplit[LotTickets_towerSplit.LotTicketsFieldName]"
                                                                [id]="fieldRandomId+'-'+LotTickets_towerSplit.LotTicketsFieldName"
                                                                [value]="LotTickets_towerSplit[LotTickets_towerSplit.LotTicketsFieldName]"
                                                                [ngClass]="LotTickets_towerSplit[LotTickets_towerSplit.LotTicketsFieldName] != null && LotTickets_towerSplit[LotTickets_towerSplit.LotTicketsFieldName] != undefined && LotTickets_towerSplit[LotTickets_towerSplit.LotTicketsFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(LotTickets_towerSplit,'LotTickets')"
                                                                [placeholder]="LotTickets_towerSplit.PlaceHolder">
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                            </ng-container>
                                            <ng-container *ngIf="showUserLotDistribution">
                                                <tr>
                                                    <th
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Users Lot Distribution %<span class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let UserLotDistribution_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="UserLotDistribution_towerSplit[UserLotDistribution_towerSplit.UserLotDistributionFieldName]"
                                                                [id]="fieldRandomId+'-'+UserLotDistribution_towerSplit.UserLotDistributionFieldName"
                                                                [value]="UserLotDistribution_towerSplit[UserLotDistribution_towerSplit.UserLotDistributionFieldName]"
                                                                [ngClass]="UserLotDistribution_towerSplit[UserLotDistribution_towerSplit.UserLotDistributionFieldName] != null && UserLotDistribution_towerSplit[UserLotDistribution_towerSplit.UserLotDistributionFieldName] != undefined && UserLotDistribution_towerSplit[UserLotDistribution_towerSplit.UserLotDistributionFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(UserLotDistribution_towerSplit,'UserLotDistribution')"
                                                                placeholder="Users Lot Distribution %"
                                                                [disabled]="UserLotDistribution_towerSplit.lotdisable">
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </ng-container>
                                            <ng-container *ngIf="showLotUsersCount">
                                                <tr>
                                                    <th
                                                        style="background-color: #e9ecef !important;color: #000000 !important;">
                                                        Users Lot Users Count<span class="required">*</span></th>

                                                    <ng-container
                                                        *ngFor="let LotUsers_towerSplit of TowerSplitData.Tot_Lots_Tower; let i = index">
                                                        <td class="text-center">
                                                            <input type="number"
                                                                [(ngModel)]="LotUsers_towerSplit[LotUsers_towerSplit.LotUsersFieldName]"
                                                                [id]="fieldRandomId+'-'+LotUsers_towerSplit.LotUsersFieldName"
                                                                [value]="LotUsers_towerSplit[LotUsers_towerSplit.LotUsersFieldName]"
                                                                [ngClass]="LotUsers_towerSplit[LotUsers_towerSplit.LotUsersFieldName] != null && LotUsers_towerSplit[LotUsers_towerSplit.LotUsersFieldName] != undefined && LotUsers_towerSplit[LotUsers_towerSplit.LotUsersFieldName] != '' 
                                                        ? 'form-control text-center form-control-sm' : 'form-control text-center form-control-sm is-invalid' "
                                                                (change)="onTowerSplitChange(LotUsers_towerSplit,'LotUsers')"
                                                                placeholder="Users Lot Users Count">
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="ModuleName == 'ModelBaseline' || ModuleName == 'ClientProfile' || ModuleName == 'DeliveryModel'|| ModuleName == 'ServiceProfile'|| ModuleName == 'AnticipatedFTE'">
                        <p class="mb-1">
                            <span><i>Note:  Negative values for inputs are not allowed</i></span>
                        </p>
                        <br>
                        <p class="mb-1">
                            <span>Please click on the lot to enter details.</span>
                        </p>
                        <ng-container *ngIf="ApproachName=='APR001'">
                            <ng-container
                                *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">
                                <grid-dispctrl #ModelBaselineGrid
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group: 'ModelBaseliningTDA'})"
                                    [GridData]="BaselineGridData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="ApproachName=='APR002'">
                            <ng-container
                                *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">
                                <grid-dispctrl #ModelBaselineGrid
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group: 'ModelBaseliningTBA'})"
                                    [GridData]="BaselineGridData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="ApproachName=='APR003' || ApproachName=='APR004'">
                            <ng-container
                                *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">
                                <grid-dispctrl #ModelBaselineGrid
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group: 'ModelBaseliningUBITA'})"
                                    [GridData]="BaselineGridData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>
                            </ng-container>
                        </ng-container>
                    </ng-container>


                    <br>
                    <br>
                    <ng-container
                        *ngIf="ApproachName !='APR001' && ApproachName!='APR004' && DealSubLineItemId != null && DealSubLineItemId != undefined && DealSubLineItemId > 0">
                        <ng-container *ngIf="ModuleName == 'ServiceProfile' && ApproachName=='APR002'">
                            <div class="row">
                                <div class="col-12">

                                    <div class="card bg-white">
                                        <div class="card-header bg-primary text-white">
                                            <h3>
                                                <span>Portfolio Profile (Lot: {{LotNameforGrid}})</span>
                                            </h3>
                                        </div>
                                        <div class="card-body overflow-auto">


                                            <ng-container
                                                *ngIf="(ModuleName == 'ServiceProfile' && ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">
                                                <customform #ModelBaselineForm
                                                    [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'PortfolioProfileSPTBA'})"
                                                    [FormData]="ModelBaselineData" [FormProp]="FormProp"
                                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                                </customform>
                                            </ng-container>



                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">

                                    <div class="card bg-white">
                                        <div class="card-header bg-primary text-white">
                                            <h3>
                                                <span>Additional Support Needs % (Lot: {{LotNameforGrid}})</span>
                                            </h3>
                                        </div>
                                        <div class="card-body overflow-auto">
                                            <ng-container *ngIf="(ModuleName == 'ServiceProfile' && ModelConfigTemplate != null && ModelConfigTemplate != undefined 
                                                && ModelConfigTemplate.length > 0 && AdditionalsupportData != null && AdditionalsupportData != undefined 
                                                && AdditionalsupportData.length > 0)">
                                                <grid-dispctrl #ModelBaselineGrid
                                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group: 'AdditionalSupportNeedsSPTBA'})"
                                                    [GridData]="AdditionalsupportData" [GridProp]="GridProp"
                                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)">
                                                </grid-dispctrl>
                                            </ng-container>


                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">

                                    <div class="card bg-white">
                                        <div class="card-header bg-primary text-white">
                                            <h3>

                                                <span>Volumetric Profile (Lot: {{LotNameforGrid}})</span>
                                            </h3>
                                        </div>
                                        <div class="card-body overflow-auto">

                                            <ng-container
                                                *ngIf="(ModuleName == 'ServiceProfile' && ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">
                                                <customform #ModelBaselineForm
                                                    [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'VolumetricProfileSPTBA'})"
                                                    [FormData]="BaselineVolData" [FormProp]="FormProp"
                                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                                </customform>
                                            </ng-container>

                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12">

                                    <div class="card bg-white">
                                        <div class="card-header bg-primary text-white">
                                            <h3>

                                                <span>Tickets Growth %</span>
                                            </h3>
                                        </div>
                                        <div class="card-body overflow-auto">

                                            <ng-container
                                                *ngIf="(ModuleName == 'ServiceProfile' && ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0 && BaselineTicketData != null && BaselineTicketData != undefined && BaselineTicketData.DealHeaderId > 0)">
                                                <customform #ModelBaselineForm
                                                    [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'TicketsGrowthSPTBA'})"
                                                    [FormData]="BaselineTicketData" [FormProp]="FormProp"
                                                    [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                    (FieldClick)="onClick($event)"
                                                    (FieldChange)="onChange($event)">
                                                </customform>
                                            </ng-container>


                                        </div>
                                    </div>


                                </div>
                            </div>


                            <ng-container *ngIf="SaveButtons != null && SaveButtons != undefined">
                                <ng-container *ngFor="let actionBar of SaveButtons 
                          | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        | translate}}
                                    </button>
                                </ng-container>
                            </ng-container>

                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'ServiceProfile' && ApproachName=='APR003'">
                            <p class="text-bold mb-1">
                                <span>Total Hours Required (Lot: {{LotNameforGrid}})</span>

                            </p>
                            <ng-container
                                *ngIf="(ModuleName == 'ServiceProfile' && ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">
                                <customform #ModelBaselineForm
                                    [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'UBASPFields'})"
                                    [FormData]="DeliveryDataModel" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                    [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                    (FieldChange)="onChange($event)">
                                </customform>
                            </ng-container>


                            <br>
                            <ng-container
                                *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0 && GridDataServiceProfileUserApproach!=undefined && GridDataServiceProfileUserApproach!=''&& GridDataServiceProfileUserApproach!=null)">
                                <grid-dispctrl #ModelBaselineGrid [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group:'UBASPGrid'})"
                                    [GridData]="GridDataServiceProfileUserApproach" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    [cellLock]="setCellLock" [parent]="this" (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>


                            </ng-container>

                            <ng-container *ngIf="SaveButtons != null && SaveButtons != undefined">
                                <ng-container *ngFor="let actionBar of SaveButtons 
                          | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        | translate}}
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                    </ng-container>

                    <br>

                    <ng-container
                        *ngIf="ModuleName == 'ClientProfile'  && DealSubLineItemId != null && DealSubLineItemId != undefined && DealSubLineItemId > 0">
                        <ng-container *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined 
                            && ModelConfigTemplate.length > 0 && ClientResourceData != null && ClientResourceData != undefined 
                            && ClientResourceData.length > 0)">
                            <p class="text-bold mb-1">
                                <span>Client Resource Profile (Lot: {{LotNameforGrid}})</span>

                            </p>
                            <br>
                            <!-- <customform #ModelBaselineForm
                            [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'DeliveredLocationModeliningAM'})"
                            [FormData]="ModelBaselineData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)">
                        </customform> -->

                            <ng-container *ngIf="ApproachName=='APR004'|| ApproachName =='APR002'">
                                <grid-dispctrl
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group:'clientresourceprofilegrid'})"
                                    [GridData]="ClientResourceData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    [cellLock]="setCellLock" [parent]="this" (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>
                            </ng-container>




                            <ng-container *ngIf="ApproachName =='APR001'">
                                <grid-dispctrl #ModelClientProfileTD
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group:'clientresourceTDAgrid'})"
                                    [GridData]="ClientResourceData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    [cellLock]="setCellLock" [parent]="this" (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>
                            </ng-container>



                            <ng-container *ngIf="ApproachName =='APR003'">
                                <grid-dispctrl
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group:'ClientResourceUBAGrid'})"
                                    [GridData]="ClientResourceData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    [cellLock]="setCellLock" [parent]="this" (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>
                            </ng-container>


                        </ng-container>
                    </ng-container>

                    <ng-container
                        *ngIf="ModuleName == 'AnticipatedFTE' && DealSubLineItemId != null && DealSubLineItemId != undefined && DealSubLineItemId > 0">
                        <p class="text-bold mb-1">
                            <span>Anticipated FTE Growth (Lot: {{LotNameforGrid}})</span>
                            <!--  <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="test"><img src="IMG/information-Icons.svg" alt="information icon"></span> -->
                        </p>
                        <ng-container *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0
                            && Anticipatedfte != null && Anticipatedfte != undefined 
                            && Anticipatedfte.length > 0)">


                            <grid-dispctrl #ModelBaselineGrid
                                [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group: 'anticipatedftegrowth'})"
                                [GridData]="Anticipatedfte" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                                (FieldClick)="onClick($event)" [IsPagination]="IsPagination"
                                (FieldChange)="onChange($event)"
                                (ActionBarClick)="actButtonClick($event)">
                            </grid-dispctrl>
                        </ng-container>
<br>
<br>


                        <ng-container *ngIf="SaveButtons != null && SaveButtons != undefined">
                            <ng-container *ngFor="let actionBar of SaveButtons 
                      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                <button type="button" [ngClass]="actionBar.CustomClass"
                                    (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                    | translate}}
                                </button>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="ModuleName == 'DeliveryModel' && DealSubLineItemId != null && DealSubLineItemId != undefined && DealSubLineItemId > 0">
                        <p class="text-bold mb-1">
                            <span>Delivery Location Modelling(Lot: {{LotNameforGrid}})</span>
                        </p>

                        <!-- 
                        <ng-container
                            *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0)">

                            <customform #ModelBaselineForm
                                [FormTemplate]="(ModelConfigTemplate| filterBy: {Group: 'MBDLMFields'})"
                                [FormData]="ModelBaselineData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)">
                            </customform>
                            <ng-container *ngIf="DeliveryModelButtons != null && DeliveryModelButtons != undefined">
                                <ng-container *ngFor="let actionBar of DeliveryModelButtons 
                                  | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        | translate}}
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container> -->





                        <!-- <br> -->
                        <br>
                        <ng-container *ngIf="ModuleName == 'DeliveryModel'">
                            <ng-container
                                *ngIf="(ModelConfigTemplate != null && ModelConfigTemplate != undefined && ModelConfigTemplate.length > 0
                                && deliveryModelgridData != null && deliveryModelgridData != undefined && deliveryModelgridData.length > 0)">
                                <grid-dispctrl #ModelBaselineGrid
                                    [FieldTemplate]="(ModelConfigTemplate| filterBy: {Group: 'MBDLMGrid'})"
                                    [GridData]="deliveryModelgridData" [GridProp]="GridProp"
                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                    [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                    [cellLock]="setCellLock" [parent]="this" (ActionBarClick)="actButtonClick($event)">
                                </grid-dispctrl>

                                <br>
                                <br>
                                <div class="">
                                    <div class="d-flex justify-content-end">
                                        <button type="button" class="rounded-pill btnCustom btnPrimary"
                                            (click)="SaveDeliveryModel()" value="">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                    </ng-container>




                </div>






                <div>
                    <div class="filters buttonHolder" id="create-deal">
                        <div class="d-flex justify-content-end">
                            <ng-container *ngIf="ModelButtons != null && ModelButtons != undefined">
                                <ng-container *ngFor="let actionBar of ModelButtons 
                          | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        | translate}}
                                    </button>
                                </ng-container>



                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</ng-container>

<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
        [curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>



<modal-popup id="ITmetrics" style="display: none;" [modaldetail]="modalInf">
    <ng-container *ngIf="(ITMetricsTemplate != null && ITMetricsTemplate != undefined && ITMetricsTemplate.length > 0
        && ITMetricsData != null && ITMetricsData != undefined && ITMetricsData.DealHeaderId > 0)">
        <customform #ITMetricsForm [FormTemplate]="ITMetricsTemplate" [FormData]="ITMetricsData"
            [FormProp]="ITMetricsFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onITMetricsChange($event)">
        </customform>
        <ng-container *ngIf="revenue == true ||opex == true ">
            <!-- <customform #ModelConfigForm [FormTemplate]="AnnualCompensation" [FormData]="ITMetricsData"
                [FormProp]="ITMetricsFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onITMetricsChange($event)">
            </customform> -->

            <p>Annual Compensation (in M)</p>
            <input [(ngModel)]="AnnualCompensation.value" type="text" class="form-control form-control-sm w-50"
                (change)="onITMetricsChange(AnnualCompensation)">

            <!-- type="text" class="form-control form-control-sm ng-untouched ng-pristine ng-valid" -->

        </ng-container>

    </ng-container>
    <br>
    <ng-container *ngIf="optionSelected">

        <p class="text-bold mb-1">
            <ng-container *ngIf="employee"><span>Total Employees</span> </ng-container>
            <ng-container *ngIf="revenue"><span>Revenue</span> </ng-container>
            <ng-container *ngIf="opex"><span>OPEX</span> </ng-container>
        </p>

        <ng-container
            *ngIf="(employee == true && ITMetricsEmployeeOptionTemplate != null && ITMetricsEmployeeOptionTemplate != undefined && ITMetricsEmployeeOptionTemplate.length > 0 )">
            <customform #ModelConfigForm [FormTemplate]="ITMetricsEmployeeOptionTemplate" [FormData]="ITMetricsData"
                [FormProp]="ITMetricsFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onITMetricsChange($event)">
            </customform>
            <ng-container *ngIf="employee == true">
                <p>IT Resource Employee</p>
                <input [(ngModel)]="ITResourceEmployee" type="text" class="form-control form-control-sm w-50">
            </ng-container>

        </ng-container>

        <ng-container
            *ngIf="(employee == true && EmployeeGridITMetrics != null && EmployeeGridITMetrics != undefined && ITMetricsEmployeeGridTemplate.length > 0)">
            <div class="mt-2">
                <grid-dispctrl #ModelBaselineGrid [FieldTemplate]="EmployeeGridITMetrics"
                    [GridData]="PopUpITMetricsGridData" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                    (FieldClick)="onClick($event)" [IsPagination]="IsPagination"
                    (FieldChange)="onITMetricsChange($event)" (ActionBarClick)="actButtonClick($event)">
                </grid-dispctrl>
            </div>
        </ng-container>
        <ng-container
            *ngIf="(revenue == true && ITMetricsRevenueOptionTemplate != null && ITMetricsRevenueOptionTemplate != undefined && ITMetricsRevenueOptionTemplate.length > 0 )">
            <customform #ModelConfigForm [FormTemplate]="ITMetricsRevenueOptionTemplate" [FormData]="ITMetricsData"
                [FormProp]="ITMetricsFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onITMetricsChange($event)">
            </customform>

            <ng-container *ngIf="revenue == true">
                <!-- <p>Revenue</p>
                <input [(ngModel)]="ITRevenue"> -->
                <div class="row">
                    <div class="col-6">
                        <p>IT Spend (in M)</p>
                        <input [(ngModel)]="ITSpend" type="number" class="form-control form-control-sm">
                    </div>
                    <div class="col-6">
                        <p>Resource Count</p>
                        <input [(ngModel)]="ResourceCount" type="number" class="form-control form-control-sm">
                    </div>
                </div>
            </ng-container>

        </ng-container>

        <ng-container
            *ngIf="(revenue == true && RevenueandITGridITMetrics != null && RevenueandITGridITMetrics != undefined && RevenueandITGridITMetrics.length > 0)">
            <div class="mt-2">
                <grid-dispctrl #ModelBaselineGrid [FieldTemplate]="RevenueandITGridITMetrics"
                    [GridData]="PopUpITMetricsGridData" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                    (FieldClick)="onClick($event)" [IsPagination]="IsPagination"
                    (FieldChange)="onITMetricsChange($event)" (ActionBarClick)="actButtonClick($event)">
                </grid-dispctrl>
            </div>
        </ng-container>
        <ng-container
            *ngIf="(opex == true && ITMetricsOPEXOptionTemplate != null && ITMetricsOPEXOptionTemplate != undefined && ITMetricsOPEXOptionTemplate.length > 0 )">
            <customform #ModelConfigForm [FormTemplate]="ITMetricsOPEXOptionTemplate" [FormData]="ITMetricsData"
                [FormProp]="ITMetricsFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldClick)="onClick($event)" (FieldChange)="onITMetricsChange($event)">
            </customform>

            <ng-container *ngIf="opex == true">
                <div class="row">
                    <div class="col-6">
                        <p>IT Spend OPEX(in M)</p>
                        <input [(ngModel)]="ITSpendOPEX" type="number" class="form-control form-control-sm">
                    </div>
                    <div class="col-6">
                        <p>Resource Count</p>
                        <input [(ngModel)]="ResourceCount" type="number" class="form-control form-control-sm">
                    </div>
                </div>

            </ng-container>

        </ng-container>

        <ng-container
            *ngIf="(opex == true && RevenueandITGridITMetrics != null && RevenueandITGridITMetrics != undefined && RevenueandITGridITMetrics.length > 0)">
            <div class="mt-2">
                <grid-dispctrl #ModelBaselineGrid [FieldTemplate]="RevenueandITGridITMetrics"
                    [GridData]="PopUpITMetricsGridData" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                    (FieldClick)="onClick($event)" [IsPagination]="IsPagination"
                    (FieldChange)="onITMetricsChange($event)" (ActionBarClick)="actButtonClick($event)">
                </grid-dispctrl>
            </div>
        </ng-container>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="saveITmetrics()" value="">
                Save
            </button>
            <button type="button" class="rounded-pill btnCustom" (click)="cancelITmetrics()" value="">
                Cancel
            </button>
        </div>
    </div>
</modal-popup>
<modal-popup id="OpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>This sub-panel “Model configuration profile” within “Creating a deal” is about defining the model construct. The
        key is to establish what approach to take in building the model. There are four approaches built in the tool -
        Top Down, Ticket based, Users based, IT metrics based. Top Down approach is to build the managed services model
        using FTE information we may have. Ticket based approach can be used when we have volumetric information like
        incidents, service requests etc. User based approach when we know no of users for the system or a portfolio. IT
        metrics-based approach is to build a proactive model for a client when we have limited information - this
        approach uses Gartner metrics for IT services. ? Tower – It’s the number - How many independent clusters like
        Application, Infrastructure and Business Services may have to configured at the highest level. Arriving at the
        numbers depends on how the client scope is defined and pricing structure is expected – for example, if Client
        Scope is Application only then no of towers is 1; if client scope is apps, infra and BPO, then no of towers are
        3. ? Sub-Tower – Each tower may have one or more sub-towers, Also, one tower may have 1 or 2 whereas other
        sub-towers may have 2 or 3 or more. The highest number should be used as the number. Example – if Application
        tower has only maintenance as scope then sub-tower is 1. If Application tower has separate portfolio bundles as
        SAP &amp;Legacy applications –then sub-towers/ tower is 2. ? Lot - This number is the lowest or most granular
        segmentation of the structure – again, each sub-tower may have different no of lots under them…the highest
        number should be used for the number. Example – if SAP has 3 functional areas and Legacy has 4 application
        bundles, then Lots/ sub-tower are 4. ? Current client resource countries count - This count helps to establish
        the current As-IS baseline and the assumed spend to compare against our price and savings.</p>
</modal-popup>