<!-- <div>
  <ng-container>
    <input type="range" class="form-range" [min]="min" [max]="max" step="step" id="customRange3">
    <div class="row"> 
      <ng-container *ngFor="let range of rangeList">
      <div class="col-{{range.UserDef2}}">
        <p class="ps-{{range.UserDef3}}" tooltip="{{range.UserDef1}}" (click)="onClick(range)">{{range.CodeDescription}}</p>
      </div>
      </ng-container>
    </div>
  </ng-container>
  </div> -->


  <div class="btn-group my-4 slider-btn-group w-100" role="group" aria-label="Basic radio toggle button group">
    <ng-container *ngFor="let range of rangeList">
      <input type="radio" class="btn-check" name="btnradio" id="{{range.CodeDescription}}" autocomplete="off">
      <label class="btn slider-btn" [ngClass]="{'active': (range.CodeDescription == selectedBtn)}" tooltip="{{range.UserDef1}}"  (click)="onClick(range)">{{range.CodeDescription}}</label>
    </ng-container>
  </div>
  