import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule,Title} from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularDraggableModule } from 'angular2-draggable';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { FilterModule } from 'src/modules/filter/filter.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { FormModule } from 'src/modules/form/form.module';
import { GridReadonlyModule } from 'src/modules/grid-readonly/grid-readonly.module';
import { GridModule } from 'src/modules/grid/grid.module';
import { SearchByModule } from 'src/modules/searchby/searchby.module';
import { DashboardModule } from 'src/modules/dashboard/dashboard.module';
import { ExcelExportModule } from 'src/modules/excelexport/excelexport.module';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { GridDisplayControlModule } from 'src/modules/grid-dispctrl/grid-dispctrl.module';
import { AttachmentModule } from 'src/modules/attachment/attachment.module';
//import { ReportModule } from '../report/report.module';
import { ModalModule } from 'src/modules/modal/modal.module';
//import { CreateDealComponent } from './create-deal.component';
import { ModelConfigService } from './model-config.service';
import { ModelConfigComponent } from './model-config.component';

@NgModule({
  declarations: [   
    ModelConfigComponent
  ],
  imports: [
    BrowserModule, FormsModule, NgbModule,
    AlertModule, PaginationModule, TooltipModule,
    ToastrModule, AngularDraggableModule, TranslateModule,
    LoadingModule, FilterModule, CustomPipeModule, FormModule,
    GridReadonlyModule, FilterPipeModule, GridModule, RouterModule,
    ExcelExportModule, ActionBarModule, NotificationModule,
    DashboardModule, SearchByModule, ModalModule, GridDisplayControlModule
    , AttachmentModule   
  ],
  exports: [


  ],
  providers: [ModelConfigService,Title],
  bootstrap: [ModelConfigComponent],

})

export class ModelConfigModule {
}
