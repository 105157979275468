import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class ReportService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private ReportUrl: string;
  private ServiceUrl: string;
  constructor(private _http: HttpClient) {
    this.ReportUrl = environment.apiURL.ReportURL;
    this.ServiceUrl = environment.apiURL.ServiceTypeURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  getMasterReportDetails(odata: any): Observable<ProcessResponse<any>> {
    //let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'GetMasterReportDetails', odata,
      { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }
  getTCVReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 33);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 14);

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetTotalTCVReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }




  GetHardwareCardReport(odata: any) {

    let Params = new HttpParams();


    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
   // Params = Params.append('DealLineitemId', odata.DealLineitemId);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetHardwareCardReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }


  GetToolCardReport(odata: any) {

    let Params = new HttpParams();


    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
   // Params = Params.append('DealLineitemId', odata.DealLineitemId);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetToolCardReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }
  getManagedServiceReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 393);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 380);
    //   UserId=0&RoleId=0&DealHeaderId=33&DealLineitemId=14

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }


  getPandCReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 393);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 380);
    //   UserId=0&RoleId=0&DealHeaderId=33&DealLineitemId=14
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetPandCServiceReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

  getRateCardReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 393);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 380);
    //   UserId=0&RoleId=0&DealHeaderId=33&DealLineitemId=14

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetRateCardReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

  getMSTowerwiseSummaryReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 33);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 14);
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceTowerwiseSummaryReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

  getMSServiceTowerYearWiseReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 33);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 14);
    // Params = Params.append('PercentileType', 'I25th Percentile');

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    Params = Params.append('PercentileType', odata.PercentileType);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceTowerYearwiseReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }


  ///Technology Wise Report///
  getManagedServiceTechnologyYearwiseReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 33);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 14);


    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
   Params = Params.append('PercentileType', odata.PercentileType);

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceTechnologyYearwiseReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

  ///Unit Price Report///


  getManagedServiceYearwiseUnitPriceReportData(odata: any) {

    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', 33);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    // Params = Params.append('DealLineitemId', 14);

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
   Params = Params.append('PercentileType', odata.PercentileType);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceYearwiseUnitPriceReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

  ///YoY Reduction Report///
  getManagedServiceYoYReductionReportData(odata: any) {

    let Params = new HttpParams();
  
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    Params = Params.append('PercentileType', odata.PercentileType);

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceYoYReductionReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

/////charts////
  getMSgraphsData(odata:any){

    let Params = new HttpParams();
  
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceReportChart',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }


  getTotaltcvGraphsData(odata:any){

    let Params = new HttpParams();
  
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetTotalTCVReportChart',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

     // http://localhost:7000/ServiceType/GetTotalTCVReportChart?DealHeaderId=570&UserId=0&RoleId=0&DealLineitemId=577

  }

  getTowerWiseGraphsData(odata:any){

    let Params = new HttpParams();
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    Params = Params.append('PercentileType', odata.PercentileType);

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceTowerwiseReportChart',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
     // http://localhost:7000/ServiceType/GetManagedServiceTowerwiseReportChart?DealHeaderId=570&UserId=0&RoleId=0&DealLineitemId=577&PercentileType=I25th Percentile
     // http://localhost:7000/ServiceType/GetTotalTCVReportChart?DealHeaderId=570&UserId=0&RoleId=0&DealLineitemId=577

  }


  getTechnologyWiseGraphsData(odata:any){

    let Params = new HttpParams();
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    Params = Params.append('PercentileType', odata.PercentileType);
    Params = Params.append('Tower', odata.Tower);
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceTechnologywiseReportChart',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
    //  http://localhost:7000/ServiceType/GetManagedServiceTechnologywiseReportChart?DealHeaderId=570&UserId=0&RoleId=0
    //  &DealLineitemId=577&PercentileType=I25th Percentile&Tower=TOW002

  }

  getYOYGraphsData(odata:any){

    let Params = new HttpParams();
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealLineitemId', odata.DealLineitemId);
    Params = Params.append('PercentileType', odata.PercentileType);

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetManagedServiceYoYReductionChart',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }
  DownloadExcel(odata:any){
    let Params = new HttpParams();
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params ,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetExportedData',
    httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }



}


