<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
		<div class="d-flex w-100 justify-content-between">
            <h1 class="sr-only">Benchmark Guidelines</h1>
			<ul class="breadcrumb m-0 flex-fill">
				<li class="breadcrumb-item active" aria-current="page">Benchmark Guidelines</li>
			</ul>
		</div>
	</nav>

    <div class="contentScroll p-2">
        <div class="planInfoWraper customForm m-2 bg-white">
        <div class="card-body overflow-auto">
            <ng-container>
                <p class="guidelines p-2">
                    1) The information provided in the tool is privileged or confidential and is the property of the
                        Capgemini Group, not to be circulated outside of the Capgemini Sales/Presales team working on
                        the deal.
                        <br/>
2) Benchmarking prices are not to be shared with any Client or anyone outside Capgemini, this is
only an internal practice to evaluate and align deal/solution specific pricings. 
<br/>
3) Benchmark prices provided here are only for solution guidelines purposes, this should not be
considered as Price to Win (P2W) or deal winning prices.
<br/>
4) Benchmark prices provided are based on pre-defined set of scope and assumptions, Capgemini
solution/ bid/ sales team to validate and customize their scope and assumption. 
<br/>
5) Price points have been arrived by mapping the scope to the nearest possible with our
repositories (based on availability of data) as per the deal requirements.
<br/>
6) The market competition considerations of our external pricing advisors are only limited Tier I,
TIER II MNC's and IPP's. Any local / region specific players may be more competitive than our
market standard benchmarking rates/ranges suggested here.
<br/>
7) External 25th / 50th Percentile are as shared by external vendor databases such as Wavestone,
Everest, ISG etc. 
<br/>
8) Internal 25th / 50th Percentile calculations are based on data collected from select Capgemini
historic sold and lost (In BAFO) deals irrespective of any new logo’s or renewal deals.
<br/>
9) The 25th and 50th percentile is the price below which 25% and 50% of the market and internal
observations may be found respectively.
<br/>
10) For more price competitiveness - solution/ bid/ sales team is expected to work closely with
delivery team for sizing negotiations on any particular client contracts. 
<br/>
11) Unit price ranges are purely based on availability of data from both our external and internal
database repositories.
<br/>
12) Some unit price ranges are uplifted or downsized to fit in deal requirements, these are purely
based on the guidelines shared by our external advisors.
<br/>
13) In cases where we are in the upper end of a range or outside it, Benchmarking practice forces
the solution / bid / sales team to further highlight our value proposition and differentiation to
the client.
<br/>
14) Price benchmarking practice is not responsible for discrepancies identified in any external
vendor pricing recommendations.
               </p>
            </ng-container>
        </div>
    </div>
    </div>
</div>
