<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <h1 class="sr-only">Admin Role </h1>
            <ul class="breadcrumb m-0 flex-fill">
                <li class="breadcrumb-item" aria-current="page">Admin</li>
                <li class="breadcrumb-item active" aria-current="page">Role Wise Menu Authentication</li>
            </ul>
        </div>
    </nav>

    <div class="contentScroll p-3 simpleCard myDealCard">
        <div class="card h-100 m-0">
            <div class="card-header">
                <h3>
                    <span>Role Wise Menu Authentication</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">
                <ng-container
                    *ngIf="(EditTemplate != null && EditTemplate != undefined && EditTemplate.length > 0)">
                    <customform #roleWiseMenuForm [FormTemplate]="EditTemplate" [FormData]="EditData"
                        [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                        (FieldChange)="onChange($event)">
                    </customform>
                </ng-container>

                <div>
                    <div class="filters buttonHolder" id="create-deal">
                        <div class="d-flex justify-content-end">
                            <ng-container *ngIf="Config.ActionButtonsBottom != null && Config.ActionButtonsBottom != undefined">
                                <ng-container 
                                *ngFor="let actionBar of (Config.ActionButtonsBottom | filterBy: 
                                    {
                                        HasPermission :true 
                                    }
                                    | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">
                                
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        |
                                        translate
                                        }}
                                    </button>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="card-body overflow-auto">
                    <ng-container
                        *ngIf="(ListTemplate != null && ListTemplate != undefined && ListTemplate.length > 0)">
                        <grid-dispctrl #overallgrid [FieldTemplate]="ListTemplate" [GridData]="ListData"
                            [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount">
                        </grid-dispctrl>
                    </ng-container>
                                    
                </div>

            </div>
           
        </div>
    </div>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>