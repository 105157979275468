// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'Localhost',
  AppID: 'APP01',
  authURL: 'https://p2w.capgemini.com',
  baseURL: 'https://p2w.capgemini.com',

  refURL: '',
  eventURL: '',
  hubBaseURL: '',

  apiURL: {
    IdentityURL: 'https://p2w.capgemini.com/CIS/Auth/',
    LoginURL: 'https://p2w.capgemini.com/CIS/Login/',
    UIConfigURL: 'https://p2w.capgemini.com/CIS/UIConfig/',    
    FileManagementURL: 'https://p2w.capgemini.com/CIS/FileManagement/',
    LogURL:'https://p2w.capgemini.com/CIS/Log/',
    MailerURL: 'https://p2w.capgemini.com/CIS/Mailer/',    
    HomeURL: 'https://p2w.capgemini.com/CIS/Home/',
    DealURL: 'https://p2w.capgemini.com/CIS/Deal/',
    ReportURL: 'https://p2w.capgemini.com/CIS/Report/',
    ServiceTypeURL: 'https://p2w.capgemini.com/CIS/ServiceType/',
    UserManagement: 'https://p2w.capgemini.com/CIS/UserManagement/',
    LotURL: 'https://p2w.capgemini.com/CIS/LotSolution/'    
  },

  IsParallelEditEnabled: false,
  tokenexpiry: 30,    // in mins
  sessionexpiry: 120,  // in mins
  sessionexpirywarning: 120,   // in mins
};