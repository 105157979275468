import { Component, Input, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core'; // Import OnDestroy
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../../../shared/app.sharedservice';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AMApproach, ComponentCodes, ComponentConfig, GuideLinesParam, ProcessStatus, ServiceDomain } from '../../../app.interface';
import { LotSolutionService } from './../lot-solution-modelling.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'productivty',
  templateUrl: './productivty.component.html'
})
@AutoUnsubscribe
export class ProductivityComponent implements OnInit {

  @Input() selectedLineItemData: any;
  @Input() ServiceDomainDetails: any;

  DealData: any = {};
  public IsLoading: boolean = false;
  public DealHeaderId: any;
  public DealLineItemId: any;
  public DealSubLineItemId: any;
  public subscriptionList$: Subscription[] = [];
  public notify: NotificationData = new NotificationData();
  public displayNotifybox: boolean = false;
  public modalInf: ModalInterface = new ModalInterface();
  public IsPagination: boolean = false;
  public Permission: any = [];
  public Properties: any = [];
  public LotName: any;
  public ActionButtons: any[] = [];
  public EffortMixButtons: any[] = [];
  public Config: any;

  public ProductivityTemplate: FieldTemplate[] = [];
  public ProductivityImpactTemplate: FieldTemplate[] = [];
  public ProductivityGuideLinesTemplate: FieldTemplate[] = [];
  public UpFrontProductivityTemplate: FieldTemplate[] = [];

  public productivityImpactListDataCount: number = 0;
  public productivityGuideLinesListDataCount: number = 0;

  public productivityImpactList: any = [];
  public productivityGuideLinesList: any = [];
  public upFrontProductivityData: any = { 'IsEditable': 1, 'UpfrontProductivityPer': 0, 'IsModified': 0, 'DealHeaderId': 0, 'DealLineItemId': 0, 'DealSubLineItemId': 0 };



  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable:false,
    PaginationConfig: {
      ItemsPerPage: 25
    }
  };
  public FormProp: FormProperty = {
    ColSize: 3,
    CaptionBold: false,
    CaptionTop: true,
    IsEditable: true,
    ShowControlAlways: true
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public GlobalConfig: any = {};

  constructor(
    private toastr: ToastrService,
    private _router: Router,
    private LotSolutionService: LotSolutionService,
    private modalService: ModalService,
    private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService
  ) { }

  ngOnInit(): void {
    // Initialize component properties
    this.DealHeaderId = this.selectedLineItemData.DealHeaderId ?? 0;
    this.DealLineItemId = this.selectedLineItemData.DealLineItemId ?? 0;
    this.DealSubLineItemId = this.selectedLineItemData.DealSubLineItemId ?? 0;
    this.LotName = this.selectedLineItemData.LotName;

    // Get the current deal data from the shared service
    this.DealData = this._appSharedService.currentDealData;

    // Fetch Location Mix Template
    if (!isNullOrUndefined(this.selectedLineItemData)) {
      this.getProductivityTemplate();
    }
  }

  // ngOnDestroy(): void {
  //   // Unsubscribe from subscriptions to prevent memory leaks
  //   this.subscriptionList$.forEach((subscription) => subscription.unsubscribe());
  // }

  private getProductivityTemplate(): void {
    // Show loading indicator
    this.IsLoading = true;

    // Define the component configuration
    const odata: ComponentConfig = {
      Component: 'LotSolutionModelling',
      ComponentCode: ComponentCodes.Productivity,
      ReferenceValueType: '1'
    };

    // Subscribe to the component configuration API call
    const subscribe$ = this._commonAPIService.getComponentConfig(odata).subscribe(
      (res) => {
        // Handle the response
        if (res !== undefined && res !== null && res.Status === 200) {
          this.ProductivityTemplate = res.Data.FieldTemplate!;
          this.Permission = res.Data.Permission;
          this.Properties = res.Data.Properties;


          this.ProductivityImpactTemplate = this.ProductivityTemplate.filter(x => x.Group === 'ProductivityImpact');
          this.UpFrontProductivityTemplate = this.ProductivityTemplate.filter(x => x.Group === 'UpfrontProductivityPer');
          this.ProductivityGuideLinesTemplate = this.ProductivityTemplate.filter(x => x.Group === 'ProductivityGuideLines');

          //Set Properties
          this.setCompProperties(this.Properties);
          this.ActionButtons = this.Config.ProductivityButtons.filter((x: any) => x.Group === 'Productivity');

          this.getProductivityImpactDetails();
        }
      },
      (err) => {
        // Handle errors
        this.IsLoading = false;
        console.log('Error on getProductivityTemplate.');
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }
  getProductivityImpactDetails(bLoad: boolean = false) {
    if (!bLoad) {
      this.IsLoading = true;
    }

    const odata: any = {
      DealHeaderId: this.DealHeaderId,
      DealLineItemId: this.DealLineItemId,
      DealSubLineItemId: this.DealSubLineItemId
    };

    const subscribe$: Subscription = this.LotSolutionService.getProductivityImpactDetails(odata).subscribe(
      result => {
        if (result && result.Status === ProcessStatus.Success) {
          this.productivityImpactList = result.Data;
          this.productivityImpactListDataCount = this.productivityImpactList?.length ?? 0;

        }

        this.getProductivityGuideLinesDetails(true);
      },
      err => {
        console.log('Error on getting getProductivityImpactDetails details.');
        this.IsLoading = false;
      }
    );

    this.subscriptionList$.push(subscribe$);
  }

  getProductivityGuideLinesDetails(bLoad: boolean = false) {
    if (!bLoad) {
      this.IsLoading = true;
    }

    const odata: any = {
      DealHeaderId: this.DealHeaderId,
      DealLineItemId: this.DealLineItemId,
      DealSubLineItemId: this.DealSubLineItemId
    };

    const subscribe$: Subscription = this.LotSolutionService.getProductivityGuideLinesDetails(odata).subscribe(
      result => {
        if (result && result.Status === ProcessStatus.Success) {
          if (this.ProductivityGuideLinesTemplate && this.ProductivityGuideLinesTemplate.length > 0 &&
            this.DealData && this.DealData.DealTerm > 0 && result.Data && result.Data.length > 0) {
            let guideLineData: any[] = [];

            if (this.ServiceDomainDetails.ServiceDomain !== ServiceDomain.Enhancement) {
              guideLineData = result.Data.filter((x: any) => x.ProductivityPer === GuideLinesParam.ManualOverride);
            } else {
              guideLineData = result.Data.filter((x: any) => x.ProductivityPer === GuideLinesParam.ManualOverride || x.ProductivityPer === GuideLinesParam.Cola);
            }

            this.upFrontProductivityData.UpfrontProductivityPer = (guideLineData[0]?.UpfrontProductivityPer || 0) > 0 ? guideLineData[0].UpfrontProductivityPer : 0;
            this.upFrontProductivityData.DealHeaderId = this.DealHeaderId;
            this.upFrontProductivityData.DealLineItemId = this.DealLineItemId;
            this.upFrontProductivityData.DealSubLineItemId = this.DealSubLineItemId;

            for (let index = 1; index <= this.DealData.DealTerm; index++) {
              let fieldName: string = 'Year' + index.toString();
              let fieldData = this.ProductivityGuideLinesTemplate.find(x => x.FieldName === fieldName);

              if (fieldData) {
                fieldData.IsHidden = false;
              }
            }

            const id = this.DealData.DealTerm + 1;

            for (let index = id; index <= 10; index++) {
              let fieldName: string = 'Year' + index.toString();

              result.Data.forEach((item: any) => {
                item[fieldName] = 0;
              });
            }
          }

          this.productivityGuideLinesList = result.Data;
          this.productivityGuideLinesListDataCount = this.productivityGuideLinesList?.length ?? 0;
        }

        this.IsLoading = false;
      },
      err => {
        console.log('Error on getting getProductivityImpactDetails details.');
        this.IsLoading = false;
      }
    );

    this.subscriptionList$.push(subscribe$);
  }



  setCompProperties(Properties: any[]) {
    this.Config = {}; // Initialize Config as an empty object

    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      this.Config.ProductivityBlockCols = Properties
        .filter(x => x.Name.toLowerCase() === 'ProductivityBlockCols'.toLowerCase())
        .map(x => JSON.parse(x.JSONData));

      Properties.forEach(prop => {
        const name = prop.Name.toLowerCase();

        if (name !== 'defaultrow' && name !== 'blockcols' && name !== 'productivityblockcols' && name !== 'paginationconfig') {
          this.Config[prop.Name] = JSON.parse(prop.JSONData);
        }
      });
    }
  }



  showNotificationMessage(msg: string, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {
      case 'PRODUCTIVITYIMPACT':
        if (event.result.toUpperCase() === 'YES') {
          this.navigateToLocationMix();
        }
        break;
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  onClick(event: any) {

  }

  onChange(event: any) {
    const selitem = event.item;
    selitem.IsModified = 1;
    const fieldname = event.fieldname.toUpperCase();

    switch (fieldname) {
      case 'PARAMCODE':
        this.calculateRecommendedPer();
        break;

      case 'UPFRONTPRODUCTIVITYPER':
        const upfrontProductivityPer = event.item.UpfrontProductivityPer;

        if (!Number.isInteger(upfrontProductivityPer)) {
          this.showNotificationMessage('Value should be a number.', 2, '', null, '', '', 3000);
          event.item.UpfrontProductivityPer = Math.trunc(upfrontProductivityPer);
        } else if (upfrontProductivityPer > 50) {
          this.showNotificationMessage('Entered Productivity is above 50%', 2, '', '', '', '', 3000);
        }
        break;
    }
  }


  calculateRecommendedPer() {
    // Influencer List Check
    if (!isNullOrUndefined(this.productivityImpactList) && this.productivityImpactList.length > 0) {
      // Take Selected Lot Service Domain
      const serviceDomain = this.productivityImpactList[0].ServiceDomain;

      // Logic for calculations
      // Calculate 1st Year: Sum of Parameter Percentage Value
      // Calculate 2nd Year: Sum of Year2 Value
      // Calculate 3rd Year: Sum of Year3 Value
      // Calculate 4th Year: Sum of Year4 Value
      // Calculate 5th Year & remaining years: Sum of Year5 Value
      // Parameter Percentage Value = Weightage * (Parameter Value Percentage / 100)

      // Step1
      // Calculate Parameter Percentage Value
      this.productivityImpactList.forEach((item: any) => {
        const choiceColName = 'Choice' + item.ParamCode.toString() + 'Per';
        item.param_Per_Value = (item.Weightage * (item[choiceColName] / 100));
      });

      // Step2
      // Take the sum of year values
      const sumYears = (field: string) => this.productivityImpactList.map((x: any) => x[field]).reduce((a: any, b: any) => a + b, 0);
      const y1Data = this.productivityImpactList.map((x: any) => x.param_Per_Value).reduce((a: any, b: any) => a + b, 0);
      const y2Data = sumYears('Year2');
      const y3Data = sumYears('Year3');
      const y4Data = sumYears('Year4');
      const y5Data = sumYears('Year5');
      const y6Data = sumYears('Year6');
      const y7Data = sumYears('Year7');
      const y8Data = sumYears('Year8');
      const y9Data = sumYears('Year9');
      const y10Data = sumYears('Year10');

      // Step3
      // Assign year values to guidelines grid
      if (!isNullOrUndefined(this.productivityGuideLinesList) && this.productivityGuideLinesList.length > 0) {
        const guidelinesData = this.productivityGuideLinesList.filter((x: any) =>
          x.ProductivityPer === GuideLinesParam.Recommended ||
          x.ProductivityPer === GuideLinesParam.ManualOverride ||
          (x.ProductivityPer === GuideLinesParam.NetReduction && serviceDomain === ServiceDomain.Enhancement)
        );
        const guidelinesColaData = this.productivityGuideLinesList.filter((x: any) => x.ProductivityPer === GuideLinesParam.Cola);

        if (!isNullOrUndefined(guidelinesData) && guidelinesData.length > 0) {
          guidelinesData.forEach((item: any) => {
            const guidelineYear1 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year1 : y1Data;
            const guidelineYear2 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year2 : y2Data;
            const guidelineYear3 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year3 : y3Data;
            const guidelineYear4 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year4 : y4Data;
            const guidelineYear5 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year5 : y5Data;
            const guidelineYear6 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year6 : y6Data;
            const guidelineYear7 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year7 : y7Data;
            const guidelineYear8 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year8 : y8Data;
            const guidelineYear9 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year9 : y9Data;
            const guidelineYear10 = item.ProductivityPer === GuideLinesParam.NetReduction ? y1Data - guidelinesColaData[0].Year10 : y10Data;

            item.Year1 = guidelineYear1;
            item.Year2 = guidelineYear2;
            item.Year3 = guidelineYear3;
            item.Year4 = guidelineYear4;
            item.Year5 = guidelineYear5;
            item.Year6 = guidelineYear6;
            item.Year7 = guidelineYear7;
            item.Year8 = guidelineYear8;
            item.Year9 = guidelineYear9;
            item.Year10 = guidelineYear10;
          });
        }

        // Step4
        // Set Year value to 0 based on the deal term
        const id = this.DealData.DealTerm + 1;
        const setYearToZero = (fieldName: string) => this.productivityGuideLinesList.forEach((item: any) => item[fieldName] = 0);

        for (let index = id; index <= 10; index++) {
          const fieldName: string = 'Year' + index.toString();
          setYearToZero(fieldName);
        }
      }
    }
  }


  HelpIconClick() {

    if (this.ServiceDomainDetails.ServiceDomain !== ServiceDomain.Enhancement) {
      this.openModal('LocationMixDetails');

    } else {
      this.openModal('enhancementDetails');
    }

    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };

  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  actionButtonClick(data: any): void {
    const name = data.Name?.toUpperCase();

    if (name !== undefined && name !== null) {
      if (name === 'SAVE') {

        this.insertUpdateProductivityDetails();

      } else if (name === 'BACK') {

        let modifiedproductivityImpact = this.productivityImpactList.filter((x: any) => x.IsModified === 1);
        let modifiedproductivityGuide = this.productivityGuideLinesList.filter((x: any) => x.IsModified === 1);

        if ((!isNullOrUndefined(modifiedproductivityImpact) && modifiedproductivityImpact.length > 0)
          || (!isNullOrUndefined(modifiedproductivityGuide) && modifiedproductivityGuide.length > 0)) {

            this.showNotificationMessage('Are you sure you want to cancel unsaved data?',
            2, 'PRODUCTIVITYIMPACT', null, 'Yes', 'No', 10000);

        } else {
          this.navigateToLocationMix();
        }

      }
    }
  }

  insertUpdateProductivityDetails(bLoad: boolean = false) {

    let modifiedproductivityImpact = this.productivityImpactList.filter((x: any) => x.IsModified === 1);
    let modifiedproductivityGuide = this.productivityGuideLinesList.filter((x: any) => x.IsModified === 1);

  //  if ((!isNullOrUndefined(modifiedproductivityImpact) && modifiedproductivityImpact.length > 0)
    //  || (!isNullOrUndefined(modifiedproductivityGuide) && modifiedproductivityGuide.length > 0)) {

      if (
        !this.validateProductivityDetails() &&
        (
          (this.productivityImpactList && this.productivityImpactList.length > 0) ||
          (this.productivityGuideLinesList && this.productivityGuideLinesList.length > 0)
        )
      ) {

        if (!bLoad) {
          this.IsLoading = true;
        }

        let guideLineData: any[] = [];

        if (this.productivityImpactList && this.productivityImpactList.length > 0) {
          this.productivityImpactList.forEach((item: any) => {
            item.ParameterValue = item.ParamCode;
            item.UpdatedBy = this._appSharedService.UserAccessInfo.actinguserid;
            item.UpdatedDate = new Date();
          });
        }

        if (this.productivityGuideLinesList && this.productivityGuideLinesList.length > 0) {
          this.productivityGuideLinesList.forEach((item: any) => {
            item.UpdatedBy = this._appSharedService.UserAccessInfo.actinguserid;
            item.UpdatedDate = new Date();
            item.UpfrontProductivityPer = this.upFrontProductivityData.UpfrontProductivityPer;

            for (let i = 1; i <= 10; i++) {
              item[`Year${i}`] = isFinite(item[`Year${i}`]) && item[`Year${i}`] !== null ? item[`Year${i}`] : 0;
            }
          });

          if (this.ServiceDomainDetails.ServiceDomain !== ServiceDomain.Enhancement) {
            guideLineData = this.productivityGuideLinesList.filter((x: any) => x.ProductivityPer === GuideLinesParam.ManualOverride);
          } else {
            guideLineData = this.productivityGuideLinesList.filter((x: any) => x.ProductivityPer === GuideLinesParam.ManualOverride || x.ProductivityPer === GuideLinesParam.Cola);
          }
        }

        const odata: any = {
          influencersDetails: this.productivityImpactList,
          yearWiseDetails: guideLineData
        };

        const subscribe$: Subscription = this.LotSolutionService.insertUpdateProductivityDetails(odata).subscribe(
          result => {
            if (result && result.Status === ProcessStatus.Success) {
              this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);

              if (this.ServiceDomainDetails.ServiceDomain !== ServiceDomain.Enhancement) {
                this.navigateToStaffingModel();
              } else {
                this.navigateToPricing();
              }
            }

            this.IsLoading = false;
          },
          err => {
            console.log('Error on getting GetDealDetails.');
            this.IsLoading = false;
          }
        );

        this.subscriptionList$.push(subscribe$);
      } else {
        this.IsLoading = false;
      }
  //  } 
    // else {
    //   this.navigateToStaffingModel();
    //   this.IsLoading = false;
    // }
  }

  validateProductivityDetails(): boolean {
    let valid = false;

    if (this.productivityGuideLinesList && this.productivityGuideLinesList.length > 0) {
      let guideLineData: any[] = [];

      if (this.ServiceDomainDetails.ServiceDomain !== ServiceDomain.Enhancement) {
        guideLineData = this.productivityGuideLinesList.filter((x: any) => x.ProductivityPer === GuideLinesParam.ManualOverride);
      } else {
        guideLineData = this.productivityGuideLinesList.filter((x: any) => x.ProductivityPer === GuideLinesParam.ManualOverride || x.ProductivityPer === GuideLinesParam.Cola);
      }

      if (guideLineData && guideLineData.length > 0) {
        let yearValue = 0;

        for (let id = 1; id <= guideLineData.length; id++) {
          let fieldName = 'Year' + id.toString();

          if (guideLineData[id - 1][fieldName] > 0) {
            yearValue++;
          }
        }

     //   if (yearValue === 0) {
        //  valid = true;
         // this.showNotificationMessage('Please fill guidelines to proceed further.', 2, '', null, '', '', 1000);
        //}
      } 
      else {
        valid = true;
        this.showNotificationMessage('No data available.', 2, '', null, '', '', 1000);
      }
    } else {
      valid = true;
      this.showNotificationMessage('No data available.', 2, '', null, '', '', 1000);
    }

    return valid;
  }


  private navigateToLocationMix(): void {
    const url = `app/lot-solution-modelling/LocationMix/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToProductivity(): void {
    const url = `app/lot-solution-modelling/productivityImpact/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToStaffingModel(): void {
    const url = `app/lot-solution-modelling/StaffingModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToDeliveryModel(): void {
    const url = `app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToPricing(): void {
    const url = `app/lot-solution-pricing-model/pricingmodel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url]);
  }

}
