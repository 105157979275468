import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CisMainPageService } from './cis-main-page.service';
import { AppSharedService, AutoUnsubscribe } from '../../shared/app.sharedservice';
import { ProcessStatus } from '../../app.interface';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cis-main-page',
  templateUrl: './cis-main-page.component.html',
  styleUrls: ['./cis-main-page.component.css']
})

@AutoUnsubscribe
export class CisMainPageComponent implements OnInit {
  public ModuleName: string = '';
  public announcementList: any;
  public IsLoading: boolean = false;
  public tabName: string = '';
  public Title: any = 'CIS Home Page';
  public subscriptionList$: Subscription[] = [];
  public ListData: any[] = [];

  constructor(private _router: Router,private CisMainPageService: CisMainPageService,
    private _appSharedService: AppSharedService, private title: Title) { }

  ngOnInit(): void {
    this.tabName = 'CISAnnouncement';
    this.title.setTitle(this.Title);
    this.GetDataCisDashboardList(this.tabName);
  }

  GetDataCisDashboardList(Tname: any) {
    let subscribe$: Subscription = this.CisMainPageService.GetDataCisDashboardList(this.tabName).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.announcementList = result.Data;
        
        for(let index=0;index<this.announcementList.length;index++){
          this.ListData.push(this.announcementList[index].UserDef2);
        }
      }

      this.IsLoading = false;
    },

      err => {
        console.log('Error on getting range details.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    // this.DealInfoForm.enableFields('Nearshore', true);
  }

  MyDealsDashaboard(){
    this.ModuleName = 'CIS';
    this._router.navigate(["app/home"]);
  }

  onBenchMarkGuidelinesClick(){
    this._router.navigate(["app/benchmark-guidelines"]);
  }

}
