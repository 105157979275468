import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule,Title  } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'src/modules/modal/modal.module';
import { CisMainPageComponent } from './cis-main-page.component';
import { CisMainPageService } from './cis-main-page.service';

@NgModule({
  declarations: [CisMainPageComponent],
  imports: [
    BrowserModule, FormsModule, NgbModule,NgSelectModule, 
    RouterModule, ModalModule
  ],
  providers: [CisMainPageService,Title],
  bootstrap: [CisMainPageComponent]
})
export class CisMainPageModule { }
