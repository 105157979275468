
<div id="wrapper" class="cis-wrapper cis-landing-page">
   
    <div id="banner">
        <div class="clear"></div>
        <div class="jumbotron">
            <h1 class="">
                <span class="wc">Welcome to</span>
                <span>CIS PRICE BENCHMARKING</span>
            </h1>
            <!-- <p>Introduction to the systematic and continuous benchmarking<br>process</p> -->
        </div>
    </div>
    <div id="container">
        <div id="platform">
            <div class="heading"><h4>Introduction to Price Benchmarking</h4></div>
            <div class="document-downloading">
                <a href="assets/files/PB Guidance document_V1.0.pdf" download class="document-downloading-link"></a>
            </div>
            <div class="benchmark-process">
                <p>Benchmarking is a systematic and continuous process to:</p>
                <ul>
                    <li>Competitive prices: To determine if the Pricing is Competitive and adjust if necessary, by evaluating and measuring pricing of services, against industry peers to maximize wins.</li>
                    <li>Market Trends: To stay aware of the changes in the market pricing trends to stay agile and respond to it by staying updated, identify market shifts and adjust pricing strategies accordingly</li>
                    <li>Internal Metrics: Establishing a standard to measure internal metrics to support a decision-making process</li>
                    <li>Best Practices: To have broader feedback mechanism to capture and share the best practices and prices with relevant stakeholders</li>
                </ul>
            </div>
            <div class="heading"><h4>Key Outcomes</h4></div>
            <ul class="list-segment">
                <li>
                    <div class="list-segment-img bg-Keyoutcomes1"></div>
                    <div class="list-segment-txt">
                        <br>
                        <h5>Price per Resource Unit</h5>
                    </div>
                </li>
                <li>
                    <div class="list-segment-img bg-Keyoutcomes2"></div>
                    <div class="list-segment-txt">
                        <br>
                        <h5>Market FTE Rate Card</h5>
                    </div>
                </li>
                <li>
                    <div class="list-segment-img bg-Keyoutcomes5"></div>
                    <div class="list-segment-txt">
                        <br>
                        <h5>TCV Estimates</h5>
                    </div>
                </li>
                <li>
                    <div class="list-segment-img bg-Keyoutcomes3"></div>
                    <div class="list-segment-txt">
                        <br>
                        <h5>Price range for comparison - 25th and 50th percentile</h5>
                    </div>
                </li>
                <li>
                    <div class="list-segment-img bg-Keyoutcomes4"></div>
                    <div class="list-segment-txt">
                        <br>
                        <h5>Capgemini historical price points</h5>
                    </div>
                </li>
   
            </ul>
        </div>
        <div id="tools">
            <div class="heading"><h4>Which tool to use?</h4></div>
            <div class="tools-holder">
                <div class="tools-box">
                    <div class="heading"><h6>Price Benchmarking Self Service Tool</h6></div>
                    <div class="tools-wrap">
                       
                        <ul class="tools-list">
                            <li>Detailed</li>
                            <li>More aligned with market definitions</li>
                            <li>Covers full portfolio of Managed Service, P&C and Rate Cards</li>
                            <li>Standard requirements</li>
                            <li>Price view only</li>
                            <li>No FTE’s estimates</li>
                        </ul>
                    </div>
                    <div class="tools-wrap">
                        <ul class="tools-feature">
                            <li><a class="buttonbgcolorcis" href="javascript:void(0);" (click)="MyDealsDashaboard()">My Deals</a></li>
                            <li><a class="buttonbgcolorcis"  href="assets/files/CIS PB Self service tool user manual v1.0.pdf" download>User Manual<a href="" class="icon-download"></a></a></li>
                            <li><a  class="buttonbgcolorcis" href="javascript:void(0);" (click)="onBenchMarkGuidelinesClick()">Benchmark Guidelines</a></li>
                        </ul>
                    </div>
                </div>
                <div class="tools-box">
                    <div class="heading"><h6>Top-Down Solutioning Tool</h6></div>
                    <div class="tools-wrap">
                      
                        <ul class="tools-list">
                            <li>High-Level</li>
                            <li>More aligned with Capgemini standards</li>
                            <li>Focused on Managed Service and Business Case/Transformation</li>
                            <li>Custom requirements</li>
                            <li>Price and Cost views</li>
                            <li>FTE’s estimates based on DCD’s</li>
                        </ul>
                    </div>
                    <div class="tools-wrap">
                        <ul class="tools-feature">
                            <li><a class="buttonbgcolorcis"  href="mailto:tomasz.furman@capgemini.com">Request Access</a></li>
                            <li><a  class="buttonbgcolorcis" href="assets/files/Top-Down Solutioning Tool Guidance_v.6.3.pdf" download>User Manual<a href="" class="icon-download"></a></a></li>
                            <li><a class="buttonbgcolorcis"  href="https://solutioning.cis.capgemini.com/portal/#/tool/top-down-solutioning" target="_blank" rel = "noopener noreferrer">Link to Top down solutioning</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        <div class="clear"></div>
        </div>
        <div id="sources-update">
            <div class="panel-left">
                <div class="heading"><h6>Sources</h6></div>
                <ul class="sourced">
                    <li><a href="javascript:void(0);" class="sourced-capgemini"></a></li>
                    <li><a href="javascript:void(0);" class="sourced-wavestone"></a></li>
                    <li><a href="javascript:void(0);" class="sourced-everest"></a></li>
                    <li><a href="javascript:void(0);" class="sourced-isg"></a></li>
                </ul>
            </div>
            <div class="panel-right">
                <div class="heading"><h6>Updates / Announcements</h6></div>
                <!-- <nav class="update-announce">
                    <a href="javascript:void(0);">Updates / Announcements</a>
                    <a href="javascript:void(0);">Updates / Announcements</a>
                </nav> -->
                <div class="update-announce">
                    <marquee direction="up" width="100%" height="150px" scrollamount="3">
                        <ul class="marqueeUl">
                            <li *ngFor="let el of ListData">{{el}}</li>
                        </ul>
                    </marquee>
                </div>
              
            </div>
            <div class="clear"></div>
        </div>
    </div>
    <!-- <div class="clear"></div>
    <div class="footer">
        <div class="footer-content">
            <div class="footer-row">
                <div class="lefter">
                    <p>This website is best viewed in Google Chrome.</p>
                    <p> All rights reserved by Capgemini. Copyright © 2023</p>
                </div>
                <div class="righter">
                    <nav>
                        <a href="mailto:cisbbs.in@capgemini.com?subject=Contact Us">Contact us</a>
                        <a  href="https://capgemini.sharepoint.com/sites/CLIKH/SitePages/NewRequirement/Clikh.aspx"
                        target="_blank">Click</a>
                    </nav>
                </div>
            </div>
        </div>
    </div> -->
</div>




              

