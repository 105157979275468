import { Component, Input, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core'; // Import OnDestroy
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../../../shared/app.sharedservice';
import { FieldTemplate } from 'src/modules/shared/_classes/utility.interface';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../../../app.interface';
import { LotSolutionService } from './../lot-solution-modelling.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { CommonAPIService } from 'src/app/shared/app.commonservice';

@Component({
  selector: 'delivery-model',
  templateUrl: './delivery-model.component.html'
})
@AutoUnsubscribe
export class DeliveryModelComponent implements OnInit {

  @Input() selectedLineItemData: any;
  @Input() ServiceDomainDetails: any;
  @ViewChildren('DeliveryModelGrid') DeliveryModelGrid!: QueryList<GridDisplayControlComponent>;

  public IsLoading: boolean = false;
  public dataTotalCount: number = 0;
  public DeliveryModelData: any[] = []; // Assign appropriate type
  public DealHeaderId: any;
  public DealLineItemId: any;
  public DealSubLineItemId: any;
  public subscriptionList$: Subscription[] = [];
  public notify: NotificationData = new NotificationData();
  public displayNotifybox: boolean = false;
  public modalInf: ModalInterface = new ModalInterface();
  public DeliveryModelTemplate: FieldTemplate[] = [];
  public Permission: any = [];
  public Properties: any = [];
  public LotName: any;
  public ActionButtons: any[] = [];
  public Config: any;

  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable: false
  };

  constructor(
    private toastr: ToastrService,
    private _router: Router,
    private LotSolutionService: LotSolutionService,
    private modalService: ModalService,
    private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService
  ) { }

  ngOnInit(): void {
    // Initialize component properties
    this.DealHeaderId = this.selectedLineItemData.DealHeaderId ?? 0;
    this.DealLineItemId = this.selectedLineItemData.DealLineItemId ?? 0;
    this.DealSubLineItemId = this.selectedLineItemData.DealSubLineItemId ?? 0;
    this.LotName = this.selectedLineItemData.LotName;

    // Fetch Delivery Location Modelling Template

    if (!isNullOrUndefined(this.selectedLineItemData)) {
      this.getDeliveryModelTemplate();
    }
  }

  // ngOnDestroy(): void {
  //   // Unsubscribe from subscriptions to prevent memory leaks
  //   this.subscriptionList$.forEach((subscription) => subscription.unsubscribe());
  // }

  private getDeliveryModelTemplate(): void {
    // Show loading indicator
    this.IsLoading = true;

    // Define the component configuration
    const odata: ComponentConfig = {
      Component: 'LotSolutionModelling',
      ComponentCode: ComponentCodes.DeliveryModel,
      ReferenceValueType: '1'
    };

    // Subscribe to the component configuration API call
    const subscribe$ = this._commonAPIService.getComponentConfig(odata).subscribe(
      (res) => {
        // Handle the response
        if (res !== undefined && res !== null && res.Status === 200) {
          this.DeliveryModelTemplate = res.Data.FieldTemplate!;
          this.Permission = res.Data.Permission;
          this.Properties = res.Data.Properties;

          //Set Properties
          this.setCompProperties(this.Properties);
          this.ActionButtons = this.Config.LSEffortModellingButtons.filter((x: any) => x.Group === 'DeliveryLocationModelling');
          this.Properties.Onshore_Blk = JSON.parse(`[{"Field":"LocationType","Value":"NEARSH","BlockCols":["PRIMARYONSHORELOCATION"]},{"Field":"LocationType","Value":"OFFSH","BlockCols":["PRIMARYONSHORELOCATION"]}]`);

          this.GetLSDeliveryLocationModelling(false);
        }
      },
      (err) => {
        // Handle errors
        this.IsLoading = false;
        console.log('Error on getLotSolutionTemplate.');
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'ProductivityBlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          if (isNullOrUndefined(this.Config)) {
            this.Config = {};
          }
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  GetLSDeliveryLocationModelling(bLoad: boolean) {

    if (!bLoad) {
      this.IsLoading = true;
    }

    let subscribe$: Subscription = this.LotSolutionService.GetLSDeliveryLocationModelling(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId)
      .subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          this.DeliveryModelData = result.Data;

          this.DeliveryModelData.forEach(item => {
            item.TechnologyProfile = this.ServiceDomainDetails.TechnologyProfile;
            item.TechnologyProfileDesc = this.ServiceDomainDetails.TechnologyProfileDesc;
            item.DeliveryUnit = this.ServiceDomainDetails.DeliveryUnit;
            item.DeliveryUnitDesc = this.ServiceDomainDetails.DeliveryUnitDesc;
            item.TowerType = this.ServiceDomainDetails.TowerType;
            item.TowerTypeDesc = this.ServiceDomainDetails.TowerTypeDesc;
            item.ServiceDomain = this.ServiceDomainDetails.ServiceDomain;
            item.ServiceDomainDesc = this.ServiceDomainDetails.ServiceDomainDesc;
          });


          this.dataTotalCount = this.DeliveryModelData.length;
        }
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting GetDealDetails.');
          this.IsLoading = false;
        });

    this.subscriptionList$.push(subscribe$);
  }

  InsertUpdateLSDeliveryLocationModelling(DeleteFlag: number, odata: any, rowSave: boolean = false) {
    if (DeleteFlag === 1) { // Use strict comparison operator
      odata[0].DeleteFlag = DeleteFlag;
    }

    odata.forEach((item: any) => {
      item.TechnologyProfile = this.ServiceDomainDetails.TechnologyProfile;
      item.TechnologyProfileDesc = this.ServiceDomainDetails.TechnologyProfileDesc;
      item.DeliveryUnit = this.ServiceDomainDetails.DeliveryUnit;
      item.DeliveryUnitDesc = this.ServiceDomainDetails.DeliveryUnitDesc;
      item.TowerType = this.ServiceDomainDetails.TowerType;
      item.TowerTypeDesc = this.ServiceDomainDetails.TowerTypeDesc;
      item.ServiceDomain = this.ServiceDomainDetails.ServiceDomain;
      item.ServiceDomainDesc = this.ServiceDomainDetails.ServiceDomainDesc;
    });

    odata = {
      'deliverylocationmodellinglist': odata
    };
    let subscribe$: Subscription = this.LotSolutionService.InsertUpdateLSDeliveryLocationModelling(odata)
      .subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          if (DeleteFlag === 1) { // Use strict comparison operator
            this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);
          }
          if (DeleteFlag === 2) { // Use strict comparison operator
            this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
          }

          if (rowSave) {
            this.GetLSDeliveryLocationModelling(true);
          } else {
            this.navigateToLocationMix();
            this.IsLoading = false;
          }

        }

      },
        err => {
          console.log('Error on getting GetDealDetails.');
          this.IsLoading = false;
        });

    this.subscriptionList$.push(subscribe$);
  }

  showNotificationMessage(msg: string, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  onClick(event: any) {
    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        if (event.icon.Tooltip == 'Save') {
          if (event.item.IsModified == 1) {
            if (this.validateGridFields(event.item)) {
              if (this.validateOffshoreCOUNT(this.DeliveryModelData)) {
                var DeliveryModel_odata = [];
                DeliveryModel_odata.push(event.item);
                this.InsertUpdateLSDeliveryLocationModelling(2, DeliveryModel_odata, true);
              }

              event.item.IsModified = 0;
            }
          }
          else {
            this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
          }
        }

        if (event.icon.Tooltip == 'Delete') {
          var DeliveryModel_odata = [];
          DeliveryModel_odata.push(event.item);
          this.InsertUpdateLSDeliveryLocationModelling(1, DeliveryModel_odata, true);
          this.dataTotalCount = this.dataTotalCount - 1;
        }
        break;
    }
  }

  onChange(event: any) {

    let selitem: any = event.item;
    selitem.IsModified = 1;
    let itemindex: any = event.item_index;
    switch (event.fieldname.toUpperCase()) {
      case 'LOCATIONTYPE':
        if (event.item.LocationType == 'ONSH') {
          if (!isNullOrUndefined(event.item.DeliveredFromRegion) &&
            !isNullOrUndefined(event.item.DeliveredToRegion) &&
            !isNullOrUndefined(event.item.DeliveredToCountry) &&
            !isNullOrUndefined(event.item.DeliveredFromCountry) &&
            !isNullOrUndefined(event.item.DeliveredToCityCategory) &&
            !isNullOrUndefined(event.item.DeliveredFromCityCategory)) {
            var DeliveryModel_odata = [];
            DeliveryModel_odata.push(event.item);
            this.InsertUpdateLSDeliveryLocationModelling(0, DeliveryModel_odata, true);
          }
        }
        break;
      case 'PRIMARYONSHORELOCATION':
        this.changeCellLockFlag(event.item, itemindex, 'DL_locationgrid');
        break;
    }
  }


  changeCellLockFlag(selitem: any, itemindex: any, gridName?: any) {
    let griditem = this.DeliveryModelGrid.filter((x: any) =>
      x.GridId === gridName);
    if (griditem.length > 0 && !isNullOrUndefined(griditem[0])) {
      // setTimeout(() => {

      let temp: any = [];
      temp.push(selitem);
      griditem[0].initCellLock(temp);
      griditem[0].refreshAllFieldsForRow(selitem, itemindex);

      // }, 50);

    }
  }
  validateGridFields(event: any): boolean {
    let valid: boolean = false;
    if (isNullOrUndefined(event.DeliveredFromRegion) ||
      isNullOrUndefined(event.DeliveredToCityCategory) ||
      isNullOrUndefined(event.DeliveredFromCountry) ||
      isNullOrUndefined(event.DeliveredToRegion) ||
      isNullOrUndefined(event.LocationType) ||
      isNullOrUndefined(event.DeliveredToCountry) ||
      isNullOrUndefined(event.DeliveredFromCityCategory)
    ) {
      this.toastr.warning("Please fill all mandatory fields");

      valid = false;
    }
    else {
      valid = true;
    }

    return valid;
  }

  validateOffshoreCOUNT(field: any): boolean {
    let disable_Flag = false;
    if (!isNullOrUndefined(field) && field.length > 0) {
      let OFFSH_ZEROCOUNT = field.filter((x: any) => x.LocationType == 'OFFSH');
      if (OFFSH_ZEROCOUNT.length > 1) {
        this.showNotificationMessage('Only one location can be selected as Offshore', 2, '', '', '', '', 10000);
        disable_Flag = false;
      }
      if (OFFSH_ZEROCOUNT.length == 1 || OFFSH_ZEROCOUNT.length == 0) {
        disable_Flag = true;
      }

    }


    return disable_Flag;
  }

  HelpIconClick() {

    this.openModal('SubOpenTooltip');
    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };


  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  validateServiceDomain(): boolean {
    if (this.ServiceDomainDetails.LSDeliveryLocationModellingId === 0) {
      if (isNullOrUndefined(this.ServiceDomainDetails.TowerType) || this.ServiceDomainDetails.TowerType === '') {
        this.toastr.warning('Please fill Tower Type');
        return false;
      }

      if (isNullOrUndefined(this.ServiceDomainDetails.ServiceDomain) || this.ServiceDomainDetails.ServiceDomain === '') {
        this.toastr.warning('Please fill Service Domain');
        return false;
      }
    }

    return true;
  }

  validateDeliveryModel(): boolean {
    const primaryOnshoreLocationCount = this.DeliveryModelData.filter((x: any) => x.PrimaryOnshoreLocation === 'POL001').length;
    let onshore_Data_exists = this.DeliveryModelData.filter((x: any) => x.LocationType === 'ONSH');
    if (!isNullOrUndefined(onshore_Data_exists) && onshore_Data_exists.length > 0) {

      if (primaryOnshoreLocationCount > 1) {
        this.toastr.warning('Only one location can be selected as Primary Onshore Location');
        return false;
      }

      if (primaryOnshoreLocationCount === 0) {
        this.toastr.warning('At least one location should be selected as Primary Onshore Location');
        return false;
      }

    }
    else {
      return true;
    }

    return true;

  }

  validateAllGridFields(): boolean {

    const modifiedItems = this.DeliveryModelData.filter((x: any) => x.IsModified === 1);

    for (const item of modifiedItems) {
      if (
        item.DeliveredFromRegion === null ||
        item.DeliveredToCityCategory === null ||
        item.DeliveredFromCountry === null ||
        item.DeliveredToRegion === null ||
        item.LocationType === null ||
        item.DeliveredToCountry === null ||
        item.DeliveredFromCityCategory === null
      ) {
        this.toastr.warning('Please fill all mandatory fields');
        return false;
      }
    }

    return true;
  }


  actionButtonClick(data: any): void {
    const name = data.Name?.toUpperCase();

    if (name !== undefined && name !== null) {
      if (name === 'SAVE') {
        // Action: Save Button
        if (this.validateServiceDomain() && this.validateDeliveryModel() && this.validateAllGridFields()) {
          const modifiedItems = this.DeliveryModelData.filter((x: any) => x.IsModified === 1);

          if (modifiedItems.length > 0) {
            this.InsertUpdateLSDeliveryLocationModelling(0, this.DeliveryModelData);
          } else {
            this.navigateToLocationMix();
          }
        }
      }





      else if (name === 'BACK') {
        // Action: Back Button

        const modifiedItems = this.DeliveryModelData.filter((x: any) => x.IsModified === 1);

        if (modifiedItems.length > 0) {
          this.showNotificationMessage('Are you sure you want to cancel unsaved data?',
            2, 'DELIVERYMODEL', null, 'Yes', 'No', 10000);
        } else {
          this.navigateToModelBaseline();
        }
      }
    }
  }
  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {
      case 'DELIVERYMODEL':
        if (event.result.toUpperCase() === 'YES') {
          this.navigateToModelBaseline();
        }
        break;
    }

  }

  setCellLock(fieldname: string, item: any, parent: DeliveryModelComponent): boolean {

    let bLock = false;
    let BlkCols: any[] = [];


    ////DeliveryModel///


    if (!isNullOrUndefined(parent.Properties.POLBlockCols) && parent.Properties.POLBlockCols.length > 0) {
      parent.Properties.POLBlockCols.forEach((blockitem: any) => {
        if (blockitem.Value == item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }

      });
    }

    if (!isNullOrUndefined(parent.Properties.Onshore_Blk) && parent.Properties.Onshore_Blk.length > 0) {
      parent.Properties.Onshore_Blk.forEach((blockitem: any) => {

        let onshore_Data_exists = parent.DeliveryModelData.filter((x: any) => x.LocationType === 'ONSH' && x.PrimaryOnshoreLocation === 'POL001');

        if (!isNullOrUndefined(onshore_Data_exists) && onshore_Data_exists.length > 0) {

          if ((item.LocationType !== 'ONSH')) {
            // blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push("PRIMARYONSHORELOCATION");
            // });
          } else if ((item.LocationType === 'ONSH' && item.PrimaryOnshoreLocation === 'POL002')) {
            BlkCols.push("PRIMARYONSHORELOCATION");
          }

        }
        else if (item.LocationType !== 'ONSH') {

          BlkCols.push("PRIMARYONSHORELOCATION");
          // blockitem.BlockCols.forEach((item: any) => {
          //   BlkCols.push(item);
          // });

        }
      });
    }








    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }
    return bLock;

  }




  private navigateToLocationMix(): void {
    const url = `app/lot-solution-modelling/LocationMix/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToModelBaseline(): void {
    const url = `app/model-config/ModelBaseline/${this.DealHeaderId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }


}
