
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LiteHomeService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;

  private P2wliteurl: any;

  private HomeURL: string;
  private DealURL: string;
  private ReportURL: string;

  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.HomeURL = environment.apiURL.HomeURL;
    this.DealURL = environment.apiURL.DealURL;
    this.ReportURL = environment.apiURL.ReportURL;
    this.P2wliteurl = environment.apiURL.ReportURL;

  }


  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  P2wliteGraphSummary(data: any): Observable<ProcessResponse<any>> {
    let odata =
    {
      "DealTerm": "3",
      "Ons_Country": 4,
      "Ons_Client_FTE": "5.60",
      "Ons_Client_Rate": "34.56",
      "Ons_Vendor_FTE": "12.45",
      "Ons_Vendor_Rate": "46",
      "Ons_CG_FTE": "67.12",
      "Ons_CG_Rate": "78.11",
      "Ons_Inflation": "22.56",
      "Off_Country": "45",
      "Off_Client_FTE": "67.34",
      "Off_Client_Rate": "66.12",
      "Off_Vendor_FTE": "55",
      "Off_Vendor_Rate": "456",
      "Off_CG_FTE": "22.67",
      "Off_CG_Rate": "45.67",
      "Off_Inflation": " 345",
      "Prd_Id": "6",
      "Prd_Custom": "23.67",
      "Loc_Ons_Sel": "0",
      "Loc_Ons_Country": "67",
      "Loc_Ons_Hub": "234",
      "Loc_Off_Sel": "0",
      "Loc_Nea_Sel": "0",
      "Loc_Nea_Country": "44",
      "Loc_Id": "0",
      "Pym_Ons_Id": "0",
      "Pym_Off_Id": "78",
      "Pym_Nea_Id": "88",
      "Mrg_Ons_Id": "56",
      "Mrg_Ons_Custom": "45.78",
      "Mrg_Off_Id": "34",
      "Mrg_Off_Custom": "99.45",
      "Mrg_Nea_Id": "77",
      "Mrg_Nea_Custom": "90",
      "Exp_Id": "67",
      "Exp_Custom": "33.78",
      "Gov_Id": "28",
      "Gov_Custom": "92",
      "Trn_Id": "56",
      "Trn_Custom": "112"
    }

    let Params = new HttpParams();
    // Params = Params.append('dealHeaderId', odata.DealHeaderId);
    // Params = Params.append('dealStatus', odata.DealStatus);

    return this._http.post<ProcessResponse<any>>(this.P2wliteurl + 'GetChartDetails', data,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  getSliderRange(codeType: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('codeType', codeType);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetCodeList',
      { headers: this.headers, params: Params });
    return response;

  }

  //http://localhost:7000/Deal/GetProductivityItems?Dealterm=5
  getProdItems(dealTerm: any) {
    let Params = new HttpParams();
    Params = Params.append('dealterm', dealTerm);


    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetProductivityItems',
      { headers: this.headers, params: Params });
    return response;
  }

  getLocationMixInfo(oncode: string, offcode: string, nearcode: string) {
    let Params = new HttpParams();
    Params = Params.append('onsite', oncode);
    Params = Params.append('offshore', offcode);
    Params = Params.append('nearshore', nearcode);


    let response = this._http.get<ProcessResponse<any>>(this.ReportURL + 'GetLocationMixInfo',
      { headers: this.headers, params: Params });
    return response;
  }

  //http://localhost:7000/Report/GetPyramidMixInfo?onshore=ONSH&offshore=OFFSH&Nearshore=NEARSH
  getPyrMixInfo(oncode: string, offcode: string, nearcode: string) {
    let Params = new HttpParams();
    Params = Params.append('onsite', oncode);
    Params = Params.append('offshore', offcode);
    Params = Params.append('Nearshore', nearcode);


    let response = this._http.get<ProcessResponse<any>>(this.ReportURL + 'GetPyramidMixInfo',
      { headers: this.headers, params: Params });
    return response;
  }

}
