import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environments/environment';
@Injectable()
export class CisMainPageService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private DealURL: string;
  constructor(private _http: HttpClient) {
    this.DealURL = environment.apiURL.DealURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  GetDataCisDashboardList(codeType: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('codeType', codeType);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDataCisDashboardList',
      { headers: this.headers, params: Params });
    return response;

  }
}
