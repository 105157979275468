
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { CustomPipeModule } from '../custompipe/custompipe.module';
import { RangeSliderComponent } from './range-slider.component';

//import { ModalInterface } from './modal.interface';

@NgModule({
  declarations: [
    RangeSliderComponent
  ],
  imports: [
    BrowserModule, FormsModule, TooltipModule,
    TranslateModule.forRoot(),
    CustomPipeModule, FilterPipeModule
  ],
 // providers: [ModalInterface],
  bootstrap: [RangeSliderComponent],
  exports: [RangeSliderComponent]
})

export class RangeSliderModule { }
