import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class AdminService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private UserURL: string;
  private LogURL: string;
  private ReportURL: string;

  constructor(private _http: HttpClient) {
    this.UserURL = environment.apiURL.UserManagement;
    this.LogURL = environment.apiURL.LogURL;
    this.ReportURL = environment.apiURL.ReportURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  getDetails(type: string,module:any): Observable<ProcessResponse<any>> {

    let methodName =
      type == "user" ? "GetUserRoleDetails" :
        type == "role" ? "GetRoleDetails" : "";

    let Params = new HttpParams();
    Params = Params.append('Module', module);

    return this._http.get<ProcessResponse<any>>(this.UserURL + methodName,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getDetailsById(type: string, Id: any): Observable<ProcessResponse<any>> {

    let methodName =
      type == "user" ? "GetUserRoleDetailsById" :
        type == "role" ? "GetRoleDetailsById" : "";

    let Params = new HttpParams();
    Params = Params.append('Id', Id);

    return this._http.get<ProcessResponse<any>>(this.UserURL + methodName,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDetails(type: string, odata: any): Observable<ProcessResponse<any>> {

    let methodName =
      type == "user" ? "UpdateUserRoleDetails" :
        type == "role" ? "UpdateRoleDetails" : "";

    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.UserURL + methodName, odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  delete(type: string, odata: any): Observable<ProcessResponse<any>> {

    let methodName =
    type == "user" ? "DeleteUserRole" :
      type == "role" ? "DeleteRole" : "";

    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.UserURL + methodName, odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getActivityLogDetails(Module:any): Observable<ProcessResponse<any>> {

    let Params = new HttpParams();
    // Params = Params.append('dealHeaderId', dealHeaderId);
    Params = Params.append('Module', Module);

    return this._http.get<ProcessResponse<any>>(this.LogURL + "GetActivityLog",
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getReportDetails(reportName:string): Observable<ProcessResponse<any>> {

    let Params = new HttpParams();
    Params = Params.append('reportName', reportName);

    return this._http.get<ProcessResponse<any>>(this.ReportURL + "GetReportDetails",
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getMenuDetails(parentId:any, roleId : any): Observable<ProcessResponse<any>> {

    let Params = new HttpParams();
    Params = Params.append('parentId', parentId);
    Params = Params.append('roleId', roleId);

    return this._http.get<ProcessResponse<any>>(this.UserURL + "GetMenuDetails",
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  saveRoleWiseMenuAuth(odata: any): Observable<ProcessResponse<any>> {

    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.UserURL + "SaveRoleWiseMenuAuth", odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

}
