import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from './shared/app.sharedservice';
import { environment } from '../environments/environment';
import { FieldTemplate, GridConst,Grid_Const } from '../modules/shared/_classes/utility.interface';
import { ComponentConfig, ProcessResponse, RowLockStatus } from './app.interface';
import { ComponentCanDeactivate } from './shared/app.can-deactivate.service';
import { NotificationData } from 'src/modules/notification/notification.interface';

export interface GenericService {
    // headers: any;
    // APIUrl: string;

    handleError(error: any): Promise<any>;

    getData(reqdata: any, CurrencyRate: any): Observable<ProcessResponse<any>>;
    saveData(odata: any, CurrencyRate: any, OtherData?: any): Observable<ProcessResponse<any>>;

}

export class GenericComponent<T> extends ComponentCanDeactivate {
    public _Template: FieldTemplate[] = [];
    public _Permission: any = [];
    public _Properties: any = [];
    public _Data: T[] = [];
    public _DataALL: T[] = [];
    public _DataTotalCount: number = 0;

    public _GridProp: any = {
        Editable: true,
        PaginationConfig: {
            // Required: true,
             ItemsPerPage: 25,
            // PageList: false,
            // ServerLoad: false
        },
        ActionBarConfig: {
            ActionBarList: null
        }
    };

    public _GridPropoffshoreVolume: any = {
        Editable: true,
        PaginationConfig: {
            // Required: true,
            // ItemsPerPage: 10,
            // PageList: false,
            // ServerLoad: false
        }
    };

    public _GridConfig: object = {
        BaseUrl: environment.baseURL
    };

    public _Action: any = {
        AddNew: false,
        Save: false,
        Delete: false,
        DefaultRow: false
    };

    public _Config: any = {
        BlockCols: [],
        DefaultRow: [],
        ActionButtons: []
    };

    public _SaveConfirm: boolean = false;
    public _IsReadOnly: boolean = false;
    public _IsProcessing: boolean = false;
    public IsProcessing$ = new Subject<boolean>();
    public _IsLoading: boolean = true;
    public _IsSaving: boolean = false;
    public _IsParallelEditEnabled: boolean = false;
    public _PEGroupName: string = '';
    public _PEUsers: string[] = [];
    // public _getReqData: any;
    public _UserID: any;
    private interval: any;
    private lastRefId: number = 0;
    public _CurrencyRate: any;
    public _DataInput: any;
    public ComponentTitle!: string;

    private ObjectType: any;
    public UniqueKeyName!: string;

    public _checkNewRowInd: number = -1;

    public displayNotifybox: boolean = false;
    public notify: NotificationData = new NotificationData();

    constructor(type: (new () => T), private _CommonAPIService: any, private _DataService: any, private _toastr: any) {
        super();

        this.ObjectType = type;
        this._IsParallelEditEnabled = environment.IsParallelEditEnabled;
    }

    initComponent(tmpltinput: ComponentConfig, datainput: object, currencyRate: any, compTitle: string, uniqueName: string) {
        let flag: boolean = true;
        this._CurrencyRate = currencyRate;
        this._DataInput = datainput;
        this._DataInput['PageIndex'] = 0;
        this._DataInput['PageSize'] = 0;

        // this._getReqData = Object.assign({}, datainput);
        this.ComponentTitle = compTitle;
        this.UniqueKeyName = uniqueName;
        this.getTemplate(tmpltinput);
    }

    settimer() {
        if (this._IsParallelEditEnabled && !this._IsReadOnly) {
            this.interval = setInterval(() => {
                this.refreshLock();
            }, 5000);
        }
    }

    getTemplate(odata: ComponentConfig) {
        this._Template = null as any;
        this._CommonAPIService.getComponentConfig(odata).subscribe((result: ProcessResponse<any>) => {
            if (result !== null && result !== undefined && result.Status === 200) {
                this._Template = result.Data.FieldTemplate;
                this._Permission = result.Data.Permission;
                this._Properties = result.Data.Properties;

                this.setCompProperties(result.Data.Properties);
                // set action based on user permission
                this.setActionProp(result.Data.Permission);

                if (!isNullOrUndefined(this._Template)) {
                    this.setTemplateReadOnly();
                    this.getData(true);
                }

                this.afterTemplateLoad(true);

            }
        },
            (err: any) => {
                console.log('Error on getting the FieldTemplate in Generic class.');
                this._toastr.error('Error on getting Component Config!', this.ComponentTitle);

                this.afterTemplateLoad(false);
            });

    }

    getData(bReload?: boolean) {
        this._IsLoading = true;

        this._DataService.getData(this._DataInput).subscribe((result: ProcessResponse<any>) => {
            if (result !== undefined && result !== null && result.Status === 200) {
                // Since pagewise data is fetched, data has to be appended
                // this._Data = result.Data;
                // result.Data = [];
                this._DataTotalCount = parseInt(result.Data.length);
                // reload all data irrespective of pagination
                if (!isNullOrUndefined(bReload) && bReload) {
                    this._Data = [];
                    this._DataALL = [];
                    this._checkNewRowInd = -1;
                }
                result.Data.forEach((x: any) => this._Data.push(x));
                console.log(JSON.stringify(this._Data));

                this._DataALL = this._Data;

                this._PEUsers = [];
                // to avoid callback for cell locking from fieldcontrol module
                this.initCellLock();
                if (this._IsParallelEditEnabled && !this._IsReadOnly) {
                    this.refreshLock();
                }

                if (this._checkNewRowInd >= 0) {
                    // move the newly added row to array end
                    this.moveData(this._Data.length, this._checkNewRowInd);
                }

                // reset new index value
                this._checkNewRowInd = -1;
            }
            else {
                console.log('Error in getting component data!');
            }

            console.log('Data count >> ' + this._Data.length);
            this.addDefaultRows();
            this.afterDataLoad(true);
            this._IsLoading = false;

        },
            (err: any) => {
                // this._Data = [];

                this._toastr.error('Error on getting Component data!', this.ComponentTitle);
                this.afterDataLoad(false);
                this._IsLoading = false;
            });
    }

    moveData(newIndex: number, oldIndex: number) {
        let item = this._Data[oldIndex];
        // add at newIndex
        this._Data.splice(newIndex, 0, item);
        // delete from oldIndex
        this._Data.splice(oldIndex, 1);
    }

    refreshLock(): any {
        if (!isNullOrUndefined(this._Data) && this._Data.length > 0) {
            this._Data.forEach((element: any) => {
                if (!isNullOrUndefined(element['UserLockBy'])) {
                    this.setElementLockStatus(element);
                }
            });
        }
    }

    setTemplateReadOnly() {
        if (this._IsReadOnly) {
            this._Template.forEach(item => item.Editable = 0);
        }
    }

    setActionProp(Permission: string[]) {
        if (!isNullOrUndefined(Permission) && Permission.length > 0
            && !isNullOrUndefined(this._Config.ActionButtons) && this._Config.ActionButtons.length > 0) {
            console.log(Permission);

            this._Action.AddNew = (Permission.indexOf('Manage') >= 0 ? true : false);
            this._Action.Save = (Permission.indexOf('Manage') >= 0 ? true : false);
            this._Action.Delete = (Permission.indexOf('Manage') >= 0 ? true : false);

            // Set permission other than Manage
            Permission.forEach(peritem => {
                if (peritem !== 'Manage') {
                    this._Action[peritem] = true;
                }
            });

            Permission.forEach(peritem => {
                if (peritem === 'Manage') {
                    // Manage permission sets 3 buttons - AddNewRow, Delete, Save
                    let actitems = this._Config.ActionButtons.filter((x: any) => x.Name.toLowerCase() === 'addnewrow' ||
                        x.Name.toLowerCase() === 'delete' || x.Name.toLowerCase() === 'save');

                    if (actitems.length > 0) {
                        actitems.forEach((itm: any) => itm.HasPermission = true);
                    }
                }
                else {
                    let actitem = this._Config.ActionButtons.filter((x: any) => x.Name.toLowerCase() === peritem.toLowerCase());

                    if (actitem.length > 0) {
                        actitem[0].HasPermission = true;
                    }
                }
            });

            this._GridProp.ActionBarConfig.ActionBarList = this._Config.ActionButtons;
        }
    }

    setCompProperties(Properties: any[]) {
        if (!isNullOrUndefined(Properties) && Properties.length > 0) {

            // Generic props

            // 1) Default field values
            this._Config.DefaultRow = (Properties.filter((x: any) => x.Name.toLowerCase() === 'DefaultRow'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'DefaultRow'.toLowerCase())[0].JSONData) : []);

            if (!isNullOrUndefined(this._Config.DefaultRow)) {
                this._Config.DefaultRow.forEach((element: any) => {
                    if (element.Refstatus === true) {
                        if (element.RefExp.includes('.')) {
                            let refexp: string[] = element.RefExp.split('.');
                            if (!isNullOrUndefined(refexp) && refexp.length > 0) {
                                let value: any = this;
                                refexp.forEach(item => {
                                    value = value[item];
                                });
                                element.Value = value;
                            }
                        }
                        else {
                            element.Value = (this as any)[element.RefExp];
                        }
                    }
                });

            }

            // 2) Blocking Cols based on custom values
            this._Config.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

                // this._Config.ToolBlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'ToolBlockCols'.toLowerCase()).length > 0 ?
                // JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'ToolBlockCols'.toLowerCase())[0].JSONData.toString()) : []);


            // 3) Pagination props
            this._GridProp.PaginationConfig = (Properties
                .filter((x: any) => x.Name.toLowerCase() === 'PaginationConfig'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'PaginationConfig'.toLowerCase())[0].JSONData) : null);

            if (!isNullOrUndefined(this._GridProp.PaginationConfig) &&
                !isNullOrUndefined(this._GridProp.PaginationConfig.ServerLoad) &&
                this._GridProp.PaginationConfig.ServerLoad) {

                this._DataInput['PageSize'] = isNullOrUndefined(this._GridProp.PaginationConfig.ItemsPerPage) ?
                   // GridConst.DEFAULTITEMSPERPAGE : this._GridProp.PaginationConfig.ItemsPerPage;
                    Grid_Const.DEFAULTITEMSPERPAGE : this._GridProp.PaginationConfig.ItemsPerPage;
            }

            // 4) ActionButtons field
            this._Config.ActionButtons = (Properties.filter((x: any) => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
                JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);


            // Other component specific props
            Properties.forEach(prop => {
                // avoiding generic props
                if (!isNullOrUndefined(prop.Name) &&
                    prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
                    && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
                    this._Config[prop.Name] = JSON.parse(prop.JSONData.toString());
                }
            });
        }
    }

    initCellLock(): void {
        // for each datarow
        this._Data.forEach((item: any, item_index) => {
            let itemcelllock: Array<any> = [];
            // for each fieldtemplate
            let temp: Array<any> = [this._Template.length];

            this._Template.forEach((key: FieldTemplate, key_index) => {
                let editcell = false;

                if (key.Editable && item['IsEditable']) {
                    // Check for cell level logic if defined in the template
                    if (isNullOrUndefined(key.DataCell) || isNullOrUndefined(key.DataCell?.CustomLock)) {
                        editcell = true;
                    }
                    else {
                        // if the cell is editable based on the row data and other custom conditions built in this component.
                        editcell = !this.checkCellLock(key.FieldName, item);
                    }
                }

                let temp1: any = { cell: key.FieldName, lock: !editcell };
                itemcelllock.push(temp1);

                // //let fld: string = key.FieldName;
                // temp[key.FieldName] = !editcell;
                // itemcelllock.push(temp);

            });

            // finally add the lock object to datarow
            item['CellLock'] = itemcelllock;
        });

    }

    checkCellLock(fieldname: string, item: T): boolean {
        let bLock = false;
        let BlkCols: any[] = [];

        // console.log(fieldname);
        if (!isNullOrUndefined(this._Config.BlockCols) && this._Config.BlockCols.length > 0) {
            this._Config.BlockCols.forEach((blockitem: any) => {
                if (blockitem.Value === (item as any)[blockitem.Field]) {
                    this._Config.BlockCols.forEach((Blk: any) => {
                        Blk.BlockCols.forEach((element: any) => {
                            BlkCols.push(element);
                        });
                    });
                }
            });
        }

        if (BlkCols.length > 0) {
            bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
        }

        return bLock;
    }

    addDefaultRows() {
        if (this._Action.AddNew === true && this._IsReadOnly === false) {
            let datalength: number = this._Data.length;
            if (datalength < 10) {
                for (let i = 0; i < (10 - datalength); i++) {
                    this.addNewRow(false);
                    // this.addNewRow(false);

                    // reset new index value since these rows are by system
                    this._checkNewRowInd = -1;
                }
            }
        }
    }

    addNewRow(bEmptyCheck?: boolean): void {
        // addNewRow(bFirst?: boolean): void {
        // bFirst = (isNullOrUndefined(bFirst)? true: bFirst);

        bEmptyCheck = (isNullOrUndefined(bEmptyCheck) ? true : bEmptyCheck);
        // Check to see if empty rows available. If so, then show message and do not add row.
        let emptyrows = this._Data.filter((x: any) => x['IsModified'] === 0 && x[this.UniqueKeyName] <= 0);

        if (emptyrows.length > 0 && bEmptyCheck) {
            this.showNotificationMessage('Please fill in the available empty row.', 2, '', null, '', '',1000);

            this.afterAddNewRow(null as any);
            return;
        }

        if (!isNullOrUndefined(this._Config) && !isNullOrUndefined(this._Config.DefaultRow) && this._Config.DefaultRow.length > 0) {
            let newitem: T = new this.ObjectType();

            this._Config.DefaultRow.forEach((item: any) => {
                // newitem[item.Field] = item.Value;
                (newitem as any)[item.Field] = item.Value;
            });

            // newitem['PageIndex'] = this._DataInput['PageIndex'];
            // newitem['PageIndex'] = this.getNewItemPageIndex();
            (newitem as any)['PageIndex'] = this.getNewItemPageIndex();
            // newitem = Object.assign(newitem, { 'PageIndex' : this.getNewItemPageIndex() });
console.log(newitem,  "++++");
            this._Data.push(newitem);
            console.log(this._Data,  "---------");
            if (!(!isNullOrUndefined(this._GridProp.PaginationConfig) &&
                !isNullOrUndefined(this._GridProp.PaginationConfig.ServerLoad) &&
                this._GridProp.PaginationConfig.ServerLoad)) {
                this._DataTotalCount++;
            }
            // if (bFirst) {
            //     this._Data.unshift(newitem);
            // }
            // else {
            //     this._Data.push(newitem);
            // }

            if (!isNullOrUndefined(this._GridProp.PaginationConfig) &&
                !isNullOrUndefined(this._GridProp.PaginationConfig.ServerLoad) &&
                this._GridProp.PaginationConfig.ServerLoad) {

                this._checkNewRowInd = (this._Data.length - 1);
            }

            this.afterAddNewRow(this._Data[this._Data.length - 1]);
        }
    }

    getNewItemPageIndex(): number {
        let newitempageind = 0;

        if (!isNullOrUndefined(this._GridProp.PaginationConfig) &&
            !isNullOrUndefined(this._GridProp.PaginationConfig.ServerLoad) &&
            this._GridProp.PaginationConfig.ServerLoad) {

            let totitems = this._DataTotalCount;
            let itemsperpage = 25;

            if (!isNullOrUndefined(this._GridProp.PaginationConfig) &&
                !isNullOrUndefined(this._GridProp.PaginationConfig.ItemsPerPage)) {
                itemsperpage = this._GridProp.PaginationConfig.ItemsPerPage;
            }

            let totpages = (totitems - (totitems % itemsperpage)) / itemsperpage;
            totpages = (totitems % itemsperpage) > 0 ? totpages + 1 : totpages;

            let lastpageitemscnt = totitems - ((totpages - 1) * itemsperpage);

            newitempageind = ((lastpageitemscnt + 1) > itemsperpage ? totpages + 1 : totpages);
            newitempageind--;

        }

        return newitempageind;
    }

    deleteRows(OtherData?: any): void {
        let deleteditems = this._Data.filter((x: any) => x['IsSelected'] === true);
        if (deleteditems.length > 0) {
            deleteditems.forEach((item: any) => {
                item['RowStatus'] = 'D';
                item['IsModified'] = 1;
            });

            // this.saveData(deleteditems.filter((x:any) => x['TransactionId'] > 0), 'Delete');
            this.saveData(deleteditems.filter((x: any) => x[this.UniqueKeyName] > 0), 'Delete', OtherData);

            for (let i = 0; i < deleteditems.length; i++) {
                let index = this._Data.indexOf(deleteditems[i]);
                this._Data.splice(index, 1);

                if (!(!isNullOrUndefined(this._GridProp.PaginationConfig) &&
                    !isNullOrUndefined(this._GridProp.PaginationConfig.ServerLoad) &&
                    this._GridProp.PaginationConfig.ServerLoad)) {
                    this._DataTotalCount--;
                }
            }
        }
        else {
            this._toastr.warning('Select atleast one row to delete', this.ComponentTitle);
        }

    }

    saveData(modifieditems: T[], ActionFlag: string, OtherData?: any, refreshflag: boolean = false,
        message: string = null as any): void {
        this._IsProcessing = true;
        this.IsProcessing$.next(this._IsProcessing);

        if (modifieditems !== null && modifieditems !== undefined && modifieditems.length > 0) {
            this._DataService.saveData(modifieditems, this._CurrencyRate, OtherData).subscribe((result: any) => {
                if (result !== null && result !== undefined && result.Status === 200) {
                    if (ActionFlag.toUpperCase() === 'SAVE') {
                        if (refreshflag === false) {
                            this.getData(true);
                            if (!isNullOrUndefined(message) && message.length > 0) {
                                this._toastr.success(message, this.ComponentTitle);
                            }
                            else {
                                this._toastr.success('Data saved successfully.', this.ComponentTitle);
                            }
                        }
                        else {
                            modifieditems.forEach(element => {
                                this.refreshRow(element);
                            });
                        }

                    } else if (ActionFlag.toUpperCase() === 'DELETE') {
                        this._toastr.success('Data deleted successfully.', this.ComponentTitle);
                        this.getData(true);
                    }
                    else if (result !== null && result !== undefined && result.Status === 202) {
                        this._toastr.warning(result.Message, this.ComponentTitle);
                    }
                    // Emitting after save event
                    //              this.resoAfterSave.emit({status: 1});
                    this.afterDataSave(true);

                    this._IsProcessing = false;
                    this.IsProcessing$.next(this._IsProcessing);

                    this._IsSaving = false;
                }
                else
                {
                    this._toastr.warning(result.Message);
                }
            },
                (err: any) => {
                    this._toastr.error('Error on saving data!', this.ComponentTitle);
                    this.afterDataSave(false);
                    this._IsProcessing = false;
                    this.IsProcessing$.next(this._IsProcessing);
                    this._IsSaving = false;
                });
        }
        else {
            this._IsProcessing = false;
            this.IsProcessing$.next(this._IsProcessing);
            this._IsSaving = false;
        }
    }

    setElementLockStatus(element: any) {
        if (!isNullOrUndefined(element['UserLockBy'])) {
            if (element['UserLockBy'] === this._UserID) {
                element['RowLockStatus'] = RowLockStatus.Edit;
            }
            else if (element['UserLockBy'] === null || element['UserLockBy'] === undefined) {
                element['RowLockStatus'] = RowLockStatus.None;
            }
            else {
                element['RowLockStatus'] = RowLockStatus.Locked;
            }

            if (!isNullOrUndefined(this._IsReadOnly) && this._IsReadOnly === false) {
                if (!isNullOrUndefined(this._PEUsers)) {
                    let index = this._PEUsers.findIndex(p => p === element['UserLockBy']);
                    if (index < 0) {
                        this._PEUsers.push(element['UserLockBy']);
                    }
                }
                else {
                    this._PEUsers = [];
                    this._PEUsers.push(element['UserLockBy']);
                }
            }
        }

        // return element;
    }

    copyData(destination: any, source: any) {
        Object.keys(source).forEach((key) => {
            destination[key] = source[key];

            // special handling for date fields
            let templtkey = this._Template.filter((x: any) => x['FieldName'] === key);
            if (templtkey.length > 0) {
                if (!isNullOrUndefined(templtkey[0].Control) && templtkey[0].Control?.Type.toLowerCase() === 'datepicker') {
                    destination[key] = (!isNullOrUndefined(source[key]) ? new Date(source[key]) : null);
                }
            }
        });
    }

    saveRow(modifieditem: T, OtherData?: any): void {
        this._IsProcessing = true;
        this.IsProcessing$.next(this._IsProcessing);

        if (modifieditem !== null && modifieditem !== undefined) {
            this._DataService.SaveRow(modifieditem, this._CurrencyRate, OtherData).subscribe((result: any) => {
                if (result !== null && result !== undefined && result.Status === 200) {
                    this.copyData(modifieditem, result.Data);
                }
                else if (result !== null && result !== undefined && result.Status === 202) {
                    this._toastr.warning(result.Message, this.ComponentTitle);
                }
                this.afterRowSave(true, modifieditem);
                this._IsProcessing = false;
                this.IsProcessing$.next(this._IsProcessing);
            },
                (err: any) => {
                    this._toastr.error('Error on saving data!', this.ComponentTitle);
                    this.afterRowSave(false, modifieditem);
                    this._IsProcessing = false;
                    this.IsProcessing$.next(this._IsProcessing);
                });
        }
        else {
            this._IsProcessing = false;
            this.IsProcessing$.next(this._IsProcessing);
        }
    }

    refreshRow(selitem: any): void {
        let _getReqData = Object.assign({}, this._DataInput);
        _getReqData[this.UniqueKeyName] = selitem[this.UniqueKeyName];

        this._DataService.getData(_getReqData, this._CurrencyRate).subscribe((result: any) => {
            if (result !== undefined && result !== null && result.Status === 200) {
                if (result.Data !== undefined && result.Data !== null && result.Data.length > 0) {
                    let index = this._Data.findIndex((p: any) => p[this.UniqueKeyName] === selitem[this.UniqueKeyName]);
                    if (index >= 0) {
                        this.copyData(this._Data[index], result.Data[0]);
                        this.afterRefreshRow(true, this._Data[index]);
                    }
                }

                // to avoid callback for cell locking from fieldcontrol module
                // single row???
                // this.InitCellLock();
            }
            else {
                this.afterRefreshRow(false, selitem);
                console.log('Error in getting component row data!');
            }

        },
            (err: any) => {
                this._toastr.error('Error in getting component row data!', this.ComponentTitle);
            });

    }

    async PEOnChange(selitem: any): Promise<boolean> {
        let res: any;
        if (this._IsParallelEditEnabled && !this._IsReadOnly) {
            let _getReqData = Object.assign({}, this._DataInput);

            //// ParallelEdit Check whether lock is acquired and is that elapsed to request for lock (SignalR)
            if (selitem[this.UniqueKeyName] > 0 && isNullOrUndefined(selitem.UserLockBy)) {
                _getReqData[this.UniqueKeyName] = selitem[this.UniqueKeyName];
                _getReqData['UserLockBy'] = this._UserID;
                res = await this._DataService.getData(_getReqData, this._CurrencyRate).subscribe((result: any) => {
                    if (result !== undefined && result !== null && result.Status === 200) {
                        if (result.Data !== undefined && result.Data !== null && result.Data.length > 0) {
                            let index = this._Data.findIndex((p: any) => p[this.UniqueKeyName] === selitem[this.UniqueKeyName]);
                            if (index >= 0) {
                                this.copyData(this._Data[index], result.Data[0]);
                                this.setElementLockStatus(this._Data[index]);
                            } // = result.Data[0];
                        }

                        // to avoid callback for cell locking from fieldcontrol module
                        this.initCellLock();
                    }
                    else {
                        console.log('Error in getting component data!');
                    }

                    console.log('Data count >> ' + this._Data.length);
                    return true;
                },
                    (err: any) => {
                        // this._Data = [];
                        this._toastr.error('Error on getting edit access', this.ComponentTitle);
                        return false;
                        // this.AfterDataLoad(false);
                        // this._IsLoading = false;
                    });
            }
            else if (selitem[this.UniqueKeyName] === 0 && isNullOrUndefined(selitem.UserLockBy)) { // New Row access
                selitem.RowLockStatus = RowLockStatus.Edit;
                selitem.UserLockBy = this._UserID;
                return true;
                // let defaultLock: DataRowlock = {
                //     UserId: 1,//this.UserAccessInfo.actinguserid,
                //     Name: 'Rakesh',//this._sharedService.UserInfo.name,
                //     RowLockStatus: RowLockStatus.Edit,
                //     GroupName: this._PEGroupName,
                //     ConnectionId: '',
                //     Data: '',
                //     LockedOn: new Date(),
                //     RowId: ''
                // };

                // selitem.DataRowlock = defaultLock;
            }
        } else {
            return true;
        }

        return res;
    }

    afterTemplateLoad(bStatus: boolean) {
        // Do nothing
    }

    afterDataLoad(bStatus: boolean) {
        // Do nothing
    }

    afterDataSave(bStatus: boolean) {
        // Do nothing
    }

    afterRowSave(bStatus: boolean, RowItem: T) {
        // Do nothing
    }

    afterRefreshRow(bStatus: boolean, RowItem: T) {
        // Do nothing
    }

    afterAddNewRow(RowItem: T) {
        // Do nothing
    }

    onPageChange(event: any) {

        this._DataInput['PageIndex'] = event.PageIndex;
        this._DataInput['PageSize'] = event.PageSize;

        // check if any data available already
        if (this._Data.length > 0) {
            // let temp = this._Data.filter((x:any) => x['PageIndex'] === event.PageIndex);
            let temp = this._Data.filter((x: any) => x['PageIndex'] === event.PageIndex && x[this.UniqueKeyName] > 0);

            if (!(temp.length > 0)) {
                this.getData();
            }
        }
    }

    canDeactivate(): boolean {
        // return true;
        return (this._Data.filter((x: any) => x['IsModified'] === 1).length > 0 ? false : true);
    }

    showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string,timeout:number) {
        let headerIcon: string = '';
    
        if (successFlag === 1) {
          headerIcon = 'fa fa-check-circle text-success';
        } else if (successFlag === 2) {
          headerIcon = 'fa fa-exclamation-circle text-warning';
        } else if (successFlag === 3) {
          headerIcon = 'fa fa-exclamation-triangle text-danger';
        } else {

            
          headerIcon = 'fa fa-info-circle text-info';
        }
    
        this.notify = {
          info: {
            header: 'Confirmation Alert',
            message: msg,
            yes: yes,
            no: no,
    
            IsHeaderHidden: true,
            HeaderIcon: headerIcon,
            IsCrossEnable: true,
            SizeClass: 'modal-sm'
          },
    
          action: action,
          item: item,
          timeout: timeout
        };
    
        this.displayNotifybox = true;
      }

}
