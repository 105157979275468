import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../../shared/app.sharedservice';
import { AppSharedService } from '../../shared/app.sharedservice';
import { JwtHelper } from './jwthelper';
import { ToastService } from 'src/app/shared/toast.service';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from './encrypt-decrypt.service';


@Injectable()
export class AuthenticationService {
    public BaseUrl!: string;
    public token: any;

    constructor(private http: HttpClient, private _appSharedService: AppSharedService,
        private toastr: ToastService, private encryptDecryptService: EncryptDecryptService) {

        // this.BaseUrl = environment.authURL + /Auth/;
        this.BaseUrl = environment.apiURL.IdentityURL;

        // console.log('Baseurl in auth service = ' + this.BaseUrl);
    }


    private async getToken(mailid: string, actingmailid?: string): Promise<any> {
        console.log('Calling getJwtToken');

        // // let headers = new HttpHeaders({
        // //     'Content-Type': 'application/json',
        // //     'grant_type': 'password',
        // // });

        // // headers = headers.append('UserMail', mailid);

        // // if (actingmailid !== undefined && mailid !== actingmailid) {
        // //     headers = headers.append('ActingUserMail', actingmailid);
        // // }

        // // const response = await this.http.get<any>(this.BaseUrl + 'GetToken',
        // //     {
        // //         headers: headers,
        // //         withCredentials: true
        // //     }).toPromise().catch(x => {
        // //         // this.toastr.warning('Access Denied', 'Login');
        // //     });
        // // return response;

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        let granttype = this.encryptDecryptService.encrypt('password');
        let UserMail = this.encryptDecryptService.encrypt(mailid.trim());
        let ActingUserMail = '';

        if (actingmailid !== undefined && mailid !== actingmailid) {
            ActingUserMail = this.encryptDecryptService.encrypt(actingmailid.trim());
        }

        let odata = {
            'grant_type': granttype,
            'UserMail': UserMail,
            'ActingUserMail': ActingUserMail
        }

        // let odata = {
        //     'grant_type': 'password',
        //     'UserMail': mailid,
        //     'ActingUserMail': ((actingmailid !== undefined && mailid !== actingmailid) ? actingmailid : '')
        // }

        const response = await this.http.post<any>(this.BaseUrl + 'GetToken', odata,
            {
                headers: headers,
                withCredentials: true
            }).toPromise().catch(x => {
                // this.toastr.warning('Access Denied', 'Login');
            });
        return response;

    }

    async getAPIToken(mailid: string, actingmailid?: string): Promise<boolean> {
        // console.log('login');
        // // Changing the localstorage to session as the switched user should not be persistent across sessions
        // // let currentEmail = localStorage.getItem('currentUser');
        // let currentEmail:any = sessionStorage.getItem('currentUser');
        // if (!isNullOrUndefined(currentEmail) && isNullOrUndefined(mailid)) {
        //     console.log('stored mail id ' + currentEmail);
        //     mailid = currentEmail;
        // }

        let res = await this.getToken(mailid, actingmailid);

        // console.log('getToken res >> ' + JSON.stringify(res));

        if (res) {
            // set token property
            this.token = res;
            // console.log('this.token = ' + JSON.stringify(this.token));
            // console.log('access_token = ' + this.token.access_token);

            let jwtHelper = new JwtHelper();
            let parsedToken = jwtHelper.decodeToken(this.token.access_token);

            // console.log('parsedToken = ' + JSON.stringify(parsedToken));
            this._appSharedService.setApiToken(this.token, parsedToken);
            // this._appSharedService.setUserInfo(parsedToken);

            // store username and jwt token in local storage to keep user logged in between page refreshes
            if (!isNullOrUndefined(mailid)) {
                // Changing the localstorage to session as the switched user should not be persistent across sessions
                // localStorage.setItem('currentUser', mailid);
                sessionStorage.setItem('currentUser', mailid!);
            }

            // return true to indicate successful login
            return true;
        } else {
            // return false to indicate failed login
            return false;
        }

    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem('currentUser');
    }

    checkTokenExpiry(): void {
        let userInfo = this._appSharedService.UserAccessInfo;
        this.logout();
        // get token
        this.getAPIToken(userInfo.email, userInfo.actingemail).then(result => {
            // console.log('refresh token >> ' + JSON.stringify(result));
        });
        // }
    }

}
