import { CommonModule, DatePipe } from '@angular/common';
// import { HttpClientModule } from '@angular/common/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
// import { DynamicComponentModule } from 'ng-dynamic';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { environment } from '../../environments/environment';
import { CustomPipeModule, LocalizedDatePipe, LocalizedDateTimePipe, LocalizedNumberPipe } from '../custompipe/custompipe.module';
import { ElioScopeService } from '../shared/_services/ElioScope.service';
import { UtilityService } from '../shared/_services/utility.service';
import { AutofocusDirective, FieldControlComponent, IconButtonComponent } from './fieldcontrol.component';
import { FieldControlService } from './fieldcontrol.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './customdatepicker.service';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    IconButtonComponent
  ],
  exports: [
    IconButtonComponent
  ]
})
export class IconButtonModule { }


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    // HttpClientModule,
    HttpClientModule,
    FormsModule,
    // CustomModalModule,	// This code is only for Milkyway
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    NgSelectModule,
    NgbModule,
    EditorModule,
    TranslateModule.forRoot(),
    // DynamicComponentModule.forRoot({
    //   imports: [CustomPipeModule, IconButtonModule]
    // }),
    CustomPipeModule
  ],
  providers: [FieldControlService, UtilityService, ElioScopeService,
    LocalizedDatePipe, LocalizedDateTimePipe, LocalizedNumberPipe, NgbDateCustomParserFormatter,
    DatePipe,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  declarations: [FieldControlComponent, AutofocusDirective],
  exports: [FieldControlComponent]
})

export class FieldControlModule { }

