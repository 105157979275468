<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <h1 class="sr-only">Admin Reports</h1>
            <ul class="breadcrumb m-0 flex-fill">
                <li class="breadcrumb-item" aria-current="page">Admin</li>
                <li class="breadcrumb-item active" aria-current="page">Reports</li>
            </ul>
        </div>
    </nav>

    <!-- <div class="contentScroll p-3 simpleCard myDealCard">
        <div class="card h-100 m-0">
            <div class="card-header">
                <h3>
                    <span>{{Title}}</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">
                <div class="gridContainer">
                    <ng-container
                        *ngIf="(ListTemplate != null && ListTemplate != undefined && ListTemplate.length > 0)">

                        <grid-dispctrl #overallgrid [FieldTemplate]="ListTemplate" [GridData]="ListData"
                            [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                            (ActionBarClick)="actButtonClick($event)">
                        </grid-dispctrl>

                    </ng-container>
                </div>
            </div>
        </div>
    </div> -->

    <div class="p-2 contentScroll full simpleCard">
        <div class="card-body overflow-auto">
            <div class="p-2 contentScroll full simpleCard">
                <ng-container
                    *ngIf="(Config.ReportTabs != null && Config.ReportTabs != undefined 
                                        && Config.ReportTabs.length > 0
                                        && (Config.ReportTabs | filterBy: {HasPermission: true,CanShow: true}).length > 0)">
                                        <div class="OP-tabs">
                    <tabset [tabList]="(Config.ReportTabs | filterBy: {HasPermission: true,CanShow: true})"
                        (selected)="setSelectedTab($event)">
                        <ng-container
                            *ngFor="let tab of (Config.ReportTabs | filterBy: {HasPermission: true,CanShow: true})">

                            <ng-container *ngIf="selectedTab==tab.Name">

                                <ng-container *ngIf="(ListTemplate != null && ListTemplate != undefined && ListTemplate.length > 0 
                                            && (ListTemplate | filterBy: {Group: tab.Name}).length>0)">

                                    <grid [FieldTemplate]="(ListTemplate | filterBy: {Group: tab.Name})"
                                        [GridData]="ListData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (ActionBarClick)="actButtonClick($event)"
                                        (FieldClick)="onClick($event)" [parent]="this">
                                    </grid>

                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tabset>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
    <filter *ngIf="(ListTemplate!= undefined && ListTemplate!= null && ListTemplate.length > 0)"
        [FieldTemplate]="(ListTemplate | filterBy: {Group: selectedTab})" [GridData]="ListDataALL" (close)="showFilter($event)"
        (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
    </filter>
</div>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>