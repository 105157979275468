import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from 'src/app/app.interface';
import { environment } from 'src/environments/environment';


@Injectable()
export class RegistryService {

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    private APIUrl: string;

    constructor(private _http: HttpClient) {
       this.APIUrl = environment.apiURL.LoginURL;
    }

    private handleError(error: any): Promise<any> {
        console.error('Error : ', error.message);
        return Promise.reject(error.message || error);
    }


    // saveRegisterDetail(odata: any): Observable<ProcessResponse<any>> {
    //     let Params = new HttpParams();

    //     return this._http.post<ProcessResponse<any>>(this.APIUrl + 'RegisterExternalLogin', odata,
    //         { headers: this.headers, params: Params })
    //         .pipe(
    //             catchError(this.handleError)
    //         );
    // }

    generateOTP(odata: any): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        return this._http.post<ProcessResponse<any>>(this.APIUrl + 'GenerateOTP', odata,
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    validateOTP(odata: any): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        return this._http.post<ProcessResponse<any>>(this.APIUrl + 'ValidateOTP', odata,
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }


}
