import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class AMChartsService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private ReportUrl: string;
  private ServiceUrl: string;
  constructor(private _http: HttpClient) {
    this.ReportUrl = environment.apiURL.ReportURL;
    this.ServiceUrl = environment.apiURL.ServiceTypeURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }


  GetModelSolutionStaffingView(odata: any) {

    let Params = new HttpParams();
    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('ModelId', odata.ModelId);
    Params = Params.append('ReportType', odata.ReportType);
    Params = Params.append('OutputType', odata.OutputType);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetModelSolutionStaffingView',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }





}

