// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../../shared/app.sharedservice';

import { Observable } from 'rxjs';

import { AppSharedService } from '../../shared/app.sharedservice';
import { environment } from 'src/environments/environment';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    private token: any;
    constructor(public _appSharedService: AppSharedService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let sameDomain = environment.baseURL;

        // Check for same domain
        if (request.url.indexOf(sameDomain) >= 0) {

            // avoid adding token for the GetToken API or Language resource file
            if (request.url.indexOf('GetToken') < 0 && request.url.indexOf('GetLanguage') < 0 && request.url.indexOf('GetUserRoleList') < 0 && request.url.indexOf('i18n') < 0) {
                this.token = this._appSharedService.ApiToken;
                let lang = (isNullOrUndefined(this._appSharedService.selectedLang) ? 'en-US' : this._appSharedService.selectedLang);
                let roleId = (isNullOrUndefined(this._appSharedService.currentRoleData) ? '0' : this._appSharedService.currentRoleData.RoleId.toString());

                request = request.clone({
                    setHeaders: {
                        'X-Frame-Options': 'DENY',
                        'X-XSS-Protection': '1; mode=block',
                        'X-Content-Type-Options': 'nosniff',
                        'Content-Security-Policy': "frame-ancestors 'none'",
                        'X-Permitted-Cross-Domain-Policies': 'none',
                        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
                        'Referrer-Policy': 'no-referrer',
                        'Permission-Policy': "geolocation 'none';midi 'none';notifications 'none';push 'none';sync-xhr 'none';microphone 'none';camera 'none';magnetometer 'none';gyroscope 'none';speaker 'self';vibrate 'none';fullscreen 'self';payment 'none';",
                        'Feature-Policy': "accelerometer 'none'; camera 'none'; geolocation 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; payment 'none';",
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                        Authorization: (isNullOrUndefined(this.token) ? `` : `Bearer ${this.token.access_token}`),
                        Language: lang,
                        CurrentRole: roleId,


                    }
                });

            }
        }

        return next.handle(request);
    }
}