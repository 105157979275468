
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ProcessStatus } from '../app.interface';
import { faqsService } from './faqs.service';
import { BrowserModule,Title } from '@angular/platform-browser';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FAQSComponent implements OnInit {
  @ViewChild('accordion1') accordion: NgbAccordion | undefined;
  subscriptionList$: any;
  faqs: any = [];
  constructor(private faqsService: faqsService) { }

  ngOnInit(): void {
    this.GetCIS_FAQs();
  }


  accordianclick(event: any) {
    console.log(event);
    switch (event.toUpperCase()) {

    }
  }
  GetCIS_FAQs() {
    let subscribe$: Subscription = this.faqsService.GetCIS_FAQs(1).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.faqs = result.Data;
        console.log(result);

      }

    },
      err => {
        console.log('Error on getting GetDealDetails.');

      });

    this.subscriptionList$.push(subscribe$);
  }
}

