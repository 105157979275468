import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReportModel } from '../app.interface';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { isNullOrUndefined } from '../shared/app.sharedservice';

@Component({
  selector: 'app-am-charts',
  templateUrl: './am-charts.component.html',
  styleUrls: ['./am-charts.component.css']
})
export class AMChartsComponent implements OnInit {
  @ViewChild('chartElement') chartElement: ElementRef<HTMLElement> | undefined;
  @Input() item: any = {};
  @Input() chartPlotName: any;
  @Input() ReportType: any;
  @Input() ModuleName: any;
  @Input() TabName: any;
  public graph_DATA: any;
  public graph_DATAHEADING: any;
  // public graphPlotid: any = '';
  private rootbar!: am5.Root;
  private rootline!: am5.Root;
  private rootbarstacked!: am5.Root;
  private rootbarlinemix!: am5.Root;
  constructor() { }

  ngOnInit(): void {

  }
  ngOnChanges() {
    this.graph_DATA = this.item.ChartOutput;
    this.graph_DATAHEADING = this.item.GraphHeading;
    console.log(this.item.ChartOutput);

    // Deal: any = 'AMRT001';
    // Tower: any = 'AMRT002';
    // SubTower: any = 'AMRT003';
    // Country: any ='AMRT004';
    // Lotwise: any ='AMRT005';

    if (this.ModuleName == ReportModel.Solution_summary) {
      if (this.TabName == 'ModelSolutionStaffingView' || this.TabName == 'PyramidView')
        switch (this.ReportType) {
          case 'AMRT001':
            this.getBarGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;
          case 'AMRT002': case 'AMRT003': case 'AMRT004': case 'AMRT005':
            this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;

          default:
            break;
        }


      if (this.TabName == 'LocationRatio') {
        switch (this.ReportType) {
          case 'AMRT001': // AMRT004 -- data should not come
            this.getBarstackedGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;
          // case 'AMRT002': case 'AMRT003': case 'AMRT004': case 'AMRT005':
          //   // this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
          //   break;

          default:
            break;
        }
      }

      if (this.TabName == 'Productivity') {
        switch (this.ReportType) {
          case 'AMRT001': case 'AMRT002': case 'AMRT003': case 'AMRT005': //case 'AMRT004': no need data and chart
            this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;
          default:
            break;
        }
      }

    } else if (this.ModuleName == ReportModel.Pricing_summary) {
      if (this.TabName == 'PriceSummary') {
        switch (this.ReportType) {
          case 'AMRT001':
            this.getBarLineGraphMix(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;
          case 'AMRT002': case 'AMRT003': case 'AMRT004': case 'AMRT005':
            this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;

          default:
            break;
        }
      } else if (this.TabName == 'PriceSummaryBlendedRate' || this.TabName == 'PriceSummaryCMPer') {
        switch (this.ReportType) {
          case 'AMRT001': case 'AMRT002': case 'AMRT003': case 'AMRT004': case 'AMRT005':
            this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
            break;
          default:
            break;
        }
      }
    }
    
    else if (this.ModuleName == ReportModel.Business_summary) {
      this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);

    }
    else if (this.ModuleName == ReportModel.modelcomparison) {
      this.graph_DATAHEADING = this.item.GraphHeading;
    //  this.getSeriesGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
      this.getSeriesGraph_mc(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
    }


    else if (this.ModuleName == ReportModel.PastDealComparison) {
      this.getSeriesGraph_pd(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
  
    }

    else if(this.ModuleName==ReportModel.whatifswithadmt){
      if(!isNullOrUndefined(this.item.ChartOutput)){
        this.getcolumnclusteredGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName);
      }
    }
    //   this.getBarGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName)
  }

  ngAfterViewInit() {
    console.log(this.item);
  }


  maybeDisposeRoot(divId: any) {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id == divId) {
        root.dispose();
      }
    });
  }

  getBarGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {

    setTimeout(() => {
      this.maybeDisposeRoot("chartdiv");

      // setTimeout(() => {
      let root = am5.Root.new("chartdiv");
      // let root = am5.Root.new(this.GraphName);
  
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
  
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
      }));
  
      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);
  
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15
      });
  
      xRenderer.grid.template.setAll({
        location: 1
      })
  
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
  
      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));
  
  
      // Create series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: Yfield,
        sequencedInterpolation: true,
        categoryXField: Xfield,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      }));
  
      series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
      series.columns.template.adapters.add("fill", function (fill, target) {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      });
  
      series.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      });
  
  
      // Set data
      let data = null;
      data = graphData;
  
      xAxis.data.setAll(data);
      series.data.setAll(data);
  
  
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);
      chart.appear(1000, 100);
      // }, 2000);

    }, 1000);


  
  }

  getSeriesGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv");
    setTimeout(() => {
     

      // setTimeout(() => {
      let root = am5.Root.new("chartdiv");
  
      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
  
        layout: root.verticalLayout
      }));
  
      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      data = graphData;
  
  
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
  
      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });
  
      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));
  
      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
  
        })
      );
  
      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });
  
  
  
  
  
      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  
      // series.data.setAll(data);
  
  
      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })
          })
        );
  
  
  
        series.data.setAll(data);
  
  
  
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });
  
      }
  
      //  createSeries("Europe", "europe");
  
  
  
      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");
  
  
      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }
  
  
      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;
  
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
  

    }, 1000);


  
  }

  getBarstackedGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    setTimeout(() => {
  // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
   // if(this.ModuleName != ReportModel.modelcomparison){
    this.maybeDisposeRoot("chartdiv");
    let root = am5.Root.new("chartdiv");
    
  // Set themes
  // https://www.amcharts.com/docs/v5/concepts/themes/
  root.setThemes([
    am5themes_Animated.new(root)
  ]);


  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  let chart: any = null;
  chart = root.container.children.push(am5xy.XYChart.new(root, {
    panX: false,
    panY: false,
    wheelX: "panX",
    wheelY: "zoomX",
    layout: root.verticalLayout
  }));

  // Add scrollbar
  // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
  chart.set("scrollbarX", am5.Scrollbar.new(root, {
    orientation: "horizontal"
  }));
  let data = graphData;

  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  let xRenderer = am5xy.AxisRendererX.new(root, {});
  let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
    categoryField: Xfield,
    renderer: xRenderer,
    cellStartLocation: 0.2,
    cellEndLocation: 0.8,
    tooltip: am5.Tooltip.new(root, {})
  }));

  xRenderer.grid.template.setAll({
    location: 1
  })

  xAxis.data.setAll(data);

  let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
    min: 0,
    max: 100,
    numberFormat: "#'%'",
    strictMinMax: true,
    calculateTotals: true,
    renderer: am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    })
  }));


  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  let legend = chart.children.push(am5.Legend.new(root, {
    centerX: am5.p50,
    x: am5.p50
  }));

  var splitted: any[] = [];
  splitted = Yfield.split(" ,");

  // Add series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  function makeSeries(name: any, fieldName: any) {
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: fieldName,
      categoryXField: Xfield,
      tooltip: am5.Tooltip.new(root, {
        labelText: "{name} in {categoryX}: {valueY} "
      })
    }));

    series.columns.template.setAll({
      tooltipText: "{name}, {categoryX}: {valueY}",
      tooltipY: am5.percent(100)
    });
    series.data.setAll(data);
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear();

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          text: "{valueY}",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true
        })
      });
    });

    legend.data.push(series);
  }
  let temp = [];

  temp = splitted[0].split(',');
  // for(let i = 0; i < temp.length; i++) {
  //   temp[i].replace(" - ", "");
  // }

  for (let i = 0; i < temp.length; i++) {
    makeSeries(temp[i], temp[i]);
  }

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  chart.appear(1000, 100);
  this.rootbarstacked = root;


  let exporting = am5plugins_exporting.Exporting.new(root, {
    menu: am5plugins_exporting.ExportingMenu.new(root, {
      deactivateRoot: false
    })
  });
 
  
 


    }, 1000);
 
 
  
    // setTimeout(() => {
  



  }

  getSeriesGraph_mc(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdivmodelcomparison");
    setTimeout(() => {
     

      // setTimeout(() => {
      let root:any=null;
      root = am5.Root.new("chartdivmodelcomparison");
  
      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
  
        layout: root.verticalLayout
      }));
  
      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      data = graphData;
  
  
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
  
      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });
  
      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));
  
      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
  
        })
      );
  
      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });
  
  
  
  
  
      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  
      // series.data.setAll(data);
  
  
      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })
          })
        );
  
  
  
        series.data.setAll(data);
  
  
  
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });
  
      }
  
      //  createSeries("Europe", "europe");
  
  
  
      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");
  
  
      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }
  
  
      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;
  
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
  

    }, 1000);


  
  }

  getSeriesGraph_pd(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartpdcomparison");
    setTimeout(() => {
     

      // setTimeout(() => {
      let root:any=null;
      root = am5.Root.new("chartpdcomparison");
  
      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
  
        layout: root.verticalLayout
      }));
  
      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      data = graphData;
  
  
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));
  
      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });
  
      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));
  
      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
  
        })
      );
  
      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });
  
  
  
  
  
      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  
      // series.data.setAll(data);
  
  
      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })
          })
        );
  
  
  
        series.data.setAll(data);
  
  
  
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });
  
      }
  
      //  createSeries("Europe", "europe");
  
  
  
      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");
  
  
      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }
  
  
      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;
  
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
  

    }, 1000);


  
  }
//   getBarstackedGraph_modelcomparison(graphName: any, graphData: any, Xfield: any, Yfield: any) {
   
//     this.maybeDisposeRoot("chartdivmodelcomparison");
//     setTimeout(() => {
//       let root:any = null;
//  // Create root element
//     // https://www.amcharts.com/docs/v5/getting-started/#Root_element
  
//      root = am5.Root.new("chartdivmodelcomparison");
    
//   // Set themes
//   // https://www.amcharts.com/docs/v5/concepts/themes/
//   root.setThemes([
//     am5themes_Animated.new(root)
//   ]);


//   // Create chart
//   // https://www.amcharts.com/docs/v5/charts/xy-chart/
//   let chart: any = null;
//   chart = root.container.children.push(am5xy.XYChart.new(root, {
//     panX: false,
//     panY: false,
//     wheelX: "panX",
//     wheelY: "zoomX",
//     layout: root.verticalLayout
//   }));

//   // Add scrollbar
//   // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
//   chart.set("scrollbarX", am5.Scrollbar.new(root, {
//     orientation: "horizontal"
//   }));
//   let data = graphData;

//   // Create axes
//   // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
//   let xRenderer = am5xy.AxisRendererX.new(root, {});
//   let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
//     categoryField: Xfield,
//     renderer: xRenderer,
//     cellStartLocation: 0.2,
//     cellEndLocation: 0.8,
//     tooltip: am5.Tooltip.new(root, {})
//   }));

//   xRenderer.grid.template.setAll({
//     location: 1
//   })

//   xAxis.data.setAll(data);

//   let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
//     min: 0,
//     max: 100,
//     numberFormat: "#'%'",
//     strictMinMax: true,
//     calculateTotals: true,
//     renderer: am5xy.AxisRendererY.new(root, {
//       strokeOpacity: 0.1
//     })
//   }));


//   // Add legend
//   // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
//   let legend = chart.children.push(am5.Legend.new(root, {
//     centerX: am5.p50,
//     x: am5.p50
//   }));

//   var splitted: any[] = [];
//   splitted = Yfield.split(" ,");

//   // Add series
//   // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
//   function makeSeries(name: any, fieldName: any) {
//     let series = chart.series.push(am5xy.ColumnSeries.new(root, {
//       name: name,
//       stacked: true,
//       xAxis: xAxis,
//       yAxis: yAxis,
//       valueYField: fieldName,
//       categoryXField: Xfield,
//       tooltip: am5.Tooltip.new(root, {
//         labelText: "{name} in {categoryX}: {valueY} "
//       })
//     }));

//     series.columns.template.setAll({
//       tooltipText: "{name}, {categoryX}: {valueY}",
//       tooltipY: am5.percent(100)
//     });
//     series.data.setAll(data);
//     // Make stuff animate on load
//     // https://www.amcharts.com/docs/v5/concepts/animations/
//     series.appear();

//     series.bullets.push(function () {
//       return am5.Bullet.new(root, {
//         sprite: am5.Label.new(root, {
//           text: "{valueY}",
//           fill: root.interfaceColors.get("alternativeText"),
//           centerY: am5.p50,
//           centerX: am5.p50,
//           populateText: true
//         })
//       });
//     });

//     legend.data.push(series);
//   }
//   let temp = [];

//   temp = splitted[0].split(',');
//   // for(let i = 0; i < temp.length; i++) {
//   //   temp[i].replace(" - ", "");
//   // }

//   for (let i = 0; i < temp.length; i++) {
//     makeSeries(temp[i], temp[i]);
//   }

//   // Make stuff animate on load
//   // https://www.amcharts.com/docs/v5/concepts/animations/
//   chart.appear(1000, 100);
//   this.rootbarstacked = root;


//   let exporting = am5plugins_exporting.Exporting.new(root, {
//     menu: am5plugins_exporting.ExportingMenu.new(root, {
//       deactivateRoot: false
//     })
//   });
 
 

//   // setTimeout(() => {

//     }, 1000);
   
   
   
   
  



//   }

  getBarLineGraphMix(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    setTimeout(() => {
      this.maybeDisposeRoot("chartdiv");
      /* Chart code */
  
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      let root = am5.Root.new("chartdiv");
  
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);
  
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout
        })
      );
  
      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal"
        })
      );
  
      // let data = [
      //   {
      //     year: "2016",
      //     income: 23.5,
      //     expenses: 21.1
      //   },
      //   {
      //     year: "2017",
      //     income: 26.2,
      //     expenses: 30.5
      //   },
      //   {
      //     year: "2018",
      //     income: 30.1,
      //     expenses: 34.9
      //   },
      //   {
      //     year: "2019",
      //     income: 29.5,
      //     expenses: 31.1
      //   },
      //   {
      //     year: "2020",
      //     income: 30.6,
      //     expenses: 28.2,
      //     strokeSettings: {
      //       stroke: chart.get("colors")?.getIndex(1),
      //       strokeWidth: 3,
      //       strokeDasharray: [5, 5]
      //     }
      //   },
      //   {
      //     year: "2021",
      //     income: 34.1,
      //     expenses: 32.9,
      //     columnSettings: {
      //       strokeWidth: 1,
      //       strokeDasharray: [5],
      //       fillOpacity: 0.2
      //     },
      //     info: "(projection)"
      //   }
      // ];
      let data: any = null;
      data = graphData;
  
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {});
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "Year",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        })
      );
      xRenderer.grid.template.setAll({
        location: 1
      })
  
      xAxis.data.setAll(data);
  
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          extraMax: 0.1,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
          })
        })
      );
  
  
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  
      // Create series
      function createSeries(name: any, field: any) {
  
  
        if (name == 'Price to Client') {
          let series_C = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: field,
              categoryXField: Xfield,
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{name} in {categoryX}: {valueY} {info}"
              })
            })
          );
  
          series_C.columns.template.setAll({
            tooltipY: am5.percent(10),
            templateField: "columnSettings"
          });
  
          series_C.data.setAll(data);
        } else {
          let series = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: field,
              categoryXField: Xfield,
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{name} in {categoryX}: {valueY} {info}"
              })
            })
          );
  
  
          series.strokes.template.setAll({
            strokeWidth: 3,
            templateField: "strokeSettings"
          });
  
  
          series.data.setAll(data);
  
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                strokeWidth: 3,
                stroke: series.get("stroke"),
                radius: 5,
                fill: root.interfaceColors.get("background")
              })
            });
          });
  
        }
  
  
  
      }
  
      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");
  
      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }
  
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
  
      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );
      legend.data.setAll(chart.series.values);
  
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootbarlinemix = root;
  
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });

    }, 1000);
 
  
  }



  getcolumnclusteredGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv_ADMTthirdreport");
    setTimeout(() => {

      let root: any = null;
      /* Chart code */
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      root = am5.Root.new("chartdiv_ADMTthirdreport");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );
      console.log(graphData);
      let data = graphData
      
      
    //   [
    //     {
    //         "Model": "CHE398715",
    //         "Onshore": 19745.85,
    //         "Nearshore": 0
    //     },
    //     {
    //         "Model": "CHE398716",
    //         "Onshore": 19745.85,
    //         "Nearshore": 0
    //     }
    // ]
      //graphData;


    


      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      })

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        tooltipText: "{name}, {categoryX}:{valueY}",
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
          
        }),
        calculateTotals: true,
      }));
      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
          
        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });



        // series.columns.template.setAll({
        //   tooltipText: "{name}, {categoryX}:{valueY}",
        //   width: am5.percent(90),
        //   tooltipY: 0,
        //   showTooltipOn: "always"
        // });
        
        // series.columns.template.setup = function(target: { set: (arg0: string, arg1: any) => void; }) {
        //   target.set("tooltip", am5.Tooltip.new(root, {}));
        // }
        
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: 0,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }




      // makeSeries("Europe", "europe");
      // makeSeries("North America", "namerica");
      // makeSeries("Asia", "asia");
      // makeSeries("Latin America", "lamerica");
      // makeSeries("Middle East", "meast");
      // makeSeries("Africa", "africa");


      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
    }, 1000);
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    //  this.browserOnly(() => {
    if (this.rootbar) {
      this.rootbar.dispose();
    }
    if (this.rootline) {
      this.rootline.dispose();
    }
    if (this.rootbarstacked) {
      this.rootbarstacked.dispose();
    }

    if (this.rootbarlinemix) {
      this.rootbarlinemix.dispose();
    }

  }

}
