<ng-container *ngIf="ModuleName =='CIS' || ModuleName =='AM'">
    <div class="h-100">
        <nav aria-label="breadcrumb" class="breadcrumbSection">
            <div class="d-flex w-100 justify-content-between">
                <h1 class="sr-only">Deal Parameters</h1>
                <h2 class="sr-only">Deal Parameters</h2>
                <ng-container *ngIf="completedDeal == false || ModuleName == 'AM'">
                    <ul class="breadcrumb m-0 flex-fill">
                        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                        </li>
                        <ng-container *ngIf="ModuleName == 'CIS'"> <li class="breadcrumb-item active" aria-current="page">Deal Parameters</li></ng-container>
                        <ng-container *ngIf="ModuleName == 'AM'"> <li class="breadcrumb-item active" aria-current="page">Deal Configuration</li></ng-container>
                       
                    </ul>

                    <ul class="flex-fill m-0 navbar-nav p-3 breadcrumb" *ngIf="ModuleName =='AM'">
                        <li>
                            <span class="progress headerProgress ms-auto">
                                <span *ngIf="dealProgressMeter!=0" class="progress-bar bg-warning headerProgressBar" [ngStyle]="{'width':dealProgressMeter+'%'}">{{dealProgressMeter}}%</span>
                                <span *ngIf="dealProgressMeter==0" class="progress-bar bg-secondary headerProgressBar" style="width: 100%;text-align:left;">0%</span>
                            </span>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="completedDeal && ModuleName != 'AM'">
                    <ul class="breadcrumb m-0 flex-fill">

                        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page"><a
                                routerLink="/app/create-deal/{{dealHeaderId}}">Deal
                                Parameters</a></li>
                        <li class="breadcrumb-item" aria-current="page"><a href="javascript:void(0);"
                                (click)="navigatetoservicepage()">Services</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            <a href="javascript:void(0);" (click)="navigatetoreportspage()">Output Screens</a>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </nav>

        <div class="contentScroll p-3 simpleCard myDealCard">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <ng-container *ngIf="ModuleName == 'CIS'">
                        <span>Deal Parameters</span>
                        </ng-container>
                        <ng-container *ngIf="ModuleName == 'AM'">
                            <span>Client and Deal Context</span>
                            <a href="javascript:void(0);" (click)="HelpIconClick()" class="HeaderHelpIconTooltip">
                                <i class="fa fa-question-circle fa-ques fa-md" data-toggle="tooltip"></i>
                            </a>
                            </ng-container>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <ng-container
                        *ngIf="(Mode == 'CREATE' && CreateDealTemplate != null && CreateDealTemplate != undefined && CreateDealTemplate.length > 0)">
                        <customform #createDealForm [FormTemplate]="CreateDealTemplate" [FormData]="DealCreationData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                        </customform>
                    </ng-container>

                    <ng-container
                        *ngIf="(Mode == 'UPDATE' && CreateDealTemplate != null && CreateDealTemplate != undefined && CreateDealTemplate.length > 0
                            && DealCreationData != null && DealCreationData != undefined && DealCreationData.DealHeaderId > 0)">
                        <customform #createDealForm [FormTemplate]="CreateDealTemplate" [FormData]="DealCreationData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            [cellLock]="setCellLock" [parent]="this" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)">
                        </customform>
                    </ng-container>
                </div>
                <div>
                    <div class="filters buttonHolder" id="create-deal">
                        <div class="d-flex justify-content-end">


                            <ng-container *ngIf="DealButtons != null && DealButtons != undefined">

                                <!-- [disabled]="!actionBar.IsEnabled" !enableSubmit()-->
                                <ng-container *ngFor="let actionBar of DealButtons 
                          | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        |
                                        translate
                                        }}
                                        <!-- <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                                aria-hidden="true"></i></span> -->
                                    </button>
                                </ng-container>



                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</ng-container>

<ng-container *ngIf="ModuleName ==''">
    <div class="h-100">
        <nav aria-label="breadcrumb" class="breadcrumbSection">
            <div class="d-flex w-100 justify-content-between">
                <h1 class="sr-only">Deal Parameters</h1>
                <h2 class="sr-only">Deal Parameters</h2>
                <ng-container *ngIf="completedDeal == false">
                    <ul class="breadcrumb m-0 flex-fill">
                        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Deal Parameters</li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="completedDeal">
                    <ul class="breadcrumb m-0 flex-fill">

                        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page"><a
                                routerLink="/app/create-deal/{{dealHeaderId}}">Deal Configuration</a></li>
                        <!-- <li class="breadcrumb-item" aria-current="page"><a href="javascript:void(0);"
                                (click)="navigatetoservicepage()">Services</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page">
                            <a href="javascript:void(0);" (click)="navigatetoreportspage()">Output Screens</a>
                        </li> -->
                    </ul>
                </ng-container>
            </div>
        </nav>

        <div class="contentScroll p-3 simpleCard myDealCard">
            <div class="card h-100 m-0">
                <div class="card-header">
                    <h3>
                        <span>Client and Deal Context</span>
                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <ng-container
                        *ngIf="(Mode == 'CREATE' && CreateDealTemplate != null && CreateDealTemplate != undefined && CreateDealTemplate.length > 0)">
                        <customform #createDealForm [FormTemplate]="CreateDealTemplate" [FormData]="DealCreationData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                        </customform>
                    </ng-container>

                    <ng-container
                        *ngIf="(Mode == 'UPDATE' && CreateDealTemplate != null && CreateDealTemplate != undefined && CreateDealTemplate.length > 0
                            && DealCreationData != null && DealCreationData != undefined && DealCreationData.DealHeaderId > 0)">
                        <customform #createDealForm [FormTemplate]="CreateDealTemplate" [FormData]="DealCreationData"
                            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            [cellLock]="setCellLock" [parent]="this" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)">
                        </customform>
                    </ng-container>
                </div>
                <div>
                    <div class="filters buttonHolder" id="create-deal">
                        <div class="d-flex justify-content-end">


                            <ng-container *ngIf="DealButtons != null && DealButtons != undefined">

                                <!-- [disabled]="!actionBar.IsEnabled" !enableSubmit()-->
                                <ng-container *ngFor="let actionBar of DealButtons 
                          | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        |
                                        translate
                                        }}
                                        <!-- <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                                aria-hidden="true"></i></span> -->
                                    </button>
                                </ng-container>



                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</ng-container>










<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
        [curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<modal-popup id="OpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>This panel captures the context of the deal being modeled – client specifics like industry, primary region of operations and Deal specifics like Deal term, which country will be used as the prime contracting country to define the base currency, SBU / MU and other optional elements like THOR ID, Oppoertunity Name & Stage, contract type. The critical entry is the rate modeling option – this builds the base of the pricing depdnding on whether external rates to be used or Capgemini cost+ model to be used.</p>
</modal-popup>