<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <ul class="breadcrumb m-0 flex-fill">
                <li class="breadcrumb-item " aria-current="page">Reports
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ReportDisplayName | translate}}</li>
            </ul>
        </div>
    </nav>
    <ng-container>
        <div class="card m-2">
            <div class="card-header">
                <h3>
                    <span>{{ReportDisplayName | translate}}</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">
                <ng-container
                    *ngIf="(ReportsFormTemplate != null && ReportsFormTemplate != undefined && ReportsFormTemplate.length > 0)">
                    <customform #lotsoldropdown [FormTemplate]="ReportsFormTemplate" [FormData]="ReportFormData"
                        [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                    </customform>
                </ng-container>
                <br>
                <ng-container *ngIf="ModuleName=='PastDealComparison'">

                    <div class="d-flex justify-content-center">
                        <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab" data-href="#tabDashboard"
                            data-url="" (click)="ButtonClick($event,'DisplayDeals')">
                            <span>Display Deals</span>
                        </button>


                        <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab" data-href="#tabDashboard"
                            data-url="" (click)="ButtonClick($event,'Cancel')">
                            <span>Cancel</span>
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="ModuleName=='modelcomparison' || ModuleName=='whatifswithmodel'">

                    <div class="d-flex justify-content-end">


                        <ng-container
                            *ngIf="AMModelComparisonFormButtons != null && AMModelComparisonFormButtons != undefined">

                            <!-- [disabled]="!actionBar.IsEnabled" !enableSubmit()-->
                            <ng-container *ngFor="let actionBar of AMModelComparisonFormButtons 
              | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                <button type="button" [ngClass]="actionBar.CustomClass"
                                    (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                    |
                                    translate
                                    }}
                                    <!-- <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                    aria-hidden="true"></i></span> -->
                                </button>
                            </ng-container>



                        </ng-container>

                       

                    </div>

                </ng-container>




            </div>
        </div>
    </ng-container>








    <div class="p-2 ">
        <ng-container *ngIf="(AMReportsTabs != null && AMReportsTabs != undefined && AMReportsTabs.length > 0)">
            <tabset [tabType]="'plaintype'" [tabList]="AMReportsTabs" (selected)="setSelectedTab($event)">


                <div class="h-100">

                    <div class="p-2 contentScroll full simpleCard">

                        <div class="tab-content childTab">
                            <div class="tab-pane fade show active" id="tabChildDetails" role="tabpanel"
                                aria-labelledby="nav-home-tab">
                                <ng-container
                                    *ngIf="ModuleName!='businesscasesummary'&& ModuleName!='modelcomparison'&& ModuleName!='whatifswithmodel'&& ModuleName!='PastDealComparison'">
                                    <ng-container
                                        *ngIf="(showChart && ReportGridData != null && ReportGridData != undefined && ReportGridData.length > 0)">
                                        <div class="row">
                                            <div class="col-12">
                                                <br>
                                                <div class="card bg-white">
                                                    <div class="card-header bg-primary text-white">
                                                        <h3>
                                                            <span>{{chartDisplayName | translate}}</span>
                                                        </h3>
                                                    </div>
                                                    <div class="card-body overflow-auto">

                                                        <ng-container *ngIf="ModuleName!='businesscasesummary'">
                                                            <app-am-charts [item]="chartsInput"
                                                                [ReportType]="ReportTYPE" [ModuleName]="ModuleName"
                                                                [TabName]="selectedTab" [chartPlotName]="chartPlotName">
                                                            </app-am-charts>
                                                        </ng-container>


                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="ModuleName=='businesscasesummary'">
                                    <ng-container
                                        *ngIf="(showChart && ReportGridData != null && ReportGridData != undefined && ReportGridData.length > 0)">
                                        <div class="row align-items-center">
                                            <div class="row">
                                                <div class="col-12">

                                                    <div class="card bg-white">
                                                        <div class="card-header bg-primary text-white">
                                                            <h3>
                                                                <span></span>
                                                            </h3>
                                                        </div>
                                                        <div class="card-body overflow-auto">

                                                            <ng-container *ngIf="ModuleName =='businesscasesummary'">
                                                                <app-am-charts-multiple [item_first]="chartsInput"
                                                                    [item_second]="chartsInputSecond"
                                                                    [ReportType]="ReportTYPE" [ModuleName]="ModuleName"
                                                                    [TabName]="selectedTab"
                                                                    [chartPlotName]="chartPlotName">
                                                                </app-am-charts-multiple>
                                                            </ng-container>



                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container
                                    *ngIf="ModuleName!='solutionsummary' && ModuleName!='pricingsummary' && ModuleName!='PastDealComparison'">
                                    <ng-container
                                        *ngIf="(showGrid && ReportsGridTemplate != null && ReportsGridTemplate != undefined && ReportsGridTemplate.length > 0)">
                                        <grid-dispctrl #ReportsTabs [FieldTemplate]="ReportsGridTemplate"
                                            [GridData]="ReportGridData" [GridProp]="GridProp" [GridConfig]="FormConfig"
                                            [GridDataTotalCount]="DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                                        </grid-dispctrl>
                                    </ng-container>
                                </ng-container>


                                <ng-container *ngIf="ModuleName=='PastDealComparison'">
                                    <ng-container
                                        *ngIf="(showGrid_PastDealComparison && ReportsGridTemplate_PastDealComparison != null && ReportsGridTemplate_PastDealComparison != undefined && ReportsGridTemplate_PastDealComparison.length > 0)">
                                        <grid-dispctrl #ReportsTabs
                                            [FieldTemplate]="ReportsGridTemplate_PastDealComparison"
                                            [GridData]="ReportGridData" [GridProp]="_GridPropoffshoreVolume"
                                            [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                                        </grid-dispctrl>
                                    </ng-container>

                                    <br>
                                    <br>
                                    <ng-container *ngIf="showGrid_PastDealComparison">

                                        <ng-container
                                            *ngIf="(ReportsFormTemplate != null && ReportsFormTemplate != undefined && ReportsFormTemplate.length > 0)">
                                            <customform #lotsoldropdown [FormTemplate]="PastDealcomparsion_secondForm"
                                                [FormData]="ReportFormData" [FormProp]="FormProp"
                                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                            </customform>
                                        </ng-container>
                                        <br>
                                        <div class="d-flex justify-content-center">
                                            <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                                data-href="#tabDashboard" data-url=""
                                                (click)="ButtonClick($event,'Compare')">
                                                <span>Compare</span>
                                            </button>
                                        </div>
                                    </ng-container>

                                    <br>
                                    <br>


                                    <ng-container *ngIf="showcompare_pd">
                                        <div class="row align-items-center">
                                            <div class="row">
                                                <div class="col-12">

                                                    <div class="card bg-white">
                                                        <div class="card-header bg-primary text-white">
                                                            <h3>
                                                                <span><span>{{chartDisplayName |
                                                                        translate}}</span></span>
                                                            </h3>
                                                        </div>
                                                        <div class="card-body overflow-auto">

                                                            <ng-container *ngIf="ModuleName =='PastDealComparison'">
                                                                <app-am-charts-multiple [item_first]="chartsInput"
                                                                    [item_second]="chartsInputSecond"
                                                                    [ReportType]="ReportTYPE" [ModuleName]="ModuleName"
                                                                    [TabName]="selectedTab"
                                                                    [chartPlotName]="chartPlotName_pastD3">
                                                                </app-am-charts-multiple>
                                                            </ng-container>

                                                            <br>
                                                            <div class="row align-items-center">
                                                                <div class="col-6">
                                                                    <div class="form-inline">
                                                                        <div class="form-group">
                                                                            <label for="ed3" class="">
                                                                                <b>{{chartPlotName_pastD1}}</b>
                                                                            </label>
                                                                            <div class="gridContainer mb-3">
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-hover table-bordered tblGrid fixed">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th
                                                                                                    *ngFor="let header of getHeaders_pd()">
                                                                                                    {{header}}</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                *ngFor="let item of gridOutputPD_1">
                                                                                                <td
                                                                                                    *ngFor="let key of getHeaders_pd()">
                                                                                                    {{item[key]}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="ed3"
                                                                            class=""><b>{{chartPlotName_pastD2}}</b></label>
                                                                        <div class="gridContainer mb-3">
                                                                            <div class="table-responsive">
                                                                                <table
                                                                                    class="table table-hover table-bordered tblGrid fixed">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th
                                                                                                *ngFor="let header of getHeaders1_pd()">
                                                                                                {{header}}</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr
                                                                                            *ngFor="let item of gridOutputPD_2">
                                                                                            <td
                                                                                                *ngFor="let key of getHeaders1_pd()">
                                                                                                {{item[key]}}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <ng-container *ngIf="ModuleName =='PastDealComparison'">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <br>
                                                                        <div class="card bg-white">
                                                                            <div
                                                                                class="card-header bg-primary text-white">
                                                                                <h3>
                                                                                    <span>{{chartDisplayName |
                                                                                        translate}}</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div class="card-body overflow-auto">

                                                                                <ng-container>
                                                                                    <app-am-charts
                                                                                        [item]="chartsInputThird"
                                                                                        [ReportType]="ReportTYPE"
                                                                                        [ModuleName]="ModuleName"
                                                                                        [TabName]="selectedTab"
                                                                                        [chartPlotName]="chartPlotName_pastD3">
                                                                                    </app-am-charts>
                                                                                </ng-container>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <br>
                                                                <br>
                                                                <div class="row align-items-center">
                                                                    <div class="col-6">
                                                                        <div class="form-inline">
                                                                            <div class="form-group">
                                                                                <label for="ed3" class="">
                                                                                    <b>{{chartPlotName_pastD3}}</b>
                                                                                </label>
                                                                                <div class="gridContainer mb-3">
                                                                                    <div class="table-responsive">
                                                                                        <table
                                                                                            class="table table-hover table-bordered tblGrid fixed">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th
                                                                                                        *ngFor="let header of getHeaders2_pd()">
                                                                                                        {{header}}</th>

                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    *ngFor="let item of gridOutputPD_3">
                                                                                                    <td
                                                                                                        *ngFor="let key of getHeaders2_pd()">
                                                                                                        {{item[key]}}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="ed3"
                                                                                class=""><b>Financial</b></label>
                                                                            <div class="gridContainer mb-3">
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-hover table-bordered tblGrid fixed">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th
                                                                                                    *ngFor="let header of getHeaders3_pd()">
                                                                                                    {{header}}</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                *ngFor="let item of gridOutputPD_4">
                                                                                                <td
                                                                                                    *ngFor="let key of getHeaders3_pd()">
                                                                                                    {{item[key]}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </ng-container>



                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </ng-container>

                                </ng-container>



                                <br>
                                <ng-container *ngIf="ModuleName=='modelcomparison' || ModuleName=='whatifswithmodel'">
                                    <div class="d-flex justify-content-center">
                                        <ng-container
                                            *ngIf="AMModelComparisonGridButtons != null && AMModelComparisonGridButtons != undefined">

                                            <!-- [disabled]="!actionBar.IsEnabled" !enableSubmit()-->
                                            <ng-container *ngFor="let actionBar of AMModelComparisonGridButtons 
                      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                                <button type="button" [ngClass]="actionBar.CustomClass"
                                                    (click)="actionButtonClick(actionBar)"
                                                    [disabled]="!actionBar.IsEnabled"
                                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                                    |
                                                    translate
                                                    }}
                                                    <!-- <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                            aria-hidden="true"></i></span> -->
                                                </button>
                                            </ng-container>



                                        </ng-container>

                                      
                                    </div>

                                    <br>

                                    <ng-container *ngIf="ModuleName=='whatifswithmodel'">
                                        <div class="d-flex justify-content-end">
                                            <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                                data-href="#tabDashboard" data-url=""
                                                (click)="ButtonClick($event,'EXPORTTOEXCEL')">
                                                <span>Export To Excel</span>
                                            </button>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <br>
                                <ng-container *ngIf="ModuleName=='modelcomparison' && modelcomparisonflag">
                                    <ng-container
                                        *ngIf="(showChart && ReportGridData != null && ReportGridData != undefined && ReportGridData.length > 0)">
                                        <div class="row align-items-center">
                                            <div class="row">
                                                <div class="col-12">

                                                    <div class="card bg-white">
                                                        <div class="card-header bg-primary text-white">
                                                            <h3>
                                                                <span><span>{{chartDisplayName |
                                                                        translate}}</span></span>
                                                            </h3>
                                                        </div>
                                                        <div class="card-body overflow-auto">

                                                            <ng-container *ngIf="ModuleName =='modelcomparison'">
                                                                <app-am-charts-multiple [item_first]="chartsInput"
                                                                    [item_second]="chartsInputSecond"
                                                                    [ReportType]="ReportTYPE" [ModuleName]="ModuleName"
                                                                    [TabName]="selectedTab"
                                                                    [chartPlotName]="chartPlotName">
                                                                </app-am-charts-multiple>
                                                            </ng-container>

                                                            <div class="row align-items-center">
                                                                <div class="col-6">
                                                                    <div class="form-inline">
                                                                        <div class="form-group">
                                                                            <label for="ed3" class=""><b>Revenue
                                                                                    Comparison</b></label>
                                                                            <div class="gridContainer mb-3">
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-hover table-bordered tblGrid fixed">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th
                                                                                                    *ngFor="let header of getHeaders()">
                                                                                                    {{header}}</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                *ngFor="let item of gridforreveenue">
                                                                                                <td
                                                                                                    *ngFor="let key of getHeaders()">
                                                                                                    {{item[key]}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="ed3" class="">
                                                                            <b>CM %
                                                                                Comparison</b></label>
                                                                        <div class="gridContainer mb-3">
                                                                            <div class="table-responsive">
                                                                                <table
                                                                                    class="table table-hover table-bordered tblGrid fixed">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th
                                                                                                *ngFor="let header of getHeaders1()">
                                                                                                {{header}}</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr
                                                                                            *ngFor="let item of gridforcmcomparison">
                                                                                            <td
                                                                                                *ngFor="let key of getHeaders1()">
                                                                                                {{item[key]}}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <ng-container *ngIf="ModuleName =='modelcomparison'">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <br>
                                                                        <div class="card bg-white">
                                                                            <div
                                                                                class="card-header bg-primary text-white">
                                                                                <h3>
                                                                                    <span>{{chartDisplayName |
                                                                                        translate}}</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div class="card-body overflow-auto">

                                                                                <ng-container>
                                                                                    <app-am-charts
                                                                                        [item]="chartsInputThird"
                                                                                        [ReportType]="ReportTYPE"
                                                                                        [ModuleName]="ModuleName"
                                                                                        [TabName]="selectedTab"
                                                                                        [chartPlotName]="chartPlotName">
                                                                                    </app-am-charts>
                                                                                </ng-container>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <div class="row align-items-center">
                                                                <div class="col-6">
                                                                    <div class="form-inline">
                                                                        <div class="form-group">
                                                                            <label for="ed3" class="">
                                                                                <b>Blended Rate Comparison</b>
                                                                            </label>
                                                                            <div class="gridContainer mb-3">
                                                                                <div class="table-responsive">
                                                                                    <table
                                                                                        class="table table-hover table-bordered tblGrid fixed">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th
                                                                                                    *ngFor="let header of getHeaders2()">
                                                                                                    {{header}}</th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr
                                                                                                *ngFor="let item of gridforblendedratecomparison">
                                                                                                <td
                                                                                                    *ngFor="let key of getHeaders2()">
                                                                                                    {{item[key]}}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="ed3" class=""><b>Other Financial And
                                                                                Staffing Details</b></label>
                                                                        <div class="gridContainer mb-3">
                                                                            <div class="table-responsive">
                                                                                <table
                                                                                    class="table table-hover table-bordered tblGrid fixed">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th
                                                                                                *ngFor="let header of getHeaders3()">
                                                                                                {{header}}</th>

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr
                                                                                            *ngFor="let item of gridforfinancialdetails">
                                                                                            <td
                                                                                                *ngFor="let key of getHeaders3()">
                                                                                                {{item[key]}}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="ModuleName=='whatifswithmodel' && showflag">
                                    <div>
                                        <div class="tabset">
                                            <!--Start From Here-->
                                            <ng-container
                                                *ngIf="arraySelecteditems!=null && arraySelecteditems!=undefined && arraySelecteditems.length>0">
                                                <ul class="nav nav-tabs" role="tablist">
                                                    <ng-container *ngFor="let tab of arraySelecteditems">
                                                        <li class="nav-item">
                                                            <a class="nav-link" href="#tabChildDetails"
                                                                [ngClass]="{'active': (tab.ModelID==selectedTab)}"
                                                                data-bs-toggle="tab" role="tab"
                                                                [attr.aria-selected]="(tab.ModelID==selectedTab)"
                                                                [attr.aria-label]="tab.ModelID"
                                                                (click)="onChange(tab)">{{tab.ModelID | translate}}</a>
                                                        </li>
                                                    </ng-container>
                                                </ul>

                                                <div class="tab-content">
                                                    <div class="tab-pane fade show active" [id]="selectedTab"
                                                        role="tabpanel">
                                                        <!-- <ng-content> -->

                                                        <p class="text-bold text-center phead">
                                                            <span>Solution Levers</span>
                                                        </p>
                                                        <div class="row small-gutter mb-4">
                                                            <div class="col-6">
                                                                <ng-container
                                                                    *ngIf="(showGrid && PyramidGridTemplate != null && PyramidGridTemplate != undefined && PyramidGridTemplate.length > 0)">
                                                                    <grid-dispctrl #ReportsTabs
                                                                        [FieldTemplate]="PyramidGridTemplate"
                                                                        [GridData]="(gridforwhatifmodel_data |  filterBy: {ReportType: 'PyramidPer', ModelID: selectedTab})"
                                                                        [GridProp]="GridProp1"
                                                                        [GridDataTotalCount]="DataTotalCount"
                                                                        (ActionBarClick)="actButtonClick($event)"
                                                                        [IsPagination]="IsPagination"
                                                                        (FieldClick)="onClick($event)"
                                                                        (FieldChange)="onChange($event)"
                                                                        [cellLock]="setCellLock" [parent]="this">
                                                                    </grid-dispctrl>
                                                                </ng-container>
                                                                <div class="d-flex justify-content-end mt-4">
                                                                    <button class="btnCustom rounded-pill btnTertiary"
                                                                        data-toggle="tab" data-href="#tabDashboard"
                                                                        data-url=""
                                                                        (click)="ApplyButtonClick(gridforwhatifmodel_data,'PyramidGrid')">
                                                                        <span>Apply</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <ng-container
                                                                    *ngIf="(showGrid && EffortsGridTemplate != null && EffortsGridTemplate != undefined && EffortsGridTemplate.length > 0)">
                                                                    <grid-dispctrl #ReportsTabs
                                                                        [FieldTemplate]="EffortsGridTemplate"
                                                                        [GridData]="(gridforwhatifmodel_data| filterBy: {ReportType: 'EffortsPer', ModelID: selectedTab})"
                                                                        [GridProp]="GridProp1"
                                                                        [GridDataTotalCount]="DataTotalCount"
                                                                        (ActionBarClick)="actButtonClick($event)"
                                                                        [IsPagination]="IsPagination"
                                                                        (FieldClick)="onClick($event)"
                                                                        (FieldChange)="onChange($event)"
                                                                        [cellLock]="setCellLock" [parent]="this">
                                                                    </grid-dispctrl>
                                                                </ng-container>

                                                                <div class="d-flex justify-content-end mt-4">
                                                                    <button class="btnCustom rounded-pill btnTertiary"
                                                                        data-toggle="tab" data-href="#tabDashboard"
                                                                        data-url=""
                                                                        (click)="ApplyButtonClick(gridforwhatifmodel_data,'EffortsGrid')">
                                                                        <span>Apply</span>
                                                                    </button>
                                                                </div>
                                                                <div class="w-100 small-gutter mb-4"></div>
                                                                <div class="gridContainer">
                                                                    <div class="table-responsive">
                                                                        <table
                                                                            class="table table-bordered tblGrid fixed">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" rowspan="2"
                                                                                        width="180">Productivity
                                                                                    </th>
                                                                                    <!--comment-->
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <!---div class="progress">
                                                                <div class="progress-bar" role="progressbar" style="width: 11%;" aria-valuenow="11" aria-valuemin="0" aria-valuemax="100">11%</div>
                                                              </div--->
                                                                                        <!-- <label for="customRange"
                                                                        class="form-label">Custom range</label> -->
                                                                                        <br>
                                                                                        <input type="range"
                                                                                            class="form-range"
                                                                                            id="customRange"
                                                                                            name="prod_range" min="-100"
                                                                                            max="100"
                                                                                            (change)="rangeChanged($event)">
                                                                                        <input type="text"
                                                                                            readonly="true"
                                                                                            class="greyinputbox"
                                                                                            value="20"
                                                                                            [(ngModel)]="prod_range">
                                                                                        ({{ProductivityPer}})%
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="row small-gutter mb-4">
                                                            <div class="col-12">
                                                                <p class="text-bold text-center phead">
                                                                    <span>Financial Levers</span>
                                                                </p>
                                                                <!--div class="row small-gutter mb-4"-->
                                                                <div class="col-6 float-start">
                                                                    <div class="gridContainer">
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-bordered tblGrid fixed">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" rowspan="2"
                                                                                            width="180">Expenses
                                                                                            %
                                                                                        </th>
                                                                                        <!--comment-->
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>


                                                                                            <input type="range"
                                                                                                class="form-range"
                                                                                                id="customRange"
                                                                                                name="expense_range"
                                                                                                min="-100" max="100"
                                                                                                (change)="rangeChanged($event)">
                                                                                            <input type="text"
                                                                                                readonly="true"
                                                                                                class="greyinputbox"
                                                                                                value="20"
                                                                                                [(ngModel)]="expense_range">({{ExpensesPer}})%
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Force next columns to break to new line -->


                                                                <div class="col-6 float-end">
                                                                    <div class="gridContainer">
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-bordered tblGrid fixed">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" rowspan="2"
                                                                                            width="180">Deal
                                                                                            Discount %</th>
                                                                                        <!--comment-->
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>

                                                                                            <input type="range"
                                                                                                class="form-range"
                                                                                                id="customRange"
                                                                                                name="Deal_range"
                                                                                                min="0" max="100"
                                                                                                (change)="rangeChanged($event)">
                                                                                            <input type="text"
                                                                                                readonly="true"
                                                                                                class="greyinputbox"
                                                                                                value="20"
                                                                                                [(ngModel)]="Deal_range">({{DealDiscountPer}})%
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--/div-->
                                                                <div class="w-100 small-gutter mb-4" style="clear:both">
                                                                </div>
                                                                <!--div class="row small-gutter mb-4"--->
                                                                <div class="col-6 float-start">
                                                                    <div class="gridContainer">
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-bordered tblGrid fixed">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" rowspan="2"
                                                                                            width="180">Blended
                                                                                            Rate
                                                                                        </th>
                                                                                        <!--comment-->
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="d-flex">
                                                                                            <div class="row w-100">
                                                                                                <div class="col-10">
                                                                                                    <input type="range"
                                                                                                        class="form-range"
                                                                                                        id="customRange"
                                                                                                        name="Blended_range1"
                                                                                                        min="-100"
                                                                                                        max="100"
                                                                                                        (change)="rangeChanged($event)">
                                                                                                    <input type="text"
                                                                                                        readonly="true"
                                                                                                        class="greyinputbox"
                                                                                                        value="20"
                                                                                                        [(ngModel)]="Blended_range1">({{BlendedRatePer_Ons}})
                                                                                                </div>
                                                                                                <div class="col-2">
                                                                                                    <span>Onshore</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="d-flex">
                                                                                            <div class="row w-100">
                                                                                                <div class="col-10">
                                                                                                    <input type="range"
                                                                                                        class="form-range"
                                                                                                        id="customRange"
                                                                                                        min="-100"
                                                                                                        max="100"
                                                                                                        name="Blended_range2"
                                                                                                        (change)="rangeChanged($event)">
                                                                                                    <input type="text"
                                                                                                        readonly="true"
                                                                                                        class="greyinputbox"
                                                                                                        value="20"
                                                                                                        [(ngModel)]="Blended_range2">({{BlendedRatePer_Off}})
                                                                                                </div>
                                                                                                <div class="col-2">
                                                                                                    <span>Offshore</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="d-flex">
                                                                                            <div class="row w-100">
                                                                                                <div class="col-10">
                                                                                                    <input type="range"
                                                                                                        class="form-range"
                                                                                                        id="customRange"
                                                                                                        name="Blended_range3"
                                                                                                        min="-100"
                                                                                                        max="100"
                                                                                                        (change)="rangeChanged($event)">
                                                                                                    <input type="text"
                                                                                                        readonly="true"
                                                                                                        class="greyinputbox"
                                                                                                        value="20"
                                                                                                        [(ngModel)]="Blended_range3">({{BlendedRatePer_Nea}})
                                                                                                </div>
                                                                                                <div class="col-2">
                                                                                                    <span>Nearshore</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Force next columns to break to new line -->


                                                                <div class="col-6 float-end">
                                                                    <div class="gridContainer">
                                                                        <div class="table-responsive">
                                                                            <table
                                                                                class="table table-bordered tblGrid fixed">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" rowspan="2"
                                                                                            width="180">
                                                                                            Contingency
                                                                                            %</th>
                                                                                        <!--comment-->
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="d-flex">
                                                                                            <div class="row w-100">
                                                                                                <div class="col-10">
                                                                                                    <input type="range"
                                                                                                        class="form-range"
                                                                                                        id="customRange"
                                                                                                        min="-100"
                                                                                                        max="100"
                                                                                                        name="contigency_range1"
                                                                                                        (change)="rangeChanged($event)">
                                                                                                    <input type="text"
                                                                                                        readonly="true"
                                                                                                        class="greyinputbox"
                                                                                                        value="20"
                                                                                                        [(ngModel)]="contigency_range1">({{ContingencyPer_Ons}})%
                                                                                                </div>
                                                                                                <div class="col-2">
                                                                                                    <span>Onshore</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="d-flex">
                                                                                            <div class="row w-100">
                                                                                                <div class="col-10">
                                                                                                    <input type="range"
                                                                                                        class="form-range"
                                                                                                        id="customRange"
                                                                                                        name="contigency_range2"
                                                                                                        min="-100"
                                                                                                        max="100"
                                                                                                        (change)="rangeChanged($event)">
                                                                                                    <input type="text"
                                                                                                        readonly="true"
                                                                                                        class="greyinputbox"
                                                                                                        value="20"
                                                                                                        [(ngModel)]="contigency_range2">({{ContingencyPer_Off}})%
                                                                                                </div>
                                                                                                <div class="col-2">
                                                                                                    <span>Offshore</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="d-flex">
                                                                                            <div class="row w-100">
                                                                                                <div class="col-10">
                                                                                                    <input type="range"
                                                                                                        class="form-range"
                                                                                                        id="customRange"
                                                                                                        name="contigency_range3"
                                                                                                        min="-100"
                                                                                                        max="100"
                                                                                                        (change)="rangeChanged($event)">
                                                                                                    <input type="text"
                                                                                                        readonly="true"
                                                                                                        class="greyinputbox"
                                                                                                        value="20"
                                                                                                        [(ngModel)]="contigency_range3">({{ContingencyPer_Nea}})%
                                                                                                </div>
                                                                                                <div class="col-2">
                                                                                                    <span>Nearshore</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--/div-->
                                                            </div>
                                                            <div class="w-100 small-gutter mb-4"></div>
                                                            <div class="col-12">
                                                                <!-- <ng-container
                                                *ngIf="(showChart && ReportGridData != null && ReportGridData != undefined && ReportGridData.length > 0)"> -->
                                                                <div class="row align-items-center">
                                                                    <div class="row">
                                                                        <div class="col-12">

                                                                            <div class="card bg-white">
                                                                                <div
                                                                                    class="card-header bg-primary text-white">
                                                                                    <h3>
                                                                                        <span>{{chartDisplayName
                                                                                            |
                                                                                            translate}}</span>
                                                                                    </h3>
                                                                                </div>
                                                                                <div class="card-body overflow-auto">

                                                                                    <ng-container>
                                                                                        <app-am-charts-multiple
                                                                                            [item_first]="chartsInput"
                                                                                            [item_second]="chartsInputSecond"
                                                                                            [ReportType]="ReportTYPE"
                                                                                            [ModuleName]="ModuleName"
                                                                                            [TabName]="selectedTab"
                                                                                            [chartPlotName]="chartPlotName">
                                                                                        </app-am-charts-multiple>
                                                                                    </ng-container>



                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <!-- </ng-container>   -->
                                                            </div>
                                                        </div>
                                                        <!-- </ng-content> -->
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <!--End Here-->
                                        </div>







                                        <!-- <tabset [tabList]="arraySelecteditems" (selected)="selectedTabs($event)"> -->




                                    </div>


                                </ng-container>
                                <ng-container *ngIf="ModuleName=='solutionsummary'">
                                    <ng-container
                                        *ngIf="(showGrid && ReportsGridTemplate != null && ReportsGridTemplate != undefined && ReportsGridTemplate.length > 0)">
                                        <grid-dispctrl #ReportsTabs [FieldTemplate]="ReportsGridTemplate"
                                            [GridData]="non_accordian_data_SS" [GridProp]="GridProp"
                                            [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                                        </grid-dispctrl>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="ModuleName=='pricingsummary' && selectedTab == 'PriceSummary'">
                                    <ng-container
                                        *ngIf="(showGrid && ReportsGridTemplate != null && ReportsGridTemplate != undefined && ReportsGridTemplate.length > 0)">
                                        <grid-dispctrl #ReportsTabs [FieldTemplate]="ReportsGridTemplate"
                                            [GridData]="non_accordian_data_SS" [GridProp]="GridProp"
                                            [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                                        </grid-dispctrl>
                                    </ng-container>
                                </ng-container>


                                <ng-container
                                    *ngIf="ModuleName=='pricingsummary' && selectedTab == 'PriceSummaryBlendedRate'">
                                    <ng-container
                                        *ngIf="(showGrid && ReportsGridTemplate != null && ReportsGridTemplate != undefined && ReportsGridTemplate.length > 0)">
                                        <grid-dispctrl #ReportsTabs [FieldTemplate]="ReportsGridTemplate"
                                            [GridData]="non_accordian_data_SS" [GridProp]="GridProp"
                                            [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                                        </grid-dispctrl>
                                    </ng-container>
                                </ng-container>


                                <ng-container
                                    *ngIf="ModuleName=='pricingsummary' && selectedTab == 'PriceSummaryCMPer'">
                                    <ng-container
                                        *ngIf="(showGrid && ReportsGridTemplate != null && ReportsGridTemplate != undefined && ReportsGridTemplate.length > 0)">
                                        <grid-dispctrl #ReportsTabs [FieldTemplate]="ReportsGridTemplate"
                                            [GridData]="non_accordian_data_SS" [GridProp]="GridProp"
                                            [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                                        </grid-dispctrl>
                                    </ng-container>
                                </ng-container>



                            </div>
                        </div>

                    </div>
                </div>
            </tabset>
        </ng-container>
    </div>

</div>



<ng-container *ngIf="showPopup">

    <modal-popup id="Opensummarypopup" [modaldetail]="modalInf">
        <div><br> {{popupheading_summarysol}}</div>
        <ng-container
            *ngIf="(ReportsGridTemplate_popup != null && ReportsGridTemplate_popup != undefined && ReportsGridTemplate_popup.length > 0 && ReportGridData_popup != null && ReportGridData_popup != undefined && ReportGridData_popup.length > 0)">
            <grid-dispctrl #ReportsTabs1 [FieldTemplate]="ReportsGridTemplate_popup" [GridData]="ReportGridData_popup"
                [GridProp]="_GridPropoffshoreVolume" [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
            </grid-dispctrl>
        </ng-container>
    </modal-popup>
</ng-container>


<ng-container *ngIf="showPopup_pricingSummary">

    <modal-popup id="OpenPricingPopup" [modaldetail]="modalInf">
        <div><br>{{popupheading_pricing}}</div>
        <ng-container
            *ngIf="(ReportsGridTemplate_popup != null && ReportsGridTemplate_popup != undefined && ReportsGridTemplate_popup.length > 0 && ReportGridData_popup != null && ReportGridData_popup != undefined && ReportGridData_popup.length > 0)">
            <grid-dispctrl #ReportsTabs1 [FieldTemplate]="ReportsGridTemplate_popup" [GridData]="ReportGridData_popup"
                [GridProp]="GridProp" [GridConfig]="FormConfig" [GridDataTotalCount]="DataTotalCount"
                (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
            </grid-dispctrl>
        </ng-container>
    </modal-popup>
</ng-container>

<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>