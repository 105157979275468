import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { AuthenticationService } from 'src/app/core/auth/authentication.service'

import { NotificationData } from 'src/modules/notification/notification.interface';
import { CustomValidators } from './customvalidators';
import { RegistryDetail } from './registry.interface';
import { RegistryService } from './registry.service';
import { Router } from '@angular/router';
import { EncryptDecryptService } from '../auth/encrypt-decrypt.service';


@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
})
export class RegistryComponent implements OnInit {
  success = '';
  submitted = false;

  // // Patterns
  // const PAT_NAME = "^[a-zA-Z ]{2,20}$";
  // const PAT_EMAIL = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$";

  // // CAPGEMINI PASSWORD POLICY
  // // Your password must:	 	
  // // 1)	Contain min 8 characters or more upto 20
  // // 2)	Include a combination of the following: 
  // //           o	Uppercase letters (A-Z)
  // //           o	Lowercase letters (a-z)
  // //           o	Numbers (0-9)
  // //           o	Special characters (, ! $ # %)
  // // 3)	Not Contain the following symbols (& + ~ ` ^ " ') 

  registerForm = new FormGroup(
    {
      // // firstName: new FormControl('', [Validators.required, Validators.pattern(PAT_NAME)]),
      // firstName: new FormControl('', [Validators.required, CustomValidators.nonEmpty]),
      // lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8),
      Validators.maxLength(20)]),
      confirmPassword: new FormControl('', [Validators.required])
    },

    // {validators: CustomValidators.mustMatch('password', 'confirmPassword')} // insert here
    { validators: CustomValidators.matchingPasswords }
  );

  public RegistryData: RegistryDetail = new RegistryDetail();
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();

  public otp: string = '';
  public showOTP: boolean = false;
  public validOTP: boolean = false;

  constructor(private registryService: RegistryService, private _authService: AuthenticationService,
    private _router: Router, private encryptDecryptService: EncryptDecryptService) { }


  ngOnInit() { }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.success = JSON.stringify(this.registerForm.value);
    //console.log(JSON.stringify(this.registerForm.value, null, 2));

    // if (this.success) {

    //   let password = this.registerForm.value['password'].trim();
    //   let confirmPassword = this.registerForm.value['confirmPassword'].trim();

    //   if (password !== confirmPassword) {
    //     this.showNotificationMessage('', 'Password and Confirm Password does not match.', 'VALID_RESET', 0);
    //     this.displayNotifybox = true;

    //     return;
    //   }

    //   this.RegistryData = new RegistryDetail();
    //   // this.RegistryData.EmailId = this.registerForm.value['email'];
    //   this.RegistryData.EmailId = this.encryptDecryptService.encrypt(this.registerForm.value['email'].trim());
    //   // this.RegistryData.FirstName = this.registerForm.value['firstName'];
    //   // this.RegistryData.LastName = this.registerForm.value['lastName'];
    //   this.RegistryData.FirstName = '';
    //   this.RegistryData.LastName = '';

    //   this.RegistryData.SecretCode = this.encryptDecryptService.encrypt(this.registerForm.value['password'].trim());


    //   this.IsLoading = true;

    //   let subscribe$: Subscription = this.registryService.saveRegisterDetail(this.RegistryData).subscribe(res => {
    //     if (res !== undefined && res !== null) {
    //       if (res.Status === 200 && res.Data != undefined && res.Data !== null) {

    //         let userId = res.Data;
    //         // res.Message

    //         if (!isNullOrUndefined(userId) && userId > 0) {
    //           this.showNotificationMessage('', 'You have been registered successfully.', 'REG_SUCCESS');
    //         }
    //         // else {
    //         //   this.showNotificationMessage('', res.Message, 'REG_FAILURE', false);
    //         // }

    //         this.displayNotifybox = true;

    //       }
    //       else {
    //         // alert(res.Message);
    //         this.showNotificationMessage('', res.Message, 'REG_FAILURE', -1);
    //         this.displayNotifybox = true;
    //       }
    //     }

    //     this.IsLoading = false;
    //   },
    //     err => {
    //       this.IsLoading = false;
    //       console.log('Error on registering user.');
    //     });

    //   this.subscriptionList$.push(subscribe$);
    // }

  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }

  generateOTP(): void {

    let emailid = this.registerForm.value['email'].trim();

    if (emailid.length <= 0) {
      this.showNotificationMessage('', 'Please enter valid Email Address.', 'VALID_RESET', 0,1000);
      this.displayNotifybox = true;

      return;
    }

    this.IsLoading = true;
    this.showOTP = false;
    this.validOTP = false;

    let mail = this.encryptDecryptService.encrypt(emailid);

    let odata = { EmailId: mail, SecretCode: '' };

    let subscribe$: Subscription = this.registryService.generateOTP(odata).subscribe(res => {
      if (res !== undefined && res !== null) {
        if (res.Status === 200 && res.Data != undefined && res.Data !== null) {

          if (res.Message.length > 0) {
            this.showOTP = true;
          }
          else {
            // alert('Sorry, unable to validate the Email Address.');
            // show as invalid mailid
            this.showNotificationMessage('', res.Message, 'REG_FAILURE', -1,1000);
            this.displayNotifybox = true;
          }

        }
        else {
          // alert(res.Message);
          this.showNotificationMessage('', res.Message, 'REG_FAILURE', -1,1000);
          this.displayNotifybox = true;
        }

      }
      else {
        alert('Error on validating the Email Address.');
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on validating the Email Address.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  validateOTP(): void {

    let otptemp = (document.getElementById('txtOTP') as HTMLInputElement).value.trim();

    if (otptemp.length <= 0) {
      this.showNotificationMessage('', 'Please enter OTP.', 'VALID_RESET', 0,1000);
      this.displayNotifybox = true;

      return;
    }

    this.IsLoading = true;
    this.validOTP = false;

    let mail = this.encryptDecryptService.encrypt(this.registerForm.value['email'].trim());
    let otp = this.encryptDecryptService.encrypt((document.getElementById('txtOTP') as HTMLInputElement).value.trim());

    let odata = { EmailId: mail, SecretCode: otp };

    let subscribe$: Subscription = this.registryService.validateOTP(odata).subscribe(res => {
      if (res !== undefined && res !== null) {
        if (res.Status === 200 && res.Data != undefined && res.Data !== null) {
          //let response = res.Data;

          if (res.Message.length > 0) {
            this.validOTP = true;
          }
          else {
            // alert('Sorry, unable to validate the OTP.');
            // show as invalid mailid
            this.showNotificationMessage('', res.Message, 'REG_FAILURE', -1,1000);
            this.displayNotifybox = true;
          }

        }
        else {
          // alert(res.Message);
          this.showNotificationMessage('', res.Message, 'REG_FAILURE', -1,1000);
          this.displayNotifybox = true;
        }
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on validating the OTP.');
      });
  }

  setNotifyRes(event: any): void {
    // console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;

    switch (event.action.toUpperCase()) {
      case 'REG_SUCCESS':

        if (event.result.toUpperCase() === 'OK') {

          // On successful registeration, redirect to home page
          this._router.navigate(['login']);

          // this._authService.getAPIToken(this.RegistryData.EmailId).then(result => {

          //   if (result) {
          //     this._router.navigate(['app/home']);
          //     // window.location.href = window.location.href;

          //   }
          //   else {
          //     alert('Sorry, unable to complete the authentication');
          //     // show as invalid mailid
          //   }

          // });
        }
        break;

      case 'REG_FAILURE':
        // On registeration failure, no redirction
        if (event.result.toUpperCase() === 'OK') {

        }
        break;

      case 'VALID_RESET':
      case 'VALID_USER_FAIL':
      case 'VALID_OTP_FAIL':
        break;

    }


    if (canClose) {
      this.closeNotifybox();
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  showNotificationMessage(header: any = '', msg: any = '', action: any = '', successFlag: number = 1,timeout:number) {
    this.notify = {
      info: {
        header: header,
        message: msg,
        yes: 'OK',
        no: '',

        IsHeaderHidden: true,
        HeaderIcon: (successFlag === 1 ? 'fa fa-check-circle text-success' : (successFlag === 0 ? 'fa fa-exclamation-circle text-warning' : 'fa fa-exclamation-triangle text-danger')),
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: null,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }



}
