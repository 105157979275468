<ng-container *ngIf="DealSubLineItemId > 0">

    <ng-container *ngIf="ServiceDomainDetails.ServiceDomain !== 'AMSD0018'">


        <div *ngIf="LotName != null && LotName != ''" class="col-12" class="HeaderHelpIconTooltip">
            <span>Delivery Model Primary Onshore Location Influencers Year 1 % (Lot:{{LotName}})</span>
            <i class="fa fa-question-circle fa-ques" (click)="HelpIconClick()" data-toggle="tooltip"></i>
        </div>
        <br>
        <ng-container
            *ngIf="(LSELocationInfluencersTemplate != null && LSELocationInfluencersTemplate != undefined && LSELocationInfluencersTemplate.length > 0
            && LSELocationInfluencersDetails != null && LSELocationInfluencersDetails != undefined && LSELocationInfluencersDetails.length > 0)">
            <grid-dispctrl #LSELocationInfluencersGrid [FieldTemplate]="LSELocationInfluencersTemplate"
                [GridData]="LSELocationInfluencersDetails" [GridProp]="GridProp"
                [GridDataTotalCount]="LSELocationInfluencersTotalCount" (FieldClick)="onClick($event)"
                [IsPagination]="IsPagination"    (FieldChange)="onChange($event)">
            </grid-dispctrl>
        </ng-container>

        <br>

        <ng-container
            *ngIf="(LSEOnshoreLocationEffortPerTemplate != null && LSEOnshoreLocationEffortPerTemplate != undefined && LSEOnshoreLocationEffortPerTemplate.length > 0
            && LSEOnshoreLocationEffortPerDetails != null && LSEOnshoreLocationEffortPerDetails != undefined && LSEOnshoreLocationEffortPerDetails.length > 0)">
            <grid-dispctrl #LSEOnshoreLocationEffortPerGrid [FieldTemplate]="LSEOnshoreLocationEffortPerTemplate"
                [GridData]="LSEOnshoreLocationEffortPerDetails" [GridProp]="GridProp"
                [GridDataTotalCount]="LSEOnshoreLocationEffortPerTotalCount" (FieldClick)="onClick($event)"
                [IsPagination]="IsPagination"      (FieldChange)="onChange($event)">
            </grid-dispctrl>
        </ng-container>

        <br>

        <ng-container *ngIf="EffortMixButtons != null && EffortMixButtons != undefined">
            <ng-container
                *ngFor="let actionBar of EffortMixButtons | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                    [disabled]="!actionBar.IsEnabled"
                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                    | translate}}
                </button>
            </ng-container>
        </ng-container>

        <br>
        <br>

        <ng-container
            *ngIf="(LSEEffortsDistributionPerTemplate != null && LSEEffortsDistributionPerTemplate != undefined && LSEEffortsDistributionPerTemplate.length > 0
            && LSEEffortsDistributionPerDetails != null && LSEEffortsDistributionPerDetails != undefined && LSEEffortsDistributionPerDetails.length > 0)">
            <grid-dispctrl #LotSolutionLocationMixGrid [FieldTemplate]="LSEEffortsDistributionPerTemplate"
                [GridData]="LSEEffortsDistributionPerDetails" [GridProp]="GridProp"
                [GridDataTotalCount]="LSEEffortsDistributionPerTotalCount" (FieldClick)="onClick($event)"
                [IsPagination]="IsPagination"       (FieldChange)="onChange($event)">
            </grid-dispctrl>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="ServiceDomainDetails.ServiceDomain === 'AMSD0018'">

        <ng-container *ngIf="(LSEEnhancementFormTemplate != null && LSEEnhancementFormTemplate != undefined && LSEEnhancementFormTemplate.length > 0 
                && LSEEnhancementFormDetails != null && LSEEnhancementFormDetails != undefined 
                && LSEEnhancementFormDetails.DealHeaderId > 0)">

            <customform #LSEEnhancementForm [FormTemplate]="LSEEnhancementFormTemplate"
                [FormData]="LSEEnhancementFormDetails" [FormProp]="FormProp" [FormConfig]="FormConfig"
                [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
            </customform>
        </ng-container>

        <div *ngIf="LotName != null && LotName != ''" class="col-12" class="HeaderHelpIconTooltip">
            <!-- <span>Location Effort % Mix (Lot:{{LotName}})</span> -->
            <span>Location Effort % (Lot:{{LotName}})</span>
            <i class="fa fa-question-circle fa-ques" (click)="HelpIconClick()" data-toggle="tooltip"></i>
        </div>

        <ng-container
            *ngIf="(LSEEnhancementGridTemplate != null && LSEEnhancementGridTemplate != undefined && LSEEnhancementGridTemplate.length > 0 
                && LSEEnhancementGridDetails != null && LSEEnhancementGridDetails != undefined && LSEEnhancementGridDetails.length > 0)">
            <grid-dispctrl #LotSolutionLocationMixGrid [FieldTemplate]="LSEEnhancementGridTemplate"
                [GridData]="LSEEnhancementGridDetails" [GridProp]="GridProp"
                [GridDataTotalCount]="LSEEnhancementGridTotalCount" (FieldClick)="onClick($event)"
                [IsPagination]="IsPagination"          (FieldChange)="onChange($event)">
            </grid-dispctrl>
        </ng-container>
        <br>

    </ng-container>

    <div class="filters buttonHolder" id="create-deal">
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="ActionButtons != null && ActionButtons != undefined">
                <ng-container *ngFor="let actionBar of ActionButtons 
      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        | translate}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>


    <!-- Loader section -->
    <div [hidden]="!IsLoading">
        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
    </div>

    <notification *ngIf="displayNotifybox" [notifyinfo]="notify?.info" [timeout]="notify.timeout"
        [curaction]="notify.action" [curitem]="notify.item" (selected)="setNotifyRes($event)"
        (close)="closeNotifybox()">
    </notification>

    <modal-popup id="enhancementDetails" style="display: none; font-size: x-small" [modaldetail]="modalInf">
        <p>Enhancement – In deals where a managed capacity or pool of resources have to provided to client and that pool
            can
            be used for any development work (typically &lt; 200 hours for each work) ; The system has multiple
            pre-defined
            models with standard pyramid by location – with user inputs of model selection, offshore leverage and total
            expected hours of development work – the resource efforts are calculated by each year. User can choose to
            modify
            the resource grades by location, % mix of resources (pyramid) by location for each role.</p>
    </modal-popup>

    <modal-popup id="LocationMixDetails" style="display: none; font-size: x-small" [modaldetail]="modalInf">
        <p>This sub-panel provides choices to define the attributes of a lot - the parameters and their value selection
            influences a location distribution % and provides recommended values.</p>
    </modal-popup>
</ng-container>