<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <ng-container *ngIf="completedDeal == false">
                <ul class="breadcrumb m-0 flex-fill">
                    <h1 class="sr-only">Services</h1>
                    <h2 class="sr-only">Services</h2>
                    <li class="breadcrumb-item" aria-current="page"><a (click)="dashboardClick()">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page"><a href="javascript: void(0);"
                            (click)="navigatetoDealPage()">Deal
                            Parameters</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ul>

            </ng-container>

            <ng-container *ngIf="completedDeal">
                <ul class="breadcrumb m-0 flex-fill">

                    <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page"><a href="javascript: void(0);"
                            (click)="navigatetoDealPage()">Deal
                            Parameters</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><a href="javascript:void(0);"
                            routerLink="/app/servicetyps/{{DealHeaderId}}">Services</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                        <a href="javascript:void(0);" routerLink="/app/reports/{{DealHeaderId}}/0">Output Screens</a>
                    </li>
                </ul>
            </ng-container>
        </div>
    </nav>

    <div class="p-2 contentScroll full simpleCard">
        <div class="card-body overflow-auto">
            <div class="text-dark">
                <p>Please refer the "Reference File" to see all combinations available within a tower, offerings &
                    technology
                    etc.
                </p>
            </div>
            <br>
            <div class="row mx-2 deal-details planInfoWraper filters">
                <div class="col-3">
                    <label for="dealid" class="fw-bold">Deal Id:&nbsp;&nbsp; </label>{{selectedDeal.DealId}}
                </div>
                <div class="col-3">
                    <label for="dealname" class="fw-bold">Opportunity Name:&nbsp;&nbsp;
                    </label>{{selectedDeal.DealName}}
                </div>
                <div class="col-3">
                    <label for="acc" class="fw-bold">Client Name:&nbsp;&nbsp;</label>{{selectedDeal.Client}}
                </div>

                <div class="col-3">
                    <label for="region" class="fw-bold">Region:&nbsp;&nbsp; </label>{{selectedDeal.RegionDesc}}
                </div>

                <div class="col-3">
                    <label for="coun" class="fw-bold">Contracting Country:&nbsp;&nbsp;
                    </label>{{selectedDeal.CountryDesc}}
                </div>
                <div class="col-3">
                    <label for="region" class="fw-bold">Competitors:&nbsp;&nbsp;
                    </label>{{selectedDeal.CompetitorsDesc}}
                </div>
                <div class="col-3">
                    <label for="region" class="fw-bold">Currency:&nbsp;&nbsp; </label>{{selectedDeal.CurrencyDesc}}
                </div>
                <div class="col-3">
                    <label for="region" class="fw-bold">Run Duration in Months:&nbsp;&nbsp;
                    </label>{{selectedDeal.DealTerm}}
                </div>


            </div>

            <br>
            <div class="p-2 contentScroll full simpleCard">

                <!--  <ng-container *ngIf="!nextFlag && !backFlag">
                    <ng-container
                        *ngIf="(_Config.ServiceTypesTabs != null && _Config.ServiceTypesTabs != undefined 
                                        && _Config.ServiceTypesTabs.length > 0
                                        && (_Config.ServiceTypesTabs | filterBy: {HasPermission: true,CanShow: true}).length > 0)">
                       
                      <div class="servicetypes_tab">
                       <tabset [tabList]="(_Config.ServiceTypesTabs | filterBy: {HasPermission: true,CanShow: true})"
                            (selected)="setSelectedTab($event)">
                            <ng-container
                                *ngFor="let ServicesTabs of (_Config.ServiceTypesTabs | filterBy: {HasPermission: true,CanShow: true})">

                                <ng-container *ngIf="selectedTab==ServicesTabs.Name">

                                    <ng-container *ngIf="(_Template != null && _Template != undefined && _Template.length > 0 
                                            && (_Template | filterBy: {Group: ServicesTabs.Name}).length>0)">
                                            <div class="servicetypes_table">
                                        <grid #ManagedServiceGrid
                                            [FieldTemplate]="(_Template | filterBy: {Group: ServicesTabs.Name})"
                                            [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                                            [GridDataTotalCount]="_DataTotalCount"
                                            (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" [parent]="this">
                                        </grid>
                                    </div>
                                    </ng-container>
                                </ng-container>

                            </ng-container>
                        </tabset>
                    </div> 
                    </ng-container>
                </ng-container> -->

                <!---on next click-->

                <ng-container>
                    <div class="tabset servicetypes_tab">
                        <!--Start From Here-->
                        <!-- [ngClass]="{'active1': (tab.Name==selectedTab)}"
                        [attr.aria-selected]="(tab.Name==selectedTab)"-->
                        <ng-container
                            *ngIf="_Config.ServiceTypesTabs !=null && _Config.ServiceTypesTabs !=undefined && _Config.ServiceTypesTabs .length>0">
                            <ul class="nav nav-tabs ST-tabs" role="tablist">
                                <ng-container *ngFor="let tab of _Config.ServiceTypesTabs  | filterBy: {CanShow: true}">
                                    <li class="nav-item">
                                        <a class="nav-link" [ngClass]="{'nav-click': (tab.Name==selectedTab)}"
                                            data-bs-toggle="tab" role="tab"
                                            [attr.aria-selected]="(tab.Name==selectedTab)" [attr.aria-label]="tab.Name"
                                            (click)="onTabChange(tab)">{{tab.DisplayName |
                                            translate}}</a>
                                    </li>
                                </ng-container>
                                <!--       <li class="nav-item">
                                    <a class="nav-link"
                                      [ngClass]="selectedTab == 'ManagedServices'? 'active' : ''"
                                        data-bs-toggle="tab"
                                        role="tab" 
                                        [attr.aria-label]="'ManagedServices'" (click)="onTabChange('ManagedServices')">Managed Services</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                      [ngClass]="selectedTab == 'PandC'? 'active' : ''"
                                        data-bs-toggle="tab"
                                        role="tab" 
                                        [attr.aria-label]="'PandC'" (click)="onTabChange('PandC')">P&C</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                      [ngClass]="selectedTab == 'RateCard'? 'active' : ''"
                                        data-bs-toggle="tab"
                                        role="tab" 
                                        [attr.aria-label]="'RateCard'" (click)="onTabChange('RateCard')">Rate Card</a>
                                </li> -->
                            </ul>

                            <div class="tab-content">
                                <div class="tab-pane fade show active" [id]="selectedTab" role="tabpanel">
                                    <ng-container *ngIf="selectedTab=='ManagedServices'">

                                        <ng-container
                                            *ngIf="(_Template != null && _Template != undefined && _Template.length > 0 && (_Template | filterBy: {Group: 'Managedservices'}).length>0)">

                                            <ng-container *ngIf="Flagoutput">
                                                <grid-dispctrl [FieldTemplate]="ServicesMSReport"
                                                    [GridData]="_Data_TCVReport" [GridProp]="GridProp"
                                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                                    (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)">
                                                </grid-dispctrl>
                                            </ng-container>


                                            <br>


                                            <div class="servicetypes_table">
                                                <grid #ManagedServiceGrid
                                                    [FieldTemplate]="(_Template | filterBy: {Group: 'Managedservices'})"
                                                    [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                                                    [GridDataTotalCount]="_DataTotalCount"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)">
                                                </grid>
                                            </div>
                                            <!-- [cellLock]="setCellLock" [parent]="this" -->

                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="selectedTab=='RateCard'">
                                        <!-- <ng-container
                                        *ngIf="(ManageServicesTemplate != null && ManageServicesTemplate != undefined && ManageServicesTemplate.length > 0)">
                                        <grid-dispctrl #overallgrid [FieldTemplate]="ManageServicesTemplate"
                                            [GridData]="ManagedServiceListData" [GridProp]="GridProp"
                                            [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" (ActionBarClick)="actButtonClick($event)"
                                            >
                                        </grid-dispctrl>
                                    </ng-container> -->
                                        <ng-container
                                            *ngIf="(_Template != null && _Template != undefined && _Template.length > 0)">
                                            <ng-container *ngIf="Flagoutput">
                                                <grid-dispctrl [FieldTemplate]="ServicesRCReport"
                                                    [GridData]="_Data_TCVReport" [GridProp]="GridProp"
                                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                                    (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)">
                                                </grid-dispctrl>
                                            </ng-container>






                                            <br>





                                            <div class="servicetypes_table">
                                                <grid #ManagedServiceGrid
                                                    [FieldTemplate]="(_Template | filterBy: {Group: 'RateCard'})"
                                                    [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                                                    [GridDataTotalCount]="_DataTotalCount"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)" [cellLock]="setCellLock"
                                                    [parent]="this">
                                                </grid>
                                            </div>
                                        </ng-container>
                                    </ng-container>


                                    <ng-container *ngIf="selectedTab=='PandC'">
                                        <!-- <ng-container
                                        *ngIf="(ManageServicesTemplate != null && ManageServicesTemplate != undefined && ManageServicesTemplate.length > 0)">
                                        <grid-dispctrl #overallgrid [FieldTemplate]="ManageServicesTemplate"
                                            [GridData]="ManagedServiceListData" [GridProp]="GridProp"
                                            [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)" (ActionBarClick)="actButtonClick($event)"
                                           >
                                        </grid-dispctrl>
                                    </ng-container> -->

                                        <ng-container
                                            *ngIf="(_Template != null && _Template != undefined && _Template.length > 0)">
                                            <ng-container *ngIf="Flagoutput">
                                                <grid-dispctrl [FieldTemplate]="ServicesPCReport"
                                                    [GridData]="_Data_TCVReport" [GridProp]="GridProp"
                                                    [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                                    (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)">
                                                </grid-dispctrl>
                                            </ng-container>






                                            <br>




                                            <div class="servicetypes_table">
                                                <grid #ManagedServiceGrid
                                                    [FieldTemplate]="(_Template | filterBy: {Group: 'PandC'})"
                                                    [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                                                    [GridDataTotalCount]="_DataTotalCount"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)" [cellLock]="setCellLock"
                                                    [parent]="this">
                                                </grid>
                                            </div>
                                        </ng-container>
                                    </ng-container>


                                    <ng-container *ngIf="selectedTab=='Tools'">


                                        <ng-container *ngIf="Flagoutput">
                                            <grid-dispctrl [FieldTemplate]="ServicesToolsReport"
                                                [GridData]="_Data_TCVReport" [GridProp]="GridProp"
                                                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                                (FieldChange)="onChange($event)"
                                                (ActionBarClick)="actButtonClick($event)">
                                            </grid-dispctrl>
                                        </ng-container>

                                        <br>

                                        <div class="left-padding">
                                            <!-- <ng-container *ngIf="Toolsflag">
                                                <ng-container *ngIf="(_Template!=null && _Template!=undefined && _Template.length > 0
             && (_Template | filterBy: {Group: 'SUBTOOL'}).length > 0)">
                                                    <ng-container *ngIf="!completedDeal">
                                                        <customform
                                                            [FormTemplate]="(_Template | filterBy: {Group: 'SUBTOOL'})"
                                                            [FormData]="TOOLCISSUBFIELDS" [FormProp]="ReportFormProp"
                                                            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                            (FieldClick)="onClick($event)"
                                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock"
                                                            [parent]="this">
                                                        </customform>
                                                    </ng-container>


                                                    <ng-container *ngIf="completedDeal">
                                                        <customform
                                                            [FormTemplate]="(_Template | filterBy: {Group: 'SUBTOOL'})"
                                                            [FormData]="TOOLCISSUBFIELDS" [FormProp]="ReportFormProp"
                                                            [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                                            (FieldClick)="onClick($event)"
                                                            (FieldChange)="onChange($event)" [cellLock]="setCellLock"
                                                            [parent]="this">
                                                        </customform>
                                                    </ng-container>


                                                </ng-container>
                                            </ng-container> -->
                                        </div>

                                        <div class="servicetypes_table">




                                            <ng-container *ngIf="(_Template!=null && _Template!=undefined && _Template.length > 0
                                                && (_Template | filterBy: {Group: 'TOOLCIS'}).length > 0)">
                                                <grid #ManagedServiceGrid
                                                    [FieldTemplate]="(_Template | filterBy: {Group: 'TOOLCIS'})"
                                                    [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                                                    [GridDataTotalCount]="_DataTotalCount"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)">
                                                </grid>
                                            </ng-container>




                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="selectedTab=='Hardware'">
                                        
                                        <ng-container *ngIf="Flagoutput">
                                            <grid-dispctrl [FieldTemplate]="ServicesHardwareReport"
                                                [GridData]="_Data_TCVReport" [GridProp]="GridProp"
                                                [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                                (FieldChange)="onChange($event)"
                                                (ActionBarClick)="actButtonClick($event)">
                                            </grid-dispctrl>
                                        </ng-container>

                                        <br>
                                        
                                        <div class="servicetypes_table">
           

                                            <ng-container *ngIf="(_Template!=null && _Template!=undefined && _Template.length > 0
                                                && (_Template | filterBy: {Group: 'TOOLHARDWARE'}).length > 0)">

                                                <grid #ManagedServiceGrid
                                                    [FieldTemplate]="(_Template | filterBy: {Group: 'TOOLHARDWARE'})"
                                                    [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                                                    [GridDataTotalCount]="_DataTotalCount"
                                                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                                    (ActionBarClick)="actButtonClick($event)" [cellLock]="setCellLock"
                                                    [parent]="this">
                                                </grid>
                                            </ng-container>


                                        </div>
                                    </ng-container>

                                </div>
                            </div>
                        </ng-container>
                        <!--End Here-->
                    </div>
                </ng-container>




                <br>

                <!--Action Buttons On Below Grid-->
                <div class="d-flex justify-content-end">
                    <ng-container
                        *ngIf="_Config.ManagedServiceButtons != null && _Config.ManagedServiceButtons != undefined">
                        <ng-container
                            *ngFor="let actionBar of _Config.ManagedServiceButtons | filterBy: {Group: 'Managedservices',HasPermission: true,IsApplicable: true}
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                            <button type="button" [ngClass]="actionBar.CustomClass"
                                (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                |
                                translate
                                }}
                            </button>
                        </ng-container>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Loader section -->
<div [hidden]="!_IsLoading">
    <loading [IsLoading]="_IsLoading" [IsFullScreen]="false"></loading>
</div>
<!--using modal popup component-->

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
        [curitem]="notify.item" (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>

<modal-popup id="offshorepopup" style="display: none;" [modaldetail]="modalInf">

    <ng-container *ngIf="year1offShoreFlag">

        <ng-container *ngIf="(_Template != null && _Template != undefined && _Template.length > 0)">
            <grid-dispctrl #overallgrid [FieldTemplate]="(_Template | filterBy: {Group: 'MSOffshoreVolume'})"
                [GridData]="OffshoreManagedSavedData" [GridProp]="_GridPropoffshoreVolume"
                [GridDataTotalCount]="offshoreLength" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                [cellLock]="setCellLock" [parent]="this">
            </grid-dispctrl>
        </ng-container>
        <br>
        <ng-container *ngIf="IsSupportTypeOnsiteflag"><span>Note: As this is on-site combination, offshore % is marked
                as non-editable and by default it will be 0 to generate the prices.</span></ng-container>
        <!--Action Buttons For Model Popup-->
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="_Config.ManagedServiceButtons != null && _Config.ManagedServiceButtons != undefined">
                <ng-container
                    *ngFor="let actionBar of _Config.ManagedServiceButtons | filterBy: {Group: 'MSOffshoreVolume'}
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        |
                        translate
                        }}
                    </button>
                </ng-container>
            </ng-container>
        </div>

    </ng-container>

</modal-popup>

<modal-popup id="productivitypopup" style="display: none;" [modaldetail]="modalInf">

    <ng-container>

        <ng-container *ngIf="(_Template != null && _Template != undefined && _Template.length > 0)">
            <grid-dispctrl #overallgrid [FieldTemplate]="(_Template | filterBy: {Group: 'RCProductivityInflation'})"
                [GridData]="productivitysavedData" [GridProp]="_GridPropoffshoreVolume"
                [GridDataTotalCount]="productivitylength" (FieldClick)="onClick($event)"
                (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
            </grid-dispctrl>
        </ng-container>
        <br>

        <!--Action Buttons For Model Popup-->
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="_Config.ManagedServiceButtons != null && _Config.ManagedServiceButtons != undefined">
                <ng-container
                    *ngFor="let actionBar of _Config.ManagedServiceButtons | filterBy: {Group: 'RCProductivityInflation'}
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        |
                        translate
                        }}
                    </button>
                </ng-container>
            </ng-container>
        </div>

    </ng-container>

</modal-popup>

<modal-popup id="ftecount" style="display: none;" [modaldetail]="modalInf">

    <ng-container>

        <ng-container *ngIf="(_Template != null && _Template != undefined && _Template.length > 0)">
            <grid-dispctrl #overallgrid [FieldTemplate]="(_Template | filterBy: {Group: 'RCFTECount'})"
                [GridData]="FTEsavedData" [GridProp]="_GridPropoffshoreVolume" [GridDataTotalCount]="ftelength"
                (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [cellLock]="setCellLock"
                [parent]="this">
            </grid-dispctrl>
        </ng-container>
        <br>

        <!--Action Buttons For Model Popup-->
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="_Config.ManagedServiceButtons != null && _Config.ManagedServiceButtons != undefined">
                <ng-container
                    *ngFor="let actionBar of _Config.ManagedServiceButtons | filterBy: {Group: 'RCFTECount'}
                                                | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        |
                        translate
                        }}
                    </button>
                </ng-container>
            </ng-container>
        </div>

    </ng-container>

</modal-popup>

<!--Model Popup for outputscreen-->

<modal-popup id="outputscreenpopup" style="display: none;" [modaldetail]="modalInf">

    <ng-container *ngIf="outputscreenNavigate">

        <h6>Please select the Price Benchmarking team Resource Involved/supported this deal (If Applicable)</h6>
        <span class="">If yes, please select the Resource name.&nbsp;</span>
        <span>If no, please select NA</span>
        <ng-container *ngIf="(_Template != null && _Template != undefined && _Template.length > 0)">
            <div>
                <customform #outputscreenDropdownForm [FormTemplate]="(_Template | filterBy: {Group: 'outputscreen'})"
                    [FormData]="outputscreenDropdown" [FormProp]="ReportFormProp1" [FormConfig]="FormConfig"
                    [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                </customform>
            </div>

        </ng-container>


        <br>
        <!--  <div class="d-flex align-items-baseline">
            <input type="checkbox" id="check" name="check" value="ok">
            <label for="check" class="align-self-center"> Kindly ensure that all the data has been entered and saved. No changes can be done once you move to Output screen.</label>
        </div> -->
        <label class="required"></label>
        <div class="form-check mb-2">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
                Kindly ensure that all the data has been entered and saved. No changes can be done once you move to
                Output screen.
            </label>
        </div>

        <!--Action Buttons For Model Popup-->
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="_Config.ManagedServiceButtons != null && _Config.ManagedServiceButtons != undefined">
                <ng-container
                    *ngFor="let actionBar of _Config.ManagedServiceButtons | filterBy: {Group: 'outputscreen'}">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        |
                        translate
                        }}
                    </button>
                </ng-container>
            </ng-container>
        </div>

    </ng-container>


    <div *ngIf="displayoutputNotifybox">
        <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
            [curitem]="notify.item" (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
    </div>
</modal-popup>