import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSharedService, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AuthenticationService } from '../auth/authentication.service';
import { EncryptDecryptService } from '../auth/encrypt-decrypt.service';
import { LoginService } from './login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  @Output() close = new EventEmitter();

  public emailid: string = '';
  public passwd: string = '';
  public otp: string = '';
  public showPassword: boolean = false;
  public showOTP: boolean = false;

  // public RegistryData: RegistryDetail = new RegistryDetail();
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();

  public userinfo: any;


  constructor(private loginService: LoginService, public _appSharedService: AppSharedService,
    private _authService: AuthenticationService, private _router: Router,
    private encryptDecryptService: EncryptDecryptService) { }

  ngOnInit() { }

  // closeLogin(): void {
  //   this.close.emit();
  // }

  togglePassword() {
    this.showPassword = !this.showPassword;

    let passField: HTMLInputElement = document.getElementById("txtPassword") as HTMLInputElement;

    if (!isNullOrUndefined(passField)) {
      if (passField.type === "password") {
        passField.type = "text";
      } else {
        passField.type = "password";
      }
    }
  }

  backToLogin(): void {
    this.showOTP = false;
  }

  ValidateUsrDetails(): void {

    this.emailid = (document.getElementById('txtEmailId') as HTMLInputElement).value;
    this.passwd = (document.getElementById('txtPassword') as HTMLInputElement).value;

    if (this.emailid.trim().length <= 0 || this.passwd.trim().length <= 0) {
      this.showNotificationMessage('', 'Please enter value in the required field(s).', 'VALID_RESET', 0,1000);
      this.displayNotifybox = true;

      return;
    }

    this.IsLoading = true;
    this.showOTP = false;
    this.userinfo = null;

    let mail = this.encryptDecryptService.encrypt(this.emailid.trim());
    let pass = this.encryptDecryptService.encrypt(this.passwd.trim());

    let odata = null;//{ EmailId: mail, SecretCode: pass };

    let subscribe$: Subscription = this.loginService.ValidateUsrDetails(odata).subscribe(res => {
      if (res !== undefined && res !== null) {
        if (res.Status === 200 && res.Data != undefined && res.Data !== null) {

          this.userinfo = res.Data;
          // res.Message

          this.showOTP = true;

          // if (!isNullOrUndefined(this.userinfo) && this.userinfo.UserId > 0) {
          //   this._appSharedService.IsAuthUser = true;
          //   this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

          //   this._appSharedService.setUserInfo(this.userinfo);

          //   this._authService.getAPIToken(this.emailid).then(result => {
          //     if (result) {
          //       this.showOTP = true;

          //       //this._router.navigate(['app/home']);
          //       // window.location.href = window.location.href;
          //       // alert('Successfully switched user, Please refresh to proceed');
          //     }
          //     else {
          //       alert('Sorry, unable to complete the authentication');
          //       // show as invalid mailid
          //     }

          //   });
          // }
          // else {
          //   alert(res.Message);
          // }

        }
        else {
          // alert(res.Message);
          this.showNotificationMessage('', res.Message, 'VALID_USER_FAIL', -1,1000);
          this.displayNotifybox = true;

        }

      }
      else {
        alert('Error on validating the external login.');
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on validating the external login.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  validateOTP(): void {
    
    this.otp = (document.getElementById('txtOTP') as HTMLInputElement).value;

    if (this.otp.trim().length <= 0) {
      this.showNotificationMessage('', 'Please enter OTP.', 'VALID_RESET', 0,1000);
      this.displayNotifybox = true;

      return;
    }

    this.IsLoading = true;
    let mail = this.encryptDecryptService.encrypt(this.emailid.trim());
    let otp = this.encryptDecryptService.encrypt(this.otp.trim());
    let odata = { EmailId: mail, SecretCode: otp };

    let subscribe$: Subscription = this.loginService.ValidateOTP(odata).subscribe(res => {
      if (res !== undefined && res !== null) {
        if (res.Status === 200 && res.Data != undefined && res.Data !== null) {
          //let response = res.Data;
          // this._router.navigate(['app/home']);

          if (!isNullOrUndefined(this.userinfo) && this.userinfo.UserId > 0) {
            this._appSharedService.IsAuthUser = true;
            this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

            this._appSharedService.setUserInfo(this.userinfo);

            this._authService.getAPIToken(this.emailid).then(result => {
              if (result) {
                this._router.navigate(['app/home']);
              }
              else {
                alert('Sorry, unable to complete the authentication');
                // show as invalid mailid
              }

            });
          }
          else {

          }

        }
        else {
          this.showNotificationMessage('', res.Message, 'VALID_OTP_FAIL', -1,1000);
          this.displayNotifybox = true;
        }
        
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on validating the external login.');
      });
  }

  // ResetLoginPassword(): void {

  //   this.emailid = (document.getElementById('txtEmailId') as HTMLInputElement).value;

  //   if (this.emailid.trim().length <= 0) {
  //     this.showNotificationMessage('', 'Please enter your Email.', 'VALID_RESET', 0);
  //     this.displayNotifybox = true;

  //     return;
  //   }

  //   let mail = this.encryptDecryptService.encrypt(this.emailid.trim());
  //   let odata = { EmailId: mail };

  //   let subscribe$: Subscription = this.loginService.ResetLoginPassword(odata).subscribe(res => {
  //     if (res !== undefined && res !== null) {
  //       if (res.Status === 200 && res.Data != undefined && res.Data !== null) {
  //         //let response = res.Data;

  //         if (!isNullOrUndefined(res.Message) && res.Message.length > 0) {
  //           this.showNotificationMessage('', 'Your password has been reset successfully and sent to your Email.', 'RESET_SUCCESS');
  //         }
  //         // else {
  //         //   this.showNotificationMessage('', res.Message, 'REG_FAILURE', false);
  //         // }

  //         this.displayNotifybox = true;

  //         this._router.navigate(['login']);

  //       }
  //       else {
  //         // alert(res.Message);
  //         this.showNotificationMessage('', res.Message, 'REG_FAILURE', -1);
  //         this.displayNotifybox = true;
  //       }
  //     }
  //     else {
  //       alert('Error on reseting the password.');
  //     }
  //     this.IsLoading = false;

  //   },
  //     err => {
  //       this.IsLoading = false;
  //       console.log('Error on reseting the password.');
  //     });

  // }

  setNotifyRes(event: any): void {
    // console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;

    switch (event.action.toUpperCase()) {
      case 'RESET_SUCCESS':

        if (event.result.toUpperCase() === 'OK') {
          // On successful reset, remain in same login page
          this._router.navigate(['login']);
        }
        break;

      case 'RESET_FAILURE':
        // On reset failure, no redirction
        if (event.result.toUpperCase() === 'OK') {

        }
        break;

      case 'VALID_RESET':
      case 'VALID_USER_FAIL':
      case 'VALID_OTP_FAIL':
        break;
    }

    if (canClose) {
      this.closeNotifybox();
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  showNotificationMessage(header: any = '', msg: any = '', action: any = '', successFlag: number = 1,timeout:number) {
    this.notify = {
      info: {
        header: header,
        message: msg,
        yes: 'OK',
        no: '',

        IsHeaderHidden: true,
        HeaderIcon: (successFlag === 1 ? 'fa fa-check-circle text-success' : (successFlag === 0 ? 'fa fa-exclamation-circle text-warning':'fa fa-exclamation-triangle text-danger')),
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: null,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }



}