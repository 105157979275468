import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { asEnumerable } from 'linq-es2015';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, isEmail, isNullOrUndefined, isStringNullOrEmpty } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AdminService } from '../admin.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';

@Component({
  selector: 'app-role-wise-menu-auth',
  templateUrl: './role-wise-menu-auth.component.html',
  styleUrls: ['./role-wise-menu-auth.component.css']
})
export class RoleWiseMenuAuthComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 3,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public ListTemplate: FieldTemplate[] = [];
  public EditTemplate: FieldTemplate[] = [];
  public EditData: any = {};
  public ListData: any[] = [];
  public ListDataALL: any[] = [];
  public Permissions: any = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public curItemsPerPage: number = 10;
  public selectedTab: string = "";
  public ParentId: number = -1;

  public GridProp: any = {
    Editable: true,
    // PaginationConfig: {
    //   ItemsPerPage: 15
    // },
    ActionBarConfig: {
      ActionBarList: null
    },   
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridDisplayControlComponent;

  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.getTemplate();
    }
  }

  getTemplate(): void {
    this.IsLoading = true;

    this.ParentId = -1;
    this.EditData = [];
    this.ListData = [];
    this.ListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        let template = res.Data.FieldTemplate!;

        this.ListTemplate = template.filter(x => x.Group === "roleWiseMenuList");
        this.EditTemplate = template.filter(x => x.Group === "roleWiseMenuEdit");

        this.setCompProperties(res.Data.Properties!);

        this.Permissions = res.Data.Permission;

        this.setActionButtonPermissions();

        this.EditData.IsEditable = 1;
        this.IsLoading = false;
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = [];

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });

    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.Permissions.indexOf(item.Name);
          if (peritem >= 0 && (item.Group === 'roleWiseMenuEdit')) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  GetData(): void {

    this.IsLoading = true;

    this.ListData = [];
    this.ListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getMenuDetails(this.ParentId, this.EditData.RoleId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (result.Data != null) {
          this.ListData = result.Data;
          console.log(this.ListData);
          this.dataTotalCount = this.ListData.length;

          this.ListDataALL = this.ListData;

          this.ListData.forEach((item) => {
            item.IsEditable = 1;
          });
        }

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }



  SaveEdit(): void {

    if (this.validateSave()) {

      this.IsLoading = true;


      this.ListData.forEach((item) => {
        item.RoleId = this.EditData.RoleId;
      });

      let subscribe$: Subscription = this.adminService.saveRoleWiseMenuAuth(this.ListData).subscribe(result => {
        if (!isNullOrUndefined(result)) {

          this.showNotificationMessage
            (
              result.Message,
              result.Status === ProcessStatus.Success && result.Message.match("exists") ? 2
                : result.Status === ProcessStatus.Success ? 1 : 3,
              '', null, '', '',1000
            );

          if (ProcessStatus.Success && !result.Message.match("exists")) {
            setTimeout(() => {
              this.getTemplate();
              this.IsLoading = false;
            }, 2000);
          } else {
            this.IsLoading = false;
          }
        }
      },

        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);

    }
  }

  validateSave(): boolean {

    if (isNullOrUndefined(this.EditData.RoleId) || this.EditData.RoleId == 0) {
      this.showNotificationMessage('Please select Role', 2, '', null, '', '',1000);
      return false;
    }

    if (isNullOrUndefined(this.EditData.HeaderMenu) || this.EditData.HeaderMenu == 0) {
      this.showNotificationMessage('Please select Header Menu', 2, '', null, '', '',1000);
      return false;
    }

    if (this.ListData.length == 0) {
      this.showNotificationMessage('No Records found to perform submit action', 2, '', null, '', '',1000);
      return false;
    }

    return true;
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.GetData();
  }

  actionButtonClick(data: any) {
    let valid: boolean = true;

    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.split('_')[0].toUpperCase()) {
        case 'CANCEL':
          this.getTemplate();
          break;
        case 'SAVE':
          if (this.validateSave()) {
            this.showNotificationMessage('Are you sure you want to save this Role-Menu Mapping?',
              2, 'SaveEdit', null, 'Yes', 'No',10000);
          }
          break;
      }
    }
  }

  onChange(event: any): void {
    let selitem: any = event.item;

    //this.RoleId = selitem.RoleId;
    //this.ParentId = -1;
    switch (event.fieldname.toLowerCase()) {
      case 'headermenu':
        this.ParentId = selitem.HeaderMenu;
        break;
      case 'menulevel2':
        this.ParentId = selitem.MenuLevel2;
        break;
      case 'menulevel3':
        this.ParentId = selitem.MenuLevel3;
        break;
      case 'menulevel4':
        this.ParentId = selitem.MenuLevel4;
        break;
    }

    //if (this.ParentId > 0)
    this.GetData();
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.ListData = asEnumerable(this.ListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.ListData.length;
    }
    else {
      this.ListData = this.ListDataALL;
      this.dataTotalCount = this.ListData.length;
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout:number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'SAVEEDIT':
        if (event.result.toUpperCase() === 'YES') {
          this.SaveEdit();
        }
        break;
      case 'DELETE':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "I";
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }
}
