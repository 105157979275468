
import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../../shared/app.sharedservice';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus, ReferenceValueType } from '../../app.interface';
import { CommonAPIService } from '../../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { BrowserModule, Title } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common'
import {ADHomeService } from './ad-home.service';
@Component({
  selector: 'app-ad-home',
  templateUrl: './ad-home.component.html',
  styleUrls: ['./ad-home.component.css']
})

@AutoUnsubscribe
export class ADHomeComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;


  public compTitle: string = 'Home';
  public selectedView: string = 'dashboard';

  public dashbaord: boolean = true;

  public selectedBtn: string = 'Recent';

  public _userId: any;

  public DashboardData: any = {};

  public SearchTemplate: FieldTemplate[] = [];
  public SearchButtons: any = [];
  public QuickLinksButton: any = [];
  public SearchPermission: any = [];
  public CaseInitiateButtons: any = [];
  public GlobalConfig: any = {};
  public UpdateInitiateCaseflag: boolean = false;
  public initiateFormData: any = {};
  public onholdcount: any;
  public cancelcount: any;
  public progresscount: any;
  public completedcount: any;
  public selectedDeal: any;


  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public dealListTemplate: FieldTemplate[] = [];
  public dealListData: any[] = [];
  public dealListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public dealheaderid: any;
  public dealitem: any;

  public infoListTemplate: FieldTemplate[] = [];
  public selectedInfoData: any;

  public curItemsPerPage: number = 10;

  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }

  public InfoFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': false
  };

  public Config: any = {
    ActionButtons: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public Title: any = 'Dashboard';
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];
  public SelectedButtonValue: any;
  public searchEvent: any = { 'item': {}, 'action': 'Clear' };

  public ShareDealTemplate: any[] = [];
  public displayShareDealNotifybox: boolean = true;
  public ShareDealData: any = {
    Code: null,
    CodeDescription: null,
    IsEditable: 1,
    ShareDealCode: null,
    ShareDealDesc: null
  };

  //public ShareDealData: any = [{ 'Code': '', 'CodeDescription': '' }];

  public ShareDealFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': true
  };

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridDisplayControlComponent;


  constructor(public datepipe: DatePipe,private title: Title, private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private ADhomeService: ADHomeService , private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);


  }

  ngOnInit() {
    this.title.setTitle(this.Title);
 
    this.getSearchTemplate();
    this.getDealCount();
  }

  getSearchTemplate(): void {

    this.SearchTemplate = [];
    this.dealListTemplate = [];
    this.searchEvent = { 'item': {}, 'action': 'Clear' };

    let odata: ComponentConfig = {
      Component: 'ADDashboard', ComponentCode: ComponentCodes.ADDashboard, ReferenceValueType: "1"
    };

    this.IsLoading = true;

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        console.log(res);
        let template = res.Data.FieldTemplate!;
        
        this.SearchPermission = res.Data.Permission;

        this.SearchTemplate = template.filter(x => x.Group === 'SearchFilterAd');
        this.dealListTemplate = template.filter(x => x.Group === 'ADDashboard');
       this.ShareDealTemplate = template.filter(x => x.Group === 'ShareDeal');
      this.ShareDealData.IsEditable = 1;

        this.setCompProperties(res.Data.Properties!);
        this.SearchButtons = this.Config.ActionButtons.filter((x: any) => x.Group === 'SearchAd');
        this.QuickLinksButton = this.Config.ActionButtons.filter((x: any) => x.Group === 'CreateDealAd');
        this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons.filter((x: any) => x.Group === 'SearchGridAd');
       
        this.setActionButtonPermissions();
        this.SearchData(this.searchEvent, true, false);
      }


    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      // this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
      //   JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      // this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // 2) Blocking Cols based on custom values
      this.Config.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  SearchData(event: any, allPendingFlag: boolean, searchClicked: boolean): void {

    if (searchClicked == true && !isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
      (isNullOrUndefined(event.item.Value) || isNullOrUndefined(event.item.Select)
        || event.item.Select.length == 0 || event.item.Value.trim().length == 0)) {

      //  alert("Please select both to proceed search")

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please select a column and value to proceed with Search.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }


    this.searchEvent = event;

    // this.IsLoading = true;

    this.dealListData = [];
    this.dealListDataALL = [];
    this.dataTotalCount = 0;

    let odata = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      allPendingFlag = true;
      odata = {};
    }

    let subscribe$: Subscription = this.ADhomeService.getDealDetailsBySearch(odata, allPendingFlag).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        // if(!isNullOrUndefined(result.Data) && result.Data.length > 0){
        //   result.Data.forEach((item : any) => {
        //     item.Status = "Completed";
        //   });
        // }

        // result.Data.forEach((item: any) => {
        //   item.Action = '1';
        // });

        this.dealListData = result.Data;
        //console.log(this.dealListData);
        //this.inprogressdata = this.dealListData.filter((x: any) => x.DealStatusDesc === 'In Progress');
        //this.completeddata = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Completed');
        this.dataTotalCount = this.dealListData.length;
        this.dealListDataALL = this.dealListData;


        /* if(this.selectedBtn == "Cancelled"){
          this.dataTotalCount=this.dealListData.filter((x: any) => x.DealStatusDesc === 'Cancelled').length;
          console.log()
        } */

        // this._toastr.success('Request stopped successfully', this.CompTitle);

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }

        }, 50);

      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  // setCellLock(fieldname: string, item: any, parent: HomeComponent): boolean {
  //   let bLock = false;

  //   if (!isNullOrUndefined(parent.Config.BlockCols) && parent.Config.BlockCols.length > 0) {
  //     parent.Config.BlockCols.forEach((blockitem: any) => {
  //       if (blockitem.Value === item[blockitem.Field] && (!isNullOrUndefined(blockitem.BlockCols)
  //         && blockitem.BlockCols.length > 0 && blockitem.BlockCols.filter((x: any) => x === fieldname.toUpperCase()).length > 0)) {
  //         bLock = true;
  //       }
  //     });
  //   }

  //   /// any other conditions
  //   // if (item.OfferStatus === 'OF003' &&
  //   //   (fieldname.toUpperCase() === 'OFFERSTATUS' || fieldname.toUpperCase() === 'EXPECTEDDOJ')) {
  //   //   bLock = true;

  //   // }


  //   return bLock;
  // }

  actButtonClick(event: any): void {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;
    }
  }

  countClick(bttn: any): void {
    switch (bttn) {
      case 'All':
        this.dashbaord = true;
        this.selectedBtn = "Recent";
        this.dataTotalCount = this.dealListData.length;
        break;
      case 'onHold':

        this.dashbaord = false;
        this.selectedBtn = "On Hold";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'On Hold').length;
        break;
      case 'cancel':

        this.dashbaord = false;
        this.selectedBtn = "Cancelled";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Cancelled').length;
        break;
      case 'Inprogress':

        this.dashbaord = false;
        this.selectedBtn = "In Progress";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'In Progress').length;
        break;
      case 'complete':

        this.dashbaord = false;

        this.selectedBtn = "Completed";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Completed').length;
        break;
    }

  }

  btnClick(butt: any): void {
    console.log(JSON.stringify(butt) + "button name");
    switch (butt.Name.toUpperCase()) {
      case 'CREATENEWDEAL':
        this._router.navigate(['app/create-deal']);

        break;

    }
  }

  exportToExcel() {

    if (this.dealListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = 'APP-Home ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()


      this.Config.DownloadColumns = JSON.parse(`[{"FieldName":"DealId","SeqOrder":1},{"FieldName":"ThorId","SeqOrder":2},{"FieldName":"Client","SeqOrder":3},{"FieldName":"DealName","SeqOrder":4},{"FieldName":"Country","SeqOrder":5},{"FieldName":"Region","SeqOrder":6},{"FieldName":"DealStatusDesc","SeqOrder":7}]`);
      console.log(this.Config.DownloadColumns, "columns****", this.dealListData, "****", this.dealListTemplate);
      let tempdata = [];
      // tempdata.push({ Name: 'Search Data', Data: this.reqListData });

      tempdata.push({
        Name: 'Search Data',
        Data: this.dealListData,
        ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null,
        fldtemplate: this.dealListTemplate
      });

      // this.excelexport.generateExcelTable(tempdata);
      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  getLoginId() {
    let email: string = '';
    email = this._appSharedService.UserInfo.email;
    let subscribe$: Subscription = this._commonAPIService.getUserRole(email).subscribe(async (result: any) => {
      let x = result.Data
      x.map((e: any) => {
        this._userId = e.UserId;

        console.log(this._userId, "***");
        return this._userId;

      })

    })
    this.subscriptionList$.push(subscribe$);



  }

  /*  getUserList(){
               let subscribe$: Subscription = this.ADhomeService.GetUserDetails(this._userId).subscribe(result => {
               console.log(result.Data,"*********");
               if (!isNullOrUndefined(result) && result.Status == 200) {
                 let x= result.Data;
                 x.map((e:any)=>{
                 this.ShareDealData=e.CodeDescription
                 })
         
                 this.IsLoading = false;
               }
             },
         
               err => {
                 this.IsLoading = false;
               });
             this.subscriptionList$.push(subscribe$); 
 
   } */

  getDealCount() {
    //this.getLoginId();
    let subscribe$: Subscription = this.ADhomeService.GetDealStatusCount("4").subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status == 200) {
        let data = result.Data;
        (data).map((e: any) => {

          if (e.DealStatus == "-1") { this.onholdcount = e.Count; }
          if (e.DealStatus == "0") { this.cancelcount = e.Count; }
          if (e.DealStatus == "1") { this.progresscount = e.Count; }
          if (e.DealStatus == "2") { this.completedcount = e.Count; }


        })
      }
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  getUserList() {

    let subscribe$: Subscription = this.ADhomeService.GetUserDetails(this.dealheaderid).subscribe(result => {

      if (!isNullOrUndefined(result) && result.Status == 200) {
        let x = result.Data;
        this.ShareDealData = result.Data;

        // x.map((e: any) => {
        //   //this.ShareDealData.dealdes = e.CodeDescription
        //   this.ShareDealData.ShareDealCode = e.Code;
        //   this.ShareDealData.ShareDealDesc = e.CodeDescription;
        //   this.ShareDealData.Code = e.Code;
        //   this.ShareDealData.CodeDescription = e.CodeDescription;
        // })

        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  clonedeal() {
    let dealheaderID = [
      {
        "DealHeaderId": this.dealheaderid
      }
    ]

    let subscribe$: Subscription = this.ADhomeService.CloneDeal(dealheaderID).subscribe(result => {
      if (!isNullOrUndefined(result)) {
        this.showNotificationMessage('Deal cloned successfully', 1, 'Success', '', '', 'ok',1000);
        this.ngOnInit();
      }

    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);


  }

  onClick(event: any): void {
    let selitem = event.item;
    if (event.item.DealHeaderId != undefined) {
      this.dealheaderid = event.item.DealHeaderId;
    }

    //console.log(this.dealheaderid)

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':

        // event.icon.IconCSS
        switch (event.icon.Value) {
          case '0':
            // Clone 
            this.showNotificationMessage('Are you sure you want to Clone the Deal?', 2, 'CLONEDEAL', selitem, 'Yes', 'No',10000);


            break;
          case '1':
            this.ShareDealData = [];
            this.ShareDealData.ShareDealCode = null;
            this.ShareDealData.DealHeaderId = this.dealheaderid;

            this.openModal('ShareDeal')
            break;
          case '2':
            // Cancel 
            this.showNotificationMessage('Are you sure you want to Cancel the Deal?', 2, 'CANCELDEAL', selitem, 'Yes', 'No',10000);

            break;
          case '3':
            // On Hold 
            this.showNotificationMessage('Are you sure you want to On Hold the Deal?', 2, 'ONHOLD', selitem, 'Yes', 'No',10000);

            break;
          case '4':
            // Resume 
            this.showNotificationMessage('Are you sure you want to Resume the Deal?', 2, 'RESUMEDEAL', selitem, 'Yes', 'No',10000);

            break;
          case '5':
            this.DownloadExcel(selitem.DealId);
            break;
        }

        break;

      case 'DEALID':
        this._router.navigate([`app/create-deal/${this.dealheaderid}`], { state: { dealData: selitem } });
        //this._router.navigate(['app/create-deal'], { state: { data: selitem } });
        break;
      case 'SHAREDEALCODE':
        //this.getUserList();
        break;
    }
  }

  onChange(event: any): void {
    let selitem: any = event.item;
    this.dealitem = event.item.ShareDealCode;
    console.log(event.item.ShareDealCode)
    // Emitting onchange event of resource plan to parent container
    selitem.IsModified = 1;
    selitem.IsRowHighlight = selitem.IsModified;

    switch (event.fieldname.toUpperCase()) {
      case 'STATUS':
        if ((isNullOrUndefined(selitem.OfferStatus) || selitem.OfferStatus === '') && !isNullOrUndefined(selitem.OldOfferStatus) && selitem.OldOfferStatusDesc !== '') {
          setTimeout(() => {
            selitem.Status = selitem.OldStatus
            selitem.StatusDesc = selitem.OldStatusDesc
            selitem.IsModified = 0;
            selitem.IsRowHighlight = selitem.IsModified;
          }, 50);
        }
        break;
      case 'SHAREDEALCODE':
        if (isNullOrUndefined(selitem.ShareDealCode != '' && selitem.ShareDealCode != null)) {
          setTimeout(() => {
            this.ShareDealData = selitem;
          }, 50);
        }
        break;
    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtons) && this.Config.ActionButtons.length > 0) {
      this.Config.ActionButtons.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          let peritem = this.SearchPermission.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
            item.IsApplicable = true;
          }
        }
      });
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string,timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  validateMandatoryFields(modifieditems: any[]): boolean {
    let valid: boolean = true;

    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
      this.showNotificationMessage('No rows are modified.', 2, '', null, '', '',1000);
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
      let OfferStatusList = modifieditems.filter(x => ((isNullOrUndefined(x.OfferStatus)) || x.OfferStatus === ''));

      if (!isNullOrUndefined(OfferStatusList) && OfferStatusList.length > 0) {
        this.showNotificationMessage('Please select offer status for modified rows.', 2, '', null, '', '',1000);
        valid = false;
      }

    }

    return valid;
  }

  /// Filter related functions ///
  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.dealListData = asEnumerable(this.dealListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.dealListData.length;
    }
    else {
      this.dealListData = this.dealListDataALL;
      this.dataTotalCount = this.dealListData.length;
    }
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'ONHOLD':
        if (event.result.toUpperCase() === 'YES') {
          this.updateDealStatus(event.item, '-1');
        }
        break;
      case 'CANCELDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this.updateDealStatus(event.item, '0');
        }
        break;
      case 'RESUMEDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this.updateDealStatus(event.item, '1');
        }
        break;
      case 'CLONEDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this.clonedeal();

        }
        break;

    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeShareDealNotifybox(): void {
    this.displayShareDealNotifybox = false;
    this.ShareDealData = [];
    // this.ShareDealData.ShareDealCode = null;
    // this.ShareDealData.ShareDealDesc = null;
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  openModal(id: string) {
    this.modalService.open(id);

    //this.getUserList();

  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  updateDealStatus(dealItem: any, dealStatus: string) {

    this.IsLoading = true;

    let odata: any = {
      DealHeaderId: dealItem.DealHeaderId,
      DealStatus: dealStatus,
    };
    let subscribe$: Subscription = this.ADhomeService.updateDealStatus(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Successfully Updated.', 1, '', null, '', '',1000);

        // dealItem.DealStatus = dealStatus;
        // dealItem.DealStatusDesc = 'On Hold';

        this.SearchData(this.searchEvent, true, false);
        this.getDealCount();

        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  validateShareDeal(): void {
    if (!isNullOrUndefined(this.ShareDealData) && !isNullOrUndefined(this.ShareDealData.ShareDealCode) && this.ShareDealData.ShareDealCode !== '') {
      this.updateShareDeal(this.dealheaderid, this.ShareDealData.ShareDealCode);

    } else {
      this.showShareDealNotificationMessage('Please select the deal.', 2, '', null, '', '',1000);
    }

  }

  showShareDealNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string,timeout:number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayShareDealNotifybox = true;
  }

  updateShareDeal(id: any, dealItem: any) {

    this.IsLoading = true;

    let subscribe$: Subscription = this.ADhomeService.updateShareDeal(id, dealItem).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Deal shared successfully', 1, '', null, '', '',1000);
        this.IsLoading = false;
        this.closeModal('ShareDeal');
      }
    },

      err => {
        this.IsLoading = false;
        this.closeModal('ShareDeal');
      });
    this.subscriptionList$.push(subscribe$);
  }
  DownloadExcel(DealId:any) {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.dealheaderid,
    }
    let subscribe$: Subscription = this.ADhomeService.DownloadExcel(odata).subscribe(res => {
      let date: any = new Date();
      let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
      console.log('TCV' + '_' + DealId + '_' + latest_date)
      this.downloadFile(res,'TCV' + '_' + DealId + '_' + latest_date);


      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }




  // downloadFile(data: Blob) {
  //   const contentType = 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet';
  //   const blob = new Blob([data], { type: contentType });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
  public downloadFile(response: any, fileName: string) {
    fileName = fileName;
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');

  }




}

