
import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, isNullOrUndefined } from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ReportModel } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AMReportsService } from './am-reports.service';
import { TabSetComponent } from 'src/modules/tabset/tabset.component';
import { ChartDetails, ReportFormDetails } from './am-reports.interface';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-am-reports',
  templateUrl: './am-reports.component.html',
  styleUrls: ['./am-reports.component.css']
})
export class AMReportsComponent implements OnInit {

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;

  public ReportsFormTemplate: FieldTemplate[] = [];
  public PastDealcomparsion_secondForm: FieldTemplate[] = [];
  public ReportsGridTemplate: FieldTemplate[] = [];
  public ReportsGridTemplate_PastDealComparison: FieldTemplate[] = [];

  public RevenueComparison: FieldTemplate[] = [];
  public CMGOPComparison: FieldTemplate[] = [];
  public BlendedRateComparison: FieldTemplate[] = [];
  public Financial: FieldTemplate[] = [];
  public ReportsGridTemplate_popup: FieldTemplate[] = [];
  public PyramidGridTemplate: FieldTemplate[] = [];
  public EffortsGridTemplate: FieldTemplate[] = [];
  public accordian_data_SS: any = [];
  public non_accordian_data_SS: any = [];
  public non_accordian_data: any = [];
  public ReportsTemplate: FieldTemplate[] = [];
  public IsLoading: boolean = false;
  public modelcomparisonflag: boolean = false;
  public showGrid_PastDealComparison: boolean = false;
  public popupheading_summarysol: any;
  public popupheading_pricing: any;
  public showflag: boolean = false;
  public openflag: boolean = false;
  public IsPagination: boolean = false;
  public AMConfigPermission: any = [];
  public ReportTYPE: any;
  public AMProperties: any = [];
  public arraySelecteditems: any = [];
  public applyListitems: any = [];
  public ReportFormData: ReportFormDetails = new ReportFormDetails();
  public GlobalConfig: any = {};
  public ModuleName: string = '';
  public FormGroupName: string = '';
  public GridGroupName: string = '';
  public AMReportsTabs: any;
  public chartsInput: ChartDetails = new ChartDetails();
  public chartsInputSecond: ChartDetails = new ChartDetails();
  public chartsInputThird: ChartDetails = new ChartDetails();
  public selectedTab: string = '';
  public selectedTabAPI: string = '';
  public selectedTabDetails: any;
  public chartPlotName: string = '';
  public chartPlotName_pastD1: string = '';
  public chartPlotName_pastD2: string = '';
  public chartPlotName_pastD3: string = '';
  public ReportGridData: any = [];
  public gridOutputPD_1: any = [];
  public gridOutputPD_2: any = [];
  public gridOutputPD_3: any = [];
  public gridOutputPD_4: any = [];
  public ReportGridData_popup: any = [];
  public AMModelComparisonFormButtons: any = [];
  public AMModelComparisonGridButtons: any = [];
  public DataTotalCount: number = 0;
  public ReportConfigDetails: any;
  public ReportDisplayName: string = '';
  public chartDisplayName: string = '';
  public subscriptionList$: Subscription[] = [];
  public showGrid: boolean = false;
  public showChart: boolean = false;
  public isOpened: boolean = false;
  public showPopup: boolean = false;
  public showcompare_pd: boolean = false;
  public showPopup_pricingSummary: boolean = false;
  public ExcelFileName: string = '';
  public ExcelDownloadColumns: any = [];
  public Title: any = 'Reports';
  public gridforreveenue: any;
  public gridforcmcomparison: any;
  public BlendedRateGraph: any;
  public TotalRevenueGraph: any;
  public gridforwhatifmodel_data: any = [];
  public ProductivityPer: any;
  public DealDiscountPer: any;
  public BlendedRatePer_Ons: any;
  public BlendedRatePer_Off: any;
  public BlendedRatePer_Nea: any;
  public ContingencyPer_Ons: any;
  public ContingencyPer_Off: any;
  public ContingencyPer_Nea: any;
  public ExpensesPer: any;
  public prod_range: any;
  public expense_range: any;
  public Deal_range: any;
  public Blended_range1: any;
  public Blended_range2: any;
  public Blended_range3: any;
  public contigency_range1: any;
  public contigency_range2: any;
  public contigency_range3: any;
  public modalInf: ModalInterface = new ModalInterface();
  public gridforblendedratecomparison: any;
  public gridforfinancialdetails: any;
  public dealHeaderId: number = 0;
  public FormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    IsSortable: false
  }
  public _GridPropoffshoreVolume: any = {
    Editable: true,
    PaginationConfig: {
      // Required: true,
      // ItemsPerPage: 10,
      // PageList: false,
      // ServerLoad: false
    }
  };
  public GridProp1: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    IsSortable: false,

    AddNewRow: false,
    DefaultRow: [],
    DataObject: {},
    UniqueKeyName: ''
    // TableCSS: 'auto',
    // PaginationConfig: {
    //   ItemsPerPage: 10
    // },
    // ActionBarConfig: {
    //   ActionBarList: null
    // }
  }
  public Config: any = {
    ActionButtons: []
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };



  constructor(
    private _commonAPIService: CommonAPIService,
    private _route: ActivatedRoute,
    public tabs: TabSetComponent,
    private _appSharedService: AppSharedService,
    public AMReportsService: AMReportsService,
    private toastr: ToastrService,
    private title: Title,
    private modalService: ModalService) {
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void {
    this.title.setTitle(this.Title);
    this.ModuleName = '';
    this.ModuleName = this._route.snapshot.data['selectedModule'];
    //  this.dealHeaderId = this._appSharedService.currentDealData.DealHeaderId;
    //  console.log(this.dealHeaderId);
    // console.log(this._appSharedService.currentDealData);
    this.ReportTYPE = "";
    this.getDropDownTemplate();

  }

  getDropDownTemplate() {
    this.IsLoading = true;
    this.ReportsTemplate = [];
    this.ReportsFormTemplate = [];
    this.ReportsGridTemplate = [];

    let odata: ComponentConfig = {
      Component: 'AMReports', ComponentCode: ComponentCodes.AMReports, ReferenceValueType: "1"
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        // this.ChartDropDownTemplate = res.Data.FieldTemplate!;
        this.ReportsTemplate = res.Data.FieldTemplate!;
        this.AMConfigPermission = res.Data.Permission;
        this.AMProperties = res.Data.Properties;
        //this.ModelConfigTemplate = template.filter(x => x.Group === 'ModelConfigurationAM');        

        this.setCompProperties(res.Data.Properties!);
        let filteredTemplate = JSON.parse(JSON.stringify(this.ReportsTemplate));
        this.ReportsFormTemplate = filteredTemplate.filter((x: any) => x.Group === this.FormGroupName);
        // this.AMReportsTabs = this.Config.ActionButtons.filter((x: any) => x.Group === 'SolutionSummaryReports');

        this.ReportFormData.DealName = this._appSharedService.currentDealData.DealName;
        this.ReportFormData.ModelId = this._appSharedService.currentDealData.ModelID;
        this.PastDealcomparsion_secondForm = filteredTemplate.filter((x: any) => x.Group == 'AMPastDealComparison');
        this.AMModelComparisonFormButtons = this.Config.ModelComparisonButtons.filter((x: any) => x.Group === 'AMModelComparisonForm');
        this.AMModelComparisonGridButtons = this.Config.ModelComparisonButtons.filter((x: any) => x.Group === 'AMModelComparisonGrid');

        this.setActionButtonPermissions();
        //    this.IsLoading = false;

        // this.ReportsGridTemplate = filteredTemplate.filter((x: any) => x.Group === this.GridGroupName);
        // this.IsLoading = false;
        // this.navigateTabs(true);

      }

    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }



  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      // console.log(this.Config.ActionButtons)

      this.AMProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      let reportDetails = (Properties.filter(x => x.Name.toLowerCase() === 'ReportDetails'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ReportDetails'.toLowerCase())[0].JSONData.toString()) : []);

      this.ReportConfigDetails = reportDetails.filter((x: any) => x.ModuleName === this.ModuleName);


      if (!isNullOrUndefined(this.ReportConfigDetails) && this.ReportConfigDetails.length > 0) {

        this.ReportDisplayName = this.ReportConfigDetails[0].DisplayName;

        this.AMReportsTabs = this.ReportConfigDetails[0].Tabs;

        this.FormGroupName = this.ReportConfigDetails[0].FormGroupName;
        // this.GridGroupName = this.ReportConfigDetails[0].GridGroupName;

        // let defaultTabData = this.AMReportsTabs.filter((x: any) => x.IsDefault === true);

        // if (!isNullOrUndefined(defaultTabData) && defaultTabData.length > 0) {
        //   this.selectedTab = defaultTabData[0].Name;
        //   this.selectedTabAPI = defaultTabData[0].APIMethodName;
        // }
      }



      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });


    }

  }


  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtons) && this.Config.ActionButtons.length > 0) {
      this.Config.ActionButtons.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          // let peritem = this.AMConfigPermission.indexOf(item.Name);
          let peritem = this.AMConfigPermission.filter((x: any) => x === item.Name)
          if (!isNullOrUndefined(peritem) && peritem.length >= 0) {
            item.HasPermission = true;
            item.IsApplicable = true;
          }
        }
      });

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    }


    if (!isNullOrUndefined(this.AMReportsTabs) && this.AMReportsTabs.length > 0) {

      this.AMReportsTabs.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;

          let peritem = this.AMConfigPermission.indexOf(item.Name);
          // if (peritem >= 0 && item.Group === this.selectedTab) {
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  setSelectedTab(event: any) {

    this.selectedTab = '';
    this.selectedTab = event.selectedTab;

    let defaultTabData = this.AMReportsTabs.filter((x: any) => x.Name === this.selectedTab);

    if (!isNullOrUndefined(defaultTabData) && defaultTabData.length > 0) {
      this.selectedTabDetails = defaultTabData[0];
      this.selectedTab = this.selectedTabDetails.Name;
      this.selectedTabAPI = this.selectedTabDetails.APIMethodName;
      this.chartDisplayName = this.selectedTabDetails.DisplayName;
      this.GridGroupName = this.selectedTabDetails.GridGroupName;
    }

    // this.ReportsGridTemplate = [];
    // let filteredTemplate = JSON.parse(JSON.stringify(this.ReportsTemplate));
    // this.ReportsGridTemplate = filteredTemplate.filter((x: any) => x.Group === this.GridGroupName);

    if (!isNullOrUndefined(this.ReportFormData.DealName) && this.ReportFormData.DealName != '' &&
      !isNullOrUndefined(this.ReportFormData.ModelId) && this.ReportFormData.ModelId != '' &&
      !isNullOrUndefined(this.ReportFormData.ReportType) && this.ReportFormData.ReportType != '') {

      this.ReportGridData = [];
      this.chartsInput = new ChartDetails();
      this.chartsInputSecond = new ChartDetails();
      this.chartsInputThird = new ChartDetails();
      this.GetReportDetails(this.IsLoading);
    } else {
      this.IsLoading = false;
    }

  }

  selectedTabs(event: any) {

    this.selectedTab = '';
    // this.selectedTab = this.arraySelecteditems[0].ModelID;
    console.log(this.selectedTab)



  }


  onChange(event: any): void {
    console.log(event);
    let selitem: any = event.item;
    console.log(selitem);
    var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');


    this.ReportTYPE = this.ReportFormData.ReportType;
    // Emitting onchange event of resource plan to parent container
    event.item.IsModified = 1;
    this.ReportFormData.COLAYear2 = !isNullOrUndefined(event.item.Year2) ? event.item.Year2 : 0;
    this.ReportFormData.COLAYear3 = !isNullOrUndefined(event.item.Year3) ? event.item.Year3 : 0;
    this.ReportFormData.COLAYear4 = !isNullOrUndefined(event.item.Year4) ? event.item.Year4 : 0;
    this.ReportFormData.COLAYear5 = !isNullOrUndefined(event.item.Year5) ? event.item.Year5 : 0;
    this.ReportFormData.COLAYear6 = !isNullOrUndefined(event.item.Year6) ? event.item.Year6 : 0;
    this.ReportFormData.COLAYear7 = !isNullOrUndefined(event.item.Year7) ? event.item.Year7 : 0;



    if (event.fieldname == "ModelId" && (this.ModuleName === "solutionsummary" || this.ModuleName === "pricingsummary" || this.ModuleName === "businesscasesummary")) {
      let fieldTempData: any;
      fieldTempData = this.ReportsFormTemplate.filter(x => x.FieldName == event.fieldname);

      if (!isNullOrUndefined(fieldTempData) && fieldTempData.length > 0) {

        let listData = fieldTempData[0].Control.List;

        if (!isNullOrUndefined(listData) && listData.length > 0) {
          let selectedmodelid = listData.filter((x: any) => x.Code === event.item.ModelId);

          let dealcurrency = !isNullOrUndefined(selectedmodelid) && selectedmodelid.length > 0 ? selectedmodelid[0].UserDef1 : 0;
          let dealcurrencydesc = !isNullOrUndefined(selectedmodelid) && selectedmodelid.length > 0 ? selectedmodelid[0].UserDef2 : 0;

          event.item.Currency = dealcurrency;
          event.item.CurrencyDesc = dealcurrencydesc;
          event.item.ReportType = "AMRT001";
          this.ReportTYPE = event.item.ReportType;

        }
      }
    }

    switch (event.fieldname.toUpperCase()) {
      case 'DEALNAME': case 'MODELID': case 'REPORTTYPE':

        if (!isNullOrUndefined(this.ReportFormData.DealName) && this.ReportFormData.DealName != '' &&
          !isNullOrUndefined(this.ReportFormData.ModelId) && this.ReportFormData.ModelId != '' &&
          !isNullOrUndefined(this.ReportFormData.ReportType) && this.ReportFormData.ReportType != '') {
          // this.navigateTabs();
          this.GetReportDetails();
        }


        break;
      case 'DEALNAME': case 'MODELID': case 'CURRENCY': case 'YEAR2': case 'YEAR3': case 'YEAR4': case 'YEAR5': case 'YEAR6': case 'YEAR7':
        if (!isNullOrUndefined(this.ReportFormData.DealName) && this.ReportFormData.DealName != '' &&
          !isNullOrUndefined(this.ReportFormData.ModelId) && this.ReportFormData.ModelId != '' &&
          !isNullOrUndefined(this.ReportFormData.Currency) && this.ReportFormData.Currency != '') {
          // this.navigateTabs();
          this.GetReportDetails();
        }
        break;
      case 'ISSELECTED':
        if (this.ModuleName == ReportModel.whatifswithmodel) {
          this.showflag = false;
        }

        if (event.oldValue == undefined || event.oldValue == false) {
          this.arraySelecteditems.push(selitem);
          if (this.arraySelecteditems.length > 3) {
            this.toastr.warning("Maximum 3 Deals can be Compared");
            this.modelcomparisonflag = false;
          }
          console.log(this.arraySelecteditems);
        }
        if (event.oldValue == true) {
          this.arraySelecteditems.forEach((value: any, index: any) => {
            if (value == selitem) this.arraySelecteditems.splice(index, 1);
            console.log(this.arraySelecteditems);
          });
        }

        break;

      // case 'DEALNAME': case 'CURRENCY': 

      //   if (!isNullOrUndefined(this.ReportFormData.DealName) && this.ReportFormData.DealName != '' &&
      //   !isNullOrUndefined(this.ReportFormData.Currency) && this.ReportFormData.Currency != ''
      //    ) {
      //     // this.navigateTabs();
      //     this.GetReportDetails();
      //   }


      //   break;

    }

    if (this.ModuleName == ReportModel.whatifswithmodel) {
      var reportData_Pyramid = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade != 'Total');
      switch (event.fieldname.toUpperCase()) {

        case 'ONSHORE':
          reportData_Pyramid.forEach((item: any) => {

            item.Onshore = isFinite(item.Onshore) && item.Onshore !== null && item.Onshore != 0 && item.Onshore != '' ? Number(item.Onshore) : 0;


          });



          var reportData_Pyramid_SUM_ONS = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade == 'Total');
          let Onshore_SUM = reportData_Pyramid
            .filter((item: any) => item.Onshore)
            .map((item: any) => +item.Onshore)
            .reduce((sum: any, current: any) => sum + current);



          console.log(Onshore_SUM);
          reportData_Pyramid_SUM_ONS[0].Onshore = Onshore_SUM;
          break;

        case 'OFFSHORE':

          reportData_Pyramid.forEach((item: any) => {

            item.Offshore = isFinite(item.Offshore) && item.Offshore !== null && item.Offshore != 0 && item.Offshore != '' ? Number(item.Offshore) : 0;


          });

          var reportData_Pyramid_SUM_OFF = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade == 'Total');
          let Offshore_SUM = reportData_Pyramid
            .filter((item: any) => item.Offshore)
            .map((item: any) => +item.Offshore)
            .reduce((sum: any, current: any) => sum + current);
          console.log(Offshore_SUM);
          reportData_Pyramid_SUM_OFF[0].Offshore = Offshore_SUM;
          break;
        case 'NEARSHORE':

          reportData_Pyramid.forEach((item: any) => {

            item.Nearshore = isFinite(item.Nearshore) && item.Nearshore !== null && item.Nearshore != 0 && item.Nearshore != '' ? Number(item.Nearshore) : 0;


          });
          var reportData_Pyramid_SUM_OFF = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade == 'Total');
          let Nearshore_SUM = reportData_Pyramid
            .filter((item: any) => item.Nearshore)
            .map((item: any) => +item.Nearshore)
            .reduce((sum: any, current: any) => sum + current);
          console.log(Nearshore_SUM);
          reportData_Pyramid_SUM_OFF[0].Nearshore = Nearshore_SUM;
          break;
        case 'CALCULATEDVALUE':

          var reportData_Expenses = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'EffortsPer' && x.ModelID == this.selectedTab && x.LocationType != 'Total');

          reportData_Expenses.forEach((item: any) => {

            item.CalculatedValue = isFinite(item.CalculatedValue) && item.CalculatedValue !== null && item.CalculatedValue != 0 && item.CalculatedValue != '' ? Number(item.CalculatedValue) : 0;


          });
          var reportData_Expenses_sum = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'EffortsPer' && x.ModelID == this.selectedTab && x.LocationType == 'Total');
          let SUM_EXP = reportData_Expenses
            .filter((item: any) => item.CalculatedValue)
            .map((item: any) => +item.CalculatedValue)
            .reduce((sum: any, current: any) => sum + current);

          console.log(SUM_EXP);
          reportData_Expenses_sum[0].CalculatedValue = SUM_EXP;
          break;
      }

    }


    // this.servicetypsOnChange.emit(eventdata);

  }

  onClick(event: any): void {
    let field = event.item;
    console.log(field);
    switch (event.fieldname.toUpperCase()) {
      case 'SUMMARYSOLUTIONVIEWS':



        if (this.selectedTab == 'ModelSolutionStaffingView') {
          let nonAccordianData = this.non_accordian_data;
          if (event.item.SummarySolutionViews.endsWith('Total FTE')
            && this.ReportFormData.ReportType != 'AMRT004') {
            if (!isNullOrUndefined(this.accordian_data_SS)) {
              if (this.non_accordian_data_SS == this.ReportGridData) {
                this.non_accordian_data_SS = nonAccordianData;
              }
              else {
                this.non_accordian_data_SS = this.ReportGridData;
              }
              console.log(this.non_accordian_data_SS);
            }
            else {
              this.non_accordian_data_SS = this.ReportGridData;
            }
          }
          if (!event.item.SummarySolutionViews.endsWith('Total FTE') &&
            !event.item.SummarySolutionViews.endsWith('Total Deal level Governance FTE') &&
            !event.item.SummarySolutionViews.endsWith('Total Operational Governance FTE') &&
            !event.item.SummarySolutionViews.endsWith('Total FTE Incl Gov') &&
            this.ReportFormData.ReportType != 'AMRT004') {
            this.showPopup = true;
            this.OpensummarypopupClick(event.item.SummarySolutionViews);
          }
        }



        if (this.selectedTab == 'Productivity') {
          if (this.ReportFormData.ReportType != 'AMRT005'
            && this.ReportFormData.ReportType != 'AMRT004') {

            let accordian_data = [];
            let nonAccordianData = [];
            this.non_accordian_data = this.ReportGridData.filter((x: any) => x.DisplayLevel === 0);
            nonAccordianData = this.non_accordian_data;

            this.accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel == 1);
            accordian_data = this.accordian_data_SS.filter((x: any) => x.MasterSummarySolutionViews === field.SummarySolutionViews);
            let index = this.accordian_data_SS.findIndex((x: any) => x.MasterSummarySolutionViews === field.SummarySolutionViews);
            this.accordian_data_SS = accordian_data;


            if (!isNullOrUndefined(this.accordian_data_SS) && this.accordian_data_SS.length > 0) {
              if (this.non_accordian_data_SS == this.ReportGridData) {
                this.non_accordian_data_SS = nonAccordianData;
              }
              else {
                if (index !== -1) {

                  if (!this.non_accordian_data_SS.includes(...this.accordian_data_SS)) {


                    if (this.openflag == true) {
                      let itemindex: any = '';

                      let itemToFind = event.item;
                      itemindex = this.non_accordian_data_SS.findIndex((x: any) => x.SummarySolutionViews == itemToFind.SummarySolutionViews);
                      this.non_accordian_data_SS.splice(itemindex + 1, 0, ...this.accordian_data_SS);
                    }
                    else {
                      this.non_accordian_data_SS.splice(index + 1, 0, ...this.accordian_data_SS);
                      this.openflag = true;
                    }

                  }
                  else {
                    this.non_accordian_data_SS = nonAccordianData;
                    this.openflag = false;
                  }

                }
              }
              console.log(this.non_accordian_data_SS);
            }
            else {

            }
          }

        }


        if (this.selectedTab == 'LocationRatio') {
          if (this.ReportFormData.ReportType != 'AMRT005'
            && this.ReportFormData.ReportType != 'AMRT004') {

            let accordian_data = [];
            let nonAccordianData = [];
            this.non_accordian_data = this.ReportGridData.filter((x: any) => x.DisplayLevel === 0);
            nonAccordianData = this.non_accordian_data;

            this.accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel == 1);
            accordian_data = this.accordian_data_SS.filter((x: any) => x.MasterSummarySolutionViews === field.SummarySolutionViews);
            let index = this.accordian_data_SS.findIndex((x: any) => x.MasterSummarySolutionViews === field.SummarySolutionViews);
            this.accordian_data_SS = accordian_data;


            if (!isNullOrUndefined(this.accordian_data_SS) && this.accordian_data_SS.length > 0) {
              if (this.non_accordian_data_SS == this.ReportGridData) {
                this.non_accordian_data_SS = nonAccordianData;
              }
              else {
                if (index !== -1) {

                  if (!this.non_accordian_data_SS.includes(...this.accordian_data_SS)) {


                    if (this.openflag == true) {
                      let itemindex: any = '';

                      let itemToFind = event.item;
                      itemindex = this.non_accordian_data_SS.findIndex((x: any) => x.SummarySolutionViews == itemToFind.SummarySolutionViews);
                      this.non_accordian_data_SS.splice(itemindex + 1, 0, ...this.accordian_data_SS);
                    }
                    else {
                      this.non_accordian_data_SS.splice(index + 1, 0, ...this.accordian_data_SS);
                      this.openflag = true;
                    }

                  }
                  else {
                    this.non_accordian_data_SS = nonAccordianData;
                    this.openflag = false;
                  }

                }
              }
              console.log(this.non_accordian_data_SS);
            }
            else {
              // this.non_accordian_data_SS = this.ReportGridData;
            }
          }

        }


        // case 'CANDIDATENAME':
        //   this._router.navigate(['app/home/search/case', field.RequestId]);
        break;
      case 'PRICESUMMARYSOLUTIONVIEWS':



        if (this.selectedTab == 'PriceSummary') {

          let nonAccordianData1 = this.non_accordian_data;
          if (event.item.PriceSummarySolutionViews.endsWith('Total FTE Price') && this.ReportFormData.ReportType != 'AMRT004') {
            if (!isNullOrUndefined(this.accordian_data_SS)) {
              if (this.non_accordian_data_SS == this.ReportGridData) {
                this.non_accordian_data_SS = nonAccordianData1;
              }
              else {
                this.non_accordian_data_SS = this.ReportGridData;
              }
              console.log(this.non_accordian_data_SS);
            }
            else {
              this.non_accordian_data_SS = this.ReportGridData;
            }
          }
          if (!event.item.PriceSummarySolutionViews.endsWith('Total FTE Price') &&
            !event.item.PriceSummarySolutionViews.endsWith('Total Deal level Governance Price') &&
            !event.item.PriceSummarySolutionViews.endsWith('Total Operational Governance Price') &&
            !event.item.PriceSummarySolutionViews.endsWith('Total Transition Price') &&
            !event.item.PriceSummarySolutionViews.endsWith('Other Solution Cost') &&
            !event.item.PriceSummarySolutionViews.endsWith('Price to Client') &&
            this.ReportFormData.ReportType != 'AMRT004') {
            this.showPopup_pricingSummary = true;
            this.OpenPricingPopupClick(event.item.PriceSummarySolutionViews);
          }
        }


        if (this.selectedTab == 'PriceSummaryBlendedRate') {
          // if (this.ReportFormData.ReportType != 'AMRT004') {
          //   this.showPopup_pricingSummary = false;
          //   let nonAccordianData2 = this.non_accordian_data;


          //   if (!isNullOrUndefined(this.accordian_data_SS)) {
          //     if (this.non_accordian_data_SS == this.ReportGridData) {
          //       this.non_accordian_data_SS = nonAccordianData2;
          //     }
          //     else {
          //       this.non_accordian_data_SS = this.ReportGridData;
          //     }
          //     console.log(this.non_accordian_data_SS);
          //   }
          //   else {
          //     this.non_accordian_data_SS = this.ReportGridData;
          //   }

          // }
          if (this.ReportFormData.ReportType != 'AMRT004') {
            this.showPopup_pricingSummary = false;
            let accordian_data = [];
            let nonAccordianData2 = [];
            this.non_accordian_data = this.ReportGridData.filter((x: any) => x.DisplayLevel === 0);
            // nonAccordianData = this.non_accordian_data;
            nonAccordianData2 = this.non_accordian_data;

            this.accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel == 1);
            accordian_data = this.accordian_data_SS.filter((x: any) => x.MasterPriceSummarySolutionViews === field.PriceSummarySolutionViews);
            let index = this.accordian_data_SS.findIndex((x: any) => x.MasterPriceSummarySolutionViews === field.PriceSummarySolutionViews);
            this.accordian_data_SS = accordian_data;


            if (!isNullOrUndefined(this.accordian_data_SS) && this.accordian_data_SS.length > 0) {
              if (this.non_accordian_data_SS == this.ReportGridData) {
                this.non_accordian_data_SS = nonAccordianData2;
              }
              else {
                if (index !== -1) {

                  if (!this.non_accordian_data_SS.includes(...this.accordian_data_SS)) {


                    if (this.openflag == true) {
                      let itemindex: any = '';

                      let itemToFind = event.item;
                      itemindex = this.non_accordian_data_SS.findIndex((x: any) => x.PriceSummarySolutionViews == itemToFind.PriceSummarySolutionViews);
                      this.non_accordian_data_SS.splice(itemindex + 1, 0, ...this.accordian_data_SS);
                    }
                    else {
                      this.non_accordian_data_SS.splice(index + 1, 0, ...this.accordian_data_SS);
                      this.openflag = true;
                    }

                  }
                  else {
                    this.non_accordian_data_SS = nonAccordianData2;
                    this.openflag = false;
                  }

                }
              }
              console.log(this.non_accordian_data_SS);
            }
            else {
              // this.non_accordian_data_SS = this.ReportGridData;
            }
          }


        }


        if (this.selectedTab == 'PriceSummaryCMPer') {
          // if (this.ReportFormData.ReportType != 'AMRT004') {
          //   this.showPopup_pricingSummary = false;
          //   let nonAccordianData3 = this.non_accordian_data;


          //   if (!isNullOrUndefined(this.accordian_data_SS)) {
          //     if (this.non_accordian_data_SS == this.ReportGridData) {
          //       this.non_accordian_data_SS = nonAccordianData3;
          //     }
          //     else {
          //       this.non_accordian_data_SS = this.ReportGridData;
          //     }
          //     console.log(this.non_accordian_data_SS);
          //   }
          //   else {
          //     this.non_accordian_data_SS = this.ReportGridData;
          //   }

          // }

          if (this.ReportFormData.ReportType != 'AMRT004') {
            this.showPopup_pricingSummary = false;
            let accordian_data = [];
            let nonAccordianData3 = [];
            this.non_accordian_data = this.ReportGridData.filter((x: any) => x.DisplayLevel === 0);
            // this.non_accordian_data_SS = [];
            nonAccordianData3 = this.non_accordian_data;

            this.accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel == 1);
            accordian_data = this.accordian_data_SS.filter((x: any) => x.MasterPriceSummarySolutionViews === field.PriceSummarySolutionViews);
            let index = this.accordian_data_SS.findIndex((x: any) => x.MasterPriceSummarySolutionViews === field.PriceSummarySolutionViews);
            this.accordian_data_SS = accordian_data;


            if (!isNullOrUndefined(this.accordian_data_SS) && this.accordian_data_SS.length > 0) {
              if (this.non_accordian_data_SS == this.ReportGridData) {
                this.non_accordian_data_SS = nonAccordianData3;
              }
              else {
                if (index !== -1) {

                  if (!this.non_accordian_data_SS.includes(...this.accordian_data_SS)) {


                    if (this.openflag == true) {
                      let itemindex: any = '';

                      let itemToFind = event.item;
                      itemindex = this.non_accordian_data_SS.findIndex((x: any) => x.PriceSummarySolutionViews == itemToFind.PriceSummarySolutionViews);
                      this.non_accordian_data_SS.splice(itemindex + 1, 0, ...this.accordian_data_SS);
                    }
                    else {
                      this.non_accordian_data_SS.splice(index + 1, 0, ...this.accordian_data_SS);
                      this.openflag = true;
                    }

                  }
                  else {

                    this.non_accordian_data_SS = nonAccordianData3;
                    this.openflag = false;
                  }

                }
              }
              console.log(this.non_accordian_data_SS);
            }
            else {
              // this.non_accordian_data_SS = this.ReportGridData;
            }
          }

        }




    }
  }


  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        if (this.selectedTab == 'PriceSummary' && !isNullOrUndefined(this.popupheading_pricing)) {

          this.exportToExcel(this.ReportsGridTemplate_popup, this.ReportGridData_popup);
        }
        else {
          this.exportToExcel(this.ReportsGridTemplate, this.ReportGridData);
        }

        break;
      case 'FILTER':
        // this.showFilter();
        break;

    }
  }

  GetReportDetails(bLoad: boolean = false): void {
    // this.non_accordian_data_SS = [];
    // this.non_accordian_data = [];
    // this.accordian_data_SS = [];
    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    if (!bLoad) {
      this.IsLoading = true
    }

    let odata = {};

    if (this.ModuleName === ReportModel.Solution_summary) {
      odata = {
        DealName: this.ReportFormData.DealName,
        ModelId: this.ReportFormData.ModelId,
        ReportType: this.ReportFormData.ReportType,
        TabName: this.selectedTab
      }
    }
    else if (this.ModuleName === ReportModel.Pricing_summary) {
      odata = {
        DealName: this.ReportFormData.DealName,
        ModelId: this.ReportFormData.ModelId,
        ReportType: this.ReportFormData.ReportType,
        Currency: this.ReportFormData.Currency,
        TabName: this.selectedTab
      }
    }
    else if (this.ModuleName === ReportModel.Business_summary) {
      odata = {
        DealName: this.ReportFormData.DealName,
        ModelId: this.ReportFormData.ModelId,
        Currency: this.ReportFormData.Currency,
        COLAYear2: this.ReportFormData.COLAYear2,
        COLAYear3: this.ReportFormData.COLAYear3,
        COLAYear4: this.ReportFormData.COLAYear4,
        COLAYear5: this.ReportFormData.COLAYear5,
        COLAYear6: this.ReportFormData.COLAYear6,
        COLAYear7: this.ReportFormData.COLAYear7,

      }
    }

    else if (this.ModuleName === ReportModel.modelcomparison) {
      odata = {
        DealName: this.ReportFormData.DealName,
        Currency: this.ReportFormData.Currency

      }
    }

    else if (this.ModuleName === ReportModel.whatifswithmodel) {
      odata = {
        DealName: this.ReportFormData.DealName,
        Currency: this.ReportFormData.Currency

      }
    }

    this.showGrid = true;
    this.showChart = true;
    if (this.selectedTab === 'LocationRatio') {
      if (this.ReportFormData.ReportType !== 'AMRT001') {
        this.showChart = false;
      }

      if (this.ReportFormData.ReportType === 'AMRT004') {
        this.showGrid = false;
      }
    } else if (this.selectedTab === 'Productivity') {
      if (this.ReportFormData.ReportType === 'AMRT004') {
        this.showGrid = false;
        this.showChart = false;
      }
    }


    // Location Ratio -- if report type is Country grid should not display
    // Location Ratio -- if report type is Tower,SubTower,Country,Lotwise Chart should not display   


    let subscribe$: Subscription = this.AMReportsService.GetReportDetails(odata, this.selectedTabAPI, this.ModuleName).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        this.ReportGridData = res.Data;
        this.DataTotalCount = this.ReportGridData.length;
        if (this.ModuleName === ReportModel.Solution_summary) {

          this.non_accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel == 0);
          this.non_accordian_data = this.ReportGridData.filter((x: any) => x.DisplayLevel == 0);
          this.accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel == 1);
          console.log(this.non_accordian_data_SS);
          console.log(this.accordian_data_SS);

        }
        if (this.ModuleName === ReportModel.Pricing_summary) {
          this.non_accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel === 0);
          this.non_accordian_data = this.ReportGridData.filter((x: any) => x.DisplayLevel === 0);
          this.accordian_data_SS = this.ReportGridData.filter((x: any) => x.DisplayLevel === 1);
          console.log(this.non_accordian_data_SS);
          console.log(this.accordian_data_SS);

        }
        if (this.ModuleName === ReportModel.Business_summary) {
          if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.ModelId) && !isNullOrUndefined(this.ReportFormData.Currency)) {
            this.chartsInput = this.ReportGridData[0];
            this.chartsInputSecond = this.ReportGridData[1];
            this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');
          }
          else {
            this.chartsInput = new ChartDetails();
            this.chartsInputSecond = new ChartDetails();

          }
        }
        if (this.ModuleName === ReportModel.Pricing_summary || this.ModuleName === ReportModel.Solution_summary) {
          if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.ModelId) && !isNullOrUndefined(this.ReportFormData.ReportType)) {
            this.chartsInput = this.ReportGridData[0];
            this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');
          }
          else {
            this.chartsInput = new ChartDetails();

          }
        }


        this.ReportsGridTemplate = [];
        let filteredTemplate = JSON.parse(JSON.stringify(this.ReportsTemplate));
        let GridfilteredTemplate = filteredTemplate.filter((x: any) => x.Group === this.GridGroupName);

        this.ExcelDownloadColumns = [];
        let downloadColumns = JSON.parse(JSON.stringify(this.Config.ExcelDownloadColumns));

        if (!isNullOrUndefined(downloadColumns) && downloadColumns.length > 0 &&
          downloadColumns.filter((x: any) => x.GridGroupName === this.GridGroupName).length > 0) {
          this.ExcelDownloadColumns = downloadColumns.filter((x: any) => x.GridGroupName === this.GridGroupName)[0].Columns;
          if (this.showPopup_pricingSummary) {
            this.ExcelDownloadColumns = downloadColumns.filter((x: any) => x.GridGroupName == 'AMSummaryCountryFTEPrice')[0].Columns;
          }

        }

        for (let index = 1; index <= this.ReportGridData[0].DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = GridfilteredTemplate.filter((x: any) => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }

          if (!isNullOrUndefined(this.ExcelDownloadColumns) && this.ExcelDownloadColumns.length > 0) {
            let propData = this.ExcelDownloadColumns.filter((x: any) => x.FieldName === fieldName);

            if (!isNullOrUndefined(propData) && propData.length > 0) {
              propData[0].IsHidden = false;
            }
          }
        }

        this.ReportsGridTemplate = filteredTemplate.filter((x: any) => x.Group === this.GridGroupName);
        this.PyramidGridTemplate = filteredTemplate.filter((x: any) => x.Group == 'AMWhatifsModelPyramidPer');
        this.EffortsGridTemplate = filteredTemplate.filter((x: any) => x.Group == 'AMWhatifsModelEffortsPer');
        this.ReportsGridTemplate_popup = filteredTemplate.filter((x: any) => x.Group == 'AMSummaryCountryFTEPrice');
        this.ReportsGridTemplate_PastDealComparison = filteredTemplate.filter((x: any) => x.Group == 'AMPastDealComparisonUploadGrid');
      }
      else if (res == null) {
        this.ReportsGridTemplate = [];
        this.ReportGridData = [];
        this.non_accordian_data_SS = [];
        this.non_accordian_data = [];
        this.accordian_data_SS = [];
      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on GetReportDetails.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  exportToExcel(gridTemplate: any, griddata: any) {

    if (griddata.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = this.chartDisplayName + ' ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      // tempdata.push({ Name: 'Search Data', Data: requestData });
      // let downloadColumns = JSON.parse(JSON.stringify(this.Config.ExcelDownloadColumns));

      // if (!isNullOrUndefined(downloadColumns) && downloadColumns.length > 0 &&
      //   downloadColumns.filter((x: any) => x.GridGroupName === this.GridGroupName).length > 0) {
      //   this.ExcelDownloadColumns = downloadColumns.filter((x: any) => x.GridGroupName === this.GridGroupName)[0].Columns;
      //   if (this.showPopup_pricingSummary) {
      //     this.ExcelDownloadColumns = downloadColumns.filter((x: any) => x.GridGroupName == 'AMSummaryCountryFTEPrice')[0].Columns;
      //   }

      // }

      if (this.selectedTab == 'PriceSummary' && !isNullOrUndefined(this.popupheading_pricing)) {

        this.ExcelDownloadColumns = [];
        let popupdownloadColumns = JSON.parse(JSON.stringify(this.Config.ExcelDownloadColumns));

        if (!isNullOrUndefined(popupdownloadColumns) && popupdownloadColumns.length > 0 &&
          popupdownloadColumns.filter((x: any) => x.GridGroupName === 'AMSummaryCountryFTEPrice').length > 0) {
          this.ExcelDownloadColumns = popupdownloadColumns.filter((x: any) => x.GridGroupName === 'AMSummaryCountryFTEPrice')[0].Columns;
        }

        for (let index = 1; index <= griddata[0].DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();
          if (!isNullOrUndefined(this.ExcelDownloadColumns) && this.ExcelDownloadColumns.length > 0) {
            let propData = this.ExcelDownloadColumns.filter((x: any) => x.FieldName === fieldName);

            if (!isNullOrUndefined(propData) && propData.length > 0) {
              propData[0].IsHidden = false;
            }
          }
        }

      }
      tempdata.push({
        Name: this.chartDisplayName, Data: griddata
        , ColumnList: !isNullOrUndefined(this.ExcelDownloadColumns) && this.ExcelDownloadColumns.length > 0 ? this.ExcelDownloadColumns.filter((s: any) => s.IsHidden === false).map((x: any) => x.FieldName) : null
        , FieldTemplate: gridTemplate
      });

      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  setCellLock(fieldname: string, item: any, parent: AMReportsComponent): boolean {

    let bLock = false;
    let BlkCols: any[] = [];


    if (!isNullOrUndefined(parent.AMProperties.BlockCols) && parent.AMProperties.BlockCols.length > 0) {
      parent.AMProperties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value == item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }
    return bLock;

  }




  isOpen() {
    return this.isOpened;
  }

  toggle() {
    this.isOpened = !this.isOpened;
    console.log(this.isOpened)
  }



  getHeaders() {
    let headers: string[] = [];
    if (this.gridforreveenue) {
      this.gridforreveenue.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }


    return headers;
  }
  getHeaders1() {
    let headers: string[] = [];

    if (this.gridforcmcomparison) {
      this.gridforcmcomparison.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }

    return headers;
  }
  getHeaders2() {
    let headers: string[] = [];

    if (this.gridforblendedratecomparison) {
      this.gridforblendedratecomparison.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }

    return headers;
  }
  getHeaders3() {
    let headers: string[] = [];

    if (this.gridforfinancialdetails) {
      this.gridforfinancialdetails.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }
    return headers;
  }
  ButtonClick(data: any, name: any) {
    console.log(data);
    if (name == 'EXPORTTOEXCEL') {
      this.GetWhatifswithModelExcel();
    }

    if (name == 'DisplayDeals') {

      this.GetDisplayDealsInPastDealComparison();
    }
    if (name == 'Cancel') {
      this.ReportFormData.Currency = '';
      this.ReportFormData.DealYearFrom = '';
      this.ReportFormData.DealYearTo = '';
      this.ReportFormData.DealTermMonthsFrom = '';
      this.ReportFormData.DealTermMonthsTo = '';
      this.ReportFormData.TCVRangeFrom = '';
      this.ReportFormData.TCVRangeTo = '';
      this.ReportFormData.DealOutcome = '';
      this.ReportFormData.PrimarySBU = '';
      this.ReportFormData.ContractType = '';
      this.ReportGridData = [];
      this.showGrid_PastDealComparison = false;
      this.showcompare_pd = false;
    }


    if (name == 'Compare') {

      if (this.ModuleName === "PastDealComparison") {

        let listpast_deal = this.arraySelecteditems.map((item: any) => { return item.DealUploadId }).join(',');
        if (this.arraySelecteditems.length > 0 && this.arraySelecteditems.length <= 3) {
          let odata = {

            Currency: this.ReportFormData.Currency,
            DealName: this.ReportFormData.DealName,
            ModelId: this.ReportFormData.ModelId,
            DealUploadSelectedList: listpast_deal,


          }
          if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.ModelId)) {
            this.GetPastDealComparisonSummary(odata);
            this.showcompare_pd = true;
          }
          else {
            this.toastr.warning("select DealName and Model ID");
          }


        }
        else {

          this.toastr.warning("select atleast 1 deal and maximum 3 deals to be compared");
        }




      }






    }

  }


  actionButtonClick(data: any) {
    console.log(data)
    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        case 'DISPLAYDEALS':
          if (this.ModuleName === "modelcomparison") {
            this.modelcomparisonflag = false;
            if (!isNullOrUndefined(this.ReportFormData.DealName) && this.ReportFormData.DealName != '' &&
              !isNullOrUndefined(this.ReportFormData.Currency) && this.ReportFormData.Currency != '') {
              // this.navigateTabs();
              this.arraySelecteditems = [];
              this.chartsInput = new ChartDetails();
              this.chartsInputSecond = new ChartDetails();
              this.chartsInputThird = new ChartDetails();
              this.GetReportDetails();

            }
          }
          if (this.ModuleName === "whatifswithmodel") {

            if (!isNullOrUndefined(this.ReportFormData.DealName) && this.ReportFormData.DealName != '' &&
              !isNullOrUndefined(this.ReportFormData.Currency) && this.ReportFormData.Currency != '') {
              // this.navigateTabs();

              this.arraySelecteditems = [];
              //   this.chartsInput = new ChartDetails();
              //  this.chartsInputSecond = new ChartDetails();
              //this.chartsInputThird = new ChartDetails();
              this.modelcomparisonflag = false;
              this.GetReportDetails();


            }
          }
          break;
        case 'RESET':
          this.ReportFormData.DealName = '';
          this.ReportFormData.Currency = '';
          this.ReportGridData = [];
          this.showflag = false;
          break;
        case 'COMPAREDEALS':

          if (this.ModuleName == 'modelcomparison') {
            this.gridforreveenue = [];
            this.gridforcmcomparison = [];
            this.gridforblendedratecomparison = [];
            this.gridforfinancialdetails = [];
            this.chartsInput = new ChartDetails();
            this.chartsInputSecond = new ChartDetails();
            this.chartsInputThird = new ChartDetails();
            this.chartPlotName = '';
            this.GetRevenueComparison(this.ReportFormData.DealName, this.ReportFormData.Currency);
          }


          if (this.ModuleName == 'whatifswithmodel') {
            this.showflag = true;
            if (this.arraySelecteditems.length > 0) {
              this.selectedTab = this.arraySelecteditems[0].ModelID;
            }

            this.prod_range = 0;
            this.expense_range = 0;
            this.Deal_range = 0;
            this.Blended_range1 = 0;
            this.Blended_range2 = 0;
            this.Blended_range3 = 0;
            this.contigency_range1 = 0;
            this.contigency_range2 = 0;
            this.contigency_range3 = 0;
            this.applyListitems = [];
            this.GetWhatifsModelSummary(this.ReportFormData);

          }
          break;

      }
    }
  }



  GetRevenueComparison(deal: any, currency: any) {
    this.gridforreveenue = [];
    this.gridforcmcomparison = [];
    this.gridforblendedratecomparison = [];
    this.gridforfinancialdetails = [];
    console.log(this.arraySelecteditems);
    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    this.chartsInputThird = new ChartDetails();
    var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');
    console.log(CSVOf_arr);
    if (this.arraySelecteditems.length > 3) {
      this.toastr.warning("Maximum 3 deals can be compared");
      this.modelcomparisonflag = false;
    }
    else {
      let odata: any = {
        DealName: deal,
        Currency: currency,
        ModelIdSelectedList: CSVOf_arr
      }
      let subscribe$: Subscription = this.AMReportsService.GetRevenueComparison(odata).subscribe(result => {
        if (result !== undefined && result !== null) {
          console.log(result, "@@@@@")
          this.gridforreveenue = JSON.parse(result.Data[0].GridOutput);
          this.gridforcmcomparison = JSON.parse(result.Data[1].GridOutput);
          this.gridforblendedratecomparison = JSON.parse(result.Data[2].GridOutput);
          this.gridforfinancialdetails = JSON.parse(result.Data[3].GridOutput);
          console.log(this.gridforreveenue);
          this.modelcomparisonflag = true;
          if (this.ModuleName === ReportModel.modelcomparison) {
            if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.Currency)) {
              // this.chartsInput = this.ReportGridData[0];
              // this.chartsInputSecond = this.ReportGridData[1];

              this.chartsInput = result.Data[0];
              this.chartsInputSecond = result.Data[1];
              this.chartsInputThird = result.Data[2];
              this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');
            }
            else {
              this.chartsInput = new ChartDetails();
              this.chartsInputSecond = new ChartDetails();
              this.chartsInputThird = new ChartDetails();


            }
          }
          //  this.getHeaders(this.gridforreveenue);
        }
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting GetDealDetails.');
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
    }

  }
  GetWhatifsModelSummary(data: any) {
    let prodper = [];
    let dealdiscountper = [];
    let BlendedRatePer = [];
    let ContingencyPer = [];
    let Blenderatepergraph = [];
    let ExpensesPerGraph = [];
    let odata: any
    var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');
    console.log(CSVOf_arr);
    if (this.arraySelecteditems.length > 3) {
      this.toastr.warning("Maximum 3 deals can be compared");
      this.modelcomparisonflag = false;
    }
    else {

      odata = {
        DealName: this.ReportFormData.DealName,
        Currency: this.ReportFormData.Currency,
        ModelIdSelectedList: CSVOf_arr,
        NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
        NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
        NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
        NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
        NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
        NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
        NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
        NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
        NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
        NModelId: this.selectedTab,
        NDataCount: this.applyListitems.length
      }
      let subscribe$: Subscription = this.AMReportsService.GetWhatifsModelSummary(odata).subscribe(result => {
        if (result !== undefined && result !== null) {
          console.log(result, "@@@@@")
          this.gridforwhatifmodel_data = result.Data;
          prodper = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'ProductivityPer' && x.ModelID == this.selectedTab);
          this.ProductivityPer = prodper[0].CalculatedValue;
          dealdiscountper = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'DealDiscountPer' && x.ModelID == this.selectedTab);
          this.DealDiscountPer = dealdiscountper[0].CalculatedValue;
          BlendedRatePer = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'BlendedRatePer' && x.ModelID == this.selectedTab);
          this.BlendedRatePer_Ons = BlendedRatePer[0].Onshore;
          this.BlendedRatePer_Off = BlendedRatePer[0].Offshore;
          this.BlendedRatePer_Nea = BlendedRatePer[0].Nearshore;
          ContingencyPer = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'ContingencyPer' && x.ModelID == this.selectedTab);
          this.ContingencyPer_Ons = ContingencyPer[0].Onshore;
          this.ContingencyPer_Off = ContingencyPer[0].Offshore;
          this.ContingencyPer_Nea = ContingencyPer[0].Nearshore;


          Blenderatepergraph = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'BlendedRatePer' && x.ModelID == this.selectedTab);
          this.BlendedRateGraph = JSON.parse(Blenderatepergraph[0].ChartOutput);
          ExpensesPerGraph = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'ExpensesPer' && x.ModelID == this.selectedTab);
          this.ExpensesPer = ExpensesPerGraph[0].CalculatedValue;
          this.TotalRevenueGraph = JSON.parse(ExpensesPerGraph[0].ChartOutput);
          // this.gridforreveenue = JSON.parse(result.Data[0].GridOutput);
          // this.gridforcmcomparison = JSON.parse(result.Data[1].GridOutput);
          // this.gridforblendedratecomparison = JSON.parse(result.Data[2].GridOutput);
          // this.gridforfinancialdetails = JSON.parse(result.Data[3].GridOutput);
          // console.log(this.gridforreveenue);
          // this.modelcomparisonflag = true;
          // if (this.ModuleName === ReportModel.modelcomparison) {
          //   if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.Currency)) {
          //     // this.chartsInput = this.ReportGridData[0];
          //     // this.chartsInputSecond = this.ReportGridData[1];

          //     this.chartsInput = result.Data[0];
          //     this.chartsInputSecond = result.Data[1];
          //     this.chartsInputThird = result.Data[2];
          //     this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');
          //   }
          //   else {
          //     this.chartsInput = new ChartDetails();
          //     this.chartsInputSecond = new ChartDetails();
          //     this.chartsInputThird = new ChartDetails();


          //   }
          // }
          // //  this.getHeaders(this.gridforreveenue);


          if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.Currency)) {
            // this.chartsInput = this.ReportGridData[0];
            // this.chartsInputSecond = this.ReportGridData[1];

            this.chartsInput = Blenderatepergraph[0];
            this.chartsInputSecond = ExpensesPerGraph[0];
            this.ReportTYPE = 'whatifswithmodel';
            this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');
          }
          else {
            this.chartsInput = new ChartDetails();
            this.chartsInputSecond = new ChartDetails();
            this.chartsInputThird = new ChartDetails();


          }
        }
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting GetDealDetails.');
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
    }

  }


  rangeChanged(event: any) {
    var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');
    console.log(CSVOf_arr);
    let odata: any;
    // = {
    //   DealName: this.ReportFormData.DealName,
    //   Currency: this.ReportFormData.Currency,
    //   ModelIdSelectedList: this.applyListitems,
    //   whatifsmodellist: '',
    //   NProductivity: '',
    //   NExpenses: '',
    //   NDealDiscount: '',
    //   NOnshoreBlendedRate: '',
    //   NOffshoreBlendedRate: '',
    //   NNearshoreBlendedRate: "",
    //   NOnshoreContingency: "",
    //   NOffshoreContingency: "",
    //   NNearshoreContingency: ""
    // }
    console.log(event);
    let APPLY_odata: any;
    this.applyListitems = (!isNullOrUndefined(this.applyListitems) && this.applyListitems.length > 0) ? this.applyListitems : this.gridforwhatifmodel_data.filter((x: any) => x.ModelID == this.selectedTab);
    switch (event.target.name) {
      case 'prod_range':

        this.prod_range = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }

        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        //this.GetWhatifsModelSummary(odata);
        break;
      case 'expense_range':
        this.expense_range = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }


        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems,

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        //  this.GetWhatifsModelSummary(odata);
        break;
      case 'Deal_range':
        this.Deal_range = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }



        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }
        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems,

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
        break;
      case 'Blended_range1':
        this.Blended_range1 = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }

        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }
        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems,

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        //  this.GetWhatifsModelSummary(odata);
        break;
      case 'Blended_range2':
        this.Blended_range2 = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }


        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        //this.GetWhatifsModelSummary(odata);
        break;
      case 'Blended_range3':
        this.Blended_range3 = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }




        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }
        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
        break;
      case 'contigency_range1':
        this.contigency_range1 = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }

        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems,

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
        break;
      case 'contigency_range2':
        this.contigency_range2 = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }
        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems,

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        //  this.GetWhatifsModelSummary(odata);
        break;
      case 'contigency_range3':
        this.contigency_range3 = event.target.value;
        odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
          NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
          NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
          NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
          NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
          NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
          NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
          NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
          NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
          NModelId: this.selectedTab
        }
        for (let i = 0; i < this.applyListitems.length; i++) {
          this.applyListitems[i].NModelId = this.selectedTab;
          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {
          DealName: this.ReportFormData.DealName,
          Currency: this.ReportFormData.Currency,
          ModelIdSelectedList: CSVOf_arr,
          whatifsmodellist: this.applyListitems

        }
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        this.GetWhatifsModelSummary(odata);
        break;


    }
  }


  ApplyButtonClick(clickData: any, Btname: any) {
    let odata: any;
    console.log("CLICKDATA");
    console.log(clickData);
    var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');
    var dataitemlist = clickData.filter((x: any) => x.ModelID == this.selectedTab);
    var reportData_Pyramid = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab);
    var reportData_Expenses = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'EffortsPer' && x.ModelID == this.selectedTab);
    console.log(reportData_Pyramid);
    console.log(reportData_Expenses);
    this.applyListitems = dataitemlist;
    console.log(this.applyListitems);
    console.log(CSVOf_arr);
    let APPLY_odata: any;



    for (let i = 0; i < this.applyListitems.length; i++) {
      this.applyListitems[i].NModelId = this.selectedTab;
      this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
      this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
      this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
      this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
      this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
      this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
      this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
      this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
      this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
    }
    APPLY_odata = {
      DealName: this.ReportFormData.DealName,
      Currency: this.ReportFormData.Currency,
      ModelIdSelectedList: CSVOf_arr,
      whatifsmodellist: this.applyListitems,
      NProductivity: this.prod_range,
      NExpenses: this.expense_range,
      NDealDiscount: this.Deal_range,
      NOnshoreBlendedRate: this.Blended_range1,
      NOffshoreBlendedRate: this.Blended_range2,
      NNearshoreBlendedRate: this.Blended_range3,
      NOnshoreContingency: this.contigency_range1,
      NOffshoreContingency: this.contigency_range2,
      NNearshoreContingency: this.contigency_range3,

    }


    odata = {
      DealName: this.ReportFormData.DealName,
      Currency: this.ReportFormData.Currency,
      ModelIdSelectedList: CSVOf_arr,
      NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
      NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
      NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
      NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
      NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
      NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
      NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
      NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
      NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
      NModelId: this.selectedTab
    }
    if (Btname == 'PyramidGrid') {
      if (this.Validations_sum_pyd_ons() && this.Validations_sum_pyd_off() && this.Validations_sum_pyd_nea()) {
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);

      }
      else {
        this.toastr.warning('Total % cannot  be  greater than 100%');
      }
    }
    if (Btname == 'EffortsGrid') {
      if (this.Validations_sum_exp()) {
        this.InsertWhatifswithModelTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
      }
      else {
        this.toastr.warning('Total % cannot  be  greater than 100%');
      }
    }



  }



  Validations_sum_pyd_ons(): boolean {
    let valid: boolean = false;

    let valid_enteries: any = [];
    var reportData_Pyramid = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade != 'Total');
    var reportData_Pyramid_SUM_ONS = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade == 'Total');
    reportData_Pyramid.forEach((item: any) => {

      item.Onshore = isFinite(item.Onshore) && item.Onshore !== null && item.Onshore != 0 && item.Onshore != '' ? Number(item.Onshore) : 0;


    });

    let Onshore_SUM = reportData_Pyramid
      .filter((item: any) => item.Onshore !== undefined || item.Onshore !== null)
      .map((item: any) => +item.Onshore || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    if (Onshore_SUM > 100) {
      this.toastr.warning('Total % cannot  be  greater than 100%');
      valid = false;
    }
    else {
      valid = true;
    }



    return valid
  }
  Validations_sum_pyd_off(): boolean {
    let valid: boolean = false;

    let valid_enteries: any = [];
    var reportData_Pyramid = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade != 'Total');
    var reportData_Pyramid_SUM_ONS = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade == 'Total');

    reportData_Pyramid.forEach((item: any) => {

      item.Offshore = isFinite(item.Offshore) && item.Offshore !== null && item.Offshore != 0 && item.Offshore != '' ? Number(item.Offshore) : 0;


    });

    let Offshore_SUM = reportData_Pyramid
      .filter((item: any) => item.Offshore !== undefined || item.Offshore !== null)
      .map((item: any) => +item.Offshore || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    console.log(Offshore_SUM);





    if (Offshore_SUM > 100) {
      this.toastr.warning('Total % cannot  be  greater than 100%');
      valid = false;
    }
    else {
      valid = true;
    }



    return valid
  }
  Validations_sum_pyd_nea(): boolean {
    let valid: boolean = false;

    let valid_enteries: any = [];
    var reportData_Pyramid = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade != 'Total');
    var reportData_Pyramid_SUM_ONS = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade == 'Total');



    reportData_Pyramid.forEach((item: any) => {

      item.Nearshore = isFinite(item.Nearshore) && item.Nearshore !== null && item.Nearshore != 0 && item.Nearshore != '' ? Number(item.Nearshore) : 0;


    });
    let Nearshore_SUM = reportData_Pyramid
      .filter((item: any) => item.Nearshore !== null && item.Nearshore !== undefined)
      .map((item: any) => +item.Nearshore || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    if (Nearshore_SUM > 100) {
      this.toastr.warning('Total % cannot  be  greater than 100%');
      valid = false;
    }
    else {
      valid = true;
    }



    //   valid_enteries.push(valid);
    // let totalvalidfalse = (valid_enteries.filter((x: any) => x === false)).length;
    // console.log(totalvalidfalse);
    // if (totalvalidfalse > 0) {
    //   valid = false;
    // }
    // console.log(totalvalidfalse);
    return valid
  }

  Validations_sum_exp(): boolean {
    let valid: boolean = false;


    var reportData_Expenses = this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'EffortsPer' && x.ModelID == this.selectedTab && x.LocationType != 'Total');

    reportData_Expenses.forEach((item: any) => {

      item.CalculatedValue = isFinite(item.CalculatedValue) && item.CalculatedValue !== null && item.CalculatedValue != 0 && item.CalculatedValue != '' ? Number(item.CalculatedValue) : 0;


    });
    let SUM_EXP = reportData_Expenses
      .filter((item: any) => item.CalculatedValue !== 0 || item.CalculatedValue !== null)
      .map((item: any) => +item.CalculatedValue || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    console.log(SUM_EXP);


    if (SUM_EXP > 100) {
      this.toastr.warning('Total % cannot  be  greater than 100%');
      valid = false;
    }
    else {
      valid = true;
    }


    //   valid_enteries.push(valid);
    // let totalvalidfalse = (valid_enteries.filter((x: any) => x === false)).length;
    // console.log(totalvalidfalse);
    // if (totalvalidfalse > 0) {
    //   valid = false;
    // }
    // console.log(totalvalidfalse);
    return valid
  }



  InsertWhatifswithModelTransactionLog(odata: any, getsumdata: any) {

    let subscribe$: Subscription = this.AMReportsService.InsertWhatifswithModelTransactionLog(odata).subscribe(result => {
      if (result !== undefined && result !== null) {

        console.log(result);

        this.GetWhatifsModelSummary(getsumdata);
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting InsertWhatifswithModelTransactionLog.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }




  OpensummarypopupClick(clickdata: any) {
    this.modalInf =
    {
      header: 'Country Wise Pyramid Details',
      size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
    };
    this.GetSolutionSummaryCountryFTEPopup(clickdata);
    this.openModal('Opensummarypopup');
  }



  OpenPricingPopupClick(clickdata: any) {

    this.modalInf =
    {
      header: 'Country Wise Pyramid Details',
      size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
    };
    this.GetPriceSummaryCountryPricePopup(clickdata);
    this.openModal('OpenPricingPopup');

  }

  GetSolutionSummaryCountryFTEPopup(data: any) {
    this.popupheading_summarysol = "";
    let odata = {
      DealName: this.ReportFormData.DealName,
      ModelId: this.ReportFormData.ModelId,
      ReportType: this.ReportFormData.ReportType,
      SummarySolutionViews: data,

    }
    this.popupheading_summarysol = data;
    let subscribe$: Subscription = this.AMReportsService.GetSolutionSummaryCountryFTEPopup(odata).subscribe(result => {
      if (result !== undefined && result !== null) {

        for (let index = 1; index <= result.Data[0].DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = this.ReportsGridTemplate_popup.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.ReportGridData_popup = result.Data;
        this.DataTotalCount = this.ReportGridData_popup.length;
        console.log(result);
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting InsertWhatifswithModelTransactionLog.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }



  GetPriceSummaryCountryPricePopup(data: any) {
    this.popupheading_pricing = "";
    let odata = {
      DealName: this.ReportFormData.DealName,
      ModelId: this.ReportFormData.ModelId,
      ReportType: this.ReportFormData.ReportType,
      Currency: this.ReportFormData.Currency,
      PriceSummarySolutionViews: data,

    }
    this.popupheading_pricing = data;
    let subscribe$: Subscription = this.AMReportsService.GetPriceSummaryCountryPrice(odata).subscribe(result => {
      if (result !== undefined && result !== null) {

        for (let index = 1; index <= result.Data[0].DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = this.ReportsGridTemplate_popup.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.ReportGridData_popup = result.Data;
        this.DataTotalCount = this.ReportGridData_popup.length;
        console.log(result);
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting InsertWhatifswithModelTransactionLog.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  GetDisplayDealsInPastDealComparison() {
    this.ReportGridData = [];
    let filteredTemplate = JSON.parse(JSON.stringify(this.ReportsTemplate));
    this.ReportsGridTemplate_PastDealComparison = filteredTemplate.filter((x: any) => x.Group == 'AMPastDealComparisonUploadGrid');
    this.RevenueComparison = filteredTemplate.filter((x: any) => x.Group == 'RevenueComparison');
    this.CMGOPComparison = filteredTemplate.filter((x: any) => x.Group == 'CMGOPComparison');
    this.BlendedRateComparison = filteredTemplate.filter((x: any) => x.Group == 'Blended Rate Comparison');
    this.Financial = filteredTemplate.filter((x: any) => x.Group == 'Financial');
    console.log(this.ReportFormData);
    let odata = {

      Currency: this.ReportFormData.Currency,
      DealYearFrom: this.ReportFormData.DealYearFrom,
      DealYearTo: this.ReportFormData.DealYearTo,
      DealTermMonthsFrom: this.ReportFormData.DealTermMonthsFrom,
      DealTermMonthsTo: this.ReportFormData.DealTermMonthsTo,
      TCVRangeUSDFrom: this.ReportFormData.TCVRangeFrom,
      TCVRangeUSDTo: this.ReportFormData.TCVRangeTo,
      DealOutcome: isNullOrUndefined(this.ReportFormData.DealOutcome) ? '' : this.ReportFormData.DealOutcome,
      PRIMARYSBU: isNullOrUndefined(this.ReportFormData.PrimarySBU) ? '' : this.ReportFormData.PrimarySBU,
      ContractType: isNullOrUndefined(this.ReportFormData.ContractType) ? '' : this.ReportFormData.ContractType,

    }
    if (!isNullOrUndefined(this.ReportFormData.Currency) &&
      !isNullOrUndefined(this.ReportFormData.DealTermMonthsFrom) &&
      !isNullOrUndefined(this.ReportFormData.DealTermMonthsTo) &&
      !isNullOrUndefined(this.ReportFormData.TCVRangeFrom) &&
      !isNullOrUndefined(this.ReportFormData.TCVRangeTo)
      //!isNullOrUndefined(this.ReportFormData.PrimarySBU)
    ) {
      let subscribe$: Subscription = this.AMReportsService.GetDisplayDealsInPastDealComparison(odata).subscribe(result => {
        if (result !== undefined && result !== null) {

          this.ReportGridData = result.Data;
          this.DataTotalCount = this.ReportGridData.length;
          this.showGrid_PastDealComparison = true;
          console.log(result);
        }
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting GetDisplayDealsInPastDealComparison.');
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
    }
    else {
      this.toastr.warning("Please fill all required fields");
    }

  }

  GetPastDealComparisonSummary(odata: any) {
    //let filteredTemplate = JSON.parse(JSON.stringify(this.ReportsTemplate));
    //   this.ReportsGridTemplate_PastDealComparison = filteredTemplate.filter((x: any) => x.Group == 'AMPastDealComparisonUploadGrid');

    console.log(this.ReportFormData);

    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    this.chartsInputThird = new ChartDetails();
    let subscribe$: Subscription = this.AMReportsService.GetPastDealComparisonSummary(odata).subscribe(result => {
      if (result !== undefined && result !== null) {

        this.chartsInput = result.Data[0];
        this.chartsInputSecond = result.Data[1];
        this.chartsInputThird = result.Data[2];
        this.gridOutputPD_1 = JSON.parse(result.Data[0].GridOutput);
        this.gridOutputPD_2 = JSON.parse(result.Data[1].GridOutput);
        this.gridOutputPD_3 = JSON.parse(result.Data[2].GridOutput);
        this.gridOutputPD_4 = JSON.parse(result.Data[3].GridOutput);
        this.ReportTYPE = 'PastDealComparison';
        //    this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');

        this.chartPlotName_pastD1 = result.Data[0].GraphHeading;
        this.chartPlotName_pastD2 = result.Data[1].GraphHeading;
        this.chartPlotName_pastD3 = result.Data[2].GraphHeading;
        // this.ReportGridData = result.Data;
        // this.DataTotalCount = this.ReportGridData.length;
        // this.showGrid_PastDealComparison = true;
        console.log(result);
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetPastDealComparisonSummary.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }




  GetWhatifswithModelExcel() {
    var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');
    let odata: any
    odata = {

      Currency: this.ReportFormData.Currency,
      ModelIdSelectedList: CSVOf_arr

    }
    let subscribe$: Subscription = this.AMReportsService.GetWhatifswithModelExcel(odata).subscribe(result => {
      //  if (result !== undefined && result !== null) {
      console.log(result);

      this.downloadFile(result, 'whatifswithModel');
      //  }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetWhatifswithModelExcel.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }








  getHeaders_pd() {
    let headers: string[] = [];
    if (this.gridOutputPD_1) {
      this.gridOutputPD_1.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }


    return headers;
  }
  getHeaders1_pd() {
    let headers: string[] = [];

    if (this.gridOutputPD_2) {
      this.gridOutputPD_2.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }

    return headers;
  }
  getHeaders2_pd() {
    let headers: string[] = [];

    if (this.gridOutputPD_3) {
      this.gridOutputPD_3.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }

    return headers;
  }
  getHeaders3_pd() {
    let headers: string[] = [];

    if (this.gridOutputPD_4) {
      this.gridOutputPD_4.forEach((value: any) => {
        Object.keys(value).forEach((key) => {
          if (!headers.find((header) => header == key)) {
            headers.push(key)
          }
        })
      })
    }
    return headers;
  }


  public downloadFile(response: any, fileName: string) {
    fileName = fileName;
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');

  }
}
