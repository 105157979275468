import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from '../shared/_services/utility.service';
import { TabItem } from './tabset.interface';

@Component({
    selector: 'tabset',
    templateUrl: './tabset.component.html'
})
export class TabSetComponent implements OnInit {
    @Input() tabType?: any = 'plaintype';
    @Input() tabList: any[] = [];

    // @Input() selectedTab?: string = '';

    @Output() public butClick = new EventEmitter<any>();
    @Output() selected = new EventEmitter();

    public fieldRandomId: string = '';
    public selectedTab?: string = '';

    constructor(private eleRef: ElementRef) {
        console.log(this.tabType);
        this.fieldRandomId = Math.random().toString(36).substr(2, 5);
    }

    ngOnInit() {

        console.log('selectedTab >>' + this.selectedTab);
        console.log(this.tabType);
        if (!isNullOrUndefined(this.tabList) && this.tabList.length > 0) {
            let selTab = this.tabList[0];

            let temp = this.tabList.filter((x: any) => x.IsDefault === true);

            if (temp.length > 0) { selTab = temp[0]; }

            // this.selectedTab = selTab.Name

            setTimeout(() => { this.onChange(selTab); }); // , 100);

        }

    }

    onChange(selTab: TabItem) {
        console.log(selTab);
        this.selectedTab = selTab.Name;
        this.selected.emit({ selectedTab: selTab.Name });
    }

    // disableTab(tab: TabItem): boolean {
    //     let retflg = false;

    //     // any other logic here        
    //     return retflg;
    // }

}
