import { Component,Renderer2, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { MenuItem } from './menubar.interface';
import { AppSharedService } from '../../app/shared/app.sharedservice';
import { NotificationData } from 'src/modules/notification/notification.interface';


@Component({
  selector: 'menubar',
  templateUrl: './menubar.component.html'
})

export class MenuBarComponent implements OnInit {
  @Input() menuList!: MenuItem[];
  @Input() menuType?: string = 'mainbar'; //'sidebar';
  @Input() defualtTab?: string = ''; //'sidebar';

  @Output() public menuClick = new EventEmitter<any>();

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('myOffCanvas') myOffCanvas!: ElementRef;

  public selectedTab: any; // = {tab: {}, parenttab: {}};
  public selTab: any;
  public currentComponentRef: any;
  public subscriptionList$: Subscription[] = [];
  public notify: NotificationData = new NotificationData();

  public isMenuSmall: boolean = true;
  public sideBarOpen: boolean = false;

  public isMenuBarVisible : boolean = true;
  public displayNotifybox: boolean = false;
  public routelink: any;
  public fieldRandomId: string;
  //AppSharedService: any;
  constructor(private renderer: Renderer2,private _route: ActivatedRoute, private eleRef: ElementRef, private _router: Router, private AppService: AppSharedService) {
    let subscribe$: Subscription = this._router.events.pipe(
      // filter(event => event instanceof NavigationStart)
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // change in routing
      console.log('change in routing >> ');
      console.log(event.url);
      this.setSelectedTab();
     
    });

    this.subscriptionList$.push(subscribe$);
    this.fieldRandomId = Math.random().toString(36).substr(2, 5);
  }

  // Click listener on the window object to handle clicks anywhere on 
  // the screen.
  @HostListener('window:click', ['$event']) onOutsideClick(event: any) {
    if (this.sideBarOpen && !this.eleRef.nativeElement.contains(event.target)) {
      this.sideBarOpen = false;
      document.getElementsByClassName('sidebar')[0].classList.remove('active');
      document.body.classList.remove('push');
    }
  }


  ngOnInit() {
    //this.sideBarClosed = false;
    if (!isNullOrUndefined(this.menuList) && this.menuList.length > 0) {
      // this.menuList = this.menuList.filter((x:MenuItem)=> x.HasPermission == true );
      let temp = this.menuList.filter((x: MenuItem) => x.IsDefault == true);

      if (temp.length > 0) {
        temp[0].Active = true;
        this.selTab = temp[0].Name;
      }

    }
  }

  ngOnChanges() {

    let execptionFlag: boolean = false;
    if (!isNullOrUndefined(this.AppService.currentDealData) && this.AppService.currentDealData.openFromHome === true) {

      let menuList = this.menuList.filter(x => x.Name === 'Process' && x.HasPermission === true);

      if (!isNullOrUndefined(menuList) && menuList.length > 0) {
        let dealConfig = menuList[0].SubMenu.filter(x => x.Name === 'DealConfiguration' && x.HasPermission === true);

        if (!isNullOrUndefined(dealConfig) && dealConfig.length > 0) {
          let temp = dealConfig[0].SubMenu.filter((x: MenuItem) => x.Name === 'ClientandDealContext');
          if (temp.length > 0) {
            temp[0].Active = true;
            this.selTab = temp[0].Name;
            execptionFlag = true;
          
          }
        }
      }




    }

    if (!execptionFlag) {
      this.selTab = this.defualtTab;
    }



  }

  // // ngOnChanges() {
  // ngOnChanges(changes: SimpleChanges) {
  //     // console.log('changes >> ');
  //     // console.log(changes);

  //     // Change in template
  //     if (!isNullOrUndefined(changes.menuList) && changes.menuList.previousValue !== changes.menuList.currentValue) {
  //         if (!isNullOrUndefined(this.menuList) && this.menuList.length > 0){
  //             let temp = this.menuList.filter((x:MenuItem)=> x.IsDefault == true );

  //             if (temp.length > 0) {
  //                 temp[0].Active = true;
  //             }
  //         }

  //     }       

  // }    

  toggleSideBar() {
  }


  hamburger_cross() {
    // let trigger: HTMLButtonElement = document.getElementsByClassName('hamburger')[0];
    // let overlay: HTMLDivElement = document.getElementsByClassName('overlay')[0];
    let trigger: HTMLButtonElement = this.eleRef.nativeElement.querySelector('.hamburger');
    let overlay: HTMLDivElement = this.eleRef.nativeElement.querySelector('.overlay');

    if (this.sideBarOpen == true) {
      overlay.hidden = true;
      trigger.classList.remove('is-open');
      trigger.classList.add('is-closed');
      this.sideBarOpen = false;
    } else {
      overlay.hidden = false;
      trigger.classList.remove('is-closed');
      trigger.classList.add('is-open');
      this.sideBarOpen = true;
    }
  }


  buttonClick(menu: any): void {
    /// Anomonous data for Click emit event -- Start
    let eventdata = { menu: menu };
    this.menuClick.emit(eventdata);
    /// Anomonous data for Click emit event -- End
  }

  setSelectedTab() {

    // Reset previously selected
    if (!isNullOrUndefined(this.selectedTab)) {
      this.selectedTab.Active = false;
    }
    // reset parent tabs
    let partemp = this.menuList.filter(x => x.Active === true);
    if (partemp.length > 0) {
      partemp[0].Active = false;
    }

    // Set currently selected
    // set selected tab from route url
    // let route = this._route.snapshot.root.url;
    let routepath = this._route.snapshot?.firstChild?.routeConfig?.path || '';

    let routearray = routepath.split('/');
    let temp = this.menuList;

    if (routearray.length > 0 && this.menuList.length > 0) {
      let i = 0;
      let x = './';
      while (temp.length > 0) {
        let curtab = temp.filter(p => p.RouteLink === x + routearray[i]);
        if (curtab.length > 0) {
          curtab[0]['Active'] = true;
          temp = curtab[0]['SubMenu'];
          x = x + routearray[i] + '/';

          this.selectedTab = curtab[0];

          i++;
        }
        else {
          temp = [];
        }
      }
    }

  }

  getSelectedSubTabName(tab: any): string {
    let str = '';
    let subtab = tab.SubMenu.filter((x: any) => x.Active === true);

    if (tab.Active === true && subtab.length > 0) {
      str = subtab[0].DisplayName;
    }

    return str;
  }



  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;

  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'MENU-CLICK':
        if (event.result.toUpperCase() === 'OK') {
          this.defualtTab = event.item.Name
          this.AppService.IsRowModified = false;
          this.AppService.IsRowModified$.next(this.AppService.IsRowModified);

          this.routelink = event.item.RouteLink;
          this._router.navigate([`app${this.routelink}`]);
        }
        break;

    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  closeModal(id: string) {
    this.closebutton.close(id);
  }

  onRouteClick(tab: any, parenttab?: any) {

    //this.defualtTab = tab.Name; 

    if (this.AppService.IsRowModified == true) {
      console.log("row modified")
      this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'MENU-CLICK', tab, 'Cancel', 'OK', 10000);
    }
    else {
      this.defualtTab = tab.Name;

      if (!isNullOrUndefined(tab.selectedLotData) && tab.selectedLotData !== '') {
        this.routelink = tab.RouteLink;
        this._router.navigate([`app${this.routelink}`], { state: { selectedData: tab.selectedLotData } });
        this.closeOffCanvas();
        // let trigger: HTMLButtonElement = this.eleRef.nativeElement.querySelector('.hamburger');
        // trigger.classList.remove('is-open');
        // trigger.classList.add('is-closed');
        //  document.getElementsByClassName('sidebar')[0].classList.remove('active');
        // document.body.classList.remove('push');
        //this.isMenuBarVisible = false;
      } else {

        this.routelink = tab.RouteLink;
        this._router.navigate([`app${this.routelink}`]);
        this.closeOffCanvas();
        // let trigger: HTMLButtonElement = this.eleRef.nativeElement.querySelector('.hamburger');
        // trigger.classList.remove('is-open');
        // trigger.classList.add('is-closed');
        //  document.getElementsByClassName('sidebar')[0].classList.remove('active');
        //  document.body.classList.remove('push');
       // this.isMenuBarVisible = false;
      }
    }
    //this.selTab = tab.Name;


    // console.log('on route click');
    // console.log('tab===>', JSON.stringify(tab));

    // // // Reset previously selected
    // // this.selectedTab.Active = false;

    // // // reset parent tabs
    // // let temp = this.menuList.filter(x => x.Active === true);
    // // if (temp.length > 0) {
    // //   temp[0].Active = false;
    // // }

    // // // Set currently selected
    // // this.selectedTab = tab;
    // // tab.Active = true;
    // // if (!isNullOrUndefined(parenttab)) {
    // //   parenttab.Active = true;
    // // }


    // // // // auto save changes if any
    // // // if (!isNullOrUndefined(this.currentComponentRef) && !isNullOrUndefined(this.currentComponentRef.autoSave)
    // // //     && typeof this.currentComponentRef.autoSave === 'function') {
    // // //   this.currentComponentRef.autoSave();
    // // // }

  }

  // onActivate(componentRef: any) {
  //     // if (componentRef instanceof SummaryComponent) {

  //     // }

  //     this.currentComponentRef = componentRef;

  //     return;
  // }

  closeOffCanvas() {
    if (this.myOffCanvas) {
      const offCanvasElement = this.myOffCanvas.nativeElement;

      // Check if the off-canvas menu is currently open
      if (offCanvasElement.classList.contains('show')) {
        // Hide the off-canvas menu by removing the 'show' class
        offCanvasElement.classList.remove('show');

        // Optionally, you can add more logic here
      }
    }
  }



}
