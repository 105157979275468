import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from '../shared/_services/utility.service';
import { AttachmentInfo, SectionInfo, UploadAttachInfo } from './attachment.interface';
import { AttachmentService } from './attachment.service';
import { Subscription } from 'rxjs';
import { NotificationData } from '../notification/notification.interface';
import { AppSharedService, AutoUnsubscribe } from 'src/app/shared/app.sharedservice';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'attachment',
    templateUrl: './attachment.html'
})
@AutoUnsubscribe
export class AttachmentComponent implements OnInit {

    @Input() DisplayMode?: string = 'LIST';    // LIST, PREVIEW
    @Input() SectionList?: SectionInfo[] = [];

    @Input() Module: string = '';
    @Input() RefId1: any = 0;
    @Input() RefId2?: any = 0;
    @Input() RefId3?: any = 0;
    @Input() RefId4?: any = 0;
    @Input() RefId5?: any = 0;
    @Input() RefId6?: any = 0;

    @Input() Permissions: any;
    @Input() UploadType?: string = '';
    @Input() IsMultiple?: boolean = true;

    @Output() close = new EventEmitter<any>();
    @Output() downloadALL = new EventEmitter<any>();
    // @Output() reloadAttach = new EventEmitter<any>();


    public GlobalConfig: any = {};

    public AttachmentData!: AttachmentInfo[];
    public AttachedFiles: any = null;
    public selFileNames: string = '';
    public displayNotifybox: boolean = false;
    public IsLoading: boolean = false;
    public notify: NotificationData = {};
    private CompTitle: string = 'Attachments';

    public subscriptionList$: Subscription[] = [];

    ////////////////////////////////////////////////////

    public previewFilePath: string = '';
    public previewFileName: string = '';
    public previewFileType: string = '';
    public previewFileData: any;

    public selectAll: any;


    constructor(private toastr: ToastrService, private _attachmentService: AttachmentService,
        private _appSharedService: AppSharedService, public sanitizer: DomSanitizer) { }


    ngOnInit() {
        if (isNullOrUndefined(this.Permissions)) {
            this.Permissions = { Upload: true, Download: true };
        }

        //this.getAttachments();

        if (this.DisplayMode == 'PREVIEW' &&
            !isNullOrUndefined(this.SectionList)) {
            this.showPreview(this.SectionList);
        }

        // if (this.DisplayMode == 'PREVIEW' && 
        //         !isNullOrUndefined(this.AttachmentData) && this.AttachmentData!.length > 0) {
        //     this.setPreviewFile(this.AttachmentData[0]);
        // }

        //&& this.SectionList.length > 0
    }


    closeAttachment(): void {
        // this.close.emit();
        this.close.emit({ 'Reload': false });

    }


    getAttachments(): void {
        let odata: any = {
            'Module': this.Module, 'Reference1': this.RefId1,
            'Reference2': this.RefId2, 'Reference3': this.RefId3,
            'Reference4': this.RefId4, 'Reference5': this.RefId5, 'Reference6': this.RefId6
        };

        this._attachmentService.getAttachments(odata).subscribe(res => {
            if (res !== null && res.Status === 200) {
                this.AttachmentData = res.Data;
            }
            else {
                this.AttachmentData = [];
            }

        },
            err => {
                this.AttachmentData = [];
                // this.toastr.error('Error on getting Attachment data.', this.CompTitle);
            });
    }


    uploadAttachments(): void {
        if (!isNullOrUndefined(this.AttachedFiles)) {

            // let odata : UploadAttachInfo = {
            //     TransactionId: 0,
            //     Module:this.Module,
            //     Reference1: this.RefId1,
            //     Reference2: this.RefId2,
            //     Reference3: this.RefId3,
            //     Reference4: this.RefId4,
            //     Reference5: this.RefId5,
            //     Reference6: this.RefId6,
            //     FilePath: this.AttachedFiles.FilePath,
            //     FileName: this.AttachedFiles.FileName,   
            //     UserId: this.AttachedFiles.FileName,    
            // };

            this.AttachedFiles.TransactionId = 0;

            this.AttachedFiles.Module = this.Module;

            this.AttachedFiles.Reference1 = this.RefId1;
            this.AttachedFiles.Reference2 = this.RefId2;
            this.AttachedFiles.Reference3 = this.RefId3;
            this.AttachedFiles.Reference4 = this.RefId4;
            this.AttachedFiles.Reference5 = this.RefId5;
            this.AttachedFiles.Reference6 = this.RefId6;


            this.IsLoading = true;
            this._attachmentService.uploadAttachments(this.AttachedFiles).subscribe(res => {
                if (res !== null && res.Status === 200) {
                    // alert('file Upload success');
                    this.notify = {
                        info: {
                            header: 'Confirmation Alert',
                            message: 'File uploaded successfully.',
                            yes: '',
                            no: '',

                            IsHeaderHidden: true,
                            HeaderIcon: 'fa fa-check-circle text-success',
                            IsCrossEnable: true,
                            SizeClass: 'modal-sm'
                        },

                        action: '',
                        item: null
                    };


                    this.displayNotifybox = true;
                    this.selFileNames = '';
                    if (!isNullOrUndefined(this.Module) && this.Module !== '') {
                        // if (this.Module === 'interm') {
                        //     this.reloadAttach.emit({ 'Reload': true, 'data': res.Data });
                        // } else if (this.Module === 'Document') {
                        // this.reloadAttach.emit({ 'Reload': true, 'data': res.Data });
                        this.close.emit({ 'Reload': true, 'data': res.Data });
                        // }

                    }
                    // this.getAttachments();
                }
                else {
                    this.notify = {
                        info: {
                            header: 'Confirmation Alert',
                            message: res.Message,
                            yes: '',
                            no: '',

                            IsHeaderHidden: true,
                            HeaderIcon: 'fa fa-exclamation-circle text-warning',
                            IsCrossEnable: true,
                            SizeClass: 'modal-sm'
                        },

                        action: '',
                        item: null
                    };


                    this.displayNotifybox = true;

                }
                this.IsLoading = false;
            },
                err => {
                    // this.toastr.error('Error on file upload.', this.CompTitle);
                    this.IsLoading = false;
                });
        }
        else {
            // this.toastr.warning('Kindly select a file for upload.', this.CompTitle);
            this.notify = {
                info: {
                    header: 'Confirmation Alert',
                    message: 'Kindly select a file for upload.',
                    yes: '',
                    no: '',

                    IsHeaderHidden: true,
                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                    IsCrossEnable: true,
                    SizeClass: 'modal-sm'
                },

                action: '',
                item: null
            };


            this.displayNotifybox = true;
        }
    }

    handleUpload(e: any): void {
        if (e.target.files && e.target.files[0]) {
            let formData: FormData = new FormData();
            let filesToUpload = <Array<File>>e.target.files;
            let filenames: string = '';
            for (let i = 0; i < filesToUpload.length; i++) {
                formData.append('uploadedFiles', filesToUpload[i], filesToUpload[i].name);
                filenames = filenames + '; ' + filesToUpload[i].name;
                this.selFileNames = filenames.substr(2, filenames.length);
                this.AttachedFiles = formData;
            }

            // this.selFileNames = filesToUpload[0].name;



            // let reader = new FileReader();
            // reader.onload = (event: any) => {
            //     this.AttachedFiles = event.target.result;
            // }
            // reader.readAsDataURL(e.target.files[0]);
        }
        else {
            this.selFileNames = '';
            this.AttachedFiles = null;
        }

        // e.target.value = '';
    }

    downloadFile(item: AttachmentInfo) {

        if (this.Permissions.Download) {
            this._attachmentService.downloadFile(item).subscribe(res => {
                console.log('start download:', res);

                // if (typeof window.navigator.msSaveOrOpenBlob === 'function') { // IE & Edge
                //     // msSaveBlob only available for IE & Edge
                //     window.navigator.msSaveBlob(res.data, item.FileName);
                // }
                // else { // Chrome & FF
                let url = window.URL.createObjectURL(res.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = item.FileName!;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // }

            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            });

        }
        else {
            // this.toastr.info('You do not have rights to view this document!', this.CompTitle);
            console.log('You do not have rights to view this document!');
        }

    }

    showDeleteConfirmation(curitem: AttachmentInfo): void {

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: 'Are you sure you want to delete ?',
                yes: 'Yes',
                no: 'No',

                IsHeaderHidden: true,
                HeaderIcon: 'fa fa-check-circle text-success',
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: 'DELETE',
            item: curitem
        };


        this.displayNotifybox = true;
    }

    deleteAttachment(Attachment: AttachmentInfo): void {
        if (!isNullOrUndefined(Attachment)) {
            this._attachmentService.deleteAttachment(Attachment).subscribe(res => {
                if (res !== null && res.Status === 200) {
                    // alert('file Delete success');
                    this.getAttachments();

                    // this.toastr.info('File deleted successfully.', this.CompTitle);
                    this.notify = {
                        info: {
                            header: 'Confirmation Alert',
                            message: 'File deleted successfully.',
                            yes: '',
                            no: '',

                            IsHeaderHidden: true,
                            HeaderIcon: 'fa fa-check-circle text-success',
                            IsCrossEnable: true,
                            SizeClass: 'modal-sm'
                        },

                        action: '',
                        item: null
                    };


                    this.displayNotifybox = true;

                }
            },
                err => {
                    // this.toastr.error('Error on file deletion.', this.CompTitle);
                    console.log('Error on file deletion.');
                });
        }
    }


    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        let canClose: boolean = true;
        switch (event.action.toUpperCase()) {
            case 'DELETE':
                if (event.result.toUpperCase() === 'YES') {
                    this.deleteAttachment(event.item);
                }
                break;
        }
        if (canClose) {
            this.closeNotifybox();
        }
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;
    }

    onClick(event: any): void {
        let selitem: any = event.item;

        console.log(selitem);
    }

    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;

        switch (event.fieldname.toUpperCase()) {
            case '':
                break;
        }
    }


    ////////////////////////////////////////////////

    downloadAttachment(data: any) {
        this.IsLoading = true;

        let odata: object = {
            'DisplayMode': data?.DisplayName,
            'Module': data.Module,
            'Reference1': data.RequestId,
            'Reference2': data.CheckId,
            'Reference3': data.DocumentTypeId,
            'Reference4': data.SubDocumentTypeId,
            'TransactionId': data.TransactionId,
            // 'FilePath': data.FilePath,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._attachmentService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = data.FileName;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // this._toastr.success('Resume downloaded successfully', this.CompTitle);

                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Document downloaded successfully',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-check-circle text-success',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    // downloadMandatoryAttachment(data: any) {
    //     this.IsLoading = true;

    //     let odata: object = {
    //         'Module': data.Module,
    //         'Reference1': data.Reference1,
    //         'Reference2': data.Reference2,
    //         'Reference3': null,
    //         'Reference4': null,
    //         'TransactionId': data.TransactionId,
    //         // 'FilePath': data.FilePath,
    //         'FilePath': '',
    //         'FileName': data.FileName
    //     };

    //     let subscribe$: Subscription = this._requestService.downloadAttachment(odata).subscribe(result => {
    //         // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
    //         //   console.log(result);
    //         // }
    //         if (!isNullOrUndefined(result)) {
    //             let url = window.URL.createObjectURL(result.data);
    //             let a = document.createElement('a');
    //             document.body.appendChild(a);
    //             a.setAttribute('style', 'display: none');
    //             a.href = url;
    //             // a.download = res.filename;
    //             a.download = data.FileName;
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             a.remove(); // remove the element
    //             // this._toastr.success('Resume downloaded successfully', this.CompTitle);

    //             this.notify = {
    //                 info: {
    //                     header: 'Confirmation Alert',
    //                     message: 'Document downloaded successfully',
    //                     yes: '',
    //                     no: '',

    //                     IsHeaderHidden: true,
    //                     HeaderIcon: 'fa fa-check-circle text-success',
    //                     IsCrossEnable: true,
    //                     SizeClass: 'modal-sm'
    //                 },

    //                 action: '',
    //                 item: null
    //             };

    //             this.displayNotifybox = true;
    //         }
    //         this.IsLoading = false;

    //     },
    //         err => {
    //             this.IsLoading = false;
    //         });
    //     this.subscriptionList$.push(subscribe$);
    // }

    showPreview(event: any): void {
        this.setPreviewFile(event);
    }

    setPreviewFile(data: any) {
        this.previewFilePath = '';
        this.previewFileName = '';
        this.previewFileType = '';
        this.previewFileData = null;

        this.IsLoading = true;

        let odata: any = {
            'Module': this.Module,
            'Reference1': data.RequestId,
            'Reference2': data.CheckId,
            'Reference3': data.DocumentTypeId,
            'Reference4': data.SubDocumentTypeId,
            'TransactionId': data.TransactionId,
            'FilePath': '',
            'FileName': data.FileName
        };

        let subscribe$: Subscription = this._attachmentService.downloadAttachment(odata).subscribe(result => {
            // if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
            //   console.log(result);
            // }
            if (!isNullOrUndefined(result)) {
                let url = window.URL.createObjectURL(result.data);

                this.previewFileData = result.data;
                this.previewFilePath = url;
                this.previewFileName = data.FileName;
                // this.previewFileType = (result.data.type.indexOf('image/') >= 0 ? 'image' : (result.data.type.indexOf('application/pdf') >= 0 ? 'pdf' : 'others'));

                // {".txt", "text/plain"},
                // {".pdf", "application/pdf"},
                // {".doc", "application/vnd.ms-word"},
                // {".docx", "application/vnd.ms-word"},
                // {".xls", "application/vnd.ms-excel"},
                // {".xlsx", "application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet"},
                // {".png", "image/png"},
                // {".jpg", "image/jpeg"},
                // {".jpeg", "image/jpeg"},
                // {".gif", "image/gif"},
                // {".csv", "text/csv"}

                switch (result.data.type.trim().toLowerCase()) {
                    case 'image/png':
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/gif':
                        this.previewFileType = 'image';
                        break;

                    case 'application/pdf':
                        this.previewFileType = 'pdf';
                        break;

                    case 'text/csv':
                    case 'application/vnd.ms-excel':
                    case 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet':
                        this.previewFileType = 'excel';
                        break;

                    default:
                        this.previewFileType = 'others';
                        break;
                }

            }
            this.IsLoading = false;

        },
            err => {
                this.IsLoading = false;
            });
        this.subscriptionList$.push(subscribe$);
    }

    toggleSelectAll() {
        this.selectAll = !this.selectAll;

        this.SectionList?.forEach((section: SectionInfo) => {
            section.AttachmentList.forEach((attachData: AttachmentInfo) => {
                attachData.IsSelected = this.selectAll;
            });
        });

    }

    // checkIfAllAttachmentSelected() {
    //     var totalSelected = 0;
    //     for (var i = 0; i < this.allAttachmentList.length; i++) {
    //         if (this.allAttachmentList[i].IsSelected) totalSelected++;
    //     }

    //     this.selectAll = totalSelected === this.allAttachmentList.length;

    //     return true;
    // }

    downloadALLAttachment() {
        this.downloadALL.emit();
    }


}
