import { Component, OnInit, Inject, NgZone, PLATFORM_ID, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {


  @Input() graphData: any = [];
  @Input() ChartType: any = [];
  public TotalTcvData: any;
  private root!: am5.Root;
  constructor(@Inject(PLATFORM_ID) private platformId: any, private zone: NgZone) {


  }


  ngOnChanges() {
    console.log("graphs*Data");
    console.log(this.graphData)
    this.TotalTcvData = this.graphData;
  }
  ngOnInit(): void {
    if (this.graphData != null || this.graphData != undefined || this.graphData != '') {
      this.InternalPercentileGraphData();
    }
  }





  InternalPercentileGraphData() {
    if (this.root) {
      this.root.dispose();
    }
    //   useEffect(() => {
    setTimeout(() => {
      let root: any = null;
      root = am5.Root.new("chartdiv");

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = null;
      chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.verticalLayout
        })
      );

      // Define data
      let data = null;
      data = this.TotalTcvData;
      //   let data = [
      //     {
      //        "PercentileType":"Internal25Per",
      //        "FinalRate":3.29
      //     },
      //     {
      //        "PercentileType":"Internal50Per",
      //        "FinalRate":3.4
      //     },
      //     {
      //        "PercentileType":"External25Per",
      //        "FinalRate":1.0
      //     },
      //     {
      //        "PercentileType":"External50Per",
      //        "FinalRate":1.3
      //     }
      //  ]
      console.log("DATA")
      console.log(data)


      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          "extraMin": 0.15,
          "extraMax": 1.0

        })
      );

      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: 'PercentileType'
        })
      );
      xAxis.data.setAll(data);

      // Create series
      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "PercentileType",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "FinalRate",
          categoryXField: "PercentileType"
        })
      );


      series1.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });


      series1.data.setAll(data);

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });



      // Add legend
      let legend = chart.children.push(am5.Legend.new(root, {}));
      legend.data.setAll(chart.series.values);

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      this.root = root;

    }, 1000);

  }

  // yearGraphData() {
  //   setTimeout(() => {
  //     let root = am5.Root.new("chartyearwise");

  //     root.setThemes([am5themes_Animated.new(root)]);

  //     let chart = root.container.children.push(
  //       am5xy.XYChart.new(root, {
  //         panY: false,
  //         layout: root.verticalLayout
  //       })
  //     );

  //     // Define data
  //   //  let data = this.graphData;
  // //   {
  // //     "ChartOutput": "[{\"Tower\":\"End User Services- Connected service\",\"TowerCount\":3.420000000000000e+002}]"
  // // }
  //       let data = [
  //         {
  //            "Tower":"End User Services- Connected service",
  //            "TowerCount":3.42
  //         },
  //         {
  //           "Tower":"End",
  //           "TowerCount":5
  //        }
  //      ]
  //     console.log("DATA")
  //     console.log(data)

  //     // Create Y-axis
  //     let yAxis = chart.yAxes.push(
  //       am5xy.ValueAxis.new(root, {
  //         renderer: am5xy.AxisRendererY.new(root, {})
  //       })
  //     );

  //     // Create X-Axis
  //     let xAxis = chart.xAxes.push(
  //       am5xy.CategoryAxis.new(root, {
  //         renderer: am5xy.AxisRendererX.new(root, {}),
  //         categoryField: 'Tower'
  //       })
  //     );
  //     xAxis.data.setAll(data);

  //     // Create series
  //     let series1 = chart.series.push(
  //       am5xy.ColumnSeries.new(root, {
  //         name: "Tower",
  //         xAxis: xAxis,
  //         yAxis: yAxis,
  //         valueYField: "TowerCount",
  //         categoryXField: "Tower"
  //       })
  //     );
  //     series1.data.setAll(data);

  //     // let series2 = chart.series.push(
  //     //   am5xy.ColumnSeries.new(root, {
  //     //     name: "Series",
  //     //     xAxis: xAxis,
  //     //     yAxis: yAxis,
  //     //     valueYField: "value2",
  //     //     categoryXField: "category"
  //     //   })
  //     // );
  //     // series2.data.setAll(data);

  //     // Add legend
  //     let legend = chart.children.push(am5.Legend.new(root, {}));
  //     legend.data.setAll(chart.series.values);

  //     // Add cursor
  //     chart.set("cursor", am5xy.XYCursor.new(root, {}));

  //     this.root = root;

  //   }, 1000);
  // }



  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }




  ngAfterViewInit() {

    console.log(this.graphData)

    this.TotalTcvData = this.graphData;

  }



  ngOnDestroy() {
    // Clean up chart when the component is removed
    // this.browserOnly(() => {
    if (this.root) {
      this.root.dispose();
    }
    //  });
  }

}
function useEffect(arg0: () => () => void) {
  throw new Error('Function not implemented.');
}

