import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse, ReportModel } from '../app.interface';
import { environment } from '../../environments/environment';
@Injectable()
export class AmDownloadsService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private ReportUrl: string;
  private serviceURL: string;
  httpClient: any;
  constructor(private _http: HttpClient) {
    this.ReportUrl = environment.apiURL.ReportURL;
    this.serviceURL = environment.apiURL.ServiceTypeURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }
  DownloadExcel(odata: any) {
    let Params = new HttpParams();

    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('ModelID', odata.ModelID);
    Params = Params.append('ReportType', odata.ReportType);
    Params = Params.append('Currency', odata.Currency);
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetConsolidatedReport',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  GetDealAnalysisReport(odata: any) {
    let Params = new HttpParams();
    Params = Params.append('SBU', odata.SBU);
    Params = Params.append('DealCreatedDateFrom', odata.DealCreatedDateFrom);
    Params = Params.append('DealCreatedDateTo', odata.DealCreatedDateTo);
    Params = Params.append('DealStatus', odata.DealStatus);
    Params = Params.append('Sector', odata.Sector);
    Params = Params.append('Currency', odata.Currency);
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetDealAnalysisReport',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  GetPegaDealAnalysisReport(odata: any) {
    let Params = new HttpParams();
    Params = Params.append('SBU', odata.SBU);
    Params = Params.append('DealCreatedDateFrom', odata.DealCreatedDateFrom);
    Params = Params.append('DealCreatedDateTo', odata.DealCreatedDateTo);
    Params = Params.append('DealStatus', odata.DealStatus);
    Params = Params.append('Sector', odata.Sector);
 
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetPegaDealAnalysisReport',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  DownloadExcel_MasterDataDownload(odata: any) {
    let Params = new HttpParams();

    Params = Params.append('MasterData', odata.MasterData);

    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetAmMasterDataDownloadReport',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }



  //   postFile(fileToUpload: File): Observable<boolean> {
  //     const endpoint = 'your-destination-url';
  //     const formData: FormData = new FormData();
  //     formData.append('fileKey', fileToUpload, fileToUpload.name);
  //     return this.httpClient
  //       .post(endpoint, formData, { headers: yourHeadersConfig })
  //       .map(() => { return true; })
  //       .catch((e) => this.handleError(e));
  // }

  // Returns an observable
  uploadIt(file: File, filename: any): Observable<any> {

    // Create form data
    const formData: any = new FormData();

    // Store form name as "file" with file data
    formData.append("file", file, filename);
    console.log(formData);


    // Make http post request over api
    // with formData as req
    //const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    return this._http.post(this.ReportUrl + 'InsertUploadADMTReportDetails',
      formData)

  }


  uploadItQuickDeal(file: File, filename: any): Observable<any> {
    // Create form data
    const formData: any = new FormData();

    // Store form name as "file" with file data
    formData.append("file", file, filename);
    console.log(formData);
    // Make http post request over api
    // with formData as req


    // const httpOptions = {
    //   headers: new HttpHeaders({
    //    "Content-Type": "multipart/form-data" // 👈
    //   })
    // };
    //  const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    return this._http.post(this.ReportUrl + 'QuickDealUpload',
      formData)
  }
  InsertAMDealComparisonUpload(file: File, filename: any): Observable<any> {
    // Create form data
    const formData: any = new FormData();


    formData.append("file", file, filename);
    console.log(formData);

    return this._http.post(this.ReportUrl + 'InsertAMDealComparisonUpload',
      formData)
  }

  GetADMTReportFirstGridDetails(id: any): Observable<any> {
    console.log("****");
    id = null;
    let Params = new HttpParams();
    Params = Params.append('ADMTUploadId', id);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetADMTReportFirstGridDetails',
      { headers: this.headers, params: id })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetADMTReportSummary(data: any): Observable<any> {
    console.log("****");

    let Params = new HttpParams();
    // Params = Params.append('FileName', data.FileName);
    // Params = Params.append('RateCard', data.RateCard);
    // Params = Params.append('Country', data.Country);
    // Params = Params.append('LocationType', data.LocationType);
    // Params = Params.append('LocationCardName', data.LocationCardName);
    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'GetADMTReportSummary', data,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateWhatifswithADMTLocationCard(data: any): Observable<any> {
    let Params = new HttpParams();

    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'UpdateWhatifswithADMTLocationCard',data,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetWhatifswithADMTSummary(data: any): Observable<any> {
   
    let Params = new HttpParams();
    Params = Params.append('ADMTUploadId', data.ADMTUploadId);
    Params = Params.append('NDataCount', data.NDataCount);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetWhatifswithADMTSummary',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }




  
  GetADMTReportSummaryPart2(id: any): Observable<any> {
    console.log("****");
    id = null;
    let Params = new HttpParams();
    Params = Params.append('FileName', id);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetADMTReportSummaryPart2',
      { headers: this.headers, params: id })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetADMTReportSummaryForGraph(id: any) {
    console.log("****");
    id = null;
    let Params = new HttpParams();
    Params = Params.append('FileName', id);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetADMTReportSummaryForGraph',
      { headers: this.headers, params: id })
      .pipe(
        catchError(this.handleError)
      );
  }




  GetADMTReportOnChangeValue(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'GetADMTReportOnChangeValue', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetADMTReportOnSliderChangeValue(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('NProductivity', odata.NProductivity);
    Params = Params.append('NExpenses', odata.NExpenses);
    Params = Params.append('NDealDiscount', odata.NDealDiscount);
    Params = Params.append('NOnshoreBlendedRate', odata.NOnshoreBlendedRate);
    Params = Params.append('NOffshoreBlendedRate', odata.NOffshoreBlendedRate);
    Params = Params.append('NNearshoreBlendedRate', odata.NNearshoreBlendedRate);
    Params = Params.append('NOnshoreContingency', odata.NOnshoreContingency);
    Params = Params.append('NNearshoreContingency', odata.NNearshoreContingency);
    Params = Params.append('NOffshoreContingency', odata.NOffshoreContingency);
    Params = Params.append('ChangeValue', odata.ChangeValue);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetADMTReportOnSliderChangeValue',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );


  }

  InsertWhatifswithADMTTransactionLog(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'InsertWhatifswithADMTTransactionLog', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }



  GetWhatifsADMTSummary(odata: any) {
    console.log("****");
    console.log(odata);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetWhatifsADMTSummary',
      { headers: this.headers, params: odata })
      .pipe(
        catchError(this.handleError)
      );

  }

}
