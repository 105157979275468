import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environments/environment';

@Injectable()

export class LotSolutionPricingModelService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private DealURL: string;
  private LotURL: string;


  constructor(private _http: HttpClient) {
    this.DealURL = environment.apiURL.DealURL;
    this.LotURL = environment.apiURL.LotURL;
  }


  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  GetAmPricingModelSecondGrid(dealHeaderId: number, dealLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetEffortModelling',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAmPricingModelDetails(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmPricingModelDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAmPricingModelPopUpDetails(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, dealLotSolutionStaffingId: any, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('DealLotSolutionStaffingId', dealLotSolutionStaffingId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmPricingModelPopUpDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetPricingModelRateGrid(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    //http://localhost:7000/LotSolution/GetPricingModelRateGrid?DealHeaderId=1296&DealSubLineItemId=304&LSDeliveryLocationModellingId=12&DealLineItemId=1
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetPricingModelRateGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetPricingModuleLastGrid(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    //http://localhost:7000/LotSolution/GetPricingModelLastGrid?DealHeaderId=1296&DealSubLineItemId=304&LSDeliveryLocationModellingId=12&DealLineItemId=1
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetPricingModuleLastGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetRateAutoPopulateExternal(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number, pricingModelDetailsId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    Params = Params.append('PricingModelDetailsId', pricingModelDetailsId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetRateAutoPopulateExternal',
      { headers: this.headers, params: Params });
    return response;
  }

  GetRateAutoPopulateCapgemini(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'RateAutoCalculateforCapgemini',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAmDealProgressBarPer(dealHeaderId: number, Approach: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('Module', 'AM');
    Params = Params.append('Approach', Approach);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmDealProgressBarPer',
      { headers: this.headers, params: Params });
    return response;
  }

  InsertandUpdateTransitionCost(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertandUpdateTransitionCost', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertUpdatePricingRate(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdatePricingRate', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertUpdatePricingMiscUplift(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdatePricingMiscUplift', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateRateAutoPopulateExternal(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'UpdateRateAutoPopulateExternal', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  CalculatePricing(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    //http://localhost:7000/LotSolution/GetPricingModelLastGrid?DealHeaderId=1296&DealSubLineItemId=304&LSDeliveryLocationModellingId=12&DealLineItemId=1
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'CalculatePricing',
      { headers: this.headers, params: Params });
    return response;
  }

  InsertUpdatePricingPopUp(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdatePricingPopUp', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getOtherCostDetails(dealHeaderId: number, dealLineItemId: number, dealSubLineItemId: number, tabName: string, lot_or_deal: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealSubLineItemId);
    Params = Params.append('TabName', tabName);
    Params = Params.append('Lot_or_Deal', lot_or_deal);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetOtherCost',
      { headers: this.headers, params: Params });
    return response;
  }

  getTotalCostDetails(dealHeaderId: number, dealLineItemId: number, dealSubLineItemId: number, tabName: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealSubLineItemId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetTotalOtherCost',
      { headers: this.headers, params: Params });
    return response;
  }

  getResourceCostDetails(dealHeaderId: number, dealLineItemId: number, dealSubLineItemId: number, tabName: string, otherCostId: number, location: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealSubLineItemId);
    Params = Params.append('TabName', tabName);
    Params = Params.append('OtherCostId', otherCostId);
    Params = Params.append('Location', location);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetResourceCost',
      { headers: this.headers, params: Params });
    return response;
  }

  insertUpdateOtherCost(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertOrUpdateOtherCost', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  insertOrUpdateResourceCost(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertOrUpdateResourceCost', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getClientCostDetails(dealHeaderId: number, dealLineItemId: number, dealSubLineItemId: number, tabName: string, subtabName: string, deal: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealSubLineItemId);
    Params = Params.append('TabName', tabName);
    Params = Params.append('SubTabName', subtabName);
    Params = Params.append('Deal', deal);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetClientCosts',
      { headers: this.headers, params: Params });
    return response;
  }

  getTotalClientCostDetails(dealHeaderId: number, dealLineItemId: number, dealSubLineItemId: number, tabName: string): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealSubLineItemId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetTotalClientCosts',
      { headers: this.headers, params: Params });
    return response;
  }

  insertUpdateClientCost(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertOrUpdateClientCosts', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  changeDealDropDown(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'chnageDealDropDown', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  changeClientDealDropDown(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'changeClientDealDropDown', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getBaselinedDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetBaselinedDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  submitDeal(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'submitDeal?DealHeaderId=' + dealHeaderId,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetStaffingModellingThirdGridGrade_E_Details(dealHeaderId: number,  dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
   
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetStaffingModellingThirdGridGrade_E_Details',
      { headers: this.headers, params: Params });
    return response;
  }


  GetStaffingModellingThirdGrid(dealHeaderId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetStaffingModellingThirdGrid',
      { headers: this.headers, params: Params });
    return response;
  }


}
