import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation,
  OnChanges, SimpleChanges, SimpleChange, AfterViewInit, OnDestroy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { isNullOrUndefined, isNull } from 'util';
import { isNullOrUndefined } from '../shared/_services/utility.service';
import { RowLockStatus, SCREEN_SIZE } from '../../app/app.interface';
import {
  FieldTemplate, GridConfig, GridConst, GridProperty, NestedTemplate,
  Pagination, PaginationConfig, FieldEventData, ActionBarConfig
} from '../shared/_classes/utility.interface';
import { ElioScopeService } from '../shared/_services/ElioScope.service';
import { UtilityService } from '../shared/_services/utility.service';

import { FilterPipe } from 'ngx-filter-pipe';
import { OrderByPipe } from '../custompipe/custompipe.module';
import { delay } from 'rxjs/operators';
import { ResizeService } from 'src/app/core/screen-size-detector/resize.service';
import { NotificationData } from '../notification/notification.interface';
import { JSONParser } from '@amcharts/amcharts4/core';


@Component({
  selector: 'grid-dispctrl',
  templateUrl: './grid-dispctrl.html',
  encapsulation: ViewEncapsulation.None,
  providers: [OrderByPipe]
})

export class GridDisplayControlComponent implements OnInit, AfterViewInit, OnDestroy {
  // export class GridComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() GridId?: any;
  @Input() FieldTemplate: Array<FieldTemplate> = [];
  @Input() GridData: any;
  @Input() GridDataTotalCount: number = 0;
  @Input() GridProp: GridProperty = {};
  @Input() GridConfig: GridConfig = {};
  @Input() IsParallelEditEnabled: boolean = false;
  @Input() GlobalConfig: any = {};
  @Input() NestedTemplate!: NestedTemplate;
  @Input() cellLock!: Function;
  @Input() cellRequired!: Function;
  @Input() CustomCss!: Function;
  @Input() parent: any;
  @Input() IsPagination: boolean = true;

  @Input() HierarchyParentData?: any = null;

  @Output() FieldClick = new EventEmitter<any>();
  @Output() FieldChange = new EventEmitter<any>();
  @Output() FieldEditStart = new EventEmitter<any>();
  @Output() PageChange = new EventEmitter<any>();
  @Output() public ActionBarClick = new EventEmitter<any>();
  // Grid properties
  // Default values
  public TableCSS: string = '';
  public HeaderCSS: string = '';
  public AlternateRowColor: any = { odd: 'white', even: 'white' };
  public IsEditable: boolean = true;
  public DisplayHeader: boolean = true;
  public TableWidth: number = 100;
  public PaginationConfig: PaginationConfig = {
    Required: true,
    ItemsPerPage: GridConst.DEFAULTITEMSPERPAGE,
    PageList: false,
    ServerLoad: false
  };
  public IsSortable: boolean = true;
  public IsColResizable: boolean = true;

  public DisplayColumns: string = '';
  public GridTotalWidth: number = 0;
  public selectedCell: string = '';
  public selectedCellItemData: any;
  public prevSelectedCell: string = '';

  public IsNested: boolean = false;
  public ParentField: string = '';
  public FilterField: string = '';
  public selectedNest: any[] = [];
  public LeafCountField: string = '';
  public IsNestedDynamicData: boolean = false;
  // public NestedGridDataTotalCount: any[] = [];
  public _NewRowInd: number = -1;

  public DataFilter: any = {};

  public Pagination: Pagination = {
    ItemsPerPage: GridConst.DEFAULTITEMSPERPAGE,
    CurrentPage: 1,
    TotalItems: 0,
    TotalPages: 0
  };

  public dispFilt: boolean = false;

  public editMode: boolean = false;
  public currCell!: HTMLTableDataCellElement;

  public datacellindx = 0;
  public sortdirection: number = -1;
  public sortcolumn: string = '';
  public sortcolumntype: string = '';
  public isDesc: boolean = false;

  public currentDataList: any;

  public thElm: any;
  public startOffset: any;
  public curItemsPerPage: number = 10;
  public screenSize!: SCREEN_SIZE;
  public pagesPerchange?: any;
  public ActionBarConfig: ActionBarConfig = {
    ActionBarList: null,
    noActionClass: false
  };

  public gridRandomId: string = '';
  public DataTotalCount: number = 0;
  public UniqueKeyName: string = '';
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  private ObjectType: any;


  constructor(private _elioScopeService: ElioScopeService, private _elioUtilityService: UtilityService,
    private translate: TranslateService, private eleRef: ElementRef,
    private filterByPipe: FilterPipe, private orderByPipe: OrderByPipe,
    private resizeSvc: ResizeService) {

    this.gridRandomId = Math.random().toString(36).substr(2, 5);

    if (/^\d$/.test(this.gridRandomId.charAt(0))) {
      this.gridRandomId = "a" + this.gridRandomId;
    }

    // Subscription for Resize 
    this.resizeSvc.onResize$
      .pipe(delay(0))
      .subscribe(x => {
        this.screenSize = x;
      });

    // To initialise screenSize
    window.dispatchEvent(new Event('resize'));

  }

  ngOnInit() {
    console.log(this.DisplayHeader)
    if (this.GridProp !== undefined) {
      this.TableCSS = (this.GridProp.TableCSS === undefined ? this.TableCSS : this.GridProp.TableCSS);
      this.HeaderCSS = (this.GridProp.HeaderCSS === undefined ? this.HeaderCSS : this.GridProp.HeaderCSS);
      this.AlternateRowColor = (this.GridProp.AlternateRowColor === undefined ? this.AlternateRowColor : this.GridProp.AlternateRowColor);
      this.IsEditable = (!isNullOrUndefined(this.GridProp.Editable) ? this.IsEditable : this.GridProp.Editable!);
      this.DisplayHeader = (this.GridProp.DisplayHeader === undefined ? this.DisplayHeader : this.GridProp.DisplayHeader);
      this.TableWidth = (this.GridProp.TableWidth === undefined ? this.TableWidth : this.GridProp.TableWidth);
      this.IsSortable = (this.GridProp.IsSortable === undefined ? this.IsSortable : this.GridProp.IsSortable);
      this.IsColResizable = (this.GridProp.IsColResizable === undefined ? this.IsColResizable : this.GridProp.IsColResizable);

      if (!isNullOrUndefined(this.GridProp.PaginationConfig)) {
        this.PaginationConfig.Required = (isNullOrUndefined(this.GridProp.PaginationConfig?.Required) ?
          this.PaginationConfig.Required : this.GridProp.PaginationConfig?.Required);
        this.PaginationConfig.ItemsPerPage = (isNullOrUndefined(this.GridProp.PaginationConfig?.ItemsPerPage) ?
          this.PaginationConfig.ItemsPerPage : this.GridProp.PaginationConfig?.ItemsPerPage);
        this.PaginationConfig.PageList = (isNullOrUndefined(this.GridProp.PaginationConfig?.PageList) ?
          this.PaginationConfig.PageList : this.GridProp.PaginationConfig?.PageList);
        this.PaginationConfig.ServerLoad = (isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) ?
          this.PaginationConfig.PageList : this.GridProp.PaginationConfig?.ServerLoad);
      }

      this.Pagination.ItemsPerPage = (this.PaginationConfig.Required && this.PaginationConfig.ItemsPerPage) ? this.PaginationConfig.ItemsPerPage : GridConst.DEFAULTITEMSPERPAGE;
      this.Pagination.CurrentPage = 1;

      if (!isNullOrUndefined(this.GridProp.ActionBarConfig)) {
        this.ActionBarConfig.ActionBarList = (isNullOrUndefined(this.GridProp.ActionBarConfig?.ActionBarList) ?
          this.ActionBarConfig.ActionBarList : this.GridProp.ActionBarConfig?.ActionBarList);
        this.ActionBarConfig.noActionClass = (isNullOrUndefined(this.GridProp.ActionBarConfig?.noActionClass) ?
          this.ActionBarConfig.noActionClass : this.GridProp.ActionBarConfig?.noActionClass);

      }

      // if (this.GridProp.AddNewRow) {
      //   this.addDefaultRows();
      // }

    }

    if (!this.IsEditable) {
      this.setTemplateReadOnly();
    }

    this.DisplayColumns = this.setDispcols();
    this.GridTotalWidth = this.getGridWidth();

    if (this.GridConfig !== undefined && this.GridConfig.BaseUrl !== undefined && this.GridConfig.BaseUrl !== null) {
      this._elioScopeService.setBaseURL(this.GridConfig.BaseUrl);
    }

    if (this.GlobalConfig !== undefined) {
      let GlobalKeys: Array<string> = [];
      // Object.entries(this.GlobalConfig).forEach(
      //   ([key, value]) => {console.log(key, value); GlobalKeys.push(key); }
      // );
      for (let key in this.GlobalConfig) { GlobalKeys.push(key); }

      this._elioUtilityService.PreProcessTemplate(this.FieldTemplate, GlobalKeys, this.GlobalConfig);
    }

    if (!isNullOrUndefined(this.NestedTemplate)) {
      this.IsNested = true;
      this.ParentField = this.NestedTemplate.ParentFieldName;
      this.FilterField = this.NestedTemplate.FilterFieldName;

      if (isNullOrUndefined(this.HierarchyParentData)) {
        this.DataFilter[this.FilterField] = { '$or': [0, null, ''] };
      }
      else {
        this.DataFilter[this.FilterField] = { '$or': [this.HierarchyParentData] };
      }

      this.LeafCountField = isNullOrUndefined(this.NestedTemplate.LeafCountFieldName) ? 'LeafCount' : this.NestedTemplate.LeafCountFieldName!;
      this.IsNestedDynamicData = isNullOrUndefined(this.NestedTemplate.IsDynamicDataLoad) ? false : this.NestedTemplate.IsDynamicDataLoad!;

      //this.GridDataTotalCount = this.filterByPipe.transform(this.GridData, this.DataFilter).length;

    }

    if (this.IsParallelEditEnabled) {
      this.datacellindx += 1;
    }
    if (this.IsNested) {
      this.datacellindx += 1;
    }

    this.getCurrentDataList();

    if (this.IsEditable) {
      this.editMode = true;
    }

    // to initialise the field level celllock logic for all data
    if (!isNullOrUndefined(this.GridData) && this.GridData.length > 0) {
      this.initCellLock(this.GridData);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes >> ');
    // console.log(changes);



    // Change in data
    if (!isNullOrUndefined(changes.GridData) && changes.GridData.previousValue !== changes.GridData.currentValue) {
      if (!isNullOrUndefined(this.NestedTemplate)) {
        this.GridDataTotalCount = this.filterByPipe.transform(this.GridData, this.DataFilter).length;
        
      }
    }

    // to initialise the field level celllock logic for all data
    if (!isNullOrUndefined(changes.GridData) && changes.GridData.previousValue !== changes.GridData.currentValue
      && !isNullOrUndefined(changes.GridData.previousValue) && !isNullOrUndefined(changes.GridData.currentValue)
      && changes.GridData.previousValue.length == 0 && changes.GridData.currentValue.length > 0) {

      if (this.GridData.length > 0) {
        this.initCellLock(this.GridData);
        this.getCurrentDataList();
      }
    }

  }

  ngOnDestroy() {
    // if (this.osInstance && this.osInstance.destroy) {
    //   this.osInstance.destroy();
    // }
  }

  ngAfterViewInit() {
    let eleTarget: HTMLElement = this.eleRef.nativeElement.querySelector('.table-responsive');

    // Remove the focus on any control in the grid
    if (this.IsEditable) {
      setTimeout(() => { this.setGridFocus(); }, 100);
    }

    // Enabling column resize in Grid
    if (this.IsColResizable) {
      let allthElms: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll('table tr th');
      if (!isNullOrUndefined(allthElms)) {
        allthElms.forEach((th) => {

          // Column resize property added as part of FieldTemplate. If no property defined, then default is true.
          // avoided adding resizer for column that is fixed/sticky
          if (th.dataset.resize?.toString() === 'true' && th.dataset.fixedcol === 'false') {
            th.style.position = 'relative';

            let grip: HTMLDivElement = document.createElement('div');
            grip.classList.add('tbl-column-resizer');

            grip.addEventListener('mousedown', (e) => {
              this.thElm = th;
              this.startOffset = th.offsetWidth - e.pageX;

              // make grid cell non-editable when resizing any column
              if (this.editMode === true && this.selectedCell.length > 0) {
                let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
                let currCell: HTMLTableCellElement = table.querySelector('#' + this.gridRandomId + '_Cell_' + this.selectedCell + '_')!;

                let x = currCell.querySelector('div.reseteditdiv') as HTMLElement;
                if (!isNullOrUndefined(x)) {
                  x.click();
                  currCell.focus();
                }
              }

              this.preventEvent(e);
            });

            grip.addEventListener('click', (e) => {
              this.preventEvent(e);
            });

            th.appendChild(grip);
            // }

          }

        });

        document.addEventListener('mousemove', (e) => {
          if (!isNullOrUndefined(this.thElm)) {
            let colwidth = this.startOffset + e.pageX;

            // set column minwidth if applicable
            let colminwidth = parseInt(this.thElm.style.minWidth) || 0;
            if (colminwidth > colwidth) { colwidth = colminwidth; }

            let tablele: HTMLDivElement = this.eleRef.nativeElement.querySelector('table');
            if (!isNullOrUndefined(tablele)) {
              colwidth = colwidth / tablele.offsetWidth * 100;
            }

            // this.thElm.style.width = this.startOffset + e.pageX + 'px';
            this.thElm.style.width = colwidth + '%';

            this.preventEvent(e);
          }
        });

        document.addEventListener('mouseup', (e) => {
          this.thElm = undefined;

          this.preventEvent(e);
        });

      }

    }

  }

  preventEvent(e: any) {
    let ev = e || window.event;

    if (ev.stopPropagation) {
      ev.stopPropagation();
    }

    if (ev.preventDefault) {
      ev.preventDefault();
    }

    return false;
  }

  setTemplateReadOnly() {
    this.FieldTemplate.forEach(item => item.Editable = 0);
  }

  setGridFocus() {

    let focusableEles: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll(`
         input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
         button:not([disabled]), [tabindex="0"]`);
    // this.focusableEles = Array.prototype.slice.call(this.focusableEles);

    if (focusableEles.length > 0) {
      let firstFocusableEle = focusableEles[0];
      // let lastFocusableEle = focusableEles[focusableEles.length - 1];

      firstFocusableEle.focus();
    }

  }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('changes >> ');
  //   console.log(changes);
  //
  //   if (!isNullOrUndefined(changes.GridData)) {
  //     this.getCurrentDataList();
  //   }
  // }

  // Special handling of Grid data for Milkyway as the URL string is passed for loading data
  isValidDataType(): boolean {
    let flag: boolean = false;

    if (!isNullOrUndefined(this.GridData) && typeof (this.GridData) === 'object' && this.GridData.length > 0) {
      flag = true;
    }

    return flag;
  }

  // ReloadGrid(): void {
  //   this.ngOnInit();
  // }

  getColumnCount(): number {
    let colCnt = 0;
    let allthElms: HTMLHeadElement[] = this.eleRef.nativeElement.querySelector('table thead tr').querySelectorAll('th:not(.d-none)');
    if (!isNullOrUndefined(allthElms)) {
      colCnt = allthElms.length;
    }

    return colCnt;
  }

  setDispcols(): string {
    let dispcols: string = '';
    for (let i = 0; i < Object.keys(this.FieldTemplate).length; i++) {
      if (!this.FieldTemplate[i].IsHidden) {
        dispcols = dispcols + ',' + this.FieldTemplate[i].FieldName;
      }
    }
    return dispcols.length > 0 ? dispcols + ',' : '';
  }

  getGridWidth(): number {
    let nTotalWidth: number = 0;
    for (let i = 0; i < Object.keys(this.FieldTemplate).length; i++) {
      if (
        this.DisplayColumns.indexOf(
          ',' + this.FieldTemplate[i].FieldName + ','
        ) >= 0
      ) {
        nTotalWidth += parseFloat(this.FieldTemplate[i].ColumnWidth);
      }
    }
    return nTotalWidth;
  }

  showGridCol(FieldName: string): boolean {
    return this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0
      ? true
      : false;
  }

  // setGridColWidth(FieldName: string, ColumnWidth: number): string {
  setGridColWidth(FieldName: string, ColumnWidth: any): string {

    let width =
      this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0
        ? ColumnWidth / this.GridTotalWidth * 100
        : 0;
    return width.toString() + '%';

    // // Convert the width in percentage to px
    // let tblele: HTMLTableElement = this.eleRef.nativeElement.querySelector('table');
    // let tblwidth = 0;

    // if (!isNullOrUndefined(tblele)) {
    //   tblwidth = tblele.offsetWidth;
    // }

    // width = (tblwidth * (width / 100));

    // return width.toString() + 'px';
  }

  setTileHeight(): number {
    let otherHt = 56 + 15 + 36 + 7;
    return 290 + 20;
  }

  fldonEditStart(event: any): void {
    if (this.IsParallelEditEnabled) {
      this.FieldEditStart.emit(event);
    }
  }

  fldOnClick(event: any): void {

    this.FieldClick.emit(event);
  }
  // removeCellLock(obj: any) {
  //   if (obj.CellLock) {
  //     delete obj.CellLock;
  //   }
  //   for (let prop in obj) {
  //     if (typeof obj[prop] === 'object') {
  //       this.removeCellLock(obj[prop]);
  //     }
  //   }
  // }


  //  removeCellLock(arr:any) {
  //   arr.forEach((obj: { CellLock: any; })=> delete obj.CellLock);
  //   return arr;
  // }
  fldOnChange(event: any): void {
  //  this.removeCellLock(this.currentDataList);
    //console.log(this.currentDataList);
    //console.log(event.item);
 //   this.removeCellLock(event.item);
    // this.FieldChange.emit({ 'fieldname': event.fieldname, 'item': event.item,  });
    let item_indx = this.currentDataList.findIndex((x: any) => x === event.item);

    let eventdata = {
      fieldname: event.fieldname, item: event.item, oldValue: event.oldValue,
      item_index: item_indx
    } as FieldEventData;
    this.FieldChange.emit(eventdata);
  }

  // cellOnClick(item, key, item_indx, key_indx, event): void {
  //    this.selectedCell = item_indx + '-' + key_indx;
  // }

  // cellOnFocusOut(): void { };

  // // setSelectedCell(cell: any): void {

  // //   console.log('prev cell > ' + this.selectedCell);

  // //   this.prevSelectedCell = this.selectedCell;

  // //   if (cell !== undefined || cell !== null) {

  // //     // different cell, then reset edit mode
  // //     if (this.editMode && this.selectedCell !== (cell.row + '-' + cell.col)) {
  // //       // this.editMode = false;

  // //       this.resetPrevCell();
  // //     }

  // //     this.selectedCell = cell.row + '-' + cell.col;
  // //     this.selectedCellItemData = cell.itemdata;

  // //   }
  // //   else {
  // //     // this.editMode = false;
  // //     this.selectedCell = '';
  // //     this.selectedCellItemData = null;
  // //   }

  // //   console.log('now cell > ' + this.selectedCell);
  // // }

  // SetBackGroundColor(cell: any): void {

  // }

  setDispNested(bExpand: boolean, id: any): void {
    if (bExpand) {
      this.selectedNest.push(id);
    }
    else {
      let indx = this.selectedNest.indexOf(id);
      if (indx >= 0) { this.selectedNest.splice(indx, 1); }
    }
  }
  getNestedData(value: any): any {
    let NestedDataFilter: any = {};

    NestedDataFilter[this.FilterField] = value;

    return NestedDataFilter;
  }

  getNestedDataForHierarchy(item: any): any {

    let resultData: any = [];

    // tempGridData = this.GridData.filter(x=> x[this.FilterField] = value);

    this.getRecursiveGridData(resultData, item);

    // this.NestedGridDataTotalCount[item[this.ParentField]] = resultData.length;

    return resultData;
  }

  getRecursiveGridData(resultData: any[], item: any) {
    let filterCond: any = {}, filteredData: any[] = [];

    filterCond[this.FilterField] = { '$or': [item[this.ParentField]] };
    filteredData = this.filterByPipe.transform(this.GridData, filterCond);

    filteredData.forEach(subitem => {
      resultData.push(subitem);

      this.getRecursiveGridData(resultData, subitem);

    });

  }

  async getDynamicNestedData(item: any) {

    let resultData: any = [];

    // on expand arrow click
    if (!isNullOrUndefined(this.parent.getDynamicNestedData)) {
      resultData = await this.parent.getDynamicNestedData(item);

      // this.NestedGridDataTotalCount[item[this.ParentField]] = resultData.length;
    }

    // resultData.forEach(subitem => {
    //   let index = this.GridData.findIndex(x=> x[this.ParentField] === subitem[this.ParentField]);

    //   if (index >= 0) {
    //     this.GridData[index] = subitem;
    //   }
    //   else {
    //     this.GridData.push(subitem);
    //   }

    // });

  }

  getReadOnly(key: FieldTemplate, item: any): boolean {
    let bFlag = false; /// defaulting to editable
    bFlag = !key.Editable;

    if (!bFlag && !isNullOrUndefined(key.DataCell) && !isNullOrUndefined(key.DataCell?.CustomLock) && this.cellLock !== undefined) {
      if (!isNullOrUndefined(item['CellLock'])) {
        bFlag = item['CellLock'].filter((x: any) => x.FieldName === key.FieldName).map((y: any) => y.Lock)[0];
      }
      else {
        bFlag = this.cellLock(key.FieldName, item, this.parent);
      }
    }

    // if (!bFlag && !isNullOrUndefined(item['CellLock'])) {
    //   let temp = item['CellLock'].filter(x=> x.cell === key.FieldName);
    //   if (temp.length > 0) {
    //     bFlag = temp[0].lock;
    //   }
    // }

    /// Checking parallel edit access and not locked by user
    if (!bFlag && !isNullOrUndefined(item.RowLockStatus) && item.RowLockStatus === RowLockStatus.Locked) {
      bFlag = true; /// reset as non editable
    }
    // if (!bFlag && !isNullOrUndefined(item.DataRowlock) && item.DataRowlock.RowLockStatus === RowLockStatus.Locked) {
    //   bFlag = true; /// reset as non editable
    // }
    if (!this.IsEditable) {
      bFlag = true;
    }
    return bFlag;
  }

  getRequired(key: FieldTemplate, item: any): boolean {
    let bFlag = false;

    // ReadOnly has higher precedence over Required
    if (!this.getReadOnly(key, item)) {
      bFlag = (key.EditValidate.Required && (isNullOrUndefined(item[key.FieldName]) || item[key.FieldName].length === 0));

      if (!bFlag && (!isNullOrUndefined(key.EditValidate.ConditionalRequired)) && key.EditValidate.ConditionalRequired &&
        !isNullOrUndefined(this.cellRequired)) {

        bFlag = this.cellRequired(key.FieldName, item, this.parent);

      }

    }

    return bFlag;
  }

  getCustomClass(key: FieldTemplate, item: any): string {
    let customclass: string = '';
    if (!isNullOrUndefined(this.CustomCss)) {
      customclass = this.CustomCss(key.FieldName, item, this.parent);
    }

    return customclass;
  }

  pageChanged(event: any): void {
    console.log('pagechanged evnt');
    console.log(event.page);

    this.Pagination.CurrentPage = event.page;

    this.prevSelectedCell = '';
    this.selectedCell = '';
    this.selectedCellItemData = null;

    if (this.PaginationConfig.ServerLoad) {
      this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
    }
  }

  totalPagesChanged(event: number): void {
    let prevPages = this.Pagination.TotalPages;
    let curPage = this.Pagination.CurrentPage;

    this.Pagination.TotalPages = event;

    // console.log('totalPagesChanged');
    // console.log('curPage = ' + curPage);
    // console.log('prevPages = ' + prevPages);
    // console.log('TotalPages = ' + this.Pagination.TotalPages);

    // // Total pages count changes when any new row is added/deleted.
    // setTimeout(() => {
    //   // this.Pagination.CurrentPage = this.Pagination.TotalPages;

    //   // On adding, set focus to last page that has newly add row.
    //   if (prevPages < this.Pagination.TotalPages) {
    //     this.Pagination.CurrentPage = this.Pagination.TotalPages;
    //   }

    //   // On deleting, set focus to current page if available else set to last page.
    //   if (prevPages > this.Pagination.TotalPages && this.Pagination.CurrentPage > this.Pagination.TotalPages) {
    //     this.Pagination.CurrentPage = this.Pagination.TotalPages;
    //   }

    // }, 500);

  }

  setCurrentPage(flag: number): void {
    // flag = 1 => added row, flag = -1 => deleted rows
    // On adding, set focus to last page that has newly add row.
    if (flag === 1 && this.Pagination.CurrentPage !== this.Pagination.TotalPages) {
      this.Pagination.CurrentPage = this.Pagination.TotalPages;

      this.prevSelectedCell = '';
      this.selectedCell = '';
      this.selectedCellItemData = null;

      // if (this.PaginationConfig.ServerLoad) {
      //   this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
      // }
    }

    // On deleting, set focus to current page if available else set to last page.
    if (flag === -1 && this.Pagination.CurrentPage > this.Pagination.TotalPages) {
      this.Pagination.CurrentPage = this.Pagination.TotalPages;
    }
  }

  // getNewItemPageIndex(): number {
  //   let totpages = this.Pagination.TotalPages;
  //   let totitems = this.GridDataTotalCount;
  //   let itemsperpage = this.Pagination.ItemsPerPage;

  //   let lastpageitemscnt = totitems - ((totpages - 1) * itemsperpage);

  //   let newitempageind = ((lastpageitemscnt + 1) > itemsperpage ? totpages + 1 : totpages);
  //   newitempageind--;

  //   return newitempageind;
  // }

  // // resetPrevCell() {

  // //   // if (this.prevSelectedCell !== this.selectedCell) {
  // //   if (this.prevSelectedCell.length > 0) {
  // //     // let s = this.prevSelectedCell.split('-');
  // //     // let item_indx = parseInt(s[0]);
  // //     // let key_indx = parseInt(s[1]);

  // //     let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
  // //     // let prevCell = table.children[item_indx].children[key_indx + this.datacellindx] as HTMLTableDataCellElement;
  // //     let prevCell: any = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.prevSelectedCell + '_');

  // //     let x = prevCell.querySelector('div.reseteditdiv') as HTMLElement;

  // //     if (!isNullOrUndefined(x)) {
  // //       x.click();
  // //     }

  // //   }
  // // }

  // // gridOnblur() {
  // //   if (!isNullOrUndefined(this.currCell)) {
  // //     this.cellKey({ keyCode: 27 });
  // //   }
  // // }

  // // cellClick(event: any, item_indx: any, key_indx: number, item: any) {
  // //   console.log('td click event row=' + item_indx + '; col=' + key_indx);
  // //   this.prevSelectedCell = this.selectedCell;
  // //   this.selectedCell = item_indx + '-' + key_indx;
  // //   this.selectedCellItemData = item;

  // //   // event.preventDefault();

  // //   console.log('prevSelectedCell=' + this.prevSelectedCell);
  // //   console.log('selectedCell=' + this.selectedCell);

  // //   if (this.selectedCell !== this.prevSelectedCell) {
  // //     this.resetPrevCell();

  // //     let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
  // //     // this.currCell = table.children[item_indx].children[key_indx + this.datacellindx] as HTMLTableDataCellElement;
  // //     this.currCell = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_') as HTMLTableDataCellElement;

  // //     this.cellDblClick(event, item_indx, key_indx);
  // //   }
  // //   else {

  // //     // // Patch fix to prevent refresh while showing dropdown list
  // //     // if (this.FieldTemplate[key_indx].Control.Type === 'dropdown') {
  // //     //   event.stopPropagation();
  // //     //   event.preventDefault();
  // //     // }

  // //     // Same cell but not in editable, then make it editable
  // //     if (this.editMode === false) {
  // //       let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
  // //       this.currCell = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_') as HTMLTableDataCellElement;

  // //       this.cellDblClick(event, item_indx, key_indx);
  // //     }
  // //     // else {
  // //     //   // Patch fix to prevent refresh while showing dropdown list
  // //     //   if (this.FieldTemplate[key_indx].Control.Type === 'textbox'
  // //     //         && this.FieldTemplate[key_indx].Control.InputType === 'number' ) {
  // //     //     event.stopPropagation();
  // //     //     event.preventDefault();
  // //     //     event.stopImmediatePropagation();
  // //     //   }
  // //     // }
  // //   }
  // // }

  // // cellDblClick(event: any, item_indx: any, key_indx: number) {
  // //   console.log('td double click event row=' + item_indx + '; col=' + key_indx);
  // //   // this.prevSelectedCell = this.selectedCell;
  // //   // this.selectedCell = item_indx + '-' + key_indx;

  // //   // if (this.selectedCell !== this.prevSelectedCell) {
  // //   //   this.resetPrevCell();
  // //   // }

  // //   // let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
  // //   // // this.currCell = table.children[item_indx].children[key_indx + this.datacellindx] as HTMLTableDataCellElement;
  // //   // this.currCell = table.querySelector(''#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_') as HTMLTableDataCellElement;

  // //   let classlist: DOMTokenList = this.currCell.classList;
  // //   // Parallel Edit cell or Nested cell
  // //   if (classlist.contains('ParallelEdit') || classlist.contains('Nested')) {
  // //     this.currCell.focus();
  // //     return;
  // //   }

  // //   // Enter, Spacebar, F2 - edit cell
  // //   if (this.IsEditable) {
  // //     this.editMode = true;

  // //     let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
  // //     let ctrltype: any = fldctrl.dataset.ctrltype;

  // //     // event.preventDefault();

  // //     // let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
  // //     // x.click();

  // //     switch (ctrltype.toLowerCase()) {
  // //       case 'checkbox': {
  // //         // let a = this.currCell.querySelector('input[type=checkbox]') as HTMLElement;
  // //         // a.click();

  // //         // // reset mode after click
  // //         // this.editMode = false;

  // //         break;
  // //       }
  // //       case 'datepicker': {
  // //         let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
  // //         x.click();

  // //         if (!isNullOrUndefined(this.parent.setDatePickerMinMaxDate)) {
  // //           this.parent.setDatePickerMinMaxDate(this.FieldTemplate[key_indx], this.selectedCellItemData);
  // //         }
  // //         // to show the datepicker pop-up
  // //         setTimeout(() => {
  // //           let b = this.currCell.querySelector('input.datepick') as HTMLElement;
  // //           if (!isNullOrUndefined(b)) {
  // //             b.click();
  // //           }
  // //         }, 50);

  // //         break;
  // //       }
  // //       case 'icon': {
  // //         // let e = this.currCell.querySelector('i.fa') as HTMLElement;
  // //         // e.click();

  // //         break;
  // //       }
  // //       case 'dropdown': {
  // //         let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
  // //         x.click();

  // //         // // to show the dropdown list
  // //         // setTimeout(() => {
  // //         //   // let b = this.currCell as HTMLElement;
  // //         //   // if (!isNullOrUndefined(b)) {
  // //         //   //   b.click();
  // //         //   // }

  // //         //   // // Showing Dropdown list
  // //         //   // // if (!isNullOrUndefined(this.key.Control) && this.key.Control.Type === 'dropdown') {
  // //         //   //   // let t = this.hostElement.nativeElement.querySelector('select') as HTMLSelectElement;

  // //         //   //   let t = this.currCell.querySelector('select') as HTMLSelectElement;

  // //         //   //   if (!isNullOrUndefined(t)) {
  // //         //   //     // t.size = (isNullOrUndefined(this.FieldTemplate[key_indx].Control.List) ?
  // //         //   //     //    1 : this.FieldTemplate[key_indx].Control.List.length);
  // //         //   //     t.dispatchEvent(new Event('mousedown'));
  // //         //   //   }
  // //         //   // // }

  // //         //   this.cellKey({ keyCode: 32 });

  // //         // }, 500);

  // //         // // to set focus on the select's input
  // //         // setTimeout(() => {
  // //         //   let t = this.currCell.querySelector('input[type=text]') as HTMLElement;
  // //         //   if (!isNullOrUndefined(t)) {
  // //         //     t.focus();
  // //         //   }
  // //         // }, 50);

  // //         break;
  // //       }
  // //       default: {
  // //         let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
  // //         x.click();

  // //         break;
  // //       }
  // //     }
  // //   }

  // //   this.currCell.focus();
  // // }

  // // cellKey(event: any) {
  // //   const TAB = 9;
  // //   const ENTER = 13;
  // //   const ESC = 27;
  // //   const SPACEBAR = 32;
  // //   const LEFT_ARROW = 37;
  // //   const UP_ARROW = 38;
  // //   const RIGHT_ARROW = 39;
  // //   const DOWN_ARROW = 40;
  // //   const F2 = 113;

  // //   console.log('onkey event with code - ' + event.keyCode);
  // //   console.log('key - ' + event.key);

  // //   let newtd!: HTMLTableDataCellElement;
  // //   let bfocus: boolean = true;

  // //   switch (event.keyCode) {
  // //     case ESC: {
  // //       newtd = this.currCell as HTMLTableDataCellElement;

  // //       this.editMode = false;
  // //       let x = this.currCell.querySelector('div.reseteditdiv') as HTMLElement;
  // //       x.click();

  // //       break;
  // //     }
  // //     // case ENTER: {
  // //     //   if (!isNullOrUndefined(event.shiftKey) && !event.shiftKey) {
  // //     //     newtd = this.currCell as HTMLTableDataCellElement;

  // //     //     this.editMode = false;
  // //     //     let x = this.currCell.querySelector('div.reseteditdiv') as HTMLElement;
  // //     //     x.click();
  // //     //   }
  // //     //   break;
  // //     // }
  // //     case RIGHT_ARROW: {
  // //       if (!this.editMode) {
  // //         newtd = this.currCell.nextElementSibling as HTMLTableDataCellElement;

  // //         while (!isNullOrUndefined(newtd) && newtd.hasAttribute('hidden')) {
  // //           newtd = newtd.nextElementSibling as HTMLTableDataCellElement;
  // //         }
  // //         if (!isNullOrUndefined(newtd)) {
  // //           newtd.click();
  // //         }
  // //       }
  // //       break;
  // //     }
  // //     case LEFT_ARROW: {
  // //       if (!this.editMode) {
  // //         newtd = this.currCell.previousElementSibling as HTMLTableDataCellElement;

  // //         while (!isNullOrUndefined(newtd) && newtd.hasAttribute('hidden')) {
  // //           newtd = newtd.previousElementSibling as HTMLTableDataCellElement;
  // //         }
  // //         if (!isNullOrUndefined(newtd)) {
  // //           newtd.click();
  // //         }
  // //       }
  // //       break;
  // //     }
  // //     case UP_ARROW: {
  // //       if (!this.editMode) {
  // //         let newtr = this.currCell.parentElement?.previousElementSibling as HTMLTableRowElement;

  // //         if (!isNullOrUndefined(newtr) && !isNullOrUndefined(this.currCell.parentElement)) {

  // //           while (newtr.hasAttribute('hidden')) {
  // //             newtr = newtr.previousElementSibling as HTMLTableRowElement;
  // //           }

  // //           let cellindx = this.currCell.cellIndex;
  // //           let delta = this.currCell.parentElement!.childElementCount - newtr.childElementCount;

  // //           let tempindx = this.currCell.id.indexOf('--1_');
  // //           if (tempindx >= 0) {
  // //             // Required only for Nested cells
  // //             while (delta !== 0) {
  // //               newtr = newtr.previousElementSibling as HTMLTableRowElement;
  // //               delta = this.currCell.parentElement!.childElementCount - newtr.childElementCount;
  // //             }
  // //           }
  // //           else {
  // //             if (!this.currCell.classList.contains('ParallelEdit')) {
  // //               cellindx = (delta === 0 ? cellindx : (cellindx - delta));
  // //             }
  // //           }

  // //           newtd = newtr.children[cellindx] as HTMLTableDataCellElement;

  // //           if (!isNullOrUndefined(newtd)) {
  // //             newtd.click();
  // //           }
  // //         }
  // //       }
  // //       break;
  // //     }
  // //     case DOWN_ARROW: {
  // //       if (!this.editMode) {
  // //         let newtr = this.currCell.parentElement?.nextElementSibling as HTMLTableRowElement;

  // //         if (!isNullOrUndefined(newtr) && !isNullOrUndefined(this.currCell.parentElement)) {
  // //           while (newtr.hasAttribute('hidden')) {
  // //             newtr = newtr.nextElementSibling as HTMLTableRowElement;
  // //           }

  // //           let cellindx = this.currCell.cellIndex;

  // //           // if (this.currCell.classList.contains('Nested') || this.currCell.classList.contains('ParallelEdit')) {
  // //           if (this.currCell.rowSpan > 1) {
  // //             let i = this.currCell.rowSpan;
  // //             while (i && i > 1) {
  // //               newtr = newtr.nextElementSibling as HTMLTableRowElement;
  // //               i--;
  // //             }
  // //           }
  // //           else {
  // //             let delta = this.currCell.parentElement!.childElementCount - newtr.childElementCount;
  // //             cellindx = (delta === 0 ? cellindx : (cellindx - delta));
  // //           }

  // //           newtd = newtr.children[cellindx] as HTMLTableDataCellElement;

  // //           if (!isNullOrUndefined(newtd)) {
  // //             newtd.click();
  // //           }
  // //         }
  // //       }
  // //       break;
  // //     }
  // //     case SPACEBAR: {

  // //       // console.log('inside spacebar');

  // //       let classlist: DOMTokenList = this.currCell.classList;
  // //       // Parallel Edit cell or Nested cell
  // //       if (classlist.contains('ParallelEdit')) {
  // //         // do nothing
  // //         break;
  // //       }

  // //       // Nested cell
  // //       if (classlist.contains('Nested')) {
  // //         let w = this.currCell.querySelector('i.fa') as HTMLElement;
  // //         if (!isNullOrUndefined(w)) {
  // //           w.click();
  // //         }

  // //         this.currCell.focus();
  // //         break;
  // //       }

  // //       // Other data cells
  // //       if (this.IsEditable) {
  // //         this.editMode = true;

  // //         let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
  // //         let ctrltype = fldctrl.dataset.ctrltype;

  // //         switch (ctrltype?.toLowerCase()) {
  // //           case 'checkbox': {
  // //             let a = this.currCell.querySelector('input[type=checkbox]') as HTMLElement;
  // //             a.click();

  // //             break;
  // //           }
  // //           default: {
  // //             break;
  // //           }
  // //         }

  // //         // Special handling for icon-button
  // //         let iconbutton = fldctrl.querySelector('icon-button div') as HTMLElement;
  // //         if (!isNullOrUndefined(iconbutton)) {
  // //           iconbutton.click();
  // //         }

  // //       }

  // //       break;
  // //     }
  // //     case F2: {
  // //       // F2 - edit cell
  // //       newtd = this.currCell as HTMLTableDataCellElement;

  // //       let classlist: DOMTokenList = this.currCell.classList;
  // //       // Parallel Edit - name display cell
  // //       if (classlist.contains('ParallelEdit')) {
  // //         // do nothing
  // //         break;
  // //       }

  // //       // Nested cell
  // //       if (classlist.contains('Nested')) {
  // //         let w = this.currCell.querySelector('i.fa') as HTMLElement;
  // //         if (!isNullOrUndefined(w)) {
  // //           w.click();
  // //         }

  // //         this.currCell.focus();
  // //         break;
  // //       }

  // //       // Other data cells
  // //       if (this.IsEditable) {
  // //         // if (!this.editMode) {
  // //         this.editMode = true;

  // //         let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
  // //         let ctrltype = fldctrl.dataset.ctrltype;

  // //         event.preventDefault();

  // //         switch (ctrltype?.toLowerCase()) {
  // //           case 'checkbox': {
  // //             // let a = this.currCell.querySelector('input[type=checkbox]') as HTMLElement;
  // //             // a.click();

  // //             // // reset mode after click
  // //             // this.editMode = false;

  // //             break;
  // //           }
  // //           case 'datepicker': {
  // //             let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
  // //             x.click();

  // //             if (!isNullOrUndefined(this.parent.setDatePickerMinMaxDate)) {
  // //               let key_indx: any = this.selectedCell.substring(this.selectedCell.lastIndexOf('-')).replace('-', '');
  // //               this.parent.setDatePickerMinMaxDate(this.FieldTemplate[key_indx], this.selectedCellItemData);
  // //             }

  // //             // to show the datepicker pop-up
  // //             setTimeout(() => {
  // //               let b = this.currCell.querySelector('input.datepick') as HTMLElement;
  // //               if (!isNullOrUndefined(b)) {
  // //                 b.click();
  // //               }
  // //             }, 50);

  // //             break;
  // //           }
  // //           case 'icon': {
  // //             // let e = this.currCell.querySelector('i.fa') as HTMLElement;
  // //             // e.click();

  // //             break;
  // //           }
  // //           default: {
  // //             let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
  // //             x.click();

  // //             break;
  // //           }
  // //         }
  // //         // }
  // //         // else {
  // //         //   // already in edit mode
  // //         if (this.gridRandomId +'_Cell_' + this.selectedCell + '_' === newtd.id) {
  // //           bfocus = false;
  // //         }
  // //         // }

  // //       }

  // //       break;
  // //     }
  // //     case TAB: {

  // //       // if (this.editMode) {
  // //       //   this.editMode = false;
  // //       // }

  // //       event.preventDefault();

  // //       // this.prevSelectedCell = this.selectedCell;

  // //       if (!event.shiftKey) {  // TAB
  // //         newtd = this.currCell.nextElementSibling as HTMLTableDataCellElement;
  // //         // this.selectedCell = newtd.tabIndex + '-' + (newtd.cellIndex - this.datacellindx);

  // //         let totcells = 0, loopcnt = 0, tablele: HTMLTableElement = this.eleRef.nativeElement.querySelector('table');
  // //         if (!isNullOrUndefined(tablele)) {
  // //           totcells = tablele.rows.length * tablele.rows[0].cells.length;
  // //         }

  // //         // Checking if the new cell is editable. If non-editable, then move on to the next editable cell.
  // //         while (!isNullOrUndefined(newtd) && (newtd.hasAttribute('hidden') || this.checkCellReadOnly(newtd))) {
  // //           newtd = newtd.nextElementSibling as HTMLTableDataCellElement;

  // //           // if no next column, then move to next row
  // //           if (isNullOrUndefined(newtd)) {
  // //             let newtr = this.currCell.parentElement?.nextElementSibling as HTMLTableRowElement;

  // //             if (!isNullOrUndefined(newtr)) {
  // //               newtd = newtr.firstElementChild as HTMLTableDataCellElement;
  // //             }
  // //             else {  // if no next row, then move to first row first column in grid
  // //               newtr = this.currCell.parentElement?.parentElement?.firstElementChild as HTMLTableRowElement;

  // //               if (!isNullOrUndefined(newtr)) {
  // //                 newtd = newtr.firstElementChild as HTMLTableDataCellElement;
  // //               }
  // //             }
  // //           }

  // //           // to avoid infinite loop when no cell is editable.
  // //           loopcnt++;
  // //           if (loopcnt > totcells - 1) {
  // //             break;
  // //           }

  // //         }

  // //       }
  // //       else {    // SHIFT + TAB
  // //         newtd = this.currCell.previousElementSibling as HTMLTableDataCellElement;
  // //         // this.selectedCell = newtd.tabIndex + '-' + (newtd.cellIndex - this.datacellindx);

  // //         let totcells = 0, loopcnt = 0, tablele: HTMLTableElement = this.eleRef.nativeElement.querySelector('table');
  // //         if (!isNullOrUndefined(tablele)) {
  // //           totcells = tablele.rows.length * tablele.rows[0].cells.length;
  // //         }

  // //         // Checking if the new cell is editable. If non-editable, then move on to the previous editable cell.
  // //         while (!isNullOrUndefined(newtd) && (newtd.hasAttribute('hidden') || this.checkCellReadOnly(newtd))) {
  // //           newtd = newtd.previousElementSibling as HTMLTableDataCellElement;

  // //           // if no prev column, then move to prev row
  // //           if (isNullOrUndefined(newtd)) {
  // //             let newtr = this.currCell.parentElement?.previousElementSibling as HTMLTableRowElement;

  // //             if (!isNullOrUndefined(newtr)) {
  // //               newtd = newtr.lastElementChild as HTMLTableDataCellElement;
  // //             }
  // //             else { // if no prev row, then move to last row last column in grid
  // //               newtr = this.currCell.parentElement?.parentElement?.lastElementChild as HTMLTableRowElement;

  // //               if (!isNullOrUndefined(newtr)) {
  // //                 newtd = newtr.lastElementChild as HTMLTableDataCellElement;
  // //               }
  // //             }
  // //           }

  // //           // to avoid infinite loop when no cell is editable.
  // //           loopcnt++;
  // //           if (loopcnt > totcells - 1) {
  // //             break;
  // //           }

  // //         }

  // //       }

  // //       if (!isNullOrUndefined(newtd)) {

  // //         // let tempid = newtd.id.replace(this.gridRandomId +'_Cell_', '');
  // //         // tempid = tempid.replace('_', '');
  // //         // this.selectedCell = tempid;

  // //         // if (this.selectedCell !== this.prevSelectedCell) {
  // //         //   this.resetPrevCell();
  // //         // }

  // //         newtd.click();
  // //       }
  // //       // }

  // //       break;
  // //     }
  // //   }

  // //   // If we didn't hit a boundary, update the current cell
  // //   if (!isNullOrUndefined(newtd)) {
  // //     this.currCell = newtd;

  // //     if (bfocus) {
  // //       this.currCell.focus();
  // //     }
  // //   }
  // // }

  // function to check is given cell is non-editable.
  checkCellReadOnly(newtd: HTMLTableCellElement) {
    let bflag: boolean = false;

    // check for readonly class
    if (!isNullOrUndefined(newtd)) {
      let classlist: DOMTokenList = newtd.classList;
      if (classlist.contains('readonly') || classlist.contains('ParallelEdit')) {
        bflag = true;
      }
    }

    return bflag;
  }

  setCellFocus() {
    let newtd = this.currCell as HTMLTableDataCellElement;

    if (!isNullOrUndefined(newtd)) {
      newtd.focus();
    }
  }

  // sort function
  // sort(property) {
  sort(key: FieldTemplate) {

    if (this.IsSortable) {
      // Don't allow to sort grid if cells as merged
      if ((this.FieldTemplate.filter(x => x.RowMerge === true)).length === 0) {

        let property: any = (!isNullOrUndefined(key.LinkedField) && key.LinkedField!.length > 0 ? key.LinkedField : key.FieldName);

        if (this.sortcolumn === property) {
          // Same column
          this.isDesc = !this.isDesc; // change the direction
        }
        else {
          this.sortcolumn = property;
          this.isDesc = false;
        }
        this.sortdirection = this.isDesc ? -1 : 1;

        // Available type
        // 'label'
        // 'textbox' => text, number
        // 'textarea'
        // 'dropdown'
        // 'typeahead'
        // 'link'
        // 'datepicker'
        // 'checkbox'
        // 'button'
        // 'icon'
        this.sortcolumntype = (key.Control?.Type === 'textbox' ? key.Control.InputType : key.Control?.Type) || '';
      }
    }

  }

  // Enabling Row Merge in Grid columns
  getCurrentDataList(): any {
    if (!isNullOrUndefined(this.GridData) && this.GridData.length > 0) {
      // PaginationConfig.ServerLoad? (GridData | orderBy:
      //       {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype}
      //     | filterBy: DataFilter | filterBy: {PageIndex: (Pagination.CurrentPage - 1)})
      //  : (GridData | orderBy:
      //       {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype}
      //     | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)
      //                             :(Pagination.CurrentPage*Pagination.ItemsPerPage))

      let orderedData = this.orderByPipe.transform(this.GridData,
        { property: this.sortcolumn, direction: this.sortdirection, ignorecase: 'true', type: this.sortcolumntype });

      let filteredData = this.filterByPipe.transform(orderedData, this.DataFilter);

      let currentData = filteredData;
      console.log()

      if (!isNullOrUndefined(this.Pagination.CurrentPage) && !isNullOrUndefined(this.Pagination.TotalPages)) {
        if (this.PaginationConfig.ServerLoad) {
          currentData = filteredData.filter((x: any) => x.PageIndex === (this.Pagination.CurrentPage - 1));
        }
        else {
          currentData = filteredData.slice((this.Pagination.CurrentPage - 1) * this.Pagination.ItemsPerPage,
            (this.Pagination.CurrentPage * this.Pagination.ItemsPerPage));
        }
      }

      this.currentDataList = currentData;

      // console.log('currentDataList >> ' + JSON.stringify(this.currentDataList));
    }
    return this.currentDataList;
    console.log(this.currentDataList)
  }

  getRowSpan(key: FieldTemplate, item: any): number {
    let rowcount: number = 1;

    // let temprows = this.currentDataList.filter(x => x[key.FieldName] === item[key.FieldName]);
    let currentData = this.getCurrentDataList();
    let temprows = currentData.filter((x: any) => x[key.FieldName] === item[key.FieldName]);
    rowcount = temprows.length;

    return rowcount;
  }

  showMergeCell(key: FieldTemplate, item: any, item_indx: number): boolean {
    let bShow: boolean = true;

    let currentData = this.getCurrentDataList();
    // if (item_indx > 0 && this.currentDataList[item_indx - 1][key.FieldName] === item[key.FieldName]) {
    if (item_indx > 0 && currentData[item_indx - 1][key.FieldName] === item[key.FieldName]) {
      bShow = false;
    }

    return bShow;
  }

  actButtonClick(event: any) {
    // if (!isNullOrUndefined(event) && !isNullOrUndefined(event.buttonname) && event.buttonname !== ''
    //   && event.buttonname.toUpperCase() === 'ADDNEWROW') {
    //   this.addNewRow();
    // }

    this.ActionBarClick.emit(event);
  }

  pageItemsChange(event: any) {
    let pagesPerchange = event.target.value;
    this.pagesPerchange = pagesPerchange;

    if (this.curItemsPerPage > 0 && this.pagesPerchange != null && this.pagesPerchange > 0) {
      //   this.curItemsPerPage = this.curItemsPerPage > 10 ? pagesPerchange : 10;
      this.Pagination.ItemsPerPage = this.curItemsPerPage;
      this.Pagination.CurrentPage = 1;
      // this.prevSelectedCell = '';
      // this.selectedCell = '';
      // this.selectedCellItemData = null;

      if (this.PaginationConfig.ServerLoad) {
        this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
      }
      // this.curItemsPerPage=pagesPerchange;
      console.log(this.Pagination.ItemsPerPage);
      console.log(this.Pagination.CurrentPage)

    }
    else {
      this.Pagination.ItemsPerPage = 100;
    }

  }


  enableFieldsForRow(colmlist: string, itemindex: any, bdisable?: boolean): void {
    let fieldlst = colmlist.split(',');

    this.editMode = this.IsEditable;

    for (let j = 0; j < fieldlst.length; j++) {
      let fieldname = fieldlst[j];

      // id="{{gridRandomId}}_Row_{{item_indx}}_Main"
      let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
      let currCell: HTMLTableCellElement = table.querySelector('#' + this.gridRandomId + '_Row_' + itemindex + '_Main')!;
      let fldctrl = currCell.querySelector('field-control[data-fieldname="' + fieldname + '"]') as HTMLElement;

      if (!isNullOrUndefined(fldctrl)) {
        let ctrltype = fldctrl.dataset.controltype;

        switch (ctrltype) {
          case 'button':
            let btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;

            if (!isNullOrUndefined(btn)) {
              // btn['disabled'] = bdisable;
              btn.disabled = bdisable!;
            }
            break;

          case 'checkbox':
            let chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;

            if (!isNullOrUndefined(chkbx)) {
              // chkbx['disabled'] = bdisable;
              chkbx.disabled = bdisable!;
            }
            break;

          default:
            let x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;

            if (!isNullOrUndefined(x)) {
              x.click();
            }
            break;

        }

      }

    }

  }

  refreshAllFieldsForRow(item: any, itemindex: any): void {

    // let fieldlst = colmlist.split(',');
    let fieldlst = this.DisplayColumns.substring(1, this.DisplayColumns.length - 1).split(',');

    this.editMode = this.IsEditable;

    for (let j = 0; j < fieldlst.length; j++) {
      let fieldname = fieldlst[j];

      let bdisable: boolean = item['CellLock'].filter((x: any) => x.FieldName === fieldname).map((y: any) => y.Lock)[0];

      // TODO: This code should be checked and removed later
      this.editMode = !bdisable;

      // id="{{gridRandomId}}_Row_{{item_indx}}_Main"
      let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
      let currCell: HTMLTableCellElement = table.querySelector('#' + this.gridRandomId + '_Row_' + itemindex + '_Main')!;
      let fldctrl = currCell.querySelector('field-control[data-fieldname="' + fieldname + '"]') as HTMLElement;

      if (!isNullOrUndefined(fldctrl)) {
        let ctrltype = fldctrl.dataset.controltype;

        switch (ctrltype) {
          case 'button':
            let btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;

            if (!isNullOrUndefined(btn)) {
              // btn['disabled'] = bdisable;
              btn.disabled = bdisable!;
            }
            break;

          case 'checkbox':
            let chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;

            if (!isNullOrUndefined(chkbx)) {
              // chkbx['disabled'] = bdisable;
              chkbx.disabled = bdisable!;
            }
            break;

          default:
            let x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;

            if (!isNullOrUndefined(x)) {
              x.click();
            }
            break;

        }

      }

    }

    // TODO: This code should be checked and removed later
    this.editMode = this.IsEditable;

  }

  initCellLock(Data: any[]): void {

    console.log('inside initCellLock in GridDisp comp');

    // for each datarow
    Data.forEach((item: any, item_index: number) => {
      let itemcelllock: Array<any> = [];

      // for each fieldtemplate
      this.FieldTemplate.forEach((key: FieldTemplate, key_index: number) => {
        let editcell = false;

        if (key.Editable && item['IsEditable']) {
          // Check for cell level logic if defined in the template
          if (isNullOrUndefined(key.DataCell) || isNullOrUndefined(key.DataCell?.CustomLock)) {
            editcell = true;
          }
          else {
            // if the cell is editable based on the row data and other custom conditions built in this component.
            editcell = !this.cellLock(key.FieldName, item, this.parent);
          }
        }

        let fldlock: any = { FieldName: key.FieldName, Lock: !editcell };
        itemcelllock.push(fldlock);

      });

      // finally add the lock object to datarow
      item['CellLock'] = itemcelllock;
    });

  }
  initCellLock_1(Data: any[]): void {

    console.log('inside initCellLock in GridDisp comp');
  
  
  
  
    this.FieldTemplate.forEach((key: FieldTemplate, key_index: number) => {
  
      key.Editable && Data.forEach((item: any) => {
  
        if (isNullOrUndefined(key.DataCell) || isNullOrUndefined(key.DataCell?.CustomLock)) {
  
          item.CellLock.push({ FieldName: key.FieldName, Lock: false });
  
        } else {
  
          const editCell = !this.cellLock(key.FieldName, item, this.parent);
  
          item.CellLock.push({ FieldName: key.FieldName, Lock: !editCell });
  
        }
  
      });
  
    });
  
  }


  refreshAllFieldsForRow_1(item: any, itemindex: any): void {

    const fieldlst = this.DisplayColumns.substring(1, this.DisplayColumns.length - 1).split(',');
  
  
  
  
    this.editMode = this.IsEditable;
  
  
  
  
    const table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
  
  
  
  
    fieldlst.forEach((fieldname) => {
  
      const bdisable: boolean = item.CellLock.find((x: any) => x.FieldName === fieldname)?.Lock || false;
  
  
  
  
      this.editMode = !bdisable;
  
  
  
  
      const currCell: HTMLTableCellElement = table.querySelector(`#${this.gridRandomId}_Row_${itemindex}_Main`)!;
  
      const fldctrl = currCell.querySelector(`field-control[data-fieldname="${fieldname}"]`) as HTMLElement;
  
  
  
  
      if (fldctrl) {
  
        const ctrltype = fldctrl.dataset.controltype;
  
  
  
  
        switch (ctrltype) {
  
          case 'button':
  
            const btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;
  
  
  
  
            if (btn) {
  
              btn.disabled = bdisable;
  
            }
  
            break;
  
  
  
  
          case 'checkbox':
  
            const chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;
  
  
  
  
            if (chkbx) {
  
              chkbx.disabled = bdisable;
  
            }
  
            break;
  
  
  
  
          default:
  
            const x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;
  
  
  
  
            if (x) {
  
              x.click();
  
            }
  
            break;
  
        }
  
      }
  
    });
  
  
  
  
    this.editMode = this.IsEditable;
  
  }
  
  
  




  addDefaultRows() {
    if (this.GridProp.AddNewRow === true) {
      let datalength: number = this.GridData.length;
      if (datalength < 5) {
        for (let i = 0; i < (5 - datalength); i++) {
          this.addNewRow(false);
          // this.addNewRow(false);

          // reset new index value since these rows are by system
          this._NewRowInd = -1;
        }
      }
    }
  }

  addNewRow(bEmptyCheck?: boolean): void {
    // addNewRow(bFirst?: boolean): void {
    // bFirst = (isNullOrUndefined(bFirst)? true: bFirst);

    bEmptyCheck = (isNullOrUndefined(bEmptyCheck) ? true : bEmptyCheck);
    // Check to see if empty rows available. If so, then show message and do not add row.
    this.UniqueKeyName = (this.GridProp.UniqueKeyName === undefined ? this.UniqueKeyName : this.GridProp.UniqueKeyName);
    let emptyrows = this.GridData.filter((x: any) => x['IsModified'] === 0 && x[this.UniqueKeyName] <= 0);

    if (emptyrows.length > 0 && bEmptyCheck) {
      // this._toastr.info('Please fill in the available empty row.', this.ComponentTitle);
      this.showNotificationMessage('Please fill in the available empty row.', 2, '', null, '', '', 1000);

      this.afterAddNewRow(null as any);
      return;
    }

    if (!isNullOrUndefined(this.GridProp) && !isNullOrUndefined(this.GridProp.DefaultRow)) {
      // this.ObjectType = this.GridProp.DataObject;
      // let newitem: any = new this.ObjectType();
      let newitem: any = JSON.parse(JSON.stringify(this.GridProp.DataObject));


      this.GridProp.DefaultRow?.forEach((item: any) => {
        // newitem[item.Field] = item.Value;
        (newitem as any)[item.Field] = item.Value;
      });

      // newitem['PageIndex'] = this._DataInput['PageIndex'];
      // newitem['PageIndex'] = this.getNewItemPageIndex();
      (newitem as any)['PageIndex'] = this.getNewItemPageIndex();
      // newitem = Object.assign(newitem, { 'PageIndex' : this.getNewItemPageIndex() });

      this.GridData.push(newitem);

      if (!(!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) &&
        this.GridProp.PaginationConfig?.ServerLoad)) {
        this.DataTotalCount++;
      }
      // if (bFirst) {
      //     this._Data.unshift(newitem);
      // }
      // else {
      //     this._Data.push(newitem);
      // }

      if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) &&
        this.GridProp.PaginationConfig?.ServerLoad) {

        this._NewRowInd = (this.GridData.length - 1);
      }

      this.afterAddNewRow(this.GridData[this.GridData.length - 1]);
    }
  }

  afterAddNewRow(RowItem: any) {
    // Do nothing
  }

  getNewItemPageIndex(): number {
    let newitempageind = 0;

    if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
      !isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) &&
      this.GridProp.PaginationConfig?.ServerLoad) {

      let totitems = this.DataTotalCount;
      let itemsperpage: any = 10;

      if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig.ItemsPerPage)) {
        itemsperpage = this.GridProp.PaginationConfig.ItemsPerPage;
      }

      let totpages = (totitems - (totitems % itemsperpage)) / itemsperpage;
      totpages = (totitems % itemsperpage) > 0 ? totpages + 1 : totpages;

      let lastpageitemscnt = totitems - ((totpages - 1) * itemsperpage);

      newitempageind = ((lastpageitemscnt + 1) > itemsperpage ? totpages + 1 : totpages);
      newitempageind--;

    }

    return newitempageind;
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

}
