import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { ComponentCodes, ComponentConfig, ProcessStatus, ReferenceValueType } from '../../app.interface';
import { Subscription } from 'rxjs';
import { CommonAPIService } from '../../shared/app.commonservice';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { environment } from '../../../environments/environment';
import { FormComponent } from 'src/modules/form/form.component';
import { ModelConfigService } from './model-config.service';
import { ModalService } from 'src/modules/modal/modal.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { GridComponent } from 'src/modules/grid/grid.component';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined, isStringNullOrEmpty } from '../../shared/app.sharedservice';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { ToastrService } from 'ngx-toastr';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { isEmptyBindingElement } from 'typescript';
@Component({
  selector: 'app-model-config',
  templateUrl: './model-config.component.html',
  styleUrls: ['./model-config.component.css']
})

@AutoUnsubscribe
export class ModelConfigComponent implements OnInit {
  @ViewChild('ModelConfigForm', { static: false }) ModelConfigForm!: FormComponent;
  @ViewChild('ModelConfig_TowerSplit_Form', { static: false }) ModelConfig_TowerSplit_Form!: FormComponent;
  @ViewChild('ITMetricsForm', { static: false }) ITMetricsForm!: FormComponent;
  @ViewChild('ModelBaselineForm', { static: false }) ModelBaselineForm!: FormComponent;
  @ViewChild('ModelBaselineGrid', { static: false }) ModelBaselineGrid!: GridComponent;
  @ViewChild('ModelClientProfileTD', { static: false }) ModelClientProfileTD!: GridComponent;


  public ModelConfigTemplate: FieldTemplate[] = [];
  public ModelConfigurationAM: FieldTemplate[] = [];
  public AdditionalAMTemplate: FieldTemplate[] = [];
  public EnterAddAMTemplate: FieldTemplate[] = [];
  public ClientProfileTable: FieldTemplate[] = [];
  public ITMetricsTemplate: any[] = [];
  public ITMetricsEmployeeOptionTemplate: any[] = [];
  public ITMetricsEmployeeGridTemplate: any[] = [];
  public ITMetricsRevenueOptionTemplate: any[] = [];
  public ITMetricsRevenueGridTemplate: any[] = [];
  public ITMetricsOPEXOptionTemplate: any[] = [];
  public ITMetricsOPEXGridTemplate: any[] = [];
  public EmployeeGridITMetrics: any[] = [];
  public RevenueandITGridITMetrics: any[] = [];
  public AnnualCompensationTemplate: any[] = [];
  public DeliveryModelTemplate: any[] = [];
  public ModelBaselineTemplate: FieldTemplate[] = [];
  public modalInf: ModalInterface = new ModalInterface();
  public IsLoading: boolean = false;
  public Metricsapproach: boolean = false;
  public optionSelected: boolean = false;
  public dataTotalCount: number = 1;
  public metricsCount: number = 0;
  public OnDealChange: boolean = false;
  public OnConfigChange: boolean = false;
  public showResourceLotDistribution: boolean = false;
  public showLotResourceCount: boolean = true;
  public showCurrentClientResource: boolean = true;
  public showTicketLotDistribution: boolean = false;
  public showLotTicketsCount: boolean = false;
  public showUserLotDistribution: boolean = false;
  public showLotUsersCount: boolean = false;
  public showLotTicketsDefaultTitle: boolean = true;
  public showLotsCountMonthly: boolean = false;
  public datacountFlag: boolean = false;
  public isInputDisabled: boolean = false;
  public dealProgressMeter: number = 0;
  public gridTotalCount: any = 0;
  public ClientResourceCount: any;
  public ItMetricsButton: any = [];
  public ClientprofileButtons: any = [];
  public DeliveryModelButtons: any = [];
  public SaveButtons: any = [];
  public BaselineGridData: any = [];
  public LotNameforGrid: any;
  public Approach: any;
  public AdditionalsupportData: any;
  public Anticipatedfte: any;
  public Mode: string = 'CREATE';
  public Hiddenform: boolean = false;
  public showGrid: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public ModelConfigPermission: any = [];
  public ModelConfigProperties: any = [];
  public TowerSplitData: any = [];
  public ModelConfigData: any = {};
  public ModelConfigHeaderData: any = {};
  public ITMetricsGridData: any = {};
  public getModelConfigurationGridCalculation: any = {};
  public PopUpITMetricsGridData: any = {};
  public ModelBaselineData: any = {};
  public DeliveryDataModel: any = {};
  public BaselineTicketData: any = {};
  public BaselineVolData: any = {};
  public highlightitemprevious: any;
  public GridDataServiceProfileUserApproach: any;
  public TowerName: any = { fieldname: 'TowerName', value: '' };
  public SubTowerName: any = { fieldname: 'SubTowerName', value: '' };
  public LotName: any = { fieldname: 'LotName', value: '' };
  public LotResourceCount: any = { fieldname: 'LotResourceCount', value: '' };
  public ResourceLotDistributionPer: any = { fieldname: 'ResourceLotDistribution%', value: '' };
  public CurrentClientResourceCountriesCount: any = { fieldname: 'CurrentClientResourceCountriesCount', value: '' };
  public TicketsLotDistributionPer: any = { fieldname: 'TicketLotDistribution%', value: '' };
  public LotTicketsCount: any = { fieldname: 'LotTicketsCount', value: '' };
  public UsersLotDistributionPer: any = { fieldname: 'UsersLotDistribution%', value: '' };
  public LotUsersCount: any = { fieldname: 'LotUsersCount', value: '' };
  public TotalResourceCount: any = { fieldname: 'TotalResourceCount', value: '' };
  public Hasperbreakdownbylottopdown: any = { fieldname: 'Hasperbreakdownbylottopdown', value: '' };
  public EnterTotalTicketCount: any = { fieldname: 'EnterTotalTicketCount', value: '' };
  public HasperbreakdownbylotTicketBased: any = { fieldname: 'HasperbreakdownbylotTicketBased', value: '' };
  public EnterTotalUsersCount: any = { fieldname: 'EnterTotalUsersCount', value: '' };
  public HasPerBreakdownbyLot: any = { fieldname: 'HasPerBreakdownbyLot', value: '' };
  public TotalNoOfEmployee: any = { fieldname: 'TotalNoOfEmployee' };
  public YearlyOpex: any = { fieldname: 'YearlyOpex' };
  public YearlyRevenu: any = { fieldname: 'YearlyRevenu' };
  public AnnualCompensation: any = { fieldname: 'AnnualCompensation', value: '' };
  public notify: NotificationData = new NotificationData();
  public GlobalConfig: any = {};
  public opex: boolean = false;
  public revenue: boolean = false;
  public employee: boolean = false;
  public selectedInfoData: any;
  public GetAmDealProgressBarPerData: any;
  public ModelButtons: any = [];
  public selectedDeal: any;
  public DealHeaderId: any;
  public DealLineItemId: any;
  public DealSubLineItemId: any;
  public IsPagination: boolean = false;
  public distributionPer: boolean = true;
  public ITMetricsData: any = {};
  public displayNotifybox: boolean = false;
  public displayNotifyDeliveryModel: boolean = false;
  public Title: any = 'Model Configuration Profile';
  public ModuleName: string = '';
  public MainModule: string = '';
  public ApproachName: any;
  public DefaultGridData: any;
  public OpenContent: boolean = false;
  public AddNewFlag: boolean = false;
  public ITSpend: any;
  public ResourceCount: any;
  public ITSpendOPEX: any;
  public ITRevenue: any;
  public ITResourceEmployee: any;
  public ClientResourceData: any;
  public deliveryModelgridData: any;
  public buttonName: any;
  public FormProp: FormProperty = {
    'ColSize': 3,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public ITMetricsFormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    AddNewRow: false,
    DefaultRow: [],
    DataObject: {},
    UniqueKeyName: '',
    IsColResizable: false
  }

  public Config: any = {
    ModelConfigurationActionButtons: []
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public fieldRandomId: string;
  public ModelConfigFullData: any = [];
  public appsDev: number = 0;
  public appsMain: number = 0;
  public Others: number = 0;
  public selecteddata: any = {}

  constructor(private toastr: ToastrService, private title: Title, private _commonAPIService: CommonAPIService, private _router: Router,
    private _appSharedService: AppSharedService, private _route: ActivatedRoute,
    private modelConfigService: ModelConfigService, private modalService: ModalService) {
    this._route.params.subscribe((params: { [x: string]: any; }) => {

      this.DealHeaderId = params['DealHeaderId'];
      this.DealLineItemId = params['DealLineitemId'];
      this.DealSubLineItemId = params['DealSubLineitemId'];
    });
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
    this.fieldRandomId = Math.random().toString(36).substr(2, 5);

  }

  ngOnInit(): void {

    let selTab = this._route.snapshot.data['selectTab'];
    console.log(selTab);

    this.selecteddata = window.history.state.selectedData
    console.log(this.selecteddata);

    this.ModuleName = selTab;
    this.Approach = this._appSharedService.selectedDeal.AmApproach;
    this.MainModule = this._appSharedService.currentUserModuleData.Code;

    if (this.selecteddata != undefined && selTab != 'ModelBaseline') {
      this.DealHeaderId = this.selecteddata.dealheaderid;
      this.DealLineItemId = this.selecteddata.deallineitemid;
      this.LotNameforGrid = this.selecteddata.lotnameforgrid;
      this.DealSubLineItemId = this.selecteddata.dealsublineitemid;
    }


    if (this.selecteddata != undefined && selTab == 'ModelBaseline') {
      // this.DealHeaderId = this.selecteddata.DealHeaderId;
      // this.DealLineItemId = this.selecteddata.DealLineItemId;
      // this.LotNameforGrid = this.selecteddata.LotName;
      // this.DealSubLineItemId = this.selecteddata.DealSubLineItemId;

     // this.DealHeaderId = this.DealHeaderId
     this.DealLineItemId = this.selecteddata.deallineitemid;
      this.LotNameforGrid = this.selecteddata.lotnameforgrid;
      this.DealSubLineItemId = this.selecteddata.dealsublineitemid;
    }

    this.title.setTitle(this.Title);

    if (!isNullOrUndefined(window.history.state.dealData) && !isNullOrUndefined(window.history.state.isMenuBar)
      && window.history.state.isMenuBar === true) {

      this.getSelectedDeal(window.history.state.dealData);
      this._appSharedService.IsCreateMode = false;
      this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);

    }

    if (this._appSharedService.IsCreateMode == true) {
      this.Mode = 'CREATE'
    }
    else {
      this.Mode = 'UPDATE'
    }

    this.selectedDeal = this._appSharedService.selectedDeal;
    console.log(this.selectedDeal.AmApproach, "selectedeal")

    //  APR001    Top Down Approach
    // APR002    Ticket Based Approach
    // APR003    No.of Users Based Approach
    // APR004    IT Metrics Driven Approach
    this.ApproachName = this.selectedDeal.AmApproach;
    // this.LotResourceCount.value=this.selectedInfoData.EnterTotalResourceCount;

    //if(this.ApproachName=='APR001' || this.ApproachName=='APR004'){
    // this.ModuleName= 'ClientProfile';
    // }
    if (this.selectedDeal.AmApproach == "APR004") {
      this.Metricsapproach = true;
      this.showCurrentClientResource = false;
    }

    this.getModelConfigTemplate();

    // this.GetAnticipatedDetails();
    this.ITMetricsData.IsEditable = 1;
    this.ITMetricsGridData.IsEditable = 1;
    this.DeliveryDataModel.IsEditable = 1;
    // this.PopUpITMetricsGridData.IsEditable = 1;
    this.ModelBaselineData.IsEditable = 1;
    this.BaselineVolData.IsEditable = 1;
    this.BaselineTicketData.IsEditable = 1;
    // this.GetServiceProfile();

  }

  actButtonClick(event: any) {
    this.setGridOnblur();
  }

  setGridOnblur() {
    if (!isNullOrUndefined(this.ModelBaselineGrid)) {
      this.ModelBaselineGrid.gridOnblur();
    }
  }

  getModelConfigTemplate() {
    this.IsLoading = true;
    this.ModelConfigTemplate = [];

    let odata: ComponentConfig = {
      Component: 'ModelConfigurationProfile', ComponentCode: ComponentCodes.ModelConfigurationProfile, ReferenceValueType: "1"
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.ModelConfigPermission = res.Data.Permission;
        this.ModelConfigProperties = res.Data.Properties;
        //this.ModelConfigTemplate = template.filter(x => x.Group === 'ModelConfigurationAM');        
        this.ModelConfigTemplate = template;
        this.ITMetricsTemplate = template.filter((x: any) => x.Group === 'ModelConfigurationItMetricsPopup');
        this.ITMetricsEmployeeOptionTemplate = template.filter((x: any) => x.Group === 'TotalNoOfEmployeePopupITMetrics');
        this.ITMetricsEmployeeGridTemplate = template.filter((x: any) => x.Group === 'TotalNoOfEmployeePopupITMetricsGrid');
        this.ITMetricsRevenueOptionTemplate = template.filter((x: any) => x.Group === 'ModelConfigurationItMetricsPopupRevenu');
        this.ITMetricsRevenueGridTemplate = template.filter((x: any) => x.Group === 'ModelConfigurationItMetricsPopupGrid');
        this.ITMetricsOPEXOptionTemplate = template.filter((x: any) => x.Group === 'OPEXPopupItmetrics');
        this.ITMetricsOPEXGridTemplate = template.filter((x: any) => x.Group === 'OPEXPopupItmetricsGrid');
        this.EmployeeGridITMetrics = template.filter((x: any) => x.Group === 'TotalNoOfEmployeePopupITMetricsGrid');
        this.RevenueandITGridITMetrics = template.filter((x: any) => x.Group === 'OPEXPopupItmetricsGrid');

        this.AnnualCompensationTemplate = template.filter((x: any) => x.Group === 'ModelConfigurationItMetricsPopupOpex');
        this.ModelConfigurationAM = template.filter((x: any) => x.Group === 'ModelConfigurationAM');
        this.ClientProfileTable = template.filter((x: any) => x.Group === 'clientresourceprofilegrid');
        if (this.selectedDeal.AmApproach == "APR001") {
          this.AdditionalAMTemplate = template.filter((x: any) => x.Group === 'TopDownApproachAM');
          this.EnterAddAMTemplate = template.filter((x: any) => x.Group === 'EnterTotalResourceCountTopDownBased');
        }
        else if (this.selectedDeal.AmApproach == "APR002") {
          this.showCurrentClientResource = false;
          this.showLotResourceCount = false;
          this.showLotTicketsCount = true;
          this.AdditionalAMTemplate = template.filter((x: any) => x.Group === 'TicketBasedApproachAM');
          this.EnterAddAMTemplate = template.filter((x: any) => x.Group === 'EnterTotalTicketCountTicketBased');
        }
        else if (this.selectedDeal.AmApproach == "APR003") {
          this.showCurrentClientResource = false;
          this.showLotResourceCount = false;
          this.showLotUsersCount = true;
          this.AdditionalAMTemplate = template.filter((x: any) => x.Group === 'UserBasedApproachAM');
          this.EnterAddAMTemplate = template.filter((x: any) => x.Group === 'EnterTotalUsersCountUserBased');
        }

        this.setCompProperties(res.Data.Properties!);
        this.ModelButtons = this.Config.ModelConfigurationActionButtons.filter((x: any) => x.Group === 'ModelConfigurationAM');
        this.ItMetricsButton = this.Config.ITMetricsDetails.filter((x: any) => x.Group === 'ItMetricsModelConfigurationAM');
        this.ClientprofileButtons = this.Config.ClientResourceProfileButtons.filter((x: any) => x.Group === 'ClientResourceAM');
        this.DeliveryModelButtons = this.Config.MBDLMButtons.filter((x: any) => x.Group === 'MBDLMFields');
        this.SaveButtons = this.Config.ServiceProfileButtons.filter((x: any) => x.Group === 'ServiceProfileTBUBA');
        this.setActionButtonPermissions();

        if (this.ModuleName == 'ModelConfig') {
          this.GetModelConfigurationDetails();
        } else if (isNullOrUndefined(this.DealSubLineItemId) || Number(this.DealSubLineItemId) === 0) {
          this.OpenContent = false;
          this.GetModelBaseliningDetails();
          this.GetModelConfigurationDetails();
        } else {
          this.getModuleDetails();
        }



        this.ModelConfigData.IsEditable = 1;

        this.IsLoading = false;

      }

    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getModuleDetails() {
    if (this.ModuleName == 'ModelConfig') {
      this.GetModelConfigurationDetails();
    }
    else if (this.ModuleName == 'ModelBaseline') {
      this.GetModelBaseliningDetails();
    }
    else if (this.ModuleName == 'ServiceProfile') {
      this.GetModelBaseliningDetails();
      this.GetModelConfigurationDetails();
      this.OpenContent = false;
      this.GetServiceProfile();
    }
    else if (this.ModuleName == 'AnticipatedFTE') {
      this.GetModelBaseliningDetails();
      this.GetModelConfigurationDetails();
      this.GetAnticipatedDetails();
    }
    else if (this.ModuleName == 'DeliveryModel') {
      this.GetModelBaseliningDetails();
      this.GetModelConfigurationDetails();
      this.GetMBDeliveryLocationModelling();
    }
    else if (this.ModuleName == 'ClientProfile') {
      this.GetModelBaseliningDetails();
      this.GetClientResourceData();
      this.GetModelConfigurationDetails();
      this.OpenContent = true;
    }
  }

  validateITMetrics(modifieditems: any): boolean {
    let valid: boolean = false;

    if (isNullOrUndefined(modifieditems)) {
      this.showNotificationMessage('All fields are mandatory', 2, '', null, '', '', 1000);
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems)) {
      if ((isNullOrUndefined(modifieditems.Country)) || (isNullOrUndefined(modifieditems.ItMetricsOptions)) || (isNullOrUndefined(modifieditems.Region)) ||
        (modifieditems.Country == '') || (modifieditems.ItMetricsOptions == '') || (modifieditems.Region == '')) {
        this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
        //this.navigate = false;
        valid = false;
      }
      else {
        valid = true;
      }


    }
    return valid
  }

  saveITmetrics() {
    if (this.validateITMetrics(this.ITMetricsData)) {

      let subscribe$: Subscription = this.modelConfigService.InsertorUpdateITMetricsPopupDetails(this.ITMetricsData).subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

          if (!isNullOrUndefined(this.PopUpITMetricsGridData) && this.PopUpITMetricsGridData.length > 0) {
            this.calcITMetric();
          }

          this.showNotificationMessage('IT Metrics Details Saved Successfully!', 1, '', '', 'OK', '', 10000);
          this.closeModal('ITmetrics');
        }

        this.IsLoading = false;

      },
        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
    }

  }

  cancelITmetrics() {
    this.closeModal('ITmetrics');
  }

  btnClick(event: any) {
    console.log(event)

    switch (event.Name) {
      case 'ITMetricsDetail':
        this.GetITMetricsGridDetails();
        this.GetITMetricsPopupDetails();
        this.ITMetricsData.IsEditable = 1;
        this.modalInf =
        {
          header: 'IT Metrics Driven Approach',
          size: 'modal-lg modal-dialog-centered modal-dialog-scrollable',
          width: 'auto',
          height: 'auto'
        };
        this.openModal('ITmetrics');

        break;
    }
  }

  GetITMetricsOptionsGridDefault(option: any) {
    let subscribe$: Subscription = this.modelConfigService.GetITMetricsOptionsGridDefault(option).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")
        this.DefaultGridData = result.Data;
        this.PopUpITMetricsGridData = result.Data;
        this.dataTotalCount = this.PopUpITMetricsGridData.length;

        if (!isNullOrUndefined(this.PopUpITMetricsGridData) && this.PopUpITMetricsGridData.length > 0) {
          this.calcITMetric();
        }

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetModelBaseliningDetails() {
    let subscribe$: Subscription = this.modelConfigService.GetModelBaseliningDetails(this.DealHeaderId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")
        this.BaselineGridData = result.Data;
        // if(!isNullOrUndefined(this.DealSubLineItemId)){
          if (this.BaselineGridData.length != 0 && !isNullOrUndefined(this.BaselineGridData)) {
            let highlighteditem = this.BaselineGridData.filter((x: any) => Number(x.DealSubLineItemId) == this.selectedDeal.DealSubLineItemId).map((x: any) => x.IsRowHighlight = 1);
  
            console.log(highlighteditem)
            highlighteditem.IsRowHighlight = 1;
     //   }
       
       
        }
        // this.DealSubLineItemId = result.Data[0].DealSubLineItemId;
        //result.Data[0].DealSubLineItemId;
        // this.LotNameforGrid = result.Data[0].LotName;
        //  this.DealLineItemId = this.DealLineItemId;
        // this.BaselineGridData.IsEditable = 1;
        this.dataTotalCount = this.BaselineGridData.length;
      }
      this.navigateModelBaselining(true);
      //this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  GetAnticipatedDetails() {
    let odata = {
      DealHeaderId: this.DealHeaderId,
      DealLineItemId: this.DealLineItemId,
      DealSubLineItemId: this.DealSubLineItemId
    }
    let subscribe$: Subscription = this.modelConfigService.GetAnticipatedData(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        let antiGirdTemp = this.ModelConfigTemplate.filter((x: any) => x.Group === 'anticipatedftegrowth');

        for (let index = 1; index <= this.selectedDeal.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = antiGirdTemp.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.Anticipatedfte = result.Data;
        // this.Anticipatedfte.forEach((element: any) => {
        //   element.Year2 = 0;
        // });
        //  this.Anticipatedfte.IsEditable = 1;
        this.dataTotalCount = this.Anticipatedfte.length;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }
  ////clientReourceData////
  GetClientResourceData() {
    let odata = {
      DealHeaderId: this.DealHeaderId,
      DealLineItemId: this.DealLineItemId,
      DealSubLineItemId: this.DealSubLineItemId
    }
    let subscribe$: Subscription = this.modelConfigService.GetClientResourceData(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")




        let antiGirdTemp = this.ModelConfigTemplate.filter((x: any) => x.Group === 'ClientResourceUBAGrid');
        let fieldData1 = antiGirdTemp.filter(x => x.FieldName.includes("Year"));
       // if (fieldData1.length > 0) {
        fieldData1.map(x => {
            x.IsHidden = true;
            return x;
          });
       // }

       if (fieldData1.length > 0) {
        for (let index = 1; index <= this.selectedDeal.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = fieldData1.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }

        }
      }
      else{

      }
        //  this.ModelBaselineData.IsEditable = 1;
        // this.ModelBaselineData = result.Data;
        // this.Anticipatedfte.forEach((element: any) => {
        //   element.Year2 = 0;
        // });
        //  this.Anticipatedfte.IsEditable = 1;
        this.ClientResourceData = result.Data;
        this.dataTotalCount = this.ClientResourceData.length;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  GetMBDeliveryLocationModelling() {
    let odata = {
      DealHeaderId: this.DealHeaderId,
      DealLineItemId: this.DealLineItemId,
      DealSubLineItemId: this.DealSubLineItemId
    }
    let subscribe$: Subscription = this.modelConfigService.GetMBDeliveryLocationModelling(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")

        this.deliveryModelgridData = result.Data;
        //  this.ModelBaselineData = result.Data;
        // this.Anticipatedfte.forEach((element: any) => {
        //   element.Year2 = 0;
        // });
        //  this.Anticipatedfte.IsEditable = 1;
        this.dataTotalCount = this.deliveryModelgridData.length;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }
  ///////InsertUpdateClientResourceProfile ////


  ITMetricsPopupChangeGridCal(odata: any) {
    let subscribe$: Subscription = this.modelConfigService.ITMetricsPopupChangeGridCal(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")
        this.PopUpITMetricsGridData = result.Data;

        if (!isNullOrUndefined(this.PopUpITMetricsGridData) && this.PopUpITMetricsGridData.length > 0) {
          this.calcITMetric();
        }

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting ITMetricsPopupChangeGridCal');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetModelConfigurationGridCalculationDetails(getModelConfigurationGridCalculation: any) {
    let subscribe$: Subscription = this.modelConfigService.GetModelConfigurationGridCalculationDetails(getModelConfigurationGridCalculation).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")
        this.selectedInfoData.LotResourceCount = result.Data[0].LotResourceCount;
        this.selectedInfoData.LotTicketsCount = result.Data[0].LotTicketsCount;
        this.selectedInfoData.LotUsersCount = result.Data[0].LotUsersCount;
        this.LotTicketsCount.value = this.selectedInfoData.LotTicketsCount;
        this.LotResourceCount.value = this.selectedInfoData.LotResourceCount;
        this.LotUsersCount.value = this.selectedInfoData.LotUsersCount;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetModelConfigurationGridCalculationDetails');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetAnnualComensationDeatailsForITMetricsPopup(countryCode: any, currency: any) {
    let subscribe$: Subscription = this.modelConfigService.GetAnnualComensationDeatailsForITMetricsPopup(countryCode, currency).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")
        console.log(result);
        this.ITMetricsData.AnnualCompensation = result.Data[0].AnnualCompensation;
        this.AnnualCompensation.value = result.Data[0].AnnualCompensation;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  ////it metrics grid data////
  GetITMetricsGridData(odata: any) {
    let subscribe$: Subscription = this.modelConfigService.GetDeatailsForITMetricsPopup(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result, "@@@@@")
        console.log(result);
        this.AnnualCompensation.value = result.Data[0].Code;

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }



  GetITMetricsGridDetails() {
    let subscribe$: Subscription = this.modelConfigService.GetDeatailsForITMetricsOptionsGrid(this.DealHeaderId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.PopUpITMetricsGridData.IsEditable = 1;
        this.PopUpITMetricsGridData = result.Data;
        this.gridTotalCount = this.PopUpITMetricsGridData.length;

        if (!isNullOrUndefined(this.PopUpITMetricsGridData) && this.PopUpITMetricsGridData.length > 0) {
          this.calcITMetric();
        }

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);

  }
  GetITMetricsPopupDetails() {
    let subscribe$: Subscription = this.modelConfigService.GetITMetricsPopupDetails(this.DealHeaderId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.ITMetricsData.IsEditable = 1;
        this.ITMetricsData = result.Data[0];
        this.ITSpend = this.ITMetricsData.ItSpend;
        this.ResourceCount = this.ITMetricsData.ResourceCount;
        this.ITSpendOPEX = this.ITMetricsData.ITSpendOpex;
        this.ITResourceEmployee = this.ITMetricsData.ITResourceEmployee;
        this.TotalNoOfEmployee.value = this.ITMetricsData.TotalNoOfEmployee;
        this.AnnualCompensation.value = this.ITMetricsData.AnnualCompensation;
        if (this.ITMetricsData.ITMetricsOption == '' && this.ITMetricsData.Country == '' && this.ITMetricsData.Region == '') {
          this.showGrid = false;
          this.optionSelected = false;
        } else {
          this.showGrid = true;
          this.optionSelected = true;
        }

        if (this.ITMetricsData.ItMetricsOptionsDesc == "OPEX") {
          this.ITMetricsData.ITMetricsOption = 'OPEX';
          this.opex = true;
          this.revenue = false;
          this.employee = false;

        }
        else if (this.ITMetricsData.ItMetricsOptionsDesc == "Revenue") {
          this.ITMetricsData.ITMetricsOption = 'Revenue';
          this.opex = false;
          this.revenue = true;
          this.employee = false;

        }
        else if (this.ITMetricsData.ItMetricsOptionsDesc == "Total Employees") {
          this.ITMetricsData.ITMetricsOption = 'Total Employees';
          this.opex = false;
          this.revenue = false;
          this.employee = true;

        }
        //this.gridTotalCount = this.ITMetricsData.length  
        console.log(this.ITMetricsData, "###")

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetModelConfigurationDetails() {
    let subscribe$: Subscription = this.modelConfigService.GetModelConfigurationDetails(this.DealHeaderId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        this.ModelConfigData.IsEditable = 1;
        this.ModelConfigData = result.Data[0];
        this.ModelConfigFullData = result.Data;

        // this.service = result.Data[0].Services;
        this.selectedInfoData = result.Data[0];
        this.GetAmDealProgressBarPer(this.DealHeaderId, this.Approach);

        this._appSharedService.currentDealData.DealProgress = this.selectedInfoData.DealProgress;
        this._appSharedService.selectedDeal.ModelID = this.ModelConfigData.ModelID;
        this._appSharedService.currentDealData.ModelID = this.ModelConfigData.ModelID;
        //this.selectedInfoData.DealProgress = 'AM2';
        if (this.selectedInfoData.AmApproach == 'APR001') {
          if (this.selectedInfoData.ResourceBreakdownByLot == 'CHKYES') {
            this.selectedInfoData.ResourceLotDistributionPer = 0;
            this.selectedInfoData.Hasperbreakdownbylottopdown = null;
            this.selectedInfoData.TotalResourceCount = 0;
            this.ResourceLotDistributionPer.value = this.selectedInfoData.ResourceLotDistributionPer;
            this.TotalResourceCount.value = this.selectedInfoData.TotalResourceCount;
            this.Hasperbreakdownbylottopdown.value = this.selectedInfoData.Hasperbreakdownbylottopdown;
            this.Hiddenform = false;
            this.showResourceLotDistribution = false;
            this.isInputDisabled = false;
          } else {
            this.Hiddenform = true;
            this.ModelConfigData.IsEditable = 1;
            this.isInputDisabled = true;
          }
        }
        if (this.selectedInfoData.AmApproach == 'APR002') {
          if (this.selectedInfoData.ticketsbreakdownbylot == 'CHKYES') {
            this.selectedInfoData.TicketsLotDistributionPer = 0;
            this.selectedInfoData.HasperbreakdownbylotTicketBased = null;
            this.selectedInfoData.EnterTotalTicketCount = 0;
            this.TicketsLotDistributionPer.value = this.selectedInfoData.TicketsLotDistributionPer;
            this.HasperbreakdownbylotTicketBased.value = this.selectedInfoData.HasperbreakdownbylotTicketBased;
            this.EnterTotalTicketCount.value = this.selectedInfoData.EnterTotalTicketCount;
            this.showTicketLotDistribution = false;
            this.isInputDisabled = false;
            this.Hiddenform = false;
          } else {
            this.Hiddenform = true;
            this.isInputDisabled = true;
            this.ModelConfigData.IsEditable = 1;
          }
        }
        if (this.selectedInfoData.AmApproach == 'APR003') {
          if (this.selectedInfoData.usersbreakdownbylot == 'CHKYES') {
            this.selectedInfoData.UsersLotDistributionPer = 0;
            this.selectedInfoData.HasPerBreakdownbyLot = null;
            this.selectedInfoData.EnterTotalUsersCount = 0;
            this.UsersLotDistributionPer.value = this.selectedInfoData.UsersLotDistributionPer;
            this.HasPerBreakdownbyLot.value = this.selectedInfoData.HasPerBreakdownbyLot;
            this.EnterTotalUsersCount.value = this.selectedInfoData.EnterTotalUsersCount;
            this.Hiddenform = false;
            this.showUserLotDistribution = false;
            this.isInputDisabled = false;
          }
          else {
            this.Hiddenform = true;
            this.isInputDisabled = true;
            this.ModelConfigData.IsEditable = 1;
          }
        }

        if (this.selectedInfoData.VolumeUnit == 'VOLUN1') {
          this.showLotsCountMonthly = true;
          this.showLotTicketsDefaultTitle = false;
        } else if (this.selectedInfoData.VolumeUnit == 'VOLUN2') {
          this.showLotsCountMonthly = false;
          this.showLotTicketsDefaultTitle = false;
        } else {
          this.showLotTicketsDefaultTitle = true;
        }

        if (this.selectedInfoData.AmApproach == 'APR001') {
          if (this.selectedInfoData.Hasperbreakdownbylottopdown == 'CHKYES' && this.selectedInfoData.ResourceBreakdownByLot == 'CHKNO') {
            this.showResourceLotDistribution = true;
          }
          else {
            this.showResourceLotDistribution = false;
          }
        }
        if (this.selectedInfoData.AmApproach == 'APR002') {
          if (this.selectedInfoData.HasperbreakdownbylotTicketBased == 'CHKYES' && this.selectedInfoData.ticketsbreakdownbylot == 'CHKNO') {
            this.showTicketLotDistribution = true;
          }
          else {
            this.showTicketLotDistribution = false;
          }
        }
        if (this.selectedInfoData.AmApproach == 'APR003') {
          if (this.selectedInfoData.HasPerBreakdownbyLot == 'CHKYES' && this.selectedInfoData.usersbreakdownbylot == 'CHKNO') {
            this.showUserLotDistribution = true;
          }
          else {
            this.showUserLotDistribution = false;
          }
        }

        //Tower Split
        if (this.selectedInfoData.AmApproach !== 'APR004') {
          this.towerSplit(this.ModelConfigFullData);
        }

        if (this.selectedInfoData.AmApproach == 'APR001') {
          if (this.selectedInfoData.ResourceBreakdownByLot == 'CHKNO') {
            this.getModelConfigurationGridCalculation = {
              AmApproach: this.selectedInfoData.AmApproach,
              ResourceBreakdownByLot: this.selectedInfoData.ResourceBreakdownByLot,
              Hasperbreakdownbylottopdown: this.selectedInfoData.Hasperbreakdownbylottopdown,
              EnterTotalResourceCount: this.selectedInfoData.EnterTotalResourceCount,
              ResourceLotDistributionPer: this.selectedInfoData.ResourceLotDistributionPer,
            }
            // this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
            if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
              !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
              && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != ""
              && !isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

              this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
                this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotResourceFieldName);
              });

            }
          }
        }
        if (this.selectedInfoData.AmApproach == 'APR002') {
          if (this.selectedInfoData.ticketsbreakdownbylot == 'CHKNO') {
            this.getModelConfigurationGridCalculation = {
              AmApproach: this.selectedInfoData.AmApproach,
              ticketsbreakdownbylot: this.selectedInfoData.ticketsbreakdownbylot,
              HasperbreakdownbylotTicketBased: this.selectedInfoData.HasperbreakdownbylotTicketBased,
              EnterTotalTicketCount: this.selectedInfoData.EnterTotalTicketCount,
              TicketsLotDistributionPer: this.selectedInfoData.TicketsLotDistributionPer,
            }
            // this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
            if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
              !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
              && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != ""
              && !isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

              this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
                this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotTicketsFieldName);
              });

            }
          }
        }
        if (this.selectedInfoData.AmApproach == 'APR003') {
          if (this.selectedInfoData.usersbreakdownbylot == 'CHKNO') {
            this.getModelConfigurationGridCalculation = {
              AmApproach: this.selectedInfoData.AmApproach,
              usersbreakdownbylot: this.selectedInfoData.usersbreakdownbylot,
              HasPerBreakdownbyLot: this.selectedInfoData.HasPerBreakdownbyLot,
              EnterTotalUsersCount: this.selectedInfoData.EnterTotalUsersCount,
              UsersLotDistributionPer: this.selectedInfoData.UsersLotDistributionPer,
            }
            // this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
            if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
              !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
              && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != ""
              && !isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

              this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
                this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotUsersFieldName);
              });

            }
          }
        }
        this.TowerName.value = this.selectedInfoData.TowerName;
        this.SubTowerName.value = this.selectedInfoData.SubTowerName;
        this.LotName.value = this.selectedInfoData.LotName;
        this.CurrentClientResourceCountriesCount.value = this.selectedInfoData.CurrentClientResourceCountriesCount;
        this.ResourceLotDistributionPer.value = this.selectedInfoData.ResourceLotDistributionPer;
        this.TicketsLotDistributionPer.value = this.selectedInfoData.TicketsLotDistributionPer;
        this.UsersLotDistributionPer.value = this.selectedInfoData.UsersLotDistributionPer;
        this.TotalResourceCount.value = this.selectedInfoData.TotalResourceCount;
        this.Hasperbreakdownbylottopdown.value = this.selectedInfoData.Hasperbreakdownbylottopdown;
        this.EnterTotalTicketCount.value = this.selectedInfoData.EnterTotalTicketCount;
        this.HasperbreakdownbylotTicketBased.value = this.selectedInfoData.HasperbreakdownbylotTicketBased;
        this.EnterTotalUsersCount.value = this.selectedInfoData.EnterTotalUsersCount;
        this.HasPerBreakdownbyLot.value = this.selectedInfoData.HasPerBreakdownbyLot;
        this.LotResourceCount.value = this.selectedInfoData.LotResourceCount;
        this.LotTicketsCount.value = this.selectedInfoData.LotTicketsCount;
        this.LotUsersCount.value = this.selectedInfoData.LotUsersCount;
        if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
          !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
          && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != "") {
          this.showGrid = true;
        }

        else {
          this.showGrid = false;
        }

        if (this.selectedInfoData.DealStatus != "1") {
          this.selectedInfoData.IsEditable = 0;
          this.ModelConfigData.IsEditable = 0;
        }
        else {
          this.selectedInfoData.IsEditable = 1;
          this.ModelConfigData.IsEditable = 1;
        }

        if (this.selectedInfoData.AmApproach !== 'APR004') {
          this.IsLoading = false;
        } else {
          this.GetITMetricsGridDetails();
        }

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetAmDealProgressBarPer(id: number, Approach: any) {
    this.IsLoading = true;
    let subscribe$: Subscription = this.modelConfigService.GetAmDealProgressBarPer(id, Approach).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.GetAmDealProgressBarPerData = result.Data;
        this.dealProgressMeter = this.GetAmDealProgressBarPerData[0].ProgressBarPer;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      // this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
      //   JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      // this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // 2) Blocking Cols based on custom values
      this.Config.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);


      this.ModelConfigProperties.TowerBlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'TowerBlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'TowerBlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      this.ModelConfigProperties.KCLBlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'KCLBlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'KCLBlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      // this.ModelConfigProperties.TowerBlockCols = [{ "Section": 1, "Field": "DealProgressOrder", "Value": 2, "BlockCols": ["NOOFTOWER", "NOOFSUBTOWER", "NOOFLOTS"] }, { "Section": 2, "Field": "AmApproach", "Value": "APR004", "BlockCols": ["NOOFTOWER", "NOOFSUBTOWER", "NOOFLOTS"] }, { "Section": 3, "Field": "DealSubLineItemId", "Value": 0, "BlockCols": ["NOOFTOWER", "NOOFSUBTOWER", "NOOFLOTS", "RESOURCEBREAKDOWNBYLOT", "HASPERBREAKDOWNBYLOTTOPDOWN", "TICKETSBREAKDOWNBYLOT", "HASPERBREAKDOWNBYLOTTICKETBASED", "HASPERBREAKDOWNBYLOT", "USERSBREAKDOWNBYLOT", "DEALLEVELGOVERNANCE", "RESOURCELOTDISTRIBUTIONPER", "VOLUMEUNIT", "TICKETSLOTDISTRIBUTIONPER", "LOTTICKETSCOUNT", "USERSLOTDISTRIBUTIONPER", "LOTUSERSCOUNT", "TOTALRESOURCECOUNT", "HASPERBREAKDOWNLOT", "ENTERTOTALRESOURCECOUNT", "ENTERTOTALTICKETCOUNT", "ENTERTOTALUSERSCOUNT"] }];

      // this.ModelConfigProperties.ITMetricLotsName = ["Apps Dev", "Apps Maint", "Others"];
      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'TowerBlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'KCLBlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ModelConfigurationActionButtons) && this.Config.ModelConfigurationActionButtons.length > 0) {
      this.Config.ModelConfigurationActionButtons.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          let peritem = this.ModelConfigPermission.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
            item.IsApplicable = true;
          }
        }
      });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);

    //this.getUserList();

  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  actionButtonClick(data: any) {
    this.buttonName = '';

    //  this.GetAnticipatedDetails();

    //  APR001    Top Down Approach
    // APR002    Ticket Based Approach
    // APR003    No.of Users Based Approach
    // APR004    IT Metrics Driven Approach
    console.log(data)
    this.buttonName = data.Name.toUpperCase();
    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        case 'SAVE':


          if (this.ModuleName == 'ModelConfig') {
            if (!this.validateTowerSplit()) {
              this.insertorUpdateModelConfig();
            }
          }
          //////// TOP DOWN AND IT METRICS APPROACH////////
          else if (this.ModuleName == 'ModelBaseline' && !isNullOrUndefined(this.LotNameforGrid) && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
            //   this.ModuleName = 'ServiceProfile'
            // console.log(this.BaselineGridData[0].ClientResourceCountriesCount)
            //this._appSharedService.currentDealData = this.selectedInfoData;
            this.GetModelBaseliningDetails();
            this.ModelBaselineData.IsEditable = 1;
            this.BaselineVolData.IsEditable = 1;
            this.BaselineTicketData.IsEditable = 1;


            if (this.ApproachName == 'APR001') {
              if (this.validateASISCOUNT(this.BaselineGridData)) {
                this.OpenContent = true;
              }
              else {
                //this.ModuleName = 'ClientProfile';
                this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                this.GetClientResourceData();
                this.OpenContent = true;
              }

            }



          }

          else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
            // this._appSharedService.currentDealData = this.selectedInfoData;
            // this.GetClientResourceData();
            this.GetAnticipatedDetails();
            //   this.ModuleName = 'ServiceProfile'
            //   this.GetModelBaseliningDetails();
            //   this.ModelBaselineData.IsEditable = 1;
            //   this.BaselineVolData.IsEditable = 1;
            //   this.BaselineTicketData.IsEditable = 1;



            if (!isNullOrUndefined(this.ClientResourceData)) {
              if (this.ClientResourceData[0].ClientResourceProfileId != 0) {
                var resourceCount: number = 0;
                this.ClientResourceData.forEach((item: any) => {
                  resourceCount = resourceCount + item.ResourceFootprintPer;
                });
                console.log(resourceCount);

                if (!isNullOrUndefined(resourceCount) && this.ApproachName == 'APR001') {
                  if ((resourceCount > 100 || resourceCount < 100)) {
                    this.toastr.warning("Distribution % by lot should be 100%");
                  }
                  else {
                    this.OpenContent = false;
                    //this.ModuleName = 'AnticipatedFTE';
                    this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                    this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                    this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                  }
                }
                else {
                  this.OpenContent = false;
                  // this.ModuleName = 'AnticipatedFTE';
                  this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                  this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                  this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                }

                /*   this.OpenContent = false;
                   //this.ModuleName = 'AnticipatedFTE';
                   this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } }); */

              }
              else {
                // this.toastr.warning("Please fill all mandatory fields");
                if (this.validateGridFields(this.ClientResourceData[0])) {
                  this.buttonName == 'SAVE';
                  var resourceCount: number = 0;
                  this.ClientResourceData.forEach((item: any) => {
                    resourceCount = resourceCount + item.ResourceFootprintPer;
                  });
                  console.log(resourceCount);

                  if (!isNullOrUndefined(resourceCount) && this.ApproachName == 'APR001') {
                    if ((resourceCount > 100 || resourceCount < 100)) {
                      this.toastr.warning("Distribution % by lot should be 100%");
                    }
                    else {
                      this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                      // this.AddNewFlag = true;
                      this.OpenContent = false;
                      this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                      //  this.ModuleName = 'AnticipatedFTE';
                    }
                  }
                  else {
                    this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                    // this.AddNewFlag = true;
                    this.OpenContent = false;
                    //this.ModuleName = 'AnticipatedFTE';
                    this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                    this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                  }

                }
                /*   else {
                    // this.toastr.warning("Please fill all mandatory fields");
  
                    this.InsertUpdateClientResourceProfile(0, this.ClientResourceData[0]);
                    // this.AddNewFlag = true;
                    this.OpenContent = false;
                    //this.ModuleName = 'AnticipatedFTE';
                    this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                  } */
                else {
                  this.toastr.warning("Please fill all mandatory fields");

                }
              }

            }

            else {
              // this._appSharedService.currentDealData = this.selectedInfoData;
              if (this.validateGridFields(this.selectedInfoData)) {
                //   this.Mode == '',
                this.buttonName == 'SAVE';
                var resourceCount: number = 0;
                this.ClientResourceData.forEach((item: any) => {
                  resourceCount = resourceCount + item.ResourceFootprintPer;
                });
                console.log(resourceCount);

                if (!isNullOrUndefined(resourceCount) && this.ApproachName == 'APR001') {
                  if ((resourceCount > 100 || resourceCount < 100)) {
                    this.toastr.warning("Distribution % by lot should be 100%");
                  }
                  else {
                    this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                    // this.AddNewFlag = true;
                    this.OpenContent = false;
                    //   this.ModuleName = 'AnticipatedFTE';
                    this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                  }
                }
                else {

                  this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                  //  this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
                  // this.AddNewFlag = true;
                  this.OpenContent = false;
                  //this.ModuleName = 'AnticipatedFTE';
                  this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                  this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                }


                /* this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
                // this.AddNewFlag = true;
                this.OpenContent = false;
               // this.ModuleName = 'AnticipatedFTE';
                this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } }); */

              }
            }


          }


          else if (this.ModuleName == 'AnticipatedFTE' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
            this.GetAnticipatedDetails();
            this.InsertUpdateAnticipatedFTEGrowth();

            if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
              let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
              if (DerivedResourceCount.length > 0) {
                this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
              }
              else {
                this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                this.OpenContent = false;
              }
            }
            else {
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = false;
            }

          }


          ////APPROACH  TICKET BASED//////

          else if (this.ModuleName == 'ModelBaseline' && !isNullOrUndefined(this.LotNameforGrid) && (this.ApproachName == 'APR002')) {
            //this._appSharedService.currentDealData = this.selectedInfoData;
            //   this.ModuleName = 'ServiceProfile'
            this.GetModelBaseliningDetails();
            this.ModelBaselineData.IsEditable = 1;
            this.BaselineVolData.IsEditable = 1;
            this.BaselineTicketData.IsEditable = 1;
            //this.ModuleName = 'ServiceProfile';
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`]), { state: { selectedData: this.selecteddata } };
            this.OpenContent = false;
            this.GetServiceProfile();

          }



          else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR002')) {
            // this._appSharedService.currentDealData = this.selectedInfoData;
            //   this.ModuleName = 'ServiceProfile'
            this.ModelBaselineData.IsEditable = 1;
            this.BaselineVolData.IsEditable = 1;
            this.BaselineTicketData.IsEditable = 1;

            if (this.selectedInfoData.IsModified == 1 || this.selectedInfoData.IsModified == 0) {
              if (this.validateFields()) {
                this.InsertUpdateServiceProfile();

                //this.ModuleName = 'ClientProfile';
                this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                this.OpenContent = true;
                this.selectedInfoData.IsModified = null;
              }
            }

            if (this.selectedInfoData.IsModified == undefined || this.selectedInfoData.IsModified == null) {
              // this.ModuleName = 'ClientProfile';
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = true;
            }
            this.GetClientResourceData();
          }
          else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {
            // this._appSharedService.currentDealData = this.selectedInfoData;
            //   this.ModuleName = 'ServiceProfile'
            this.GetClientResourceData();
            if (!isNullOrUndefined(this.ClientResourceData)) {
              if (this.ClientResourceData[0].ClientResourceProfileId != 0) {
                // this.ModuleName = 'DeliveryModel';
                this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                this._router.navigate([`app/model-config/delivery-location-modeling/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                this.GetMBDeliveryLocationModelling();
                this.OpenContent = false;
              }
              else {
                // this.toastr.warning("Please fill all mandatory fields");
                if (this.validateGridFields(this.ClientResourceData[0])) {
                  this.buttonName == 'SAVE';
                  this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                  // this.AddNewFlag = true;
                  this.OpenContent = false;
                  //this.ModuleName = 'AnticipatedFTE';
                  this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                  this._router.navigate([`app/model-config/delivery-location-modeling/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                  //  this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                }
                else {
                  this.toastr.warning("Please fill all mandatory fields");
                }
              }

            }
            else {
              if (this.validateGridFields(this.selectedInfoData)) {
                this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
                //this.ModuleName = 'DeliveryModel';
                this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                this._router.navigate([`app/model-config/delivery-location-modeling/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
                this.GetMBDeliveryLocationModelling();
                this.OpenContent = false;
              }
            }

            // else {
            //   this.toastr.warning("Please fill all mandatory fields");
            // }



          }
          else if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {
            this.GetMBDeliveryLocationModelling();
            if (!isNullOrUndefined(this.deliveryModelgridData)) {
              if (this.deliveryModelgridData[0].MBDeliveryLocationModellingId != 0) {

                var resourceCount: number = 0;
                this.deliveryModelgridData.forEach((item: any) => {
                  resourceCount = resourceCount + item.EffortDistributionPer;
                });
                console.log(resourceCount);

                if (!isNullOrUndefined(resourceCount)) {
                  if ((resourceCount > 100 || resourceCount < 100)) {
                    this.toastr.warning("Distribution % by lot should be 100%");
                  }
                  else if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                    let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                    if (DerivedResourceCount.length > 0) {
                      this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }
                  }
                  else {
                    this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                    this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                    this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                    this.OpenContent = false;
                  }

                }


                // this._router.navigate([`app/lot-solution-modelling/${this.DealHeaderId}/${this.DealLineItemId}`]);
                // this.OpenContent = false;
              }
              else {
                // this.toastr.warning("Please fill all mandatory fields");
                if (this.validateGridFields(this.deliveryModelgridData[0])) {
                  this.buttonName == 'SAVE';

                  var resourceCount: number = 0;
                  this.deliveryModelgridData.forEach((item: any) => {
                    resourceCount = resourceCount + item.EffortDistributionPer;
                  });
                  console.log(resourceCount);

                  if (!isNullOrUndefined(resourceCount)) {
                    if ((resourceCount > 100 || resourceCount < 100)) {
                      this.toastr.warning("Distribution % by lot should be 100%");
                    }
                    else if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                      let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                      if (DerivedResourceCount.length > 0) {
                        this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                      }
                      else {
                        this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                        this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                        this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                        this.OpenContent = false;
                      }
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }

                  }
                  else {
                    this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                    // this.AddNewFlag = true;
                    if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                      let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                      if (DerivedResourceCount.length > 0) {
                        this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                      }
                      else {
                        this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                        this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                        this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                        this.OpenContent = false;
                      }
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }
                  }



                  // this.ModuleName = 'AnticipatedFTE';
                }
                else {
                  this.toastr.warning("Please fill all mandatory fields");
                }
              }
            }
            else {
              if (this.validateGridFields(this.selectedInfoData)) {
                var resourceCount: number = 0;
                this.deliveryModelgridData.forEach((item: any) => {
                  resourceCount = resourceCount + item.EffortDistributionPer;
                });
                console.log(resourceCount);

                if (!isNullOrUndefined(resourceCount)) {
                  if ((resourceCount > 100 || resourceCount < 100)) {
                    this.toastr.warning("Distribution % by lot should be 100%");
                  }
                  else {
                    this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                    //   this.ModuleName = 'ServiceProfile'
                    // this._router.navigate([`app/lot-solution-modelling/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealHeaderId}`])

                    if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                      let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                      if (DerivedResourceCount.length > 0) {
                        this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                      }
                      else {
                        this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                        this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                        this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                        this.OpenContent = false;
                      }
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }
                  }

                }

                else {
                  this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                  //   this.ModuleName = 'ServiceProfile'
                  // this._router.navigate([`app/lot-solution-modelling/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealHeaderId}`])

                  if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                    let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                    if (DerivedResourceCount.length > 0) {
                      this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }
                  }
                  else {
                    this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                    this.InsertUpdateMBDeliveryLocationModelling(0, this.deliveryModelgridData);
                    this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                    this.OpenContent = false;
                  }
                }



              }
            }




          }


          ///////// USER BASED APPROACH//////

          else if (this.ModuleName == 'ModelBaseline' && !isNullOrUndefined(this.LotNameforGrid) && (this.ApproachName == 'APR003')) {
            //   this.ModuleName = 'ServiceProfile'
            this.GetModelBaseliningDetails();
            this.OpenContent = false;
            this.ModelBaselineData.IsEditable = 1;
            this.BaselineVolData.IsEditable = 1;
            this.BaselineTicketData.IsEditable = 1;
            //this.ModuleName = 'ServiceProfile';
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
            this.GetServiceProfile();

          }



          else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR003')) {
            //   this.ModuleName = 'ServiceProfile'
            //  this.GetClientResourceData();
            this.ModelBaselineData.IsEditable = 1;
            if (this.validateFields()) {
              this.InsertUpdateServiceProfile();
              //this.ModuleName = 'ClientProfile';
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = true;
            }
            this.GetClientResourceData();
          }


          else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
            //   this.ModuleName = 'ServiceProfile'

            if (!isNullOrUndefined(this.ClientResourceData)) {
              if (this.ClientResourceData[0].ClientResourceProfileId != 0) {


                var resourceCount: number = 0;
                this.ClientResourceData.forEach((item: any) => {
                  resourceCount = resourceCount + item.UserDistributionPerbyLot;
                });
                console.log(resourceCount);

                if (!isNullOrUndefined(resourceCount)) {
                  if ((resourceCount > 100 || resourceCount < 100)) {
                    this.toastr.warning("Distribution % by lot should be 100%");
                  }
                  else if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                    let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                    if (DerivedResourceCount.length > 0) {
                      this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }
                  }
                  else {
                    this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                    this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                    this.OpenContent = false;
                  }

                }
                // else
                // {
                //   this._router.navigate([`app/lot-solution-modelling/${this.DealHeaderId}/${this.DealLineItemId}`]);
                //     this.OpenContent = false;
                // }

              }


              else {
                this.toastr.warning("Please fill all mandatory fields");
              }
            }
            else {
              if (this.validateGridFields(this.selectedInfoData)) {

                var resourceCount: number = 0;
                this.ClientResourceData.forEach((item: any) => {
                  resourceCount = resourceCount + item.UserDistributionPerbyLot;
                });
                console.log(resourceCount);

                if (!isNullOrUndefined(resourceCount)) {
                  if ((resourceCount > 100 || resourceCount < 100)) {
                    this.toastr.warning("Distribution % by lot should be 100%");
                  }
                  else if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
                    let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
                    if (DerivedResourceCount.length > 0) {
                      this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
                    }
                    else {
                      this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                      this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                      this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                      this.OpenContent = false;
                    }
                  }
                  else {
                    this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
                    this.InsertUpdateClientResourceProfile(0, this.ClientResourceData);
                    this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
                    this.OpenContent = false;
                  }

                }


                // this._router.navigate([`app/lot-solution-modelling/${this.DealHeaderId}/${this.DealLineItemId}`]);
              }
            }


          }

          break;
        case 'BACK':
          if (this.ModuleName == 'ModelConfig') {
            if (this.OnConfigChange == true) {
              this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
                2, 'CREATEDEAL', null, 'Yes', 'No', 10000);
            } else {
              this._appSharedService.currentDealData.selectedLotData = this.selectedDeal;
              this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
            }
            this.OpenContent = false;
          }
          else if (this.ModuleName == 'ModelBaseline') {
            //this.ModuleName = 'ModelConfig';
            this._router.navigate([`app/model-config/${this.DealHeaderId}`]);
            this.ModelConfigData.IsEditable = 1;
            this.OpenContent = false;
            this.LotNameforGrid = "";
          }

          //////// TOP DOWN AND IT METRICS APPROACH////////



          else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {

            //this.ModuleName = 'ModelBaseline'
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/model-config/ModelBaseline/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
            this.OpenContent = false;
          }
          else if (this.ModuleName == 'AnticipatedFTE' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
            //   this.ModuleName = 'ServiceProfile'
            this.OpenContent = true;
            this.GetClientResourceData();
            //this.ModuleName = 'ClientProfile'
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
          }

          ////APPROACH  TICKET BASED//////
          else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR002')) {

            if (this.selectedInfoData.IsModified == 1) {
              this.showNotificationMessage('Are you sure you want to cancel unsaved data?',
                2, 'MODELBASELINE_TICKETBASED', null, 'Yes', 'No', 10000);
              this.selectedInfoData.IsModified = null;
            }
            else {
              //this.ModuleName = 'ModelBaseline';
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/model-config/ModelBaseline/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
            }
            this.OpenContent = false;
          }
          else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {


            if (this.selectedInfoData.IsModified == 1) {
              this.showNotificationMessage('Are you sure you want to cancel unsaved data?',
                2, 'SERVICEPROFILE_TICKETBASED', null, 'Yes', 'No', 10000);
              this.selectedInfoData.IsModified = null;
            }
            else {
              // this.ModuleName = 'ServiceProfile';
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
              this.GetServiceProfile();
            }
            this.OpenContent = false;
          }
          else if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {



            if (this.selectedInfoData.IsModified == 1) {
              this.showNotificationMessage('Are you sure you want to cancel unsaved data?',
                2, 'CLIENTPROFILE_TICKETBASED', null, 'Yes', 'No', 10000);
              this.selectedInfoData.IsModified = null;
            }
            else {
              // this.ModuleName = 'ClientProfile';
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
              this.GetClientResourceData();
              this.OpenContent = true;
            }

          }

          ///////// USER BASED APPROACH//////

          else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR003')) {
            //this.ModuleName = 'ModelBaseline';
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/model-config/ModelBaseline/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
          }
          else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
            this.OpenContent = false;
            this.GetServiceProfile();
            //this.ModuleName = 'ServiceProfile'
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
          }


          break;
        case 'ADDNEW':
          //  this.buttonName = 'ADDNEW';
          // this.Mode == 'ADD';
          console.log("dddddd");
          console.log(this.ModelBaselineData);
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001')) {
            if (this.dataTotalCount == this.ClientResourceCount) {
              this.datacountFlag = true;
              this.showNotificationMessage('Cannot Add rows more than client resource country count', 2, '', '', '', '', 5000);
            }
            else {
              if (this.selectedInfoData.IsModified == 1) {
                if (this.validateFields()) {
                  // this.selectedInfoData.IsModified = 1;

                  this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
                }
              }

            }

          }
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR004')) {

            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {
            if (this.validateFields()) {
              this.InsertUpdateMBDeliveryLocationModelling(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {
            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }
          this.AddNewFlag = true;

          break;
        case 'UPDATE':
          //this.buttonName = 'UPDATE';
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001')) {

            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR004')) {

            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {
            if (this.validateFields()) {
              this.InsertUpdateMBDeliveryLocationModelling(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {
            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }
          if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
            if (this.validateFields()) {
              this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
            }
          }

          this.AddNewFlag = true;

          break;
        case 'SAVEIT':


          if (this.ModuleName == 'AnticipatedFTE' && !isNullOrUndefined(this.LotNameforGrid) && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
            if (this.selectedInfoData.IsModified == 1) {
              this.InsertUpdateAnticipatedFTEGrowth_SAVE();
            }
          }
          else {
            this.OpenContent = false;
            if (this.selectedInfoData.IsModified == 1) {
              if (this.validateFields()) {
                // this.selectedInfoData.IsModified = 1;
                this.InsertUpdateServiceProfile();
                //this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
              }
            }
            else {
              this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
            }
          }

          break;
        case 'ITMETRICSDETAILS':

          break;

      }
    }
  }

  onGridChange(event: any) {
    this.OnDealChange = true;
    console.log(event, "###", this.selectedInfoData);

    if (!isNullOrUndefined(this.selectedInfoData)) {
      switch (event.fieldname) {
        case "TowerName":
          this.selectedInfoData.TowerName = event.value;
          break;
        case "SubTowerName":
          this.selectedInfoData.SubTowerName = event.value;
          break;
        case "LotName":
          this.selectedInfoData.LotName = event.value;
          break;
        case "LotResourceCount":
          this.selectedInfoData.LotResourceCount = event.value;
          break;
        case "CurrentClientResourceCountriesCount":
          this.selectedInfoData.CurrentClientResourceCountriesCount = event.value;
          break;
        case "ResourceLotDistribution%":
          this.selectedInfoData.ResourceLotDistributionPer = event.value;
          if (event.value < 100 || event.value > 100) {
            this.showNotificationMessage('Resource Lot Distribution % should be 100% across all lots.', 2, '', '', '', '', 2000);
            this.distributionPer = false;
          }
          else {
            this.distributionPer = true;
            this.getModelConfigurationGridCalculation = {
              AmApproach: this.selectedInfoData.AmApproach,
              ResourceBreakdownByLot: this.selectedInfoData.ResourceBreakdownByLot,
              Hasperbreakdownbylottopdown: this.selectedInfoData.Hasperbreakdownbylottopdown,
              EnterTotalResourceCount: this.selectedInfoData.EnterTotalResourceCount,
              ResourceLotDistributionPer: this.selectedInfoData.ResourceLotDistributionPer,
            }
            this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
          }
          break;
        case "TicketLotDistribution%":
          this.selectedInfoData.TicketsLotDistributionPer = event.value;
          if (event.value < 100 || event.value > 100) {
            this.showNotificationMessage('Ticket Lot Distribution % should be 100% across all lots.', 2, '', '', '', '', 2000);
            this.distributionPer = false;
          }
          else {
            this.distributionPer = true;
            this.getModelConfigurationGridCalculation = {
              AmApproach: this.selectedInfoData.AmApproach,
              ticketsbreakdownbylot: this.selectedInfoData.ticketsbreakdownbylot,
              HasperbreakdownbylotTicketBased: this.selectedInfoData.HasperbreakdownbylotTicketBased,
              EnterTotalTicketCount: this.selectedInfoData.EnterTotalTicketCount,
              TicketsLotDistributionPer: this.selectedInfoData.TicketsLotDistributionPer,
            }
            this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
          }
          break;
        case "LotTicketsCount":
          this.selectedInfoData.LotTicketsCount = event.value;
          break;
        case "UsersLotDistribution%":
          this.selectedInfoData.UsersLotDistributionPer = event.value;
          if (event.value < 100 || event.value > 100) {
            this.showNotificationMessage('User Lot Distribution % should be 100% across all lots.', 2, '', '', '', '', 2000);
            this.distributionPer = false;
          }
          else {
            this.distributionPer = true;
            this.getModelConfigurationGridCalculation = {
              AmApproach: this.selectedInfoData.AmApproach,
              usersbreakdownbylot: this.selectedInfoData.usersbreakdownbylot,
              HasPerBreakdownbyLot: this.selectedInfoData.HasPerBreakdownbyLot,
              EnterTotalUsersCount: this.selectedInfoData.EnterTotalUsersCount,
              UsersLotDistributionPer: this.selectedInfoData.UsersLotDistributionPer,
            }
            this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
          }
          break;
        case "LotUsersCount":
          this.selectedInfoData.LotUsersCount = event.value;
          break;
      }
    }

  }


  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
    this.displayNotifyDeliveryModel = true;
  }

  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {

      case 'MODELCONFIG':
        if (event.result.toUpperCase() === 'OK') {
          this._router.navigate([`app/model-config/ModelBaseline/${this.DealHeaderId}`]);
          this.ModuleName = 'ModelBaseline';
          this.GetModelBaseliningDetails();
          this.ModelBaselineData.IsEditable = 1;
          this.BaselineVolData.IsEditable = 1;
          this.BaselineTicketData.IsEditable = 1;
        }
        break;

      case 'SERVICEPROF':
        this.ModuleName = 'ServiceProfile';
        this.GetModelBaseliningDetails();
        this.ModelBaselineData.IsEditable = 1;
        this.BaselineVolData.IsEditable = 1;
        this.BaselineTicketData.IsEditable = 1;
        break;

      case 'CREATEDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this._appSharedService.currentDealData.selectedLotData = this.selectedDeal;
          this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
        }
        break;
      case 'MODELBASELINE_TICKETBASED':
        if (event.result.toUpperCase() === 'YES') {
          this.ModuleName = 'ModelBaseline';
          //this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
        }
        break;
      case 'SERVICEPROFILE_TICKETBASED':
        if (event.result.toUpperCase() === 'YES') {
          this.OpenContent = false;
          this.ModuleName = 'ServiceProfile';
          //this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
        }
        break;
      case 'CLIENTPROFILE_TICKETBASED':
        if (event.result.toUpperCase() === 'YES') {
          this.ModuleName = 'ClientProfile';
          this.OpenContent = true;
          //  this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
        }
        break;

      case 'ITMETRICSPOPUP':
        if (event.result.toUpperCase() === 'OK') {
          if (this.ITMetricsData.ItMetricsOptionsDesc == "Total Employees") {
            this.opex = false;
            this.revenue = false;
            this.employee = true;
            this.ITMetricsData.TotalNoOfEmployee = 0;
            this.ITResourceEmployee = 0;
            this.TotalNoOfEmployee.value = this.ITMetricsData.TotalNoOfEmployee;
            this.GetITMetricsOptionsGridDefault(event.item.ItMetricsOptions);
          } else if (this.ITMetricsData.ItMetricsOptionsDesc == "Revenue") {
            this.opex = false;
            this.revenue = true;
            this.employee = false;
            this.ITSpend = 0;
            this.ResourceCount = 0;
            this.ITMetricsData.YearlyRevenu = 0;
            this.YearlyRevenu.value = this.ITMetricsData.YearlyRevenu;
            this.GetITMetricsOptionsGridDefault(event.item.ItMetricsOptions);
          } else {
            this.opex = true;
            this.revenue = false;
            this.employee = false;
            this.ResourceCount = 0;
            this.ITSpendOPEX = 0;
            this.ITMetricsData.YearlyOpex = 0;
            this.YearlyOpex.value = this.ITMetricsData.YearlyOpex;
            this.GetITMetricsOptionsGridDefault(event.item.ItMetricsOptions);
          }
        }
        break;
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
    this.displayNotifyDeliveryModel = false;
    /*  if(this.ModuleName == 'ModelConfig'){
       this.ModuleName ='ServiceProfile';
     } */


  }

  InsertorUpdateAmDealsModelBaselining() {

  }


  InsertUpdateClientResourceProfile(DeleteFlag: any, Data: any) {
    this.IsLoading = true;
    this.selectedInfoData.DealHeaderId = this.DealHeaderId;
    this.selectedInfoData.DealLineItemId = this.DealLineItemId;
    this.selectedInfoData.DealSubLineItemId = this.DealSubLineItemId;
    if (DeleteFlag == 1) {
      Data[0].DeleteFlag = DeleteFlag;
    }
    Data = {
      'clientresourceprofilelist': Data
    }
    let subscribe$: Subscription = this.modelConfigService.InsertUpdateClientResourceProfile(Data).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {
        if (this.buttonName == 'ADDNEW') {
          this.showNotificationMessage('Added Successfully!', 1, '', '', '', '', 1000);
          this.GetClientResourceData();

          this.ModelBaselineData = {};
          this.ModelBaselineData.IsEditable = 1;
          console.log(this.ModelBaselineData)
        }
        if (this.buttonName == 'UPDATE') {
          this.showNotificationMessage('Updated Successfully!', 1, '', '', '', '', 1000);
          this.GetClientResourceData();
        }
        if (this.buttonName == 'SAVE') {
          //this.showNotificationMessage('Added Successfully!', 1, 'MODELCONFIG', '', 'OK', '', 10000);
          this.GetClientResourceData();
          this.GetModelBaseliningDetails();
        }
        if (DeleteFlag == 1) {
          this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);
          this.GetClientResourceData();
        }

        if (DeleteFlag == 0) {
          // this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);
          this.GetClientResourceData();
        }
        if (DeleteFlag == 2) {
          this.GetClientResourceData();
          if (this.ClientResourceData.length > 0) {
            if (result.Data.length != 0) {
              if (result.Data[0].ResponseMessage == 'Data Exists') {
                this.showNotificationMessage('Cannot add  duplicate Region,Country and City', 2, '', '', '', '', 10000);
              }
              else {
                this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
              }
            }
            else {
              this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
            }

          }
          else {
            this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
          }

        }



      }

      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }


  InsertUpdateMBDeliveryLocationModelling(DeleteFlag: any, Data: any) {
    this.selectedInfoData.DealHeaderId = this.DealHeaderId;
    this.selectedInfoData.DealLineItemId = this.DealLineItemId;
    this.selectedInfoData.DealSubLineItemId = this.DealSubLineItemId;
    if (DeleteFlag == 1) {
      Data[0].DeleteFlag = DeleteFlag;
    }
    Data = {
      'deliverylocationmodellinglist': Data
    }
    let subscribe$: Subscription = this.modelConfigService.InsertUpdateMBDeliveryLocationModelling(Data).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        console.log(result);
        this.GetMBDeliveryLocationModelling();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  InsertUpdateMBDeliveryLocationSAVE(DeleteFlag: any, Data: any) {
    this.selectedInfoData.DealHeaderId = this.DealHeaderId;
    this.selectedInfoData.DealLineItemId = this.DealLineItemId;
    this.selectedInfoData.DealSubLineItemId = this.DealSubLineItemId;
    if (DeleteFlag == 1) {
      Data[0].DeleteFlag = DeleteFlag;
    }
    Data = {
      'deliverylocationmodellinglist': Data
    }
    let subscribe$: Subscription = this.modelConfigService.InsertUpdateMBDeliveryLocationModelling(Data).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        console.log(result);
        this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
        this.GetMBDeliveryLocationModelling();
        this.GetModelBaseliningDetails();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }



  InsertUpdateServiceProfile() {
    this.IsLoading = true;
    this.selectedInfoData.DealHeaderId = this.DealHeaderId;
    this.selectedInfoData.DealLineItemId = this.DealLineItemId;
    this.selectedInfoData.DealSubLineItemId = this.DealSubLineItemId;
    console.log(this.selectedInfoData);
    let subscribe$: Subscription = this.modelConfigService.InsertUpdateServiceProfile(this.selectedInfoData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {

        // if (this.Mode == 'ADD') {

        //  }
        // if (this.Mode == 'UPDATE') {
        //  this.showNotificationMessage('Updated Successfully!', 1, '', '', '', '', 10000);
        //   this.GetClientResourceData();
        this.GetServiceProfile();
        // }
        // if (this.Mode == '') {
        //   this.GetClientResourceData();
        // }


      }

      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }


  InsertUpdateAnticipatedFTEGrowth() {
    this.IsLoading = true;
    console.log("#####");
    this.selectedInfoData.DealHeaderId = this.DealHeaderId;
    this.selectedInfoData.DealLineItemId = this.DealLineItemId;
    this.selectedInfoData.DealSubLineItemId = this.DealSubLineItemId;
    console.log(this.selectedInfoData);
    console.log(this.Anticipatedfte[0])
    let subscribe$: Subscription = this.modelConfigService.InsertUpdateAnticipatedFTEGrowth(this.Anticipatedfte[0]).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {

        //  this.showNotificationMessage('Added Successfully!', 1, '', '', '', '', 10000);
        this.GetAnticipatedDetails();
      }

      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }
  InsertUpdateAnticipatedFTEGrowth_SAVE() {
    this.IsLoading = true;
    console.log("#####");
    this.selectedInfoData.DealHeaderId = this.DealHeaderId;
    this.selectedInfoData.DealLineItemId = this.DealLineItemId;
    this.selectedInfoData.DealSubLineItemId = this.DealSubLineItemId;
    console.log(this.selectedInfoData);
    console.log(this.Anticipatedfte[0])
    let subscribe$: Subscription = this.modelConfigService.InsertUpdateAnticipatedFTEGrowth(this.Anticipatedfte[0]).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {

        this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
        this.GetAnticipatedDetails();
      }

      this.IsLoading = false;

    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  insertorUpdateModelConfig() {

    //this._appSharedService.currentDealData = this.selectedInfoData;

    //if (this.OnDealChange == true|| this.Mode == 'CREATE') {
    if (this.validateModelConfigMandatoryFields(this.selectedInfoData) && this.distributionPer) {
      console.log("success", this.selectedInfoData)

      //this.getModelBaselineTemplate();

      let split_Data = this.frameSplitData();

      // this.selectedInfoData.LotTicketsCount = Number(this.selectedInfoData.LotTicketsCount.toFixed(0));
      // this.selectedInfoData.LotResourceCount = Number(this.selectedInfoData.LotResourceCount.toFixed(0));
      // this.selectedInfoData.LotUsersCount = Number(this.selectedInfoData.LotUsersCount.toFixed(0));
      // this.selectedInfoData.ResourceLotDistributionPer = Number(this.selectedInfoData.ResourceLotDistributionPer.toFixed(0));
      // this.selectedInfoData.TicketsLotDistributionPer = Number(this.selectedInfoData.TicketsLotDistributionPer.toFixed(0));
      // this.selectedInfoData.UsersLotDistributionPer = Number(this.selectedInfoData.UsersLotDistributionPer.toFixed(0));
      this.selectedInfoData.CurrentClientResourceCountriesCount = Number(this.selectedInfoData.CurrentClientResourceCountriesCount.toFixed(0));

      this.selectedInfoData.LotTemplate = null;
      if (!isNullOrUndefined(split_Data) && split_Data.length > 0) {
        this.selectedInfoData.LotTemplate = JSON.stringify(split_Data);
      }

      this.IsLoading = true;

      let subscribe$: Subscription = this.modelConfigService.insertorUpdateModelConfigDeals(this.selectedInfoData).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {
          this.OnConfigChange = false;
          //this._appSharedService.currentDealData.DealHeaderId = result.Data[0].DealHeaderId;
          //this.showNotificationMessage('Model Config Created Successfully!', 1, 'MODELCONFIG', '', 'OK', '', 10000);

          /*  if (result.Data[0] != null) {
             this.DealHeaderId = result.Data[0].DealHeaderId;
             this.selectedInfoData.DealId = result.Data[0].DealId;
             this.selectedInfoData.DealStatus = result.Data[0].DealStatus;
             this.getSelectedDeal(this.selectedInfoData);
           }*/

          if (this.OnDealChange == true) {
            if (this.Mode == 'UPDATE') {
              this.showNotificationMessage('Model Configuration Profile Updated Successfully!', 1, 'MODELCONFIG', '', 'OK', '', 10000);
              this.OnDealChange = false;
              //this._appSharedService.IsCreateMode = false;
              //this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);
            }
            if (this.Mode == 'CREATE') {
              this.showNotificationMessage('Model Configuration Profile Created Successfully!', 1, 'MODELCONFIG', '', 'OK', '', 10000);
              this.OnDealChange = false;
              //this._appSharedService.IsCreateMode = true;
              //this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);
            }
          }
          else {
            // this._appSharedService.IsCreateMode = false;
            // this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);
            this._router.navigate([`app/model-config/ModelBaseline/${this.DealHeaderId}`]);
            //this.ModuleName = 'ModelBaseline';            
            this.GetModelBaseliningDetails();
            this.ModelBaselineData.IsEditable = 1;
            this.BaselineVolData.IsEditable = 1;
            this.BaselineTicketData.IsEditable = 1;
          }
          //this.showNotificationMessage('Deal Created Successfully!', 1, '', null, '', '');
          //this._router.navigate(['app/home']);
        }

        this.IsLoading = false;

      },
        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);
    }
    else {
      this.showNotificationMessage('Please fill all mandatory fields', 2, '', '', 'OK', '', 10000);
      // this.ModuleName = 'ModelBaseline';
      // this.GetModelBaseliningDetails();
      // this.ModelBaselineData.IsEditable = 1;
      // this.BaselineVolData.IsEditable = 1;
      // this.BaselineTicketData.IsEditable = 1;
    }

  }

  validateModelConfigMandatoryFields(modifieditems: any): boolean {

    console.log("Model config");
    console.log(modifieditems)
    let valid: boolean = false;

    if (isNullOrUndefined(modifieditems)) {
      this.showNotificationMessage('All fields are mandatory', 2, '', null, '', '', 1000);
      valid = false;
    }
    if (!isNullOrUndefined(modifieditems)) {
      if (this.ApproachName == "APR001") {
        if ((isNullOrUndefined(modifieditems.NoOfTower)) || (isNullOrUndefined(modifieditems.NoOfSubTower)) || (isNullOrUndefined(modifieditems.NoOfLots)) ||
          // (isNullOrUndefined(modifieditems.TowerName)) ||
          // (isNullOrUndefined(modifieditems.SubTowerName)) || (isNullOrUndefined(modifieditems.LotName)) 
          // (isNullOrUndefined(modifieditems.LotResourceCount)) || (isNullOrUndefined(modifieditems.CurrentClientResourceCountriesCount)) ||
          // (modifieditems.Hasperbreakdownbylottopdown == 'CHKYES' && modifieditems.ResourceBreakdownByLot == 'CHKNO' && ( isNullOrUndefined(modifieditems.ResourceLotDistributionPer))) ||
          (isNullOrUndefined(modifieditems.ResourceBreakdownByLot)) ||
          (modifieditems.NoOfTower == '') || (modifieditems.NoOfSubTower == '') || (modifieditems.NoOfLots == '') ||
          // (modifieditems.TowerName == '') || (modifieditems.SubTowerName == '') ||
          // (modifieditems.LotName == '') || (modifieditems.LotResourceCount == '') || (modifieditems.CurrentClientResourceCountriesCount == '') || 
          (modifieditems.ResourceBreakdownByLot == '') // ||
          // (modifieditems.Hasperbreakdownbylottopdown == 'CHKYES' && modifieditems.ResourceBreakdownByLot == 'CHKNO' && modifieditems.ResourceLotDistributionPer == '')
        ) {
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          //this.navigate = false;
          valid = false;
        } else {
          valid = true;
        }
      }
      else if (this.ApproachName == "APR002") {
        if ((isNullOrUndefined(modifieditems.NoOfTower)) || (isNullOrUndefined(modifieditems.NoOfSubTower)) || (isNullOrUndefined(modifieditems.NoOfLots)) ||
          // (isNullOrUndefined(modifieditems.TowerName)) || (isNullOrUndefined(modifieditems.SubTowerName)) ||
          // (isNullOrUndefined(modifieditems.LotName)) || (isNullOrUndefined(modifieditems.LotTicketsCount)) || 
          // (modifieditems.HasperbreakdownbylotTicketBased == 'CHKYES' && modifieditems.ticketsbreakdownbylot == 'CHKNO' && (isNullOrUndefined(modifieditems.TicketsLotDistributionPer))) || 
          (isNullOrUndefined(modifieditems.ticketsbreakdownbylot)) || (isNullOrUndefined(modifieditems.VolumeUnit)) ||
          (modifieditems.NoOfTower == '') || (modifieditems.NoOfSubTower == '') || (modifieditems.NoOfLots == '') ||
          // (modifieditems.TowerName == '') || (modifieditems.SubTowerName == '') || (modifieditems.LotName == '') || 
          (modifieditems.ticketsbreakdownbylot == '') || (modifieditems.VolumeUnit == '') // ||
          // (modifieditems.LotTicketsCount == '') || 
          // (modifieditems.HasperbreakdownbylotTicketBased == 'CHKYES' && modifieditems.ticketsbreakdownbylot == 'CHKNO' && modifieditems.TicketsLotDistributionPer == '')
        ) {
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          //this.navigate = false;
          valid = false;
        } else {
          valid = true;
        }
      }
      else if (this.ApproachName == "APR003") {
        if ((isNullOrUndefined(modifieditems.NoOfTower)) || (isNullOrUndefined(modifieditems.NoOfSubTower)) || (isNullOrUndefined(modifieditems.NoOfLots)) ||
          // (isNullOrUndefined(modifieditems.TowerName)) || (isNullOrUndefined(modifieditems.SubTowerName)) || (isNullOrUndefined(modifieditems.LotName)) || (isNullOrUndefined(modifieditems.LotUsersCount)) || 
          // (modifieditems.HasPerBreakdownbyLot == 'CHKYES' && modifieditems.usersbreakdownbylot == 'CHKNO' && (isNullOrUndefined(modifieditems.UsersLotDistributionPer))) ||
          (isNullOrUndefined(modifieditems.usersbreakdownbylot)) || (isNullOrUndefined(modifieditems.VolumeUnit)) || (modifieditems.NoOfTower == '') || (modifieditems.NoOfSubTower == '') || (modifieditems.NoOfLots == '') ||
          // (modifieditems.TowerName == '') || (modifieditems.SubTowerName == '') ||
          // (modifieditems.LotName == '') || 
          (modifieditems.usersbreakdownbylot == '') // ||
          // (modifieditems.LotUsersCount == '') || 
          // (modifieditems.HasPerBreakdownbyLot == 'CHKYES' && modifieditems.usersbreakdownbylot == 'CHKNO' && modifieditems.UsersLotDistributionPer == '')
        ) {
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          //this.navigate = false;
          valid = false;
        } else {
          valid = true;
        }
      }
      else if (this.ApproachName == "APR004") {
        if ((isNullOrUndefined(modifieditems.NoOfTower)) || (isNullOrUndefined(modifieditems.NoOfSubTower)) || (isNullOrUndefined(modifieditems.NoOfLots)) ||
          // (isNullOrUndefined(modifieditems.TowerName)) || (isNullOrUndefined(modifieditems.SubTowerName)) || (isNullOrUndefined(modifieditems.LotName)) || (isNullOrUndefined(modifieditems.LotResourceCount)) ||
          (modifieditems.NoOfTower == '') || (modifieditems.NoOfSubTower == '') || (modifieditems.NoOfLots == '')  // ||
          // (modifieditems.TowerName == '') || (modifieditems.SubTowerName == '') || (modifieditems.LotName == '') ||
          // (modifieditems.LotResourceCount == '')
        ) {
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          //this.navigate = false;
          valid = false;
        } else {
          valid = true;
        }
      }
      else {
        valid = true;
      }
    }
    return valid
  }


  onClick(event: any) {
    //event.item.IsRowHighlight = 0;
    console.log(event, "fieldclick")
    //this.LotNameforGrid = "";
    let selitem1 = event.item;

    let selitem1Array = [];
    selitem1Array.push(selitem1);
    console.log(selitem1Array);
    // selitem1.IsRowHighlight = this.highlightitemprevious;
    let highlighteditem = this.BaselineGridData.filter((x: any) => Number(x.IsRowHighlight) == 1);

    console.log(this.BaselineGridData)
    this.DealHeaderId = event.item.DealHeaderId;
    this.DealLineItemId = event.item.DealLineItemId;
    this.DealSubLineItemId = event.item.DealSubLineItemId;

    this._appSharedService.currentDealData.DealHeaderId = this.DealHeaderId;
    this._appSharedService.currentDealData.DealLineItemId = this.DealLineItemId;
    this._appSharedService.currentDealData.DealSubLineItemId = this.DealSubLineItemId;

    this.ClientResourceCount = event.item.ClientResourceCountriesCount;
    if ((event.fieldname != 'Action')) {
      event.item.IsModified = 1;
    }

    if (event.fieldname == 'SubTowerName' ||
      event.fieldname == 'LotName' ||
      event.fieldname == 'SplitPercentage' ||
      event.fieldname == 'TowerName' ||
      event.fieldname == 'AsIsResource' ||
      event.fieldname == 'DerivedResource' ||
      event.fieldname == 'ClientResourceCountriesCount') {
      this.LotNameforGrid = event.item.LotName;
      this.OpenContent = true;
      if (!isNullOrUndefined(highlighteditem)) {
        if (highlighteditem.length >= 1) {
          // selitem1.IsRowHighlight = 0;
          highlighteditem.map((x: any) => x.IsRowHighlight = 0);
          selitem1.IsRowHighlight = 1;
        }
        else {
          if (selitem1.IsRowHighlight == undefined || selitem1.IsRowHighlight == 0) {
            selitem1.IsRowHighlight = 1;
            this.highlightitemprevious = 1;
          }
          else {
            selitem1.IsRowHighlight = 0;
          }
        }
      }


      // event.item.IsRowHighlight = 1;
      let selitem = {
        "dealheaderid": this.DealHeaderId,
        "deallineitemid": this.DealLineItemId,
        "dealsublineitemid": this.DealSubLineItemId,
        "lotnameforgrid": this.LotNameforGrid
      }

      this._appSharedService.currentDealData.selectedLotData = selitem;

      this.selecteddata = selitem;

      if (this.ModuleName == 'ServiceProfile' && this.ApproachName == 'APR002') {
        switch (event.fieldname) {
          case 'ThirdPartyProductVendorInteractions':
            this.InsertUpdateServiceProfile();
            break;
          case 'DailyOperationalSupport':
            this.InsertUpdateServiceProfile();
            break;
          case 'MiscSupport':
            this.InsertUpdateServiceProfile();
            break;
          case 'Monitoring':
            this.InsertUpdateServiceProfile();
            break;
          case 'SeasonalActivities':
            this.InsertUpdateServiceProfile();
            break;
          case 'ServiceManagement':
            this.InsertUpdateServiceProfile();
            break;
          case 'UserQueries':
            this.InsertUpdateServiceProfile();
            break;

          default:
            break;
        }
      }


      if (this.ModuleName == 'ModelBaseline') {

        if (!isNullOrUndefined(highlighteditem)) {
          if (highlighteditem.length >= 1) {
            // selitem1.IsRowHighlight = 0;
            highlighteditem.map((x: any) => x.IsRowHighlight = 0);
            selitem1.IsRowHighlight = 1;
          }
          else {
            if (selitem1.IsRowHighlight == undefined || selitem1.IsRowHighlight == 0) {
              selitem1.IsRowHighlight = 1;
              this.highlightitemprevious = 1;
            }
            else {
              selitem1.IsRowHighlight = 0;
            }
          }
        }
        if ((this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
          this.OpenContent = true;
          this.GetClientResourceData();
          //this.ModuleName = 'ClientProfile';

          this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
        if ((this.ApproachName == 'APR002')) {
          this.OpenContent = false;
          this.GetServiceProfile();
          //this.ModuleName = 'ServiceProfile';
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
        if ((this.ApproachName == 'APR003')) {
          this.OpenContent = false;
          this.GetServiceProfile();
          //this.ModuleName = 'ServiceProfile';
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
      }
      if (this.ModuleName == 'ServiceProfile') {
        if ((this.ApproachName == 'APR002')) {
          this.OpenContent = false;
          this.GetServiceProfile();
          //this.ModuleName = 'ServiceProfile';
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
        if ((this.ApproachName == 'APR003')) {
          this.OpenContent = false;
          this.GetServiceProfile();
          // this.ModuleName = 'ServiceProfile';
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
      }
      if (this.ModuleName == 'ClientProfile') {
        if ((this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
          this.OpenContent = true;
          this.GetClientResourceData();
          //this.ModuleName = 'ClientProfile';
          this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
        if ((this.ApproachName == 'APR002')) {
          this.OpenContent = true;
          //   this.GetClientResourceData();
          //this.ModuleName = 'ClientProfile';
          this.GetServiceProfile();
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
          //  this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }
        if ((this.ApproachName == 'APR003')) {
          this.OpenContent = true;
          //  this.GetClientResourceData();
          //this.ModuleName = 'ClientProfile';
          // this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });

          this.GetServiceProfile();
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });

        }
      }



      if (this.ModuleName == 'DeliveryModel') {
        if ((this.ApproachName == 'APR002')) {
          this.OpenContent = false;

          this.GetServiceProfile();
          this._router.navigate([`app/model-config/service-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
          // this.GetMBDeliveryLocationModelling();
          //this.ModuleName = 'DeliveryModel';
          // this._router.navigate([`app/model-config/delivery-location-modeling/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        }



        // if ((this.ApproachName == 'APR003')) {
        //   this.OpenContent = false;
        //   this.GetServiceProfile();
        //   this.ModuleName = 'ServiceProfile';
        // }
      }

      if (this.ModuleName == 'AnticipatedFTE' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
        //   this.ModuleName = 'ServiceProfile'
        // this.OpenContent = true;
        // this.GetAnticipatedDetails();

        //  this._router.navigate([`app/model-config/anticipated-FTE-growth/${this.DealHeaderId}`], { state: { selectedData: selitem } });
        this.GetClientResourceData();
        //this.ModuleName = 'ClientProfile';
        this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: selitem } });
      }
    }
    //   if(this.ModuleName=='ServiceProfile'){

    // }
    if (event.fieldname == 'Action') {

      if (event.icon.Tooltip == 'Save') {
        console.log(event.item);

        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001')) {
          if (event.item.IsModified == 1) {
            if (this.validateGridFields(event.item)) {

              var clientresourceprofileodata = [];
              clientresourceprofileodata.push(event.item);
              this.InsertUpdateClientResourceProfile(2, clientresourceprofileodata);

              event.item.IsModified = 0;
            }
          }
          else {
            this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
          }
        }
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR004')) {
          if (event.item.IsModified == 1) {
            if (this.validateGridFields(event.item)) {

              var clientresourceprofileodata = [];
              clientresourceprofileodata.push(event.item);
              this.InsertUpdateClientResourceProfile(2, clientresourceprofileodata);

              event.item.IsModified = 0;
            }
          }
          else {
            this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
          }
        }
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
          if (event.item.IsModified == 1) {
            if (this.validateGridFields(event.item)) {
              var clientresourceprofileodata = [];
              clientresourceprofileodata.push(event.item);
              this.InsertUpdateClientResourceProfile(2, clientresourceprofileodata);
              event.item.IsModified = 0;
            }
          }
          else {
            this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
          }
        }
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {
          if (event.item.IsModified == 1) {
            if (this.validateGridFields(event.item)) {
              var clientresourceprofileodata = [];
              clientresourceprofileodata.push(event.item);
              this.InsertUpdateClientResourceProfile(2, clientresourceprofileodata);
              event.item.IsModified = 0;
            }
          }
          else {
            this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
          }
        }

        ///////delivery model////////////////
        if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {
          if (event.item.IsModified == 1) {
            if (this.validateGridFields(event.item)) {
              var MBDeliveryLocation = [];
              MBDeliveryLocation.push(event.item);
              this.InsertUpdateMBDeliveryLocationModelling(2, MBDeliveryLocation);
              event.item.IsModified = 0;
            }
          }
          else {
            this.showNotificationMessage('Nothing to Save', 1, '', '', '', '', 1000);
          }
        }

        this.AddNewFlag = true;
      }


      if (event.icon.Tooltip == 'Delete') {
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001')) {

          var clientresourceprofileodata = [];
          clientresourceprofileodata.push(event.item);
          this.InsertUpdateClientResourceProfile(1, clientresourceprofileodata);
          this.dataTotalCount = this.dataTotalCount - 1;
        }
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR004')) {
          var clientresourceprofileodata = [];
          clientresourceprofileodata.push(event.item);
          this.InsertUpdateClientResourceProfile(1, clientresourceprofileodata);
        }
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
          var clientresourceprofileodata = [];
          clientresourceprofileodata.push(event.item);
          this.InsertUpdateClientResourceProfile(1, clientresourceprofileodata);
        }
        if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {
          var clientresourceprofileodata = [];
          clientresourceprofileodata.push(event.item);
          this.InsertUpdateClientResourceProfile(1, clientresourceprofileodata);
        }
        if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {
          var MBDeliveryLocationModelling = [];
          MBDeliveryLocationModelling.push(event.item);
          this.InsertUpdateMBDeliveryLocationModelling(1, MBDeliveryLocationModelling);
        }

        this.AddNewFlag = true;
      }

    }
    // event.item.IsModified = 0;
  }

  HelpIconClick() {
    this.openModal('OpenTooltip');
    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };
  }

  onChange(event: any) {

    this.selectedInfoData = {};
    this.selectedInfoData = '';
    console.log(event);
    this.OnDealChange = true;
    this.OnConfigChange = true;
    this.selectedInfoData = event.item;
    this.selectedInfoData.IsModified = 1;
    // this.selectedInfoData = this.selectedInfoData;
    this.DealHeaderId = event.item.DealHeaderId;
    this.DealLineItemId = event.item.DealLineItemId;
    this.DealSubLineItemId = event.item.DealSubLineItemId;
    //this.LotNameforGrid = event.item.LotName;
    this.ClientResourceCount = event.item.ClientResourceCountriesCount;
    console.log("ON CHANGE");
    console.log(this.selectedInfoData);
    if (event.fieldname == "NoOfSubTower" || event.fieldname == "NoOfTower" || event.fieldname == "NoOfLots") {
      if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
        !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
        && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != "") {


        // if (!Number.isInteger(this.selectedInfoData.NoOfTower) || !Number.isInteger(this.selectedInfoData.NoOfSubTower)
        //   || !Number.isInteger(this.selectedInfoData.NoOfLots)) {

        //   this.showNotificationMessage('Decimal Values are not allowed', 2, '', null, '', '', 1000);
        //   this.selectedInfoData.NoOfTower = Math.trunc(this.selectedInfoData.NoOfTower);
        //   this.selectedInfoData.NoOfSubTower = Math.trunc(this.selectedInfoData.NoOfSubTower);
        //   this.selectedInfoData.NoOfLots = Math.trunc(this.selectedInfoData.NoOfLots);

        // }



        let check_TowerCount = this.selectedInfoData.NoOfTower * this.selectedInfoData.NoOfSubTower * this.selectedInfoData.NoOfLots;

        // if (event.item.AmApproach === 'APR002') {
        if (check_TowerCount > 25) {
          // event.item[event.fieldname] = 1;
          this.showNotificationMessage('Multiplication of Tower, Sub Tower, Lots should be less than 25', 2, '', null, '', '', 1000);
          this.showGrid = false;
        } else {

          let splitData: any = [];
          splitData.push(this.selectedInfoData);

          this.towerSplit(splitData);

          this.showGrid = true;
        }

        // }

      }
      else {
        this.showGrid = false;
      }
    }
    if (this.ModuleName == 'DeliveryModel' && this.ApproachName == 'APR002') {
      switch (event.fieldname) {
        case 'EffortDistributionPer':
        case 'DeliveredFromCountry':

          let fieldTempData: any;
          let annualWorkingHours: number = 0;

          fieldTempData = this.ModelConfigTemplate.filter(x => x.Group === 'MBDLMGrid' && x.FieldName == 'DeliveredFromCountry');

          if (!isNullOrUndefined(fieldTempData) && fieldTempData.length > 0) {

            let listData = fieldTempData[0].Control.List;

            if (!isNullOrUndefined(listData) && listData.length > 0) {
              let selectedCountryData = listData.filter((x: any) => x.Code === event.item.DeliveredFromCountry);

              annualWorkingHours = !isNullOrUndefined(selectedCountryData) && selectedCountryData.length > 0 ? Number(selectedCountryData[0].UserDef1) : 0;

              annualWorkingHours = !isNullOrUndefined(annualWorkingHours) && annualWorkingHours > 0 ? annualWorkingHours : event.item.AnnualWorkingHours;

            }
          }

          // If Volume Unit is Yearly = (Total Efforts / Annual Working Hours) * (Efforts Distribution %)                                                                        
          // If Volume Unit is Monthly = (Total Efforts / (Annual Working Hours/12)) * (Efforts Distribution %)   
          let clientPerCount: number = 0;

          if (event.item.VolumeUnit === 'Monthly') {
            clientPerCount = (event.item.TotalEfforts / (annualWorkingHours / 12)) * (event.item.EffortDistributionPer / 100);
          } else {
            clientPerCount = (event.item.TotalEfforts / annualWorkingHours) * (event.item.EffortDistributionPer / 100);
          }

          event.item.ClientOwnPersonnelCount = !isFinite(clientPerCount) ? 0 : clientPerCount.toFixed(2);

          break;

        default:
          break;
      }
    }
    if (this.ModuleName == 'ServiceProfile' && this.ApproachName == 'APR002') {
      switch (event.fieldname) {



        case 'SplitPer':
        case 'PortfolioType':
        case 'TechnologyType':
        case 'ServiceCoverageWindow':
        case 'HasMinorEnhancementsPerofOverallEfforts':
        case 'AverageIncidentsMonthlyOrYearly':
        case 'ServiceRequestsMonthlyOrYearly':
        case 'MinorEnhancementsMonthlyOrYearly':
          this.InsertUpdateServiceProfile();
          break;

        case 'ThirdPartyProductVendorInteractions':
        case 'DailyOperationalSupport':
        case 'MiscSupport':
        case 'Monitoring':
        case 'SeasonalActivities':
        case 'ServiceManagement':
        case 'UserQueries':

          let tot = Number(event.item.ThirdPartyProductVendorInteractions)
            + Number(event.item.DailyOperationalSupport) + Number(event.item.MiscSupport) + Number(event.item.Monitoring)
            + Number(event.item.SeasonalActivities) + Number(event.item.ServiceManagement) + Number(event.item.UserQueries);

          event.item.Total = !isFinite(tot) ? 0 : tot;
          break;
        // case 'SplitPer':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'HasMinorEnhancementsPerofOverallEfforts':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'AverageIncidentsMonthlyOrYearly':
        //   this.InsertUpdateServiceProfile();
        //   break;

        // case 'ServiceRequestsMonthlyOrYearly':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'MinorEnhancementsMonthlyOrYearly':
        //   this.InsertUpdateServiceProfile();
        //   break;

        // case 'ThirdPartyProductVendorInteractions':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'DailyOperationalSupport':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'MiscSupport':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'Monitoring':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'SeasonalActivities':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'ServiceManagement':
        //   this.InsertUpdateServiceProfile();
        //   break;
        // case 'UserQueries':
        //   this.InsertUpdateServiceProfile();
        //   break;

        default:
          break;
      }
    }
    if (this.ModuleName == 'ModelConfig') {
      let formatType = /^[-+]?[0-9]+\.[0-9]+$/
      let NoOfTowerTest = formatType.test(this.selectedInfoData.NoOfTower);
      let NoOfSubTowerTest = formatType.test(this.selectedInfoData.NoOfSubTower);
      let NoOfLotsTest = formatType.test(this.selectedInfoData.NoOfLots);
      let GovernanceTest = formatType.test(this.selectedInfoData.DeallevelGovernance);

      switch (event.fieldname.toUpperCase()) {
        case 'ENTERTOTALRESOURCECOUNT':
          this.getModelConfigurationGridCalculation = {
            AmApproach: this.selectedInfoData.AmApproach,
            ResourceBreakdownByLot: this.selectedInfoData.ResourceBreakdownByLot,
            Hasperbreakdownbylottopdown: this.selectedInfoData.Hasperbreakdownbylottopdown,
            EnterTotalResourceCount: this.selectedInfoData.EnterTotalResourceCount,
            ResourceLotDistributionPer: this.selectedInfoData.ResourceLotDistributionPer,
          }
          // this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
          if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
            !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
            && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != ""
            && !isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

            this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
              this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotResourceFieldName);
            });

          }
          break;
        case 'ENTERTOTALTICKETCOUNT':
          this.getModelConfigurationGridCalculation = {
            AmApproach: this.selectedInfoData.AmApproach,
            ticketsbreakdownbylot: this.selectedInfoData.ticketsbreakdownbylot,
            HasperbreakdownbylotTicketBased: this.selectedInfoData.HasperbreakdownbylotTicketBased,
            EnterTotalTicketCount: this.selectedInfoData.EnterTotalTicketCount,
            TicketsLotDistributionPer: this.selectedInfoData.TicketsLotDistributionPer,
          }
          // this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
          if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
            !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
            && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != ""
            && !isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

            this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
              this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotTicketsFieldName);
            });

          }
          break;
        case 'ENTERTOTALUSERSCOUNT':
          this.getModelConfigurationGridCalculation = {
            AmApproach: this.selectedInfoData.AmApproach,
            usersbreakdownbylot: this.selectedInfoData.usersbreakdownbylot,
            HasPerBreakdownbyLot: this.selectedInfoData.HasPerBreakdownbyLot,
            EnterTotalUsersCount: this.selectedInfoData.EnterTotalUsersCount,
            UsersLotDistributionPer: this.selectedInfoData.UsersLotDistributionPer,
          }
          // this.GetModelConfigurationGridCalculationDetails(this.getModelConfigurationGridCalculation);
          if (!isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
            !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
            && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots != ""
            && !isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

            this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
              this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotUsersFieldName);
            });

          }
          break;
        case 'RESOURCEBREAKDOWNBYLOT':
          if (event.item.ResourceBreakdownByLot == 'CHKYES' || event.item.ResourceBreakdownByLot == null) {
            this.Hiddenform = false;
            this.isInputDisabled = false;
            this.showResourceLotDistribution = false;
            this.selectedInfoData.ResourceLotDistributionPer = 0;
            this.selectedInfoData.Hasperbreakdownbylottopdown = null;
            this.selectedInfoData.TotalResourceCount = 0;
            this.selectedInfoData.LotResourceCount = 0;
            this.LotResourceCount.value = this.selectedInfoData.LotResourceCount;
            this.ResourceLotDistributionPer.value = this.selectedInfoData.ResourceLotDistributionPer;
            this.TotalResourceCount.value = this.selectedInfoData.TotalResourceCount;
            this.Hasperbreakdownbylottopdown.value = this.selectedInfoData.Hasperbreakdownbylottopdown;
          }
          else {
            this.Hiddenform = true;
            this.showResourceLotDistribution = true;
            this.isInputDisabled = true;
            this.selectedInfoData.LotResourceCount = 0;
            this.ModelConfigData.IsEditable = 1;
            this.selectedInfoData.Hasperbreakdownbylottopdown = "CHKYES";
            this.LotResourceCount.value = this.selectedInfoData.LotResourceCount;
            this.Hasperbreakdownbylottopdown.value = this.selectedInfoData.Hasperbreakdownbylottopdown;
          }

          this.block_TowerFields(event.item);

          break;

        case 'TICKETSBREAKDOWNBYLOT':
          if (event.item.ticketsbreakdownbylot == 'CHKYES' || event.item.ticketsbreakdownbylot == null) {
            this.showTicketLotDistribution = false;
            this.Hiddenform = false;
            this.isInputDisabled = false;
            this.selectedInfoData.LotTicketsCount = 0;
            this.selectedInfoData.TicketsLotDistributionPer = 0;
            this.selectedInfoData.HasperbreakdownbylotTicketBased = null;
            this.selectedInfoData.EnterTotalTicketCount = 0;
            this.LotTicketsCount.value = this.selectedInfoData.LotTicketsCount;
            this.TicketsLotDistributionPer.value = this.selectedInfoData.TicketsLotDistributionPer;
            this.HasperbreakdownbylotTicketBased.value = this.selectedInfoData.HasperbreakdownbylotTicketBased;
            this.EnterTotalTicketCount.value = this.selectedInfoData.EnterTotalTicketCount;
          }
          else {
            this.Hiddenform = true;
            this.showTicketLotDistribution = true;
            this.isInputDisabled = true;
            this.selectedInfoData.LotTicketsCount = 0;
            this.ModelConfigData.IsEditable = 1;
            this.selectedInfoData.HasperbreakdownbylotTicketBased = "CHKYES";
            this.LotTicketsCount.value = this.selectedInfoData.LotTicketsCount;
            this.HasperbreakdownbylotTicketBased.value = this.selectedInfoData.HasperbreakdownbylotTicketBased;
          }

          this.block_TowerFields(event.item);

          break;
        case 'USERSBREAKDOWNBYLOT':
          if (event.item.usersbreakdownbylot == 'CHKYES' || event.item.usersbreakdownbylot == null) {
            this.Hiddenform = false;
            this.showUserLotDistribution = false;
            this.isInputDisabled = false;
            this.selectedInfoData.LotUsersCount = 0;
            this.selectedInfoData.UsersLotDistributionPer = 0;
            this.selectedInfoData.HasPerBreakdownbyLot = null;
            this.selectedInfoData.EnterTotalUsersCount = 0;
            this.LotUsersCount.value = this.selectedInfoData.LotUsersCount;
            this.UsersLotDistributionPer.value = this.selectedInfoData.UsersLotDistributionPer;
            this.HasPerBreakdownbyLot.value = this.selectedInfoData.HasPerBreakdownbyLot;
            this.EnterTotalUsersCount.value = this.selectedInfoData.EnterTotalUsersCount;
          }
          else {
            this.Hiddenform = true;
            this.showUserLotDistribution = true;
            this.isInputDisabled = true;
            this.ModelConfigData.IsEditable = 1;
            this.selectedInfoData.LotUsersCount = 0;
            this.selectedInfoData.HasPerBreakdownbyLot = "CHKYES";
            this.HasPerBreakdownbyLot.value = this.selectedInfoData.HasPerBreakdownbyLot;
            this.LotUsersCount.value = this.selectedInfoData.LotUsersCount;
          }

          this.block_TowerFields(event.item);

          break;
        case 'HASPERBREAKDOWNBYLOTTOPDOWN':
          if (event.item.Hasperbreakdownbylottopdown == 'CHKYES' && this.selectedInfoData.ResourceBreakdownByLot == 'CHKNO') {
            this.showResourceLotDistribution = true;
          }
          else {
            this.showResourceLotDistribution = false;
            this.selectedInfoData.ResourceLotDistributionPer = 0;
            this.ResourceLotDistributionPer.value = this.selectedInfoData.ResourceLotDistributionPer;
          }

          this.block_TowerFields(event.item);

          break;
        case 'HASPERBREAKDOWNBYLOTTICKETBASED':
          if (event.item.HasperbreakdownbylotTicketBased == 'CHKYES' && this.selectedInfoData.ticketsbreakdownbylot == 'CHKNO') {
            this.showTicketLotDistribution = true;
          }
          else {
            this.showTicketLotDistribution = false;
            this.selectedInfoData.TicketsLotDistributionPer = 0;
            this.TicketsLotDistributionPer.value = this.selectedInfoData.TicketsLotDistributionPer;
          }

          this.block_TowerFields(event.item);

          break;
        case 'HASPERBREAKDOWNBYLOT':
          if (event.item.HasPerBreakdownbyLot == 'CHKYES' && this.selectedInfoData.usersbreakdownbylot == 'CHKNO') {
            this.showUserLotDistribution = true;
          }
          else {
            this.showUserLotDistribution = false;
            this.selectedInfoData.UsersLotDistributionPer = 0;
            this.UsersLotDistributionPer.value = this.selectedInfoData.UsersLotDistributionPer;
          }

          this.block_TowerFields(event.item);

          break;
        case 'VOLUMEUNIT':
          if (event.item.VolumeUnit == 'VOLUN1') {
            this.showLotsCountMonthly = true;
            this.showLotTicketsDefaultTitle = false;
          } else if (event.item.VolumeUnit == 'VOLUN2') {
            this.showLotsCountMonthly = false;
            this.showLotTicketsDefaultTitle = false;
          } else {
            this.showLotTicketsDefaultTitle = true;
          }
          break;

        case 'DEALLEVELGOVERNANCE':
          // if (GovernanceTest == true) {
          //   this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
          // }
          break;

        case 'NOOFTOWER':
          if (NoOfTowerTest == true) {
            this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
            this.showGrid = false;
          }
          break;

        case 'NOOFSUBTOWER':
          if (NoOfSubTowerTest == true) {
            this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
            this.showGrid = false;
          }
          break;

        case 'NOOFLOTS':
          if (NoOfLotsTest == true) {
            this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
            this.showGrid = false;
          }
          break;
      }

    }

    if (event.fieldname == "ResourceFootprintPer" && this.ModuleName == 'ClientProfile') {
      // this.InsertUpdateClientResourceProfile(0, event.item);
      let COPValue = Number(event.item.LotResourceCount) * (Number(event.item.ResourceFootprintPer) / 100);

      event.item.ClientOwnPersonnelCount = !isFinite(COPValue) ? 0 : COPValue.toFixed(2);
    }

    if (event.fieldname == "ResourceLocationCountry" && this.ModuleName == 'ClientProfile') {

      let fieldTempData: any;

      if (this.ApproachName == 'APR002' || this.ApproachName == 'APR004') {
        fieldTempData = this.ModelConfigTemplate.filter(x => x.Group === 'clientresourceprofilegrid' && x.FieldName == event.fieldname);
      } else if (this.ApproachName == 'APR001') {
        fieldTempData = this.ModelConfigTemplate.filter(x => x.Group === 'clientresourceTDAgrid' && x.FieldName == event.fieldname);
      } else if (this.ApproachName == 'APR003') {
        fieldTempData = this.ModelConfigTemplate.filter(x => x.Group === 'ClientResourceUBAGrid' && x.FieldName == event.fieldname);
      }

      if (!isNullOrUndefined(fieldTempData) && fieldTempData.length > 0) {

        let listData = fieldTempData[0].Control.List;

        if (!isNullOrUndefined(listData) && listData.length > 0) {
          let selectedCountryData = listData.filter((x: any) => x.Code === event.item.ResourceLocationCountry);

          let wrkHours = !isNullOrUndefined(selectedCountryData) && selectedCountryData.length > 0 ? Number(selectedCountryData[0].UserDef1) : 0;
          let annualComp = !isNullOrUndefined(selectedCountryData) && selectedCountryData.length > 0 ? Number(selectedCountryData[0].UserDef2) : 0;

          event.item.AverageAnnualWorkingHours = wrkHours;

          if (this.ApproachName == 'APR001') {
            event.item.AverageAnnualCompensation = annualComp;
          } else {
            event.item.TotalAnnualCompensation = annualComp;
          }

        }
      }

    }

    if (event.fieldname == "IncumbentVendorName" && this.ModuleName == 'ClientProfile' && this.ApproachName == 'APR001') {

      let fieldTempData: any;

      fieldTempData = this.ModelConfigTemplate.filter(x => x.Group === 'clientresourceTDAgrid' && x.FieldName == event.fieldname);

      if (!isNullOrUndefined(fieldTempData) && fieldTempData.length > 0) {

        let listData = fieldTempData[0].Control.List;

        if (!isNullOrUndefined(listData) && listData.length > 0) {
          let selectedCompetitorsData = listData.filter((x: any) => x.Code === event.item.IncumbentVendorName);

          let incumwrkHours = !isNullOrUndefined(selectedCompetitorsData) && selectedCompetitorsData.length > 0 ? Number(selectedCompetitorsData[0].UserDef1) : 0;
          let incumblendedrate = !isNullOrUndefined(selectedCompetitorsData) && selectedCompetitorsData.length > 0 ? Number(selectedCompetitorsData[0].UserDef2) : 0;

          if (incumwrkHours == 0) {
            this.toastr.warning("Selected Incumbent Vendor does not have Working Hours updated in the Master!!");
          }
          if (incumblendedrate == 0) {
            this.toastr.warning("Selected Incumbent Vendor does not have Blended Rates updated in the Master!!");
          }
        }
      }
    }



    //  APR001    Top Down Approach
    // APR002    Ticket Based Approach
    // APR003    No.of Users Based Approach
    // APR004    IT Metrics Driven Approach


    // this.ModelConfigForm.setDispcols();
  }

  onITMetricsChange(event: any) {
    let odata: any;
    let ITField_odata: any;
    odata = {
      ITMetricsOption: '',
      Totalnumberofemployee: '',
      AnnualCompensation: '',
      Yearlyrevenue: ''

    }
    ITField_odata = {
      DealHeaderId: this.DealHeaderId,
      MatrixInput: '',
      AnnualCompensation: '',
      ITMetricsOption: '',
      Spend: '',
      Resource: '',
      ITDomain: ''
    }
    console.log("ON CHANGE");
    console.log(event);

    switch (event.fieldname.toUpperCase()) {
      case 'ITMETRICSOPTIONS':
        this.optionSelected = true;
        this.showGrid = true;
        if (event.item.ItMetricsOptionsDesc == "OPEX") {
          this.metricsCount = this.metricsCount + 1;
          odata.ITMetricsOption = 'OPEX';
          this.metricsCount = this.metricsCount + 1;
          if (this.metricsCount != 1) {
            this.showNotificationMessage('Data will be lost!! Do you want to continue..?',
              2, 'ITMETRICSPOPUP', null, 'OK', 'Cancel', 10000);
          } else {
            this.opex = true;
            this.revenue = false;
            this.employee = false;
            this.ResourceCount = 0;
            this.ITSpendOPEX = 0;
            this.ITMetricsData.YearlyOpex = 0;
            this.YearlyOpex.value = this.ITMetricsData.YearlyOpex;
            this.GetITMetricsOptionsGridDefault(event.item.ItMetricsOptions);
          }
        }
        else if (event.item.ItMetricsOptionsDesc == "Revenue") {
          odata.ITMetricsOption = 'Revenue';
          this.metricsCount = this.metricsCount + 1;
          if (this.metricsCount != 1) {
            this.showNotificationMessage('Data will be lost!! Do you want to continue..?',
              2, 'ITMETRICSPOPUP', null, 'OK', 'Cancel', 10000);
          } else {
            this.opex = false;
            this.revenue = true;
            this.employee = false;
            this.ITSpend = 0;
            this.ResourceCount = 0;
            this.ITMetricsData.YearlyRevenu = 0;
            this.YearlyRevenu.value = this.ITMetricsData.YearlyRevenu;
            this.GetITMetricsOptionsGridDefault(event.item.ItMetricsOptions);
          }
        }
        else if (event.item.ItMetricsOptionsDesc == "Total Employees") {
          odata.ITMetricsOption = 'Total Employees';
          this.metricsCount = this.metricsCount + 1;
          if (this.metricsCount != 1) {
            this.showNotificationMessage('Data will be lost!! Do you want to continue..?',
              2, 'ITMETRICSPOPUP', null, 'OK', 'Cancel', 10000);
          }
          else {
            this.opex = false;
            this.revenue = false;
            this.employee = true;
            this.ITMetricsData.TotalNoOfEmployee = 0;
            this.ITResourceEmployee = 0;
            this.ITMetricsData.AnnualCompensation = 0;
            this.AnnualCompensation.value = this.ITMetricsData.AnnualCompensation;
            this.TotalNoOfEmployee.value = this.ITMetricsData.TotalNoOfEmployee;
            this.GetITMetricsOptionsGridDefault(event.item.ItMetricsOptions);
          }
        }
        break;
      case 'COUNTRY':
        this.AnnualCompensation.value = "";
        this.GetAnnualComensationDeatailsForITMetricsPopup(event.item.Country, this.ITMetricsData.Currency);
        break;
      case 'ANNUALCOMPENSATION':
        this.ITMetricsData.AnnualCompensation = event.value;
        break;
      case 'RESOURCE':
        ITField_odata = {
          DealHeaderId: this.ITMetricsData.DealHeaderId,
          MatrixGridInput: this.ITResourceEmployee,
          ITMetricsOption: this.ITMetricsData.ItMetricsOptions,
          Spend: 0,
          Resource: event.item.Resource,
          AnnualCompensation: 0,
          ITDomain: event.item.ITDomains
        }
        this.ITMetricsPopupChangeGridCal(ITField_odata);
        break;
      case 'SPEND':
        if (this.ITMetricsData.ItMetricsOptions == 'ITMTR2') {
          ITField_odata = {
            DealHeaderId: this.ITMetricsData.DealHeaderId,
            MatrixGridInput: this.ITSpend,
            ITMetricsOption: this.ITMetricsData.ItMetricsOptions,
            Spend: event.item.Spend,
            Resource: 0,
            AnnualCompensation: this.AnnualCompensation.value,
            ITDomain: event.item.ITDomains
          }
          this.ITMetricsPopupChangeGridCal(ITField_odata);
        } else {
          ITField_odata = {
            DealHeaderId: this.ITMetricsData.DealHeaderId,
            MatrixGridInput: this.ITSpendOPEX,
            ITMetricsOption: this.ITMetricsData.ItMetricsOptions,
            Spend: event.item.Spend,
            Resource: 0,
            AnnualCompensation: this.AnnualCompensation.value,
            ITDomain: event.item.ITDomains
          }
          this.ITMetricsPopupChangeGridCal(ITField_odata);
        }
        break;
      case 'YEARLYREVENU':
        odata.Yearlyrevenue = event.item.YearlyRevenu;
        ITField_odata = {
          DealHeaderId: this.DealHeaderId,
          Industry: event.item.ClientIndustry,
          MatrixInput: event.item.YearlyRevenu,
          AnnualCompensation: event.item.AnnualCompensation,
          ITMetricsOption: event.item.ItMetricsOptions
        }
        this.ITMetricsFieldsData(ITField_odata, 'Revenue');
        this.PopGridData(ITField_odata);
        //this.GetITMetricsGridData(odata);
        break;
      case 'TOTALNOOFEMPLOYEE':
        odata.Totalnumberofemployee = event.item.TotalNoOfEmployee;
        ITField_odata = {
          DealHeaderId: this.DealHeaderId,
          Industry: event.item.ClientIndustry,
          MatrixInput: event.item.TotalNoOfEmployee,
          AnnualCompensation: 0,
          ITMetricsOption: event.item.ItMetricsOptions
        }
        this.ITMetricsFieldsData(ITField_odata, 'Employee');
        this.PopGridData(ITField_odata);
        //  this.GetITMetricsGridData(odata);
        break;
      case 'YEARLYOPEX':
        odata.Totalnumberofemployee = event.item.TotalNoOfEmployee;
        ITField_odata = {
          DealHeaderId: this.DealHeaderId,
          Industry: event.item.ClientIndustry,
          MatrixInput: event.item.YearlyOpex,
          AnnualCompensation: event.item.AnnualCompensation,
          ITMetricsOption: event.item.ItMetricsOptions
        }
        this.ITMetricsFieldsData(ITField_odata, 'Opex');
        this.PopGridData(ITField_odata);
        //  this.GetITMetricsGridData(odata);
        break;

    }
  }

  ITMetricsFieldsData(odata: any, Type: any) {
    this.ResourceCount = '';
    this.ITSpend = '';
    this.ITSpendOPEX = '';
    this.ITResourceEmployee = '';
    let subscribe$: Subscription = this.modelConfigService.ITMetricsFieldsData(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        console.log(result);
        if (Type == 'Revenue') {
          this.ITSpend = result.Data[0].ITSpend;
          this.ResourceCount = result.Data[0].ResourceCount;
        }
        if (Type == 'Employee') {
          this.ITResourceEmployee = result.Data[0].ResourceCount;
        }
        if (Type == 'Opex') {
          this.ITSpendOPEX = result.Data[0].ITSpend;
          this.ResourceCount = result.Data[0].ResourceCount;
        }
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  PopGridData(odata: any) {
    let subscribe$: Subscription = this.modelConfigService.PopGridData(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        console.log(result);
        this.PopUpITMetricsGridData = result.Data;
        this.dataTotalCount = this.PopUpITMetricsGridData.length;

        if (!isNullOrUndefined(this.PopUpITMetricsGridData) && this.PopUpITMetricsGridData.length > 0) {
          this.calcITMetric();
        }

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }




  setCellLock(fieldname: string, item: any, parent: ModelConfigComponent): boolean {

    let bLock = false;
    let BlkCols: any[] = [];

    if (!isNullOrUndefined(parent.ModelConfigProperties.KCLBlockCols) && parent.ModelConfigProperties.KCLBlockCols.length > 0) {
      parent.ModelConfigProperties.KCLBlockCols.forEach((blockitem: any) => {
        if (blockitem.Value == item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    // Only for Tower Block
    if (!isNullOrUndefined(parent.ModelConfigProperties.TowerBlockCols) && parent.ModelConfigProperties.TowerBlockCols.length > 0) {
      parent.ModelConfigProperties.TowerBlockCols.forEach((blockitem: any) => {
        if (blockitem.Section === 1 && blockitem.Value === item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }

        if (blockitem.Section === 2 && blockitem.Value === item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }

        if (blockitem.Section === 3 && item.DealSubLineItemId > 0) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }
    return bLock;

  }
  GetServiceProfile() {
    // this.ModelBaselineData ={};
    // this.BaselineVolData = {} ;
    // this.BaselineTicketData = {};
    // this.ModelBaselineData = {};
    // this.AdditionalsupportData = {};
    // this.DeliveryDataModel = {};
    let odata = {
      DealHeaderId: this.DealHeaderId,
      DealLineItemId: this.DealLineItemId,
      DealSubLineItemId: this.DealSubLineItemId
    }
    let subscribe$: Subscription = this.modelConfigService.GetServiceProfile(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        let antiGirdTemp = this.ModelConfigTemplate.filter((x: any) => x.Group === 'TicketsGrowthSPTBA');

        for (let index = 1; index <= this.selectedDeal.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = antiGirdTemp.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }
        if (this.ApproachName == 'APR003') {

          let antiGirdTemp1 = this.ModelConfigTemplate.filter((x: any) => x.Group === 'UBASPGrid');
          //   let fieldData_All = antiGirdTemp.map(x => x.IsHidden == true);
          antiGirdTemp1.map(x => {
            x.IsHidden = true;
            return x;
          });
          console.log(antiGirdTemp1);
          for (let index = 1; index <= this.selectedDeal.DealTerm; index++) {
            let fieldName: string = 'Year' + index.toString();

            let fieldData = antiGirdTemp1.filter(x => x.FieldName === fieldName);

            if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
              fieldData[0].IsHidden = false;
            }

          }

        }

        //  let yearsWithZeroValue: string[] = [];

        // for (let index = 1; index <=7; index++) {
        //   let fieldName: string = 'Year' + index.toString();
        //   let fieldValue = result.Data[0][fieldName];

        //   if (!isNullOrUndefined(fieldValue) && fieldValue === 0) {
        //     yearsWithZeroValue.push(fieldName);
        //   }
        // }


        // for (let index = 0; index < yearsWithZeroValue.length; index++) {
        //   let fieldName: string = yearsWithZeroValue[index];
        //   //'Year' + index.toString();
        //   let antiGirdTemp1 = this.ModelConfigTemplate.filter((x: any) => x.Group === 'UBASPGrid');
        //   let fieldData = antiGirdTemp1.filter(x => x.FieldName === fieldName);

        //   if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
        //     fieldData[0].IsHidden = true;
        //   }

        // }
        // Remove the years with value 0 from the object
        // for (const year of yearsWithZeroValue) {
        //   delete result.Data[0][year];
        // }

        console.log(result.Data);


        if (this.ApproachName == 'APR002') {
          this.selectedInfoData = result.Data[0];
        }
        this.GridDataServiceProfileUserApproach = result.Data;
        console.log(this.GridDataServiceProfileUserApproach);
        this.BaselineVolData = result.Data[0];
        this.BaselineTicketData = result.Data[0];
        this.ModelBaselineData = result.Data[0];
        this.AdditionalsupportData = result.Data;
        this.DeliveryDataModel = result.Data[0];
        // // this.Anticipatedfte.forEach((element: any) => {
        // //   element.Year2 = 0;
        // // });
        // //  this.Anticipatedfte.IsEditable = 1;
        // this.dataTotalCount = this.deliveryModelgridData.length;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  ///VALIDATIONS//////
  validateFields(): boolean {
    let valid: boolean = false;

    //  APR001    Top Down Approach
    // APR002    Ticket Based Approach
    // APR003    No.of Users Based Approach
    // APR004    IT Metrics Driven Approach
    switch (this.ApproachName) {
      case 'APR001':

        // if (this.ModuleName == 'ClientProfile') {

        //   if (isNullOrUndefined(this.ModelBaselineData.ResourceLocationCountry) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationRegion) ||
        //     isNullOrUndefined(this.ModelBaselineData.KeyClientLocation) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceFootprintPer) ||
        //     isNullOrUndefined(this.ModelBaselineData.AverageAnnualWorkingHours) ||
        //     isNullOrUndefined(this.ModelBaselineData.AverageAnnualCompensation) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationCityCategory) ||
        //     isNullOrUndefined(this.ModelBaselineData.IncumbentVendorResourceCount) ||
        //     isNullOrUndefined(this.ModelBaselineData.IncumbentHourlyBlendedRate)) {



        //     this.toastr.warning("Please fill all mandatory fields");

        //     valid = false;
        //   }
        //   else {
        //     valid = true;
        //   }






        // }
        // if (this.ModuleName == 'AnticipatedFTE') {

        //   if (isNullOrUndefined(this.Anticipatedfte.ResourceLocationCountry) ||
        //     isNullOrUndefined(this.Anticipatedfte.ResourceLocationRegion) ||
        //     isNullOrUndefined(this.Anticipatedfte.CityDesc) ||
        //     isNullOrUndefined(this.Anticipatedfte.KeyClientLocation) ||
        //     isNullOrUndefined(this.Anticipatedfte.ResourceFootprintPer) ||
        //     isNullOrUndefined(this.Anticipatedfte.AverageAnnualWorkingHours)||
        //     isNullOrUndefined(this.Anticipatedfte.RegionDesc)) {
        //     this.toastr.warning("Please fill all mandatory fields");

        //     valid = false;
        //   }
        //   else {


        //     valid = true;
        //   }






        // }

        break;
      case 'APR004':


        // if (this.ModuleName == 'ClientProfile') {

        //   if (isNullOrUndefined(this.ModelBaselineData.ResourceLocationCountry) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationRegion) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationCityCategory) ||
        //     isNullOrUndefined(this.ModelBaselineData.KeyClientLocation) ||
        //     isNullOrUndefined(this.ModelBaselineData.AverageAnnualWorkingHours) ||
        //     isNullOrUndefined(this.ModelBaselineData.TotalAnnualCompensation)) {
        //     this.toastr.warning("Please fill all mandatory fields");

        //     valid = false;
        //   }
        //   else {


        //     valid = true;
        //   }


        // }
        console.log("gdgd")
        break;
      case 'APR002':

        console.log(this.ModelBaselineData);
        if (this.ModuleName == 'ServiceProfile') {


          if (isNullOrUndefined(this.ModelBaselineData.TechnologyType) ||
            isNullOrUndefined(this.ModelBaselineData.PortfolioType) ||
            isNullOrUndefined(this.ModelBaselineData.ServiceCoverageWindow) ||
            isNullOrUndefined(this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts) ||
            isNullOrUndefined(this.BaselineVolData.SplitPer)) {
            this.toastr.warning("Please fill all mandatory fields");
            valid = false;
          }
          else if (this.ModelBaselineData.TechnologyType == '' ||
            this.ModelBaselineData.PortfolioType == '' ||
            this.ModelBaselineData.ServiceCoverageWindow == '' ||
            this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts == '' ||
            this.BaselineVolData.SplitPer == '') {
            this.toastr.warning("Please fill all mandatory fields");
            valid = false;
          }
          else {
            valid = true;
          }
          // this.ModelBaselineData.TechnologyType
          // this.ModelBaselineData.PortfolioType
          // this.ModelBaselineData.ServiceCoverageWindow
          // this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts
          // this.BaselineVolData.P1TicketPercentageDesc
          // this.BaselineVolData.SplitPer
          // this.BaselineVolData.AverageIncidentsMonthlyOrYearly
          // this.BaselineVolData.VolumeUnit
        }
        // if (this.ModuleName == 'DeliveryModel') {
        //   if (isNullOrUndefined(this.ModelBaselineData.DeliveredFromRegion) ||
        //     isNullOrUndefined(this.ModelBaselineData.LocationType) ||
        //     isNullOrUndefined(this.ModelBaselineData.DeliveredFromCityCategory) ||
        //     isNullOrUndefined(this.ModelBaselineData.DeliveredFromCountry) ||
        //     isNullOrUndefined(this.ModelBaselineData.EffortDistributionPer)) {

        //     this.toastr.warning("Please fill all mandatory fields");
        //     valid = false;
        //   }
        //   else if (this.ModelBaselineData.DeliveredFromRegion == '' ||
        //     this.ModelBaselineData.LocationType == '' ||
        //     this.ModelBaselineData.DeliveredFromCityCategory == '' ||
        //     this.ModelBaselineData.DeliveredFromCountry == '' ||
        //     this.ModelBaselineData.EffortDistributionPer == '') {
        //     this.toastr.warning("Please fill all mandatory fields");
        //     valid = false;
        //   }
        //   else {
        //     valid = true;
        //   }

        // }
        // if (this.ModuleName == 'ClientProfile') {

        //   if (isNullOrUndefined(this.ModelBaselineData.ResourceLocationRegion) ||
        //     isNullOrUndefined(this.ModelBaselineData.TotalAnnualCompensation) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationCountry) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationCityCategory) ||
        //     isNullOrUndefined(this.ModelBaselineData.KeyClientLocation) ||
        //     isNullOrUndefined(this.ModelBaselineData.AverageAnnualWorkingHours)) {

        //     this.toastr.warning("Please fill all mandatory fields");

        //     valid = false;
        //   }
        //   else if (this.ModelBaselineData.ResourceLocationRegion == '' ||
        //     this.ModelBaselineData.TotalAnnualCompensation == '' ||
        //     this.ModelBaselineData.ResourceLocationCountry == '' ||
        //     this.ModelBaselineData.ResourceLocationCityCategory == '' ||
        //     this.ModelBaselineData.KeyClientLocation == '' ||
        //     this.ModelBaselineData.AverageAnnualWorkingHours == '') {
        //     this.toastr.warning("Please fill all mandatory fields");
        //     valid = false;
        //   }
        //   else {
        //     valid = true;
        //   }

        // }
        break;
      case 'APR003':

        // if (this.ModuleName == 'ClientProfile') {

        //   if (isNullOrUndefined(this.ModelBaselineData.ResourceLocationRegion) ||
        //     isNullOrUndefined(this.ModelBaselineData.TotalAnnualCompensation) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationCountry) ||
        //     isNullOrUndefined(this.ModelBaselineData.ResourceLocationCityCategory) ||
        //     isNullOrUndefined(this.ModelBaselineData.KeyClientLocation) ||
        //     isNullOrUndefined(this.ModelBaselineData.AverageAnnualWorkingHours) ||
        //     isNullOrUndefined(this.ModelBaselineData.UserDistributionPerbyLot)) {

        //     this.toastr.warning("Please fill all mandatory fields");

        //     valid = false;
        //   }

        //   else if (this.ModelBaselineData.ResourceLocationRegion == '' ||
        //     this.ModelBaselineData.TotalAnnualCompensation == '' ||
        //     this.ModelBaselineData.ResourceLocationCountry == '' ||
        //     this.ModelBaselineData.ResourceLocationCityCategory == '' ||
        //     this.ModelBaselineData.KeyClientLocation == '' ||
        //     this.ModelBaselineData.AverageAnnualWorkingHours == '' ||
        //     this.ModelBaselineData.UserDistributionPerbyLot == '') {
        //     this.toastr.warning("Please fill all mandatory fields");
        //     valid = false;
        //   }
        //   else {
        //     valid = true;
        //   }
        // }
        if (this.ModuleName == 'ServiceProfile') {
          if (isNullOrUndefined(this.DeliveryDataModel.PortfolioStability)) {
            this.toastr.warning("Please fill all mandatory fields");
            valid = false;
          }

          else if (this.ModelBaselineData.ResourceLocationRegion == '' ||
            this.ModelBaselineData.TotalAnnualCompensation == '' ||
            this.ModelBaselineData.ResourceLocationCountry == '' ||
            this.ModelBaselineData.ResourceLocationCityCategory == '' ||
            this.ModelBaselineData.KeyClientLocation == '' ||
            this.ModelBaselineData.AverageAnnualWorkingHours == '' ||
            this.ModelBaselineData.UserDistributionPerbyLot == '') {
            this.toastr.warning("Please fill all mandatory fields");
            valid = false;
          }
          else {
            valid = true;
          }
          // this.ModelBaselineData.TechnologyType
          // this.ModelBaselineData.PortfolioType
          // this.ModelBaselineData.ServiceCoverageWindow
          // this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts
          // this.BaselineVolData.P1TicketPercentageDesc
          // this.BaselineVolData.SplitPer
          // this.BaselineVolData.AverageIncidentsMonthlyOrYearly
          // this.BaselineVolData.VolumeUnit
        }

        break;


    }

    return valid
  }
  ////////validate grids/////
  validateGridFields(item: any): boolean {
    let valid: boolean = false;

    //  APR001    Top Down Approach
    // APR002    Ticket Based Approach
    // APR003    No.of Users Based Approach
    // APR004    IT Metrics Driven Approach
    switch (this.ApproachName) {
      case 'APR001':

        if (this.ModuleName == 'ClientProfile') {

          if (isNullOrUndefined(item.ResourceLocationCountry) ||
            isNullOrUndefined(item.ResourceLocationRegion) ||
            isNullOrUndefined(item.KeyClientLocation) ||
            isNullOrUndefined(item.ResourceFootprintPer) ||
            isNullOrUndefined(item.AverageAnnualWorkingHours) ||
            isNullOrUndefined(item.AverageAnnualCompensation) ||
            isNullOrUndefined(item.ResourceLocationCityCategory) ||
            isNullOrUndefined(item.IncumbentVendorResourceCount) ||
            isNullOrUndefined(item.IncumbentHourlyBlendedRate)) {



            this.toastr.warning("Please fill all mandatory fields");

            valid = false;
          }
          else {
            valid = true;
          }






        }
        // if (this.ModuleName == 'AnticipatedFTE') {

        //   if (isNullOrUndefined(this.Anticipatedfte.ResourceLocationCountry) ||
        //     isNullOrUndefined(this.Anticipatedfte.ResourceLocationRegion) ||
        //     isNullOrUndefined(this.Anticipatedfte.CityDesc) ||
        //     isNullOrUndefined(this.Anticipatedfte.KeyClientLocation) ||
        //     isNullOrUndefined(this.Anticipatedfte.ResourceFootprintPer) ||
        //     isNullOrUndefined(this.Anticipatedfte.AverageAnnualWorkingHours)||
        //     isNullOrUndefined(this.Anticipatedfte.RegionDesc)) {
        //     this.toastr.warning("Please fill all mandatory fields");

        //     valid = false;
        //   }
        //   else {


        //     valid = true;
        //   }






        // }

        break;
      case 'APR004':


        if (this.ModuleName == 'ClientProfile') {

          if (isNullOrUndefined(item.ResourceLocationCountry) ||
            isNullOrUndefined(item.ResourceLocationRegion) ||
            isNullOrUndefined(item.ResourceLocationCityCategory) ||
            isNullOrUndefined(item.KeyClientLocation) ||
            isNullOrUndefined(item.AverageAnnualWorkingHours) ||
            isNullOrUndefined(item.TotalAnnualCompensation)) {
            this.toastr.warning("Please fill all mandatory fields");

            valid = false;
          }
          else {


            valid = true;
          }


        }
        console.log("gdgd")
        break;
      case 'APR002':

        console.log(this.ModelBaselineData);
        // if (this.ModuleName == 'ServiceProfile') {


        //   if (isNullOrUndefined(this.ModelBaselineData.TechnologyType) ||
        //     isNullOrUndefined(this.ModelBaselineData.PortfolioType) ||
        //     isNullOrUndefined(this.ModelBaselineData.ServiceCoverageWindow) ||
        //     isNullOrUndefined(this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts) ||
        //     isNullOrUndefined(this.BaselineVolData.SplitPer)) {
        //     this.toastr.warning("Please fill all mandatory fields");
        //     valid = false;
        //   }
        //   else {


        //     valid = true;
        //   }
        //   // this.ModelBaselineData.TechnologyType
        //   // this.ModelBaselineData.PortfolioType
        //   // this.ModelBaselineData.ServiceCoverageWindow
        //   // this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts
        //   // this.BaselineVolData.P1TicketPercentageDesc
        //   // this.BaselineVolData.SplitPer
        //   // this.BaselineVolData.AverageIncidentsMonthlyOrYearly
        //   // this.BaselineVolData.VolumeUnit
        // }
        if (this.ModuleName == 'DeliveryModel') {
          if (isNullOrUndefined(item.DeliveredFromRegion) ||
            isNullOrUndefined(item.LocationType) ||
            isNullOrUndefined(item.DeliveredFromCityCategory) ||
            isNullOrUndefined(item.DeliveredFromCountry) ||
            isNullOrUndefined(item.EffortDistributionPer)) {

            this.toastr.warning("Please fill all mandatory fields");
            valid = false;
          }
          else {
            valid = true;
          }

        }
        if (this.ModuleName == 'ClientProfile') {

          if (isNullOrUndefined(item.ResourceLocationRegion) ||
            isNullOrUndefined(item.TotalAnnualCompensation) ||
            isNullOrUndefined(item.ResourceLocationCountry) ||
            isNullOrUndefined(item.ResourceLocationCityCategory) ||
            isNullOrUndefined(item.KeyClientLocation) ||
            isNullOrUndefined(item.AverageAnnualWorkingHours)) {

            this.toastr.warning("Please fill all mandatory fields");

            valid = false;
          }
          else {


            valid = true;
          }

        }
        break;
      case 'APR003':

        if (this.ModuleName == 'ClientProfile') {

          if (isNullOrUndefined(item.ResourceLocationRegion) ||
            isNullOrUndefined(item.TotalAnnualCompensation) ||
            isNullOrUndefined(item.ResourceLocationCountry) ||
            isNullOrUndefined(item.ResourceLocationCityCategory) ||
            isNullOrUndefined(item.KeyClientLocation) ||
            isNullOrUndefined(item.AverageAnnualWorkingHours) ||
            isNullOrUndefined(item.UserDistributionPerbyLot)) {

            this.toastr.warning("Please fill all mandatory fields");

            valid = false;
          }
          else {


            valid = true;
          }
        }
        if (this.ModuleName == 'ServiceProfile') {


          if (isNullOrUndefined(this.DeliveryDataModel.PortfolioStability)) {


            this.toastr.warning("Please fill all mandatory fields");
            valid = false;
          }
          else {


            valid = true;
          }
          // this.ModelBaselineData.TechnologyType
          // this.ModelBaselineData.PortfolioType
          // this.ModelBaselineData.ServiceCoverageWindow
          // this.BaselineVolData.HasMinorEnhancementsPerofOverallEfforts
          // this.BaselineVolData.P1TicketPercentageDesc
          // this.BaselineVolData.SplitPer
          // this.BaselineVolData.AverageIncidentsMonthlyOrYearly
          // this.BaselineVolData.VolumeUnit
        }

        break;


    }

    return valid
  }

  getSelectedDeal(data: any) {
    this._appSharedService.setSelectedDeal(data);
  }


  NavigatetoNext() {
    if (this.ModuleName == 'ModelConfig') {
      this.insertorUpdateModelConfig();
    }
    //////// TOP DOWN AND IT METRICS APPROACH////////
    else if (this.ModuleName == 'ModelBaseline' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
      //   this.ModuleName = 'ServiceProfile'
      //console.log(this.BaselineGridData[0].ClientResourceCountriesCount)
      this.GetModelBaseliningDetails();
      this.ModelBaselineData.IsEditable = 1;
      this.BaselineVolData.IsEditable = 1;
      this.BaselineTicketData.IsEditable = 1;
      this.ModuleName = 'ClientProfile';
      this.GetClientResourceData();
      this.OpenContent = true;
    }

    else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {

      // this.GetClientResourceData();
      this.GetAnticipatedDetails();
      //   this.ModuleName = 'ServiceProfile'
      //   this.GetModelBaseliningDetails();
      //   this.ModelBaselineData.IsEditable = 1;
      //   this.BaselineVolData.IsEditable = 1;
      //   this.BaselineTicketData.IsEditable = 1;


      if (!isNullOrUndefined(this.ClientResourceData)) {
        if (this.ClientResourceData[0].ClientResourceProfileId != 0) {
          this.OpenContent = false;
          this.ModuleName = 'AnticipatedFTE';
        }
        else {
          this.toastr.warning("Please fill all mandatory fields");
        }

      }

      else {
        if (this.validateGridFields(this.selectedInfoData)) {
          //   this.Mode == '',
          this.buttonName == 'SAVE';
          this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
          // this.AddNewFlag = true;
          this.OpenContent = false;
          this.ModuleName = 'AnticipatedFTE';
        }
      }


    }


    else if (this.ModuleName == 'AnticipatedFTE' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {
      this.GetAnticipatedDetails();
      this.InsertUpdateAnticipatedFTEGrowth();

      if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
        let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
        if (DerivedResourceCount.length > 0) {
          this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
        }
        else {
          this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
          this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
          this.OpenContent = false;
        }
      }
      else {
        this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
        this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
        this.OpenContent = false;
      }
    }


    ////APPROACH  TICKET BASED//////

    else if (this.ModuleName == 'ModelBaseline' && (this.ApproachName == 'APR002')) {
      //   this.ModuleName = 'ServiceProfile'
      this.GetModelBaseliningDetails();
      this.ModelBaselineData.IsEditable = 1;
      this.BaselineVolData.IsEditable = 1;
      this.BaselineTicketData.IsEditable = 1;
      this.ModuleName = 'ServiceProfile';
      this.OpenContent = false;
      this.GetServiceProfile();

    }



    else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR002')) {
      //   this.ModuleName = 'ServiceProfile'
      this.ModelBaselineData.IsEditable = 1;
      this.BaselineVolData.IsEditable = 1;
      this.BaselineTicketData.IsEditable = 1;

      if (this.selectedInfoData.IsModified == 1) {
        if (this.validateFields()) {
          this.InsertUpdateServiceProfile();

          this.ModuleName = 'ClientProfile';
          this.OpenContent = true;
          this.selectedInfoData.IsModified = null;
        }
      }

      if (this.selectedInfoData.IsModified == undefined || this.selectedInfoData.IsModified == null) {
        this.ModuleName = 'ClientProfile';
        this.OpenContent = true;
      }
      this.GetClientResourceData();
    }
    else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {
      //   this.ModuleName = 'ServiceProfile'
      this.GetClientResourceData();
      if (!isNullOrUndefined(this.ClientResourceData)) {
        if (this.ClientResourceData[0].ClientResourceProfileId != 0) {
          this.ModuleName = 'DeliveryModel';
          this.GetMBDeliveryLocationModelling();
          this.OpenContent = false;
        }
        else {
          this.toastr.warning("Please fill all mandatory fields");
        }

      }
      else {
        if (this.validateFields()) {
          this.InsertUpdateClientResourceProfile(0, this.selectedInfoData);
          this.ModuleName = 'DeliveryModel';
          this.GetMBDeliveryLocationModelling();
          this.OpenContent = false;
        }
      }

      // else {
      //   this.toastr.warning("Please fill all mandatory fields");
      // }



    }
    else if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {
      this.GetMBDeliveryLocationModelling();
      if (!isNullOrUndefined(this.deliveryModelgridData)) {
        if (this.deliveryModelgridData[0].MBDeliveryLocationModellingId != 0) {

          if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
            let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
            if (DerivedResourceCount.length > 0) {
              this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
            }
            else {
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = false;
            }
          }
          else {
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
            this.OpenContent = false;
          }
        }
        else {
          this.toastr.warning("Please fill all mandatory fields");
        }
      }
      else {
        if (this.validateFields()) {
          this.InsertUpdateMBDeliveryLocationModelling(0, this.selectedInfoData);
          //   this.ModuleName = 'ServiceProfile'
          // this._router.navigate([`app/lot-solution-modelling/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealHeaderId}`])

          if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
            let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
            if (DerivedResourceCount.length > 0) {
              this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
            }
            else {
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = false;
            }
          }
          else {
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
            this.OpenContent = false;
          }
        }
      }




    }


    ///////// USER BASED APPROACH//////

    else if (this.ModuleName == 'ModelBaseline' && (this.ApproachName == 'APR003')) {
      //   this.ModuleName = 'ServiceProfile'
      this.GetModelBaseliningDetails();
      this.OpenContent = false;
      this.ModelBaselineData.IsEditable = 1;
      this.BaselineVolData.IsEditable = 1;
      this.BaselineTicketData.IsEditable = 1;
      this.ModuleName = 'ServiceProfile';
      this.GetServiceProfile();

    }



    else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR003')) {
      //   this.ModuleName = 'ServiceProfile'
      //  this.GetClientResourceData();
      this.ModelBaselineData.IsEditable = 1;
      if (this.validateFields()) {
        this.InsertUpdateServiceProfile();
        this.ModuleName = 'ClientProfile';
        this.OpenContent = true;
      }
      this.GetClientResourceData();
    }


    else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {
      //   this.ModuleName = 'ServiceProfile'

      if (!isNullOrUndefined(this.ClientResourceData)) {
        if (this.ClientResourceData[0].ClientResourceProfileId != 0) {

          if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
            let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
            if (DerivedResourceCount.length > 0) {
              this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
            }
            else {
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = false;
            }
          }
          else {
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
            this.OpenContent = false;
          }
        }
        else {
          this.toastr.warning("Please fill all mandatory fields");
        }
      }
      else {
        if (this.validateFields()) {

          if (!isNullOrUndefined(this.BaselineGridData) && this.BaselineGridData.length > 0) {
            let DerivedResourceCount = this.BaselineGridData.filter((x: any) => Number(x.DerivedResource) == 0);
            if (DerivedResourceCount.length > 0) {
              this.showNotificationMessage('Derived Resource Count must be greater than 0', 2, '', '', '', '', 1000);
            }
            else {
              this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
              this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
              this.OpenContent = false;
            }
          }
          else {
            this._appSharedService.currentDealData.selectedLotData = this.selecteddata;
            this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`], { state: { selectedData: this.selecteddata } });
            this.OpenContent = false;
          }
        }
      }


    }

  }


  // Dynamic Grid For Tower Split
  onTowerSplitChange(event: any, tFlag: any) {

    switch (tFlag) {
      case 'Tower':

        // Lots
        if (!isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {
          let lots_Data = this.TowerSplitData.Tot_Lots_Tower.filter((x: any) => x.TowerId === event.TowerId);

          if (!isNullOrUndefined(lots_Data) && lots_Data.length > 0) {
            lots_Data.forEach((item: any) => {
              item.Tower = event[event.TowerFieldName];
              item.IsModified = 1;
            });
          }
        }

        break;
      case 'SubTower':


        // Lots
        if (!isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {
          let lots_Data = this.TowerSplitData.Tot_Lots_Tower.filter((x: any) => x.TowerId === event.TowerId && x.SubTowerId === event.SubTowerId);

          if (!isNullOrUndefined(lots_Data) && lots_Data.length > 0) {
            lots_Data.forEach((item: any) => {
              item.SubTower = event[event.SubTowerFieldName];
              item.IsModified = 1;
            });
          }
        }

        break;
      case 'Lot':
        event.Lot = event[event.LotsFieldName];
        event.IsModified = 1;
        break;
      case 'ResourceLotDistribution':
        event.ResourceLotDistributionPer = event[event.ResourceLotFieldName];
        event.IsModified = 1;
        this.getModelConfigurationGridCalculationDetail(event, this.selectedInfoData, event.LotResourceFieldName);
        break;
      case 'LotResource':
        event.LotResourceCount = event[event.LotResourceFieldName];
        this.selectedInfoData.LotResourceCount = event.LotResourceCount;
        event.IsModified = 1;
        break;
      case 'ClientResource':
        event.ClientResourceCountriesCount = event[event.ClientResourceFieldName];
        this.selectedInfoData.ClientResourceCountriesCount = event.ClientResourceCountriesCount;
        event.IsModified = 1;
        break;
      case 'TicketLotDistribution':
        event.TicketsLotDistributionPer = event[event.TicketLotDistributionFieldName];
        event.IsModified = 1;
        this.getModelConfigurationGridCalculationDetail(event, this.selectedInfoData, event.LotTicketsFieldName);
        break;
      case 'LotTickets':
        event.LotTicketsCount = event[event.LotTicketsFieldName];
        this.selectedInfoData.LotTicketsCount = event.LotTicketsCount;
        event.IsModified = 1;
        break;
      case 'UserLotDistribution':
        event.UsersLotDistributionPer = event[event.UserLotDistributionFieldName];
        event.IsModified = 1;
        this.getModelConfigurationGridCalculationDetail(event, this.selectedInfoData, event.LotUsersFieldName);
        break;
      case 'LotUsers':
        event.LotUsersCount = event[event.LotUsersFieldName];
        this.selectedInfoData.LotUsersCount = event.LotUsersCount;
        event.IsModified = 1;
        break;

    }
  }

  towerSplit(data: any) {
    if (!isNullOrUndefined(data) && data.length > 0) {

      let Tot_Tower: any = [];
      let Tot_Sub_Tower: any = [];
      let Tot_Lots_Tower: any = [];

      let l_saved_Data = data.filter((x: any) => x.DealSubLineItemId > 0);

      let saved_Data = !isNullOrUndefined(l_saved_Data) && l_saved_Data.length > 0 ? JSON.parse(JSON.stringify(l_saved_Data)) : null;

      let disable_Header_Row = !isNullOrUndefined(saved_Data) && saved_Data.length > 0 ? true : false;


      let deserilized_Data: any = [];

      if (disable_Header_Row && !isNullOrUndefined(saved_Data) && saved_Data.length > 0 && !isNullOrUndefined(saved_Data[0].LotTemplate) && saved_Data[0].LotTemplate !== '') {
        saved_Data.forEach((dseri: any) => {
          dseri.LotTemplate = JSON.parse(dseri.LotTemplate);
          deserilized_Data.push(dseri.LotTemplate);
        });
      }

      if (disable_Header_Row && !isNullOrUndefined(saved_Data) && saved_Data.length > 0 && !isNullOrUndefined(saved_Data[0].LotTemplate) && saved_Data[0].LotTemplate !== '') {

        let lotsId_index = 0;

        saved_Data.forEach((_sData: any) => {

          lotsId_index = lotsId_index + 1;

          let tower_name_Id = 'TowerId' + _sData.LotTemplate.TowerId;
          let sub_tower_name_Id = 'Sub_TowerId' + _sData.LotTemplate.SubTowerId;
          let lots_name_Id = 'Lots' + _sData.LotTemplate.LotsId;
          let resource_lots_name_Id = 'ResourceLot' + lotsId_index;
          let TicketLotDistribution_lots_name_Id = 'TicketLotDistribution' + lotsId_index;
          let UserLotDistribution_lots_name_Id = 'UserLotDistribution' + lotsId_index;
          let LotResource_lots_name_Id = 'LotResource' + lotsId_index;
          let LotUsers_lots_name_Id = 'LotTickets' + lotsId_index;
          let LotUsers_lotsId = 'LotUsers' + lotsId_index;
          let ClientResource_lots_name_Id = 'ClientResource' + lotsId_index;

          let lots_tow_oData: any = {
            'DealSubLineItemId': _sData.DealSubLineItemId,
            'TowerId': _sData.LotTemplate.TowerId,
            'TowerFieldName': tower_name_Id,
            [tower_name_Id]: _sData.TowerName,
            'SubTowerFieldName': sub_tower_name_Id,
            'SubTowerId': _sData.LotTemplate.SubTowerId,
            [sub_tower_name_Id]: _sData.SubTowerName,
            'LotsId': _sData.LotTemplate.LotsId,
            'LotsFieldName': lots_name_Id,
            'Group': 'Lots',
            [lots_name_Id]: _sData.LotName,
            'Tower': _sData.TowerName,
            'SubTower': _sData.SubTowerName,
            'Lot': _sData.LotName,
            'ResourceLotFieldName': resource_lots_name_Id,
            [resource_lots_name_Id]: _sData.ResourceLotDistributionPer,
            'ResourceLotDistributionPer': _sData.ResourceLotDistributionPer,
            'TicketLotDistributionFieldName': TicketLotDistribution_lots_name_Id,
            [TicketLotDistribution_lots_name_Id]: _sData.TicketsLotDistributionPer,
            'TicketsLotDistributionPer': _sData.TicketsLotDistributionPer,
            'UserLotDistributionFieldName': UserLotDistribution_lots_name_Id,
            [UserLotDistribution_lots_name_Id]: _sData.UsersLotDistributionPer,
            'UsersLotDistributionPer': _sData.UsersLotDistributionPer,
            'LotResourceFieldName': LotResource_lots_name_Id,
            [LotResource_lots_name_Id]: _sData.LotResourceCount,
            'LotResourceCount': _sData.LotResourceCount,
            'LotTicketsFieldName': LotUsers_lots_name_Id,
            [LotUsers_lots_name_Id]: _sData.LotTicketsCount,
            'LotTicketsCount': _sData.LotTicketsCount,
            'PlaceHolder': this.showLotTicketsDefaultTitle ? 'Lot Tickets Count' :
              (this.showLotsCountMonthly && !this.showLotTicketsDefaultTitle ? 'Lot Tickets Monthly Count' :
                (!this.showLotsCountMonthly && !this.showLotTicketsDefaultTitle ? 'Lot Tickets Yearly Count' : '')),
            'LotUsersFieldName': LotUsers_lotsId,
            [LotUsers_lotsId]: _sData.LotUsersCount,
            'LotUsersCount': _sData.LotUsersCount,
            'ClientResourceFieldName': ClientResource_lots_name_Id,
            [ClientResource_lots_name_Id]: _sData.CurrentClientResourceCountriesCount,
            'ClientResourceCountriesCount': _sData.CurrentClientResourceCountriesCount,
            'IsModified': 0,
            'lotdisable': disable_Header_Row,
            'lotresourcedisable': this.disableDynamicFields('RESOURCE'),
            'lotticketsdisable': this.disableDynamicFields('TICKET'),
            'usersbreakdownbylotdisable': this.disableDynamicFields('USER'),
            'itmetriclotnamedisable': this.disableDynamicFields('ITMETRIC')
          };

          Tot_Lots_Tower.push(lots_tow_oData);

          if (!isNullOrUndefined(Tot_Sub_Tower) && Tot_Sub_Tower.length > 0) {

            let check_subTower = Tot_Sub_Tower.filter((x: any) => x.SubTowerId === _sData.LotTemplate.SubTowerId && x.TowerId === _sData.LotTemplate.TowerId);

            if (isNullOrUndefined(check_subTower) || check_subTower.length === 0) {
              let sub_tow_oData = {
                'TowerId': _sData.LotTemplate.TowerId,
                'TowerFieldName': tower_name_Id,
                [tower_name_Id]: _sData.TowerName,
                'SubTowerId': _sData.LotTemplate.SubTowerId,
                'SubTowerFieldName': sub_tower_name_Id,
                [sub_tower_name_Id]: _sData.SubTowerName,
                'SubTowerColSpan': data[0].NoOfLots,
                'Group': 'SubTower',
                'subtowerdisable': disable_Header_Row,
                'Lots': []
              };

              Tot_Sub_Tower.push(sub_tow_oData);
            }

          } else {
            let sub_tow_oData = {
              'TowerId': _sData.LotTemplate.TowerId,
              'TowerFieldName': tower_name_Id,
              [tower_name_Id]: _sData.TowerName,
              'SubTowerId': _sData.LotTemplate.SubTowerId,
              'SubTowerFieldName': sub_tower_name_Id,
              [sub_tower_name_Id]: _sData.SubTowerName,
              'SubTowerColSpan': data[0].NoOfLots,
              'Group': 'SubTower',
              'subtowerdisable': disable_Header_Row,
              'Lots': []
            };

            Tot_Sub_Tower.push(sub_tow_oData);
          }

          if (!isNullOrUndefined(Tot_Tower) && Tot_Tower.length > 0) {

            let check_Tower = Tot_Tower.filter((x: any) => x.TowerId === _sData.LotTemplate.TowerId);

            if (isNullOrUndefined(check_Tower) || check_Tower.length === 0) {
              let tower_Data = {
                'TowerId': _sData.LotTemplate.TowerId,
                'TowerFieldName': tower_name_Id,
                [tower_name_Id]: _sData.TowerName,
                'TowerColSpan': data[0].NoOfSubTower * data[0].NoOfLots,
                'SubTower': Tot_Sub_Tower,
                'Group': 'Tower',
                'towerdisable': disable_Header_Row
              };

              Tot_Tower.push(tower_Data);
            }

          } else {
            let tower_Data = {
              'TowerId': _sData.LotTemplate.TowerId,
              'TowerFieldName': tower_name_Id,
              [tower_name_Id]: _sData.TowerName,
              'TowerColSpan': data[0].NoOfSubTower * data[0].NoOfLots,
              'SubTower': Tot_Sub_Tower,
              'Group': 'Tower',
              'towerdisable': disable_Header_Row
            };

            Tot_Tower.push(tower_Data);
          }

        });


      } else {

        if (this.selectedInfoData.AmApproach === 'APR004') {
          data[0].NoOfTower = 1;
          data[0].NoOfSubTower = 1;
          data[0].NoOfLots = 3;
        }

        for (let index = 0; index < data[0].NoOfTower; index++) {
          let towerId = index + 1;

          let tower_name_Id = 'TowerId' + towerId;

          let subTowerColumns = [];

          //Sub Tower
          for (let index_sub = 0; index_sub < data[0].NoOfSubTower; index_sub++) {
            let subTowerId = index_sub + 1;

            let sub_tower_name_Id = 'Sub_TowerId' + subTowerId;

            let sub_tower_ColSpan = data[0].NoOfLots;

            let sub_tow_oData = {
              'TowerId': towerId,
              'TowerFieldName': tower_name_Id,
              [tower_name_Id]: tower_name_Id,
              'SubTowerId': subTowerId,
              'SubTowerFieldName': sub_tower_name_Id,
              [sub_tower_name_Id]: null,
              'SubTowerColSpan': sub_tower_ColSpan,
              'Group': 'SubTower',
              'subtowerdisable': disable_Header_Row,
              'Lots': []
            };

            let lotsColumns: any = [];

            //Lots
            for (let index_lots = 0; index_lots < data[0].NoOfLots; index_lots++) {
              let lotsId = index_lots + 1;

              let lots_name_Id = 'Lots' + lotsId;

              let lots_value = this.selectedInfoData.AmApproach === 'APR004' && !isNullOrUndefined(this.Config.ITMetricLotsName)
                && this.Config.ITMetricLotsName.length > 0 ?
                this.Config.ITMetricLotsName[index_lots]
                : null;

              let lots_tow_oData: any = {
                'TowerId': towerId,
                'TowerFieldName': tower_name_Id,
                [tower_name_Id]: towerId,
                'SubTowerFieldName': sub_tower_name_Id,
                'SubTowerId': subTowerId,
                [sub_tower_name_Id]: sub_tower_name_Id,
                'LotsId': lotsId,
                'LotsFieldName': lots_name_Id,
                'Group': 'Lots',
                [lots_name_Id]: lots_value,
                'Tower': null,
                'SubTower': null,
                'Lot': lots_value,
                'ResourceLotDistributionPer': null,
                'TicketsLotDistributionPer': null,
                'UsersLotDistributionPer': null,
                'LotResourceCount': null,
                'LotTicketsCount': null,
                'LotUsersCount': null,
                'ClientResourceCountriesCount': null,
                'TotalResourceCount': null,
                'IsModified': 0,
                'lotdisable': disable_Header_Row,
                'lotresourcedisable': this.disableDynamicFields('RESOURCE'),
                'lotticketsdisable': this.disableDynamicFields('TICKET'),
                'usersbreakdownbylotdisable': this.disableDynamicFields('USER'),
                'itmetriclotnamedisable': this.disableDynamicFields('ITMETRIC')
              };

              if (this.showResourceLotDistribution) {
                let resource_lotsId = index_lots + 1;

                let resource_lots_name_Id = 'ResourceLot' + resource_lotsId;

                lots_tow_oData.ResourceLotFieldName = resource_lots_name_Id;

                lots_tow_oData[resource_lots_name_Id] = null;

              }

              if (this.showLotResourceCount) {
                let LotResource_lotsId = index_lots + 1;

                let LotResource_lots_name_Id = 'LotResource' + LotResource_lotsId;

                lots_tow_oData.LotResourceFieldName = LotResource_lots_name_Id;
                lots_tow_oData[LotResource_lots_name_Id] = null;

                if (this.selectedInfoData.AmApproach === 'APR004') {
                  lots_tow_oData.lotresourcedisable = true;
                  lots_tow_oData[LotResource_lots_name_Id] = lots_value === 'Apps Dev' ? this.appsDev : lots_value === 'Apps Maint' ? this.appsMain : lots_value === 'Others' ? this.Others : null;
                  lots_tow_oData.LotResourceCount = lots_value === 'Apps Dev' ? this.appsDev : lots_value === 'Apps Maint' ? this.appsMain : lots_value === 'Others' ? this.Others : null;
                } else {
                  lots_tow_oData[LotResource_lots_name_Id] = null;
                }
              }

              if (this.showCurrentClientResource) {
                let ClientResource_lotsId = index_lots + 1;

                let ClientResource_lots_name_Id = 'ClientResource' + ClientResource_lotsId;

                lots_tow_oData.ClientResourceFieldName = ClientResource_lots_name_Id;
                lots_tow_oData[ClientResource_lots_name_Id] = null;
              }

              if (this.showTicketLotDistribution) {
                let TicketLotDistribution_lotsId = index_lots + 1;

                let TicketLotDistribution_lots_name_Id = 'TicketLotDistribution' + TicketLotDistribution_lotsId;
                lots_tow_oData.TicketLotDistributionFieldName = TicketLotDistribution_lots_name_Id;
                lots_tow_oData[TicketLotDistribution_lots_name_Id] = null;
              }

              if (this.showLotTicketsCount) {
                let LotTickets_lotsId = index_lots + 1;

                let LotTickets_lots_name_Id = 'LotTickets' + LotTickets_lotsId;
                lots_tow_oData.LotTicketsFieldName = LotTickets_lots_name_Id;
                lots_tow_oData[LotTickets_lots_name_Id] = null;
                lots_tow_oData.PlaceHolder = this.showLotTicketsDefaultTitle ? 'Lot Tickets Count' :
                  (this.showLotsCountMonthly && !this.showLotTicketsDefaultTitle ? 'Lot Tickets Monthly Count' :
                    (!this.showLotsCountMonthly && !this.showLotTicketsDefaultTitle ? 'Lot Tickets Yearly Count' : ''));
              }

              if (this.showUserLotDistribution) {
                let UserLotDistribution_lotsId = index_lots + 1;

                let UserLotDistribution_lots_name_Id = 'UserLotDistribution' + UserLotDistribution_lotsId;
                lots_tow_oData.UserLotDistributionFieldName = UserLotDistribution_lots_name_Id;
                lots_tow_oData[UserLotDistribution_lots_name_Id] = null;
              }

              if (this.showLotUsersCount) {
                let LotUsers_lotsId = index_lots + 1;

                let LotUsers_lots_name_Id = 'LotUsers' + LotUsers_lotsId;
                lots_tow_oData.LotUsersFieldName = LotUsers_lots_name_Id;
                lots_tow_oData[LotUsers_lots_name_Id] = null;
              }

              lotsColumns.push(lots_tow_oData);

              Tot_Lots_Tower.push(lots_tow_oData);

            }

            sub_tow_oData.Lots = lotsColumns;

            Tot_Sub_Tower.push(sub_tow_oData);

            subTowerColumns.push(sub_tow_oData);

          }


          // let distict_Tower_Name = saved_Data.filter((v: { [x: string]: any; }, i: any, a: any[]) => a.findIndex((v2: { [x: string]: any; }) => ['DealSubLineItemId', 'TowerName'].every(k => v2[k] === v[k])) === i).map((x: { DealSubLineItemId: any; TowerName: any; }) => [x.DealSubLineItemId, x.TowerName]).sort((a: { DealSubLineItemId: number; TowerName: number; }, b: { DealSubLineItemId: number; TowerName: number; }) => (a.DealSubLineItemId - b.DealSubLineItemId || a.TowerName - b.TowerName));

          let tower_ColSpan = data[0].NoOfSubTower * data[0].NoOfLots;

          let tower_Data = {
            'TowerId': towerId,
            'TowerFieldName': tower_name_Id,
            [tower_name_Id]: null,
            'TowerColSpan': tower_ColSpan,
            'SubTower': subTowerColumns,
            'Group': 'Tower',
            'towerdisable': disable_Header_Row
          };

          Tot_Tower.push(tower_Data);
        }
      }

      this.TowerSplitData.Tot_Tower = Tot_Tower;
      this.TowerSplitData.Tot_Sub_Tower = Tot_Sub_Tower;
      this.TowerSplitData.Tot_Lots_Tower = Tot_Lots_Tower;
    }
  }

  validateTowerSplit(): boolean {

    let valid: boolean = false;
    let formatType = /^[-+]?[0-9]+\.[0-9]+$/
    let NoOfTowerTest = formatType.test(this.selectedInfoData.NoOfTower);
    let NoOfSubTowerTest = formatType.test(this.selectedInfoData.NoOfSubTower);
    let NoOfLotsTest = formatType.test(this.selectedInfoData.NoOfLots);
    let GovernanceTest = formatType.test(this.selectedInfoData.DeallevelGovernance);

    // Lots
    let lots_Modified_Data = this.TowerSplitData.Tot_Lots_Tower;
    if (NoOfTowerTest == true || NoOfSubTowerTest == true || NoOfLotsTest == true) {
      valid = true;
      this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
    }
    else if (!isNullOrUndefined(lots_Modified_Data) && lots_Modified_Data.length > 0) {

      //Tower Check
      let tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.Tower) || x.Tower === '');
      //Sub Tower Check
      let sub_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.SubTower) || x.SubTower === '');
      //Lots Check
      let lots_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.Lot) || x.Lot === '');
      //Resource Lots Check
      let Resource_lots_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.ResourceLotDistributionPer) || x.ResourceLotDistributionPer === '' || x.ResourceLotDistributionPer === 0);
      //Lots Resource Check
      let lots_resource_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.LotResourceCount) || x.LotResourceCount === '' || x.LotResourceCount === 0);
      //Current Client Resource Check
      let current_client_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.ClientResourceCountriesCount) || x.ClientResourceCountriesCount === '' || x.ClientResourceCountriesCount === 0);
      //Ticket Lot Distribution
      let ticket_lots_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.TicketsLotDistributionPer) || x.TicketsLotDistributionPer === '' || x.TicketsLotDistributionPer === 0);
      //Lot Tickets Count
      let lots_ticket_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.LotTicketsCount) || x.LotTicketsCount === '' || x.LotTicketsCount === 0);
      //User Lot Distribution
      let user_lots_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.UsersLotDistributionPer) || x.UsersLotDistributionPer === '' || x.UsersLotDistributionPer === 0);
      //Lot Users Count
      let lots_user_tower_Check = lots_Modified_Data.filter((x: any) => isNullOrUndefined(x.LotUsersCount) || x.LotUsersCount === '' || x.LotUsersCount === 0);



      if (!isNullOrUndefined(tower_Check) && tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill tower fields', 2, '', null, '', '', 1000);
      } else if (!isNullOrUndefined(sub_tower_Check) && sub_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill sub tower fields', 2, '', null, '', '', 1000);
      } else if (!isNullOrUndefined(lots_tower_Check) && lots_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill lots fields', 2, '', null, '', '', 1000);
      } else if (this.showResourceLotDistribution && !isNullOrUndefined(Resource_lots_tower_Check) && Resource_lots_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill resource lots fields', 2, '', null, '', '', 1000);
      } else if (this.showLotResourceCount && !isNullOrUndefined(lots_resource_tower_Check) && lots_resource_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill lot resource fields', 2, '', null, '', '', 1000);
      } else if (this.showCurrentClientResource && !isNullOrUndefined(current_client_tower_Check) && current_client_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill client resource fields', 2, '', null, '', '', 1000);
      } else if (this.showTicketLotDistribution && !isNullOrUndefined(ticket_lots_tower_Check) && ticket_lots_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill ticket lot distribution fields', 2, '', null, '', '', 1000);
      } else if (this.showLotTicketsCount && !isNullOrUndefined(lots_ticket_tower_Check) && lots_ticket_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill lot ticket fields', 2, '', null, '', '', 1000);
      } else if (this.showUserLotDistribution && !isNullOrUndefined(user_lots_tower_Check) && user_lots_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill user lot distribution fields', 2, '', null, '', '', 1000);
      } else if (this.showLotUsersCount && !isNullOrUndefined(lots_user_tower_Check) && lots_user_tower_Check.length > 0) {
        valid = true;
        this.showNotificationMessage('Please fill lot user fields', 2, '', null, '', '', 1000);
      }

      if (this.selectedInfoData.AmApproach === 'APR001' && this.selectedInfoData.ResourceBreakdownByLot !== 'CHKYES' && this.showResourceLotDistribution && !isNullOrUndefined(lots_Modified_Data) && lots_Modified_Data.length > 0) {
        let resourceCount: number = 0;
        lots_Modified_Data.forEach((item: any) => {
          resourceCount = !isNullOrUndefined(item.ResourceLotDistributionPer) && item.ResourceLotDistributionPer > 0 ? resourceCount + item.ResourceLotDistributionPer : 0;
        });

        if (resourceCount !== 100) {
          valid = true;
          this.showNotificationMessage('Resource lot distribution split should be equal to 100%', 2, '', null, '', '', 1000);
        }
      }

      if (this.selectedInfoData.AmApproach === 'APR002' && this.showLotTicketsCount
        && !isNullOrUndefined(this.selectedInfoData.NoOfTower) && !isNullOrUndefined(this.selectedInfoData.NoOfSubTower) &&
        !isNullOrUndefined(this.selectedInfoData.NoOfLots) && this.selectedInfoData.NoOfTower != ""
        && this.selectedInfoData.NoOfSubTower != "" && this.selectedInfoData.NoOfLots > 0
        && this.selectedInfoData.NoOfTower > 0
        && this.selectedInfoData.NoOfSubTower > 0 && this.selectedInfoData.NoOfLots > 0) {

        let check_TowerCount = this.selectedInfoData.NoOfTower * this.selectedInfoData.NoOfSubTower * this.selectedInfoData.NoOfLots;
        if (check_TowerCount > 25) {
          valid = true;
          this.showNotificationMessage('Multiplication of Tower, Sub Tower, Lots should be less than 25', 2, '', null, '', '', 1000);
        }

      }

      if (this.selectedInfoData.AmApproach === 'APR002' && this.selectedInfoData.ticketsbreakdownbylot !== 'CHKYES' && this.showLotTicketsCount && !isNullOrUndefined(lots_Modified_Data) && lots_Modified_Data.length > 0) {
        let ticketlotCount: number = 0;
        lots_Modified_Data.forEach((item: any) => {
          ticketlotCount = !isNullOrUndefined(item.TicketsLotDistributionPer) && item.TicketsLotDistributionPer > 0 ? ticketlotCount + item.TicketsLotDistributionPer : 0;
        });

        if (ticketlotCount !== 100) {
          valid = true;
          this.showNotificationMessage('Ticket lot distribution split should be equal to 100%', 2, '', null, '', '', 1000);
        }
      }

      if (this.selectedInfoData.AmApproach === 'APR003' && this.selectedInfoData.usersbreakdownbylot !== 'CHKYES' && this.showUserLotDistribution && !isNullOrUndefined(lots_Modified_Data) && lots_Modified_Data.length > 0) {
        let userlotCount: number = 0;
        lots_Modified_Data.forEach((item: any) => {
          userlotCount = !isNullOrUndefined(item.UsersLotDistributionPer) && item.UsersLotDistributionPer > 0 ? userlotCount + item.UsersLotDistributionPer : 0;
        });

        if (userlotCount !== 100) {
          valid = true;
          this.showNotificationMessage('Users lot distribution split should be equal to 100%', 2, '', null, '', '', 1000);
        }
      }

    }

    return valid;
  }

  frameSplitData(): any[] {

    let finalData: any = [];
    // Lots
    if (!isNullOrUndefined(this.TowerSplitData.Tot_Lots_Tower) && this.TowerSplitData.Tot_Lots_Tower.length > 0) {

      this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
        let oData: any = {
          'DealHeaderId': this.DealHeaderId,
          'Tower': item.Tower,
          'SubTower': item.SubTower,
          'Lot': item.Lot,
          'ResourceLotDistributionPer': !isNullOrUndefined(item.ResourceLotDistributionPer) && item.ResourceLotDistributionPer > 0 ? item.ResourceLotDistributionPer : 0,
          'TicketsLotDistributionPer': !isNullOrUndefined(item.TicketsLotDistributionPer) && item.TicketsLotDistributionPer > 0 ? item.TicketsLotDistributionPer : 0,
          'UsersLotDistributionPer': !isNullOrUndefined(item.UsersLotDistributionPer) && item.UsersLotDistributionPer > 0 ? item.UsersLotDistributionPer : 0,
          'LotResourceCount': !isNullOrUndefined(item.LotResourceCount) && item.LotResourceCount > 0 ? item.LotResourceCount : 0,
          'LotTicketsCount': !isNullOrUndefined(item.LotTicketsCount) && item.LotTicketsCount > 0 ? item.LotTicketsCount : 0,
          'LotUsersCount': !isNullOrUndefined(item.LotUsersCount) && item.LotUsersCount > 0 ? item.LotUsersCount : 0,
          'ClientResourceCountriesCount': !isNullOrUndefined(item.ClientResourceCountriesCount) && item.ClientResourceCountriesCount > 0 ? Number(item.ClientResourceCountriesCount.toFixed(0)) : 0,
          'TowerId': item.TowerId,
          'SubTowerId': item.SubTowerId,
          'LotsId': item.LotsId,
          'DealSubLineItemId': !isNullOrUndefined(item.DealSubLineItemId) && item.DealSubLineItemId > 0 ? item.DealSubLineItemId : 0
          // 'TotalResourceCount': item.TotalResourceCount,
        }

        finalData.push(oData);
      });
    }

    return finalData;
  }

  getModelConfigurationGridCalculationDetail(data: any, selectedInfoData: any, fieldname: any) {

    // TopDown Based
    if (selectedInfoData.AmApproach === 'APR001') {
      if (selectedInfoData.ResourceBreakdownByLot === 'CHKNO' && selectedInfoData.Hasperbreakdownbylottopdown === 'CHKYES') {
        // set @LotResourceCount= ( (@EnterTotalResourceCount) * @ResourceLotDistributionPer)
        // SELECT cast((@LotResourceCount / 100) as decimal(18,2)) as LotResourceCount;  

        let lotResourceCount = ((selectedInfoData.EnterTotalResourceCount * data.ResourceLotDistributionPer) / 100);

        data.LotResourceCount = lotResourceCount;
        data[fieldname] = data.LotResourceCount;

      } else if (selectedInfoData.ResourceBreakdownByLot === 'CHKNO' && selectedInfoData.Hasperbreakdownbylottopdown === 'CHKNO') {
        // set @LotResourceCount=@EnterTotalResourceCount
        // SELECT cast((@LotResourceCount) as decimal(18,2)) as LotResourceCount;

        let lotResourceCount = selectedInfoData.EnterTotalResourceCount;

        data.LotResourceCount = lotResourceCount;
        data[fieldname] = data.LotResourceCount;

      }
    }

    // Ticket Based
    if (selectedInfoData.AmApproach === 'APR002') {
      if (selectedInfoData.ticketsbreakdownbylot === 'CHKNO' && selectedInfoData.HasperbreakdownbylotTicketBased === 'CHKYES') {
        // set @LotTicketsCount= ( (@EnterTotalTicketCount) * @TicketsLotDistributionPer)
        // SELECT cast((@LotTicketsCount / 100) as decimal(18,2)) as LotTicketsCount;   

        let lotTicketsCount = ((selectedInfoData.EnterTotalTicketCount * data.TicketsLotDistributionPer) / 100);

        data.LotTicketsCount = lotTicketsCount;
        data[fieldname] = data.LotTicketsCount;

      } else if (selectedInfoData.ticketsbreakdownbylot === 'CHKNO' && selectedInfoData.HasperbreakdownbylotTicketBased === 'CHKNO') {
        // set @LotTicketsCount=@EnterTotalTicketCount
        // SELECT cast((@LotTicketsCount) as decimal(18,2)) as LotTicketsCount; 

        let lotTicketsCount = selectedInfoData.EnterTotalTicketCount;

        data.LotTicketsCount = lotTicketsCount;
        data[fieldname] = data.LotTicketsCount;

      }
    }

    // User Based 
    if (selectedInfoData.AmApproach === 'APR003') {
      if (selectedInfoData.usersbreakdownbylot === 'CHKNO' && selectedInfoData.HasPerBreakdownbyLot === 'CHKYES') {
        // set @LotUsersCount= ( (@EnterTotalUsersCount) * @UsersLotDistributionPer)
        // SELECT cast((@LotUsersCount / 100) as decimal(18,2)) as LotUsersCount;  

        let lotUsersCount = ((selectedInfoData.EnterTotalUsersCount * data.UsersLotDistributionPer) / 100);

        data.LotUsersCount = lotUsersCount;
        data[fieldname] = data.LotUsersCount;

      } else if (selectedInfoData.usersbreakdownbylot === 'CHKNO' && selectedInfoData.HasPerBreakdownbyLot === 'CHKNO') {
        // set @LotUsersCount=@EnterTotalUsersCount
        // SELECT cast((@LotUsersCount) as decimal(18,2)) as LotUsersCount; 

        let lotUsersCount = selectedInfoData.EnterTotalUsersCount;

        data.LotUsersCount = lotUsersCount;
        data[fieldname] = data.LotUsersCount;

      }
    }
  }

  block_TowerFields(data: any) {
    // Top Down Approach
    if (data.AmApproach === 'APR001') {
      if (data.ResourceBreakdownByLot == 'CHKNO' && data.Hasperbreakdownbylottopdown == 'CHKNO') {
        if (!isNullOrUndefined(this.ModelConfig_TowerSplit_Form)) {

          this.selectedInfoData.NoOfTower = 1;
          this.selectedInfoData.NoOfSubTower = 1;
          this.selectedInfoData.NoOfLots = 1;

          this.ModelConfig_TowerSplit_Form.enableFields('NoOfLots,NoOfSubTower,NoOfTower', true);
        }
      } else {
        if (!isNullOrUndefined(this.ModelConfig_TowerSplit_Form)) {
          this.ModelConfig_TowerSplit_Form.enableFields('NoOfLots,NoOfSubTower,NoOfTower', false);
        }
      }


      let splitData: any = [];
      splitData.push(this.selectedInfoData);

      this.towerSplit(splitData);

      this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
        item.lotresourcedisable = this.disableDynamicFields('RESOURCE');
        this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotResourceFieldName);
      });
    }

    // Ticket Based Approach
    if (data.AmApproach === 'APR002') {
      if (data.ticketsbreakdownbylot == 'CHKNO' && data.HasperbreakdownbylotTicketBased == 'CHKNO') {
        if (!isNullOrUndefined(this.ModelConfig_TowerSplit_Form)) {

          this.selectedInfoData.NoOfTower = 1;
          this.selectedInfoData.NoOfSubTower = 1;
          this.selectedInfoData.NoOfLots = 1;

          this.ModelConfig_TowerSplit_Form.enableFields('NoOfLots,NoOfSubTower,NoOfTower', true);
        }
      } else {
        if (!isNullOrUndefined(this.ModelConfig_TowerSplit_Form)) {
          this.ModelConfig_TowerSplit_Form.enableFields('NoOfLots,NoOfSubTower,NoOfTower', false);
        }
      }

      let splitData: any = [];
      splitData.push(this.selectedInfoData);

      this.towerSplit(splitData);

      this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
        item.lotticketsdisable = this.disableDynamicFields('TICKET');
        this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotTicketsFieldName);
      });
    }

    // No. of Users Based Approach
    if (data.AmApproach === 'APR003') {
      if (data.usersbreakdownbylot == 'CHKNO' && data.HasPerBreakdownbyLot == 'CHKNO') {
        if (!isNullOrUndefined(this.ModelConfig_TowerSplit_Form)) {

          this.selectedInfoData.NoOfTower = 1;
          this.selectedInfoData.NoOfSubTower = 1;
          this.selectedInfoData.NoOfLots = 1;

          this.ModelConfig_TowerSplit_Form.enableFields('NoOfLots,NoOfSubTower,NoOfTower', true);
        }
      } else {
        if (!isNullOrUndefined(this.ModelConfig_TowerSplit_Form)) {
          this.ModelConfig_TowerSplit_Form.enableFields('NoOfLots,NoOfSubTower,NoOfTower', false);
        }
      }

      let splitData: any = [];
      splitData.push(this.selectedInfoData);

      this.towerSplit(splitData);

      this.TowerSplitData.Tot_Lots_Tower.forEach((item: any) => {
        item.usersbreakdownbylotdisable = this.disableDynamicFields('USER');
        this.getModelConfigurationGridCalculationDetail(item, this.selectedInfoData, item.LotUsersFieldName);
      });
    }
  }

  disableDynamicFields(field: any): boolean {

    let disable_Flag = false;
    // Top Down Approach
    if ((field === 'RESOURCE' && this.selectedInfoData.AmApproach === 'APR001' && this.selectedInfoData.ResourceBreakdownByLot !== 'CHKYES') || this.selectedInfoData.DealSubLineItemId > 0) {
      disable_Flag = true;
    }
    // Ticket Based Approach
    else if ((field === 'TICKET' && this.selectedInfoData.AmApproach === 'APR002' && this.selectedInfoData.ticketsbreakdownbylot !== 'CHKYES') || this.selectedInfoData.DealSubLineItemId > 0) {
      disable_Flag = true;
    }
    // No. of Users Based Approach
    else if ((field === 'USER' && this.selectedInfoData.AmApproach === 'APR003' && this.selectedInfoData.usersbreakdownbylot !== 'CHKYES') || this.selectedInfoData.DealSubLineItemId > 0) {
      disable_Flag = true;
    }
    // IT Metrics Driven Approach
    else if ((field === 'ITMETRIC' && this.Metricsapproach && this.selectedInfoData.AmApproach === 'APR004') || this.selectedInfoData.DealSubLineItemId > 0) {
      disable_Flag = true;
    }

    return disable_Flag;
  }

  calcITMetric() {

    let apps_Dev = this.PopUpITMetricsGridData.filter((x: any) => x.ITDomains === 'App Development').map((a: any) => Number(a.ResourceCount))
    let apps_Main = this.PopUpITMetricsGridData.filter((x: any) => x.ITDomains === 'App Support').map((a: any) => Number(a.ResourceCount))
    let Other_Value = this.PopUpITMetricsGridData.filter((x: any) => x.ITDomains !== 'App Support' && x.ITDomains !== 'App Development').map((a: any) => Number(a.ResourceCount))

    let sum = 0;

    Other_Value.forEach((element: any) => {
      sum += element;
    });

    this.appsDev = !isNullOrUndefined(apps_Dev) && apps_Dev.length > 0 ? Number(apps_Dev[0].toFixed(2)) : 0;
    this.appsMain = !isNullOrUndefined(apps_Main) && apps_Main.length > 0 ? Number(apps_Main[0].toFixed(2)) : 0;
    this.Others = !isNullOrUndefined(sum) && sum > 0 ? Number(sum.toFixed(2)) : 0;

    this.towerSplit(this.ModelConfigFullData);
  }


  validateASISCOUNT(field: any): boolean {
    let disable_Flag = false;
    if (!isNullOrUndefined(field) && field.length > 0) {
      let ASIS_ZEROCOUNT = field.filter((x: any) => Number(x.AsIsResource) == 0);
      if (ASIS_ZEROCOUNT.length > 0) {
        this.showNotificationMessage('AS-IS Resource Count must be greater than 0', 2, '', '', '', '', 1000);
        disable_Flag = true;
      }
      if (ASIS_ZEROCOUNT.length == 0) {
        disable_Flag = false;
      }

    }
    return disable_Flag;
  }


  /////////////////////Navigate function///////////////////////


  navigateModelBaselining(bLoad: boolean = false) {
    //////// TOP DOWN AND IT METRICS APPROACH////////

    if (this.ModuleName == 'ModelBaseline' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {

      if (this.DealSubLineItemId > 0 && !isNullOrUndefined(this.DealLineItemId)) {


        //  if (this.ApproachName == 'APR001') {
        //    if (this.validateASISCOUNT(this.BaselineGridData)) {
        this.OpenContent = true;
        //   }
        //  else {            
        //this.ModuleName = 'ClientProfile';
        //  this._router.navigate([`app/model-config/client-resource-profile/${this.DealHeaderId}`], { state: { selectedData: this.selecteddata } });
        this.GetClientResourceData();
        // this.OpenContent = true;
        // }


      }
      else {
        this.IsLoading = false;
      }
    }

    else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {


      if (this.DealSubLineItemId > 0) {
        this.OpenContent = true;
        // this.GetAnticipatedDetails();
        this.GetClientResourceData();
      }
      else {
        this.IsLoading = false;
      }
    }


    else if (this.ModuleName == 'AnticipatedFTE' && (this.ApproachName == 'APR001' || this.ApproachName == 'APR004')) {

      this.OpenContent = false;
      if (this.DealSubLineItemId > 0) {

        this.GetAnticipatedDetails();

      }
      else {
        this.IsLoading = false;
      }


    }


    ////APPROACH  TICKET BASED//////

    else if (this.ModuleName == 'ModelBaseline' && (this.ApproachName == 'APR002')) {
      //this._appSharedService.currentDealData = this.selectedInfoData;
      //   this.ModuleName = 'ServiceProfile'


      if (this.DealSubLineItemId > 0) {

        this.OpenContent = false;
        this.GetServiceProfile();
      }
      else {
        this.IsLoading = false;
      }
    }



    else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR002')) {


      if (this.DealSubLineItemId > 0) {

        this.OpenContent = true;
        this.GetServiceProfile();

      }
      else {
        this.IsLoading = false;
      }
    }
    else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR002')) {



      if (this.DealSubLineItemId > 0) {
        this.GetClientResourceData();
        this.OpenContent = false;
      }
      else {
        this.IsLoading = false;
      }
    }
    else if (this.ModuleName == 'DeliveryModel' && (this.ApproachName == 'APR002')) {




      if (this.DealSubLineItemId > 0) {
        this.GetMBDeliveryLocationModelling();
        this.OpenContent = false;
      }
      else {
        this.IsLoading = false;
      }



    }


    ///////// USER BASED APPROACH//////

    else if (this.ModuleName == 'ModelBaseline' && (this.ApproachName == 'APR003')) {
      //   this.ModuleName = 'ServiceProfile'

      if (this.DealSubLineItemId > 0) {
        this.GetServiceProfile();
        this.OpenContent = false;
      }
      else {
        this.IsLoading = false;
      }


    }



    else if (this.ModuleName == 'ServiceProfile' && (this.ApproachName == 'APR003')) {

      if (this.DealSubLineItemId > 0) {
        this.GetServiceProfile();
        this.OpenContent = true;
      }
      else {
        this.IsLoading = false;
      }

    }


    else if (this.ModuleName == 'ClientProfile' && (this.ApproachName == 'APR003')) {

      if (this.DealSubLineItemId > 0) {
        this.GetClientResourceData();
        this.OpenContent = true;
      }
      else {
        this.IsLoading = false;
      }

    }

  }



  SaveDeliveryModel() {


    var resourceCount: number = 0;
    this.deliveryModelgridData.forEach((item: any) => {
      resourceCount = resourceCount + item.EffortDistributionPer;
    });


    if ((resourceCount > 100 || resourceCount < 100)) {
      this.toastr.warning("Distribution % by lot should be 100%");
    }
    else {
      this.InsertUpdateMBDeliveryLocationSAVE(2, this.deliveryModelgridData);
    }



  }
}
