import { isInRectangle } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { Component, ElementRef, NgZone } from '@angular/core';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { isUndefined } from 'ngx-bootstrap/chronos/utils/type-checks';
import { fromEvent, Subscription } from 'rxjs';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ActivatedRoute, NavigationEnd, Router } from '../../node_modules/@angular/router';
import { environment } from '../environments/environment';
import { AMApproach, ComponentCodes, ComponentConfig, MenuModel, ProcessStatus, ServiceDomain } from './app.interface';
import { AuthenticationService } from './core/auth/authentication.service';
import { LiteHomeService } from './home/lite-home/lite-home.service';
import { CisMainPageService } from './home/cis-main-page/cis-main-page.service';
import { CommonAPIService } from './shared/app.commonservice';
import { AppSharedService, isNullOrUndefined } from './shared/app.sharedservice';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalInterface } from 'src/modules/modal/modal.interface';



@Component({
  selector: 'app-main',
  templateUrl: './app-main.component.html'
})
export class AppMainComponent {
  title = 'P2W-UI';

  idleState = 'NOT_STARTED';
  timedOut = false;

  public userinfo: any = {};
  public userAccessInfo: any = {};
  public refURL: string = '';
  public dftTab: string = '';
  public module_data: any;
  public IsLite: boolean = false;
  public IsLiteOld: boolean = false;
  public displaySwitchUser: boolean = false;
  public SwitchUserAccess: boolean = false;
  public LandingPage: boolean = true;
  public displaySessionExp: boolean = false;
  public session: any = {};
  public AppIsLoading: boolean = false;
  public languageList: Array<string> = [];
  public subscriptionList$: Subscription[] = [];
  public AMDataModuleData: any;
  public menuListPermission: any = [];
  public menuListProperty: any = [];
  public menuList: any = [];
  public LotSolutionCondition: any = [];
  public bk_menuList: any = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public cisavailable: boolean = false;
  public showNoDataMessage: boolean = false;
  public showNoRequestMessage: boolean = false;
  public showNoMenuMessage: boolean = false;
  public litedashboard: boolean = false;
  public fieldRandomId: string;
  public IsCIS: boolean = false;
  public IsAM: boolean = false;
  public modalInf: ModalInterface = new ModalInterface();
  constructor(public _appSharedService: AppSharedService, public _authService: AuthenticationService,
    public translate: TranslateService, private element: ElementRef, private idle: Idle,
    private _router: Router, private _commonAPIService: CommonAPIService, private ngZone: NgZone,
    private oauthService: OAuthService, private _route: ActivatedRoute, private litehomeService: LiteHomeService,
    private CisMainPageService: CisMainPageService,private modalService: ModalService, 
    private toastr: ToastrService) {

    let subscribe$: Subscription = this._router.events.pipe(
      // filter(event => event instanceof NavigationStart)
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // change in routing
      // console.log('change in routing >> ');
      // console.log(event.url);
      if (event.url == '/app' || event.url == '/') {

        this._router.navigate(["app/home"]);
        this.LandingPage = true;
      }



    });



    if (!isNullOrUndefined(this._appSharedService.UserInfo)) {
      this._appSharedService.UserInfo$.subscribe(() => {
        this.ngOnInit();
      })
    }
    else {
      this.LandingPage = true;
      //let userid: number = 0;
      let userEmail: string = '';
      let Module: string = '';
      // Note: Special Handling is done in order to login contract employees who will 
      // have alphanumeric in their EmployeeNumber field from Ping SSO
      //userid = this.removeString(this._appSharedService.UserInfo.employeeNumber);

      userEmail = this._appSharedService.UserInfo.email;
      this.getUserModuleList(userEmail, Module);

    }


    console.log("fsfsgsagsg")
    console.log(this._appSharedService.UserInfo);

    this.fieldRandomId = Math.random().toString(36).substr(2, 5);

  }

  ngOnInit() {



    // this.refURL = environment.refURL;
    if (this._appSharedService.IsAuthUser) {
      this.showApp();

    }




  }


  showApp() {
    //   this._router.navigate(['landing-page']);
    let subscribe$_1: Subscription = this._appSharedService.UserInfo$.subscribe((data: any) => {
      this.userinfo = data;
      //////////////////////////////this.getLanguageList();
      this.getLanguageList();

    });
    this.subscriptionList$.push(subscribe$_1);

    let subscribe$_2: Subscription = this._appSharedService.AppIsLoading$.subscribe((data: any) => {
      this.AppIsLoading = data;
    });
    this.subscriptionList$.push(subscribe$_2);

    let subscribe$_3: Subscription = this._appSharedService.ApiToken$.subscribe((data: any) => {
      this.userAccessInfo = this._appSharedService.UserAccessInfo;
    });
    this.subscriptionList$.push(subscribe$_3);

    // this._appSharedService.SelectedLanguage$.subscribe((data:any) => {
    //   this.selectedLang = data.toString();
    //   this.selectedLang = (this.languageList.filter(x => x === this.selectedLang).length > 0 ? this.selectedLang :
    //     (!isNullOrUndefined(localStorage.getItem('currentLanguage')) ? localStorage.getItem('currentLanguage'): 'en-US'));
    //   this._appSharedService.selectedLang = this.selectedLang;

    //   translate.setDefaultLang(this.selectedLang);
    //   translate.use(this.selectedLang);
    // });


    // // initialise default language
    // this.getLanguage();

    //// the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('en');

    this.userinfo = this._appSharedService.UserInfo;
    this.userAccessInfo = this._appSharedService.UserAccessInfo;

    ///////////////////////////////this.getLanguageList();

    this.getLanguageList();

    console.log('userinfo app init >> ');
    console.log(this.userinfo);
    if (!isNullOrUndefined(this._appSharedService.UserInfo) && !isNullOrUndefined(this._appSharedService.UserAccessInfo)) {
      this.ngZone.runOutsideAngular(() => {
        setInterval(() => {
          // Changes here will not propagate into your view.
          this.ngZone.run(() => {
            console.log('check for token expiry - ' + new Date());
            this._authService.checkTokenExpiry();
          });
          //   // testing - 5 mins
          // }, 5 * 60 * 1000);
        }, environment.tokenexpiry * 60 * 1000);

      });

      this.setSessionExpiry();

      //let userid: number = 0;
      let userEmail: string = '';
      let Module: string = '';
      // Note: Special Handling is done in order to login contract employees who will 
      // have alphanumeric in their EmployeeNumber field from Ping SSO
      //userid = this.removeString(this._appSharedService.UserInfo.employeeNumber);

      userEmail = this._appSharedService.UserInfo.email;
      this.getUserModuleList(userEmail, Module);
    }




  }

  removeString(value: string) {
    var numsStr = value.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  }

  openSwitchUser(): void {
    this.displaySwitchUser = true;
  }

  closeSwitchUser(reLoad?: any): void {
    if (!isNullOrUndefined(reLoad) && reLoad.reLoad === true) {



      // this._router.navigate(["app/home"]).then(() => {
      //   //window.location.reload();
      //   ////////////////this.getUserModuleList(this._appSharedService.UserAccessInfo.actingemail);
      //   this.getUserRoleList(this._appSharedService.UserAccessInfo.actingemail);
      // });
      this.getUserRoleList(this._appSharedService.UserAccessInfo.actingemail);
    }

    this.displaySwitchUser = false;
  }

  openSessionPopup(): void {
    this.displaySessionExp = true;
  }

  closeSessionPopup(event: any): void {
    this.displaySessionExp = false;

    if (event.extend) {
      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);
          window.location.reload();
        }
        else {
          this._router.navigate(['app/home']);
          window.location.reload();
        }
      } else {
        this._router.navigate(['app/home']);
        window.location.reload();

      }
      this.resetSession(true);
    }
    else {
      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);
          window.location.reload();
        }
        else {
          this._router.navigate(['app/home']);
          window.location.reload();
        }
      } else {
        this._router.navigate(['app/home']);
        window.location.reload();
      }
      this.endSession();
    }
  }

  setSessionExpiry(): void {
    // sets an idle timeout of 15 minutes.
    this.idle.setIdle(environment.sessionexpiry * 60);

    // sets a timeout period of 5 minutes.
    this.idle.setTimeout(environment.sessionexpirywarning * 60);

    // // for testing
    // this.idle.setIdle(1 * 60);
    // this.idle.setTimeout(.5 * 60);

    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);
    // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NO_LONGER_IDLE';
      console.log('session no longer idle');
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      console.log('session timeout');
      //  this._router.navigate(['app/home']);
      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);
          window.location.reload();

        }
        else {
          this._router.navigate(['app/home']);
          window.location.reload();

        }
      }
      else {
        this._router.navigate(['app/home']);
        window.location.reload();

      }
      this.idle.onIdleStart.unsubscribe();

      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);
          window.location.reload();
        }

        else {
          this._router.navigate(['app/home']);
          window.location.reload();
        }
      }
      else {
        this._router.navigate(['app/home']);
        window.location.reload();
      }
      this.closeSessionPopup({ extend: false });
    });

    this.idle.onIdleStart.subscribe(() => {
      this.session = { count: 1 };
      this.idleState = 'IDLE_START', this.openSessionPopup();
      console.log('session idle start');
      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);
          window.location.reload();
        }

        else {
          this._router.navigate(['app/home']);
          window.location.reload();
        }
      }
      else {
        this._router.navigate(['app/home']);
        window.location.reload();
      }
    });

    this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = 'IDLE_TIME_IN_PROGRESS';
      this.session = {
        count: (Math.floor((countdown - 1) / 60) + 1),
        progressCount: this.reverseNumber(countdown),
        countMinutes: (Math.floor(countdown / 60)),
        countSeconds: countdown % 60,
        maxCount: this.idle.getTimeout()
      };


      console.log('session idle in-progress');
    });

    this.resetSession();
  }

  // ngOnDestroy() {
  //   //this.endSession();
  // }

  reverseNumber(countdown: number) {
    // return (5*60 - (countdown - 1));
    return (this.idle.getTimeout() - (countdown - 1));
  }

  CISHomeButtonClick() {
    this._router.navigate(["app/cis-home-page"]);
  }

  resetSession(withsave?: boolean) {
    console.log('session reset');

    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;

    if (withsave) {
      let curcomp = this._appSharedService.getCurrentComponent();
      if (!isNullOrUndefined(curcomp)) {
        // let element = document.getElementsByTagName(curcomp['Component']);
        let compref: any = curcomp['ComponentReference'];
        compref.saveClick();
      }
      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);

        }
        else {
          this._router.navigate(['app/home']);

        }
      } else {
        this._router.navigate(['app/home']);

      }
    }
    else {
      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
        if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
          this._router.navigate(['app/lite-home']);

        }
        else {
          this._router.navigate(['app/home']);

        }
      } else {
        this._router.navigate(['app/home']);

      }
    }
    // else if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
    //   if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
    //     this._router.navigate(['app/lite-home']);
    //   }
    // }
    // else {
    //   this._router.navigate(['app/home']);
    // }
  }

  endSession() {
    console.log('session end');
    // this._router.navigate(['app/home']);
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();

    // window.close();
    //this._router.navigate(['sessionexpired']);
    if (!isNullOrUndefined(this._appSharedService.currentUserModuleData)) {
      if (this._appSharedService.currentUserModuleData.CodeDescription == 'P2W Lite') {
        this._router.navigate(['app/lite-home']);
        window.location.reload();
      }
      else {
        this._router.navigate(['app/home']);
        window.location.reload();
      }
    } else {
      this._router.navigate(['app/home']);
      window.location.reload();
    }
  }

  getLanguageList(): void {
    let subscribe$: Subscription = this._commonAPIService.getLanguage().subscribe((result: any) => {
      if (!isNullOrUndefined(result) && result.Status === 200) {

        this._appSharedService.LanguageDetail = result.Data;

        result.Data.forEach((element: any) => {
          if (this.languageList.filter(x => x === element.Code).length === 0) {
            this.languageList.push(element.Code);
          }
        });
        this.translate.addLangs(this.languageList);
        // //// this language will be used as a fallback when a translation isn't found in the current language
        // if (!isNullOrUndefined(this.selectedLang)) {
        //   this.translate.setDefaultLang(this.selectedLang);
        //   // translate.use(this.lang.match(/en-US|en-IN|de|fr/) ? this.lang : 'en-US');
        //   // this.selectedLang = (this.selectedLang.match(/en-US/) ? this.selectedLang : 'en-US');
        // }
        // else {
        //   this.setDefaultLanguage();
        // }

        // this.translate.use(this.selectedLang);

        let lang = (!isNullOrUndefined(this._appSharedService.urlLang)) ?
          this._appSharedService.urlLang : this._appSharedService.selectedLang;

        this.setLanguage(lang);

      }
      else {
        this.setLanguage('');
      }
    },
      (err: any) => {
        this.setLanguage('');
        console.log('Error on getting language list.');
      });
    this.subscriptionList$.push(subscribe$);
  }




  setLanguage(langCode: string): void {

    // First Preference:  lang given in URL
    // Second Preference: lang stored in localStorage
    // Third Preference:  lang based on user location (received in user token)
    // Fourth Preference: en-US

    let lang: any = langCode;

    lang = (!isNullOrUndefined(this.languageList) && this.languageList.filter(x => x === lang).length > 0) ? lang : '';

    if (isNullOrUndefined(lang) || lang.length === 0) {
      if (!isNullOrUndefined(localStorage.getItem('currentLanguage'))) {
        lang = localStorage.getItem('currentLanguage');
      }
      else {
        if (!isNullOrUndefined(this._appSharedService.UserInfo)
          && !isNullOrUndefined(this._appSharedService.UserInfo.language)) {
          lang = this._appSharedService.UserInfo.language;
        }
      }
    }

    // Still nothing is set, then set default as 'en-US'
    if (lang.length === 0) { lang = 'en-US'; }

    // at this stage, language is always set
    this._appSharedService.selectedLang = lang;

    this.translate.use(lang);
    this.translate.setDefaultLang(lang);

    // save language to localStorage
    localStorage.setItem('currentLanguage', lang);

    // finally set the date format for the selected language
    if (this._appSharedService.LanguageDetail.filter((x: any) => x.Code === lang).length > 0) {
      this._appSharedService.AppDateTimeFormat = this._appSharedService.LanguageDetail.filter((x: any) => x.Code === lang)[0].UserDef1;
      this._appSharedService.AppDateFormat = this._appSharedService.AppDateTimeFormat.substr(0,
        this._appSharedService.AppDateTimeFormat.indexOf(' '));
    }

    // Custom property added in the translate service
    // this.translate['AppDateTimeFormat'] = this._appSharedService.AppDateTimeFormat;
    // this.translate['AppDateFormat'] = this._appSharedService.AppDateFormat;
    this.translate = Object.assign(this.translate, { 'AppDateTimeFormat': this._appSharedService.AppDateTimeFormat });
    this.translate = Object.assign(this.translate, { 'AppDateFormat': this._appSharedService.AppDateFormat });
  }

  onLanguageClick(lang: any): void {
    // this._appSharedService.SelectedLanguage$.next(lang);
    // localStorage.setItem('currentLanguage', lang);
    this.setLanguage(lang);
    window.location.reload();
  }

  getAppTemplate(): void {

    this.AppIsLoading = true;
    this.showNoMenuMessage = false;

    if (this.IsLite) {
      let odata: ComponentConfig = {
        Component: 'LiteAppComponent', ComponentCode: ComponentCodes.LiteAppComponent
      };
      let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
        if (res !== undefined && res !== null && res.Status === 200) {

          this.menuList = res.Data.FieldTemplate;

          console.log('Tab List Template >> ' + JSON.stringify(this.menuList));

          this.menuListPermission = res.Data.Permission;
          this.menuListProperty = res.Data.Properties;

          this.menuList = (this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'LiteMenuList'.toLowerCase()).length > 0 ?
            JSON.parse(this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'LiteMenuList'.toLowerCase())[0].JSONData) : []);

          this.menuList.forEach((item: any) => {
            // let menuitem = this.menuList.filter((x: any) => x[this.menuListPermission['MenuList_' + item.Name + '_Permission']]);
            item.HasPermission = false;
            let peritem = this.menuListPermission.indexOf('MenuList_' + item.Name + '_Permission');
            if (peritem >= 0) {
              item.HasPermission = true;
            }
            if (item.SubMenu.length > 0) {
              // this.menuList.forEach((submenuitem: any) => {
              item.SubMenu.forEach((submenuitem: any) => {
                submenuitem.HasPermission = false;
                let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
                if (subperitem >= 0) {
                  submenuitem.HasPermission = true;
                }
              });
            }
          });


          if (this.menuList.filter((x: any) => x.HasPermission === true).length == 0) {
            this.showNoMenuMessage = true;
          }
          else {
            var firstMenu = this.menuList.filter((x: any) => x.HasPermission === true)[0];

            if (firstMenu.SubMenu.filter((x: any) => x.HasPermission === true).length > 0) {
              let rout = firstMenu.SubMenu.filter((x: any) => x.HasPermission === true)[0].RouteLink;

              this.dftTab = firstMenu.SubMenu.filter((x: any) => x.HasPermission === true)[0].Name;
              // this._router.navigateByUrl(rout.replace(".", "app/lite-home"));
              this._router.navigate(["app/lite-home"]);
            }
            else {
              let rout = firstMenu.RouteLink;

              this.dftTab = firstMenu.Name;
              this._router.navigate(["app/lite-home"]);
              // this._router.navigateByUrl(rout.replace(".", "app/lite-home"));
            }
          }
        }
        this.AppIsLoading = false;
      },
        err => {
          this.AppIsLoading = false;
          console.log('Error on getHomeTemplate.');
        });
      this.subscriptionList$.push(subscribe$);

    }

    else if (this.IsAM) {
      let odata: ComponentConfig = {
        Component: 'AMAppComponent', ComponentCode: ComponentCodes.AMAppComponent
      };
      let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
        if (res !== undefined && res !== null && res.Status === 200) {

          this.menuList = res.Data.FieldTemplate;

          console.log('Tab List Template >> ' + JSON.stringify(this.menuList));

          this.menuListPermission = res.Data.Permission;
          this.menuListProperty = res.Data.Properties;

          this.menuList = (this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'AMMenuList'.toLowerCase()).length > 0 ?
            JSON.parse(this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'AMMenuList'.toLowerCase())[0].JSONData) : []);

          this.LotSolutionCondition = JSON.parse(`[{"AmApproach":"APR001","AmApproachDesc":"Top Down Approach","AMStatus":"AM3.4","AmDealProgress":6,"Group":"LotSolution"},{"AmApproach":"APR002","AmApproachDesc":"Ticket Based Approach","AMStatus":"AM3.3","AmDealProgress":5,"Group":"LotSolution"},{"AmApproach":"APR003","AmApproachDesc":"No. of Users Based Approach","AMStatus":"AM3.2","AmDealProgress":4,"Group":"LotSolution"},{"AmApproach":"APR004","AmApproachDesc":"IT Metrics Driven Approach","AMStatus":"AM3.4","AmDealProgress":6,"Group":"LotSolution"},{"AmApproach":"","AmApproachDesc":"","AMStatus":"AM4.4","AmDealProgress":10,"Group":"LotPricingEnhancement"},{"AmApproach":"","AmApproachDesc":"","AMStatus":"AM4.3","AmDealProgress":9,"Group":"LotPricingNoEnhancement"}]`);
          // this.menuList.forEach((item: any) => {
          //   // let menuitem = this.menuList.filter((x: any) => x[this.menuListPermission['MenuList_' + item.Name + '_Permission']]);
          //   item.HasPermission = false;
          //   let peritem = this.menuListPermission.indexOf('MenuList_' + item.Name + '_Permission');

          //   let dealData = window.history.state.data;

          //   if (item.Name === 'Process' && !isNullOrUndefined(dealData)) {
          //     if (peritem >= 0) {
          //       item.HasPermission = true;
          //     }
          //     if (item.SubMenu.length > 0) {
          //       // this.menuList.forEach((submenuitem: any) => {
          //       item.SubMenu.forEach((submenuitem: any) => {
          //         submenuitem.HasPermission = false;
          //         let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
          //         if (subperitem >= 0) {
          //           submenuitem.HasPermission = true;
          //         }

          //         if (submenuitem.SubMenu.length > 0) {
          //           submenuitem.SubMenu.forEach((submenuitems: any) => {
          //             submenuitems.HasPermission = false;
          //             let subperitems = this.menuListPermission.indexOf('MenuList_' + submenuitems.Name + '_Permission');
          //             if (subperitems >= 0) {
          //               submenuitems.HasPermission = true;
          //             }
          //           });
          //         }
          //       });


          //     }
          //   } else {

          //     if (peritem >= 0) {
          //       item.HasPermission = true;
          //     }
          //     if (item.SubMenu.length > 0) {
          //       // this.menuList.forEach((submenuitem: any) => {
          //       item.SubMenu.forEach((submenuitem: any) => {
          //         submenuitem.HasPermission = false;
          //         let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
          //         if (subperitem >= 0) {
          //           submenuitem.HasPermission = true;
          //         }

          //         if (submenuitem.SubMenu.length > 0) {
          //           submenuitem.SubMenu.forEach((submenuitems: any) => {
          //             submenuitems.HasPermission = false;
          //             let subperitems = this.menuListPermission.indexOf('MenuList_' + submenuitems.Name + '_Permission');
          //             if (subperitems >= 0) {
          //               submenuitems.HasPermission = true;
          //             }
          //           });
          //         }
          //       });


          //     }
          //   }
          // });
          this.bk_menuList = JSON.parse(JSON.stringify(this.menuList));
          this.menuListReconfigure();

          if (this.menuList.filter((x: any) => x.HasPermission === true).length == 0) {
            this.showNoMenuMessage = true;
          }
          else {
            var firstMenu = this.menuList.filter((x: any) => x.HasPermission === true)[0];

            if (firstMenu.SubMenu.filter((x: any) => x.HasPermission === true).length > 0) {
              let rout = firstMenu.SubMenu.filter((x: any) => x.HasPermission === true)[0].RouteLink;

              this.dftTab = firstMenu.SubMenu.filter((x: any) => x.HasPermission === true)[0].Name;
              // this._router.navigateByUrl(rout.replace(".", "app/lite-home"));
              this._router.navigate(["app/home"]);
            }
            else {
              let rout = firstMenu.RouteLink;

              this.dftTab = firstMenu.Name;
              this._router.navigate(["app/home"]);
              // this._router.navigateByUrl(rout.replace(".", "app/lite-home"));
            }
          }
        }
        this.AppIsLoading = false;
      },
        err => {
          this.AppIsLoading = false;
          console.log('Error on getHomeTemplate.');
        });
      this.subscriptionList$.push(subscribe$);
    }

    else {

      let odata: ComponentConfig = {
        Component: 'AppComponent', ComponentCode: ComponentCodes.AppComponent
      };
      let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
        if (res !== undefined && res !== null && res.Status === 200) {

          this.menuList = res.Data.FieldTemplate;

          console.log('Tab List Template >> ' + JSON.stringify(this.menuList));

          this.menuListPermission = res.Data.Permission;
          this.menuListProperty = res.Data.Properties;

          this.menuList = (this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'MenuList'.toLowerCase()).length > 0 ?
            JSON.parse(this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'MenuList'.toLowerCase())[0].JSONData) : []);

          this.menuList.forEach((item: any) => {
            // let menuitem = this.menuList.filter((x: any) => x[this.menuListPermission['MenuList_' + item.Name + '_Permission']]);
            item.HasPermission = false;
            let peritem = this.menuListPermission.indexOf('MenuList_' + item.Name + '_Permission');
            if (peritem >= 0) {
              item.HasPermission = true;
            }
            if (item.SubMenu.length > 0) {
              // this.menuList.forEach((submenuitem: any) => {
              item.SubMenu.forEach((submenuitem: any) => {
                submenuitem.HasPermission = false;
                let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
                if (subperitem >= 0) {
                  submenuitem.HasPermission = true;
                }
              });
            }
          });


          if (this.menuList.filter((x: any) => x.HasPermission === true).length == 0) {
            this.showNoMenuMessage = true;
          }
          else {
            var firstMenu = this.menuList.filter((x: any) => x.HasPermission === true)[0];

            if (firstMenu.SubMenu.filter((x: any) => x.HasPermission === true).length > 0) {
              let rout = firstMenu.SubMenu.filter((x: any) => x.HasPermission === true)[0].RouteLink;

              this.dftTab = firstMenu.SubMenu.filter((x: any) => x.HasPermission === true)[0].Name;
              //this._router.navigateByUrl(rout.replace(".", "app"));
              this._router.navigate(["app/home"]);
            }
            else {
              let rout = firstMenu.RouteLink;

              this.dftTab = firstMenu.Name;
              // this._router.navigate(['app/home']);
              this._router.navigate(["app/cis-home-page"]);
              // this._router.navigateByUrl(rout.replace(".", "app"));
            }
          }
        }
        this.AppIsLoading = false;
      },
        err => {
          this.AppIsLoading = false;
          console.log('Error on getHomeTemplate.');
        });
      this.subscriptionList$.push(subscribe$);
    }
  }

  onRouteClick(parenttab: any) {
    this._appSharedService.containerData.FilterKey = '';
    if (parenttab.DisplayName == 'Dashboard') {
    }
    else {
    }
  }

  getSelectedSubTabName(tab: any): string {
    let str = '';
    let subtab = tab.SubMenu.filter((x: any) => x.Active === true);
    if (tab.Active === true && subtab.length > 0) {
      str = subtab[0].DisplayName;
    }
    return str;
  }

  getUserRoleList(userEmail: string): void {
    this.AppIsLoading = true;
    this.showNoDataMessage = false;
    this.showNoRequestMessage = false;


    let subscribe$: Subscription = this._commonAPIService.getUserRole(userEmail).subscribe(async (result: any) => {

      if (result.Data === null && result.Status === ProcessStatus.Success) {
        this.AppIsLoading = false;
        this.showNoDataMessage = true;
      }

      else if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this._appSharedService.UserRoleList = result.Data;

        // this._appSharedService.UserRoleList = [];

        if (!isNullOrUndefined(this._appSharedService.UserRoleList) && this._appSharedService.UserRoleList.length > 0) {
          this._appSharedService.currentRoleData = result.Data[0];
          this._appSharedService.currentRoleData$.next(this._appSharedService.currentRoleData);

          this.AppIsLoading = false;
          this.getAppTemplate();

        }
        else {
          this.AppIsLoading = false;

          this.showNoDataMessage = true;
        }

      }
      else {
        this.AppIsLoading = false;

        this.showNoDataMessage = true;
      }

      // this.AppIsLoading = false;
    },
      (err: any) => {
        this.setLanguage('');
        console.log('Error on getting User Role list.');
        this.AppIsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  getUserModuleList(userEmail: string, Module: string): void {

    this.AppIsLoading = true;
    this.showNoDataMessage = false;
    this.showNoRequestMessage = false;

    let subscribe$: Subscription = this._commonAPIService.getUserModuleList(userEmail, Module).subscribe(async (result: any) => {

      if (result.Data === null && result.Status === ProcessStatus.Success) {
        this.AppIsLoading = false;
        this.showNoDataMessage = true;
      }
      else if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this._appSharedService.UserModuleList = result.Data;
        console.log(this._appSharedService.UserModuleList);

        if (!isNullOrUndefined(this._appSharedService.UserModuleList) && this._appSharedService.UserModuleList.length > 0) {
          this._appSharedService.currentUserModuleData = result.Data[0];
          this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);

          this.AppIsLoading = false;
          if (this._appSharedService.currentUserModuleData.Code == 'AD') {

            window.open(result.Data[0].UserDef2, '_self');
          }

          if (this._appSharedService.currentUserModuleData.Code == 'CIS') {
            this.IsCIS = true;
            this.IsLite = false;
            this.IsAM = false;
          }
          else if (this._appSharedService.currentUserModuleData.Code == 'AM') {
            this.IsCIS = false;
            this.IsLite = false;
            this.IsAM = true;
          }
          else if (this._appSharedService.currentUserModuleData.Code == 'LITE') {
            this.IsCIS = false;
            this.IsLite = true;
            this.IsAM = false;
          }

          if (this._appSharedService.UserModuleList.length == 1) {

            // if (this._appSharedService.currentUserModuleData.Code != 'CIS' && this._appSharedService.currentUserModuleData.Code != 'LITE') {
            //   window.open(this._appSharedService.currentUserModuleData.UserDef2, '_blank');

            if (this._appSharedService.currentUserModuleData.Code != 'CIS' && this._appSharedService.currentUserModuleData.Code != 'LITE') {
              window.open(this._appSharedService.currentUserModuleData.UserDef2, '_self');
            }
            if (this._appSharedService.currentUserModuleData.Code == 'CIS') {
              this.IsCIS = true;
              this.IsLite = false;
              this.IsAM = false;
            }
            else if (this._appSharedService.currentUserModuleData.Code == 'AM') {
              this.IsLite = false;
              this.IsCIS = false;
              this.IsAM = true;
              //this._router.navigate(["app/home"]);
              //window.open(this._appSharedService.currentUserModuleData.UserDef2, '_self');
            }
            else if (this._appSharedService.currentUserModuleData.Code == 'LITE') {
              this.IsLite = true;
              this.IsCIS = false;
              this.IsAM = false;
              //this._router.navigate(["app/lite-home"]);
            }
          }
          else if (this._appSharedService.UserModuleList.length == 0) {

          }


        }
        else {
          this.AppIsLoading = false;
          this.showNoDataMessage = true;
        }
      }
      else {
        this.AppIsLoading = false;
        this.showNoDataMessage = true;
      }

      this.getUserRoleList(userEmail);

    },
      (err: any) => {
        this.setLanguage('');
        console.log('Error on getting User Role list.');
        this.AppIsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  roleChange(roleData: any): void {
    this._appSharedService.currentRoleData = roleData;
    this._appSharedService.currentRoleData$.next(this._appSharedService.currentRoleData);
    this.getAppTemplate();

  }
  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';
    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'NEXTTAB':
        if (event.result.toUpperCase() === 'OK') {
          this._appSharedService.currentUserModuleData = event.item;
          this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);

          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);

          if (this._appSharedService.currentUserModuleData.Code != 'CIS' && this._appSharedService.currentUserModuleData.Code != 'LITE') {
            window.open(this._appSharedService.currentUserModuleData.UserDef2, '_self');
          }

          if (event.item.Code == 'CIS') {
            this.IsCIS = true;
            this.IsLiteOld = false;
            this.IsLite = false;
            this.IsAM = false;
          }

          if (event.item.Code == 'AM') {
            this.IsCIS = false;
            this.IsLiteOld = false;
            this.IsLite = false;
            this.IsAM = true;
          }

          if (event.item.Code == 'LITE') {
            this.IsLite = true;
            this.IsLiteOld = false;
            this.IsCIS = false;
            this.IsAM = false;
            this._router.navigate(["app/lite-home"]);
          }
          if (event.item.Code == 'LITE-OLD') {
            this.IsLite = false;
            this.IsLiteOld = true;
            this.IsCIS = false;
            this.IsAM = false;
            this._router.navigate(["app/dashboard"]);
            this.getAppTemplate();
          }
          this.getAppTemplate();
        }

        break;

      case 'LOGO-CLICK':
        if (event.result.toUpperCase() === 'OK') {
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
          this._router.navigate(["app/home"]);
        }
        break;

    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  onlogoclick() {
    if (this.IsCIS == true) {
      this._router.navigate(["app/cis-home-page"]);
    }
    else if (this._appSharedService.IsRowModified == true) {
      console.log("row modified")
      this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'LOGO-CLICK', '', 'Cancel', 'OK', 10000);
    }
    else {
      this._router.navigate(["app/home"]);
    }
  }
  ModuleChange(moduleData: any): void {
    console.log(moduleData.Code, "%%")
    //this._appSharedService.currentUserModuleData = moduleData;
    //this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);

    if (this._appSharedService.IsRowModified == true) {
      console.log("row modified")
      this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'NEXTTAB', moduleData, 'Cancel', 'OK', 10000);
    }
    else {
      this._appSharedService.currentUserModuleData = moduleData;
      this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);

      if (this._appSharedService.currentUserModuleData.Code != 'CIS' && this._appSharedService.currentUserModuleData.Code != 'LITE') {
        window.open(this._appSharedService.currentUserModuleData.UserDef2, '_self');
      }

      if (moduleData.Code == 'CIS') {
        this.IsCIS = true;
        this.IsLiteOld = false;
        this.IsLite = false;
        this.IsAM = false;
        this._router.navigate(["app/dashboard"]);
        this.getAppTemplate();
      }

      if (moduleData.Code == 'AM') {
        this.IsLite = false;
        this.IsLiteOld = false;
        this.IsCIS = false;
        this.IsAM = true;
        this._router.navigate(["app/dashboard"]);
        this.getAppTemplate();
        //window.open(this._appSharedService.currentUserModuleData.UserDef2, '_self');
      }
      if (moduleData.Code == 'LITE') {
        this.IsLite = true;
        this.IsLiteOld = false;
        this.IsCIS = false;
        this.IsAM = false;
        this._router.navigate(["app/dashboard"]);
        this.getAppTemplate();
      }
      if (moduleData.Code == 'LITE-OLD') {
        this.IsLite = false;
        this.IsLiteOld = true;
        this.IsCIS = false;
        this.IsAM = false;
        this._router.navigate(["app/dashboard"]);
        this.getAppTemplate();
      }
    }
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  ModuleChange_Mainscreen(moduleData: any): void {

   // let AMDataModuleData: any;
    //  if (this._appSharedService.UserModuleList.length != 0) {
    this.AMDataModuleData = this._appSharedService.UserModuleList.filter((x: any) => x.Code == moduleData);
    //  }
    // else
    // {
    //   let userEmail: string = '';
    //   let Module: string = '';
    //   userEmail = this._appSharedService.UserInfo.email;
    //   this.getUserModuleList(userEmail,Module );
    // }



    console.log(this.AMDataModuleData);
    if (isNullOrUndefined(this.AMDataModuleData) || this.AMDataModuleData.length == 0) {
      if (moduleData == 'CIS') {
        this.toastr.warning(`<p class="lefter">

        You do not have access for this module. Please reach out to  
       
        
         <a href="mailto:cisbbs.in@capgemini.com?subject=P2W%20Access%20Needed&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ASBU (UK, NON-UK, Americas, APAC, Financial services):%0D%0A%0D%0ACountry:%0D%0A%0D%0AThank%20you!
         "target="_blank"><u>CIS Team.</u></a></p>`);
      }
      if (moduleData == 'AD') {
        // this.toastr.warning(`<p class="lefter">

        // You do not have access for this module. Please reach out to  <a href="mailto:p2wadmin.cor@capgemini.com?subject=P2W%20Access%20Needed&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ASBU%20(UK,%20NON-UK,%20Americas,%20APAC,%20Financial services):%0D%0A%0D%0ACountry:%0D%0A%0D%0AModules%20(AM,%20AD,%20P2W LITE,%20P2W PCP):%0D%0A%0D%0AThank%20you!
        // "target="_blank"><u>AM Team.</u></a>
        
  
        // </p>`);

        this.toastr.warning(`<p class="lefter">

        You do not have access for this module. Please reach out to  <a href="mailto:p2wadmin.cor@capgemini.com?subject=P2W%20Access%20Needed&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ASBU (UK, NON-UK, Americas, APAC, Financial services):%0D%0A%0D%0ACountry:%0D%0A%0D%0AModules (AM, AD, P2W LITE, P2W PCP):%0D%0A%0D%0AThank%20you!
        "target="_blank"><u>AM Team.</u></a></p>`);
      }

      if (moduleData == 'AM') {
        this.toastr.warning(`<p class="lefter">

        You do not have access for this module. Please reach out to  <a href="mailto:p2wadmin.cor@capgemini.com?subject=P2W%20Access%20Needed&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ASBU (UK, NON-UK, Americas, APAC, Financial services):%0D%0A%0D%0ACountry:%0D%0A%0D%0AModules (AM, AD, P2W LITE, P2W PCP):%0D%0A%0D%0AThank%20you!
        "target="_blank"><u>AM Team.</u></a></p>`);
      }

      if (moduleData == 'LITE') {
        this.toastr.warning(`<p class="lefter">

        You do not have access for this module. Please reach out to  <a href="mailto:p2wadmin.cor@capgemini.com?subject=P2W%20Access%20Needed&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ASBU (UK, NON-UK, Americas, APAC, Financial services):%0D%0A%0D%0ACountry:%0D%0A%0D%0AModules (AM, AD, P2W LITE, P2W PCP):%0D%0A%0D%0AThank%20you!
        "target="_blank"><u>AM Team.</u></a>
        </p>`);
      }
      // this.toastr.warning(`<p class="lefter">

      // You do not have access for this module. Please reach out to  <a href="mailto:p2wadmin.cor@capgemini.com?subject=P2W%20Access%20Needed&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ASBU(UK,NON UK,Americas,APAC,Financial services):%0D%0A%0D%0ACountry:%0D%0A%0D%0AModules(AM,AD,CIS):%0D%0A%0D%0AThank%20you!
      // "target="_blank"><u>AM Team (AM and AD)</u></a>and
      
     
      
      //  <a href="mailto:cisbbs.in@capgemini.com?subject=Technical%20access%20related%20issues&body=Hello%20Team,%0D%0A%0D%0APlease%20find%20the%20required%20details%20below:%0D%0A%0D%0AName:%0D%0A%0D%0AEmail%20Id:%0D%0A%0D%0ACorp%20Id:%0D%0A%0D%0ACountry:%0D%0A%0D%0AModules(AM,AD,CIS):%0D%0A%0D%0AThank%20you!
      //  "target="_blank"><u>CIS Team .</u></a></p>`);


      
    }
    else {

      this.LandingPage = false;

      this._appSharedService.currentUserModuleData.Code = moduleData;
      this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);
      if (moduleData == 'AD') {
        this.IsCIS = false;
        this.IsLiteOld = false;
        this.IsLite = false;
        this.IsAM = false;
        let userEmail = this._appSharedService.UserInfo.email;
        this.getUserModuleList(userEmail, 'AD');

      }
      if (moduleData == 'CIS') {
        this.IsCIS = true;
        this.IsLiteOld = false;
        this.IsLite = false;
        this.IsAM = false;
        //  this._router.navigate(["app/dashboard"]);
        let userEmail = this._appSharedService.UserInfo.email;
        this.getUserModuleList(userEmail, 'CIS');
        // this._appSharedService.currentUserModuleData.CodeDescription = 'CIS';
        // this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);
        // this._appSharedService.currentUserModuleData.CodeDescription='CIS'
        this.getAppTemplate();

      }

      if (moduleData == 'AM') {
        this.IsLite = false;
        this.IsLiteOld = false;
        this.IsCIS = false;
        this.IsAM = true;
        let userEmail = this._appSharedService.UserInfo.email;
        this.getUserModuleList(userEmail, 'AM');
        // this._router.navigate(["app/dashboard"]);
        //   this._appSharedService.currentUserModuleData.CodeDescription = 'AM (.Net)';
        //    this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);
        this.getAppTemplate();

        //window.open(this._appSharedService.currentUserModuleData.UserDef2, '_self');
      }
      if (moduleData == 'LITE') {
        this.IsLite = true;
        this.IsLiteOld = false;
        this.IsCIS = false;
        this.IsAM = false;
        let userEmail = this._appSharedService.UserInfo.email;
        this.getUserModuleList(userEmail, 'LITE');
        // this._router.navigate(["app/dashboard"]);
        //  this._appSharedService.currentUserModuleData.CodeDescription = 'LITE';
        //  this._appSharedService.currentUserModuleData$.next(this._appSharedService.currentUserModuleData);
        this.getAppTemplate();

      }


      // if (moduleData == 'AD') {
      //   https://p2wnonprod.azurewebsites.net/
      // }


      // if (moduleData == 'LITE-OLD') {
      //   this.IsLite = false;
      //   this.IsLiteOld = true;
      //   this.IsCIS = false;
      //   this.IsAM = false;
      //   // this._router.navigate(["app/dashboard"]);

      // }
    }

  }

  menuTabClick(event: any) {
    console.log("menu click")
  }


  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  onSideBarButtonclick() {

    if (!isNullOrUndefined(this._appSharedService.currentUserModuleData) && this._appSharedService.currentUserModuleData.Code != ''
      && this._appSharedService.currentUserModuleData.Code === 'AM') {

      this.menuListReconfigure();
    }

  }

  public AmStatusList: any;

  menuListReconfigure() {

    let dealData = this._appSharedService.currentDealData;

    let DealHeaderId = !isNullOrUndefined(dealData) && !isNullOrUndefined(dealData.DealHeaderId) && dealData.DealHeaderId > 0 ? dealData.DealHeaderId : 0;
    let DealLineItemId = !isNullOrUndefined(dealData) && !isNullOrUndefined(dealData.DealLineItemId) && dealData.DealLineItemId > 0 ? dealData.DealLineItemId : 0;
    let DealSubLineItemId = !isNullOrUndefined(dealData) && !isNullOrUndefined(dealData.DealSubLineItemId) && dealData.DealSubLineItemId > 0 ? dealData.DealSubLineItemId : 0;
    let servicedomain = !isNullOrUndefined(dealData) && !isNullOrUndefined(dealData.ServiceDomain) && dealData.ServiceDomain !== '' ? dealData.ServiceDomain : '';
    let selectedLotData = !isNullOrUndefined(dealData) && !isNullOrUndefined(dealData.selectedLotData) && dealData.selectedLotData !== '' ? dealData.selectedLotData : '';

    this.menuList = [];
    this.menuList = JSON.parse(JSON.stringify(this.bk_menuList));

    this.menuList.forEach((item: any) => {
      // let menuitem = this.menuList.filter((x: any) => x[this.menuListPermission['MenuList_' + item.Name + '_Permission']]);
      item.HasPermission = false;
      let peritem = this.menuListPermission.indexOf('MenuList_' + item.Name + '_Permission');

      if (item.Name === 'Process' && !isNullOrUndefined(dealData) && dealData.DealHeaderId > 0) {

        // let subscribe$: Subscription = this.litehomeService.getSliderRange('DealProgress').subscribe(result => {
        //   if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        //     this.AmStatusList = result.Data;
        //     this.AmStatusList = this.AmStatusList.sort((a: { UserDef1: any; }, b: { UserDef1: any; }) => (Number(a.UserDef1) < Number(b.UserDef1)) ? -1 : 1);

        //     let dealStageData = this.AmStatusList.filter((x: any) => x.Code === this._appSharedService.currentDealData.DealProgress);

        //     let stageValue = !isNullOrUndefined(dealStageData) && dealStageData.length > 0 ? Number(dealStageData[0].UserDef1) : 0;

        //     let final_AMLIst = this.AmStatusList.filter((x: any) => Number(x.UserDef1) <= stageValue);

        //     if (!isNullOrUndefined(final_AMLIst) && final_AMLIst.length > 0) {

        if (peritem >= 0) {
          item.HasPermission = true;
        }
        if (item.SubMenu.length > 0) {
          // this.menuList.forEach((submenuitem: any) => {



          // Deal Configuration
          let model_Data = item.SubMenu.filter((x: any) => x.P2WGroup === MenuModel.ModelConfig);
          if (!isNullOrUndefined(model_Data) && model_Data.length > 0) {
            model_Data.forEach((submenuitem: any) => {
              submenuitem.HasPermission = false;
              let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
              if (subperitem >= 0) {
                submenuitem.HasPermission = true;
              }



              if (submenuitem.SubMenu.length > 0) {
                submenuitem.SubMenu.forEach((submenuitems: any) => {
                  submenuitems.HasPermission = false;
                  let subperitems = this.menuListPermission.indexOf('MenuList_' + submenuitems.Name + '_Permission');

                  // let submenuAMData = final_AMLIst.filter((x: any) => x.Code === submenuitems.AMStatus);

                  // if (!isNullOrUndefined(submenuAMData) && submenuAMData.length > 0) {
                  if (submenuitems.AMProgressStatus <= dealData.DealProgressOrder) {
                    if (subperitems >= 0) {
                      submenuitems.HasPermission = true;
                    }

                    if (submenuitems.SubMenu.length === 0) {
                      //Expection for P2W
                      if (!isNullOrUndefined(this._appSharedService.currentUserModuleData) && this._appSharedService.currentUserModuleData.Code != ''
                        && this._appSharedService.currentUserModuleData.Code === 'AM') {

                        let routeLink = submenuitems.CopyRouteLink + '/' + DealHeaderId;

                        if (!isNullOrUndefined(dealData)) {
                          submenuitems.selectedLotData = selectedLotData;
                          submenuitems.RouteLink = routeLink;
                        }

                      }
                    }

                    //Model Baselining
                    if (!isNullOrUndefined(submenuitems.SubMenu) && submenuitems.SubMenu.length > 0) {

                      submenuitems.SubMenu.forEach((sitem: any) => {

                        sitem.HasPermission = false;

                        // let innersubmenuAMData = final_AMLIst.filter((x: any) => x.Code === sitem.AMStatus);

                        // if (!isNullOrUndefined(innersubmenuAMData) && innersubmenuAMData.length > 0) {
                        if (sitem.AMProgressStatus <= dealData.DealProgressOrder) {
                          if (sitem.SubMenu.length === 0 && sitem.Group === dealData.AmApproach) {
                            let routeLink = sitem.CopyRouteLink + '/' + DealHeaderId;

                            if (!isNullOrUndefined(dealData)) {
                              sitem.selectedLotData = selectedLotData;
                              sitem.RouteLink = routeLink;
                            }

                            sitem.HasPermission = true;
                          }
                        }
                      });

                    }
                  }

                });
              }
            });
          }

          let check_Lot = this.LotSolutionCondition.filter((x: any) => x.AmApproach === dealData.AmApproach && x.AMStatus === dealData.DealProgress && x.Group === 'LotSolution')

          if ((!isNullOrUndefined(check_Lot) && check_Lot.length > 0) || (dealData.DealProgressOrder >= 7)) {
            // Lot Solution
            let lot_Solution = item.SubMenu.filter((x: any) => x.P2WGroup === MenuModel.LotSolution);
            if (!isNullOrUndefined(lot_Solution) && lot_Solution.length > 0) {

              lot_Solution.forEach((submenuitem: any) => {
                submenuitem.HasPermission = false;
                let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
                if (subperitem >= 0) {
                  submenuitem.HasPermission = true;
                }

                if (submenuitem.SubMenu.length > 0) {

                  let lot_Effort = submenuitem.SubMenu.filter((x: any) => x.P2WGroup === MenuModel.LotSolutionEffort);
                  let lot_Pricing = submenuitem.SubMenu.filter((x: any) => x.P2WGroup === MenuModel.LotPricing);
                  // Effort Modeing
                  if (!isNullOrUndefined(lot_Effort) && lot_Effort.length > 0) {
                    lot_Effort.forEach((submenuitems: any) => {
                      submenuitems.HasPermission = false;
                      let subperitems = this.menuListPermission.indexOf('MenuList_' + submenuitems.Name + '_Permission');
                      if (subperitems >= 0) {
                        submenuitems.HasPermission = true;
                      }

                      let enhancement_approach: any = [];
                      //Not Enhancement
                      if (servicedomain !== ServiceDomain.Enhancement) {

                        // with approach check
                        if (dealData.AmApproach !== AMApproach.Users_Based_Approach) {
                          enhancement_approach = submenuitems.SubMenu.filter((x: any) => x.Conditon === 'NotEqual' && x.Group !== dealData.AmApproach);
                        } else {
                          enhancement_approach = submenuitems.SubMenu.filter((x: any) => x.Conditon === 'Equal' && x.Group === dealData.AmApproach);
                        }


                      }
                      //Enhancement
                      else if (servicedomain === ServiceDomain.Enhancement) {
                        enhancement_approach = submenuitems.SubMenu.filter((x: any) => x.Conditon === 'Enhancement');
                      }

                      if (enhancement_approach.length > 0) {
                        if (!isNullOrUndefined(enhancement_approach) && enhancement_approach.length > 0) {

                          enhancement_approach.forEach((lotEFitem: any) => {

                            lotEFitem.HasPermission = false;

                            // let innersubmenuAMData = final_AMLIst.filter((x: any) => x.Code === lotEFitem.AMStatus);

                            // if (!isNullOrUndefined(innersubmenuAMData) && innersubmenuAMData.length > 0) {

                            let dealProgress = (lotEFitem.Name === 'DeliveryLocationModeling' && !isNullOrUndefined(check_Lot) && check_Lot.length > 0) ? check_Lot[0].AmDealProgress : lotEFitem.AMProgressStatus;

                            if (dealProgress <= dealData.DealProgressOrder) {
                              if (lotEFitem.SubMenu.length === 0) {
                                let routeLink = lotEFitem.CopyRouteLink + '/' + DealHeaderId + '/' + DealLineItemId + '/' + DealSubLineItemId;

                                if (!isNullOrUndefined(dealData)) {
                                  lotEFitem.selectedLotData = selectedLotData;
                                  lotEFitem.RouteLink = routeLink;
                                }

                                lotEFitem.HasPermission = true;
                              }
                            }
                          });

                        }
                      }

                    });
                  }

                  let deal_progress_id = 0;

                  if (servicedomain === ServiceDomain.Enhancement) {
                    deal_progress_id = 9;
                  } else {
                    deal_progress_id = 10;
                  }

                  if (deal_progress_id <= dealData.DealProgressOrder) {
                    // Pricing Model
                    if (!isNullOrUndefined(lot_Pricing) && lot_Pricing.length > 0) {

                      lot_Pricing.forEach((submenuitems: any) => {
                        submenuitems.HasPermission = true;

                        if (!isNullOrUndefined(submenuitems.SubMenu) && submenuitems.SubMenu.length > 0) {
                          submenuitems.SubMenu.forEach((lot_pricing_item: any) => {

                            lot_pricing_item.HasPermission = true;

                            if (lot_pricing_item.SubMenu.length === 0) {
                              let routeLink = lot_pricing_item.CopyRouteLink + '/' + DealHeaderId + '/' + DealLineItemId + '/' + DealSubLineItemId;

                              if (!isNullOrUndefined(dealData)) {
                                lot_pricing_item.selectedLotData = selectedLotData;
                                lot_pricing_item.RouteLink = routeLink;
                              }

                              lot_pricing_item.HasPermission = true;
                            }

                            if (lot_pricing_item.SubMenu.length > 0) {
                              lot_pricing_item.SubMenu.forEach((otherclientData: any) => {

                                let routeLink = otherclientData.CopyRouteLink + '/' + DealHeaderId + '/' + DealLineItemId + '/' + DealSubLineItemId;

                                if (!isNullOrUndefined(dealData)) {
                                  otherclientData.selectedLotData = selectedLotData;
                                  otherclientData.RouteLink = routeLink;
                                }

                                otherclientData.HasPermission = true;
                              });
                            }

                          });

                        }

                      });
                    }
                  }


                }


              });
            }
          }

        }
        //     }

        //   }
        // },

        //   err => {
        //     console.log('Error on getting range details.');
        //   });
        // this.subscriptionList$.push(subscribe$);
        // this.DealInfoForm.enableFields('Nearshore', true);



      } else if (item.Name !== 'Process') {

        if (peritem >= 0) {
          item.HasPermission = true;
        }
        if (item.SubMenu.length > 0) {
          // this.menuList.forEach((submenuitem: any) => {
          item.SubMenu.forEach((submenuitem: any) => {
            submenuitem.HasPermission = false;
            let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
            if (subperitem >= 0) {
              submenuitem.HasPermission = true;
            }

            if (submenuitem.SubMenu.length > 0) {
              submenuitem.SubMenu.forEach((submenuitems: any) => {
                submenuitems.HasPermission = false;
                let subperitems = this.menuListPermission.indexOf('MenuList_' + submenuitems.Name + '_Permission');
                if (subperitems >= 0) {
                  submenuitems.HasPermission = true;
                }
              });
            }
          });


        }
      }
    });
  }
}
