<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <h1 class="sr-only">Admin Management</h1>
            <ul class="breadcrumb m-0 flex-fill">
                <li class="breadcrumb-item" aria-current="page">Admin</li>
                <li class="breadcrumb-item active" aria-current="page">{{tabName}} Management</li>
            </ul>
        </div>
    </nav>

    <div class="contentScroll p-3 simpleCard myDealCard">
        <div class="card h-100 m-0">
            <div class="card-header">
                <h3>
                    <span>{{Title}}</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">
                <div *ngIf="viewName == 'ListView'">
                    <div class="gridContainer">
                        <ng-container
                            *ngIf="(ListTemplate != null && ListTemplate != undefined && ListTemplate.length > 0)">

                            <grid-dispctrl #overallgrid [FieldTemplate]="ListTemplate" [GridData]="ListData"
                                [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                                (FieldClick)="onClick($event)" (ActionBarClick)="actButtonClick($event)">
                            </grid-dispctrl>

                        </ng-container>
                    </div>
                </div>
                <ng-container *ngIf="ModuleName=='CIS'">
                    <div *ngIf="viewName == 'EditView'" tabindex="0">

                        <ng-container
                            *ngIf="(CIS_EditTemplate != null && CIS_EditTemplate != undefined && CIS_EditTemplate.length > 0
                        && editData != null && editData != undefined && 
                        (editData.UserId == 0 && selectedTab + 'Edit' == 'userEdit') || (editData.RoleId == 0 && selectedTab + 'Edit' == 'roleEdit'))">
                            <customform [FormTemplate]="CIS_EditTemplate" [FormData]="editData" [FormProp]="FormProp"
                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)">
                            </customform>
                        </ng-container>
    
                        <ng-container
                            *ngIf="(CIS_EditTemplate != null && CIS_EditTemplate != undefined && CIS_EditTemplate.length > 0
                            && editData != null && editData != undefined  && 
                            (editData.UserId > 0 && selectedTab + 'Edit' == 'userEdit') || (editData.RoleId > 0 && selectedTab + 'Edit' == 'roleEdit'))">
                            <customform [FormTemplate]="CIS_EditTemplate" [FormData]="editData" [FormProp]="FormProp"
                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)">
                            </customform>
                        </ng-container>
    
                    </div>
                </ng-container>

                <ng-container *ngIf="ModuleName!='CIS'">
                    <div *ngIf="viewName == 'EditView'" tabindex="0">

                        <ng-container
                            *ngIf="(EditTemplate != null && EditTemplate != undefined && EditTemplate.length > 0
                        && editData != null && editData != undefined && 
                        (editData.UserId == 0 && selectedTab + 'Edit' == 'userEdit') || (editData.RoleId == 0 && selectedTab + 'Edit' == 'roleEdit'))">
                            <customform [FormTemplate]="EditTemplate" [FormData]="editData" [FormProp]="FormProp"
                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)">
                            </customform>
                        </ng-container>
    
                        <ng-container
                            *ngIf="(EditTemplate != null && EditTemplate != undefined && EditTemplate.length > 0
                            && editData != null && editData != undefined  && 
                            (editData.UserId > 0 && selectedTab + 'Edit' == 'userEdit') || (editData.RoleId > 0 && selectedTab + 'Edit' == 'roleEdit'))">
                            <customform [FormTemplate]="EditTemplate" [FormData]="editData" [FormProp]="FormProp"
                                [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)">
                            </customform>
                        </ng-container>
    
                    </div>
                </ng-container>

               

            </div>
            <div>
                <div class="filters buttonHolder" id="managedServices">
                    <div class="d-flex justify-content-end">
                        <ng-container
                            *ngIf="Config.ActionButtonsBottom != null && Config.ActionButtonsBottom != undefined">
                            <ng-container
                                *ngFor="let actionBar of (Config.ActionButtonsBottom | filterBy: 
                                    {
                                        IsApplicable: true,HasPermission :true, 
                                        Group: 
                                        (
                                            viewName == 'ListView' ? selectedTab + 'List' : 
                                            viewName == 'EditView' ? selectedTab + 'Edit' : ''
                                        ) 
                                    }
                                    | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}); let i = index">

                                <button type="button" [ngClass]="actionBar.CustomClass"
                                    (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                    |
                                    translate
                                    }}
                                    <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
                                            aria-hidden="true"></i></span>
                                </button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Loader section -->
    <div [hidden]="!IsLoading">
        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
    </div>

    <!-- Notification section -->
    <div *ngIf="displayNotifybox">
        <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
            [curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
    </div>

    <!-- Filter Pop-up -->
    <div *ngIf="DispFilter">
        <filter *ngIf="(ListTemplate!= undefined && ListTemplate!= null && ListTemplate.length > 0)"
            [FieldTemplate]="ListTemplate" [GridData]="ListDataALL" (close)="showFilter($event)"
            (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
        </filter>
    </div>

    <!-- Excel Download -->
    <excel-export #excelexport [FileName]="ExcelFileName"></excel-export>