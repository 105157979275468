<!-- Horizontal Menu -->
<ng-container *ngIf="menuType=='mainbar'">
    <!-- <ul id="tabMain" class="nav nav-tabs nav-justified"> -->

    <!-- Multi-level tree -->
    <ul id="MainMenu" class="nav nav-tabs" role="tablist">

        <ng-container
            *ngTemplateOutlet="menuView; context:{ $implicit: (menuList | filterBy: {HasPermission:true}), parentTab: null, level:1 }">
        </ng-container>

        <ng-template #menuView let-tabList let-parentTab="parentTab" , let-level="level">
            <ng-template ngFor let-tab [ngForOf]="tabList">


                <li *ngIf="(tab.SubMenu.length==0)" [ngClass]="[((level == 1)?'nav-item':'')]">
                    <a [attr.aria-label]="tab?.DisplayName"
                        [ngClass]="[((level == 1)?'nav-link':'dropdown-item'), (tab.Active == true ? ' active': '')]"
                        href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
                        [routerLink]="[tab.RouteLink]" (click)="onRouteClick(tab, parentTab)">
                        <i [class]="tab.Icon"></i> {{tab.DisplayName | translate}} </a>
                </li>

                <li *ngIf="(tab.SubMenu.length>0)" [ngClass]="[((level == 1)?'nav-item dropdown':'')]" [id]="tab.Name">
                    <a [attr.aria-label]="tab?.DisplayName" id="{{fieldRandomId}}-{{tab.DisplayName}}"
                        [ngClass]="[((level == 1)?'nav-link dropdown-toggle':'dropdown-item'), (tab.Active == true ? ' active': '')]"
                        [class.disabled]="false" href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true"
                        aria-expanded="false" [routerLink]="[tab.RouteLink]" (click)="onRouteClick(tab, parentTab)">
                        <i [class]="tab.Icon"></i> {{tab.DisplayName | translate}}

                        <i class="ps-2 fa" aria-hidden="true"
                            [ngClass]="[((level == 1)?'fa-chevron-down':'fa-chevron-right')]"></i>
                        <!-- [ngClass]="[((level > 1)?'iconRotateLeft':'')]" -->
                    </a>

                    <span class="tabpageName">{{getSelectedSubTabName(tab)}}</span>
                    <!-- Sub Menulist -->
                    <ul class="dropdown-menu" [ngClass]="[((level == 1)?'':'submenu')]">
                        <ng-container
                            *ngTemplateOutlet="menuView; context:{ $implicit: (tab.SubMenu | filterBy: {HasPermission:true}), parentTab: tab, level:level+1 }">
                        </ng-container>

                    </ul>
                </li>

            </ng-template>
        </ng-template>
    </ul>


</ng-container>


<!-- Sidebar Menu -->
<ng-container *ngIf="menuType=='sidebar'">
    <div class="d-flex h-100">
        <span class="sidebarFix d-none sr-hidden sidebarText collapse show"></span>
<ng-container>
    <div  #myOffCanvas class="offcanvas offcanvas-start"  tabindex="-1" id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Navigation</h5>
        <button #closebutton type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
    </div>

    <div class="offcanvas-body p-0 sidebar-container">
        <!-- <a href="#" data-bs-target=".sidebarText" data-bs-toggle="collapse" class="close-menu">Close</a> -->
        <ul class="list-group overflow-auto" id="tabMain">
            <ng-container
                *ngTemplateOutlet="tabView; context:{ $implicit: menuList | filterBy: {HasPermission:true} }">
            </ng-container>

            <ng-template #tabView let-tabList>
                <ng-template ngFor let-tab [ngForOf]="tabList">
                    <li>
                        <ng-container *ngIf="tab.SubMenu.length==0">
                            <a class="list-group-item" [ngClass]="{'active': (tab.Name == selTab)}"
                                [attr.aria-label]="tab?.DisplayName" href="#tabMain"
                                [routerLink]="[tab.RouteLink]" (click)="onRouteClick(tab)"
                                title="{{tab.DisplayName |translate}}">
                                <!-- <i [class]="tab.Icon"></i> {{tab.DisplayName |translate}} -->
                                <img alt="menubar-{{tab.DisplayName}}" *ngIf="tab.Src != null" class="admin-img"
                                    src="{{tab.Src}}">

                                <span
                                    class="sidebarText collapse-horizontal collapse fade show">{{tab.DisplayName
                                    |translate}}</span>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="tab.SubMenu.length>0">
                            <a class="list-group-item" [attr.aria-label]="tab?.DisplayName"
                                data-bs-toggle="collapse" [href]="'#sidebar'+tab.Name" role="button"
                                aria-expanded="false" [attr.aria-controls]="'sidebar'+tab.Name"
                                [ngClass]="{'active': (tab.Active == true)}">
                                <!-- [class.disabled]="tab.SubMenu.length>0" -->

                                <!-- <span class="me-2"><i [class]="tab.Icon"></i> {{tab.DisplayName
                                    |translate}}</span>
                                <i class="fa fa-chevron-down" aria-hidden="true"></i> -->
                                <img alt="menubar-{{tab.DisplayName}}" *ngIf="tab.Src != null" class="admin-img"
                                    src="{{tab.Src}}">

                                <span
                                    class="sidebarText collapse-horizontal collapse fade show">{{tab.DisplayName
                                    |translate}}</span>
                            </a>

                            <!-- <div class="collapse show" [id]="'sidebar'+tab.Name"> -->
                                <ul class="collapse sub-list-group ps-4" [id]="'sidebar'+tab.Name">
                                    <ng-container
                                        *ngTemplateOutlet="tabView; context:{ $implicit: tab.SubMenu | filterBy: {HasPermission:true} }">
                                    </ng-container>
                                </ul>
                            <!-- </div> -->
                        </ng-container>

                    </li>
                </ng-template>
            </ng-template>
        </ul>
    </div>
</div>
</ng-container>
       



        <div class="w-100 h-100">
            <div class="tab-content h-100">
                <div class="tab-pane fade active show">
                    <div class="h-100">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [timeout]="notify.timeout"
        [curitem]="notify.item" (close)="closeNotifybox() " (selected)="setNotifyRes($event) "></notification>
</div>

<style>
    .nav-tabs .nav-item.dropdown-item a [class*="fa-chevron"]:hover,
    .nav-tabs .nav-item .dropdown-item [class*="fa-chevron"]:hover {
        /* .transform(rotate(-90deg)); */

        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .fa-chevron-down:hover {
        content: "\f077";
    }
</style>