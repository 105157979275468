import { FilterPipeModule } from 'ngx-filter-pipe';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { FilterModule } from 'src/modules/filter/filter.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { FormModule } from 'src/modules/form/form.module';
import { GridReadonlyModule } from 'src/modules/grid-readonly/grid-readonly.module';
import { GridModule } from 'src/modules/grid/grid.module';
import { SearchByModule } from 'src/modules/searchby/searchby.module';
import { DashboardModule } from 'src/modules/dashboard/dashboard.module';
import { ExcelExportModule } from 'src/modules/excelexport/excelexport.module';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { GridDisplayControlModule } from 'src/modules/grid-dispctrl/grid-dispctrl.module';
import { ModalModule } from 'src/modules/modal/modal.module';
import { TabSetModule } from 'src/modules/tabset/tabset.module';
import { ChartsComponent } from '../charts/charts.component';

@NgModule({
    declarations: [
        ChartsComponent
    ],
    imports: [
       TranslateModule,
      LoadingModule, FilterModule, CustomPipeModule, FormModule,
      GridReadonlyModule, FilterPipeModule, GridModule,
      ExcelExportModule, ActionBarModule, NotificationModule,
      DashboardModule, SearchByModule,ModalModule,GridDisplayControlModule
    ],
    exports: [
        ChartsComponent
    
      ],
    providers: [],
    bootstrap: [ChartsComponent]
  })
  
  export class ChartsModule {
  }