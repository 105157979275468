import { Component, OnInit } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {
  public Title: any = 'Glossary';
  constructor(private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle(this.Title);
  }

}
