export class dataPropInterface {
FTERateDesc:string='';
  FTEOnshore: string = '';
  RateOnshore: string = '';
  FTEOffshore: string = '';
  RateOffshore: string = '';
  constructor(init?: Partial<dataPropInterface>) {
    Object.assign(this, init);
}
}

export class summaryInterface1{
  ShoreDesc:string='';
  Onshore:string = '';
  Offshore:string = '';
  Nearshore:string= '';
  constructor(init?: Partial<summaryInterface1>) {
    Object.assign(this, init);
}
}

export class summaryInterface2{
  OthersDesc:string= '';
  TransitionCost:string = '';
  Governance:string ='';
  OtherExpenses:string = '';
  ContingencyCost:string = '';
  constructor(init?: Partial<summaryInterface2>) {
    Object.assign(this, init);
}
}

export class summaryInterface3{
  OtherCosts: string = '';
  Discount : string ='';
  Negotiation :string ='';
  Connectivity : string = '';
  constructor(init?: Partial<summaryInterface3>) {
    Object.assign(this, init);
}
}

export class formData{
  IsSelectedOnshore:boolean=false;
  IsSelectedOffshore:boolean = false;
  IsSelectedNearshore:boolean = false;
  IsEditable:number=1;
  City:string='';
  CityDesc:string = '';
  Nearshore : string ='';
  NearshoreDesc:string = '';
  Offshore :string= '';
  OffshoreDesc:string = '';
  Onshore:string ='';
  OnshoreDesc:string ='';
  LocationOffshore:string ='';
  LocationOffshoredesc:string='';
  
  constructor(init?: Partial<formData>) {
    Object.assign(this, init);
}
}