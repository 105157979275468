import { Component, Input, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core'; // Import OnDestroy
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../../../shared/app.sharedservice';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AMApproach, ComponentCodes, ComponentConfig, ProcessStatus, ServiceDomain } from '../../../app.interface';
import { LotSolutionService } from './../lot-solution-modelling.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'location-mix',
  templateUrl: './location-mix.component.html'
})
//@AutoUnsubscribe
export class LocationMixComponent implements OnInit {

  @Input() selectedLineItemData: any;
  @Input() ServiceDomainDetails: any;
  @ViewChildren('LocationMixGrid') LocationMixGrid!: QueryList<GridDisplayControlComponent>;

  DealData: any = {};
  public IsLoading: boolean = false;
  public DealHeaderId: any;
  public DealLineItemId: any;
  public DealSubLineItemId: any;
  public subscriptionList$: Subscription[] = [];
  public notify: NotificationData = new NotificationData();
  public displayNotifybox: boolean = false;
  public modalInf: ModalInterface = new ModalInterface();

  public Permission: any = [];
  public Properties: any = [];
  public LotName: any;
  public ActionButtons: any[] = [];
  public EffortMixButtons: any[] = [];
  public Config: any;

  public LocationMixTemplate: FieldTemplate[] = [];
  public LSELocationInfluencersTemplate: FieldTemplate[] = [];
  public LSEOnshoreLocationEffortPerTemplate: FieldTemplate[] = [];
  public LSEEffortsDistributionPerTemplate: FieldTemplate[] = [];
  public LSEEnhancementFormTemplate: FieldTemplate[] = [];
  public LSEEnhancementGridTemplate: FieldTemplate[] = [];

  public LSELocationInfluencersDetails: any[] = [];
  public LSEOnshoreLocationEffortPerDetails: any[] = [];
  public LSEEffortsDistributionPerDetails: any[] = [];
  public LSEEnhancementFormDetails: any = {};
  public LSEEnhancementGridDetails: any[] = [];

  public LSELocationInfluencersTotalCount: number = 0;
  public LSEOnshoreLocationEffortPerTotalCount: number = 0;
  public LSEEffortsDistributionPerTotalCount: number = 0;
  public LSEEnhancementGridTotalCount: number = 0;
  public IsPagination: boolean = false;
  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable:false,
    PaginationConfig: {
      ItemsPerPage: 25
    }
  };

  public FormProp: FormProperty = {
    ColSize: 3,
    CaptionBold: false,
    CaptionTop: true,
    IsEditable: true,
    ShowControlAlways: true
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public GlobalConfig: any = {};

  constructor(
    private toastr: ToastrService,
    private _router: Router,
    private LotSolutionService: LotSolutionService,
    private modalService: ModalService,
    private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService
  ) { }

  ngOnInit(): void {
    // Initialize component properties
    this.DealHeaderId = this.selectedLineItemData.DealHeaderId ?? 0;
    this.DealLineItemId = this.selectedLineItemData.DealLineItemId ?? 0;
    this.DealSubLineItemId = this.selectedLineItemData.DealSubLineItemId ?? 0;
    this.LotName = this.selectedLineItemData.LotName;

    // Get the current deal data from the shared service
    this.DealData = this._appSharedService.currentDealData;

    // Fetch Location Mix Template
    if (!isNullOrUndefined(this.selectedLineItemData)) {
      this.getLocationMixTemplate();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from subscriptions to prevent memory leaks
    this.subscriptionList$.forEach((subscription) => subscription.unsubscribe());
  }

  private getLocationMixTemplate(): void {
    // Show loading indicator
    this.IsLoading = true;

    // Define the component configuration
    const odata: ComponentConfig = {
      Component: 'LotSolutionModelling',
      ComponentCode: ComponentCodes.LocationMix,
      ReferenceValueType: '1'
    };

    // Subscribe to the component configuration API call
    const subscribe$ = this._commonAPIService.getComponentConfig(odata).subscribe(
      (res) => {
        // Handle the response
        if (res !== undefined && res !== null && res.Status === 200) {
          this.LocationMixTemplate = res.Data.FieldTemplate!;
          this.Permission = res.Data.Permission;
          this.Properties = res.Data.Properties;


          this.LSELocationInfluencersTemplate = this.LocationMixTemplate.filter(x => x.Group === 'LSELocationInfluencers');
          this.LSEOnshoreLocationEffortPerTemplate = this.LocationMixTemplate.filter(x => x.Group === 'LSEOnshoreLocationEffortPer');
          this.LSEEffortsDistributionPerTemplate = this.LocationMixTemplate.filter(x => x.Group === 'LSEEffortsDistributionPer');
          this.LSEEnhancementFormTemplate = this.LocationMixTemplate.filter(x => x.Group === 'LSEEnhancementDropdown');
          this.LSEEnhancementGridTemplate = this.LocationMixTemplate.filter(x => x.Group === 'LSEEnhancementGrid');

          //Set Properties
          this.setCompProperties(this.Properties);
          this.ActionButtons = this.Config.LSEffortModellingButtons.filter((x: any) => x.Group === 'LocationMix');
          this.EffortMixButtons = this.Config.LocationEffortPerMixButtons.filter((x: any) => x.Group === 'LSEOnshoreLocationEffortPer');

          this.getLocationMix();
        }
      },
      (err) => {
        // Handle errors
        this.IsLoading = false;
        console.log('Error on getLotSolutionTemplate.');
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }

  getLocationMix(): void {

    this.LSELocationInfluencersDetails = [];
    this.LSEOnshoreLocationEffortPerDetails = [];
    this.LSEEffortsDistributionPerDetails = [];
    this.LSEEnhancementFormDetails = {};
    this.LSEEnhancementGridDetails = [];

    if (this.ServiceDomainDetails.ServiceDomain === ServiceDomain.Enhancement) {
      this.GetEnhancementPyramid(this.LSEEnhancementFormDetails.Model);
    }
    else {
      this.GetLocationInfluencers();


    }
  }

  GetLocationInfluencers() {
    let subscribe$: Subscription = this.LotSolutionService.GetLocationInfluencers(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.LSELocationInfluencersDetails = result.Data;
        this.LSELocationInfluencersTotalCount = this.LSELocationInfluencersDetails.length;
      }
      this.GetOnshoreLocationEffortPer();
      // this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetLocationInfluencers.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetOnshoreLocationEffortPer() {
    let subscribe$: Subscription = this.LotSolutionService.GetOnshoreLocationEffortPer(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        for (let index = 1; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = this.LSEOnshoreLocationEffortPerTemplate.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.LSEOnshoreLocationEffortPerDetails = result.Data;
        this.LSEOnshoreLocationEffortPerTotalCount = this.LSEOnshoreLocationEffortPerDetails.length;
      }

      this.GetEffortsDistributionPer();
      // this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetOnshoreLocationEffortPer.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }
  GetEffortsDistributionPer() {
    let subscribe$: Subscription = this.LotSolutionService.GetEffortsDistributionPer(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        for (let index = 1; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = this.LSEEffortsDistributionPerTemplate.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.LSEEffortsDistributionPerDetails = result.Data;
        this.LSEOnshoreLocationEffortPerTotalCount = this.LSEEffortsDistributionPerDetails.length;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetEffortsDistributionPer.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetEnhancementPyramid(Model: any) {
    let subscribe$: Subscription = this.LotSolutionService.GetEnhancementPyramid(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId, Model).subscribe(result => {
      this.IsLoading = true;
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        for (let index = 1; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString() + 'PerOnshore';

          let fieldData = this.LSEEnhancementGridTemplate.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        for (let index = 1; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString() + 'PerOffshore';

          let fieldData = this.LSEEnhancementGridTemplate.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.LSEEnhancementGridDetails = result.Data;
        this.LSEEnhancementFormDetails = this.LSEEnhancementGridDetails[0];
        this.LSEEnhancementFormDetails.Model = this.LSEEnhancementGridDetails[0].Model;
        this.LSEEnhancementGridTotalCount = this.LSEEnhancementGridDetails.length;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'ProductivityBlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          if (isNullOrUndefined(this.Config)) {
            this.Config = {};
          }
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  showNotificationMessage(msg: string, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {
      case 'LOCATIONMIX':
        if (event.result.toUpperCase() === 'YES') {
          this.navigateToDeliveryModel();
        }
        break;
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  onClick(event: any) {

  }

  onChange(event: any) {
    let selitem: any = event.item;
    selitem.IsModified = 1;
    let itemindex: any = event.item_index;

    switch (event.fieldname.toUpperCase()) {
      case 'YEAR1':
      case 'YEAR2':
      case 'YEAR3':
      case 'YEAR4':
      case 'YEAR5':
      case 'YEAR6':
      case 'YEAR7':
        if (this.LSEEffortsDistributionPerDetails.length <= 15) {
          const filteredItems = this.LSEEffortsDistributionPerDetails
            .filter((x: any) => x[event.fieldname])
            .map((item: any) => item[event.fieldname]);
          console.log(filteredItems);
          this.Autopercentagefill(itemindex, event.fieldname, filteredItems);
        } else {
          const yearSum = this.LSEEffortsDistributionPerDetails
            .filter((item: any) => item[event.fieldname])
            .map((item: any) => +item[event.fieldname])
            .reduce((sum: any, current: any) => sum + current, 0);
          console.log(yearSum);
          if (yearSum !== 100) {
            this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
          }
        }
        selitem[event.fieldname] = !isNullOrUndefined(selitem[event.fieldname]) && selitem[event.fieldname] > 0 ? selitem[event.fieldname] : 0;
        // if (this.ServiceDomainDetails.ServiceDomain === ServiceDomain.Enhancement) {
        //   this.calculateNetReduction(event.fieldname);
        // }
        break;
      case 'PARAMCODE':
        this.InsertUpdateLocationInfluencers(0, selitem);
        break;
    }


  }

  Autopercentagefill(index: any, year: string, year1: number[]) {
    for (let i = index + 1; i < this.LSEEffortsDistributionPerDetails.length; i++) {
      this.LSEEffortsDistributionPerDetails[i][year] = 0;
    }

    for (let i = index + 1; i < this.LSEEffortsDistributionPerDetails.length; i++) {
      this.LSEEffortsDistributionPerDetails[i][year] = 100 - year1.slice(0, i).reduce((sum, current) => sum + current, 0);
      year1[i] = this.LSEEffortsDistributionPerDetails[i][year];
      console.log(this.LSEEffortsDistributionPerDetails[i][year]);
    }
  }

  HelpIconClick() {

    if (this.ServiceDomainDetails.ServiceDomain !== ServiceDomain.Enhancement) {
      this.openModal('LocationMixDetails');

    } else {
      this.openModal('enhancementDetails');
    }

    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };

  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  actionButtonClick(data: any): void {
    const name = data.Name?.toUpperCase();

    if (name !== undefined && name !== null) {
      if (name === 'SAVE') {



        if (this.ServiceDomainDetails.ServiceDomain == ServiceDomain.Enhancement) {
          if (this.ValidateLSEEnhancementForm()) {
            this.InsertUpdateEnhancementPyramid(0, this.LSEEnhancementGridDetails);
          }
        } else {
          if (this.ValidateEEffortsDistributionPer()) {
            this.InsertUpdateOnshoreLocationEffortPer(0, this.LSEOnshoreLocationEffortPerDetails[0]);
            this.InsertUpdateEffortsDistributionPer(0, this.LSEEffortsDistributionPerDetails);


            if (this.DealData.AmApproach === AMApproach.Users_Based_Approach) {
              this.navigateToStaffingModel();
            } else {
              this.navigateToProductivity();
            }
          }
        }


      } else if (name === 'BACK') {

        this.navigateToDeliveryModel();

      } else if (name === 'APPLYRECOMMENDED') {

        this.LSEEffortsDistributionPerDetails = [];
        if (this.Validate_LSEOnshoreLocationEffortPer(this.LSEOnshoreLocationEffortPerDetails)) {
          this.LSEOnshoreLocationEffortPerDetails[0].ARflag = 1;
          this.InsertUpdateOnshoreLocationEffortPer(0, this.LSEOnshoreLocationEffortPerDetails[0]);
        }

      }
    }
  }


  private navigateToLocationMix(): void {
    const url = `app/lot-solution-modelling/LocationMix/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToProductivity(): void {
    const url = `app/lot-solution-modelling/productivityImpact/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToStaffingModel(): void {
    const url = `app/lot-solution-modelling/StaffingModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToDeliveryModel(): void {
    const url = `app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  Validate_LSEOnshoreLocationEffortPer(event: any): boolean {
    const years = ['Year1', 'Year2', 'Year3', 'Year4', 'Year5', 'Year6', 'Year7'];

    for (const year of years) {
      if (event[0][year] > 100) {
        this.toastr.warning("Year cannot be greater than 100");
        return false;
      }

      if (isNullOrUndefined(event[0][year])) {
        this.toastr.warning("Year cannot be empty");
        return false;
      }
    }

    return true;
  }

  InsertUpdateOnshoreLocationEffortPer(DeleteFlag: any, odata: any) {
    if (DeleteFlag === 1) {
      odata.DeleteFlag = DeleteFlag;
    }
    console.log(odata);

    const subscribePer2$: Subscription = this.LotSolutionService.InsertUpdateOnshoreLocationEffortPer(odata).subscribe(
      result => {
        if (result && result.Status === ProcessStatus.Success) {
          if (DeleteFlag === 1) {
            this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);
          } else {
            this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
          }
          this.GetOnshoreLocationEffortPer();
        }
        this.IsLoading = false;
        subscribePer2$.unsubscribe();
      },
      err => {
        console.log('Error on getting GetDealDetails.', err);
        this.IsLoading = false;
        subscribePer2$.unsubscribe();
      }
    );

   // this.subscriptionList$.push(subscribePer2$);
  }

  InsertUpdateLocationInfluencers(DeleteFlag: any, odata: any) {
    if (DeleteFlag == 1) {
      odata.DeleteFlag = DeleteFlag;
    }
    const subscribe$: Subscription = this.LotSolutionService.InsertUpdateLocationInfluencers(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        if (DeleteFlag == 1) {
          this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);

        }
        if (DeleteFlag == 2 || DeleteFlag == 0) {
          this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);
        }

        this.GetLocationInfluencers();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  InsertUpdateEnhancementPyramid(DeleteFlag: any, odata: any) {


    if (DeleteFlag == 1) {
      odata[0].DeleteFlag = DeleteFlag;
    }
    odata = {
      'enhancementpyramidlist': odata
    }
    const subscribe$: Subscription = this.LotSolutionService.InsertUpdateEnhancementPyramid(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        if (DeleteFlag == 1) {
          this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);
          this.GetEnhancementPyramid(this.LSEEnhancementFormDetails.Model);
        }
        if (DeleteFlag == 0) {

          this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);

          if (this.DealData.AmApproach === AMApproach.Users_Based_Approach) {
            this.navigateToStaffingModel();
          } else {
            this.navigateToProductivity();
          }
        }


      }

      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  InsertUpdateEffortsDistributionPer(DeleteFlag: any, odata: any) {
    if (DeleteFlag == 1) {
      odata[0].DeleteFlag = DeleteFlag;
    }
    odata = {
      'deliverylocationmodellinglist': odata
    }
    const subscribePer$: Subscription = this.LotSolutionService.InsertUpdateEffortsDistributionPer(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        if (DeleteFlag == 1) {
          this.showNotificationMessage('Deleted Successfully!', 1, '', '', '', '', 1000);
          this.GetEffortsDistributionPer();
        }
        if (DeleteFlag == 2) {
          this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);


        }
      }
      this.IsLoading = false;
      subscribePer$.unsubscribe();
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
        subscribePer$.unsubscribe();
      });

    //this.subscriptionList$.push(subscribe$);
  }

  ValidateLSEEnhancementForm(): boolean {
    let valid = false;

    if (
      isNullOrUndefined(this.LSEEnhancementFormDetails.Model) ||
      isNullOrUndefined(this.LSEEnhancementFormDetails.HoursofEfforts) ||
      isNullOrUndefined(this.LSEEnhancementFormDetails.CapacityPer)
    ) {
      this.toastr.warning('Please fill all mandatory fields');
      valid = false;
    } else {
      valid = true;
    }

    return valid;
  }

  // ValidateEEffortsDistributionPer(): boolean {
  //   let valid: boolean = false;
  //   let YEAR1_SUM: any = '';
  //   let YEAR2_SUM: any = '';
  //   let YEAR3_SUM: any = '';
  //   let YEAR4_SUM: any = '';
  //   let YEAR5_SUM: any = '';
  //   let YEAR6_SUM: any = '';
  //   let YEAR7_SUM: any = '';
  //   let valid_enteries: any = [];

  //   switch (this.DealData.DealTerm) {
  //     case 1:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       break;

  //     case 2:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR2_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year2 != undefined || item.Year2 != null || item.Year2 != '')
  //         .map((item: any) => +item.Year2)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR2_SUM);



  //       if (!isNullOrUndefined(YEAR2_SUM)) {
  //         if (YEAR2_SUM > 100 || YEAR2_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       break;
  //     case 3:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR2_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year2 != undefined || item.Year2 != null || item.Year2 != '')
  //         .map((item: any) => +item.Year2)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR2_SUM);



  //       if (!isNullOrUndefined(YEAR2_SUM)) {
  //         if (YEAR2_SUM > 100 || YEAR2_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR3_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year3 != undefined || item.Year3 != null || item.Year3 != '')
  //         .map((item: any) => +item.Year3)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR3_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR3_SUM)) {
  //         if (YEAR3_SUM > 100 || YEAR3_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }

  //       break;
  //     case 4:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR2_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year2 != undefined || item.Year2 != null || item.Year2 != '')
  //         .map((item: any) => +item.Year2)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR2_SUM);



  //       if (!isNullOrUndefined(YEAR2_SUM)) {
  //         if (YEAR2_SUM > 100 || YEAR2_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR3_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year3 != undefined || item.Year3 != null || item.Year3 != '')
  //         .map((item: any) => +item.Year3)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR3_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR3_SUM)) {
  //         if (YEAR3_SUM > 100 || YEAR3_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }



  //       YEAR4_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year4 != undefined || item.Year4 != null || item.Year4 != '')
  //         .map((item: any) => +item.Year4)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR4_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR4_SUM)) {
  //         if (YEAR4_SUM > 100 || YEAR4_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       break;
  //     case 5:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR2_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year2 != undefined || item.Year2 != null || item.Year2 != '')
  //         .map((item: any) => +item.Year2)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR2_SUM);



  //       if (!isNullOrUndefined(YEAR2_SUM)) {
  //         if (YEAR2_SUM > 100 || YEAR2_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR3_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year3 != undefined || item.Year3 != null || item.Year3 != '')
  //         .map((item: any) => +item.Year3)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR3_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR3_SUM)) {
  //         if (YEAR3_SUM > 100 || YEAR3_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }



  //       YEAR4_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year4 != undefined || item.Year4 != null || item.Year4 != '')
  //         .map((item: any) => +item.Year4)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR4_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR4_SUM)) {
  //         if (YEAR4_SUM > 100 || YEAR4_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       YEAR5_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year5 != undefined || item.Year5 != null || item.Year5 != '')
  //         .map((item: any) => +item.Year5)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR5_SUM);
  //       if (!isNullOrUndefined(YEAR5_SUM)) {
  //         if (YEAR5_SUM > 100 || YEAR5_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }



  //       break;
  //     case 6:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR2_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year2 != undefined || item.Year2 != null || item.Year2 != '')
  //         .map((item: any) => +item.Year2)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR2_SUM);



  //       if (!isNullOrUndefined(YEAR2_SUM)) {
  //         if (YEAR2_SUM > 100 || YEAR2_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR3_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year3 != undefined || item.Year3 != null || item.Year3 != '')
  //         .map((item: any) => +item.Year3)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR3_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR3_SUM)) {
  //         if (YEAR3_SUM > 100 || YEAR3_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }



  //       YEAR4_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year4 != undefined || item.Year4 != null || item.Year4 != '')
  //         .map((item: any) => +item.Year4)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR4_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR4_SUM)) {
  //         if (YEAR4_SUM > 100 || YEAR4_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       YEAR5_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year5 != undefined || item.Year5 != null || item.Year5 != '')
  //         .map((item: any) => +item.Year5)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR5_SUM);
  //       if (!isNullOrUndefined(YEAR5_SUM)) {
  //         if (YEAR5_SUM > 100 || YEAR5_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }

  //       /////////

  //       YEAR6_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Yea6 != undefined || item.Year6 != null || item.Year6 != '')
  //         .map((item: any) => +item.Year6)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR6_SUM);
  //       if (!isNullOrUndefined(YEAR6_SUM)) {
  //         if (YEAR6_SUM > 100 || YEAR6_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       //////
  //       break;
  //     case 7:
  //       YEAR1_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year1 != undefined || item.Year1 != null || item.Year1 != '')
  //         .map((item: any) => +item.Year1)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR1_SUM);

  //       if (!isNullOrUndefined(YEAR1_SUM)) {
  //         if (YEAR1_SUM > 100 || YEAR1_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR2_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year2 != undefined || item.Year2 != null || item.Year2 != '')
  //         .map((item: any) => +item.Year2)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR2_SUM);



  //       if (!isNullOrUndefined(YEAR2_SUM)) {
  //         if (YEAR2_SUM > 100 || YEAR2_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }


  //       YEAR3_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year3 != undefined || item.Year3 != null || item.Year3 != '')
  //         .map((item: any) => +item.Year3)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR3_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR3_SUM)) {
  //         if (YEAR3_SUM > 100 || YEAR3_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }



  //       YEAR4_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year4 != undefined || item.Year4 != null || item.Year4 != '')
  //         .map((item: any) => +item.Year4)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR4_SUM);
  //       //  let YEAR1 = this.LSEEffortsDistributionPerDetails.filter((x: any) => Number(x.Year1));

  //       if (!isNullOrUndefined(YEAR4_SUM)) {
  //         if (YEAR4_SUM > 100 || YEAR4_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       YEAR5_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year5 != undefined || item.Year5 != null || item.Year5 != '')
  //         .map((item: any) => +item.Year5)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR5_SUM);
  //       if (!isNullOrUndefined(YEAR5_SUM)) {
  //         if (YEAR5_SUM > 100 || YEAR5_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }



  //       /////////

  //       YEAR6_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Yea6 != undefined || item.Year6 != null || item.Year6 != '')
  //         .map((item: any) => +item.Year6)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR6_SUM);
  //       if (!isNullOrUndefined(YEAR6_SUM)) {
  //         if (YEAR6_SUM > 100 || YEAR6_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       //////
  //       YEAR7_SUM = this.LSEEffortsDistributionPerDetails.filter((item: any) => item.Year7 != undefined || item.Year7 != null || item.Year7 != '')
  //         .map((item: any) => +item.Year7)
  //         .reduce((sum: any, current: any) => sum + current);
  //       console.log(YEAR7_SUM);
  //       if (!isNullOrUndefined(YEAR7_SUM)) {
  //         if (YEAR7_SUM > 100 || YEAR7_SUM < 100) {
  //           //  this.showNotificationMessage('! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.', 2, '', '', '', '', 10000);
  //           //{
  //           this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");

  //           valid = false;
  //         }
  //         else {
  //           valid = true;
  //         }
  //         valid_enteries.push(valid);
  //       }
  //       break;



  //   }
  //   let totalvalidfalse = (valid_enteries.filter((x: any) => x === false)).length;
  //   console.log(totalvalidfalse);
  //   if (totalvalidfalse > 0) {
  //     valid = false;
  //   }
  //   console.log(totalvalidfalse);
  //   return valid
  // }

  ValidateEEffortsDistributionPer(): boolean {
    let valid: boolean = false;
    const valid_enteries: boolean[] = [];

    // Loop through each case based on DealTerm
    for (let i = 1; i <= this.DealData.DealTerm; i++) {
      const yearSums: number[] = [];

      // Calculate the sum for each year
      for (let j = 1; j <= i; j++) {
        const yearValue = this.LSEEffortsDistributionPerDetails
          .filter((item: any) => item[`Year${j}`] !== undefined && item[`Year${j}`] !== null && item[`Year${j}`] !== '')
          .map((item: any) => +item[`Year${j}`])
          .reduce((sum: number, current: number) => sum + current, 0);

        yearSums.push(yearValue);
      }

      // Validate each year sum
      for (let j = 0; j < yearSums.length; j++) {
        const yearSum = yearSums[j];

        if (isNaN(yearSum) || yearSum !== 100) {
          this.toastr.warning("! All Year Effort Distribution % should be equal to 100% and it should not be 0% or blank.'");
          valid = false;
        } else {
          valid = true;
        }

        valid_enteries.push(valid);
      }
    }

    // Count the number of invalid entries
    const totalInvalidEntries = valid_enteries.filter((x: boolean) => x === false).length;


    // If there are any invalid entries, set valid to false
    if (totalInvalidEntries > 0) {
      valid = false;
    }

    return valid;
  }



}
