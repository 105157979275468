export class Info {
    header!: string;
    message!: string;
    yes!: string;
    no?: string;
    IsRemarksApplicable?: boolean;
    IsRemarksMandatory?: boolean;
    NoteMessage?: string;
    IsCrossEnable?: boolean;
    Remarks?: string;

    cssyes?: string;
    cssno?: string;
    data?: string;

    IsHeaderHidden?: boolean = false;
    HeaderIcon?: string;
    SizeClass?: string = '';

    constructor(init?: Partial<Info>) {
        Object.assign(this, init);
    }
}

export class NotificationData {
    info?: Info;
    action?: any = null;
    item?: any = null;
    timeout?: any = null;
    constructor(init?: Partial<Notification>) {
        Object.assign(this, init);
    }

}
