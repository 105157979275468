import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from '../../shared/_services/utility.service';
import { AttachmentInfo, UploadAttachInfo } from '../attachment.interface';
import { AttachmentService } from '../attachment.service';
import { Subscription } from 'rxjs';
import { NotificationData } from '../../notification/notification.interface';
import { AppSharedService, AutoUnsubscribe } from 'src/app/shared/app.sharedservice';
import { number } from '@amcharts/amcharts4/core';


@Component({
    selector: 'file-upload',
    templateUrl: './fileupload.component.html'
})
@AutoUnsubscribe
export class FileUploadComponent implements OnInit {

    @Input() Module: string = '';
    @Input() RefId1: any = 0;
    @Input() RefId2?: any = 0;
    @Input() RefId3?: any = 0;
    @Input() RefId4?: any = 0;
    @Input() RefId5?: any = 0;
    @Input() RefId6?: any = 0;

    @Input() UploadType?: string = '';
    @Input() Permissions: any;
    @Input() IsMultiple?: boolean = true;

    //Note : Temporarly added to make file management service to work. this should be removed later.
    @Input() CandidateId: any = 0;

    @Output() close = new EventEmitter<any>();
    // @Output() reloadAttach = new EventEmitter<any>();

    public GlobalConfig: any = {};

    public AttachmentData!: AttachmentInfo[];
    public AttachedFiles: any = null;
    public selFileNames: string = '';
    public displayNotifybox: boolean = false;
    public IsLoading: boolean = false;
    public notify: NotificationData = {};
    private CompTitle: string = 'File Upload';

    public subscriptionList$: Subscription[] = [];
    public uploadSuccess: boolean = false;

    constructor(private _attachmentService: AttachmentService) { }

    ngOnInit() {
        if (isNullOrUndefined(this.Permissions)) {
            this.Permissions = { Upload: true, Download: true };
        }
    }

    closeUploader(): void {
        this.close.emit({ 'Reload': false });
    }

    getAttachments(): void {
        let odata: any = {
            'Module': this.Module, 'Reference1': this.RefId1,
            'Reference2': this.RefId2, 'Reference3': this.RefId3,
            'Reference4': this.RefId4, 'Reference5': this.RefId5, 'Reference6': this.RefId6
        };

        this._attachmentService.getAttachments(odata).subscribe(res => {
            if (res !== null && res.Status === 200) {
                this.AttachmentData = res.Data;
            }
            else {
                this.AttachmentData = [];
            }

        },
            err => {
                this.AttachmentData = [];
                // this.toastr.error('Error on getting Attachment data.', this.CompTitle);
            });
    }

    uploadFiles(): void {
        if (!isNullOrUndefined(this.AttachedFiles)) {

            this.AttachedFiles.TransactionId = 0;

            this.AttachedFiles.Module = this.Module;

            this.AttachedFiles.Reference1 = this.RefId1;
            this.AttachedFiles.Reference2 = this.RefId2;
            this.AttachedFiles.Reference3 = this.RefId3;
            this.AttachedFiles.Reference4 = this.RefId4;
            this.AttachedFiles.Reference5 = this.RefId5;
            this.AttachedFiles.Reference6 = this.RefId6;
            this.AttachedFiles.UserId = this.CandidateId;

            if (!isNullOrUndefined(this.selFileNames) && this.selFileNames !== '' && !this.validateFileName(this.selFileNames.split('.').slice(0, -1).join('.'))) {
                this.IsLoading = true;

                this.uploadSuccess = false;

                this._attachmentService.uploadAttachments(this.AttachedFiles).subscribe(res => {
                    if (res !== null && res.Status === 200) {
                        // alert('file Upload success');
                        this.notify = {
                            info: {
                                header: 'Confirmation Alert',
                                message: 'File uploaded successfully.',
                                yes: '',
                                no: '',

                                IsHeaderHidden: true,
                                HeaderIcon: 'fa fa-check-circle text-success',
                                IsCrossEnable: true,
                                SizeClass: 'modal-sm'
                            },

                            action: 'UPLOAD_SUCCESS',
                            item: res.Data
                        };

                        this.displayNotifybox = true;

                        this.selFileNames = '';
                        this.uploadSuccess = true;
                        // if (!isNullOrUndefined(this.Module) && this.Module !== '') {
                        //     // if (this.Module === 'interm') {
                        //     //     this.reloadAttach.emit({ 'Reload': true, 'data': res.Data });
                        //     // } else if (this.Module === 'Document') {
                        //     // this.reloadAttach.emit({ 'Reload': true, 'data': res.Data });
                        //     this.close.emit({ 'Reload': true, 'data': res.Data });
                        //     // }

                        // }

                    }
                    else {
                        this.notify = {
                            info: {
                                header: 'Confirmation Alert',
                                message: res.Message,
                                yes: '',
                                no: '',

                                IsHeaderHidden: true,
                                HeaderIcon: 'fa fa-exclamation-circle text-warning',
                                IsCrossEnable: true,
                                SizeClass: 'modal-sm'
                            },

                            action: '',
                            item: null
                        };

                        this.displayNotifybox = true;

                    }
                    this.IsLoading = false;
                },
                    err => {
                        // this.toastr.error('Error on file upload.', this.CompTitle);
                        this.IsLoading = false;
                    });

            }else{
                this.notify = {
                    info: {
                        header: 'Confirmation Alert',
                        message: 'Invalid file name. File name should not contain whitespaces.',
                        yes: '',
                        no: '',

                        IsHeaderHidden: true,
                        HeaderIcon: 'fa fa-exclamation-circle text-warning',
                        IsCrossEnable: true,
                        SizeClass: 'modal-sm'
                    },

                    action: '',
                    item: null
                };

                this.displayNotifybox = true;
            }
        }
        else {
            // this.toastr.warning('Kindly select a file for upload.', this.CompTitle);
            this.notify = {
                info: {
                    header: 'Confirmation Alert',
                    message: 'Kindly select a file for upload.',
                    yes: '',
                    no: '',

                    IsHeaderHidden: true,
                    HeaderIcon: 'fa fa-exclamation-circle text-warning',
                    IsCrossEnable: true,
                    SizeClass: 'modal-sm'
                },

                action: '',
                item: null
            };

            this.displayNotifybox = true;
        }
    }

    validateFileName(fileName: any): boolean {

        var specialChars = "<>[]{}?:;|'\"\\,/`=";

        
        let i: number = 0;

        for (i = 0; i < specialChars.length; i++) {
            if (fileName.indexOf(specialChars[i]) > -1) {
                return true
            }
        }

        return false;
    }

    handleUpload(e: any): void {
        if (e.target.files && e.target.files[0]) {
            let formData: FormData = new FormData();
            let filesToUpload = <Array<File>>e.target.files;
            let filenames: string = '';
            for (let i = 0; i < filesToUpload.length; i++) {
                formData.append('uploadedFiles', filesToUpload[i], filesToUpload[i].name);
                filenames = filenames + '; ' + filesToUpload[i].name;
                this.selFileNames = filenames.substr(2, filenames.length);
                this.AttachedFiles = formData;
            }

            // this.selFileNames = filesToUpload[0].name;

            // let reader = new FileReader();
            // reader.onload = (event: any) => {
            //     this.AttachedFiles = event.target.result;
            // }
            // reader.readAsDataURL(e.target.files[0]);
        }
        else {
            this.selFileNames = '';
            this.AttachedFiles = null;
        }

        // e.target.value = '';
    }

    deleteAttachment(Attachment: AttachmentInfo): void {
        if (!isNullOrUndefined(Attachment)) {
            this._attachmentService.deleteAttachment(Attachment).subscribe(res => {
                if (res !== null && res.Status === 200) {
                    // alert('file Delete success');
                    this.getAttachments();

                    // this.toastr.info('File deleted successfully.', this.CompTitle);
                    this.notify = {
                        info: {
                            header: 'Confirmation Alert',
                            message: 'File deleted successfully.',
                            yes: '',
                            no: '',

                            IsHeaderHidden: true,
                            HeaderIcon: 'fa fa-check-circle text-success',
                            IsCrossEnable: true,
                            SizeClass: 'modal-sm'
                        },

                        action: '',
                        item: null
                    };


                    this.displayNotifybox = true;

                }
            },
                err => {
                    // this.toastr.error('Error on file deletion.', this.CompTitle);
                    console.log('Error on file deletion.');
                });
        }
    }

    showDeleteConfirmation(curitem: AttachmentInfo): void {

        this.notify = {
            info: {
                header: 'Confirmation Alert',
                message: 'Are you sure you want to delete ?',
                yes: 'Yes',
                no: 'No',

                IsHeaderHidden: true,
                HeaderIcon: 'fa fa-check-circle text-success',
                IsCrossEnable: true,
                SizeClass: 'modal-sm'
            },

            action: 'DELETE',
            item: curitem
        };


        this.displayNotifybox = true;
    }

    downloadFile(item: AttachmentInfo) {

        if (this.Permissions.Download) {
            this._attachmentService.downloadFile(item).subscribe(res => {
                console.log('start download:', res);

                // if (typeof window.navigator.msSaveOrOpenBlob === 'function') { // IE & Edge
                //     // msSaveBlob only available for IE & Edge
                //     window.navigator.msSaveBlob(res.data, item.FileName);
                // }
                // else { // Chrome & FF
                let url = window.URL.createObjectURL(res.data);
                let a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                // a.download = res.filename;
                a.download = item.FileName!;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
                // }

            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            });

        }
        else {
            // this.toastr.info('You do not have rights to view this document!', this.CompTitle);
            console.log('You do not have rights to view this document!');
        }

    }


    setNotifyRes(event: any): void {
        console.log('notify res >> ' + JSON.stringify(event));
        // let canClose: boolean = true;

        // switch (event.action.toUpperCase()) {
        //     case 'UPLOAD_SUCCESS':
        //         if (event.result.toUpperCase() === 'YES') {
        //             this.close.emit({ 'Reload': true, 'data': event.item });
        //         }
        //         break;
        //     case 'DELETE':
        //         if (event.result.toUpperCase() === 'YES') {
        //             this.deleteAttachment(event.item);
        //         }
        //         break;
        // }

        // if (canClose) {
        //     this.closeNotifybox();
        // }

        this.closeNotifybox();
    }

    closeNotifybox(): void {
        this.displayNotifybox = false;

        if (this.uploadSuccess) {
            this.close.emit({ 'Reload': true, 'data': this.notify.item });
        }

    }

    onClick(event: any): void {
        let selitem: any = event.item;

        console.log(selitem);
    }

    onChange(event: any): void {
        let selitem: any = event.item;
        // Emitting onchange event of resource plan to parent container
        selitem.IsModified = 1;

        switch (event.fieldname.toUpperCase()) {
            case '':
                break;
        }
    }


}
