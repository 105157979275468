<ng-container>
  <div class="gridAction">
    <div class="d-flex justify-content-between">
      <label class="d-flex">
        <span class="pe-2">Show</span>
        <select class="form-select form-select-sm align-self-center" name="complex-table1_length"
          aria-controls="complex-table1" [(ngModel)]="curItemsPerPage" (change)="pageItemsChange($event)">
          <option value=10>10</option>
          <option value=25>25</option>
          <option value=50>50</option>
          <option value=100>100</option>
          <!-- <option value=-1>All</option> -->
        </select>
        <span class="ps-2">entries</span>
      </label>
      <ng-container
        *ngIf="ActionBarConfig.ActionBarList!=null && ActionBarConfig.ActionBarList!=undefined && ActionBarConfig.ActionBarList.length>0">
        <actionbar [actionList]="ActionBarConfig.ActionBarList" (butClick)="actButtonClick($event)"
          [noActionClass]="ActionBarConfig.noActionClass">
        </actionbar>
      </ng-container>
    </div>
  </div>


  <div class="gridContainer">
    <div class="table-responsive">
      <!-- (keydown)="cellKey($event)" -->
      <table class="table table-sm table-bordered tblGrid fixed" [ngClass]="TableCSS"
        [ngStyle]="{'min-width': TableWidth+'%'}">
        <caption class="sr-only">Grid Data</caption>
        <thead class="tblGridHeader" *ngIf="DisplayHeader" [ngClass]="HeaderCSS">
          <tr>
            <!-- [ngStyle]="{ 'width': setGridColWidth(key.FieldName, key.ColumnWidth), 'text-align':'center', 'padding-right': '2px'}" 
                            (click)="sortGridCol(key.FieldName)"
                        -->
            <!-- <th *ngIf="IsParallelEditEnabled" class="ParallelEditHeader" style="width: 3%" scope="col"></th> -->
            <th *ngIf="IsNested" class="NestedHeader" data-resize="false" scope="col">&nbsp;</th>
            <th *ngFor="let key of FieldTemplate" scope="col"
                [ngClass]="{'d-none': (key.ResponsiveMediaSize != null && key.ResponsiveMediaSize != undefined && key.ResponsiveMediaSize.length > 0
                                      && ((screenSize == 0 && key.ResponsiveMediaSize.indexOf('xs') < 0) || (screenSize == 1 && key.ResponsiveMediaSize.indexOf('sm') < 0) 
                                          || (screenSize == 2 && key.ResponsiveMediaSize.indexOf('md') < 0) || (screenSize == 3 && key.ResponsiveMediaSize.indexOf('lg') < 0) 
                                          || (screenSize == 4 && key.ResponsiveMediaSize.indexOf('xl') < 0)))}"
              [ngStyle]="{ 'width': ((key.Control?.Type == 'checkbox' && key.DisplayName.length == 0 )? '32px': setGridColWidth(key.FieldName, key.ColumnWidth)),
                            'min-width': (key.Control?.Type == 'checkbox'? '32px': '100px')}"
              [hidden]="!(DisplayColumns.indexOf(',' + key.FieldName + ',') >= 0 ? true : false)"
              [attr.data-resize]="(key.Control?.Type == 'checkbox'? false : (key.IsColResizable == null || key.IsColResizable == undefined)? true: key.IsColResizable)">

              <div (click)="sort(key)" [ngClass]="{'text-right': key.Control.InputType == 'number'}">
                <div *ngIf="key.HeaderTemplate == undefined || key.HeaderTemplate.length == 0">
                  <p class="text-truncate my-0">
                    <span
                      [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                      container="body" placement="bottom">{{key.DisplayName | translate}}</span>
                      <span class="sr-only">sort</span>
                    <i class="fa"
                      [ngClass]="{'fa-sort-up': (sortcolumn == (key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0? key.FieldName: key.LinkedField) && !isDesc), 
                                   'fa-sort-down': (sortcolumn == (key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0? key.FieldName: key.LinkedField) && isDesc) }"
                      aria-hidden="true"> </i>
                  </p>
                </div>

                <div *ngIf="key.HeaderTemplate != undefined && key.HeaderTemplate.length > 0"
                  [innerHtml]="key.HeaderTemplate" class="text-truncate"
                  [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                  container="body" placement="bottom">
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <!-- [ngStyle]="{ 'overflow': 'hidden', 'background-color': ((IsEditable == 'false' && (item_indx+1)%2 == 0) ? AlternateRowColor.even: AlternateRowColor.odd)}" -->
        <!-- <tbody *ngIf="isValidDataType()"> -->
        <tbody *ngIf="GridData.length > 0">
          <ng-template ngFor let-item
            [ngForOf]="PaginationConfig.ServerLoad? (GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
                        | filterBy: DataFilter | filterBy: {PageIndex: (Pagination.CurrentPage - 1)}) : 
                      (GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
                        | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage):(Pagination.CurrentPage*Pagination.ItemsPerPage))"
            let-item_indx="index">
            <!-- <ng-template ngFor let-item [ngForOf]="currentDataList" let-item_indx="index"> -->
            <tr
              [ngClass]="{'textBold': ((item.IsRowHighlight !=null && item.IsRowHighlight !=undefined && item.IsRowHighlight == true)? true: false)}">
              <!-- Parallel Editing User -->
              <!-- <td id="{{gridRandomId}}_Cell_{{item_indx}}--2_" class="ParallelEdit text-center" *ngIf="IsParallelEditEnabled" [tabindex]="item_indx"
                (click)="cellClick($event, item_indx, -2)">
                <ng-container
                  *ngIf="item.RowLockStatus != undefined && item.RowLockStatus != null && item.UserLockBy != undefined">
                  <span *ngIf="item.RowLockStatus == 2" class="badge badge-pill badge-success"
                    title="{{item.UserLockBy}}">
                    {{ item.UserLockBy.substring(0,1) }} </span>
                  <span *ngIf="item.RowLockStatus != 2" class="badge badge-pill badge-warning"
                    title="{{item.UserLockBy}}">
                    {{ item.UserLockBy.substring(0,1) }} </span>
                </ng-container>
              </td> -->
              <!-- Expand/Collapse Icon for Nested -->
              <td id="{{gridRandomId}}_Cell_{{item_indx}}--1_" class="Nested p-0" *ngIf="IsNested"
                (click)="cellClick($event, item_indx, -1)">
                <div *ngIf="(GridData | filterBy: getNestedData(item[ParentField])).length > 0">
                  <div class="text-center" *ngIf="selectedNest.indexOf(item[ParentField]) < 0"
                    (click)="setDispNested(true, item[ParentField])">
                    <span class="sr-only">right</span>
                    <i class="fa fa-caret-right"></i>
                  </div>
                  <div class="text-center" *ngIf="selectedNest.indexOf(item[ParentField]) >= 0"
                    (click)="setDispNested(false, item[ParentField])">
                    <span class="sr-only">down</span>
                    <i class="fa fa-caret-down"></i>
                  </div>
                </div>
              </td>
              <!-- Template columns -->
              <!-- (click)="cellClick($event, item_indx, key_indx)" -->
              <td *ngFor="let key of FieldTemplate;let key_indx= index" id="{{gridRandomId}}_Cell_{{item_indx}}-{{key_indx}}_" [ngClass]="{'d-none': (key.ResponsiveMediaSize != null && key.ResponsiveMediaSize != undefined && key.ResponsiveMediaSize.length > 0
                                            && ((screenSize == 0 && key.ResponsiveMediaSize.indexOf('xs') < 0) || (screenSize == 1 && key.ResponsiveMediaSize.indexOf('sm') < 0) 
                                                || (screenSize == 2 && key.ResponsiveMediaSize.indexOf('md') < 0) || (screenSize == 3 && key.ResponsiveMediaSize.indexOf('lg') < 0) 
                                                || (screenSize == 4 && key.ResponsiveMediaSize.indexOf('xl') < 0)))}"
                [ngStyle]="{'overflow': 'hidden'}" [attr.rowspan]="(key.RowMerge? getRowSpan(key, item): 1)"
                [hidden]="!(DisplayColumns.indexOf(',' + key.FieldName + ',') >= 0 ? true : false) || (key.RowMerge? !showMergeCell(key, item, item_indx) : false)">

                <!-- <field-control [parentRandomId]="gridRandomId" [item_indx]="item_indx" [key_indx]="key_indx" [item]="item" [key]="key"
                  [parent]="this.parent" [parentNode]="this" [(selectedCell)]="selectedCell"
                  (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"
                  [attr.data-ctrltype]="key.Control?.Type"></field-control> -->

                <ng-container
                  *ngTemplateOutlet="fieldcontrol; context:{item_indx:item_indx, key_indx:key_indx, item:item, key:key}">
                </ng-container>

              </td>
            </tr>

            <!-- New row added for showing the details of the column hidden because of ui responsiveness -->
            <tr class="row-detail border-top-0" *ngIf="(getColumnCount()-datacellindx) != FieldTemplate.length"
              [ngClass]="{'row-deleted': ((item.IsRevised !=null && item.IsRevised !=undefined && item.IsRevised == true && item.RowStatus != null && item.RowStatus != undefined && item.RowStatus == 'D')? true: false),
              'text-bold': ((item.IsRowHighlight !=null && item.IsRowHighlight !=undefined && item.IsRowHighlight == true && (item.RowStatus == null || item.RowStatus == undefined || item.RowStatus != 'D'))? true: false)}">

              <td class="border-top-0" [attr.colspan]="getColumnCount()">
                <div>
                  <ng-container *ngFor="let key of FieldTemplate;let key_indx= index">
                    <div *ngIf="(key.ResponsiveMediaSize != null && key.ResponsiveMediaSize != undefined && key.ResponsiveMediaSize.length > 0
                                && ((screenSize == 0 && key.ResponsiveMediaSize.indexOf('xs') < 0) || (screenSize == 1 && key.ResponsiveMediaSize.indexOf('sm') < 0) 
                                    || (screenSize == 2 && key.ResponsiveMediaSize.indexOf('md') < 0) || (screenSize == 3 && key.ResponsiveMediaSize.indexOf('lg') < 0) 
                                    || (screenSize == 4 && key.ResponsiveMediaSize.indexOf('xl') < 0)))"
                      id="{{gridRandomId}}_Cell_{{item_indx}}-{{key_indx}}_" [ngClass]="{'row pb-2': true}"
                      [ngStyle]="{'overflow': 'hidden'}"
                      [hidden]="!(DisplayColumns.indexOf(',' + key.FieldName + ',') >= 0 ? true : false) || (key.RowMerge? !showMergeCell(key, item, item_indx) : false)"
                      [attr.data-thtitle]="key.DisplayName">

                      <div class="col-4">
                        <p class="text-truncate text-bold my-0">
                          <span
                            [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                            container="body" placement="bottom">{{key.DisplayName | translate}}:</span>
                        </p>
                      </div>

                      <div class="col-8">
                        <ng-container
                          *ngTemplateOutlet="fieldcontrol; context:{item_indx:item_indx, key_indx:key_indx, item:item, key:key}">
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>

            <!-- SubGrid -->
            <tr class="subgrid"
              *ngIf="IsNested && !(NestedTemplate?.Inline) && (selectedNest.indexOf(item[ParentField]) >= 0)">
              <!-- Dummy cell -->
              <!-- <td class="ParallelEdit" *ngIf="IsParallelEditEnabled" [tabindex]="item_indx"></td> -->

              <td class="Nested"></td>
              <!-- [GridData]="GridData | filterBy: { FilterField: item[ParentField]}" -->
              <!-- <td [attr.colspan]="FieldTemplate.length + 1" style="padding: 0px !important"> -->
              <td *ngIf="!(NestedTemplate?.Inline)" [attr.colspan]="getColumnCount()-datacellindx">
                <grid-readonly *ngIf="NestedTemplate?.Type == 1" [FieldTemplate]="NestedTemplate?.Template!"
                  [GridData]="GridData | filterBy: getNestedData(item[ParentField])"
                  [GridProp]="NestedTemplate?.Property" [GridConfig]="NestedTemplate?.Config"
                  [GlobalConfig]="GlobalConfig"></grid-readonly>

                <!-- <form *ngIf="NestedTemplate.Type == 2" ></form> -->
              </td>
            </tr>

            <!-- Subitems as part of Grid -->
            <ng-container *ngIf="IsNested && NestedTemplate?.Inline">
              <tr class="subitem"
                *ngFor="let subitem of (GridData | filterBy: getNestedData(item[ParentField])); let subitem_indx = index"
                [hidden]="!(IsNested && NestedTemplate?.Inline && (selectedNest.indexOf(item[ParentField]) >= 0))"
                [ngClass]="{'textBold': ((subitem.IsRowHighlight !=null && subitem.IsRowHighlight !=undefined && subitem.IsRowHighlight == true)? true: false)}">
                <!-- Dummy cell -->
                <!-- <td id="{{gridRandomId}}_Cell_{{item_indx}}-{{subitem_indx}}--2_" class="ParallelEdit" *ngIf="IsParallelEditEnabled"
                [tabindex]="item_indx" (click)="cellClick($event, item_indx+'-'+subitem_indx, -2)"></td> -->
                <td id="{{gridRandomId}}_Cell_{{item_indx}}-{{subitem_indx}}--1_" class="Nested"
                  style="background-color: rgba(0, 0, 0, 0.1);" *ngIf="subitem_indx == 0"
                  [attr.rowspan]="(GridData | filterBy: getNestedData(item[ParentField])).length"
                  (click)="cellClick($event, item_indx+'-'+subitem_indx, -1)"></td>


                <!-- (click)="cellClick($event, item_indx+'-'+subitem_indx, innerkey_indx)" -->
                <td *ngFor="let innerkey of NestedTemplate?.Template;let innerkey_indx= index"
                  id="{{gridRandomId}}_Cell_{{item_indx}}-{{subitem_indx}}-{{innerkey_indx}}_" [ngStyle]="{ 'overflow': 'hidden'}"
                  [hidden]="!(DisplayColumns.indexOf(',' + innerkey.FieldName + ',') >= 0 ? true : false)">

                  <!-- <field-control [parentRandomId]="gridRandomId" [item_indx]="item_indx+'-'+subitem_indx" [key_indx]="innerkey_indx" [item]="subitem"
                  [key]="innerkey" [parent]="this.parent" [parentNode]="this"
                  [(selectedCell)]="selectedCell" (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"
                  (fldonEditStart)="fldonEditStart($event)" [attr.data-ctrltype]="innerkey.Control?.Type">
                </field-control> -->
                  <ng-container
                    *ngTemplateOutlet="fieldcontrol; context:{item_indx:item_indx+'-'+subitem_indx, key_indx:innerkey_indx, item:subitem, key:innerkey}">
                  </ng-container>


                </td>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>
        <tbody *ngIf="GridData.length == 0">
          <tr>
            <td class="text-center" style="background-color: #ffffff" [attr.colspan]="getColumnCount()">
              -- No Record Found --
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <pagination *ngIf="GridData.length > 0 && PaginationConfig.Required" [totalItems]="GridDataTotalCount"
    [itemsPerPage]="Pagination.ItemsPerPage" [maxSize]="5" [boundaryLinks]="true" previousText="&lsaquo;"
    nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="false" [(ngModel)]="Pagination.CurrentPage"
    (pageChanged)="pageChanged($event)"></pagination> --> 
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="align-self-center" *ngIf="GridData.length > 0">
        <!-- <ng-container > -->
          <!-- <span class="px-2">Showing 1 to 6 of 6 entries</span> -->
          <!-- <span class="px-2">Showing {{((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)+1}} to {{(Pagination.CurrentPage*Pagination.ItemsPerPage)}} of {{(GridDataTotalCount)}} entries</span> -->
          <span class="px-2">Showing {{((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)+1}} to {{((Pagination.CurrentPage-1)*Pagination.ItemsPerPage) + getCurrentDataList().length}} of {{(GridDataTotalCount)}} entries</span>
        <!-- </ng-container> -->
      </li>
      <li class="ms-auto page-item">
        <pagination *ngIf="GridData.length > 0 && PaginationConfig.Required" [totalItems]="GridDataTotalCount"
          [itemsPerPage]="Pagination.ItemsPerPage" [maxSize]="5" [boundaryLinks]="true" 
          previousText="&lsaquo; Previous" nextText="Next &rsaquo;"
          firstText="&laquo; First" lastText="Last &raquo;" [rotate]="false" [(ngModel)]="Pagination.CurrentPage" 
          (pageChanged)="pageChanged($event)" (numPages)="totalPagesChanged($event)"></pagination>
      </li>
    </ul>
  </nav>

  <!--<div *ngIf="IsEditable && GridData.length > 0">
            <!-- [dismissOnTimeout]="6000"
        <alert type="info" >
            <strong>Keys for Navigating/Editing Grid:</strong> 
            <li>Tab/Right Arrow - Move focus to next cell in the right.</li>
            <li>Shift+Tab/Left Arrow - Move focus to previous cell in the left.</li>
            <li>Up Arrow - Move focus to the cell above.</li>
            <li>Down Arrow - Move focus to the cell below.</li>
            <!-- <li>F2/Spacebar - Editing a cell.</li>
            <li>F2 - Editing a cell.</li>
            <li>Esc/Enter - Switching out of cell edit.</li>
        </alert>
    </div>-->

  <ng-template #fieldcontrol let-item_indx="item_indx" let-key_indx="key_indx" let-item="item" let-key="key">

    <ng-container *ngIf="(key.Control?.Type != 'button' && key.Control?.Type != 'icon' && key.Control?.Type != 'link'
      && key.Control?.Type != 'checkbox' && key.Control?.Type != 'badge' && key.Control?.Type != 'dataimg')">
      <div [ngClass]="{'text-right': key.Control.InputType == 'number'}">
        <div *ngIf="key.LabelTemplate == undefined || key.LabelTemplate == null || key.LabelTemplate.length == 0">
          <p *ngIf="key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0"
            class="text-truncate" [ngStyle]="key.Style">
            <span [ngClass]="key.Cssnames" *ngIf="key.Control?.Type == 'datepicker'" [tooltip]="tooltipTemplate"
              container="body" placement="top" html="true">
              {{ (item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ?
              ' ':
              ( (key.Control.Format != null && key.Control.Format != undefined && (key.Control.Format.length > 0 &&
              key.Control.Format.toLowerCase()) == 'true') ? (item[key.FieldName] | localizedDateTime) :
              (item[key.FieldName] | localizedDate) ) }}
            </span>
            <!-- <span [ngClass]="key.Cssnames" *ngIf="key.Control?.Type == 'datepicker'" [tooltip]="tooltipTemplate" container="body" placement="top" html="true">
          {{ (item[key.FieldName] == null || item[key.FieldName] == undefined || item[key.FieldName].length == 0 ) ? ' ':
            ( (key.Control.Format != null && key.Control.Format != undefined && (key.Control.Format.length > 0 &&
            key.Control.Format.toLowerCase()) == 'true') ? (item[key.FieldName] | date: translate['AppDateTimeFormat']) :  (item[key.FieldName] | date: translate['AppDateFormat']) ) }}
        </span> -->

            <span [ngClass]="key.Cssnames" *ngIf="key.Control?.Type != 'datepicker'" [tooltip]="tooltipTemplate"
              container="body" placement="top" html="true">
              {{ key.Control?.Type == 'textbox' && key.Control.InputType == 'number' &&
              (key.Control.Format == null || key.Control.Format == undefined || (key.Control.Format.length > 0 &&
              key.Control.Format.toLowerCase()) == 'true') ? ( item[key.FieldName] | localizedNumber ) :
              item[key.FieldName] }}
            </span>
          </p>

          <p *ngIf="key.LinkedField != undefined && key.LinkedField != null && key.LinkedField.length > 0"
            class="text-truncate">
            <span [ngClass]="key.Cssnames" [tooltip]="item[key.LinkedField]" container="body"
              placement="top">{{item[key.LinkedField]}}</span>
          </p>
        </div>

        <div *ngIf="key.LabelTemplate != undefined && key.LabelTemplate != null && key.LabelTemplate.length > 0">
          <p class="text-truncate">
            <span [ngClass]="key.Cssnames" [tooltip]="tooltipTemplate" container="body" placement="top">
              <div *dynamicComponent="key.LabelTemplate; context: {item: item, parent: this};"></div>
            </span>
          </p>
        </div>

      </div>
    </ng-container>

    <ng-template #tooltipTemplate>
      <div *ngIf="key.ToolTipTemplate == undefined || key.ToolTipTemplate == null || key.ToolTipTemplate.length == 0"
        [innerHtml]="getToolTipContent(key,item)"></div>

      <ng-container
        *ngIf="key.ToolTipTemplate != undefined && key.ToolTipTemplate != null && key.ToolTipTemplate.length > 0">
        <div *dynamicComponent="key.ToolTipTemplate; context: {item: item};"></div>
      </ng-container>
    </ng-template>

    <div class="text-center" *ngIf="key.Control?.Type == 'checkbox'">
      <label for="item[key.FieldName]" class="sr-only">
      checkboxvalue
      </label>
      <input id="item[key.FieldName]" type="checkbox" [(ngModel)]="item[key.FieldName]" [disabled]="true" />
    </div>

    <!-- <ng-container *ngIf="key.Control?.Type == 'button'">
    <button type="button" name="key.FieldName" [class]="key.Cssnames" [ngStyle]="key.Style" [disabled]="!enableAction()"
      (click)="ctrlClick()">
      {{key.FieldName}}
    </button>
  </ng-container> -->

    <ng-container *ngIf="key.Control?.Type == 'link'">
      <a [attr.aria-label]="key?.FieldName" [href]="parseURL(key,item)" [target]="key.Control.Source.Target" [ngStyle]="key.Style" [class]="key.Cssnames"
        [tooltip]="tooltipTemplate" container="body" placement="top">
        {{item[key.FieldName]}}
      </a>
    </ng-container>

    <ng-container *ngIf="key.Control?.Type == 'icon'">
      <!-- No icon list -->
      <ng-container
        *ngIf="key.Control.IconList == undefined || key.Control.IconList == null || key.Control.IconList.length == 0">
        <!-- <ng-container *ngIf="(key.Control.InputType=='action')">
        <i *ngIf="enableAction()" [class]="key.Cssnames" [ngStyle]="key.Style" (click)="ctrlClick()"></i>
        <i *ngIf="!enableAction()" [class]="key.Cssnames" [ngStyle]="{'color':'grey'}"></i>
      </ng-container>
      <ng-container *ngIf="(key.Control.InputType=='info')"> -->
        <i [tooltip]="item[key.FieldName]" [class]="key.Cssnames" [ngStyle]="key.Style"></i>
        <!-- </ng-container> -->
      </ng-container>

      <!-- icon to be displayed based on fieldvalues from list of icons -->
      <ng-container
        *ngIf="key.Control.IconList != undefined && key.Control.IconList != null && key.Control.IconList.length > 0">
        <!-- <ng-container *ngIf="(key.Control.InputType=='action')">
        <ng-container *ngFor='let icondet of getIconCSSClassList(key,item)'>
          <i *ngIf="enableAction()" [class]="icondet.IconCSS" [ngStyle]="key.Style" (click)="ctrlIconClick(icondet)"></i>
          <i *ngIf="!enableAction()" [class]="icondet.IconCSS" [ngStyle]="{'color':'grey'}"></i>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="(key.Control.InputType=='info')"> -->
        <span class="sr-only">tooltip</span>
        <i [tooltip]="item[key.FieldName]" [class]="getIconCSSClass(key,item)" [ngStyle]="key.Style"></i>
        <!-- </ng-container> -->
      </ng-container>
    </ng-container>

    <ng-container *ngIf="key.Control?.Type == 'badge'">
      <!-- No list -->
      <ng-container
        *ngIf="key.Control.IconList == undefined || key.Control.IconList == null || key.Control.IconList.length == 0">
        <span [class]="key.Cssnames" [ngStyle]="key.Style" [tooltip]="tooltipTemplate">{{item[key.FieldName] |
          translate}}</span>
      </ng-container>

      <!-- badge to be displayed based on fieldvalues from list -->
      <ng-container
        *ngIf="key.Control.IconList != undefined && key.Control.IconList != null && key.Control.IconList.length > 0">
        <ng-container *ngFor='let icondet of getIconCSSClassList(key,item)'>
          <span [class]="icondet.IconCSS" [ngStyle]="key.Style" [tooltip]="tooltipTemplate">{{item[key.FieldName] |
            translate}}</span>
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="key.Control?.Type == 'dataimg'">
      <img alt="Capgemini" [src]="sanitizer.bypassSecurityTrustResourceUrl(item[key.FieldName])" />
    </ng-container>


  </ng-template>
</ng-container>