export class ServiceDomainInf {
    DealHeaderId: number = 0;
    DealLineItemId: number = 0;
    DealSubLineItemId: number = 0;
    LSDeliveryLocationModellingId: number = 0;
    TechnologyProfile?: string = '';
    TechnologyProfileDesc?: string = '';
    DeliveryUnit?: string = '';
    DeliveryUnitDesc?: string = '';
    TowerType?: string = '';
    TowerTypeDesc?: string = '';
    ServiceDomain?: string = '';
    ServiceDomainDesc?: string = '';
    IsEditable: number = 1;
    IsModified: number = 0;

    constructor(init?: Partial<ServiceDomainInf>) {
        Object.assign(this, init);
    }
}
