<ng-container *ngIf="DealSubLineItemId > 0">

    <p class="text-bold mb-1">
        <span>Productivity Influencers Year 1 % (Lot:{{LotName}})</span> <span
            data-bs-toggle="tooltip" data-bs-placement="bottom"
            title="This sub-panel provides choices to define the attributes of a lot - the parameters and their value selection influences productivity % and provides recommended values."><img
                src="../../../assets/images/information-Icons.svg" alt="information icon"></span>
    </p>

    <ng-container
        *ngIf="(ProductivityImpactTemplate != null && ProductivityImpactTemplate != undefined && ProductivityImpactTemplate.length > 0)">
        <grid-dispctrl #ProductivityImpactGrid [FieldTemplate]="ProductivityImpactTemplate"
            [GridData]="productivityImpactList" [GridProp]="GridProp"
            [IsPagination]="IsPagination"    [GridDataTotalCount]="productivityImpactListDataCount" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </grid-dispctrl>
    </ng-container>
    <br>
    <ng-container
        *ngIf="(UpFrontProductivityTemplate != null && UpFrontProductivityTemplate != undefined && UpFrontProductivityTemplate.length > 0)">
        <customform #UpFrontProductivityForm [FormTemplate]="UpFrontProductivityTemplate"
            [FormData]="upFrontProductivityData" [FormProp]="FormProp" [FormConfig]="FormConfig"
            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>

    <ng-container
        *ngIf="(ProductivityGuideLinesTemplate != null && ProductivityGuideLinesTemplate != undefined && ProductivityGuideLinesTemplate.length > 0
        && productivityGuideLinesList != null && productivityGuideLinesList != undefined && productivityGuideLinesList.length > 0)">
        <grid-dispctrl #ProductivityGuideLinesGrid [FieldTemplate]="ProductivityGuideLinesTemplate"
            [GridData]="productivityGuideLinesList" [GridProp]="GridProp"
            [GridDataTotalCount]="productivityGuideLinesListDataCount" (FieldClick)="onClick($event)"
            [IsPagination]="IsPagination" (FieldChange)="onChange($event)">
        </grid-dispctrl>
    </ng-container>

    <div class="filters buttonHolder" id="create-deal">
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="ActionButtons != null && ActionButtons != undefined">
                <ng-container *ngFor="let actionBar of ActionButtons 
      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        | translate}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>


    <!-- Loader section -->
    <div [hidden]="!IsLoading">
        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
    </div>

    <notification *ngIf="displayNotifybox" [notifyinfo]="notify?.info" [timeout]="notify.timeout"
        [curaction]="notify.action" [curitem]="notify.item" (selected)="setNotifyRes($event)"
        (close)="closeNotifybox()">
    </notification>

</ng-container>