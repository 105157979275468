export class ServicetypeInfo {
  
    TransactionId: number = 0;
    ServiceTypeTransactionId: number = 0;
    DealHeaderId: string = '';
    ServiceType: string = '';
    DealLIneitemId: string = '';
    Volume: string = '';
    MappedRole: string = '';
    MappedYoE: string = '';
    NoofDaysinMonth: string = '';
    UpliftDownsize: string = '';
    HrsDay: string = '';
    ProductivityandInflation: string = '';
    Size: string = '';
    Phase: string = '';
    Location: string = '';
    NoofMonths: string = '';
    Source: string = '';
    Subtower: string = '';
    Tower: string = '';
    TowerDesc: string = '';
    Offering: string = '';
    Technology: string = '';
    Complexity: string = '';
    PricingUnit: string = '';
    SLA: string = '';
    DeliveryModel: string = '';
    ServiceWindow: string = '';
    OnsiteCountry: string = '';
    OffshoreCountry: string = '';
    Year1OffshorePer: string = '';
    Year1Volume: string = '';
    Year2OffshorePer: string = '';
    Year2Volume: string = '';
    Year3OffshorePer: string = '';
    Year3Volume: string = '';
    Year4OffshorePer: string = '';
    Year4Volume: string = '';
    Year5OffshorePer: string = '';
    Year5Volume: string = '';
    Year6OffshorePer: string = '';
    Year6Volume: string = '';
    Year7OffshorePer: string = '';
    Year7Volume: string = '';
    Year8OffshorePer: string = '';
    Year8Volume: string = '';
    Year9OffshorePer: string = '';
    Year9Volume: string = '';
    Year10OffshorePer: string = '';
    Year10Volume: string = '';
    Year2Inflation: string = '';
    Year2Productivity: string = '';
    Year3Inflation: string = '';
    Year3Productivity: string = '';
    Year4Inflation: string = '';
    Year4Productivity: string = '';
    Year5Inflation: string = '';
    Year5Productivity: string = '';
    Year6Inflation: string = '';
    Year6Productivity: string = '';
    Year7Inflation: string = '';
    Year7Productivity: string = '';
    Year8Inflation: string = '';
    Year8Productivity: string = '';
    Year9Inflation: string = '';
    Year9Productivity: string = '';
    Year10Inflation: string = '';
    Year10Productivity: string = '';

    FTECountY1: string = '';
    FTECountY2: string = '';
    FTECountY3: string = '';
    FTECountY4: string = '';
    FTECountY5: string = '';
    FTECountY6: string = '';
    FTECountY7: string = '';
    FTECountY8: string = '';
    FTECountY9: string = '';
    FTECountY10: string = '';
    Internal25thPercentileY1: string = '';
    Internal50thPercentileY1: string = '';
    External25thPercentileY1: string = '';
    External50thPercentileY1: string = '';
    Comments: string = '';
    InternalSource: string = '';
    ExternalSource: string = '';
    IsEditable: number = 1;
    IsModified: number = 0;
    IsSelected: boolean = false;
    IsValid: boolean = false;
    IsOffshoreVolumeFlag: number = 0;
    IsProductivityFlag: number = 0;
    IsFTEcountFlag: number = 0;
    SLADesc: null | undefined;
    DeliveryModelDesc: null | undefined;
    ServiceWindowDesc: any;
    OffshoreCountryDesc: null | undefined;
    OnsiteCountryDesc: null | undefined;
    ToolName:any;
    DealStatus:any;
    constructor(init?: Partial<ServicetypeInfo>) {
        Object.assign(this, init);
    }
}

export class ProductivityInterface {
    ProductivityDesc: String = '';
    ServiceTypeTransactionId: number = 0;
    DealHeaderId: string = '';
    ServiceType: string = '';
    DealLIneitemId: string = '';
    Year2: string = '';
    Year3: string = '';
    Year4: string = '';
    Year5: string = '';
    Year6: string = '';
    Year7: string = '';
    Year8: string = '';
    Year9: string = '';
    Year10: string = '';
    TotalMonths: number = 0;
    IsEditable: number = 1;
    IsModified: number = 0;
    constructor(init?: Partial<ProductivityInterface>) {
        Object.assign(this, init);
    }

}
export class FTEInterface {
    FTEDesc: String = '';
    ServiceTypeTransactionId: number = 0;
    DealHeaderId: string = '';
    ServiceType: string = '';
    DealLIneitemId: string = '';
    Y1: string = '';
    Y2: string = '';
    Y3: string = '';
    Y4: string = '';
    Y5: string = '';
    Y6: string = '';
    Y7: string = '';
    Y8: string = '';
    Y9: string = '';
    Y10: string = '';
    TotalMonths: number = 0;
    IsEditable: number = 1;
    IsModified: number = 0;
    constructor(init?: Partial<FTEInterface>) {
        Object.assign(this, init);
    }

}
export class OffshorePerInterface {

    OffshoreVolumeDesc: String = '';
    ServiceTypeTransactionId: number = 0;
    DealHeaderId: string = '';
    ServiceType: string = '';
    DealLIneitemId: string = '';
    Year1: string = '';
    Year2: string = '';
    Year3: string = '';
    Year4: string = '';
    Year5: string = '';
    Year6: string = '';
    Year7: string = '';
    Year8: string = '';
    Year9: string = '';
    Year10: string = '';
    DealTerm: number = 0;
    IsEditable: number = 1;
    IsModified: number = 0;
    constructor(init?: Partial<OffshorePerInterface>) {
        Object.assign(this, init);
    }
}