
import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, isNullOrUndefined } from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { AmDownloadsService } from './am-downloads.service';
import { ReportFormDetails, ChartDetails } from './am-downloads.interface';
import { FormComponent } from 'src/modules/form/form.component';
import { Title } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { DatePipe } from '@angular/common';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ToastrService } from 'ngx-toastr';
import { isTemplateMiddle } from 'typescript';

@Component({
  selector: 'app-am-downloads',
  templateUrl: './am-downloads.component.html',
  styleUrls: ['./am-downloads.component.css']
})
export class AmDownloadsComponent implements OnInit {
  @ViewChild('downloadsForm', { static: false }) downloadsForm!: FormComponent;

  public ReportConfigDetails: any;
  public AMReportsTabs: any;
  public ReportDisplayName: string = '';
  public Title: any = 'Reports';
  public ModuleName: string = '';
  public GlobalConfig: any = {};
  public ReportsFormTemplate: FieldTemplate[] = [];
  public ReportsTemplate: FieldTemplate[] = [];
  public ReportsTemplate_ADMT: FieldTemplate[] = [];
  public ReportGridData_ADMT: any = [];
  public LocationCardDetails: any;
  public ReportGridData_ADMT_grid: any = [];
  public ReportGridData_ADMT_grid1: any = [];
  public PyramidGridTemplate: FieldTemplate[] = [];
  public EffortsGridTemplate: FieldTemplate[] = [];
  public compareflag: boolean = false;
  public uploadflag: boolean = false;
  public BlendedRateGraph: any;
  public TotalRevenueGraph: any;
  public BlendedRatePer_Ons: any;
  public BlendedRatePer_Off: any;
  public BlendedRatePer_Nea: any;
  public ContingencyPer_Ons: any;
  public ContingencyPer_Off: any;
  public ContingencyPer_Nea: any;
  public chartDisplayName: string = '';
  public chartPlotName_pastD1: string = '';
  public chartPlotName_pastD2: string = '';
  public chartPlotName_pastD3: string = '';
  public chartPlotName: string = '';
  public ReportTYPE: any;
  public selectedTab: string = '';
  public selectedTabAPI: string = '';
  public selectedTabDetails: any;
  public chartsInput: ChartDetails = new ChartDetails();
  public chartsInputSecond: ChartDetails = new ChartDetails();
  public chartsInputThird: ChartDetails = new ChartDetails();

  public applyListitems: any = [];
  public GridProp: any = {
    Editable: true,
    TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }

  public Config: any = {
    ActionButtons: []
  };


  public IsPagination: boolean = false;
  public ProductivityPer: any;
  public DealDiscountPer: any;

  public ExpensesPer: any;
  public prod_range: any;
  public expense_range: any;
  public Deal_range: any;
  public Blended_range1: any;
  public Blended_range2: any;
  public Blended_range3: any;
  public contigency_range1: any;
  public contigency_range2: any;
  public contigency_range3: any;
  public IsLoading: boolean = false;
  public AMConfigPermission: any = [];
  public AMProperties: any = [];
  public selectedInfoData: any;
  public ReportFormData: ReportFormDetails = new ReportFormDetails();
  public FormGroupName: string = '';
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public downloadDealAnalysisFlag: boolean = false;
  public displayNotifyDeliveryModel: boolean = false;
  public notify: NotificationData = new NotificationData();
  public fileToUpload: File | any;
  public DataTotalCount: number = 0;
  public filename: any;
  public modalInf: ModalInterface = new ModalInterface();
  public GridProp1: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
  }

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  constructor(
    public datepipe: DatePipe,
    private _commonAPIService: CommonAPIService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _appSharedService: AppSharedService,
    public AmDownloadsService: AmDownloadsService,
    private title: Title,
    private modalService: ModalService,
    private toastr: ToastrService,) {

      let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
        this.ngOnInit();
      });
      this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void {
    this.title.setTitle(this.Title);
    this.ModuleName = '';
    this.ModuleName = this._route.snapshot.data['selectedModule'];
    this.getComponentConfig();

  }

  getComponentConfig() {
   // this.IsLoading = true;
    this.ReportsTemplate = [];
    this.ReportsFormTemplate = [];

    let odata: ComponentConfig = {
      Component: 'AmReports', ComponentCode: ComponentCodes.AMReports, ReferenceValueType: "1"
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        this.ReportsTemplate = res.Data.FieldTemplate!;
        this.AMConfigPermission = res.Data.Permission;
        this.AMProperties = res.Data.Properties;

        let dealContractMinDate = this.ReportsTemplate.filter(x => x.Group === 'AMDealAnalysisReport').filter(x => x.FieldName == "DealCreatedDateFrom");
        let dealContractMaxDate = this.ReportsTemplate.filter(x => x.Group === 'AMDealAnalysisReport').filter(x => x.FieldName == "DealCreatedDateTo");
        dealContractMinDate[0].EditValidate.Max = new Date();
        dealContractMaxDate[0].EditValidate.Max = new Date();

        this.setCompProperties(res.Data.Properties!);
        let filteredTemplate = JSON.parse(JSON.stringify(this.ReportsTemplate));
        this.ReportsFormTemplate = filteredTemplate.filter((x: any) => x.Group === this.FormGroupName);
        this.ReportFormData.DealName = this._appSharedService.currentDealData.DealName;
        this.ReportFormData.ModelId = this._appSharedService.currentDealData.ModelID
        this.ReportsTemplate_ADMT = filteredTemplate.filter((x: any) => x.Group == 'AMADMTDealDetailsGrid');
        this.PyramidGridTemplate = filteredTemplate.filter((x: any) => x.Group == 'PyaramidPerPopUpADMT');
        this.EffortsGridTemplate = filteredTemplate.filter((x: any) => x.Group == 'EffortPerPopUpADMT');
        console.log(this.ReportsFormTemplate)
        this.setActionButtonPermissions();

        if (this.ModuleName == 'quickdealupload') {

          this.openModal('OpenQuickDealUpload');
          this.modalInf =
          {
            header: '',
            size: 'modal-dialog modal-fullscreen'
          };

        }

      }

    },
      err => {
       // this.IsLoading = false;
        console.log('Error on getComponentConfig.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  // public downloadFile(response: any, fileName: string) {
  //   this.IsLoading = true;
  //   setTimeout(() => {
  //   fileName = fileName;
  //   const blob = new Blob([response.body], { type: 'application/octet-stream' });
  
  //   FileSaver.saveAs(blob, fileName + '.xlsx');
    
  //   this.IsLoading = false;
  // }, 1000);

  // }


  public async downloadFile(response: any, fileName: string) {
    this.IsLoading = true;
  
    // Display loader
   // showLoader();
  
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        fileName = fileName;
        const blob = new Blob([response.body], { type: 'application/octet-stream' });
  
        // Initiate file download
        FileSaver.saveAs(blob, fileName + '.xlsx');
  
        // Resolve the promise after the download is initiated
        resolve();
      }, 2000);
    });
  
    // Hide loader
   // hideLoader();
    this.IsLoading = false;
  }
  






  validateDownloads(modifieditems: any): boolean {
    let valid: boolean = false;
    if (this.ModuleName === "dealanalysis") {
      this.downloadDealAnalysisFlag = true;
    }

    if (!isNullOrUndefined(modifieditems)) {
      if (this.ModuleName === "dealanalysis") {
        if ((isNullOrUndefined(modifieditems.DealCreatedDateFrom)) || (isNullOrUndefined(modifieditems.DealCreatedDateTo)) || (isNullOrUndefined(modifieditems.DealStatus))
          || modifieditems.DealCreatedDateFrom == '' || modifieditems.DealCreatedDateTo == '' || modifieditems.DealStatus == '') {
          this.downloadDealAnalysisFlag = false;
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          valid = false;
        } else {
          valid = true;
        }
      }

      if (this.ModuleName === "PegaDealAnalysis") {
        if ((isNullOrUndefined(modifieditems.DealCreatedDateFrom)) || (isNullOrUndefined(modifieditems.DealCreatedDateTo)) || (isNullOrUndefined(modifieditems.DealStatus))
          || modifieditems.DealCreatedDateFrom == '' || modifieditems.DealCreatedDateTo == '' || modifieditems.DealStatus == '') {
          this.downloadDealAnalysisFlag = false;
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          valid = false;
        } else {
          valid = true;
        }
      }
      else if (this.ModuleName === "consolidated") {
        if ((isNullOrUndefined(modifieditems.DealName)) || (isNullOrUndefined(modifieditems.ModelId)) || (isNullOrUndefined(modifieditems.ReportType)) ||
          modifieditems.DealName == '' || modifieditems.ModelId == '' || modifieditems.ReportType == '') {
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          valid = false;
        } else {
          valid = true;
        }
      }
      else if (this.ModuleName === "masterdatadownload") {
        if ((isNullOrUndefined(modifieditems.MasterData)) || modifieditems.MasterData == '') {
          this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
          valid = false;
        } else {
          valid = true;
        }
      }

    }
    return valid
  }

  DownloadConsolidateReport() {
    if (this.validateDownloads(this.ReportFormData)) {

      let odata = {
        DealName: this.ReportFormData.DealName,
        ModelID: this.ReportFormData.ModelIdDesc,
        ReportType: this.ReportFormData.ReportType,
        Currency: this.ReportFormData.Currency
      }
      let subscribe$: Subscription = this.AmDownloadsService.DownloadExcel(odata).subscribe(res => {
        this.downloadFile(res, 'SolutionAndPriceSummary');
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          console.log('Error on DownloadConsolidateReport.');
          this.showNotificationMessage('Download Failed', 2, '', null, '', '', 1000);
        });

      this.subscriptionList$.push(subscribe$);

    }

  }

  GetDealAnalysisReport() {
    if (this.validateDownloads(this.ReportFormData)) {
      let latest_fromdate = this.datepipe.transform(this.ReportFormData.DealCreatedDateFrom, "yyyy-MM-dd HH:mm:ss.SSS");
      let latest_todate = this.datepipe.transform(this.ReportFormData.DealCreatedDateTo, "yyyy-MM-dd HH:mm:ss.SSS");
      let odata = {
        SBU: this.ReportFormData.SBU,
        Sector: this.ReportFormData.CapgeminiSectors,
        DealCreatedDateFrom: latest_fromdate,
        DealCreatedDateTo: latest_todate,
        DealStatus: this.ReportFormData.DealStatus,
        Currency: this.ReportFormData.Currency

      }
      let subscribe$: Subscription = this.AmDownloadsService.GetDealAnalysisReport(odata).subscribe(res => {
        this.downloadDealAnalysisFlag = false;
        this.downloadFile(res, 'DealAnalysis');
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          this.downloadDealAnalysisFlag = false;
          console.log('Error on GetDealAnalysisReport.');
          this.showNotificationMessage('Download Failed', 2, '', null, '', '', 1000);
        });

      this.subscriptionList$.push(subscribe$);

    }

  }

  GetPegaDealAnalysisReport() {
    if (this.validateDownloads(this.ReportFormData)) {
      let latest_fromdate = this.datepipe.transform(this.ReportFormData.DealCreatedDateFrom, "yyyy-MM-dd HH:mm:ss.SSS");
      let latest_todate = this.datepipe.transform(this.ReportFormData.DealCreatedDateTo, "yyyy-MM-dd HH:mm:ss.SSS");
      let odata = {
        SBU: this.ReportFormData.SBU,
        Sector: this.ReportFormData.CapgeminiSectors,
        DealCreatedDateFrom: latest_fromdate,
        DealCreatedDateTo: latest_todate,
        DealStatus: this.ReportFormData.DealStatus,
       

      }
      let subscribe$: Subscription = this.AmDownloadsService.GetPegaDealAnalysisReport(odata).subscribe(res => {
        this.downloadDealAnalysisFlag = false;
        this.downloadFile(res, 'PegaDealAnalysis');
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          this.downloadDealAnalysisFlag = false;
          console.log('Error on PegaDealAnalysis.');
          this.showNotificationMessage('Download Failed', 2, '', null, '', '', 1000);
        });

      this.subscriptionList$.push(subscribe$);

    }

  }


  DownloadExcel_MasterDataDownload() {
    
    // setTimeout(() => {
    //   this.IsLoading = true;
    console.log(this.ReportFormData);
    if (this.validateDownloads(this.ReportFormData)) {
 
      // let latest_fromdate = this.datepipe.transform(this.ReportFormData.DealCreatedDateFrom, "yyyy-MM-dd HH:mm:ss.SSS");
      // let latest_todate = this.datepipe.transform(this.ReportFormData.DealCreatedDateTo, "yyyy-MM-dd HH:mm:ss.SSS");
      let odata = {
        MasterData: this.ReportFormData.MasterData
      }
      let subscribe$: Subscription = this.AmDownloadsService.DownloadExcel_MasterDataDownload(odata).subscribe(res => {
        //   this.downloadDealAnalysisFlag = false;
    
        this.downloadFile(res, this.ReportFormData.MasterDataDesc);
        
     
  
      },
        err => {
          this.IsLoading = false;
          //    this.downloadDealAnalysisFlag = false;
          console.log('Error on GetDealAnalysisReport.');
          this.showNotificationMessage('Download Failed', 2, '', null, '', '', 1000);
        });

      this.subscriptionList$.push(subscribe$);

  
    }
 

  }


  //////UPLOAD///////
  public async UploadExcel() {
    this.IsLoading = true;
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        
      console.log(this.ReportFormData);
      // if (this.validateDownloads(this.ReportFormData)) {
      // let latest_fromdate = this.datepipe.transform(this.ReportFormData.DealCreatedDateFrom, "yyyy-MM-dd HH:mm:ss.SSS");
      // let latest_todate = this.datepipe.transform(this.ReportFormData.DealCreatedDateTo, "yyyy-MM-dd HH:mm:ss.SSS");
  
      let subscribe$: Subscription = this.AmDownloadsService.uploadIt(this.fileToUpload, this.filename).subscribe(res => {
        //   this.downloadDealAnalysisFlag = false;
        console.log(res);
        if (res.Status == 200) {
          //  this.showNotificationMessage(res.Data, 1, '', null, '', '', 1000);
          this.toastr.success(res.Data);
          this.uploadflag = true;
          this.compareflag = false;
          this.GetADMTReportFirstGridDetails(res);
        }
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          //    this.downloadDealAnalysisFlag = false;
          console.log('Error on Upload');
          this.showNotificationMessage('Upload Failed', 2, '', null, '', '', 1000);
        });
  
      this.subscriptionList$.push(subscribe$);
  
      // }
      resolve();
   }, 5000);
    });


 this.IsLoading = false;
  }

  public async uploadItQuickDeal() {
    this.IsLoading = true;
    await new Promise<void>((resolve) => {
      setTimeout(() => {
     
      console.log(this.ReportFormData);
      // if (this.validateDownloads(this.ReportFormData)) {
      // let latest_fromdate = this.datepipe.transform(this.ReportFormData.DealCreatedDateFrom, "yyyy-MM-dd HH:mm:ss.SSS");
      // let latest_todate = this.datepipe.transform(this.ReportFormData.DealCreatedDateTo, "yyyy-MM-dd HH:mm:ss.SSS");
  
      let subscribe$: Subscription = this.AmDownloadsService.uploadItQuickDeal(this.fileToUpload, this.filename).subscribe(res => {
        //   this.downloadDealAnalysisFlag = false;
        console.log(res);
  
        if (res.Status == 200) {
          //  this.showNotificationMessage(res.Data, 1, '', null, '', '', 1000);
          this.toastr.success(res.Data);
  
        }
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          //    this.downloadDealAnalysisFlag = false;
          console.log('Error on Upload');
          this.showNotificationMessage('Upload Failed', 2, '', null, '', '', 1000);
        });
  
      this.subscriptionList$.push(subscribe$);
  
      // }
      resolve();
   }, 5000);
    });
    this.IsLoading = false;
  }

  InsertAMDealComparisonUpload() {
    console.log(this.ReportFormData);


    let subscribe$: Subscription = this.AmDownloadsService.InsertAMDealComparisonUpload(this.fileToUpload, this.filename).subscribe(res => {
      //   this.downloadDealAnalysisFlag = false;
      console.log(res);

      if (res.Status == 200) {
        //  this.showNotificationMessage(res.Data, 1, '', null, '', '', 1000);
        this.toastr.success(res.Data);

      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        //    this.downloadDealAnalysisFlag = false;
        console.log('Error on Upload');
        this.showNotificationMessage('Upload Failed', 2, '', null, '', '', 1000);
      });

    this.subscriptionList$.push(subscribe$);



  }





  GetMasterData() {
    if (this.validateDownloads(this.ReportFormData)) {
      let latest_fromdate = this.datepipe.transform(this.ReportFormData.DealCreatedDateFrom, "yyyy-MM-dd HH:mm:ss.SSS");
      let latest_todate = this.datepipe.transform(this.ReportFormData.DealCreatedDateTo, "yyyy-MM-dd HH:mm:ss.SSS");
      let odata = {
        SBU: this.ReportFormData.SBU,
        Sector: this.ReportFormData.CapgeminiSectors,
        DealCreatedDateFrom: latest_fromdate,
        DealCreatedDateTo: latest_todate,
        DealStatus: this.ReportFormData.DealStatus
      }
      let subscribe$: Subscription = this.AmDownloadsService.GetDealAnalysisReport(odata).subscribe(res => {
        this.downloadDealAnalysisFlag = false;
        this.downloadFile(res, 'DealAnalysis');
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          this.downloadDealAnalysisFlag = false;
          console.log('Error on GetDealAnalysisReport.');
          this.showNotificationMessage('Download Failed', 2, '', null, '', '', 1000);
        });

      this.subscriptionList$.push(subscribe$);

    }

  }

  CancelDealAnalysis() {
    this.ReportFormData.SBU = '';
    this.ReportFormData.CapgeminiSectors = '';
    this.ReportFormData.DealCreatedDateFrom = '';
    this.ReportFormData.DealCreatedDateTo = '';
    this.ReportFormData.DealStatus = '';
  }

  onChange(event: any): void {

    let selitem: any = event.item;
    this.selectedInfoData = event.item;

    if (this.ModuleName == 'whatifswithadmt') {

      var reportData_Pyramid = this.ReportGridData_ADMT_grid.filter((x: any) => x.GlobalGrade != 'Total' && x.ReportType == 'PyramidPer');

      switch (event.fieldname.toUpperCase()) {

        case 'ONSHORE':

          reportData_Pyramid.forEach((item: any) => {

            item.Onshore = isFinite(item.Onshore) && item.Onshore !== null && item.Onshore != 0 && item.Onshore != '' ? Number(item.Onshore) : 0;


          });
          var reportData_Pyramid_SUM_ONS = this.ReportGridData_ADMT_grid.filter((x: any) => x.GlobalGrade == 'Total' && x.ReportType == 'PyramidPer');
          let Onshore_SUM = reportData_Pyramid
            .filter((item: any) => item.Onshore)
            .map((item: any) => +item.Onshore)
            .reduce((sum: any, current: any) => sum + current);



          console.log(Onshore_SUM);
          reportData_Pyramid_SUM_ONS[0].Onshore = Onshore_SUM;
          break;

        case 'OFFSHORE':

          reportData_Pyramid.forEach((item: any) => {

            item.Offshore = isFinite(item.Offshore) && item.Offshore !== null && item.Offshore != 0 && item.Offshore != '' ? Number(item.Offshore) : 0;


          });

          var reportData_Pyramid_SUM_OFF = this.ReportGridData_ADMT_grid.filter((x: any) => x.GlobalGrade == 'Total' && x.ReportType == 'PyramidPer');
          let Offshore_SUM = reportData_Pyramid
            .filter((item: any) => item.Offshore)
            .map((item: any) => +item.Offshore)
            .reduce((sum: any, current: any) => sum + current);
          console.log(Offshore_SUM);
          reportData_Pyramid_SUM_OFF[0].Offshore = Offshore_SUM;
          break;
        case 'NEARSHORE':

          reportData_Pyramid.forEach((item: any) => {

            item.Nearshore = isFinite(item.Nearshore) && item.Nearshore !== null && item.Nearshore != 0 && item.Nearshore != '' ? Number(item.Nearshore) : 0;


          });
          var reportData_Pyramid_SUM_OFF = this.ReportGridData_ADMT_grid.filter((x: any) => x.GlobalGrade == 'Total' && x.ReportType == 'PyramidPer');
          let Nearshore_SUM = reportData_Pyramid
            .filter((item: any) => item.Nearshore)
            .map((item: any) => +item.Nearshore)
            .reduce((sum: any, current: any) => sum + current);
          console.log(Nearshore_SUM);
          reportData_Pyramid_SUM_OFF[0].Nearshore = Nearshore_SUM;
          break;
        case 'CALCULATEDVALUE':


          var reportData_Expenses = this.ReportGridData_ADMT_grid.filter((x: any) => x.LocationType != 'Total' && x.ReportType == 'EffortsPer');
          reportData_Expenses.forEach((item: any) => {

            item.CalculatedValue = isFinite(item.CalculatedValue) && item.CalculatedValue !== null && item.CalculatedValue != 0 && item.CalculatedValue != '' ? Number(item.CalculatedValue) : 0;


          });

          var reportData_Expenses_sum = this.ReportGridData_ADMT_grid.filter((x: any) => x.LocationType == 'Total' && x.ReportType == 'EffortsPer');
          let SUM_EXP = reportData_Expenses
            .filter((item: any) => item.CalculatedValue)
            .map((item: any) => +item.CalculatedValue)
            .reduce((sum: any, current: any) => sum + current);
          console.log(SUM_EXP);
          reportData_Expenses_sum[0].CalculatedValue = SUM_EXP;
          break;
      }

    }

  }

  onClick(event: any): void {
    let field = event.item;
    console.log(field);
    switch (event.fieldname.toUpperCase()) {
      case 'LOCATIONCARD':
        this.LocationCardDetails = field;
        // this.GetADMTReportSummary(field);
        break;
    }
  }


  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {

    }
  }

  // rangeChanged(event: any) {

  //   console.log(event);
  //   let APPLY_odata: any;
  //   let APPLY_RANGE: any = {};
  //   switch (event.target.name) {
  //     case 'prod_range':

  //       this.prod_range = event.target.value;

  //       for (let i = 0; i < this.applyListitems.length; i++) {

  //         this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
  //         this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
  //         this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
  //         this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
  //         this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
  //         this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
  //         this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
  //         this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
  //         this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
  //       }





  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: event.target.value,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //        // ChangeValue: 'ProductivityPer',
  //       }
  //       // APPLY_RANGE = {
  //       //   NProductivity: event.target.value,
  //       //   NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //       //   NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //       //   NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //       //   NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //       //   NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //       //   NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //       //   NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //       //   NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //       //   ChangeValue: 'ProductivityPer',
  //       // }
  //       this.InsertWhatifswithADMTTransactionLog();
  //       //  this.GetADMTReportOnChangeValue(APPLY_odata);
  //       //  this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'expense_range':
  //       this.expense_range = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: event.target.value,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'ExpensesPer',


  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: event.target.value,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'ExpensesPer',
  //       }
  //       // this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'Deal_range':
  //       this.Deal_range = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,

  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: event.target.value,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'DealPer',



  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: event.target.value,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'DealPer',
  //       }
  //       //   this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'Blended_range1':
  //       this.Blended_range1 = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: event.target.value,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'BlendedRatePer',



  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: event.target.value,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'BlendedRatePer',

  //       }
  //       // this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'Blended_range2':
  //       this.Blended_range2 = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: event.target.value,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'BlendedRatePer',



  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: event.target.value,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'BlendedRatePer',
  //       }
  //       // this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'Blended_range3':
  //       this.Blended_range3 = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: event.target.value,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'BlendedRatePer',


  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: event.target.value,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'BlendedRatePer',
  //       }
  //       // this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'contigency_range1':
  //       this.contigency_range1 = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: event.target.value,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'ContingencyPer',


  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: event.target.value,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'ContingencyPer',
  //       }
  //       // this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'contigency_range2':
  //       this.contigency_range2 = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: event.target.value,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'ContingencyPer',
  //       }

  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: event.target.value,
  //         NNearshoreContingency: this.contigency_range3 != null && this.contigency_range3 != undefined ? this.contigency_range3 : 0,
  //         ChangeValue: 'ContingencyPer',
  //       }
  //       //  this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;
  //     case 'contigency_range3':
  //       this.contigency_range3 = event.target.value;
  //       APPLY_odata = {

  //         ADMTsmodellist: this.ReportGridData_ADMT_grid,
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: event.target.value,
  //         ChangeValue: 'ContingencyPer',


  //       }
  //       APPLY_RANGE = {
  //         NProductivity: this.prod_range != null && this.prod_range != undefined ? this.prod_range : 0,
  //         NExpenses: this.expense_range != null && this.expense_range != undefined ? this.expense_range : 0,
  //         NDealDiscount: this.Deal_range != null && this.Deal_range != undefined ? this.Deal_range : 0,
  //         NOnshoreBlendedRate: this.Blended_range1 != null && this.Blended_range1 != undefined ? this.Blended_range1 : 0,
  //         NOffshoreBlendedRate: this.Blended_range2 != null && this.Blended_range2 != undefined ? this.Blended_range2 : 0,
  //         NNearshoreBlendedRate: this.Blended_range3 != null && this.Blended_range3 != undefined ? this.Blended_range3 : 0,
  //         NOnshoreContingency: this.contigency_range1 != null && this.contigency_range1 != undefined ? this.contigency_range1 : 0,
  //         NOffshoreContingency: this.contigency_range2 != null && this.contigency_range2 != undefined ? this.contigency_range2 : 0,
  //         NNearshoreContingency: event.target.value,
  //         ChangeValue: 'ContingencyPer',
  //       }
  //       //  this.GetADMTReportOnChangeValue(APPLY_odata);
  //       this.GetADMTReportOnSliderChangeValue(APPLY_RANGE);
  //       break;


  //   }
  // }





  rangeChanged(event: any) {

    let odata: any;

    console.log(event);
    let APPLY_odata: any;
    this.applyListitems = (!isNullOrUndefined(this.applyListitems) && this.applyListitems.length > 0) ? this.applyListitems : this.ReportGridData_ADMT_grid;
    switch (event.target.name) {
      case 'prod_range':

        this.prod_range = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        //this.GetWhatifsModelSummary(odata);
        break;
      case 'expense_range':
        this.expense_range = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        //  this.GetWhatifsModelSummary(odata);
        break;
      case 'Deal_range':
        this.Deal_range = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
        break;
      case 'Blended_range1':
        this.Blended_range1 = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        //  this.GetWhatifsModelSummary(odata);
        break;
      case 'Blended_range2':
        this.Blended_range2 = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        //this.GetWhatifsModelSummary(odata);
        break;
      case 'Blended_range3':
        this.Blended_range3 = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
        break;
      case 'contigency_range1':
        this.contigency_range1 = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        // this.GetWhatifsModelSummary(odata);
        break;
      case 'contigency_range2':
        this.contigency_range2 = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
        //  this.GetWhatifsModelSummary(odata);
        break;
      case 'contigency_range3':
        this.contigency_range3 = event.target.value;
        // APPLY_odata = {
        //   whatifswithADMTlist: this.ReportGridData_ADMT_grid,
        // }
        // this.applyListitems = APPLY_odata;
        odata = {
          ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
          NDataCount: this.applyListitems.length
        }

        for (let i = 0; i < this.applyListitems.length; i++) {

          this.applyListitems[i].NProductivity = isNullOrUndefined(this.prod_range) ? 0 : this.prod_range;
          this.applyListitems[i].NExpenses = isNullOrUndefined(this.expense_range) ? 0 : this.expense_range;
          this.applyListitems[i].NDealDiscount = isNullOrUndefined(this.Deal_range) ? 0 : this.Deal_range;
          this.applyListitems[i].NOnshoreBlendedRate = isNullOrUndefined(this.Blended_range1) ? 0 : this.Blended_range1;
          this.applyListitems[i].NOffshoreBlendedRate = isNullOrUndefined(this.Blended_range2) ? 0 : this.Blended_range2;
          this.applyListitems[i].NNearshoreBlendedRate = isNullOrUndefined(this.Blended_range3) ? 0 : this.Blended_range3;
          this.applyListitems[i].NOnshoreContingency = isNullOrUndefined(this.contigency_range1) ? 0 : this.contigency_range1;
          this.applyListitems[i].NOffshoreContingency = isNullOrUndefined(this.contigency_range2) ? 0 : this.contigency_range2;
          this.applyListitems[i].NNearshoreContingency = isNullOrUndefined(this.contigency_range3) ? 0 : this.contigency_range3;
        }

        APPLY_odata = {

          whatifswithADMTlist: this.applyListitems,
          //  NModelId: this.selectedTab
        }
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);

        break;


    }
  }

  actionButtonClick(data: any) {
    console.log(data)
    if (this.ModuleName == 'whatifswithadmt') {
      this.compareflag = true;
      this.UpdateWhatifswithADMTLocationCard();
    }
  }


  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
    this.displayNotifyDeliveryModel = true;
  }

  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {

    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
    this.displayNotifyDeliveryModel = false;
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      // console.log(this.Config.ActionButtons)

      this.AMProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      let reportDetails = (Properties.filter(x => x.Name.toLowerCase() === 'ReportDetails'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ReportDetails'.toLowerCase())[0].JSONData.toString()) : []);

      this.ReportConfigDetails = reportDetails.filter((x: any) => x.ModuleName === this.ModuleName);


      if (!isNullOrUndefined(this.ReportConfigDetails) && this.ReportConfigDetails.length > 0) {

        this.ReportDisplayName = this.ReportConfigDetails[0].DisplayName;
        this.FormGroupName = this.ReportConfigDetails[0].FormGroupName;

        Properties.forEach(prop => {
          // avoiding generic props
          if (!isNullOrUndefined(prop.Name) &&
            prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
            && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
            this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
          }
        });


      }

    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtons) && this.Config.ActionButtons.length > 0) {
      this.Config.ActionButtons.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          // let peritem = this.AMConfigPermission.indexOf(item.Name);
          let peritem = this.AMConfigPermission.filter((x: any) => x === item.Name)
          if (!isNullOrUndefined(peritem) && peritem.length >= 0) {
            item.HasPermission = true;
            item.IsApplicable = true;
          }
        }
      });

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    }


    if (!isNullOrUndefined(this.AMReportsTabs) && this.AMReportsTabs.length > 0) {

      this.AMReportsTabs.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;

          let peritem = this.AMConfigPermission.indexOf(item.Name);
          // if (peritem >= 0 && item.Group === this.selectedTab) {
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  /////UPLOAD EXCEL/////

  // handleFileInput(files: any) {

  //   this.fileToUpload = files.item(0);
  //   console.log(this.fileToUpload);
  // }

  handleFileInput(input: any) {


    console.log(input);
    if (input.target.files && input.target.files[0]) {
      //   var reader = new FileReader();
      //   reader.onload = (e: any) => {
      //     console.log('Got here: ', e.target.result);
      //   //  this.obj.photoUrl = e.target.result;

      //   //e.target.result
      // //  input.files[0] as File;
      // this.fileToUpload = e.target.result ;
      //   }
      //   reader.readAsDataURL(input.target.files[0]);
      //  this.fileToUpload = input.target.files[0] as File;
      this.filename = input.target.files[0].name;

      const fileupd: File = input.target.files[0];
      this.fileToUpload = fileupd;
    }
  }

  // uploadFile() {
  //   this.fileUploadService.postFile(this.fileToUpload).subscribe(data => {
  //     // do something, if upload success
  //     }, error => {
  //       console.log(error);
  //     });
  // }


  openModal(id: string) {
    this.modalService.open(id);

    //this.getUserList();

  }
  closeModal(id: string) {
    this.modalService.close(id);
  }


  GetADMTReportFirstGridDetails(data: any) {

    let subscribe$: Subscription = this.AmDownloadsService.GetADMTReportFirstGridDetails(data).subscribe(result => {
      if (result !== undefined && result !== null) {


        this.ReportGridData_ADMT = result.Data;
        this.DataTotalCount = this.ReportGridData_ADMT.length;
        console.log(result);
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting InsertWhatifswithADMTTransactionLog.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  InsertWhatifswithADMTTransactionLog(data: any, getdata: any) {
    let subscribe$: Subscription = this.AmDownloadsService.InsertWhatifswithADMTTransactionLog(data).subscribe(result => {
      if (result !== undefined && result !== null) {
        //   this.ReportGridData_ADMT = result.Data;
        //    this.DataTotalCount = this.ReportGridData_ADMT.length;
        console.log(result);
        this.GetWhatifswithADMTSummary();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting InsertWhatifswithADMTTransactionLog.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetWhatifsADMTSummary(data: any) {

    let subscribe$: Subscription = this.AmDownloadsService.GetWhatifsADMTSummary(data).subscribe(result => {
      if (result !== undefined && result !== null) {


        // this.ReportGridData_ADMT_grid = result.Data;
        // this.DataTotalCount = this.ReportGridData_ADMT_grid.length;
        console.log(result);
        //    this.GetADMTReportSummaryForGraph();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetWhatifsADMTSummary.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetWhatifswithADMTSummary() {
    let prodper = [];
    let dealdiscountper = [];
    let BlendedRatePer = [];
    let ContingencyPer = [];
    let Blenderatepergraph = [];
    let ExpensesPerGraph = [];
    let CMPerGraph = [];
    let odata: any

    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    this.chartsInputThird = new ChartDetails();
    odata = {
      ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
      NDataCount: this.applyListitems.length
    }

    let subscribe$: Subscription = this.AmDownloadsService.GetWhatifswithADMTSummary(odata).subscribe(result => {
      if (result !== undefined && result !== null) {
        console.log(result, "@@@@@")
        this.ReportGridData_ADMT_grid = result.Data;
        prodper = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'ProductivityPer');
        this.ProductivityPer = prodper[0].CalculatedValue;
        dealdiscountper = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'DealDiscountPer');
        this.DealDiscountPer = dealdiscountper[0].CalculatedValue;
        BlendedRatePer = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'BlendedRatePer');
        this.BlendedRatePer_Ons = BlendedRatePer[0].Onshore;
        this.BlendedRatePer_Off = BlendedRatePer[0].Offshore;
        this.BlendedRatePer_Nea = BlendedRatePer[0].Nearshore;
        ContingencyPer = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'ContingencyPer');
        this.ContingencyPer_Ons = ContingencyPer[0].Onshore;
        this.ContingencyPer_Off = ContingencyPer[0].Offshore;
        this.ContingencyPer_Nea = ContingencyPer[0].Nearshore;


        Blenderatepergraph = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'BlendedRatePer');
        // this.BlendedRateGraph = JSON.parse(Blenderatepergraph[0].ChartOutput);
        ExpensesPerGraph = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'ExpensesPer');
        this.ExpensesPer = ExpensesPerGraph[0].CalculatedValue;
        //  this.TotalRevenueGraph = JSON.parse(ExpensesPerGraph[0].ChartOutput);


        CMPerGraph = this.ReportGridData_ADMT_grid.filter((x: any) => x.ReportType == 'ContingencyPer');
        //  this.BlendedRateGraph = JSON.parse(Blenderatepergraph[0].ChartOutput);

        // if (!isNullOrUndefined(this.ReportFormData.DealName) && !isNullOrUndefined(this.ReportFormData.Currency)) {


        this.chartsInput = Blenderatepergraph[0];
        this.chartsInputSecond = ExpensesPerGraph[0];
        this.chartsInputThird = CMPerGraph[0];
        this.ReportTYPE = 'whatifswithADMTmodel';
        this.chartPlotName = this.chartsInput.GraphHeading.replace(/[{()}]/g, '').replace(/\s/g, '');
        // }
        // else {
        //   this.chartsInput = new ChartDetails();
        //   this.chartsInputSecond = new ChartDetails();
        //   this.chartsInputThird = new ChartDetails();


        // }
      }
      this.IsLoading = false;
    },
      err => {
        this.chartsInput = new ChartDetails();
        this.chartsInputSecond = new ChartDetails();
        this.chartsInputThird = new ChartDetails();
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    // }

  }




  UpdateWhatifswithADMTLocationCard() {
    console.log(this.ReportGridData_ADMT);
    let odata: any = {};
    odata.whatifswithADMTlist = this.ReportGridData_ADMT;
    let subscribe$: Subscription = this.AmDownloadsService.UpdateWhatifswithADMTLocationCard(odata).subscribe(result => {
      if (result != null && result != undefined) {
        this.GetWhatifswithADMTSummary();

      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting UpdateWhatifswithADMTLocationCard');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  GetADMTReportSummary() {
    console.log(this.ReportGridData_ADMT);

    let subscribe$: Subscription = this.AmDownloadsService.GetADMTReportSummary(this.ReportGridData_ADMT).subscribe(result => {
      if (result !== undefined && result !== null) {


        this.ReportGridData_ADMT_grid = result.Data;
        this.DataTotalCount = this.ReportGridData_ADMT_grid.length;
        console.log(result);
        //    this.GetADMTReportSummaryForGraph();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting ReportGridData_ADMT_grid.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetADMTReportSummaryPart2() {
    let data = {};
    let subscribe$: Subscription = this.AmDownloadsService.GetADMTReportSummaryPart2(data).subscribe(result => {
      if (result !== undefined && result !== null) {


        this.ReportGridData_ADMT_grid1 = result.Data;


        var Blendeditemlist = this.ReportGridData_ADMT_grid1.filter((x: any) => (x.TabName == 'Blended'));
        var Contingencylist = this.ReportGridData_ADMT_grid1.filter((x: any) => (x.TabName == 'Contingency'));
        var Productivitylist = this.ReportGridData_ADMT_grid1.filter((x: any) => (x.TabName == 'ProductivityPer'));
        var Expenseslist = this.ReportGridData_ADMT_grid1.filter((x: any) => (x.TabName == 'ExpensesPer'));
        var DealDiscountlist = this.ReportGridData_ADMT_grid1.filter((x: any) => (x.TabName == 'DealDiscountPer'));


        if (Blendeditemlist.length > 0) {
          this.BlendedRatePer_Ons = Blendeditemlist.filter((x: any) => (x.LocationType == 'ONSHORE'))[0].EffortsPer;
          this.BlendedRatePer_Off = Blendeditemlist.filter((x: any) => (x.LocationType == 'OFFSHORE'))[0].EffortsPer;
          this.BlendedRatePer_Nea = Blendeditemlist.filter((x: any) => (x.LocationType == 'NEARSHORE'))[0].EffortsPer;
        }

        if (Contingencylist.length > 0) {
          this.ContingencyPer_Ons = Contingencylist.filter((x: any) => (x.LocationType == 'ONSHORE'))[0].EffortsPer;
          this.ContingencyPer_Off = Contingencylist.filter((x: any) => (x.LocationType == 'OFFSHORE'))[0].EffortsPer;
          this.ContingencyPer_Nea = Contingencylist.filter((x: any) => (x.LocationType == 'NEARSHORE'))[0].EffortsPer;
        }


        if (Productivitylist.length > 0) {
          this.ProductivityPer = Productivitylist[0].EffortsPer;
        }

        if (Expenseslist.length > 0) {
          this.ExpensesPer = Expenseslist[0].EffortsPer;
        }
        if (DealDiscountlist.length > 0) {
          this.DealDiscountPer = DealDiscountlist[0].EffortsPer;
        }


        this.DataTotalCount = this.ReportGridData_ADMT_grid1.length;
        console.log(result);
        //   this.GetADMTReportSummaryForGraph();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting ReportGridData_ADMT_grid.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetADMTReportOnChangeValue(odata: any) {
    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    this.chartsInputThird = new ChartDetails();
    let subscribe$: Subscription = this.AmDownloadsService.GetADMTReportOnChangeValue(odata).subscribe(result => {
      if (result !== undefined && result !== null) {

        this.chartsInput = result.Data[0];
        this.chartsInputSecond = result.Data[1];
        this.chartsInputThird = result.Data[2];
        //  this.ModuleName = 'ADMTREPORTS';
        // this.ReportGridData_ADMT_grid1 = result.Data;
        // this.DataTotalCount = this.ReportGridData_ADMT_grid1.length;
        console.log(result);
        //   this.GetADMTReportSummaryForGraph();
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting ReportGridData_ADMT_grid.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  GetADMTReportOnSliderChangeValue(odata: any) {
    let Blenderatepergraph = [];
    let ExpensesPerGraph = [];
    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    this.chartsInputThird = new ChartDetails();
    let subscribe$: Subscription = this.AmDownloadsService.GetADMTReportOnSliderChangeValue(odata).subscribe(result => {
      if (result !== undefined && result !== null) {
        Blenderatepergraph = result.Data[0];
        ExpensesPerGraph = result.Data[1];
        this.chartsInput = Blenderatepergraph;
        this.chartsInputSecond = ExpensesPerGraph;
        this.chartsInputThird = result.Data[2];

        console.log(result);
        //   this.GetADMTReportSummaryForGraph();
      }
      this.IsLoading = false;
    },
      err => {
        this.chartsInput = new ChartDetails();
        this.chartsInputSecond = new ChartDetails();
        this.chartsInputThird = new ChartDetails();
        console.log('Error on getting ReportGridData_ADMT_grid.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  GetADMTReportSummaryForGraph() {
    let data = {};
    this.chartsInput = new ChartDetails();
    this.chartsInputSecond = new ChartDetails();
    this.chartsInputThird = new ChartDetails();

    let Blenderatepergraph = [];
    let ExpensesPerGraph = [];
    let subscribe$: Subscription = this.AmDownloadsService.GetADMTReportSummaryForGraph(data).subscribe(result => {
      if (result !== undefined && result !== null) {
        Blenderatepergraph = result.Data[0];
        ExpensesPerGraph = result.Data[1];
        this.chartsInput = Blenderatepergraph;
        this.chartsInputSecond = ExpensesPerGraph;
        this.chartsInputThird = result.Data[2];
        // this.ModuleName = 'ADMTREPORTS';
        //  this.ModuleName = 'whatifswithadmt';
        //this.ReportGridData_ADMT_grid1 = result.Data;
        //  this.DataTotalCount = this.ReportGridData_ADMT_grid1.length;
        console.log(result);
      }
      this.IsLoading = false;
    },
      err => {
        this.chartsInput = new ChartDetails();
        this.chartsInputSecond = new ChartDetails();
        this.chartsInputThird = new ChartDetails();
        console.log('Error on getting ReportGridData_ADMT_grid.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }



  setCellLock(fieldname: string, item: any, parent: AmDownloadsComponent): boolean {

    let bLock = false;
    let BlkCols: any[] = [];


    if (!isNullOrUndefined(parent.AMProperties.BlockCols) && parent.AMProperties.BlockCols.length > 0) {
      parent.AMProperties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value == item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }
    return bLock;

  }






  ApplyButtonClick(clickData: any, Btname: any) {
    let odata: any;
    console.log("CLICKDATA");
    console.log(clickData);
    // var CSVOf_arr = this.arraySelecteditems.map((item: any) => { return item.ModelID }).join(',');
    // var dataitemlist = clickData.filter((x: any) => (x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab) || (x.ReportType == 'EffortsPer' && x.ModelID == this.selectedTab));
    // var reportData_Pyramid = this.ReportGridData_ADMT_grid.filter((x: any) => (x.TabName == 'Pyaramid'));
    // var reportData_Expenses = this.ReportGridData_ADMT_grid1;
    this.applyListitems = (!isNullOrUndefined(this.applyListitems) && this.applyListitems.length > 0) ? this.applyListitems : this.ReportGridData_ADMT_grid;

    let APPLY_odata: any = [];
    APPLY_odata = {
      whatifswithADMTlist: this.applyListitems,
    }
    // this.applyListitems = APPLY_odata;
    odata = {
      ADMTUploadId: this.ReportGridData_ADMT[0].ADMTUploadId,
      NDataCount: this.applyListitems.length
    }



    if (Btname == 'PyramidGrid') {
      if (this.Validations_sum_pyd()) {

        //  APPLY_odata.ChangeValue = 'PyaramidPer';

        //this.GetADMTReportOnChangeValue(APPLY_odata);
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
      }
      else {
        this.toastr.warning('Total % cannot  be  greater than 100%');
      }
    }
    if (Btname == 'EffortsGrid') {
      if (this.Validations_sum_exp()) {
        // APPLY_odata.ChangeValue = 'EffortPer'
        // this.GetADMTReportOnChangeValue(APPLY_odata);
        this.InsertWhatifswithADMTTransactionLog(APPLY_odata, odata);
      }
      else {
        this.toastr.warning('Total % cannot  be  greater than 100%');
      }
    }



  }



  Validations_sum_pyd(): boolean {
    let valid: boolean = false;

    let valid_enteries: any = [];

    var reportData_Pyramid = this.ReportGridData_ADMT_grid.filter((x: any) => x.GlobalGrade != 'Total' && x.ReportType == 'PyramidPer');
    // this.gridforwhatifmodel_data.filter((x: any) => x.ReportType == 'PyramidPer' && x.ModelID == this.selectedTab && x.GlobalGrade != 'Total');
    let Onshore_SUM = reportData_Pyramid
      .filter((item: any) => item.Onshore !== undefined || item.Onshore !== null)
      .map((item: any) => +item.Onshore || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    console.log(Onshore_SUM);

    let Offshore_SUM = reportData_Pyramid
      .filter((item: any) => item.Offshore !== undefined || item.Offshore !== null)
      .map((item: any) => +item.Offshore || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    console.log(Offshore_SUM);



    let Nearshore_SUM = reportData_Pyramid
      .filter((item: any) => item.Nearshore !== null && item.Nearshore !== undefined)
      .map((item: any) => +item.Nearshore || 0)
      .reduce((sum: any, current: any) => sum + current, 0);



    if (Onshore_SUM > 100 || Offshore_SUM > 100 || Nearshore_SUM > 100) {
      this.toastr.warning('Total % cannot  be  greater than 100%');
      valid = false;
    }
    else {
      valid = true;
    }




    return valid
  }


  Validations_sum_exp(): boolean {
    let valid: boolean = false;
    var reportData_Expenses = this.ReportGridData_ADMT_grid.filter((x: any) => x.LocationType != 'Total' && x.ReportType == 'EffortsPer');
    let SUM_EXP = reportData_Expenses
      .filter((item: any) => item.EffortsPer !== null || item.EffortsPer !== undefined)
      .map((item: any) => +item.EffortsPer || 0)
      .reduce((sum: any, current: any) => sum + current, 0);

    console.log(SUM_EXP);


    if (SUM_EXP > 100) {
      this.toastr.warning('Total % cannot  be  greater than 100%');
      valid = false;
    }
    else {
      valid = true;
    }



    return valid
  }
}

