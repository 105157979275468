export class ReportFormDetails {
    DealName: any = null;
    ModelId: any = null;
    ReportType: any = null;
    Currency: any = null;
    COLAYear2:any = 0;
    COLAYear3:any = 0;
    COLAYear4:any = 0;
    COLAYear5:any = 0;
    COLAYear6:any = 0;
    COLAYear7:any = 0;
    IsEditable: number = 1;
  DealYearFrom: any;
  DealOutcome: any;
  DealTermMonthsFrom: any;
  TCVRangeFrom: any;
  PrimarySBU: any;
  ContractType: any;
  TCVRangeTo: any;
  DealYearTo: any;
  DealTermMonthsTo: any;
}

export class ReportTypeDetails {
    Deal: any = 'AMRT001';
    Tower: any = 'AMRT002';
    SubTower: any = 'AMRT003';
    Country: any = 'AMRT004';
    Lotwise: any = 'AMRT005';
}

export class ChartDetails {
    XFieldName: string = '';
    YFieldName: string = '';
    GraphHeading: string = '';
    ChartOutput: any = [];
}