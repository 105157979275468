import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgIdleModule } from '@ng-idle/core';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { CommonAPIService } from './shared/app.commonservice';
import { AppSharedService } from './shared/app.sharedservice';
import { AuthGuardService } from './core/auth/auth-guard.service';
import { AuthenticationService } from './core/auth/authentication.service';
import { CustomHttpInterceptor } from './core/auth/customhttpinterceptor';
import { CustomPipeModule } from '../modules/custompipe/custompipe.module';
import { LoginModule } from './core/login/login.module';
import { SessionModalComponent } from './core/session/session.component';
import { LoadingModule } from '../modules/loading/loading.module';
import { CanDeactivateGuard } from './shared/app.can-deactivate.service';
import { ToastService } from './shared/toast.service';
import { ResizeService } from './core/screen-size-detector/resize.service';
import { MenuBarModule } from 'src/modules/menubar/menubar.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { SwitchUserModule } from './core/switchuser/switchuser.module';
import { RegistryModule } from './core/registry/registry.module';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app-main.component';
import { HomeModule } from './home/home.module';
import { CreateDealModule } from './create-deal/create-deal.module';
import { ModelConfigModule } from './create-deal/model-config/model-config.module';
import { ReportModule } from './report/report.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ServicetypsModule } from './servicetyps/servicetyps.module';
import { AdminModule } from './admin/admin.module';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { LiteHomeModule } from './home/lite-home/lite-home.module';
import { CisMainPageModule } from './home/cis-main-page/cis-main-page.module';
import { ADHomeModule } from './home/AD-home/ad-home.module';
import { LotSolutionModellingModule } from './create-deal/lot-solution-modelling/lot-solution-modelling.module';
import { LotSolutionPricingModelModule } from './create-deal/lot-solution-pricing-model/lot-solution-pricing-model.module';
import { AMReportsModule } from './am-reports/am-reports.module';
import { AmDownloadsModule } from './am-downloads/am-downloads.module';
import { AMChartsModule } from './am-charts/am-charts.module';
import { AmChartsMultipleModule } from './am-charts-multiple/am-charts-multiple.module';

import { FAQSModule } from './faqs/faqs.module';
import { SecurityInterceptor } from './security.interceptor';

//import { ChartsModule } from './charts/charts.module';
//import { ChartsComponent } from './charts/charts.component';
//import { CreateDealComponent } from './create-deal/create-deal.component';


registerLocaleData(localeDe);
registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent, AppMainComponent, SessionModalComponent, DashboardComponent, BenchmarkComponent, GlossaryComponent
  ],
  imports: [
    BrowserModule, HttpClientModule, BrowserAnimationsModule,AMChartsModule,AmChartsMultipleModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgIdleModule.forRoot(),
    FilterPipeModule,
    AppRoutingModule,
    LoginModule,
    NotificationModule,
   
    ToastrModule.forRoot({
      timeOut: 5000,
      autoDismiss: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot(),
    CustomPipeModule, LoadingModule,
    NgbModule, MenuBarModule,
    SwitchUserModule, RegistryModule,ReportModule,AMReportsModule, AmDownloadsModule, AMChartsModule,AmChartsMultipleModule,FAQSModule,
    HomeModule, CreateDealModule, LiteHomeModule,CisMainPageModule,LotSolutionModellingModule,ModelConfigModule, ServicetypsModule, AdminModule, ADHomeModule,LotSolutionPricingModelModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CommonAPIService, AppSharedService, AuthenticationService, AuthGuardService, ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/' + (window.location.pathname.split('/')[1] || '')
    },
    CanDeactivateGuard,
    ResizeService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor() {
  }

}


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

