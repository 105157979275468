
export class FieldTemplate {
  FieldName: string = '';
  DisplayName: string = '';
  HeaderTemplate?: string;
  LabelTemplate?: string;
  LinkedField?: string;
  ColumnWidth: string = '';
  Control!: FieldControl; // Either Control or FieldCollection not both should be passed in the Template
  Cssnames: string = '';
  Style: any = null;
  Events!: string;
  Editable: number = 1;
  IsHidden: boolean = false;
  ToolTipTemplate?: string;
  EditValidate!: FieldValidation;
  FieldCollection?: FieldTemplate[];
  Group?: string;
  DataCell?: CellValidation;
  RowMerge?: boolean = false;
  IsColResizable?: boolean = true;
  HeaderToolTip?: string;  // supports only static header tooltip
  ColumnFixed?: string;    // value = left/right. Allowed only one column to be fixed at left or right in grid

  ResponsiveMediaSize?: string = '';    // to make the column shown in responsive grid based on bootstrap screensize
  // e.g. when value = "xs,sm,md,lg,xl" or value = "" (default), then column is shown in all screensize
  //      when value = "lg,xl", then column is shown only in large screensize

  ShowControlAlways?: boolean = false;    // when set to true, this show the html control in the ui always

  constructor(init?: Partial<FieldTemplate>) {
    Object.assign(this, init);
  }
}


export interface CellValidation {
  CustomLock?: boolean;
  // CustomStyle?: string;
}

export interface FieldValidation {
  Required: boolean;
  Min?: any;
  Max?: any;
  ConditionalRequired?: boolean;
  DefaultValue?: string;    // options - 'MinMax'(default prop), 'Previous', 'Empty'
  DisableDates?: DateRange[];
}

export interface DateRange {
  StartDt: any;
  EndDt: any;
}

export interface FieldControl {
  Type: string;
  InputType?: string;
  List: ListItem[] | any[] | null;
  IconList?: IconList[];
  Source?: ListSource;
  DependentControl?: string[];
  Format?: string;
  //LoadedUrl?: string;
  LoadedUrl?: LoadedUrl[];
  DynamicLoad?: boolean;
  AllowNewListItem?: boolean;
  PlaceHolderText?: string;
}

export interface LoadedUrl {
  Url: string;
  List?: ListItem[];
}


export interface IconList {
  IconCSS: string;
  Value: string;
  Tooltip: string;
  IconStyle: string;
}

export interface ListItem {
  Code?: string;
  CodeDescription?: string;
  CodeType?: string;
}

export interface ListSource {
  Url: string;
  KeyField?: string;
  ValueField?: string;
  UrlAttribute?: string[];
  Target?: string;
}

export interface FieldEventData {
  fieldname: string;
  item: any;
  icon: any;
  oldValue: any;
  item_index?: number;
}

// Form related interfaces

export interface FormProperty {
  // Name: string;
  CaptionBold?: boolean;
  CaptionTop?: boolean;
  ColSize?: number;
  IsEditable?: boolean;
  ShowControlAlways?: boolean;
  TabIndex?: number;

}

export interface FormConfig {
  BaseUrl?: string;
}


// Grid related interfaces

export interface GridProperty {
  TableCSS?: string;
  HeaderCSS?: string;
  AlternateRowColor?: any;
  Editable?: boolean;
  DisplayHeader?: boolean;
  TableWidth?: number;
  PaginationConfig?: PaginationConfig;
  IsSortable?: boolean;
  IsColResizable?: boolean;
  ActionBarConfig?: ActionBarConfig;
  AddNewRow?: boolean;
  DefaultRow?: any[];
  DataObject?: {};
  UniqueKeyName?: string;
}

export interface GridConfig {
  BaseUrl?: string;
}

export interface NestedTemplate {
  ParentFieldName: string;
  FilterFieldName: string;
  Template: FieldTemplate[];
  Type: NestedType;
  Property?: any;
  Config?: any;
  Inline?: boolean;
  IsDynamicDataLoad?: boolean;
  LeafCountFieldName?: string;
}

export enum NestedType {
  Grid = 1,
  Form = 2,
}

export interface Pagination {
  ItemsPerPage: number;
  TotalItems: number;
  CurrentPage: number;
  TotalPages: number;
}

export interface PaginationConfig {
  Required?: boolean;
  ItemsPerPage?: number;
  PageList?: boolean;
  ServerLoad?: boolean;
  // PageEntryList?:any[];
}

export class GridConst {
  public static readonly DEFAULTITEMSPERPAGE: number = 10;
}


export class Grid_Const {
  public static readonly DEFAULTITEMSPERPAGE: number = 25;
}

export class ProcessResponse<T> {
  Data!: T;
  Status!: number;
  Message!: string;
  EventId!: string;
}

export interface ActionBarConfig {
  ActionBarList?: any;
  noActionClass?: boolean;
}


