<!-- Home Component -->
<!-- <h1> Home Page </h1>

<nav aria-label="breadcrumb" class="breadcrumbSection">
  <div class="d-flex w-100 justify-content-between">
    <ul class="breadcrumb m-0 flex-fill"> -->
<!-- <li class="breadcrumb-item"><a href="#">Reports</a></li> -->
<!-- <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
    </ul>
  </div> -->
<!-- </nav>  -->

<div class="h-100">
	<nav aria-label="breadcrumb" class="breadcrumbSection">
		<div class="d-flex w-100 justify-content-between">
			<h1 class="sr-only">Dashboard</h1>
			<h2 class="sr-only">My {{selectedBtn}} Deals</h2>
			<h3 class="sr-only">Deal Status Count</h3>
			<h4 class="sr-only">Click on any deal to see the details</h4>
			<ul class="breadcrumb m-0 flex-fill">
				<li class="breadcrumb-item active" aria-current="page">Dashboard</li>
			</ul>
		</div>
	</nav>
	<div class="contentScroll p-2">



		<div class="p-2">
			<h5>Quick Links</h5>
			<div class="d-flex" id="dashboardTab">

				<!-- <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab" data-href="#tabDashboard"
						data-url="adamDealCreation.html">
						<span>Create a new deal</span>
					  </button> -->
				<ng-container
					*ngIf="QuickLinksButton != null && QuickLinksButton != undefined && QuickLinksButton.length > 0">

					<ng-container *ngFor="let actionBar of QuickLinksButton  | filterBy: {IsApplicable: true,HasPermission :true}
								  | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">

						<div class="row">
							<div class="col-auto align-self-end ml-auto px-2">
								&nbsp;
								&nbsp;
								&nbsp;
								<button type="button" [ngClass]="actionBar.CustomClass" class="btnTertiary"
									(click)="btnClick(actionBar)" [disabled]="!actionBar.IsEnabled"
									value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
									translate
									}}
									<!-- <span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
														aria-hidden="true"></i></span> -->
								</button>
							</div>
						</div>

					</ng-container>
				</ng-container>

				<!-- </div>
					
				  </div> -->
			</div>
		</div>
		<!-- <h5>Quick Links</h5> -->


		<div class="planInfoWraper customForm m-2 bg-white">
			<div class="m-0 px-2">
				<div class="row align-items-center">
					<div class="col-4 d-flex ps-4">
						<div class="form-group">
							<h5>My {{selectedBtn}} Deals</h5>
							<span>Click on any deal to see the details.</span>
						</div>
						<!-- <ng-container>
							<div class="align-self-center dealbtn-col ms-4">
								<div></div>
			
								<ng-container
									*ngIf="QuickLinksButton != null && QuickLinksButton != undefined && QuickLinksButton.length > 0">
			
									<ng-container *ngFor="let actionBar of QuickLinksButton  | filterBy: {IsApplicable: true,HasPermission :true}
								  | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
										<div class="col-auto px-2 ml-auto">
											<button type="button" [ngClass]="actionBar.CustomClass" class="btnTertiary" (click)="btnClick(actionBar)"
												[disabled]="!actionBar.IsEnabled"
												value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName |
												translate
												}}
												<span *ngIf="actionBar.ShowArrow" class="ps-3"><i class="ion ion-md-arrow-forward"
														aria-hidden="true"></i></span> -->
						<!-- </button>
										</div>
									</ng-container>
								</ng-container>
								
			
							</div>




						</ng-container>  -->

					</div>
					<!-- <div class="col-8">
						<ng-container>
							<div class="row d-flex justify-content-end">
								<div class="col-auto count-col">
									<span>Deal Status Count &nbsp;</span>
								<button class="m-2 badge-count rounded-pill" [ngClass]="{'active':(selectedBtn=='Recent')}" (click)="countClick('All')"  [tooltip]="'All Deals'">All</button>
								<button class="m-2 badge-count bg-secondary rounded-pill" [ngClass]="{'active': (selectedBtn=='On Hold')}" (click)="countClick('onHold')"  [tooltip]="'On Hold'">{{onholdcount}}</button>
								<button class="m-2 badge-count bg-danger rounded-pill" [ngClass]="{'active': (selectedBtn=='Cancelled')}" (click)="countClick('cancel')"  [tooltip]="'Cancelled'">{{cancelcount}}</button>
								<button class="m-2 badge-count bg-warning rounded-pill" [ngClass]="{'active': (selectedBtn=='In Progress')}" (click)="countClick('Inprogress')"  [tooltip]="'In Progress'">{{progresscount}}</button>
								<button class="m-2 badge-count bg-success rounded-pill" [ngClass]="{'active': (selectedBtn=='Completed')}" (click)="countClick('complete')"  [tooltip]="'Completed'">{{completedcount}}</button></div>
								
							</div>
						</ng-container>
						
					</div> -->


					<div class="col-12">
						<div class="form-group">
							<ng-container>
								<searchby [SearchButtons]="SearchButtons" [SearchTemplate]="SearchTemplate"
									[FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig" [FormProp]="FormProp"
									(SearchData)="SearchData($event,false,true)" class="customForm">
								</searchby>
							</ng-container>

						</div>
					</div>
				</div>
			</div>

			<div class="card-body overflow-auto">
				<ng-container
					*ngIf="(dealListTemplate != null && dealListTemplate != undefined && dealListTemplate.length > 0)">
					<grid-dispctrl #overallgrid [FieldTemplate]="dealListTemplate"
						[GridData]="dashbaord?dealListData:(dealListData | filterBy: {DealStatusDesc: selectedBtn})"
						[GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
						(FieldChange)="onChange($event)" (ActionBarClick)="actButtonClick($event)">
					</grid-dispctrl>
				</ng-container>

			</div>
		</div>
	</div>
</div>
<!-- Loader section -->
<div [hidden]="!IsLoading">
	<loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
	<filter *ngIf="(dealListTemplate!= undefined && dealListTemplate!= null && dealListTemplate.length > 0)"
		[FieldTemplate]="dealListTemplate" [GridData]="dealListDataALL" (close)="showFilter($event)"
		(selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
	</filter>
</div>


<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>


<!-- Notification section -->
<div *ngIf="displayNotifybox">
	<notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
		[curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>



<modal-popup id="ShareDeal" style="display: none;" [modaldetail]="{'header':'Share Deal'}">
	<ng-container
		*ngIf="(ShareDealTemplate != null && ShareDealTemplate != undefined && ShareDealTemplate.length > 0 && dealListData != null && dealListData != undefined && dealListData.length)">
		<customform #shareDeal [FormTemplate]="ShareDealTemplate" [FormData]="ShareDealData"
			[FormProp]="ShareDealFormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
			(FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
		</customform>

	</ng-container>
	<div class="">
		<div class="d-flex justify-content-end p-3">
			<button type="button" class="rounded-pill btnCustom btnPrimary" (click)="validateShareDeal()"
				value="{{'Share Deal' | translate }}">
				{{'Share Deal' | translate }}
			</button>
		</div>
	</div>

	<!-- Notification section -->
	<div *ngIf="displayShareDealNotifybox">
		<notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
			[curitem]="notify.item" (selected)="setNotifyRes($event) " (close)="closeShareDealNotifybox() ">
		</notification>
	</div>

</modal-popup>