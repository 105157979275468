<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
		<div class="d-flex w-100 justify-content-between">
      <h1 class="sr-only">Glossary</h1>
      <h2 class="sr-only">Glossary</h2>
			<ul class="breadcrumb m-0 flex-fill">
				<li class="breadcrumb-item active" aria-current="page">Glossary</li>
			</ul>
		</div>
	</nav>

    <div class="contentScroll p-2">
        <div class="contentScroll p-2 simpleCard">
          <div class="card m-0">
            
            <div class="card-body overflow-auto">
            
                <div class="row p-2"> 
                  <div class="col-12 mb-4">
                    <a type="button" class="btnTertiary btnCustom rounded-pill border download-btn" href="../../assets/files/Managed Services-ScopeDescription.xlsx" download>
                      Managed Services - Scope Description
                      <span>
                        <i class="fa fa-download ps-2"></i>
                      </span>
                    </a>
                  </div>
                  <div class="col-12 mb-4">
                    <a type="button"  class="btnTertiary btnCustom rounded-pill border download-btn" href="../../assets/files/PandC-ScopeDescription.xlsx" download>
                      P and C - Scope Description 
                      <span>
                        <i class="fa fa-download ps-2"></i>
                      </span>
                    </a>
                  </div>
                  <div class="col-12 mb-4">
                    <a type="button"  class="btnTertiary btnCustom rounded-pill border download-btn" href="../../assets/files/RateCard-FTERoleDescription.xlsx" download>
                      Rate Card - FTE Role Description
                      <span>
                        <i class="fa fa-download ps-2"></i>
                      </span>
                    </a>
                  </div>

                </div>
                
              
            </div>
          
              
          </div>
        </div>
      </div>
</div>

