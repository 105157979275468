export class ProcessResponse<T> {
    Data!: T;
    Status!: number;
    Message!: string;
    EventId!: string;
}

export interface UserInfo {
    name: string;
    email: string;
    username: string;
    userid: number;
    actingusername: string;
    actinguserid: number;

    exp?: number;
    iss?: string;
    aud?: string;
    jti?: string;
}

export interface ComponentConfig {
    // AppId: string;
    Component?: string;
    SubComponent?: string;
    ReferenceValueType?: string;
    ReferenceValue?: string;
    FieldTemplate?: any[];
    Permission?: string[];
    Properties?: any[];
    ComponentCode: string;
    ComponentViewId?: number;
}

export enum ProcessStatus {
    Success = 200,
    Queued = 202,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    NoContent = 404,
    Error = 500
}

export enum ReferenceValueType {
    Quality = "1",
    ProjectId = "2",
    Admin = "3"
}
export enum RowLockStatus {
    None = 0,
    Locked = 1,
    Edit = 2
}

export interface HubGroupMember {

    GroupName: string;
    UserId: number;
    Name: string;
    ConnectionId: string;
    LastActionOn: Date;
    RowIds: string[];
}

export class GroupDataRowLocks {
    constructor(init?: Partial<GroupDataRowLocks>) {
        Object.assign(this, init);
    }

    GroupName!: string;
    Locks!: DataRowlock[];
    DataObject: any;
    DataUniqueKey!: string;
}

export class DataRowlock {
    constructor(init?: Partial<DataRowlock>) {
        Object.assign(this, init);
    }
    GroupName!: string;
    UserId!: number;
    RowId!: string;
    Name!: string;
    ConnectionId!: string;
    LockedOn!: Date;
    RowLockStatus!: RowLockStatus;
    Data: any;
}




export enum ComponentCodes {
    AppComponent = 'C001',
    DealComponent = 'C002',
    SearchComponent = 'C003',
    ReportComponent = 'C005',
    ServicetypsComponent = 'C004',
    AdminComponent = 'C006',
    LiteComponent = 'C007',
    LiteAppComponent = 'C008',
    ADDashboard = 'C010',
    AMDashboard = 'C011',
    AMDealComponent = 'C012',
    ModelConfigurationProfile = 'C013',
    AMAppComponent = 'C014',
    LotSolutionEffortModelling = 'C015',
    AMPricingModelling = 'C016',
    AMReports = 'C017',
    LotSolutionModel = 'C018',
    DeliveryModel = 'C019',
    LocationMix = 'C020',
    Productivity = 'C021',
    Staffing = 'C022'
}


export enum ActionCode {
    Update_Check = 'AC001',
    Partial_Save = 'AC002',
}

export enum ServiceDomain {
    AM = 'AMSD003',
    Enhancement = 'AMSD0018'
}

export enum GuideLinesParam {
    Recommended = 'Recommended',
    ManualOverride = 'Manual Override',
    Cola = 'COLA %',
    NetReduction = 'Net Reduction'
}

export enum LotSolutionModule {
    LocationMix = 'LocationMix',
    ProductivityImpact = 'ProductivityImpact',
    DeliveryModel = 'DeliveryModel',
    StaffingModel = 'StaffingModel'
}
export enum AMApproach {
    Top_Down_Approach = 'APR001',
    Ticket_Based_Approach = 'APR002',
    Users_Based_Approach = 'APR003',
    IT_Metrics_Driven_Approach = 'APR004'
}


export enum LotPricingModule {
    PriceModelling = 'PriceModelling',
    OtherCost = 'OtherCost',
    ClientCost = 'ClientCost'
}

export enum OtherCostTabs {
    TravelRelatedCosts = 'travelrelatedcosts',
    CommunicationCosts = 'communicationcosts',
    AssetTakeOverCosts = 'assettakeovercosts',
    ServiceManagementCost = 'servicemanagementcost',
    NonStandardHardwareSoftware = 'nonstandardhardwaresoftware',
    RebadgeCosts = 'rebadgecosts',
    ContingencyCosts = 'contingencycosts',
    MiscCostsValues = 'misccostsvalues'
}

export enum ClientCostTabs {
    SeverancePayBurden = 'severancepayburden',
    GovernanceCosts = 'governancecosts',
    RetainedFTE = 'retainedfte',
    MiscellaneosCost = 'miscellaneoscost'
}

export enum MenuModel {
    ModelConfig = 'ModelConfig',
    LotSolution = 'LotSolution',
    LotPricing = 'LotPricing',
    LotSolutionEffort = 'LotSolutionEffort'
}

export enum RateModellingOption {
    Capgemini = 'RTM001',
    External = 'RTM002'
}

export enum ReportModel {
    Solution_summary = 'solutionsummary',
    Pricing_summary = 'pricingsummary',
    Business_summary = 'businesscasesummary',
    Deal_analysis = 'dealanalysis',
    consolidated = 'consolidated',
    modelcomparison = 'modelcomparison',
    masterdatadownload = 'masterdatadownload',
    whatifswithmodel = 'whatifswithmodel',
    whatifswithadmt = 'whatifswithadmt',
    PastDealComparison = 'PastDealComparison',
}

export enum SCREEN_SIZE {
    XS,     // Extra small devices (portrait phones, less than 576px)
    SM,     // Small devices (landscape phones, 576px and up)
    MD,     // Medium devices (tablets, 768px and up)
    LG,     // Large devices (desktops, 992px and up)
    XL      // Extra large devices (large desktops, 1200px and up)
}
