<ng-container>
    <div class="h-100">
        <nav aria-label="breadcrumb" class="breadcrumbSection">
            <div class="d-flex w-100 justify-content-between">
                <!-- <h1 class="sr-only">Deal Parameters</h1>
                <h2 class="sr-only">Deal Parameters</h2> -->
                <ng-container>
                    <ul class="breadcrumb m-0 flex-fill">
                        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page"><a
                                routerLink="/app/create-deal/{{DealHeaderId}}">Deal Configuration</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page"><a
                                routerLink="/app/model-config/{{DealHeaderId}}">Model Configuration</a>
                        </li>
                        <li class="breadcrumb-item" aria-current="page"><a
                                routerLink="/app/model-config/ModelBaseline/{{DealHeaderId}}">Model Baselining</a>
                        </li>
                        <!-- <li class="breadcrumb-item" aria-current="page">
                            <a [routerLink]="['app/lot-solution-modelling/productivityImpact', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                [state]="{selectedData: selectedDeal}">Lot Solution Effort Modelling</a>
                        </li> -->
                        <ng-container>
                            <li class="breadcrumb-item" aria-current="page">
                                <a [routerLink]="['/app/lot-solution-modelling/DeliveryModel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                    [state]="{selectedData:selectedDeal}">Lot Solution Effort Modelling</a>
                            </li>
                        </ng-container>

                        <!-- <ng-container *ngIf="ServiceDomain!='AMSD0018'">
                            <li class="breadcrumb-item" aria-current="page">
                                <a [routerLink]="['app/lot-solution-modelling/StaffingModel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                                    [state]="{selectedData: selectedDeal}">Lot Solution Effort Modelling</a>
                            </li>
                        </ng-container>  -->


                        <ng-container>
                            <li class="breadcrumb-item active" aria-current="page">Lot Solution Price Modelling</li>
                        </ng-container>


                    </ul>

                    <ul class="flex-fill m-0 navbar-nav p-3 breadcrumb" *ngIf="MainModule =='AM'">
                        <li>
                            <span class="progress headerProgress ms-auto">
                                <span *ngIf="dealProgressMeter!=100" class="progress-bar bg-warning headerProgressBar"
                                    style="width:80%">{{dealProgressMeter}}%</span>
                                <span *ngIf="dealProgressMeter==100" class="progress-bar bg-warning headerProgressBar"
                                    style="width:100%">{{dealProgressMeter}}%</span>
                            </span>
                        </li>
                    </ul>
                </ng-container>

            </div>
        </nav>

        <div class="contentScroll p-3 simpleCard myDealCard">
            <div class="card h-100 m-0">
                <div class="p-1">
                    <div class="row mx-2 deal-details planInfoWraper filters">
                        <div class="col-2">
                            <label for="clientname" class="fw-bold">Client Name:&nbsp;&nbsp;
                            </label>{{selectedDeal.Client}}
                        </div>
                        <div class="col-2">
                            <label for="dealname" class="fw-bold">Deal Name:&nbsp;&nbsp;
                            </label>{{selectedDeal.DealName}}
                        </div>
                        <div class="col-2">
                            <label for="thorid" class="fw-bold">Thor Opportunity
                                ID:&nbsp;&nbsp;</label>{{selectedDeal.ThorId}}
                        </div>

                        <div class="col-2">
                            <label for="contracttype" class="fw-bold">Contract Type:&nbsp;&nbsp;
                            </label>{{selectedDeal.ContractTypeDesc}}
                        </div>

                        <div class="col-2">
                            <label for="contracttype" class="fw-bold">Model ID:&nbsp;&nbsp;
                            </label>{{selectedDeal.ModelID}}
                        </div>

                        <div class="col-2">
                            <label for="dealterm" class="fw-bold">Deal Term (in Years):&nbsp;&nbsp;
                            </label>{{selectedDeal.DealTerm}}
                        </div>

                        <div class="col-2">
                            <label for="dealterm" class="fw-bold">Model Configuration Approach:&nbsp;&nbsp;
                            </label>{{selectedDeal.AmApproachDesc}}
                        </div>

                        <div class="col-2">
                            <label for="dealcountry" class="fw-bold">Deal Contracting Country:&nbsp;&nbsp;
                            </label><span *ngIf="selectedDeal.CountryDesc">{{selectedDeal.CountryDesc}}</span><span
                                *ngIf="!selectedDeal.CountryDesc">{{selectedDeal.DealContractCountrydesc}}</span>
                        </div>
                        <div class="col-2">
                            <label for="currency" class="fw-bold">Currency:&nbsp;&nbsp;
                            </label>{{selectedDeal.Currency}}
                        </div>
                        <div class="col-2">
                            <label for="rateoption" class="fw-bold">Rate Modelling Option:&nbsp;&nbsp;
                            </label><span
                                *ngIf="selectedDeal.RateModellingOptionDesc">{{selectedDeal.RateModellingOptionDesc}}</span><span
                                *ngIf="!selectedDeal.RateModellingOptionDesc">{{selectedDeal.RateModellingDesc}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <h3>
                        <ng-container [ngSwitch]="ModuleName">
                            <ng-container *ngSwitchCase="'PriceModelling'">
                                <span>Lot Solution Price Modelling | Pricing Model</span>
                                <a href="javascript:void(0);" (click)="HelpIconClick()" class="HeaderHelpIconTooltip">
                                    <i class="fa fa-question-circle fa-ques fa-md" data-toggle="tooltip"></i>
                                </a>
                            </ng-container>
                            <ng-container *ngSwitchCase="'OtherCost'">
                                <span>Capgemini Solution Other Costs | {{tabName}}</span>
                                <a href="javascript:void(0);" (click)="HelpIconClick()" class="HeaderHelpIconTooltip">
                                    <i class="fa fa-question-circle fa-ques fa-md" data-toggle="tooltip"></i>
                                </a>
                            </ng-container>

                            <ng-container *ngSwitchCase="'ClientCost'">
                                <span>Client Costs | {{CT_tabName}}</span>
                                <a href="javascript:void(0);" (click)="HelpIconClick()" class="HeaderHelpIconTooltip">
                                    <i class="fa fa-question-circle fa-ques fa-md" data-toggle="tooltip"></i>
                                </a>
                            </ng-container>
                        </ng-container>


                    </h3>
                </div>
                <div class="card-body overflow-auto">
                    <ng-container>
                        <p class="mb-1">
                            <span>Note : Please click on the Lot to enter the pricing details</span>
                        </p>
                        <ng-container
                            *ngIf="(LotSolutionPricingTowerAM != null && LotSolutionPricingTowerAM != undefined && LotSolutionPricingTowerAM.length > 0 && BaselineDetails != null && BaselineDetails != undefined && BaselineDetails.length > 0)">
                            <grid-dispctrl #LotSolutionPricingGrid [FieldTemplate]="LotSolutionPricingTowerAM"
                                [GridData]="BaselineDetails" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                                (FieldClick)="onClick($event)" [IsPagination]="IsPagination"
                                (FieldChange)="onChange($event)" (ActionBarClick)="actButtonClick($event)">
                            </grid-dispctrl>
                        </ng-container>
                    </ng-container>
                    <br>
                    <ng-container [ngSwitch]="ModuleName">
                        <ng-container *ngSwitchCase="'PriceModelling'">
                            <ng-container *ngIf="OpenContent">
                                <ng-container
                                    *ngIf="(TransitionCostAMTemplate != null && TransitionCostAMTemplate != undefined && TransitionCostAMTemplate.length > 0 && LotSolutionPricingDetails != null && LotSolutionPricingDetails != undefined && LotSolutionPricingDetails.length > 0)">
                                    <customform #LotSolutionPricingForm [FormTemplate]="TransitionCostAMTemplate"
                                        [FormData]="selectedLotSolutionPricingDetails" [FormProp]="FormProp"
                                        [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                        [cellLock]="setCellLock" [parent]="this">
                                    </customform>
                                </ng-container>
                                <br>

                                <p class="mb-1">
                                    <span>Note: Please click on Region to enter pricing details</span>
                                </p>

                                <div class="row planInfoWraper mx-1 mb-1 helpIconFilters">
                                    <a href="javascript:void(0);" (click)="GridHelpIconClick()"
                                        class="HeaderHelpIconTooltip">
                                        <i class="fa fa-question-circle fa-ques fa-lg" data-toggle="tooltip"></i>
                                    </a>
                                </div>

                                <div class="row gridFilters mx-1 mb-1">
                                    <div class="col-6 text-center">
                                        <label for="deliveredfrom">Delivered From</label>
                                    </div>
                                    <div class="col-6 text-center">
                                        <label for="priceinm">Price (in M)</label>
                                    </div>
                                </div>

                                <ng-container
                                    *ngIf="(LotSolutionPricingAMDetailsTemplate != null && LotSolutionPricingAMDetailsTemplate != undefined && LotSolutionPricingAMDetailsTemplate.length > 0 && noDataFoundPricing == true)">
                                    <grid-dispctrl #LotSolutionPricingGrid
                                        [FieldTemplate]="LotSolutionPricingAMDetailsTemplate"
                                        [GridData]="LotSolutionPricingDetails" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                        [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>
                                </ng-container>

                                <ng-container
                                    *ngIf="(LotSolutionPricingAMDetailsTemplate != null && LotSolutionPricingAMDetailsTemplate != undefined && LotSolutionPricingAMDetailsTemplate.length > 0 && LotSolutionPricingDetails != null && LotSolutionPricingDetails != undefined && LotSolutionPricingDetails.length > 0 && noDataFoundPricing == false)">
                                    <grid-dispctrl #LotSolutionPricingGrid
                                        [FieldTemplate]="LotSolutionPricingAMDetailsTemplate"
                                        [GridData]="LotSolutionPricingDetails" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                                        [IsPagination]="IsPagination" (FieldChange)="onChange($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>
                                </ng-container>

                                <table class="table table-hover table-bordered tblGrid fixed"
                                    style="min-width: 100%; table-layout:fixed">
                                    <tbody>
                                        <tr>
                                            <td rowspan="1">Grand total</td>
                                            <td rowspan="1"></td>
                                            <td rowspan="1"></td>
                                            <td *ngFor="let el of grandTotalCustom;" rowspan="1">{{el.value}}</td>
                                            <td rowspan="1">{{SumGrandTotal}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                            </ng-container>

                            <ng-container *ngIf="OpenGrids">
                                <div class="col-12">
                                    <span class="text-bold">Resource Pyramid % - Delivered From :
                                        {{DeliveredFromRegionDesc}} - {{DeliveredFromCountryDesc}} |
                                        Delivered To : {{DeliveredToRegionDesc}} - {{DeliveredToCountryDesc}} (Lot:
                                        {{LotNameforGrid}})</span>
                                    <a href="javascript:void(0);" (click)="ResourcePyramidHelpIconClick()"
                                        class="helpIconTooltip">
                                        <i class="fa fa-question-circle fa-ques fa-lg" data-toggle="tooltip"></i>
                                    </a>
                                </div>
                                <ng-container
                                    *ngIf="((selectedDeal.RateModellingDesc == 'Capgemini' && !selectedDeal.RateModellingOptionDesc) || (selectedDeal.RateModellingOptionDesc == 'Capgemini')) && PricingRateGridTemplate != null && PricingRateGridTemplate != undefined && PricingRateGridTemplate.length > 0">
                                    <grid-dispctrl #LotSolutionPricingGrid [FieldTemplate]="PricingRateGridTemplate"
                                        [GridData]="PricingRateGridData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount_PricingRateGridData"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>
                                </ng-container>
                                <ng-container
                                    *ngIf="((selectedDeal.RateModellingDesc == 'External' && !selectedDeal.RateModellingOptionDesc) || (selectedDeal.RateModellingOptionDesc == 'External')) && PricingRateGridExternalTemplate != null && PricingRateGridExternalTemplate != undefined && PricingRateGridExternalTemplate.length > 0">
                                    <grid-dispctrl #LotSolutionPricingGrid
                                        [FieldTemplate]="PricingRateGridExternalTemplate"
                                        [GridData]="PricingRateGridData" [GridProp]="GridProp"
                                        [GridDataTotalCount]="dataTotalCount_PricingRateGridData"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>
                                </ng-container>
                                <br>

                                <ng-container
                                    *ngIf="PricingLastGridTemplate != null && PricingLastGridTemplate != undefined && PricingLastGridTemplate.length > 0 && PricingLastGridData != null && PricingLastGridData != undefined && PricingLastGridData.length > 0">
                                    <grid-dispctrl #LotSolutionPricingGrid [FieldTemplate]="PricingLastGridTemplate"
                                        [GridData]="PricingLastGridData" [GridProp]="GridProp"
                                        [IsPagination]="IsPagination" [GridDataTotalCount]="dataTotalCount"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                        (ActionBarClick)="actButtonClick($event)">
                                    </grid-dispctrl>
                                </ng-container>
                                <br>

                                <div class="">
                                    <div class="d-flex justify-content-end p-3">
                                        <button type="button" class="rounded-pill btnCustom btnPrimary"
                                            (click)="savePricingModel()" value="">
                                            Save
                                        </button>
                                    </div>
                                </div>

                            </ng-container>
                        </ng-container>


                        <ng-container *ngSwitchCase="'OtherCost'">
                            <ng-container *ngIf="DealSubLineItemId > 0">
                                <div class="p-2 full simpleCard">

                                    <ng-container
                                        *ngIf="(showdealDropdown && DealTemplate != null && DealTemplate != undefined && DealTemplate.length > 0)">

                                        <customform #lotpridealdropdown [FormTemplate]="DealTemplate"
                                            [FormData]="DealData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </customform>
                                    </ng-container>

                                    <ng-container *ngIf="gridName != null && gridName != undefined 
                                                                        && gridName != ''">
                                        <p class="text-bold mb-1">
                                            <span>{{gridName}}</span> <span data-bs-toggle="tooltip"
                                                data-bs-placement="bottom" [title]="gridToolTip"><img
                                                    src="../../../assets/images/information-Icons.svg"
                                                    alt="information icon"></span>
                                        </p>
                                    </ng-container>
                                    <div class="gridContainer mb-3">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered tblGrid fixed">
                                                <thead>

                                                    <ng-container *ngIf="dynamicHeaderTemlate != null && dynamicHeaderTemlate != undefined 
                                                                        && dynamicHeaderTemlate.length > 0">


                                                        <ng-container *ngFor="let headerData of dynamicHeaderTemlate">

                                                            <ng-container *ngIf="headerData.Columns != null && headerData.Columns != undefined 
                                                                                && headerData.Columns.length > 0">
                                                                <tr>

                                                                    <ng-container
                                                                        *ngFor="let colData of headerData.Columns">

                                                                        <ng-container *ngIf="!colData.IsRowSpan">
                                                                            <ng-container
                                                                                *ngIf="colData.IsField == true && colData.IsColsSpan == false && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == false">

                                                                                <th class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == true && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == false && colData.IsField == false">

                                                                                <th scope="col"
                                                                                    [attr.colspan]="colData.ColSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == true && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == true && colData.IsField == false">

                                                                                <th scope="col"
                                                                                    [attr.colspan]="colData.ColSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="colData.IsDealTermColsSpan == true && colData.IsDealTermSplit == true && colData.IsColsSpan == false && colData.InputType == 'label'">

                                                                                <th scope="col" class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="colData.IsDealTermColsSpan == true
                                                                                            && colData.IsDealTermSplit == true && colData.IsColsSpan == false
                                                                                            && colData.InputType != 'label'">

                                                                                <th class="text-center">

                                                                                    <div class="input-group mb-3">
                                                                                        <input
                                                                                            [type]="colData.InputType"
                                                                                            [(ngModel)]="colData[colData.FieldName]"
                                                                                            [id]="fieldRandomId+'-'+colData.FieldName"
                                                                                            [value]="colData[colData.FieldName]"
                                                                                            class="form-control text-center form-control-sm"
                                                                                            (change)="ondynamicHeaderChange(colData)">
                                                                                        <span
                                                                                            class="input-group-text">%</span>
                                                                                    </div>
                                                                                </th>

                                                                            </ng-container>
                                                                        </ng-container>

                                                                        <ng-container *ngIf="colData.IsRowSpan">
                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == false  
                                                                                                && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == false && colData.IsField == false">

                                                                                <th scope="col"
                                                                                    [attr.rowspan]="colData.RowSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == false 
                                                                                            && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == true && colData.IsField == false">

                                                                                <th scope="col"
                                                                                    [attr.rowspan]="colData.RowSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="colData.IsDealTermColsSpan == true
                                                                                                && colData.IsDealTermSplit == true 
                                                                                                && colData.IsColsSpan == false 
                                                                                                && colData.InputType == 'label'">

                                                                                <th scope="col"
                                                                                    [attr.rowspan]="colData.RowSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>

                                                                        </ng-container>

                                                                    </ng-container>

                                                                </tr>

                                                            </ng-container>

                                                        </ng-container>
                                                    </ng-container>
                                                </thead>
                                                <tbody *ngIf="otherCostData != null && otherCostData != undefined 
                                                && otherCostData.length > 0">
                                                    <ng-container *ngFor="let data of otherCostData">
                                                        <tr>
                                                            <ng-container *ngFor="let fldData of dynamicFieldTemlate">

                                                                <ng-container
                                                                    *ngIf="fldData.Control.InputType != 'button' && fldData.Control.InputType != 'dropdown'">
                                                                    <td class="text-center">
                                                                        <input [type]="fldData.Control.InputType"
                                                                            [(ngModel)]="data[fldData.FieldName]"
                                                                            [id]="fieldRandomId+'-'+fldData.FieldName"
                                                                            [value]="data[fldData.FieldName]"
                                                                            class="form-control text-center form-control-sm"
                                                                            (change)="ondynamicChange(data,fldData)"
                                                                            [placeholder]="fldData.DisplayName"
                                                                            [disabled]="fldData.Disable">
                                                                    </td>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="fldData.Control.InputType == 'button'">
                                                                    <td class="text-center">
                                                                        <button
                                                                            [id]="fieldRandomId+'-'+fldData.FieldName"
                                                                            class="text-center btnCustom btnPrimary btn-sm rounded-pill"
                                                                            (click)="ondynamicClick(data,fldData)">
                                                                            <span>{{fldData.DisplayName}}</span>
                                                                        </button>
                                                                    </td>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="fldData.Control.InputType == 'dropdown'">
                                                                    <td class="text-center">
                                                                        <ng-select
                                                                            [id]="fieldRandomId+'-'+fldData.FieldName"
                                                                            #select class="custom"
                                                                            [items]="fldData.Control.List"
                                                                            [(ngModel)]="data[fldData.FieldName]"
                                                                            [bindLabel]="fldData.Control!.Source!.ValueField!"
                                                                            [bindValue]="fldData.Control!.Source!.KeyField!"
                                                                            [placeholder]="fldData.DisplayName"
                                                                            appendTo="body"
                                                                            [inputAttrs]="{autofocus: 'false'}"
                                                                            [compareWith]="compareWithFn"
                                                                            [closeOnSelect]="true"
                                                                            (change)="ondynamicChange(data,fldData)"
                                                                            [disabled]="fldData.Disable"
                                                                            (click)="ondynamicClick(data,fldData)">
                                                                        </ng-select>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="data.Split != null && data.Split != undefined 
                                                                                                        && data.Split.length > 0">
                                                                <ng-container *ngFor="let splitData of data.Split">

                                                                    <td class="text-center">
                                                                        <input type="number"
                                                                            [(ngModel)]="splitData.Value"
                                                                            [id]="fieldRandomId+'-'+splitData.Year"
                                                                            [value]="splitData.Value"
                                                                            class="form-control text-center form-control-sm"
                                                                            disabled=true>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>

                                                    <tr>
                                                        <ng-container *ngFor="let ftData of dynamicFooterTemlate">

                                                            <ng-container *ngIf="ftData.ColSpan == 0">
                                                                <td class="text-center">
                                                                    <input [type]="ftData.InputType"
                                                                        [(ngModel)]="ftData[ftData.FieldName]"
                                                                        [id]="fieldRandomId+'-'+ftData.FieldName"
                                                                        [value]="ftData[ftData.FieldName]"
                                                                        class="form-control text-center form-control-sm"
                                                                        [placeholder]="ftData.DisplayName"
                                                                        [disabled]="ftData.disabled">
                                                                </td>
                                                            </ng-container>

                                                            <ng-container *ngIf="ftData.ColSpan > 0">
                                                                <td scope="col" [attr.colspan]="ftData.ColSpan"
                                                                    class="text-center">{{ftData.DisplayName}}</td>
                                                            </ng-container>


                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                                <tbody
                                                    *ngIf="otherCostData != null && otherCostData != undefined && otherCostData.length == 0">
                                                    <tr>
                                                        <td class="text-center" style="background-color: #ffffff"
                                                            [attr.colspan]="getColumnCount()">
                                                            -- No Record Found --
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <p class="text-bold mb-1">
                                        <span>Capgemini Solution Total Cost - Price (in M)</span>
                                    </p>
                                    <div class="gridContainer mb-3">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered tblGrid fixed">
                                                <thead>
                                                    <tr>
                                                        <ng-container *ngFor="let fthData of dynamicTotalCostTemlate">
                                                            <th class="text-center text-bold">
                                                                {{fthData.DisplayName}}</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="dynamicTotalCostTemlate != null && dynamicTotalCostTemlate != undefined 
                                                    && dynamicTotalCostTemlate.length > 0">
                                                    <tr>
                                                        <ng-container *ngFor="let ftData of dynamicTotalCostTemlate">
                                                            <td class="text-center">
                                                                <input [type]="ftData.InputType"
                                                                    [(ngModel)]="ftData[ftData.FieldName]"
                                                                    [id]="fieldRandomId+'-'+ftData.FieldName"
                                                                    [value]="ftData[ftData.FieldName]"
                                                                    class="form-control text-center form-control-sm"
                                                                    [placeholder]="ftData.DisplayName"
                                                                    [disabled]="ftData.disabled">
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                                <tbody
                                                    *ngIf="dynamicTotalCostTemlate != null && dynamicTotalCostTemlate != undefined && dynamicTotalCostTemlate.length == 0">
                                                    <tr>
                                                        <td class="text-center" style="background-color: #ffffff"
                                                            [attr.colspan]="getColumnCount()">
                                                            -- No Record Found --
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- </ng-container>
                                     </ng-container>
                                     </tabset>
                                        </div>
                                    </ng-container> -->
                                </div>
                            </ng-container>
                        </ng-container>


                        <ng-container *ngSwitchCase="'ClientCost'">
                            <ng-container *ngIf="DealSubLineItemId > 0">
                                <div class="p-2 full simpleCard">
    
                                    <ng-container
                                        *ngIf="(ClientCostDealTemplate != null && ClientCostDealTemplate != undefined && ClientCostDealTemplate.length > 0)">
    
                                        <customform #lotpridealdropdown [FormTemplate]="ClientCostDealTemplate"
                                            [FormData]="ClientCostDealData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                            (FieldChange)="onChange($event)">
                                        </customform>
                                    </ng-container>
    
                                    <ng-container *ngIf="CT_gridName != null && CT_gridName != undefined 
                                                                        && CT_gridName != ''">
                                        <p class="text-bold mb-1">
                                            <span>{{CT_gridName}}</span> <span data-bs-toggle="tooltip"
                                                data-bs-placement="bottom" [title]="CT_gridToolTip"><img
                                                    src="../../../assets/images/information-Icons.svg"
                                                    alt="information icon"></span>
                                        </p>
                                    </ng-container>
                                    <div class="gridContainer mb-3">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered tblGrid fixed">
                                                <thead>
    
                                                    <ng-container *ngIf="dynamicClientCostHeaderTemlate != null && dynamicClientCostHeaderTemlate != undefined 
                                                                        && dynamicClientCostHeaderTemlate.length > 0">
    
    
                                                        <ng-container
                                                            *ngFor="let headerData of dynamicClientCostHeaderTemlate">
    
                                                            <ng-container *ngIf="headerData.Columns != null && headerData.Columns != undefined 
                                                                                && headerData.Columns.length > 0">
                                                                <tr>
    
                                                                    <ng-container
                                                                        *ngFor="let colData of headerData.Columns">
    
                                                                        <ng-container *ngIf="!colData.IsRowSpan">
                                                                            <ng-container
                                                                                *ngIf="colData.IsField == true && colData.IsColsSpan == false && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == false">
    
                                                                                <th class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == true && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == false && colData.IsField == false">
    
                                                                                <th scope="col"
                                                                                    [attr.colspan]="colData.ColSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == true && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == true && colData.IsField == false">
    
                                                                                <th scope="col"
                                                                                    [attr.colspan]="colData.ColSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                            <ng-container
                                                                                *ngIf="colData.IsDealTermColsSpan == true && colData.IsDealTermSplit == true && colData.IsColsSpan == false && colData.InputType == 'label'">
    
                                                                                <th scope="col" class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                            <ng-container
                                                                                *ngIf="colData.IsDealTermColsSpan == true
                                                                                            && colData.IsDealTermSplit == true && colData.IsColsSpan == false
                                                                                            && colData.InputType != 'label'">
    
                                                                                <th class="text-center">
    
                                                                                    <div class="input-group mb-3">
                                                                                        <input [type]="colData.InputType"
                                                                                            [(ngModel)]="colData[colData.FieldName]"
                                                                                            [id]="fieldRandomId+'-'+colData.FieldName"
                                                                                            [value]="colData[colData.FieldName]"
                                                                                            class="form-control text-center form-control-sm"
                                                                                            (change)="ondynamicHeaderChange(colData)">
                                                                                        <span
                                                                                            class="input-group-text">%</span>
                                                                                    </div>
                                                                                </th>
    
                                                                            </ng-container>
                                                                        </ng-container>
    
                                                                        <ng-container *ngIf="colData.IsRowSpan">
                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == false  
                                                                                                && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == false && colData.IsField == false">
    
                                                                                <th scope="col"
                                                                                    [attr.rowspan]="colData.RowSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                            <ng-container
                                                                                *ngIf="colData.IsColsSpan == false 
                                                                                            && colData.IsDealTermSplit == false && colData.IsDealTermColsSpan == true && colData.IsField == false">
    
                                                                                <th scope="col"
                                                                                    [attr.rowspan]="colData.RowSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                            <ng-container
                                                                                *ngIf="colData.IsDealTermColsSpan == true
                                                                                                && colData.IsDealTermSplit == true 
                                                                                                && colData.IsColsSpan == false 
                                                                                                && colData.InputType == 'label'">
    
                                                                                <th scope="col"
                                                                                    [attr.rowspan]="colData.RowSpan"
                                                                                    class="text-center">
                                                                                    {{colData.DisplayName}}</th>
                                                                            </ng-container>
    
                                                                        </ng-container>
    
                                                                    </ng-container>
    
                                                                </tr>
    
                                                            </ng-container>
    
                                                        </ng-container>
                                                    </ng-container>
                                                </thead>
                                                <tbody *ngIf="clientCostData != null && clientCostData != undefined 
                                                && clientCostData.length > 0">
                                                    <ng-container *ngFor="let data of clientCostData">
                                                        <tr>
                                                            <ng-container
                                                                *ngFor="let fldData of dynamicClientCostFieldTemlate">
    
    
                                                                <ng-container
                                                                    *ngIf="fldData.Control.InputType != 'button' && fldData.Control.InputType != 'dropdown'">
    
    
                                                                    <td class="text-center">
                                                                        <input [type]="fldData.Control.InputType"
                                                                            [(ngModel)]="data[fldData.FieldName]"
                                                                            [id]="fieldRandomId+'-'+fldData.FieldName"
                                                                            [value]="data[fldData.FieldName]"
                                                                            class="form-control text-center form-control-sm"
                                                                            (change)="ondynamicChange(data,fldData)"
                                                                            [placeholder]="fldData.DisplayName"
                                                                            [disabled]="fldData.Disable">
                                                                    </td>
                                                                </ng-container>
    
                                                                <ng-container *ngIf="fldData.Control.InputType == 'button'">
                                                                    <td class="text-center">
                                                                        <button [id]="fieldRandomId+'-'+fldData.FieldName"
                                                                            class="text-center btnCustom btnPrimary btn-sm rounded-pill"
                                                                            (click)="ondynamicClick(data,fldData)">
                                                                            <span>{{fldData.DisplayName}}</span>
                                                                        </button>
                                                                    </td>
                                                                </ng-container>
    
                                                                <ng-container
                                                                    *ngIf="fldData.Control.InputType == 'dropdown'">
                                                                    <td class="text-center">
                                                                        <ng-select
                                                                            [id]="fieldRandomId+'-'+fldData.FieldName"
                                                                            #select class="custom"
                                                                            [items]="fldData.Control.List"
                                                                            [(ngModel)]="data[fldData.FieldName]"
                                                                            [bindLabel]="fldData.Control!.Source!.ValueField!"
                                                                            [bindValue]="fldData.Control!.Source!.KeyField!"
                                                                            [placeholder]="fldData.DisplayName"
                                                                            appendTo="body"
                                                                            [inputAttrs]="{autofocus: 'false'}"
                                                                            [compareWith]="compareWithFn"
                                                                            [closeOnSelect]="true"
                                                                            (change)="ondynamicChange(data,fldData)"
                                                                            [disabled]="fldData.Disable"
                                                                            (click)="ondynamicClick(data,fldData)">
                                                                        </ng-select>
                                                                    </td>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="data.Split != null && data.Split != undefined 
                                                                                                        && data.Split.length > 0">
                                                                <ng-container *ngFor="let splitData of data.Split">
    
                                                                    <ng-container
                                                                        *ngIf="showSplitYear && splitData.Year === 'Y1'">
    
                                                                        <td class="text-center">
                                                                            <input type="number"
                                                                                [(ngModel)]="splitData.Value"
                                                                                [id]="fieldRandomId+'-'+splitData.Year"
                                                                                [value]="splitData.Value"
                                                                                class="form-control text-center form-control-sm"
                                                                                disabled=true>
                                                                        </td>
                                                                    </ng-container>
    
                                                                    <ng-container *ngIf="!showSplitYear">
    
                                                                        <td class="text-center">
                                                                            <input type="number"
                                                                                [(ngModel)]="splitData.Value"
                                                                                [id]="fieldRandomId+'-'+splitData.Year"
                                                                                [value]="splitData.Value"
                                                                                class="form-control text-center form-control-sm"
                                                                                disabled=true>
                                                                        </td>
                                                                    </ng-container>
    
                                                                </ng-container>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
    
                                                    <tr>
                                                        <ng-container *ngFor="let ftData of dynamicClientCostFooterTemlate">
    
                                                            <ng-container *ngIf="ftData.ColSpan == 0">
                                                                <td class="text-center">
                                                                    <input [type]="ftData.InputType"
                                                                        [(ngModel)]="ftData[ftData.FieldName]"
                                                                        [id]="fieldRandomId+'-'+ftData.FieldName"
                                                                        [value]="ftData[ftData.FieldName]"
                                                                        class="form-control text-center form-control-sm"
                                                                        [placeholder]="ftData.DisplayName"
                                                                        [disabled]="ftData.disabled">
                                                                </td>
                                                            </ng-container>
    
                                                            <ng-container *ngIf="ftData.ColSpan > 0">
                                                                <td scope="col" [attr.colspan]="ftData.ColSpan"
                                                                    class="text-center">{{ftData.DisplayName}}</td>
                                                            </ng-container>
    
    
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                                <tbody
                                                    *ngIf="clientCostData != null && clientCostData != undefined && clientCostData.length == 0">
                                                    <tr>
                                                        <td class="text-center" style="background-color: #ffffff"
                                                            [attr.colspan]="getColumnCount()">
                                                            -- No Record Found --
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
    
                                    <ng-container *ngIf="is_CT_SaveButton">
                                        <div class="d-flex justify-content-end mb-4">
                                            <button class="btnCustom rounded-pill btnTertiary"
                                                [disabled]="CT_SaveButton_enabled" (click)="saveClick('SAVE')">
                                                <span>Save</span>
                                            </button>
                                        </div>
                                    </ng-container>
    
                                    <p class="text-bold mb-1">
                                        <span>Client Total Cost - Price (in M)</span>
                                    </p>
                                    <div class="gridContainer mb-3">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-bordered tblGrid fixed">
                                                <thead>
                                                    <tr>
                                                        <ng-container
                                                            *ngFor="let fthData of dynamicClientCostTotalCostTemlate">
                                                            <th class="text-center text-bold">
                                                                {{fthData.DisplayName}}</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="dynamicClientCostTotalCostTemlate != null && dynamicClientCostTotalCostTemlate != undefined 
                                                    && dynamicClientCostTotalCostTemlate.length > 0">
                                                    <tr>
                                                        <ng-container
                                                            *ngFor="let ftData of dynamicClientCostTotalCostTemlate">
                                                            <td class="text-center">
                                                                <input [type]="ftData.InputType"
                                                                    [(ngModel)]="ftData[ftData.FieldName]"
                                                                    [id]="fieldRandomId+'-'+ftData.FieldName"
                                                                    [value]="ftData[ftData.FieldName]"
                                                                    class="form-control text-center form-control-sm"
                                                                    [placeholder]="ftData.DisplayName"
                                                                    [disabled]="ftData.disabled">
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </tbody>
                                                <tbody
                                                    *ngIf="dynamicClientCostTotalCostTemlate != null && dynamicClientCostTotalCostTemlate != undefined && dynamicClientCostTotalCostTemlate.length == 0">
                                                    <tr>
                                                        <td class="text-center" style="background-color: #ffffff"
                                                            [attr.colspan]="getColumnCount()">
                                                            -- No Record Found --
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
    
                                </div>
                            </ng-container>
                        </ng-container>
                        
                    </ng-container>

                </div>

                <div>
                    <div class="filters buttonHolder" id="create-deal">
                        <div class="d-flex justify-content-end">
                            <ng-container *ngIf="Config.ActionButtons != null && Config.ActionButtons != undefined">
                                <ng-container *ngFor="let actionBar of Config.ActionButtons 
                          | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                    <button type="button" [ngClass]="actionBar.CustomClass"
                                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                        | translate}}
                                    </button>
                                </ng-container>



                            </ng-container>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-container>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
        [curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<modal-popup id="CalculatorDetailsExternal" style="display: none;" [modaldetail]="modalInf">
    <ng-container
        *ngIf="((selectedDeal.RateModellingDesc == 'External' && !selectedDeal.RateModellingOptionDesc) || (selectedDeal.RateModellingOptionDesc == 'External')) && ModuleName == 'PriceModelling' && CalculatorExternalTemplate != null && CalculatorExternalTemplate != undefined && CalculatorExternalTemplate.length > 0  && CalculatorDetailsExternal != null && CalculatorDetailsExternal != undefined && CalculatorDetailsExternal.length > 0">
        <customform #LotSolutionPricingForm [FormTemplate]="CalculatorExternalTemplate"
            [FormData]="SelectedCalculatorDetailsExternal" [FormProp]="FormProp" [FormConfig]="FormConfig"
            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </customform>
    </ng-container>
    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="saveCalculatorExternal()" value="">
                Save
            </button>
            <button type="button" class="rounded-pill btnCustom" (click)="cancelCalculator()" value="">
                Cancel
            </button>
        </div>
    </div>
</modal-popup>

<modal-popup id="RateDetails" style="display: none;" [modaldetail]="modalInf">
    <ng-container>
        <div class="row deal-details filters mx-0">
            <a href="javascript:void(0);" (click)="PricingRateHelpIconClick()" class="HeaderHelpIconTooltip">
                <i class="fa fa-question-circle fa-ques" data-toggle="tooltip"></i>
            </a>
        </div>
    </ng-container>
    <br>
    <ng-container
        *ngIf="((selectedDeal.RateModellingDesc == 'External' && !selectedDeal.RateModellingOptionDesc) || (selectedDeal.RateModellingOptionDesc == 'External')) && ModuleName == 'PriceModelling' && AMPricingModelPopUpExternalTemplate != null && AMPricingModelPopUpExternalTemplate != undefined && AMPricingModelPopUpExternalTemplate.length > 0  && AMPricingModelPopupDetails != null && AMPricingModelPopupDetails != undefined && AMPricingModelPopupDetails.length > 0">
        <customform #LotSolutionPricingForm [FormTemplate]="AMPricingModelPopUpExternalTemplate"
            [FormData]="selectedAMPricingModelPopupDetails" [FormProp]="FormPropModelPopup" [FormConfig]="FormConfig"
            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" [cellLock]="setCellLock" [parent]="this"
            (FieldChange)="onChange($event)">
        </customform>



    </ng-container>

    <ng-container
        *ngIf="((selectedDeal.RateModellingDesc == 'Capgemini' && !selectedDeal.RateModellingOptionDesc) || (selectedDeal.RateModellingOptionDesc == 'Capgemini')) && ModuleName == 'PriceModelling' && AMPricingModelPopUpTemplate != null && AMPricingModelPopUpTemplate != undefined && AMPricingModelPopUpTemplate.length > 0  && AMPricingModelPopupDetails != null && AMPricingModelPopupDetails != undefined && AMPricingModelPopupDetails.length > 0">

        <customform #LotSolutionPricingForm [FormTemplate]="AMPricingModelPopUpTemplate"
            [FormData]="selectedAMPricingModelPopupDetails" [FormProp]="FormPropModelPopup" [FormConfig]="FormConfig"
            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
            [cellLock]="setCellLock" [parent]="this">
        </customform>

    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="InsertUpdatePricingPopUp()"
                value="">
                Save
            </button>
            <button type="button" class="rounded-pill btnCustom" (click)="cancelRatePopup()" value="">
                Cancel
            </button>
        </div>
    </div>
</modal-popup>

<modal-popup id="OpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>Lot Solution price modeling – this panel is about creating a pricing model for all the lots based on the staffing
        models created. This has three sub-panels to price labor costs, non-labor costs and client costs associated with
        the outsourcing initiative.</p>
</modal-popup>

<modal-popup id="GridOpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>Lot-Specific location-wise detailed pricing - The form allows users to select rates specific to a role while
        displaying attributes of a role e.g. Standard role name, YoE and Role type. The default values are displayed as
        per location and service domains of the lot. Individual rates can be modified by clicking the “Rate” button.</p>
</modal-popup>

<modal-popup id="ResourcePyramidDetails" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>Lot-Specific location-wise detailed pricing - The form allows users to select costs and margins specific to a
        role while displaying attributes of a role e.g. Local grades, Global grades, Standard role name, YoE and Role
        type. The default values are displayed as per location and service domains of the lot. Individual rates can be
        modified by clicking the “Rate” button.</p>
</modal-popup>

<modal-popup id="OpenRateTooltip" style="display: none; font-size: x-small; size:modal-xs modal-dialog-centered;">
    Rate – Individual roles can be assigned a rate of choice – in case of external market benchmark data, by selecting
    two different benchmark sources. The selected role rates can be used either taking the high, low or median values.
    ? Rate Category - to identify a role with specific premiums if not Generic skills; e.g. Niche skills, Cloud, RPA
    etc.
</modal-popup>

<modal-popup id="ServiceManagementDetails" style="display: none;" [modaldetail]="modalInf">

    <ng-container *ngIf="(ServManagePopupTemplate != null && ServManagePopupTemplate != undefined && ServManagePopupTemplate.length > 0
        && resourceCostData != null && resourceCostData != undefined && resourceCostData.length > 0)">
        <grid-dispctrl #serviceMangePopupGrid [FieldTemplate]="ServManagePopupTemplate" [GridData]="resourceCostData"
            [GridProp]="GridProp" [GridDataTotalCount]="resourceCostData.length" (FieldClick)="onClick($event)"
            [IsPagination]="IsPagination" (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
        </grid-dispctrl>
    </ng-container>

    <div class="">
        <div class="d-flex justify-content-end p-3">
            <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="insertUpdateResourceCost()"
                value="">
                Save
            </button>
            <button type="button" class="rounded-pill btnCustom" (click)="cancelresourceCostPopup()" value="">
                Cancel
            </button>
        </div>
    </div>
</modal-popup>