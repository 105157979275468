
<div class="row align-items-center">
    <div class="col-6">
        <div class="card bg-white mb-0">
            <div class="card-header rounded-1 text-center pt-2">
                <h5>
                    <span>{{Graph_DATA_1_NAME | translate}}</span>
                </h5>
            </div>
            <div class="card-body p-1">
                <div id="chartdiv" class="P2WCharts"></div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card bg-white mb-0">
            <div class="card-header rounded-1 text-center pt-2">
                <h5>
                    <span>{{Graph_DATA_2_NAME | translate}}</span>
                </h5>
            </div>
            <div class="card-body p-1">
                <div id="chartdiv1" class="P2WCharts"></div>
            </div>
        </div>
    </div>

</div>
