<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <ul class="breadcrumb m-0 flex-fill">
                <li class="breadcrumb-item " aria-current="page">Reports
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{ReportDisplayName | translate}}</li>
            </ul>
        </div>
    </nav>
    <ng-container
        *ngIf="ModuleName!='whatifswithadmt' && ModuleName!='quickdealupload' && ModuleName!='DealComparisonUpload'">
        <div class="card m-2">
            <div class="card-header">
                <h3>
                    <span>{{ReportDisplayName | translate}}</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">
                <ng-container
                    *ngIf="(ReportsFormTemplate != null && ReportsFormTemplate != undefined && ReportsFormTemplate.length > 0)">
                    <customform #downloadsForm [FormTemplate]="ReportsFormTemplate" [FormData]="ReportFormData"
                        [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                    </customform>
                </ng-container>
                <br>
                <div [hidden]="!downloadDealAnalysisFlag">
                    <loading [IsLoading]="downloadDealAnalysisFlag" [IsFullScreen]="false"></loading>
                </div>
                <div *ngIf="displayNotifybox">
                    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
                        [curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()">
                    </notification>
                </div>
                <div *ngIf="ModuleName=='consolidated'">
                    <div class="d-flex justify-content-start">
                        <button type="button" class="rounded-pill btnCustom btnPrimary mb-3"
                            (click)="DownloadConsolidateReport()" value="">
                            Export To xlsx
                        </button>
                    </div>
                </div>

                <div *ngIf="ModuleName=='dealanalysis'">
                    <div class="d-flex justify-content-center p-3">
                        <button type="button" class="rounded-pill btnCustom btnPrimary"
                            (click)="GetDealAnalysisReport()" value="">
                            Download
                        </button>
                        <button type="button" class="rounded-pill btnCustom" (click)="CancelDealAnalysis()" value="">
                            Cancel
                        </button>
                    </div>
                </div>


                <div *ngIf="ModuleName=='PegaDealAnalysis'">
                    <div class="d-flex justify-content-center p-3">
                        <button type="button" class="rounded-pill btnCustom btnPrimary"
                            (click)="GetPegaDealAnalysisReport()" value="">
                            Download
                        </button>
                        <button type="button" class="rounded-pill btnCustom" (click)="CancelDealAnalysis()" value="">
                            Cancel
                        </button>
                    </div>
                </div>

                <div *ngIf="ModuleName=='masterdatadownload'">
                    <div class="d-flex justify-content-center p-3">
                        <button type="button" class="rounded-pill btnCustom btnPrimary"
                            (click)="DownloadExcel_MasterDataDownload()" value="">
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="ModuleName=='whatifswithadmt'">
        <div class="card m-2">
            <div class="card-header">
                <h3>
                    <span>What-ifs with ADMT</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">

                <div class="row small-gutter mb-4">
                    <div class="col-4">
                        <p><b>Step 1 - </b> Download the excel template and provide your inputs</p>
                        <p><b>Step 2 -</b> Upload the Excel</p>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <ul class="actionList">
                                    <li>
                                        <input type="file" id="file" accept=".xlsx, .xls,.xlsm"
                                            (change)="handleFileInput($event)">


                                    </li>
                                    <!-- <li>
                                          
                                            <a href="javascript:;"><i class="fa fa-upload" aria-hidden="true"></i>Upload
                                                Excel</a>
                                        </li> -->
                                </ul>
                            </div>
                            <div class="addHolidaypage-btn">
                                <!-- <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url="">
                                        <span>Choose File</span>
                                    </button> -->


                                <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                    data-href="#tabDashboard" data-url="" (click)="UploadExcel()">
                                    <span>Upload</span>
                                </button>


                                &nbsp;&nbsp;
                                <a type="button" href="../../assets/files/admtdealsimulationuploadtemplate.xlsm"
                                    download>
                                    <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url="">
                                        <span>Download</span>
                                    </button>

                                </a>
                            </div>
                        </div>
                    </div>

                </div>



                <ng-container *ngIf="ModuleName=='whatifswithadmt'">

                    <ng-container
                        *ngIf="(ReportsTemplate_ADMT != null && ReportsTemplate_ADMT != undefined && ReportsTemplate_ADMT.length > 0) && uploadflag">
                        <b>Deal Details</b>
                        <grid-dispctrl #ReportsTabs [FieldTemplate]="ReportsTemplate_ADMT"
                            [GridData]="ReportGridData_ADMT" [GridProp]="GridProp1"
                            [GridDataTotalCount]="DataTotalCount" (ActionBarClick)="actButtonClick($event)"
                            [IsPagination]="IsPagination" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
                        </grid-dispctrl>
                    </ng-container>
                    <br>
                    <div *ngIf="uploadflag" class="d-flex justify-content-center">
                        <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab" data-href="#tabDashboard"
                            data-url="" (click)="actionButtonClick($event)">
                            <span>Compare Deals</span>
                        </button>
                    </div>

                    <br>
                    <ng-container *ngIf="compareflag">
                        <p class="text-bold text-center phead">
                            <span>Solution Levers</span>
                        </p>
                        <div class="row small-gutter mb-4">
                            <div class="col-6">
                                <br>
                                <b>Pyramid %</b>
                                <ng-container
                                    *ngIf="(PyramidGridTemplate != null && PyramidGridTemplate != undefined && PyramidGridTemplate.length > 0)">
                                    <grid-dispctrl #ReportsTabs [FieldTemplate]="PyramidGridTemplate"
                                        [GridData]="(ReportGridData_ADMT_grid| filterBy: {ReportType:'PyramidPer'})"
                                        [GridProp]="GridProp1" [GridDataTotalCount]="DataTotalCount"
                                        (ActionBarClick)="actButtonClick($event)" [IsPagination]="IsPagination"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                        [cellLock]="setCellLock" [parent]="this">
                                    </grid-dispctrl>


                                </ng-container>
                                <div class="d-flex justify-content-end mt-4">
                                    <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url=""
                                        (click)="ApplyButtonClick(ReportGridData_ADMT_grid,'PyramidGrid')">
                                        <span>Apply</span>

                                        <!-- (click)="ApplyButtonClick(gridforwhatifmodel_data)" -->
                                    </button>
                                </div>
                            </div>
                            <div class="col-6">
                                <br>
                                <b>Efforts %</b>
                                <ng-container
                                    *ngIf="(EffortsGridTemplate != null && EffortsGridTemplate != undefined && EffortsGridTemplate.length > 0)">
                                    <grid-dispctrl #ReportsTabs [FieldTemplate]="EffortsGridTemplate"
                                        [GridData]="(ReportGridData_ADMT_grid | filterBy: {ReportType:'EffortsPer'})"
                                        [GridProp]="GridProp1" [GridDataTotalCount]="DataTotalCount"
                                        (ActionBarClick)="actButtonClick($event)" [IsPagination]="IsPagination"
                                        (FieldClick)="onClick($event)" (FieldChange)="onChange($event)"
                                        [cellLock]="setCellLock" [parent]="this">
                                    </grid-dispctrl>
                                </ng-container>

                                <div class="d-flex justify-content-end mt-4">
                                    <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url=""
                                        (click)="ApplyButtonClick(ReportGridData_ADMT_grid1,'EffortsGrid')">
                                        <span>Apply</span>

                                        <!-- (click)="ApplyButtonClick(gridforwhatifmodel_data)" -->
                                    </button>
                                </div>
                                <div class="w-100 small-gutter mb-4"></div>
                                <div class="gridContainer">
                                    <div class="table-responsive">
                                        <table class="table table-bordered tblGrid fixed">
                                            <thead>
                                                <tr>
                                                    <th scope="col" rowspan="2" width="180">Productivity
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>

                                                        <br>
                                                        <input type="range" class="form-range" id="customRange"
                                                            name="prod_range" min="-100" max="100"
                                                            (change)="rangeChanged($event)">
                                                        <input type="text" readonly="true" class="greyinputbox"
                                                            value="20" [(ngModel)]="prod_range">
                                                        ({{ProductivityPer}})%
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="row small-gutter mb-4">
                            <div class="col-12">
                                <p class="text-bold text-center phead">
                                    <span>Financial Levers</span>
                                </p>

                                <div class="col-6 float-start">
                                    <div class="gridContainer">
                                        <div class="table-responsive">
                                            <table class="table table-bordered tblGrid fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" rowspan="2" width="180">Expenses
                                                            %
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>


                                                            <input type="range" class="form-range" id="customRange"
                                                                name="expense_range" min="-100" max="100"
                                                                (change)="rangeChanged($event)">
                                                            <input type="text" readonly="true" class="greyinputbox"
                                                                value="20" [(ngModel)]="expense_range">({{ExpensesPer}})%
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-6 float-end">
                                    <div class="gridContainer">
                                        <div class="table-responsive">
                                            <table class="table table-bordered tblGrid fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" rowspan="2" width="180">Deal
                                                            Discount %</th>
                                                        <!--comment-->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>

                                                            <input type="range" class="form-range" id="customRange"
                                                                name="Deal_range" min="0" max="100"
                                                                (change)="rangeChanged($event)">
                                                            <input type="text" readonly="true" class="greyinputbox"
                                                                value="20" [(ngModel)]="Deal_range">({{DealDiscountPer}})%
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 small-gutter mb-4" style="clear:both"></div>

                                <div class="col-6 float-start">
                                    <div class="gridContainer">
                                        <div class="table-responsive">
                                            <table class="table table-bordered tblGrid fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" rowspan="2" width="180">Blended
                                                            Rate
                                                            %</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="d-flex">
                                                            <div class="row w-100">
                                                                <div class="col-10">
                                                                    <input type="range" class="form-range"
                                                                        id="customRange" name="Blended_range1" min="-100"
                                                                        max="100" (change)="rangeChanged($event)">
                                                                    <input type="text" readonly="true"
                                                                        class="greyinputbox" value="20"
                                                                        [(ngModel)]="Blended_range1">({{BlendedRatePer_Ons}})%
                                                                </div>
                                                                <div class="col-2">
                                                                    <span>Onshore</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="d-flex">
                                                            <div class="row w-100">
                                                                <div class="col-10">
                                                                    <input type="range" class="form-range"
                                                                        id="customRange" name="Blended_range2" min="-100"
                                                                        max="100"
                                                                        (change)="rangeChanged($event)">
                                                                    <input type="text" readonly="true"
                                                                        class="greyinputbox" value="20"
                                                                        [(ngModel)]="Blended_range2">({{BlendedRatePer_Off}})%
                                                                </div>
                                                                <div class="col-2">
                                                                    <span>Offshore</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="d-flex">
                                                            <div class="row w-100">
                                                                <div class="col-10">
                                                                    <input type="range" class="form-range"
                                                                        id="customRange" name="Blended_range3" min="-100"
                                                                        max="100"
                                                                        (change)="rangeChanged($event)">
                                                                    <input type="text" readonly="true"
                                                                        class="greyinputbox" value="20"
                                                                        [(ngModel)]="Blended_range3">({{BlendedRatePer_Nea}})%
                                                                </div>
                                                                <div class="col-2">
                                                                    <span>Nearshore</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>




                                <div class="col-6 float-end">
                                    <div class="gridContainer">
                                        <div class="table-responsive">
                                            <table class="table table-bordered tblGrid fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" rowspan="2" width="180">
                                                            Contingency
                                                            %</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="d-flex">
                                                            <div class="row w-100">
                                                                <div class="col-10">
                                                                    <input type="range" class="form-range"
                                                                        id="customRange" name="contigency_range1" min="-100"
                                                                        max="100"
                                                                        (change)="rangeChanged($event)">
                                                                    <input type="text" readonly="true"
                                                                        class="greyinputbox" value="20"
                                                                        [(ngModel)]="contigency_range1">({{ContingencyPer_Ons}})%
                                                                </div>
                                                                <div class="col-2">
                                                                    <span>Onshore</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="d-flex">
                                                            <div class="row w-100">
                                                                <div class="col-10">
                                                                    <input type="range" class="form-range"
                                                                        id="customRange" name="contigency_range2" min="-100"
                                                                        max="100"
                                                                        (change)="rangeChanged($event)">
                                                                    <input type="text" readonly="true"
                                                                        class="greyinputbox" value="20"
                                                                        [(ngModel)]="contigency_range2">({{ContingencyPer_Off}})%
                                                                </div>
                                                                <div class="col-2">
                                                                    <span>Offshore</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="d-flex">
                                                            <div class="row w-100">
                                                                <div class="col-10">
                                                                    <input type="range" class="form-range"
                                                                        id="customRange" name="contigency_range3" 
                                                                        min="-100" max="100"
                                                                        (change)="rangeChanged($event)">
                                                                    <input type="text" readonly="true"
                                                                        class="greyinputbox" value="20"
                                                                        [(ngModel)]="contigency_range3">({{ContingencyPer_Nea}})%
                                                                </div>
                                                                <div class="col-2">
                                                                    <span>Nearshore</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="w-100 small-gutter mb-4"></div>
                            <div class="col-12">

                                <div class="row align-items-center">
                                    <div class="row">
                                        <div class="col-12">

                                            <div class="card bg-white">
                                                <div class="card-header bg-primary text-white">
                                                    <h3>
                                                        <span>{{chartDisplayName
                                                            |
                                                            translate}}</span>
                                                    </h3>
                                                </div>
                                                <div class="card-body overflow-auto">

                                                    <ng-container>
                                                        <app-am-charts-multiple [item_first]="chartsInput"
                                                            [item_second]="chartsInputSecond" [ReportType]="ReportTYPE"
                                                            [ModuleName]="ModuleName" [TabName]="selectedTab"
                                                            [chartPlotName]="chartPlotName">
                                                        </app-am-charts-multiple>
                                                    </ng-container>



                                                </div>
                                            </div>




                                        </div>


                                    </div>

                                </div>

                            </div>
                            <div class="col-12">

                                <div class="row align-items-center">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card bg-white">
                                                <div class="card-header bg-primary text-white">
                                                    <h3>
                                                        <span>{{chartDisplayName |
                                                            translate}}</span>
                                                    </h3>
                                                </div>
                                                <div class="card-body overflow-auto">

                                                    <ng-container>
                                                        <app-am-charts [item]="chartsInputThird"
                                                            [ReportType]="ReportTYPE" [ModuleName]="ModuleName"
                                                            [TabName]="selectedTab" [chartPlotName]="chartPlotName">
                                                        </app-am-charts>
                                                    </ng-container>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-12">

                                <div class="row align-items-center">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card bg-white">
                                                <div
                                                    class="card-header bg-primary text-white">
                                                    <h3>
                                                        <span>{{chartDisplayName |
                                                            translate}}</span>
                                                    </h3>
                                                </div>
                                                <div class="card-body overflow-auto">
                                    
                                                    <ng-container>
                                                        <app-am-charts
                                                            [item]="chartsInputThird"
                                                            [ReportType]="ReportTYPE"
                                                            [ModuleName]="ModuleName"
                                                            [TabName]="selectedTab"
                                                            [chartPlotName]="chartPlotName">
                                                        </app-am-charts>
                                                    </ng-container>
                                    
                                    
                                                </div>
                                            </div>
                                            </div>

                                       
                                    </div>

                                </div>

                            </div> -->


                        </div>
                    </ng-container>




                </ng-container>




            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="ModuleName=='quickdealupload'">

        <div class="card m-2">
            <div class="card-header">
                <h3>
                    <span>Quick Deal Upload</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">

                <div class="row small-gutter mb-4">
                    <div class="col-4">
                        <p><b>Step 1 - </b> Download the excel template and provide your inputs</p>
                        <p><b>Step 2 -</b> Upload the Excel</p>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <ul class="actionList">
                                    <li>
                                        <input type="file" id="file" (change)="handleFileInput($event)">
                                    </li>
                                    <!-- <li>
                                          
                                            <a href="javascript:;"><i class="fa fa-upload" aria-hidden="true"></i>Upload
                                                Excel</a>
                                        </li> -->
                                </ul>
                            </div>
                            <div class="addHolidaypage-btn">
                                <!-- <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url="">
                                        <span>Choose File</span>
                                    </button> -->


                                <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                    data-href="#tabDashboard" data-url="" (click)="uploadItQuickDeal()">
                                    <span>Upload</span>
                                </button>


                                &nbsp;&nbsp;
                                <a type="button" href="../../assets/files/quickdealtemplate.xlsm" download>
                                    <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url="">
                                        <span>Download</span>
                                    </button>

                                </a>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="ModuleName=='DealComparisonUpload'">

        <div class="card m-2">
            <div class="card-header">
                <h3>
                    <span>Deal Comparison Upload</span>
                </h3>
            </div>
            <div class="card-body overflow-auto">

                <div class="row small-gutter mb-4">
                    <div class="col-4">
                        <p><b>Step 1 - </b> Download the excel template and provide your inputs</p>
                        <p><b>Step 2 -</b> Upload the Excel</p>
                    </div>
                    <div class="col-2">

                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <ul class="actionList">
                                    <li>
                                        <input type="file" id="file" (change)="handleFileInput($event)">
                                    </li>
                                    <!-- <li>
                                          
                                            <a href="javascript:;"><i class="fa fa-upload" aria-hidden="true"></i>Upload
                                                Excel</a>
                                        </li> -->
                                </ul>
                            </div>
                            <div class="addHolidaypage-btn">
                                <!-- <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url="">
                                        <span>Choose File</span>
                                    </button> -->


                                <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                    data-href="#tabDashboard" data-url="" (click)="InsertAMDealComparisonUpload()">
                                    <span>Upload</span>
                                </button>


                                &nbsp;&nbsp;
                                <a type="button" href="../../assets/files/pastdealcomparisonupload.xlsm" download>
                                    <button class="btnCustom rounded-pill btnTertiary" data-toggle="tab"
                                        data-href="#tabDashboard" data-url="">
                                        <span>Download</span>
                                    </button>

                                </a>
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </div>

    </ng-container>


    <!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>
</div>
