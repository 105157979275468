import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule,Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AngularDraggableModule } from 'angular2-draggable';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'src/modules/modal/modal.module';

import { LoadingModule } from 'src/modules/loading/loading.module';
import { FilterModule } from 'src/modules/filter/filter.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { FormModule } from 'src/modules/form/form.module';
import { GridReadonlyModule } from 'src/modules/grid-readonly/grid-readonly.module';
import { GridModule } from 'src/modules/grid/grid.module';
import { ExcelExportModule } from 'src/modules/excelexport/excelexport.module';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { GridDisplayControlModule } from 'src/modules/grid-dispctrl/grid-dispctrl.module';
import { SearchByModule } from 'src/modules/searchby/searchby.module';

import { AdminComponent } from './admin.component';
import { AdminService } from './admin.service';
import { UserRoleManageComponent } from './user-role-manage/user-role-manage.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ReportsComponent } from './report/report.component';
import { TabSetModule } from 'src/modules/tabset/tabset.module';
import { RoleWiseMenuAuthComponent } from './role-wise-menu-auth/role-wise-menu-auth.component';


@NgModule({
  declarations: [
    AdminComponent,  UserRoleManageComponent, ActivityLogComponent, ReportsComponent, RoleWiseMenuAuthComponent
  ],
  imports: [
    BrowserModule, FormsModule, NgbModule,
    AlertModule, PaginationModule, TooltipModule,
    ToastrModule, AngularDraggableModule, TranslateModule,
    LoadingModule, FilterModule, CustomPipeModule, FormModule,
    GridReadonlyModule, FilterPipeModule, GridModule, RouterModule, LoadingModule,
    NotificationModule,
    ActionBarModule,
    ExcelExportModule,
    GridDisplayControlModule,
    ModalModule,SearchByModule,TabSetModule
  ],
  providers: [AdminService,Title],
  bootstrap: [AdminComponent]
})
export class AdminModule {
}
