



<!-- <div class="h-100">
  <nav aria-label="breadcrumb" class="breadcrumbSection">
    <div class="d-flex w-100 justify-content-between">
      <h1 class="sr-only">FAQS</h1>
      <ul class="breadcrumb m-0 flex-fill">
        <li class="breadcrumb-item active" aria-current="page">FAQS</li>
      </ul>
    </div>
  </nav>

  <div class="contentScroll p-2">
    <div class="planInfoWraper customForm m-2 bg-white">
      <div class="card-body overflow-auto">
        <ng-container>
          <p class="guidelines p-2">
            <b>FAQ – P2W Self – Service Tool</b>
            <br>
            <br>

            <b><i>1.Can I edit a deal after it is “Completed”? How?</i></b>
            <br>
            Once the output is generated i.e., the deal is “Completed”, you cannot make any further changes. However,
            you can “clone” the deal, make required changes and generate a new output.
            <br>
            <b><i>2. How to get the access to the Online tool?</i></b>
            <br>
            You need to write an email to DL IN CIS BBS: cisbbs.in@capgemini.com to get access.
            <br>
            <b><i>3.Can the output of Online tool be considered as Price to Win?</i></b>
            <br>
            The output of the online tool is a benchmark estimate which is one of the inputs in Price to Win. However,
            this cannot be considered as price to Win.
            <br>

            <b><i>4.What is availability time of the tool? </i></b>
            <br>
            The tool is available 24x7, exceptions in case of any scheduled maintenance.
            <br>
            <b><i>5.Who do we reach out during unexpected downtime of the tool?</i></b>
            <br>
            An incident must be created in the service central for the Global IT team to review.
            Home Page - ServiceCentral (capgemini.com) – Price to win (Service) – Login/Access Issue or P2W Report Not Working or Other Issues (Category) – Add Description.
            <br>
            <b><i>6.How frequently are the unit prices updated?</i></b>
            <br>
            The unit rates are updated half yearly. 
            <br>
            <b><i>7.Which external database subscriptions are considered for pricing?</i></b>
            <br>
            Databases from external advisors like ISG, Wavestone and Everest are considered for pricing. 
            <br>
            <b><i>8.I would like to suggest some improvements, how do I go about it?</i></b>
            <br>
            Please write to us at DL IN CIS BBS: cisbbs.in@capgemini.com 
            <br>
            <b><i>9.How can I share my deal with other users?</i></b>
            <br>
            A deal can only be shared by the users who has created the deal while the deal is “In Progress” state. Once, the output is generated, i.e., the deal is “Completed”, the deal cannot be shared. You can still download the output and share it with your team members, if required.
            <br>
            <b><i>10.I am unable to find the RU prices for a specific country/technology, how should I proceed?</i></b>
            <br>
            Benchmarking of rates can only be done for standard technologies. In case of some specific technologies for which the rates are unavailable, you need to go with the nearest possible match. For country as well you need to select the nearest possible match considering similar Cost of Living. 
            Also, you can drop a note to us at DL IN CIS BBS: cisbbs.in@capgemini.com. We are continuously working on reducing the white spaces and will include it in our future updates, if feasible.
            <br>
            <b><i>11.Do the prices include contribution margin, how much? </i></b>
            <br>
            Yes, all internal RU prices include contribution margin which is ~30% across all deals and towers.
            <br>
            <b><i>12.	What does 25th and 50th percentile mean?</i></b>
            <br>
            25th- There are 25% price points in the market are lower than this price. 50th- 50% of price points in the market are lower than this price. 
            <br>

            <b><i>13.What does the TCV estimate include?  </i></b>
            <br>
            TCV estimate includes Transition (a % of Year 1 CV inc. Gov and SMG), Governance (a % of Total CV) and Service management Charges - SMG (a % of Total CV) based on the values provided in Deal parameters, COLA and FX based on onshore and offshore location, tools and hardware price.
            <br>
            <b><i>14.What does Rate card include?</i></b>
            <br>
            FTE Rates includes Salary, Fringe Benefits, Overhead Human Resources, and Indirect expenses (transportation, seat cost, shift allowance). FOREX rates are as per Exchange rates defined at Capgemini Group level. Cola, FX are factored in for Year 1 FTE rates only and for rest years based on user’s input.
            <br>


            Q2) 	How many approaches are available for deal creation in the system and what are those.
                <br>
                Ans) 	There are 4 approaches available for deal creation in the system namely 1) Top Down Approach	2) Ticket Based Approach 3) User based Approach 4) IT Metrics Approach 
                <br>
                Q3)   How many approaches can be selected for a one single deal
                <br>
                Ans)  For every model created in tool user can select only one approach.
                <br>
                Q4) 	Can we create multiple models for a same deal
                <br>
                Ans) 	Yes
                <br>
                Q5) 	Can we compare between multiple models of same deal
                <br>
                Ans) 	Yes in Model Comparison report
                <br>
                Q6) 	How do I create a new deal
                <br>
                Ans)	Click on Add new deal from my deals page.
                <br>
                Q7) 	How to edit the existing deal
                <br>
                Ans)	You can search your deal from My Deals page and click on edit
                <br>
                Q8) 	Can I see the deals created by other users
                <br>
                Ans) 	No, you cannot see the deals created by others, Others also cannot see the deals created by you, only Admin and Regional Admin has right to see the deals of other users. 
                      The only case where you can see the deal created by others is in case when a user shares the deal with you.
                <br>
                Q9) 	Can I assign the deal to another users. 
                <br>
                Ans) 	Yes
                <br>
                Q10) 	Can I edit the deal that another user has assigned to me. 
                Ans) 	Yes
                <br>
                Q11) 	Can I save the deal with partial information and later complete the deal
                <br>
                Ans) 	Yes, you can save the deal at any point of time and later retrieve the deal data and complete the deal
                <br>
                Q12) 	I don’t find a value in the dropdown list; how can I add a new value in the dropdown 
                <br>
                Ans) 	If any dropdown value is not available - Ex: Client Industry/Capgemini Sectors/Region/SBU/Currency, you can request the Admin, Regional Admin and PEGA Support team to add the same and they would add it to the required master tables.
                <br>
                Q13) 	The standard rates are not available
                <br>
                Ans) 	User needs to connect with SBU Admin and SPOC to get the necessary support. 
                <br>
                Q14) 	What is my user role, who is my regional admin 
                <br>
                Ans) 	User can check the role with the SBU SPOC who is the regional admin for that region
                <br>
                Q15) 	Can I edit the tower after staffing details are saved.
                <br>
                Ans) 	No
                <br>
                Q15) 	Can I change the approach selected initially while creating the new deal
                <br>
                Ans) 	No
                Q16) 	Can we edit Deal configuration details after creating the staff modeling?
                <br>
                Ans) 	No, we can’t edit deal details. 
                <br>
                Q17) 	Can I delete the Lot delivery model after creating the staffing details?
                <br>
                Ans)  No, You can’t delete.
                <br>
                Q18) 	Can I change staffing model after creating the pricing model?
                <br>
                Ans) 	Yes 
                <br>
                Q19) 	Section ‘Capgemini Solution Other’ and ‘Client cost’ are mandatory sections?
                <br>
                Ans) 	No
                <br>
                Q20) 	Can I change Service domain after creating the staffing model?
                <br>
                Ans) 	No
                <br>
                Q21) 	Can I add more than one primary onshore Location in Lot delivery modeling?
                <br>
                Ans) 	No
                <br>
                Q22) 	Is my data saved automatically on clicking the next button?
                <br>
                Ans) 	Yes
                <br>
                Q23)	Replication of existing deal with option to allow change in 'Rate modeling option'
                <br>
                Ans)	Yes, User can change the Rate Modeling option while deal replication.
                <br>
                Q24)	How does P2W pick up rates for a deal  
                <br>
                Ans)	P2W master data retrieves the rate card depending on combination of modelling option selected by user during deal creation (Internal/External) and Service domain chosen during Lot solution modelling.
                <br>
                Q25)	What is the data frequency upload for Capgemini and External rates
                <br>
                Ans)	The P2W team will upload the data based on SBU SPOC’S approval
                <br>
                Q26)	What is the best compatible browser for P2W tool
                <br>
                Ans)	Google Chrome
                <br>
                Q27)	Can we do deal simulation using ADMT output.
                <br>
                Ans)	Yes, using the Deal Simulation-ADMT report
                <br>
                Q28)	For modelling deal using ‘Enhancement’ service domain, can pyramid be manually created
                <br>
                Ans)	No, User needs to select the available models configured in the tool
                <br>
                Q29)	What is the metric used to define deal tenure
                <br>
                Ans)	Deal tenure needs to be entered in years
                <br>
                Q30)	Can we change currency of the deal after it is created
                <br>
                Ans)	No, it cannot be changed as once deal configuration tab is saved no edits can be made to it. 
                <br>
                Q31)	What type of reports are available in P2W 
                <br>
                Ans)	P2W provides multiple options for report generation. Some of the key reports are
                1)	Solution summary report 2) Price Summary View 3) Model Comparison view  
                
                 -->

          <!-- </p>
        </ng-container>
      </div>
    </div>
  </div>
</div> --> 




<br>

<div class="col-10 mx-auto faq-accordion">
	<div class="col-12">
		<h4 class="text-green mb-4">FAQ-P2W Self-Service Tool</h4>
	</div>


  <ng-container *ngFor="let item of faqs; let i = index;">
    <div class="accordion" [id]="'accordion_' + i">
      <div class="accordion-item">
        <h2 class="accordion-header" [id]="'header_' + i">
          <button (click)="accordianclick(i)"
            class="accordion-button collapsed justify-content-between" type="button"
            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse_' + i" aria-expanded="false"
            [attr.aria-controls]="'collapse_' + i">
            <div>
              {{item.Question}}
            </div>
          </button>
        </h2>
        <div [id]="'collapse_' + i" class="accordion-collapse collapse"
          [attr.aria-labelledby]="'header_' + i">
          <div class="accordion-body">
            <div>
              <div class="form-group">
                <br>
                <span>{{item.Answer}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  


  
</div>
