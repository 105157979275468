
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReportModel } from '../app.interface';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { AnyRecordWithTtl } from 'dns';
import { isNullOrUndefined } from '../shared/app.sharedservice';
@Component({
  selector: 'app-am-charts-multiple',
  templateUrl: './am-charts-multiple.component.html',
  styleUrls: ['./am-charts-multiple.component.css']
})
export class AmChartsMultipleComponent implements OnInit {
  @ViewChild('chartElement') chartElement: ElementRef<HTMLElement> | undefined;
  @Input() item_first: any = {};
  @Input() item_second: any = {};
  @Input() chartPlotName: any;
  @Input() ReportType: any;
  @Input() ModuleName: any;
  @Input() TabName: any;
  public Graph_DATA_1_NAME: any;
  public Graph_DATA_2_NAME: any;
  // public graphPlotid: any = '';
  private rootbar!: am5.Root;
  private rootline!: am5.Root;
  private rootbarstacked!: am5.Root;
  private rootbarlinemix!: am5.Root;
  constructor() { }

  ngOnInit(): void {
  }


  ngOnChanges() {

    console.log(this.item_first.ChartOutput);
    console.log(this.item_second.ChartOutput);
    this.Graph_DATA_1_NAME = this.item_first.GraphHeading;
    this.Graph_DATA_2_NAME = this.item_second.GraphHeading;
    // Deal: any = 'AMRT001';
    // Tower: any = 'AMRT002';
    // SubTower: any = 'AMRT003';
    // Country: any ='AMRT004';
    // Lotwise: any ='AMRT005';

    if (this.ModuleName == ReportModel.Business_summary) {
      this.getSeriesGraph1(this.item_first.GraphHeading, JSON.parse(this.item_first.ChartOutput), this.item_first.XFieldName, this.item_first.YFieldName);
      this.getSeriesGraph2(this.item_second.GraphHeading, JSON.parse(this.item_second.ChartOutput), this.item_second.XFieldName, this.item_second.YFieldName);
    }
    else if (this.ModuleName == ReportModel.modelcomparison) {

      this.getBarLineGraphMix(this.item_first.GraphHeading, JSON.parse(this.item_first.ChartOutput), this.item_first.XFieldName, this.item_first.YFieldName);
      this.getSeriesGraph1_mc(this.item_second.GraphHeading, JSON.parse(this.item_second.ChartOutput), this.item_second.XFieldName, this.item_second.YFieldName);

    }
    else if (this.ModuleName == ReportModel.whatifswithmodel) {
      this.getBarstackedGraph(this.item_second.GraphHeading, JSON.parse(this.item_second.ChartOutput), this.item_second.XFieldName, this.item_second.YFieldName);

      this.getcolumnclusteredGraph(this.item_first.GraphHeading, JSON.parse(this.item_first.ChartOutput), this.item_first.XFieldName, this.item_first.YFieldName);
    }

    else if (this.ModuleName == ReportModel.PastDealComparison) {

      this.getBarstackedGraph_pastdealcomparison(this.item_first.GraphHeading, JSON.parse(this.item_first.ChartOutput), this.item_first.XFieldName, this.item_first.YFieldName);
      this.getSeriesGraph1_mc(this.item_second.GraphHeading, JSON.parse(this.item_second.ChartOutput), this.item_second.XFieldName, this.item_second.YFieldName);

    }
    else if (this.ModuleName == ReportModel.whatifswithadmt) {
    //  if (!isNullOrUndefined(this.item_first.ChartOutput)) {
      this.getBarstackedGraph_ADMT(this.item_second.GraphHeading, JSON.parse(this.item_second.ChartOutput), this.item_second.XFieldName, this.item_second.YFieldName);
        this.getcolumnclusteredGraph_ADMT(this.item_first.GraphHeading, JSON.parse(this.item_first.ChartOutput), this.item_first.XFieldName, this.item_first.YFieldName);
     // }

    //  if (!isNullOrUndefined(this.item_second.ChartOutput)) {
       
    //  }

    }


    //   this.getBarGraph(this.item.GraphHeading, JSON.parse(this.item.ChartOutput), this.item.XFieldName, this.item.YFieldName)
  }


  getBarstackedGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.maybeDisposeRoot("chartdiv");


    setTimeout(() => {

      let root: any = null;

      // setTimeout(() => {
      root = am5.Root.new("chartdiv1");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));
      let data = graphData;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {});
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        //     numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });
        series.data.setAll(data);
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootbarstacked = root;


      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });


    }, 1000);


  }

  getBarstackedGraph_pastdealcomparison(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    this.maybeDisposeRoot("chartdiv1");
    this.maybeDisposeRoot("chartdiv");

    setTimeout(() => {

      let root: any = null;

      // setTimeout(() => {
      root = am5.Root.new("chartdiv");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));
      let data = graphData;

      //   let data =[
      //     {
      //         "Model": "M1",
      //         "Onshore": 79092.84,
      //         "Offshore": 105405.11,
      //         "Nearshore": 0
      //     }
      // ]

      //graphData;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {});
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        //     numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });
        series.data.setAll(data);
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootbarstacked = root;


      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });


    }, 1000);


  }
  getBarstackedGraph_ADMT(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    // this.maybeDisposeRoot("chartdiv");
//  this.maybeDisposeRoot("chartdiv1");
  //  this.maybeDisposeRoot("chartdiv");

    setTimeout(() => {

      let root: any = null;

      // setTimeout(() => {
      root = am5.Root.new("chartdiv1");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));
      let data = graphData;
      //      [
      //     {
      //         "Model": "M1",
      //         "Onshore": 79092.84,
      //         "Offshore": 105405.11,
      //         "Nearshore": 0
      //     }
      // ]
      // graphData;
      //   [
      //     {
      //         "Model": "M1",
      //         "Onshore": 79092.84,
      //         "Offshore": 105405.11,
      //         "Nearshore": 0
      //     }
      // ]
      //graphData;
      //   [
      //     {
      //         "Model": "M1",
      //         "Onshore": 317073.3,
      //         "Offshore": 422694.7,
      //         "Nearshore": 0
      //     }
      // ]
      // graphData;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {});
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        // min: 0,
        // max: 100,
        //     numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });
        series.data.setAll(data);
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootbarstacked = root;


      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });


    }, 1000);


  }
  getBarstackedGraph1(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv");


    setTimeout(() => {
      let root: any = null;

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element


      // setTimeout(() => {
      root = am5.Root.new("chartdiv1");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));
      let data = graphData;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {});
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        //  numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });
        series.data.setAll(data);
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootbarstacked = root;


      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });


    }, 1000);

  }

  getSeriesGraph1(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv1");
    this.maybeDisposeRoot("chartdiv");
    setTimeout(() => {


      let root: any = null;
      // setTimeout(() => {
      root = am5.Root.new("chartdiv");

      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,

        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      // data = [
      //   {
      //     "Year": "Year1",
      //     "AS IS Client Spend": 0,
      //     " Capgemini Price": 9.86,
      //     " Savings": -9.86
      //   },
      //   {
      //     "Year": "Year2",
      //     "AS IS Client Spend": 0,
      //     " Capgemini Price": 1.196,
      //     " Savings": -1.196
      //   }
      // ]
      data = graphData;


      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });





      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      // series.data.setAll(data);


      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })
          })
        );



        series.data.setAll(data);

        series.strokes.template.setAll({
          strokeWidth: 3,
          templateField: "strokeSettings"
        });


        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });

      }

      //  createSeries("Europe", "europe");



      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");


      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }


      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
    }, 1000);


  }
  getSeriesGraph2(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    // this.maybeDisposeRoot("chartdiv1");
    // this.maybeDisposeRoot("chartdiv");
    setTimeout(() => {
      let root: any = null;


      // setTimeout(() => {
      root = am5.Root.new("chartdiv1");

      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,

        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      data = graphData;


      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });





      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      // series.data.setAll(data);


      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })

          })
        );



        series.data.setAll(data);

        series.strokes.template.setAll({
          strokeWidth: 3,
          templateField: "strokeSettings"
        });


        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });

      }

      //  createSeries("Europe", "europe");



      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");


      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }


      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });


    }, 1000);

  }
  getSeriesGraph2_pd(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv");
    this.maybeDisposeRoot("chartdiv1");
    setTimeout(() => {
      let root: any = null;


      // setTimeout(() => {
      root = am5.Root.new("chartdiv1");

      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,

        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      data = graphData;


      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });





      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      // series.data.setAll(data);


      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })

          })
        );



        series.data.setAll(data);

        series.strokes.template.setAll({
          strokeWidth: 3,
          templateField: "strokeSettings"
        });


        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });

      }

      //  createSeries("Europe", "europe");



      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");


      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }


      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });


    }, 1000);

  }
  getBarGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    setTimeout(() => {
      this.maybeDisposeRoot("chartdiv");

      // setTimeout(() => {
      let root = am5.Root.new("chartdiv");
      // let root = am5.Root.new(this.GraphName);

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
      }));

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15
      });

      xRenderer.grid.template.setAll({
        location: 1
      })

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));


      // Create series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: Yfield,
        sequencedInterpolation: true,
        categoryXField: Xfield,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      }));

      series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
      series.columns.template.adapters.add("fill", function (fill, target) {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      });

      series.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors")?.getIndex(series.columns.indexOf(target));
      });


      // Set data
      let data = null;
      data = graphData;

      xAxis.data.setAll(data);
      series.data.setAll(data);


      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);
      chart.appear(1000, 100);
      // }, 2000);

    }, 1000);


  }


  getSeriesGraph1_mc(graphName: any, graphData: any, Xfield: any, Yfield: any) {

    this.maybeDisposeRoot("chartdiv");


    setTimeout(() => {


      let root: any = null;
      // setTimeout(() => {
      root = am5.Root.new("chartdiv1");

      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,

        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data: any = null;
      // data = [
      //   {
      //     "Year": "Year1",
      //     "AS IS Client Spend": 0,
      //     " Capgemini Price": 9.86,
      //     " Savings": -9.86
      //   },
      //   {
      //     "Year": "Year2",
      //     "AS IS Client Spend": 0,
      //     " Capgemini Price": 1.196,
      //     " Savings": -1.196
      //   }
      // ]
      data = graphData;


      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0,
          numberFormat: "#'%'",
        })
      );


      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));

      let series: any = null;
      series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: graphName,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: Yfield,
          categoryXField: Xfield,

          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name} in {categoryX}: {valueY} {info}"
          })

        })
      );

      series.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });





      // Add series
      ///  https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      // series.data.setAll(data);


      // Create series
      function createSeries(name: any, field: any) {
        var series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: field,
            categoryXField: Xfield,
            tooltip: am5.Tooltip.new(root, {
              labelText: "{name} in {categoryX}: {valueY} "
            })
          })
        );



        series.data.setAll(data);

        series.strokes.template.setAll({
          strokeWidth: 3,
          templateField: "strokeSettings"
        });


        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              strokeWidth: 3,
              stroke: series.get("stroke"),
              radius: 5,
              fill: root.interfaceColors.get("background")
            })
          });
        });

      }

      //  createSeries("Europe", "europe");



      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");


      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }


      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootline = root;

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
    }, 4000);


  }
  getBarLineGraphMix(graphName: any, graphData: any, Xfield: any, Yfield: any) {

    this.maybeDisposeRoot("chartdiv1");
    setTimeout(() => {


      let root: any = null;
      /* Chart code */

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      root = am5.Root.new("chartdiv");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout
        })
      );

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal"
        })
      );

      // let data = [
      //   {
      //     year: "2016",
      //     income: 23.5,
      //     expenses: 21.1
      //   },
      //   {
      //     year: "2017",
      //     income: 26.2,
      //     expenses: 30.5
      //   },
      //   {
      //     year: "2018",
      //     income: 30.1,
      //     expenses: 34.9
      //   },
      //   {
      //     year: "2019",
      //     income: 29.5,
      //     expenses: 31.1
      //   },
      //   {
      //     year: "2020",
      //     income: 30.6,
      //     expenses: 28.2,
      //     strokeSettings: {
      //       stroke: chart.get("colors")?.getIndex(1),
      //       strokeWidth: 3,
      //       strokeDasharray: [5, 5]
      //     }
      //   },
      //   {
      //     year: "2021",
      //     income: 34.1,
      //     expenses: 32.9,
      //     columnSettings: {
      //       strokeWidth: 1,
      //       strokeDasharray: [5],
      //       fillOpacity: 0.2
      //     },
      //     info: "(projection)"
      //   }
      // ];
      let data: any = null;
      data = graphData;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {});
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: Xfield,
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        })
      );
      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          extraMax: 0.1,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
          })
        })
      );


      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

      // Create series
      function createSeries(name: any, field: any) {


        if (name == 'Total Revenue') {
          let series_C = chart.series.push(
            am5xy.ColumnSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: field,
              categoryXField: Xfield,
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{name} in {categoryX}: {valueY} {info}"
              })
            })
          );

          series_C.columns.template.setAll({
            tooltipY: am5.percent(10),
            templateField: "columnSettings"
          });

          series_C.data.setAll(data);
        } else {
          let series = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: field,
              categoryXField: Xfield,
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "{name} in {categoryX}: {valueY} {info}"
              })
            })
          );


          series.strokes.template.setAll({
            strokeWidth: 3,
            templateField: "strokeSettings"
          });


          series.data.setAll(data);

          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                strokeWidth: 3,
                stroke: series.get("stroke"),
                radius: 5,
                fill: root.interfaceColors.get("background")
              })
            });
          });

        }



      }

      // let temp = [];
      var splitted: any[] = [];
      splitted = Yfield.split(",");

      for (let i = 0; i < splitted.length; i++) {
        createSeries(splitted[i], splitted[i]);
      }

      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );
      legend.data.setAll(chart.series.values);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootbarlinemix = root;

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });

    }, 3000);


  }

  getcolumnclusteredGraph(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv1");
    // this.maybeDisposeRoot("chartdiv1");
    setTimeout(() => {

      let root: any = null;
      /* Chart code */
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      root = am5.Root.new("chartdiv");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );
      console.log(graphData);
      let data = graphData;


      //   [
      //     {
      //         "Model": "M1",
      //         "Onshore": 317073.3,
      //         "Offshore": 422694.7,
      //         "Nearshore": 0,
      //         "OverAll":68789.1
      //     }
      // ]

      //graphData


      //   [
      //     {
      //         "Model": "CHE398715",
      //         "Onshore": 19745.85,
      //         "Nearshore": 0
      //     },
      //     {
      //         "Model": "CHE398716",
      //         "Onshore": 19745.85,
      //         "Nearshore": 0
      //     }
      // ]
      //graphData;





      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      })

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        tooltipText: "{name}, {categoryX}:{valueY}",
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1

        }),
        calculateTotals: true,
      }));
      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });



        // series.columns.template.setAll({
        //   tooltipText: "{name}, {categoryX}:{valueY}",
        //   width: am5.percent(90),
        //   tooltipY: 0,
        //   showTooltipOn: "always"
        // });

        // series.columns.template.setup = function(target: { set: (arg0: string, arg1: any) => void; }) {
        //   target.set("tooltip", am5.Tooltip.new(root, {}));
        // }

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: 0,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }




      // makeSeries("Europe", "europe");
      // makeSeries("North America", "namerica");
      // makeSeries("Asia", "asia");
      // makeSeries("Latin America", "lamerica");
      // makeSeries("Middle East", "meast");
      // makeSeries("Africa", "africa");


      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
    }, 1000);
  }

  getcolumnclusteredGraph_ADMT(graphName: any, graphData: any, Xfield: any, Yfield: any) {
    this.maybeDisposeRoot("chartdiv1");
    // this.maybeDisposeRoot("chartdiv");
    setTimeout(() => {

      let root: any = null;
      /* Chart code */
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      root = am5.Root.new("chartdiv");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );
      console.log(graphData);
      let data = graphData;


      //   [
      //     {
      //         "Model": "M1",
      //         "Onshore": 317073.3,
      //         "Offshore": 422694.7,
      //         "Nearshore": 0,
      //         "OverAll":68789.1
      //     }
      // ]

      //graphData


      //   [
      //     {
      //         "Model": "CHE398715",
      //         "Onshore": 19745.85,
      //         "Nearshore": 0
      //     },
      //     {
      //         "Model": "CHE398716",
      //         "Onshore": 19745.85,
      //         "Nearshore": 0
      //     }
      // ]
      //graphData;





      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      })

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: Xfield,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        tooltipText: "{name}, {categoryX}:{valueY}",
      }));

      xRenderer.grid.template.setAll({
        location: 1
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1

        }),
        calculateTotals: true,
      }));
      var splitted: any[] = [];
      splitted = Yfield.split(" ,");

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: Xfield,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });



        // series.columns.template.setAll({
        //   tooltipText: "{name}, {categoryX}:{valueY}",
        //   width: am5.percent(90),
        //   tooltipY: 0,
        //   showTooltipOn: "always"
        // });

        // series.columns.template.setup = function(target: { set: (arg0: string, arg1: any) => void; }) {
        //   target.set("tooltip", am5.Tooltip.new(root, {}));
        // }

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: 0,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }
      let temp = [];

      temp = splitted[0].split(',');
      // for(let i = 0; i < temp.length; i++) {
      //   temp[i].replace(" - ", "");
      // }

      for (let i = 0; i < temp.length; i++) {
        makeSeries(temp[i], temp[i]);
      }




      // makeSeries("Europe", "europe");
      // makeSeries("North America", "namerica");
      // makeSeries("Asia", "asia");
      // makeSeries("Latin America", "lamerica");
      // makeSeries("Middle East", "meast");
      // makeSeries("Africa", "africa");


      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          deactivateRoot: false
        })
      });
    }, 1000);
  }


  maybeDisposeRoot(divId: any) {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id == divId) {
        root.dispose();
      }
    });
  }

  ngAfterViewInit() {
    // console.log(this.item);
  }
  ngOnDestroy() {
    // Clean up chart when the component is removed
    //  this.browserOnly(() => {
    if (this.rootbar) {
      this.rootbar.dispose();
    }
    if (this.rootline) {
      this.rootline.dispose();
    }
    if (this.rootbarstacked) {
      this.rootbarstacked.dispose();
    }

    if (this.rootbarlinemix) {
      this.rootbarlinemix.dispose();
    }

  }
}
