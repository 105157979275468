
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ADHomeService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
  private HomeURL: string;
  private DealURL: string;
  private serviceURL: string;



  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.HomeURL = environment.apiURL.HomeURL;
    this.DealURL = environment.apiURL.DealURL;
    this.serviceURL=environment.apiURL.ServiceTypeURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  getDealDetailsBySearch(odata: any, allPendingFlag: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
  //  Params = Params.append('DealHeaderId', 1);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'GetDealDetailsBySearch', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDealStatus(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', odata.DealHeaderId);
    Params = Params.append('dealStatus', odata.DealStatus);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'UpdateDealStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateShareDeal(dealHeaderId: any,UserId:any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', dealHeaderId);
    Params = Params.append('UserId', UserId);

    return this._http.get<ProcessResponse<any>>(this.DealURL + 'Sharedeal',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  
  // GetExportedData(dealHeaderId: any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('dealHeaderId', dealHeaderId);
    
  //   return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetExportedData',
  //     { headers: this.headers, params: Params })
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  CloneDeal(id: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('dealHeaderId', odata.DealHeaderId);
  

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'CloneDeal', id,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetUserDetails(id: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();  
    Params = Params.append('DealHeaderId', id);

    let response =  this._http.get<ProcessResponse<any>>(this.DealURL + 'GetUserDetails',
      { headers: this.headers, params: Params });
      return response;   
  }

  GetDealStatusCount(id: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();  
    Params = Params.append('UserId', id);
    //http://localhost:7000/Deal/GetDealStatusCount?UserId=12

    let response =  this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDealStatusCount',
      { headers: this.headers, params: Params });
      return response;
      
  } 

  DownloadExcel(odata:any){
    let Params = new HttpParams();
    
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params ,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetExportedData',
    httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }
}