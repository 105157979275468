import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Add security headers here
    // Check if it's a document request (HTML, CSS, JS, etc.)
//     if (this.isDocumentRequest(request)) {
//  //   if (request.url && request.url.endsWith('.well-known/openid-configuration')) {
//     const modifiedRequest = request.clone({
//       setHeaders: {
//         'X-Frame-Options': 'DENY',
//         'X-XSS-Protection': '1; mode=block',
//         'X-Content-Type-Options': 'nosniff',
//         'Content-Security-Policy': "frame-ancestors 'none'",
//         'X-Permitted-Cross-Domain-Policies': 'none',
//         'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
//         'Referrer-Policy': 'no-referrer',
//         'Permission-Policy': "geolocation 'none';midi 'none';notifications 'none';push 'none';sync-xhr 'none';microphone 'none';camera 'none';magnetometer 'none';gyroscope 'none';speaker 'self';vibrate 'none';fullscreen 'self';payment 'none';",
//         'Feature-Policy': "accelerometer 'none'; camera 'none'; geolocation 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; payment 'none';",
//         // Add more headers as needed
//       }
//     });
//      return next.handle(modifiedRequest);
//  }


 if (this.isDocumentRequest(request)) {
  // Add security headers for document requests
  request = request.clone({
    setHeaders: {
      'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'Content-Security-Policy': "frame-ancestors 'none'",
        'X-Permitted-Cross-Domain-Policies': 'none',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'Referrer-Policy': 'no-referrer',
        'Permission-Policy': "geolocation 'none';midi 'none';notifications 'none';push 'none';sync-xhr 'none';microphone 'none';camera 'none';magnetometer 'none';gyroscope 'none';speaker 'self';vibrate 'none';fullscreen 'self';payment 'none';",
        'Feature-Policy': "accelerometer 'none'; camera 'none'; geolocation 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; payment 'none';",
      // Add more security headers as needed
    }
  });
}
return next.handle(request);
}
    
   




  private isDocumentRequest(request: HttpRequest<any>): boolean {
    const url = request.url.toLowerCase();
    return url.endsWith('.html') || url.endsWith('.css') || url.endsWith('.js');
    // Add more extensions if necessary (e.g., .css, .js)
  }
}
