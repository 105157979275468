import { Component, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from './report.service';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridComponent } from 'src/modules/grid/grid.component';
import { TabItem } from 'src/modules/tabset/tabset.interface';
import { TotalTCVInterface } from './report.interface';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { isPlatformBrowser } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common'
import { transpileModule } from 'typescript';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html'
})

@AutoUnsubscribe
export class ReportComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;
  public Title: any = 'Reports';
  public compTitle: string = 'Report';
  public selectedView: string = 'dashboard';
  public serviceTabs: any[] = [];
  public ReportServiceData: any[] = [];


  public SearchTemplate: FieldTemplate[] = [];


  public PercentileFileteredTemplate_I25th: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E25th: FieldTemplate[] = [];
  public PercentileFileteredTemplate_I50th: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E50th: FieldTemplate[] = [];


  public PercentileFileteredTemplate_I25th_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E25th_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_I50th_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E50th_concat: FieldTemplate[] = [];
  public repListTemplate_unitPriceReport_concat: FieldTemplate[] = [];

  public PercentileFileteredTemplate_I25th_TS: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E25th_TS: FieldTemplate[] = [];
  public PercentileFileteredTemplate_I50th_TS: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E50th_TS: FieldTemplate[] = [];

  public MSTechnologyYearwiseReportInput_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_I25th_TS_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E25th_TS_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_I50th_TS_concat: FieldTemplate[] = [];
  public PercentileFileteredTemplate_E50th_TS_concat: FieldTemplate[] = [];
  public MSTechnologyYearwiseReportInput: FieldTemplate[] = [];
  public selectedTab: string = 'totaltcv';
  public FilterData: any = {};
  public TotaltcvReportData: any[] = [];
  public selectedDealTabs: any[] = [];
  public ServiceTabsNames: any[] = [];
  public TotaltcvGraphData: any;
  public reportExcel: any
  public MSGraphData: any;
  public TowerGraphData: any;
  public TechnologyGraphData: any;
  public YOYGraphData: any;
  public reportTabs: any;
  public GlobalConfig: any = {};
  public DealHeaderId: number = 0;
  public DealLineitemID: number = 0;
  public UserID: string = '';
  public RoleID: string = '';
  public Currency: string = '';
  public configtabs: any = [];
  public istoweryearCollapsed = false;
  public istowerwisesummaryCollapsed = false;
  public istechnologyCollapsed = false;
  public unitPriceCollapsed = false;
  public yoyCollapsed = false;
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public repListTemplate: FieldTemplate[] = [];
  public repListTemplate_unitPriceReport: FieldTemplate[] = [];
  public repListTemplate_unitPriceReport_Input: FieldTemplate[] = [];
  public repListTemplate_MSTechnologyYearwiseReport: FieldTemplate[] = []
  public repListData_TCV: any[] = [];
  public repListData_MS: any[] = [];
  public repListData_MS_Tower_YearWise: any[] = [];
  public repListData_MS_Tower_Summary: any[] = [];
  public repListData_MS_Technology_Summary: any[] = [];
  public repListData_MS_UnitPrice_Year: any[] = [];
  public repListData_MS_YoYReduction: any[] = [];
  public repListData_PC: any[] = [];
  public repListData_RC: any[] = [];
  public repListData_TOOLS: any[] = [];
  public repListData_HARDWARE: any[] = [];
  public repListData: any[] = [];
  public InternalPercentileDropdown: any = {};
  public internalPercentileData: any = "";
  public TowerDropDownData: any;
  public TowerDropdown: any = {};
  public repListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public dataTotalCount_PC: number = 0;
  public dataTotalCount_RC: number = 0;
  public dataTotalCount_Tools: number = 0;
  public dataTotalCount_HW: number = 0;

  public selectedDeal: any;
  public repListData_MS_Technology_Summary_Count: number = 0;
  public repListData_MS_UnitPrice_Year_Count: number = 0;
  public repListData_MS_YoYReduction_Count: number = 0;
  public repListData_MS_Tower_YearWise_Count: number = 0;
  public repListData_MS_Tower_Summary_Count: number = 0;
  public repListData_MS_Count: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };

  public selectedInfoData: any;

  public curItemsPerPage: number = 10;

  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable: false,
    TableCSS: 'auto',
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }


  public GridProp1: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable: false,
    TableCSS: 'auto',


  }
  public Config: any = {
    ActionButtons: []
  };
  // public ReportFormProp: FormProperty = {
  //   'ColSize': 1,
  //   'CaptionBold': true,
  //   'CaptionTop': true,
  //   'IsEditable': true
  // };

  public ReportFormProp: FormProperty = {
    'ColSize': 10,
    'CaptionBold': false,
    // 'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];
  private roottechwise!: am5.Root;
  private roottoweryear!: am5.Root;
  private rootyoy!: am5.Root;
  public searchEvent: any = { 'item': {}, 'action': 'Clear' };
  public ReportObj: any = {};


  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridComponent;



  constructor(public datepipe: DatePipe, private title: Title, private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private reportService: ReportService, private toastr: ToastrService, private modalService: ModalService, @Inject(PLATFORM_ID) private platformId: any, private zone: NgZone) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

    this._route.params.subscribe((params: { [x: string]: any; }) => {
      this.DealHeaderId = params['DealHeaderId'];
      this.DealLineitemID = params['DealLineitemId'];

    });
    console.log(this.DealHeaderId);
    console.log(this.DealLineitemID);

    this.UserID = this._appSharedService.UserRoleList[0].UserId;
    this.RoleID = this._appSharedService.UserRoleList[0].RoleId;
    console.log(this._appSharedService)
    this.selectedDeal = this._appSharedService.selectedDeal;
    this.Currency = this.selectedDeal.CurrencyDesc;
  }



  ngOnInit() {

    this.title.setTitle(this.Title);

    // this.OffShoreProp.forEach((element: any) => {
    //   this.ReportObj = new OffshorePerInterface();
    //   this.ReportObj.OffshoreVolumeDesc = element.value;
    //   this.OffshoreManagedServiceData.push(this.ReportObj)
    // });
    ///for making the drop
    this.InternalPercentileDropdown.IsEditable = 1;
    this.TowerDropdown.IsEditable = 1;

    this._appSharedService.IsRowModified = false;
    this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);


    // this.repListTemplate.forEach((field: any) => {

    //   this.ReportProp.forEach((element: any,index:any) => {
    //     this.ReportObj = new TotalTCVInterface();
    //     this.ReportObj.BenchmarkingEstimaton = element.value;


    //     if (this.ReportObj.BenchmarkingEstimaton === 'Managed Services Year-1 CV') {
    //       this.ReportObj.Internal25thPercentile = field[index][FieldName]Internal25thPercentile;
    //       this.ReportObj.Internal50thPercentile = field.Internal50thPercentile;
    //       this.ReportObj.External25thPercentile = field.External25thPercentile;
    //       this.ReportObj.External50thPercentile = field.External50thPercentile;
    //     }
    //     // else if () {

    //     // }


    //     //this.ReportObj.IsModified = 0;
    //     this.TotaltcvReportData.push(this.ReportObj);

    //   });
    // });

    // this.repListTemplate = JSON.parse(`[
    //     {"FieldName":"TotalTCVDesc","DisplayName":"","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"textbox","InputType":"number","List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"false","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":0,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchResult","ResponsiveMediaSize":"md,lg,xl"},
    //     {"FieldName":"Internal25thPercentile","DisplayName":"Internal 25th Percentile","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"textbox","InputType":"number","List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"false","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":0,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchResult","ResponsiveMediaSize":"md,lg,xl"},
    //     {"FieldName":"Internal50thPercentile","DisplayName":"Internal 50th Percentile","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"textbox","InputType":"number","List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"false","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":0,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchResult","ResponsiveMediaSize":"md,lg,xl"},
    //     {"FieldName":"External25thPercentile","DisplayName":"External 25th Percentile","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"textbox","InputType":"number","List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"false","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":0,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchResult","ResponsiveMediaSize":"md,lg,xl"},
    //     {"FieldName":"External50thPercentile","DisplayName":"External 50th Percentile","LabelTemplate":null,"LinkedField":null,"ColumnWidth":"20","Control":{"Type":"textbox","InputType":"number","List":null,"Source":{"Url":null,"KeyField":null,"ValueField":null,"UrlAttribute":null,"Target":null},"DependentControl":null,"Format":"false","LoadedUrl":null,"DynamicLoad":false},"Cssnames":"","Style":"","Events":"","Editable":0,"IsHidden":false,"ToolTipTemplate":null,"EditValidate":{"Required":false,"Min":null,"Max":null},"FieldCollection":null,"Group":"SearchResult","ResponsiveMediaSize":"md,lg,xl"}
    //     ]`);
    // this.serviceTabs = [
    //   {
    //     "Icon": "",
    //     "Name": "totaltcv",
    //     "DisplayName": "Total TCV",
    //     "SeqOrder": 1,
    //     "IsDefault": false,
    //     "CanShow": true,
    //     "Group": "Reports",
    //     "Active": true,
    //     "HasPermission": true
    //   },
    //   {
    //     "Icon": "",
    //     "Name": "managedservices",
    //     "DisplayName": "Managed Services",
    //     "SeqOrder": 1,
    //     "IsDefault": false,
    //     "CanShow": true,
    //     "Group": "Reports",
    //     "Active": true,
    //     "HasPermission": true
    //   },
    //   {
    //     "Icon": "",
    //     "Name": "ratecard",
    //     "DisplayName": "Rate Card",
    //     "SeqOrder": 2,
    //     "IsDefault": false,
    //     "CanShow": true,
    //     "Group": "Reports",
    //     "HasPermission": true
    //   },
    //   {
    //     "Icon": "",
    //     "Name": "p&c",
    //     "DisplayName": "P&C",
    //     "SeqOrder": 3,
    //     "IsDefault": false,
    //     "CanShow": true,
    //     "Group": "Reports",
    //     "HasPermission": true
    //   }
    // ];


    this.selectedDealTabs = this.selectedDeal.Services.split(',');
    this.ServiceTabsNames = [];
    this.ServiceTabsNames.push('TotalTCV');
    //console.log("this._Config.ServiceTypesTabs")
    //console.log(this._Config.ServiceTypesTabs)


    for (let i = 0; i < this.selectedDealTabs.length; i++) {

      switch (this.selectedDealTabs[i].toUpperCase()) {
        case 'STR001':
          this.ServiceTabsNames.push('ManagedServices');
          break;
        case 'STR003':
          this.ServiceTabsNames.push('RateCard');
          break;
        case 'STR002':
          this.ServiceTabsNames.push('PandC');
          break;
        case 'STR004':
          this.ServiceTabsNames.push('Tool');
          break;
        case 'STR005':
          this.ServiceTabsNames.push('Hardware');
          break;
      }



    }


    // for (let i = 0; i < this.selectedDealTabs.length; i++) {
    //   switch (this.selectedDealTabs[i].toUpperCase()) {
    //     case 'STR001':
    //       this.getManagedServiceReportData();
    //       // this.getManagedServiceGraphData();
    //       break;
    //     case 'STR003':
    //       this.getRateCardReportData();
    //       //  this.getDealDetails('ratecard');
    //       break;
    //     case 'STR002':
    //       this.getPandCReportData();
    //       //   this.getDealDetails('p&c');
    //       break;
    //   }
    // }

    this.getMasterReportTemplate();
    // this.getTCVReportData();
    // this.gettotaltcvGraphData();

    //   this.Config.ActionButtons = JSON.parse(`[
    //     {
    //        "Name":"ExportToExcel",
    //        "Icon":"fa fa-file-excel-o",
    //        "DisplayName":"Dow<u>n</u>load Excel",
    //        "AccessKey":"N",
    //        "IsApplicable":true,
    //        "HasPermission":true,
    //        "IsEnabled":true,
    //        "Confirmation":{
    //           "IsEnabled":false,
    //           "Message":null,
    //           "Action":null
    //        },
    //        "SeqOrder":1
    //     }
    //  ]`);
    //this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    // this.FilterData = {};



  }

  getMasterReportTemplate(): void {
    this.IsLoading = true;

    this.SearchTemplate = [];
    this.repListTemplate = [];
    //  this.repListTemplate_unitPriceReport = [];
    //  this.repListTemplate_MSTechnologyYearwiseReport = [];
    let odata: ComponentConfig = {
      Component: 'Reports', ComponentCode: ComponentCodes.ReportComponent
    };
    const years = this.monthsToYears(this.selectedDeal.DealTerm);
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.repListTemplate = template;
        this.repListTemplate_unitPriceReport = this.repListTemplate.filter(x => x.Group === 'MSYearwiseUnitPriceReport');
        this.repListTemplate_unitPriceReport_Input = this.repListTemplate.filter(x => x.Group === 'MSYearwiseUnitPriceReportInput');
        this.repListTemplate_MSTechnologyYearwiseReport = this.repListTemplate.filter(x => x.Group === 'MSTechnologyYearwiseReport');
        //   this.SearchTemplate = template.filter(x => x.Group === 'SearchFilter');

        this.MSTechnologyYearwiseReportInput = this.repListTemplate.filter(x => x.Group === 'MSTechnologyYearwiseReportInput');
        let temp_repListTemplate_unitPriceReport = this.repListTemplate_unitPriceReport;
        this.MSTechnologyYearwiseReportInput_concat = this.MSTechnologyYearwiseReportInput.concat(this.repListTemplate_MSTechnologyYearwiseReport);

        this.repListTemplate_unitPriceReport_concat = this.repListTemplate_unitPriceReport_Input.concat(this.repListTemplate_unitPriceReport);
        let temp_repListTemplate_MSTechnologyYearwiseReport = this.repListTemplate_MSTechnologyYearwiseReport;
        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'External25Per';

          let fieldData = this.repListTemplate_unitPriceReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }


        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'Internal25Per';

          let fieldData = this.repListTemplate_unitPriceReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }



        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'External50Per';

          let fieldData = this.repListTemplate_unitPriceReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'Internal50Per';

          let fieldData = this.repListTemplate_unitPriceReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }


        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'Internal25Per';

          let fieldData = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'External25Per';

          let fieldData = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }



        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'External50Per';

          let fieldData = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        for (let index = 1; index <= years; index++) {
          let fieldName: string = 'Y' + index.toString() + 'Internal50Per';

          let fieldData = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }



        let fieldData1 = this.repListTemplate_MSTechnologyYearwiseReport
          .filter(x => x.FieldName === 'OverallInternal50Per');

        if (!isNullOrUndefined(fieldData1) && fieldData1.length > 0) {
          fieldData1[0].IsHidden = false;
        }

        let fieldData2 = this.repListTemplate_MSTechnologyYearwiseReport
          .filter(x => x.FieldName === 'OverallExternal25Per');

        if (!isNullOrUndefined(fieldData2) && fieldData2.length > 0) {
          fieldData2[0].IsHidden = false;
        }

        let fieldData3 = this.repListTemplate_MSTechnologyYearwiseReport
          .filter(x => x.FieldName === 'OverallInternal25Per');

        if (!isNullOrUndefined(fieldData3) && fieldData3.length > 0) {
          fieldData3[0].IsHidden = false;
        }
        let fieldData4 = this.repListTemplate_MSTechnologyYearwiseReport
          .filter(x => x.FieldName === 'OverallExternal50Per');

        if (!isNullOrUndefined(fieldData4) && fieldData4.length > 0) {
          fieldData4[0].IsHidden = false;
        }





        console.log("REPORT")
        console.log(this.repListTemplate);

        this.reportExcel = this.Config.ActionButtons.filter((x: any) => x.Group === 'Reports');

        this.setCompProperties(res.Data.Properties!);
        let local: any = [];
        if (!isNullOrUndefined(this.reportTabs) && this.reportTabs.length > 0) {

          for (let i = 0; i < this.ServiceTabsNames.length; i++) {

            local = (this.reportTabs.filter((x: any) => x.Name == this.ServiceTabsNames[i]));
            this.configtabs = [...this.configtabs, local[0]]
            console.log("fsfsfs")
            console.log(this.configtabs)

          }
          this.reportTabs = [];
          this.reportTabs = this.configtabs;
          this.configtabs = [];
          console.log("dgsg")
          console.log(this.reportTabs);

        }

        //this.SearchData(this.searchEvent);

      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getTCVReportData(): void {
    this.IsLoading = true;
    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID
    }
    console.log(odata);
    let subscribe$: Subscription = this.reportService.getTCVReportData(odata).subscribe(res => {

      console.log(res);


      // if(res.Status == ProcessStatus.Success && res.Data==null && res.Message!=''){
      //   this.toastr.warning(res.Message);
      //  }
      //else{
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_TCV = template_Data;
        console.log("REPORT")
        console.log(this.repListData_TCV);
        this.dataTotalCount = this.repListData_TCV.length;

      }
      // }


      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getManagedServiceReportData(): void {
    this.IsLoading = true;
    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID
    }
    let subscribe$: Subscription = this.reportService.getManagedServiceReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_MS = template_Data;
        console.log("REPORT")
        console.log(this.repListData_MS);
        this.repListData_MS_Count = this.repListData_MS.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getRateCardReportData(): void {
    this.IsLoading = true;
    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID
    }
    let subscribe$: Subscription = this.reportService.getRateCardReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_RC = template_Data;
        console.log("REPORT")
        console.log(this.repListData_RC);
        this.dataTotalCount_RC = this.repListData_RC.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }


  getPandCReportData(): void {
    this.IsLoading = true;
    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID
    }

    let subscribe$: Subscription = this.reportService.getPandCReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_PC = template_Data;
        console.log("REPORT")
        console.log(this.repListData_PC);
        this.dataTotalCount_PC = this.repListData_PC.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }



  getToolsData(): void {
    this.IsLoading = true;
    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      //  DealLineitemId: this.DealLineitemID
    }

    let subscribe$: Subscription = this.reportService.GetToolCardReport(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_TOOLS = template_Data;
        console.log("REPORT")
        console.log(this.repListData_TOOLS);
        this.dataTotalCount_Tools = this.repListData_TOOLS.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }


  getHardwareData(): void {
    this.IsLoading = true;
    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      //  DealLineitemId: this.DealLineitemID
    }

    let subscribe$: Subscription = this.reportService.GetHardwareCardReport(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_HARDWARE = template_Data;
        console.log("REPORT")
        console.log(this.repListData_HARDWARE);
        this.dataTotalCount_HW = this.repListData_HARDWARE.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getMSTowerwiseSummaryReportData(): void {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID
    }
    let subscribe$: Subscription = this.reportService.getMSTowerwiseSummaryReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_MS_Tower_Summary = template_Data;
        console.log("REPORT")
        console.log(this.repListData_MS_Tower_Summary);
        this.repListData_MS_Tower_Summary_Count = this.repListData_MS_Tower_Summary.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  getMSServiceTowerYearWiseReportData(): void {
    this.IsLoading = true;


    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: this.internalPercentileData
    }

    let subscribe$: Subscription = this.reportService.getMSServiceTowerYearWiseReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_MS_Tower_YearWise = template_Data;
        console.log("REPORT")
        console.log(this.repListData_MS_Tower_YearWise);
        this.repListData_MS_Tower_YearWise_Count = this.repListData_MS_Tower_YearWise.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }



  getManagedServiceTechnologyYearwiseReportData(): void {
    this.IsLoading = true;

    let internalpdata: any
    if (this.internalPercentileData == null) {
      internalpdata = '';
    }
    else {
      internalpdata = this.internalPercentileData;
    }

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: internalpdata
    }

    const years = this.monthsToYears(this.selectedDeal.DealTerm);
    let subscribe$: Subscription = this.reportService.getManagedServiceTechnologyYearwiseReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {



        let template_Data = res.Data!;
        this.repListData_MS_Technology_Summary = template_Data;
        console.log("REPORT")
        console.log(this.repListData_MS_Technology_Summary);
        this.repListData_MS_Technology_Summary_Count = this.repListData_MS_Technology_Summary.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }


  monthsToYears(months: any) {
    const years = Math.ceil(months / 12);
    return years;
  }

  getManagedServiceUnitPriceReportData(): void {
    this.IsLoading = true;
    let internalpdata: any
    if (this.internalPercentileData == null) {
      internalpdata = '';
    }
    else {
      internalpdata = this.internalPercentileData;
    }

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: internalpdata
    }

    let subscribe$: Subscription = this.reportService.getManagedServiceYearwiseUnitPriceReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {

        this.repListData_MS_UnitPrice_Year = res.Data!;
        console.log("REPORT")
        console.log(this.repListData_MS_UnitPrice_Year);
        this.repListData_MS_UnitPrice_Year_Count = this.repListData_MS_UnitPrice_Year.length;


      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getManagedServiceYoYReductionReportData(): void {
    console.log(this.InternalPercentileDropdown)
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: this.internalPercentileData
    }
    let subscribe$: Subscription = this.reportService.getManagedServiceYoYReductionReportData(odata).subscribe(res => {

      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template_Data = res.Data!;
        this.repListData_MS_YoYReduction = template_Data;
        console.log("REPORT")
        console.log(this.repListData_MS_YoYReduction);
        this.repListData_MS_YoYReduction_Count = this.repListData_MS_YoYReduction.length;
        //   this.YOYchart();

      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }




  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      console.log(this.Config.ActionButtons)

      this.reportTabs = (Properties.filter(x => x.Name.toLowerCase() === 'ReportTabs'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ReportTabs'.toLowerCase())[0].JSONData.toString()) : []);

      console.log(this.reportTabs)
      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    }

  }

  SearchData(event: any, timeout: number): void {

    console.log(event)

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
      (isNullOrUndefined(event.item.LastMonth) || event.item.LastMonth.trim().length == 0)) {

      //  alert("Please select both to proceed search")

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please select last month value to proceed with Search.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null,
        timeout: timeout
      };

      this.displayNotifybox = true;

      return;
    }


    this.searchEvent = event;

    this.IsLoading = true;

    this.repListData = [];
    this.repListDataALL = [];
    this.dataTotalCount = 0;

    this.FilterData = event.item;

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      this.FilterData = {};
      this.IsLoading = false;
      return;
    }

    let subscribe$: Subscription = this.reportService.getMasterReportDetails(this.FilterData).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.repListData = result.Data;
        console.log(this.repListData);
        this.dataTotalCount = this.repListData.length;
        console.log(this.dataTotalCount)
        //  this.dataTotalCount = this.dealListData.length;
        this.repListDataALL = this.repListData;

        this.repListData.forEach((item) => {
          item.Action = '0,1';
          item.IsEditable = 1;
        });

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }


    // reload grid data
    this.SearchData(this.searchEvent, 1000);
  }

  actButtonClick(event: any, ReportData: any, ReportType: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel(ReportData, ReportType);
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  exportToExcel(ReportData: any, ReportType: any) {

    if (ReportData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = ReportType + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      // tempdata.push({ Name: 'Master Data', Data: this.repListData });
      tempdata.push({
        Name: ReportType,
        Data: ReportData,
        ColumnList: (!isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ?
          this.Config.DownloadColumns.map((x: any) => x.FieldName) : null),
        FieldTemplate: this.repListTemplate
      });

      this.excelexport.generateExcelTable(tempdata);

      this.IsLoading = false;
    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.repListData = asEnumerable(this.repListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.repListData.length;
    }
    else {
      this.repListData = this.repListDataALL;
      this.dataTotalCount = this.repListData.length;
    }
  }

  onClick(event: any): void {
    let field = event.item;
    console.log(field);
    switch (event.fieldname.toUpperCase()) {
      // case 'CANDIDATEID':
      // case 'CANDIDATENAME':
      //   this._router.navigate(['app/home/search/case', field.RequestId]);
      //   break;
    }
  }


  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'STOPCASE':
        if (event.result.toUpperCase() === 'YES') {
          // this.requestStopCase();
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }


  onTabChange(selTab: TabItem) {
    console.log(selTab);
    this.selectedTab = selTab.Name;
    //  this.selected.emit({ selectedTab: selTab.Name });
  }

  setSelectedTab(event: any) {
    console.log(event)
    this.selectedTab = event.selectedTab

    switch (this.selectedTab.toUpperCase()) {
      case 'TOTALTCV':
        this.getTCVReportData();
        this.gettotaltcvGraphData();
        break;
      case 'MANAGEDSERVICES':
        this.getManagedServiceReportData();
        this.getManagedServiceGraphData();
        if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
          this.getManagedServiceYoYReductionReportData();
          this.getMSServiceTowerYearWiseReportData();
          this.getTowerWiseGraphData();
          this.getyoyGraphsGraphData();

          // this.getTechnologyWiseGraphsGraphData();
        }

        if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
          if (this.TowerDropDownData != null || this.TowerDropDownData != '' || this.TowerDropDownData != undefined) {
            this.getTechnologyWiseGraphsGraphData();
          }

        }
        //commented//
        // this.getMSServiceTowerYearWiseReportData();
        // this.getMSTowerwiseSummaryReportData();
        // this.getManagedServiceTechnologyYearwiseReportData();
        // this.getManagedServiceUnitPriceReportData();
        // this.getManagedServiceYoYReductionReportData();




        //  this.getTowerWiseGraphData();
        //  this.getyoyGraphsGraphData();


        break;
      case 'RATECARD':
        this.getRateCardReportData();
        break;
      case 'PANDC':
        this.getPandCReportData();
        break;
      case 'TOOL':
        this.getToolsData();
        break;
      case 'HARDWARE':
        this.getHardwareData();
        break;

    }

  }


  onChange(event: any): void {
    let field: any = event.item;
    console.log(event.fieldname);
    let templist: any[] = [];
    //this.PercentileFileteredTemplate=[];
    // Emitting onchange event of resource plan to parent container
    field.IsModified = 1;
    //field.IsRowHighlight = field.IsModified;
    const years = this.monthsToYears(this.selectedDeal.DealTerm);
    switch (event.fieldname.toUpperCase()) {
      case 'PERCENTILE':
        console.log(event);
        this.internalPercentileData = field.Percentile;
        console.log(this.internalPercentileData);
        /////unit price///////

        if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
          if (this.internalPercentileData == 'E25th Percentile') {
            this.PercentileFileteredTemplate_E25th = this.repListTemplate_unitPriceReport.filter(x => x.FieldName.includes('External25Per'));
            this.PercentileFileteredTemplate_E25th_concat = this.repListTemplate_unitPriceReport_Input.concat(this.PercentileFileteredTemplate_E25th);
            console.log(this.PercentileFileteredTemplate_E25th);


          }


          if (this.internalPercentileData == 'E50th Percentile') {

            this.PercentileFileteredTemplate_E50th = this.repListTemplate_unitPriceReport.filter(x => x.FieldName.includes('External50Per'));
            this.PercentileFileteredTemplate_E50th_concat = this.repListTemplate_unitPriceReport_Input.concat(this.PercentileFileteredTemplate_E50th);
            console.log(this.PercentileFileteredTemplate_E50th);

          }

          if (this.internalPercentileData == 'I25th Percentile') {

            this.PercentileFileteredTemplate_I25th = this.repListTemplate_unitPriceReport.filter(x => x.FieldName.includes('Internal25Per'));
            this.PercentileFileteredTemplate_I25th_concat = this.repListTemplate_unitPriceReport_Input.concat(this.PercentileFileteredTemplate_I25th);
            console.log(this.PercentileFileteredTemplate_I25th);

          }


          if (this.internalPercentileData == 'I50th Percentile') {

            this.PercentileFileteredTemplate_I50th = this.repListTemplate_unitPriceReport.filter(x => x.FieldName.includes('Internal50Per'));
            this.PercentileFileteredTemplate_I50th_concat = this.repListTemplate_unitPriceReport_Input.concat(this.PercentileFileteredTemplate_I50th);
            console.log(this.PercentileFileteredTemplate_I50th);

          }


          ////Technology and services//////


          if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
            if (this.internalPercentileData == 'E25th Percentile') {

              this.PercentileFileteredTemplate_E25th_TS = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName.endsWith('External25Per'));
              console.log(this.PercentileFileteredTemplate_E25th);
              this.PercentileFileteredTemplate_E25th_TS_concat = this.MSTechnologyYearwiseReportInput.concat(this.PercentileFileteredTemplate_E25th_TS);
              // this.PercentileFileteredTemplate_E25th_TS.concat(this.MSTechnologyYearwiseReportInput);
              console.log(this.MSTechnologyYearwiseReportInput.concat(this.PercentileFileteredTemplate_E25th_TS))

            }


            if (this.internalPercentileData == 'E50th Percentile') {



              this.PercentileFileteredTemplate_E50th_TS = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName.endsWith('External50Per'));
              this.PercentileFileteredTemplate_E50th_TS_concat = this.MSTechnologyYearwiseReportInput.concat(this.PercentileFileteredTemplate_E50th_TS);
              console.log(this.PercentileFileteredTemplate_E50th);

            }

            if (this.internalPercentileData == 'I25th Percentile') {

              this.PercentileFileteredTemplate_I25th_TS = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName.endsWith('Internal25Per'));
              this.PercentileFileteredTemplate_I25th_TS_concat = this.MSTechnologyYearwiseReportInput.concat(this.PercentileFileteredTemplate_I25th_TS);
              console.log(this.PercentileFileteredTemplate_I25th);

            }


            if (this.internalPercentileData == 'I50th Percentile') {

              this.PercentileFileteredTemplate_I50th_TS = this.repListTemplate_MSTechnologyYearwiseReport.filter(x => x.FieldName.endsWith('Internal50Per'));

              this.PercentileFileteredTemplate_I50th_TS_concat = this.MSTechnologyYearwiseReportInput.concat(this.PercentileFileteredTemplate_I50th_TS);
              console.log(this.PercentileFileteredTemplate_I50th);

            }




          }







          this.getManagedServiceYoYReductionReportData();
          this.getMSServiceTowerYearWiseReportData();
          this.getTowerWiseGraphData();
          this.getyoyGraphsGraphData();
          this.getManagedServiceTechnologyYearwiseReportData();
          this.getManagedServiceUnitPriceReportData();




          // this.getTechnologyWiseGraphsGraphData();
        }


        if (this.internalPercentileData == null || this.internalPercentileData == '') {
          this.getManagedServiceTechnologyYearwiseReportData();
          this.getManagedServiceUnitPriceReportData();
        }
        break;
      case 'TOWER':
        this.TowerDropDownData = field.Tower;

        if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
          if (this.TowerDropDownData != null || this.TowerDropDownData != '' || this.TowerDropDownData != undefined) {
            this.getTechnologyWiseGraphsGraphData();
          }

        }

        break;

    }

    let eventdata = {
      fieldname: event.fieldname
    };

    // this.servicetypsOnChange.emit(eventdata);

  }


  ///////for graphs//////////////////////
  getManagedServiceGraphData(): void {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,

    }
    let subscribe$: Subscription = this.reportService.getMSgraphsData(odata).subscribe(res => {
      console.log("GRAPHS")
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        this.MSGraphData = JSON.parse(res.Data[0].ChartOutput);

        console.log(res.Data[0]);

      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  gettotaltcvGraphData(): void {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,

    }
    let subscribe$: Subscription = this.reportService.getTotaltcvGraphsData(odata).subscribe(res => {
      console.log("GRAPHS")
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        if (res.Data[0].ChartOutput != null) {
          this.TotaltcvGraphData = JSON.parse(res.Data[0].ChartOutput);

        }

        console.log(res.Data[0]);

      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  getTowerWiseGraph() {
    if (this.roottoweryear) {
      this.roottoweryear.dispose();
    }
    setTimeout(() => {
      let root = am5.Root.new("TowerWiseChart");

      root.setThemes([am5themes_Animated.new(root)]);
      let chart: any = null;
      chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          // panY: false,
          layout: root.verticalLayout,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true
        })
      );

      // Define data
      let data = null;
      data = this.TowerGraphData;
      //  console.log(data);

      //   let data = [
      //     {
      //        "Tower":"End User Services- Connected service",
      //        "TowerCount":3.42
      //     }

      //  ]
      //   let data = [
      //     {
      //        "PercentileType":"Internal25Per",
      //        "FinalRate":3.29
      //     },
      //     {
      //        "PercentileType":"Internal50Per",
      //        "FinalRate":3.4
      //     },
      //     {
      //        "PercentileType":"External25Per",
      //        "FinalRate":1.0
      //     },
      //     {
      //        "PercentileType":"External50Per",
      //        "FinalRate":1.3
      //     }
      //  ]
      console.log("Tower DATA")
      console.log(data)

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          "extraMin": 0.15,
          "extraMax": 1.0
        })
      );
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40
      });
      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          renderer: xRenderer,
          categoryField: 'Tower',
          tooltip: am5.Tooltip.new(root, {})
        })
      );

      xRenderer.labels.template.setAll({
        oversizedBehavior: "wrap",
        textAlign: "center"
      });

      // Set up automatic width calculation using an adapter
      xRenderer.labels.template.adapters.add("width", function (width, target) {
        let x0 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 0);
        let x1 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 1);
        target.set("maxWidth", x1 - x0)
        return x1 - x0;
      });
      xAxis.data.setAll(data);

      // Create series
      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Tower",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "TowerCount",
          categoryXField: "Tower"
        })
      );


      series1.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });
      series1.data.setAll(data);

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });


      // let series2 = chart.series.push(
      //   am5xy.ColumnSeries.new(root, {
      //     name: "Series",
      //     xAxis: xAxis,
      //     yAxis: yAxis,
      //     valueYField: "value2",
      //     categoryXField: "category"
      //   })
      // );
      // series2.data.setAll(data);

      // Add legend
      let legend = chart.children.push(am5.Legend.new(root, {}));
      legend.data.setAll(chart.series.values);

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      this.roottoweryear = root;

    }, 1000);
  }
  getTowerWiseGraphData(): void {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: this.internalPercentileData

    }
    let subscribe$: Subscription = this.reportService.getTowerWiseGraphsData(odata).subscribe(res => {
      console.log("GRAPHS")
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        this.TowerGraphData = JSON.parse(res.Data[0].ChartOutput);
        console.log("Tower graph DATA")
        console.log(this.TowerGraphData);

        if (this.TowerGraphData != null || this.TowerGraphData != undefined || this.TowerGraphData != '') {

          if (this.roottoweryear) {
            this.roottoweryear.dispose();
          }
          this.IsLoading = true;
          this.getTowerWiseGraph();
          this.IsLoading = false;
        }
        // useEffect(() => {


        //   return () => {  this.root.dispose();  };
        //   })
      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  getTechnologyGraph() {
    if (this.roottechwise) {
      this.roottechwise.dispose();
    }
    setTimeout(() => {
      let root: any = null;
      root = am5.Root.new("TechnologiesWiseChart");

      root.setThemes([am5themes_Animated.new(root)]);
      let chart = null;
      chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          // panY: false,
          layout: root.verticalLayout,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true
        })
      );

      // Define data
      let data = null;
      data = this.TechnologyGraphData;
      //  console.log(data);

      //   let data = [
      //     {
      //        "Tower":"End User Services- Connected service",
      //        "TowerCount":3.42
      //     }

      //  ]
      //   let data = [
      //     {
      //        "PercentileType":"Internal25Per",
      //        "FinalRate":3.29
      //     },
      //     {
      //        "PercentileType":"Internal50Per",
      //        "FinalRate":3.4
      //     },
      //     {
      //        "PercentileType":"External25Per",
      //        "FinalRate":1.0
      //     },
      //     {
      //        "PercentileType":"External50Per",
      //        "FinalRate":1.3
      //     }
      //  ]
      console.log("Tech DATA")
      console.log(data)

      // Create Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          "extraMin": 0.15,
          "extraMax": 1.0
        })
      );
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40
      });
      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: xRenderer,
          categoryField: 'Technology',
          tooltip: am5.Tooltip.new(root, {})
        })
      );


      xRenderer.labels.template.setAll({
        oversizedBehavior: "wrap",
        textAlign: "center"
      });

      // Set up automatic width calculation using an adapter
      xRenderer.labels.template.adapters.add("width", function (width, target) {
        let x0 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 0);
        let x1 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 1);
        target.set("maxWidth", x1 - x0)
        return x1 - x0;
      });
      xAxis.data.setAll(data);

      // Create series
      let series1 = null
      series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Technology",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "FinalRate",
          categoryXField: "Technology"
        })
      );

      series1.columns.template.setAll({
        tooltipText: "{name}, {categoryX}: {valueY}",
        tooltipY: am5.percent(10)
      });
      series1.data.setAll(data);

      // let series2 = chart.series.push(
      //   am5xy.ColumnSeries.new(root, {
      //     name: "Series",
      //     xAxis: xAxis,
      //     yAxis: yAxis,
      //     valueYField: "value2",
      //     categoryXField: "category"
      //   })
      // );
      // series2.data.setAll(data);

      // Add legend

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      let legend = chart.children.push(am5.Legend.new(root, {}));
      legend.data.setAll(chart.series.values);

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      this.roottechwise = root;

    }, 1000);
  }
  getTechnologyWiseGraphsGraphData(): void {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: this.internalPercentileData,
      Tower: this.TowerDropDownData

    }
    let subscribe$: Subscription = this.reportService.getTechnologyWiseGraphsData(odata).subscribe(res => {
      console.log("GRAPHS")
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        this.TechnologyGraphData = JSON.parse(res.Data[0].ChartOutput);

        console.log(res.Data[0]);


        if (this.TechnologyGraphData != null || this.TechnologyGraphData != undefined || this.TechnologyGraphData != '') {
          if (this.roottechwise) {
            this.roottechwise.dispose();
          }

          this.IsLoading = true;
          this.getTechnologyGraph();
          this.IsLoading = false;

        }





      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  getyoyGraphsGraphData(): void {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
      UserId: this.UserID,
      RoleId: this.RoleID,
      DealLineitemId: this.DealLineitemID,
      PercentileType: this.internalPercentileData,


    }
    let subscribe$: Subscription = this.reportService.getYOYGraphsData(odata).subscribe(res => {
      console.log("GRAPHS")
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        if (!isNullOrUndefined(res.Data[0].ChartOutput)) {
          this.YOYGraphData = JSON.parse(res.Data[0].ChartOutput);

          console.log(res.Data[0]);
        }
        console.log("yoy charts");
        console.log(this.YOYGraphData);
        if (this.YOYGraphData != null || this.YOYGraphData != undefined) {
          if (this.rootyoy) {
            this.rootyoy.dispose();
          }
          this.IsLoading = true;
          this.YOYchart();
          this.IsLoading = false;
        }





      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }



  ngOnDestroy() {
    // Clean up chart when the component is removed
    //  this.browserOnly(() => {
    if (this.roottechwise) {
      this.roottechwise.dispose();
    }
    if (this.roottoweryear) {
      this.roottoweryear.dispose();
    }
    if (this.rootyoy) {
      this.rootyoy.dispose();
    }
    //  });
  }



  goBack() {
    window.history.back();
    // this.location.back();

    console.log('goBack()...');
  }


  navigatetoDealPage() {
    this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { dealData: this.selectedDeal } });
  }

  navigatetoservicepage() {
    this._router.navigate([`app/servicetyps/${this.DealHeaderId}`]);
  }


  /////yoy chart///
  YOYchart() {
    if (this.rootyoy) {
      this.rootyoy.dispose();
    }
    setTimeout(() => {
      let root: any = null;
      root = am5.Root.new("yoychart");


      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/

      let chart: any = null;
      chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));

      let data: any = null;
      data = this.YOYGraphData;
      console.log(this.YOYGraphData)

      // let data = [
      //   {
      //     "Year": "Y1",
      //     "Run": 341.92,
      //     "Transition": 53.85,
      //     "Governance": 27.35,
      //     "ServiceManagement": 17.1,
      //     "Total": 440.22
      //   },
      //   {
      //     "Year": "Y2",
      //     "Run": 485.92,
      //     "Transition": 34.50,
      //     "Governance": 25.00,
      //     "ServiceManagement": 15.8,
      //     "Total":489.22
      //   },
      //   {
      //     "Year": "Y3",
      //     "Run": 200.92,
      //     "Transition": 25,
      //     "Governance": 22,
      //     "ServiceManagement": 10.1,
      //     "Total": 300
      //   }
      // ]

      // let data = [{
      //   "year": "2021",
      //   "europe": 2.5,
      //   "namerica": 2.5,
      //   "asia": 2.1,
      //   "lamerica": 1,
      //   "meast": 0.8,
      //   "africa": 0.4,
      //   "expenses": 20.5
      // }, {
      //   "year": "2022",
      //   "europe": 2.6,
      //   "namerica": 2.7,
      //   "asia": 2.2,
      //   "lamerica": 0.5,
      //   "meast": 0.4,
      //   "africa": 0.3,
      //   "expenses": 25.5
      // }, {
      //   "year": "2023",
      //   "europe": 2.8,
      //   "namerica": 2.9,
      //   "asia": 2.4,
      //   "lamerica": 0.3,
      //   "meast": 0.9,
      //   "africa": 0.5,
      //   "expenses": 30.5
      // }]


      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "Year",
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));


      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "Year"
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(10)
        });
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }

      makeSeries("Run", "Run");
      makeSeries("Transition", "Transition");
      makeSeries("Governance", "Governance");
      makeSeries("ServiceManagement", "ServiceManagement");
      // makeSeries("Middle East", "meast");
      // makeSeries("Africa", "africa");

      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Total",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Total",
          categoryXField: "Year",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name} in {categoryX}: {valueY}"
          })
        })
      );

      series2.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      series2.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });



      // var title = chart.titles.create();
      // title.text = "Total, $M";
      // title.fontSize = 25;
      // title.tooltipText = "{name}, {categoryX}: {valueY}";
      series2.data.setAll(data);


      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            stroke: series2.get("stroke"),
            radius: 5,
            fill: root.interfaceColors.get("background")
          })
        });
      });
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);

      this.rootyoy = root;


    })
  }


  /////yoy column////
  // YOYchart() {
  //   if (this.roottechwise) {
  //     this.roottechwise.dispose();
  //   }
  //   setTimeout(() => {
  //     let root:any = null
  //     root = am5.Root.new("yoychart");

  //     // Set themes
  //     // https://www.amcharts.com/docs/v5/concepts/themes/
  //     root.setThemes([am5themes_Animated.new(root)]);

  //     // Create chart
  //     // https://www.amcharts.com/docs/v5/charts/xy-chart/
  //     let chart = root.container.children.push(
  //       am5xy.XYChart.new(root, {
  //         panX: false,
  //         panY: false,
  //         wheelX: "panX",
  //         wheelY: "zoomX",
  //         layout: root.verticalLayout
  //       })
  //     );

  //     // Add scrollbar
  //     // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
  //     chart.set(
  //       "scrollbarX",
  //       am5.Scrollbar.new(root, {
  //         orientation: "horizontal"
  //       })
  //     );

  //     let data = [
  //       {
  //         year: "2016",
  //         income: 23.5,
  //         expenses: 21.1
  //       },
  //       {
  //         year: "2017",
  //         income: 26.2,
  //         expenses: 30.5
  //       },
  //       {
  //         year: "2018",
  //         income: 30.1,
  //         expenses: 34.9
  //       },
  //       {
  //         year: "2019",
  //         income: 29.5,
  //         expenses: 31.1
  //       },
  //       {
  //         year: "2020",
  //         income: 30.6,
  //         expenses: 28.2,
  //         strokeSettings: {
  //           stroke: am5.color(0x000000),
  //           strokeWidth: 3,
  //           strokeDasharray: [5, 5]
  //         }
  //       },
  //       {
  //         year: "2021",
  //         income: 34.1,
  //         expenses: 32.9,
  //         columnSettings: {
  //           strokeWidth: 1,
  //           strokeDasharray: [5],
  //           fillOpacity: 0.2
  //         },
  //         info: "(projection)"
  //       }
  //     ];

  //     // Create axes
  //     // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  //     let xAxis = chart.xAxes.push(
  //       am5xy.CategoryAxis.new(root, {
  //         categoryField: "year",
  //         renderer: am5xy.AxisRendererX.new(root, {}),
  //         tooltip: am5.Tooltip.new(root, {})
  //       })
  //     );

  //     xAxis.data.setAll(data);

  //     let yAxis = chart.yAxes.push(
  //       am5xy.ValueAxis.new(root, {
  //         min: 0,
  //         extraMax: 0.1,
  //         renderer: am5xy.AxisRendererY.new(root, {})
  //       })
  //     );


  //     // Add series
  //     // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

  //     let series1 = chart.series.push(
  //       am5xy.ColumnSeries.new(root, {
  //         name: "Income",
  //         xAxis: xAxis,
  //         yAxis: yAxis,
  //         valueYField: "income",
  //         categoryXField: "year",
  //         tooltip: am5.Tooltip.new(root, {
  //           pointerOrientation: "horizontal",
  //           labelText: "{name} in {categoryX}: {valueY} {info}"
  //         })
  //       })
  //     );

  //     series1.columns.template.setAll({
  //       tooltipY: am5.percent(10),
  //       templateField: "columnSettings"
  //     });

  //     series1.data.setAll(data);

  //     let series2 = chart.series.push(
  //       am5xy.LineSeries.new(root, {
  //         name: "Expenses",
  //         xAxis: xAxis,
  //         yAxis: yAxis,
  //         valueYField: "expenses",
  //         categoryXField: "year",
  //         tooltip: am5.Tooltip.new(root, {
  //           pointerOrientation: "horizontal",
  //           labelText: "{name} in {categoryX}: {valueY} {info}"
  //         })
  //       })
  //     );

  //     series2.strokes.template.setAll({
  //       strokeWidth: 3,
  //       templateField: "strokeSettings"
  //     });


  //     series2.data.setAll(data);

  //     series2.bullets.push(function () {
  //       return am5.Bullet.new(root, {
  //         sprite: am5.Circle.new(root, {
  //           strokeWidth: 3,
  //           stroke: series2.get("stroke"),
  //           radius: 5,
  //           fill: root.interfaceColors.get("background")
  //         })
  //       });
  //     });

  //     chart.set("cursor", am5xy.XYCursor.new(root, {}));

  //     // Add legend

  //     let legend = chart.children.push(
  //       am5.Legend.new(root, {
  //         centerX: am5.p50,
  //         x: am5.p50
  //       })
  //     );
  //     legend.data.setAll(chart.series.values);

  //     // Make stuff animate on load
  //     // https://www.amcharts.com/docs/v5/concepts/animations/
  //     chart.appear(1000, 100);
  //     series1.appear();



  //   })
  // }
  /* Chart code */
  // Create root element
  // https://www.amcharts.com/docs/v5/getting-started/#Root_element
  accordianclick(event: any) {
    console.log(event);
    switch (event.toUpperCase()) {
      case 'ISTOWERYEARCOLLAPSED':
        this.istoweryearCollapsed = !this.istoweryearCollapsed;
        console.log(this.istoweryearCollapsed);
        if (this.istoweryearCollapsed == true) {
          this.getMSServiceTowerYearWiseReportData();
          if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
            this.getTowerWiseGraphData();
            this.getyoyGraphsGraphData();
          }

          if (this.internalPercentileData != null && this.internalPercentileData != '' && this.internalPercentileData != undefined) {
            if (this.TowerDropDownData != null || this.TowerDropDownData != '' || this.TowerDropDownData != undefined) {
              this.getTechnologyWiseGraphsGraphData();
            }

          }
        }

        break;
      case 'ISTOWERWISESUMMARYCOLLAPSED':
        this.istowerwisesummaryCollapsed = !this.istowerwisesummaryCollapsed;
        this.getMSTowerwiseSummaryReportData();
        break;

      case 'ISTECHNOLOGYCOLLAPSED':
        this.istechnologyCollapsed = !this.istechnologyCollapsed;
        this.getManagedServiceTechnologyYearwiseReportData();
        break;

      case 'UNITPRICECOLLAPSED':
        this.unitPriceCollapsed = !this.unitPriceCollapsed;
        this.getManagedServiceUnitPriceReportData();

        break;

      case 'YOYCOLLAPSED':
        this.yoyCollapsed = !this.yoyCollapsed;
        this.getManagedServiceYoYReductionReportData();
        break;
    }
  }



  DownloadExcel() {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.DealHeaderId,
    }
    let subscribe$: Subscription = this.reportService.DownloadExcel(odata).subscribe(res => {
      let date: any = new Date();
      let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
      console.log('TCV' + '_' + this.selectedDeal.DealId + '_' + latest_date)
      this.downloadFile(res, 'TCV' + '_' + this.selectedDeal.DealId + '_' + latest_date);


      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }




  // downloadFile(data: Blob) {
  //   const contentType = 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet';
  //   const blob = new Blob([data], { type: contentType });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
  public downloadFile(response: any, fileName: string) {
    fileName = fileName || response.headers.get('content-disposition').split(';')[0];
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');
    // const blob = new Blob([response.body], { type: response.headers.get('content-type') });
    // fileName = fileName || response.headers.get('content-disposition').split(';')[0];
    // const file = new File([blob], fileName, { type: response.headers.get('content-type') });
    // FileSaver.saveAs(file);
  }
}

