import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProcessResponse, ProcessStatus } from '../app.interface';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr'

@Injectable()
export class ServicetypsService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
  private serviceTypeURL: string;
  private DealURL: string;

  constructor(private _http: HttpClient, private toastr: ToastrService) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.serviceTypeURL = environment.apiURL.ServiceTypeURL;
    this.DealURL = environment.apiURL.DealURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  // async getData(reqdata: any): Promise<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('ServiceType', 'STR001');
  //   Params = Params.append('DealHeaderId', 0);
  //   Params = Params.append('DealLIneitemId', 0);

  //   let result = await this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServiceDetails',
  //     { headers: this.headers, params: Params }).toPromise()
  //     .catch(this.handleError);

  //   return result;

  // }

  getData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('ServiceType', odata.ServiceType);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealLIneitemId', odata.DealLIneitemId);

    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServiceDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  getDataTools(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('ServiceType', odata.ServiceType);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealLIneitemId', odata.DealLIneitemId);

    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetToolServiceDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetSplunkModuleToolData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);

    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetSplunkModuleToolData',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  copyrow(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();    

    return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'CopyRowDataServices',odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  

  saveData(odata: any): Observable<ProcessResponse<any>> {

    return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'InsertOrUpdateServiceTypeDetails', odata)
      .pipe(
        catchError(this.handleError)
      );
  }


  // saveData(odata: any): Observable<any> {
  //   return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'InsertOrUpdateServiceTypeDetails', odata)
  //     .pipe(
  //       catchError(this.handleError),
  //       map((result: ProcessResponse<any>) => {
  //         if (result.Status === ProcessStatus.Success && result.Data === null && result.Message !== '') {
  //           this.toastr.warning(result.Message);
  //           return null; // Returning null when conditions for warning are met
  //         } else {
  //           // Handle other cases when the conditions are not met
  //           // For example, returning the result data
  //           return result;
  //         }
  //       })
  //     );
  // }

  openOutputScreen(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'InsertSupportTeamDetails', { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  saveOffshoreData(odata: any): Observable<ProcessResponse<any>> {

    return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'InsertOrUpdateSTVolumeDetails', odata)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveProductivityInflation(odata: any): Observable<ProcessResponse<any>> {

    return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'InsertOrUpdateSTProductivityInflationDetails', odata)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveFTECount(odata: any): Observable<ProcessResponse<any>> {

    return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'InsertOrUpdateFTECount', odata)
      .pipe(
        catchError(this.handleError)
      );
  }
  

  getDealData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('ServiceType', odata.ServiceType);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    // Params = Params.append('DealLIneitemId', 0);

    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServiceTypeDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  


  deleteServiceData(odata: any): Observable<ProcessResponse<any>> {
    console.log(odata);
    let Params = new HttpParams();
    // Params = Params.append('TransactionId', odata.TransactionId);
    // Params = Params.append('UserId', odata.UserId);
    // Params = Params.append('DealLIneitemId', 0);

    return this._http.delete<ProcessResponse<any>>(this.serviceTypeURL + 'DeleteOrInactiveServiceType',
      { headers: this.headers, params: Params, body: odata })
      .pipe(
        catchError(this.handleError)
      );
  }

  getServiceTypeVolumeDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('STTransactionId', odata.STTransactionId);
    Params = Params.append('DealHeaderId',  odata.DealHeaderId);
    Params = Params.append('DealLIneitemId', odata.DealLIneitemId);
    // Params = Params.append('DealLIneitemId', 0);
    //STTransactionId?=1&&DealHeaderId=542&&DealLIneitemId=1
    
    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServiceTypeVolumeDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  
  GetServiceTypeProductivityInflationDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('STTransactionId', odata.STTransactionId);
    Params = Params.append('DealHeaderId',  odata.DealHeaderId);
    Params = Params.append('DealLIneitemId', odata.DealLIneitemId);
    // Params = Params.append('DealLIneitemId', 0);
    //STTransactionId?=1&&DealHeaderId=542&&DealLIneitemId=1
    
    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServiceTypeProductivityInflationDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetServiceTypeFTECountDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('STTransactionId', odata.STTransactionId);
    Params = Params.append('DealHeaderId',  odata.DealHeaderId);
    Params = Params.append('DealLIneitemId', odata.DealLIneitemId);
    // Params = Params.append('DealLIneitemId', 0);
    //STTransactionId?=1&&DealHeaderId=542&&DealLIneitemId=1
    
    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServiceTypeFTECountDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  updateDealStatus(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', odata.DealHeaderId);
    Params = Params.append('dealStatus', odata.DealStatus);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'UpdateDealStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  // saveManagedServiceData(odata:any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('ServiceType', 'STR003');
  //   Params = Params.append('DealHeaderId', 0);
  //   Params = Params.append('DealLIneitemId', 0);
  //   return this._http.post<ProcessResponse<any>>(this.serviceTypeURL + 'InsertOrUpdateServiceTypeDetails', odata, { headers: this.headers, params: Params })
  //   .pipe(
  //     catchError(this.handleError)
  //   );
  // }


   ///////get output progress count/////
   getOutputProgressStatusData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);

    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetCalculationsCount',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  GetAutopopulateData(odata:any){
    let Params = new HttpParams();
    Params = Params.append('Tower', odata.Tower);
    Params = Params.append('Offerings', odata.Offerings);
    Params = Params.append('Technologies', odata.Technologies);
    Params = Params.append('Complexity', odata.Complexity);
    Params = Params.append('PricingUnit', odata.PricingUnit);
    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetAutopopulateData',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  GetServicesTCVReport(odata:any){
    let Params = new HttpParams();
    Params = Params.append('RoleId', odata.RoleId);
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('UserId', odata.UserId);
    Params = Params.append('DealLineItemId', odata.DealLineItemId);
    Params = Params.append('ServiceType', odata.ServiceType);
    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetServicesTCVReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      ); 
  }


  
  GetCISDealStatus(odata:any){
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
  

    return this._http.get<ProcessResponse<any>>(this.serviceTypeURL + 'GetCISDealStatus',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      ); 
  }

}
