<ng-container *ngIf="DealSubLineItemId > 0">

   
    <p class="mb-1">
        <span>Please click on Region to enter staffing details</span>
    </p>

    
    <div class="col-12">
        <span class="text-bold">Lot Staffing Model Resource (Lot: {{LotName}})</span>
        <a href="javascript:void(0);" (click)="HelpIconClick()" class="helpIconTooltip">
            <i class="fa fa-question-circle fa-ques fa-lg" data-toggle="tooltip"></i>
        </a>
    </div>

    

    <ng-container
        *ngIf="(StaffingRegionTemplate != null && StaffingRegionTemplate != undefined && StaffingRegionTemplate.length > 0)">
        <grid-dispctrl #StaffingRegionGrid [FieldTemplate]="StaffingRegionTemplate" [GridData]="StaffingRegionData"
            [GridProp]="GridProp" [GridDataTotalCount]="StaffingRegionData_DataCount" (FieldClick)="onClick($event)"
            [IsPagination]="IsPagination"   (FieldChange)="onChange($event)">
        </grid-dispctrl>
    </ng-container>

    <br>

    <ng-container
        *ngIf="(GovernancePercentageTemplate != null && GovernancePercentageTemplate != undefined && GovernancePercentageTemplate.length > 0)">

        <div class="mb-1">
            <div tabindex="-1" class="col-12">
                <label for="Oprationallevel">Operational level Governance Percentage</label>
            </div>
        </div>

        <grid-dispctrl #StaffingRegionGrid [FieldTemplate]="GovernancePercentageTemplate"
            [GridData]="GovernancePercentagenData" [GridProp]="GridProp"
            [GridDataTotalCount]="GovernancePercentagenData_DataCount" (FieldClick)="onClick($event)"
            [IsPagination]="IsPagination"  (FieldChange)="onChange($event)">
        </grid-dispctrl>
    </ng-container>

    <br>

    <ng-container
        *ngIf="(GradeAllocationTemplate != null && GradeAllocationTemplate != undefined && GradeAllocationTemplate.length > 0)">

        <div class="mb-1">
            <div class="col-12">
                <label for="gradeallocation">Grade Allocation for Governance</label>
            </div>
        </div>

        <grid-dispctrl #StaffingRegionGrid [FieldTemplate]="GradeAllocationTemplate" [GridData]="GradeAllocationData"
            [GridProp]="GridProp" [GridDataTotalCount]="GradeAllocationData_DataCount" (FieldClick)="onClick($event)"
            [IsPagination]="IsPagination"   (FieldChange)="onChange($event)">
        </grid-dispctrl>
    </ng-container>

    <br>

    <ng-container *ngIf="(PyramidTemplate != null && PyramidTemplate != undefined && PyramidTemplate.length > 0)">
        
        <div class="col-12">
            <span class="text-bold">Resource Pyramid % - Delivered From :
                {{DeliveredFromRegionDesc}} - {{DeliveredFromCountryDesc}} |
                Delivered To : {{DeliveredToRegionDesc}} - {{DeliveredToCountryDesc}} (Lot:
                {{LotName}})</span>
            <a href="javascript:void(0);" (click)="ResourcePyramidHelpIconClick()" class="helpIconTooltip">
                <i class="fa fa-question-circle fa-ques fa-lg" data-toggle="tooltip"></i>
            </a>
        </div>

        <grid-dispctrl #StaffingPyramidGrid [FieldTemplate]="PyramidTemplate" [GridData]="PyramidData"
            [GridProp]="PyramidGridProp" [GridDataTotalCount]="PyramidData_DataCount" (FieldClick)="onClick($event)"
            [IsPagination]="IsPagination" [cellLock]="setCellLock" [parent]="this"  [GridId]="'Fourth_locationgrid'" (FieldChange)="onChange($event)">
        </grid-dispctrl>


        <!-- <table class="table table-hover table-bordered tblGrid fixed mb-4" style="min-width: 100%; table-layout:fixed">
            <tbody>
                <tr>
                    <td rowspan="1"></td>
                    <td rowspan="1"></td>
                    <td rowspan="1" style="width:12%"></td>
                    <td rowspan="1"></td>
                    <td rowspan="1"></td>
                    <td rowspan="1"></td>
                    <td rowspan="1"></td>
                    <td style="text-align: center" *ngFor="let el of pyramidCustom;" rowspan="1">
                        {{el.value}}<span> %</span></td>
                </tr>
            </tbody>
        </table> -->



        <!-- <table class="table table-hover table-bordered tblGrid fixed mb-4"
        style="min-width: 100%; table-layout:fixed">
        <tbody>
            <tr
            *ngIf="isCapgeminiSelected() && isStaffingDataValid() && isLocationTypeOnsh()">
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td rowspan="1" style="width:12%"></td>
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td style="text-align: left" *ngFor="let el of pyramidCustom;" rowspan="1">
                    {{el.value}}<span> %</span></td>
            </tr>
        
            <tr
            *ngIf="(isCapgeminiSelected() && isStaffingDataValid() && isLocationTypeNotOnsh()) || (isExternalSelected() && isStaffingDataValid() && isLocationTypeOnsh())">
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td rowspan="1" style="width:12%"></td>
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td style="text-align: left" *ngFor="let el of pyramidCustom;" rowspan="1">
                    {{el.value}}<span> %</span></td>
            </tr>
        
            <tr
            *ngIf="isExternalSelected() && isStaffingDataValid() && isLocationTypeNotOnsh()">
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td rowspan="1"></td>
                <td style="text-align: left" *ngFor="let el of pyramidCustom;" rowspan="1">
                    {{el.value}}<span> %</span></td>
            </tr>
        </tbody>
        </table> -->








        <div class="">
            <div class="d-flex justify-content-end">
                <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="SaveStaffingModel()" value="">
                    Save
                </button>
            </div>
        </div>
    </ng-container>


    <div class="filters buttonHolder" id="create-deal">
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="ActionButtons != null && ActionButtons != undefined">
                <ng-container *ngFor="let actionBar of ActionButtons 
      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass" (click)="actionButtonClick(actionBar)"
                        [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        | translate}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>


    <!-- Loader section -->
    <div [hidden]="!IsLoading">
        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
    </div>

    <notification *ngIf="displayNotifybox" [notifyinfo]="notify?.info" [timeout]="notify.timeout"
        [curaction]="notify.action" [curitem]="notify.item" (selected)="setNotifyRes($event)"
        (close)="closeNotifybox()">
    </notification>

    <modal-popup id="LotResourceDetails" style="display: none; font-size: x-small" [modaldetail]="modalInf">
        <p>This display panel provides a resource summary of a specific lot ( the one selected from the top panel) - by
            location and for the deal term.</p>
    </modal-popup>

    <modal-popup id="PyramidPopupDetails" style="display: none;" [modaldetail]="modalInf">
        <ng-container
            *ngIf="(PyramidPopupDetailsTemplate != null && PyramidPopupDetailsTemplate != undefined && PyramidPopupDetailsTemplate.length > 0 && PyramidPopupDetailsData != null && PyramidPopupDetailsData != undefined && PyramidPopupDetailsData.length > 0)">
            <customform #LotStaffingPyramidForm [FormTemplate]="PyramidPopupDetailsTemplate"
                [FormData]="SelectedPyramidPopupDetailsData" [FormProp]="FormProp" [FormConfig]="FormConfig"
                [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"  [parent]="this"
                [cellLock]="setCellLock" [parent]="this"  (FieldChange)="onChange($event)">
            </customform>
        </ng-container>

        <div class="">
            <div class="d-flex justify-content-end p-3">
                <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="saveSpecificPyramid()" value="">
                    Save
                </button>
                <button type="button" class="rounded-pill btnCustom" (click)="cancelSpecificPyramid()" value="">
                    Cancel
                </button>
            </div>
        </div>


       
    </modal-popup>

    <modal-popup id="ResourcePyramidDetails" style="display: none; font-size: x-small" [modaldetail]="modalInf">
        <p>Staffing Model is based on modeling approach option selected during deal context setting - if using Capgemini
            rates and pyramid structure by location is chosen, then specific country level pyramid for the chosen
            service
            domain is defaulted - % of resource level as per best practice / country level guidelines. ? Standard Role –
            Since each country may have different titles or designations or levels for a given resource type with a
            specific
            range of years of experience and type of role and since external benchmarking sources have some different
            nomenclatures for the same, this definition normalizes those to a unique label. ? Standard Role Type – Some
            roles may have same nomenclature but performs a different role – example analyst could be operational role
            for
            some specific scope of work whereas it could be for transformation work for something else. The default
            values
            are as per normalized definition within the tool.</p>
    </modal-popup>

</ng-container>