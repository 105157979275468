import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { ModalService } from './modal.service';
//import { ModalInterface } from './modal.interface';
import {RangeList} from './range-slider.interface';
import {AppSharedService} from '../../app/shared/app.sharedservice';

@Component({
    selector: 'range-slider',
    templateUrl: './range-slider.component.html',
   
})
export class RangeSliderComponent implements OnInit, OnDestroy {
    @Input() rangeList!:RangeList [];
    @Input() min! : number;
    @Input() max! : number;
    @Input() step! : number;
    @Input() colWidth! : number;
    @Input() tooltip! : any;
    @Input() selectedtab! : any;
    @Input() selectedButton! :any;
  
    @Output() public rangeClick = new EventEmitter<any>();
   

    private element: any;    
    public selectedBtn:any;
    public retrieveBtn:any
    
    constructor(private el: ElementRef, private sharedService: AppSharedService) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        this.selectedBtn="";
        if(this.selectedtab == 'Productivity'){
            this.selectedBtn=this.selectedButton;
        }
        else if(this.selectedtab == 'LocationMix'){
            this.selectedBtn=this.selectedButton;
            }

        else if(this.selectedtab == 'Pyramid'){
            this.selectedBtn=this.selectedButton;
            }
         else if(this.selectedtab == 'Margin'){            
            this.selectedBtn=this.selectedButton;
            }
        else if(this.selectedtab == 'Others'){            
                this.selectedBtn=this.selectedButton;
                }


        console.log(this.selectedBtn)
        
    }
       
    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
      
    }

    onClick(event:any){
        this.rangeClick.emit(event);
        this.selectedBtn=event.CodeDescription;
        //this.sharedService.setSelectedBtn(this.selectedBtn);
    }
    
    @HostListener('document:keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent) {
       
    }
}