
import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined, isStringNullOrEmpty } from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DealService } from './create-deal.service';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus, ReferenceValueType } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { FormComponent } from 'src/modules/form/form.component';
import { isEmpty } from 'rxjs/operators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-deal',
  templateUrl: './create-deal.component.html',
  styleUrls: ['./create-deal.component.css']
})

@AutoUnsubscribe
export class CreateDealComponent implements OnInit {

  @ViewChild('createDealForm', { static: false }) createDealForm!: FormComponent;

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 3,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public CreateDealTemplate: FieldTemplate[] = [];
  public createTemplateMandatory: FieldTemplate[] = [];
  public createTemplateNonMandatory: FieldTemplate[] = [];
  public modalInf: ModalInterface = new ModalInterface();
  public CreateDealListData: any[] = [];
  public GetAmDealProgressBarPerData: any[] = [];
  public CreateDealListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public indexid: any;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public infoListTemplate: FieldTemplate[] = [];
  public selectedInfoData: any;
  public Approach: any;
  public navigate: boolean = false;
  public GlobalConfig: any = {};
  public curItemsPerPage: number = 10;
  public count: number = 0;
  public dealProgressMeter: number = 0;
  public DealCreationData: any = {};
  public DealSearchCreationData: any = {};
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public DealButtons: any = [];
  public QuickLinksButton: any = [];
  public CreateDealPermission: any = [];
  public CreateDealProperties: any = [];
  public dealArray: any;
  public servicesLength: any;
  public Format: boolean = false;
  public completedDeal: boolean = false;
  public Mode: string = 'CREATE';
  public dealHeaderId: number = 0;
  public OnDealChange: boolean = false;
  public AMnavigate: boolean = false;
  public ModuleName: string = '';
  public Title: any = 'Creation of Deals';
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }

  public InfoFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': false
  };

  public Config: any = {
    ActionButtons: []
  };




  @ViewChild('overallgrid', { static: false }) overallgrid!: GridDisplayControlComponent;
  service: any;
  // sub: Subscription;
  DealSearch: any;



  constructor(private title: Title, private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private modalService: ModalService, private dealservice: DealService , private toastr: ToastrService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  // ngOnInit(): void {
  //   this.title.setTitle(this.Title);
  //   console.log("CREATE DEAL")
  //   this.DealSearch = window.history.state.data;
  //   // this.DealSearchCreationData = window.history.state.data;
  //   //   this._route.queryParams.subscribe(params => {
  //   //     this.DealSearch = JSON.parse(params["data"]);
  //   //  });
  //   console.log(this.DealSearch);
  //   //   this._route.params.subscribe((params: { [x: string]: string | number; }) => {
  //   //     this.DealSearch = +params['data']; // (+) converts string 'id' to a number

  //   //     // In a real app: dispatch action to load the details here.
  //   //  });
  //   if (!isNullOrUndefined(this.DealSearch)) {
  //     this.Mode = 'UPDATE';

  //     // this.DealSearchCreationData = this.DealSearch;
  //     this.DealSearchCreationData = window.history.state.data;

  //     if (this.DealSearchCreationData.DealStatus === '2') {
  //       this.FormProp.IsEditable = false;
  //     }
  //     if (this.DealSearchCreationData.DealStatus === '1') {
  //       this.FormProp.IsEditable = true;
  //       this._appSharedService.IsEditable = true;
  //       this._appSharedService.IsEditable$.next(this._appSharedService.IsEditable);
  //     }
  //     if (this.DealSearchCreationData.DealStatus === '0' || this.DealSearchCreationData.DealStatus === '-1' || this.DealSearchCreationData.DealStatus === '2') {
  //       //this.FormProp.IsEditable = false;
  //       this._appSharedService.IsEditable = false;
  //       this._appSharedService.IsEditable$.next(this._appSharedService.IsEditable);

  //     }

  //     if (this.DealSearchCreationData.DealStatus === '2') {
  //       this.completedDeal = true;
  //     }

  //     if (this._appSharedService.setDealHeaderId > 0) {
  //       this.dealHeaderId = this._appSharedService.setDealHeaderId;
  //     }
  //     else {
  //       this.dealHeaderId = this.DealSearchCreationData.DealHeaderId;
  //     }


  //   }
  //   else {
  //     this.dealHeaderId = this._appSharedService.setDealHeaderId;
  //   }

  //   this.getCreateDealTemplate();

  //   console.log(this.Mode, 'mode!!!!!!!!!')
  // }
  ngOnInit(): void {
    this.title.setTitle(this.Title);
    if (!isNullOrUndefined(this._appSharedService.currentDealData)) {
      this.Mode = 'UPDATE';
      this.dealHeaderId = this._appSharedService.currentDealData.DealHeaderId;
    }
    else {
      this.Mode = 'CREATE';
    }

    console.log(this._appSharedService);
    console.log(this._appSharedService.currentDealData)
    this.ModuleName = this._appSharedService.currentUserModuleData.Code;

    if (!isNullOrUndefined(window.history.state.dealData)) {
      this.Mode = 'UPDATE';
      //this._appSharedService.IsCreateMode = false;
      //this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);

      this.DealSearchCreationData = window.history.state.dealData;
      // this._appSharedService.selectedDeal = this.DealSearchCreationData;
      // this._appSharedService.selectedDeal$.next(this._appSharedService.selectedDeal);

      console.log(this._appSharedService.selectedDeal);
      if (this.DealSearchCreationData.DealStatus === '2') {
        this.FormProp.IsEditable = false;
      }
      if (this.DealSearchCreationData.DealStatus === '1') {
        this.FormProp.IsEditable = true;
        this._appSharedService.IsEditable = true;
        this._appSharedService.IsEditable$.next(this._appSharedService.IsEditable);
      
      }
      if (this.DealSearchCreationData.DealStatus === '0' || this.DealSearchCreationData.DealStatus === '-1' || this.DealSearchCreationData.DealStatus === '2') {
        //this.FormProp.IsEditable = false;
        this._appSharedService.IsEditable = false;
        this._appSharedService.IsEditable$.next(this._appSharedService.IsEditable);

      }

      if (this.DealSearchCreationData.DealStatus === '2') {
        this.completedDeal = true;
      }

      if (this._appSharedService.setDealHeaderId > 0) {
        this.dealHeaderId = this._appSharedService.setDealHeaderId;
      }
      else {
        this.dealHeaderId = this.DealSearchCreationData.DealHeaderId;
      }


    }

    this.getCreateDealTemplate();

    console.log(this.Mode, 'mode!!!!!!!!!')
  }

  getCreateDealTemplate(): void {
    this.IsLoading = true;
    this.CreateDealTemplate = [];


    if (this.ModuleName == 'AM') {

      let Amdata: ComponentConfig = {
        Component: 'AMDealComponent', ComponentCode: ComponentCodes.AMDealComponent, ReferenceValueType: "1"
      };
      let subscribe$: Subscription = this._commonAPIService.getComponentConfig(Amdata).subscribe(res => {
        console.log(res);
        if (res !== undefined && res !== null && res.Status === 200) {
          let template = res.Data.FieldTemplate!;
          this.CreateDealPermission = res.Data.Permission;
          this.CreateDealProperties = res.Data.Properties;
          this.CreateDealTemplate = template.filter(x => x.Group === 'CreateDealAM');
          let contractMinDate = this.CreateDealTemplate.filter(x => x.FieldName == "ContractStartDate");
          contractMinDate[0].EditValidate.Min = new Date();

          this.setCompProperties(res.Data.Properties!);
          this.DealButtons = this.Config.AmActionButtons.filter((x: any) => x.Group === 'CreateDealAM');
          //this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons.filter((x: any) => x.Group === 'SearchGrid');
          this.setActionButtonPermissions();

        }

        if (this.Mode === 'UPDATE') {
          this.GetAmDealDetails();
        } else {
          this.GetAmDealDetails();
          this.DealCreationData.IsEditable = 1;
          this.IsLoading = false;
        }
      },
        err => {
          this.IsLoading = false;
          console.log('Error on getRequestTemplate.');
        });

      this.subscriptionList$.push(subscribe$);
    }
    else {

      let odata: ComponentConfig = {
        Component: 'DealComponent', ComponentCode: ComponentCodes.DealComponent, ReferenceValueType: "1"
      };

      let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
        console.log(res);
        if (res !== undefined && res !== null && res.Status === 200) {
          let template = res.Data.FieldTemplate!;
          this.CreateDealPermission = res.Data.Permission;
          this.CreateDealProperties = res.Data.Properties;
          this.CreateDealTemplate = template.filter(x => x.Group === 'CreateNewDeal');
        
          this.setCompProperties(res.Data.Properties!);
          this.DealButtons = this.Config.ActionButtons.filter((x: any) => x.Group === 'DealCreation');
          this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons.filter((x: any) => x.Group === 'SearchGrid');
          this.setActionButtonPermissions();
         
        }



        if (this.Mode === 'UPDATE') {
          this.getDealDetails();
        } else {
          this.getDealDetails();
          this.DealCreationData.IsEditable = 1;
          this.IsLoading = false;

        }
      },
        err => {
          this.IsLoading = false;
          console.log('Error on getRequestTemplate.');
        });

      this.subscriptionList$.push(subscribe$);

    }
  }
  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      // this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
      //   JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
      // this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // 2) Blocking Cols based on custom values
      this.Config.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      this.CreateDealProperties.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtons) && this.Config.ActionButtons.length > 0) {
      this.Config.ActionButtons.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          item.IsApplicable = false;
          let peritem = this.CreateDealPermission.indexOf(item.Name);
          if (peritem >= 0) {
            item.HasPermission = true;
            item.IsApplicable = true;
          }
        }
      });
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  onClick(event: any): void {


    switch (event.fieldname.toUpperCase()) {
      case '':
      case '':
        // this._router.navigate(['app/home/search/case', selitem.RequestId]);
        break;
    }
  }

  HelpIconClick() {
    this.openModal('OpenTooltip');
    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };
  }

  onChange(event: any): void {
    this.OnDealChange = true;

    if (this.ModuleName == 'AM') {
      this.selectedInfoData = event.item;
      this.selectedInfoData.IsEditable = true;
      console.log(event)

      let formatType = /^[-+]?[0-9]+\.[0-9]+$/
      let dealTermTest = formatType.test(this.selectedInfoData.DealTerm)
      let thorIdTest = formatType.test(this.selectedInfoData.ThorId)

      switch (event.fieldname.toUpperCase()) {
        case 'DEALTERM':
          if (dealTermTest == true) {
            this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
          }
          break;

        case 'THORID':
          if (thorIdTest == true) {
            this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
          }
          break;
        case 'COUNTRY':

          let controlArray: any = [];
          controlArray = this.CreateDealTemplate[16].Control.List;
          let filteredcountryCode = controlArray.filter((x: any) => x.Code == event.item.Country);
          console.log(filteredcountryCode);
          console.log(this.DealCreationData)
          this.DealCreationData.Currency = filteredcountryCode[0].UserDef1;
          break;
      }

      const inputFields = document.querySelectorAll('.form-control.form-control-sm');

      console.log(inputFields);
      // this.createTemplateMandatory = this.CreateDealTemplate.filter(x => x.EditValidate.Required == true);
      // for (let index = 0; index <= this.createTemplateMandatory.length; index++) {

      //   let LinkedField: any = this.createTemplateMandatory[index].FieldName;
      //   //if (modifieditems[LinkedField] == null || modifieditems[LinkedField] == "") {


      switch (event.fieldname) {
        case 'Client':
          const inputField0 = inputFields[0] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Client) || this.selectedInfoData.Client == '') {
            console.log(inputField0);
            inputField0.style.border = "1px solid red";
          }
          else {
            inputField0.style.border = "";
          }
          break;
        case 'DealName':
          const inputField1 = inputFields[2] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.DealName) || this.selectedInfoData.DealName == '') {

            console.log(inputField1);
            inputField1.style.border = "1px solid red";
          }
          else {
            inputField1.style.border = "";
          }
          break;

        case 'ThorId':
          const inputField3 = inputFields[4] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.ThorId) || this.selectedInfoData.ThorId == '') {
            inputField3.style.border = "1px solid red";
          }
          else {
            inputField3.style.border = "";
          }
          break;

        case 'ContractStartDate':
          const inputField5 = inputFields[5] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.ContractStartDate) || this.selectedInfoData.ContractStartDate == '') {
            //  if (datePicker) {
            inputField5.style.border = "1px solid red";
            // }


          }
          else {
            inputField5.style.border = "";
          }
          break;
        case 'DealTerm':
          const inputField4 = inputFields[6] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.DealTerm) || this.selectedInfoData.DealTerm == '') {
            inputField4.style.border = "1px solid red";
          }
          else {
            inputField4.style.border = "";
          }
          break;

        default:
          break;
      }
      // }
      const selectBoxes = document.querySelectorAll('.ng-select-container');
      const datePicker = document.querySelector('.datepick.form-control.form-control-sm.ng-untouched.ng-pristine.ng-valid') as HTMLInputElement;
      switch (event.fieldname) {

        case 'AmApproach':
          const selectBox0 = selectBoxes[0] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.AmApproach) || this.selectedInfoData.AmApproach == '') {
            console.log(selectBox0);
            selectBox0.style.border = "1px solid red";
          }
          else {
            selectBox0.style.border = "";
          }

          break;

        case 'RateModellingOption':
          const selectBox1 = selectBoxes[1] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.RateModellingOption) || this.selectedInfoData.RateModellingOption == '') {

            selectBox1.style.border = "1px solid red";
          }
          else {
            selectBox1.style.border = "";
          }

          break;

        case 'ClientIndustry':
          const selectBox2 = selectBoxes[2] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.ClientIndustry) || this.selectedInfoData.ClientIndustry == '') {

            selectBox2.style.border = "1px solid red";
          }
          else {
            selectBox2.style.border = "";
          }

          break;


        case 'SBU':
          const selectBox3 = selectBoxes[5] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.SBU) || this.selectedInfoData.SBU == '') {

            selectBox3.style.border = "1px solid red";
          }
          else {
            selectBox3.style.border = "";
          }

          break;

        case 'Country':
          const selectBox4 = selectBoxes[8] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Country) || this.selectedInfoData.Country == '') {

            selectBox4.style.border = "1px solid red";
          }
          else {
            selectBox4.style.border = "";
          }

          break;
        case 'Currency':
          const selectBox5 = selectBoxes[9] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Currency) || this.selectedInfoData.Currency == '') {

            selectBox5.style.border = "1px solid red";
          }
          else {
            selectBox5.style.border = "";
          }

          break;
        case 'ContractStartDate':
          if (isNullOrUndefined(this.selectedInfoData.ContractStartDate) || this.selectedInfoData.ContractStartDate == '') {
            //  if (datePicker) {
            datePicker.style.border = "1px solid red";
            // }


          }
          else {
            datePicker.style.border = "";
          }

          break;


          if (isNullOrUndefined(this.selectedInfoData.Client) || this.selectedInfoData.Client == '') {
            this.selectedInfoData.Style.border = "1px solid red"


          }
          else {
            selectBox5.style.border = "";
          }

          break;
        default:
          break;
      }


      //this._appSharedService.setSelectedTab(this.selectedInfoData.Services)



      /* let formatType = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z]/;
      let res = formatType.test(this.selectedInfoData.DealTerm)
      console.log(res)
  
      if (res == true) {
        this.Format = false;
        this.selectedInfoData.DealTerm = '0';
        this.showNotificationMessage('Special characters & alphabets are not allowed', 2, '', null, '', '', 1000);
        this.navigate = false;
      }
      else if (res == false) {
        this.Format = true;
      } */
      this.createDealForm.setDispcols();
    }

    if (this.ModuleName == 'CIS') {
      // let service:any='';
      // let serlength:any='';
      this.selectedInfoData = event.item;
      this.selectedInfoData.IsEditable = true;
      console.log(this.selectedInfoData.Services, event.fieldname)



      let formatType = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z]/;
      let res = formatType.test(this.selectedInfoData.DealTerm)
      console.log(res)

      if (res == true) {
        this.Format = false;
        //this.selectedInfoData.DealTerm = '0';
        this.showNotificationMessage('Special characters & alphabets are not allowed', 2, '', null, '', '', 2500);
        this.navigate = false;
      }
      else if (res == false) {
        this.Format = true;
      }


      if (!isNullOrUndefined(this.selectedInfoData.Services)) {
        var service = (this.selectedInfoData.Services).split(',')
        var serlength = service.length;
      }

      switch (event.fieldname.toUpperCase()) {

        case 'SERVICES':
          if (serlength < this.servicesLength)
            this.showNotificationMessage('This action will remove all existing data from the respective Service type.To undo this action click on "Back" button and click on "Yes"', 2, '', null, '', '', 5000);
          this.navigate = false;
          break;
        case 'BENCHMARKTYPE':
          this.selectedInfoData.BenchmarkSubType = null;
          break;
        case 'TRANSITIONPER':
          if (this.selectedInfoData.TransitionPer > 100 || this.selectedInfoData.TransitionPer < 0) {
            this.selectedInfoData.TransitionPer = "15.0"
          }
          break;
        case 'GOVERNANCEPER':
          if (this.selectedInfoData.GovernancePer > 100 || this.selectedInfoData.GovernancePer < 0) {
            this.selectedInfoData.GovernancePer = "8.0"
          }
          break;
        case 'SERVICEMANAGEMENTPER':
          if (this.selectedInfoData.ServiceManagementPer > 100 || this.selectedInfoData.ServiceManagementPer < 0) {
            this.selectedInfoData.ServiceManagementPer = "5.0"
          }
          break;
        case 'TOOL':
          if (event.item.Tool == 'COS002') {
            this.createDealForm.enableFields('CostasPer', true);
            this.DealCreationData.CostasPer = 0;

          
          }
          if (event.item.Tool == 'COS001') {
            this.createDealForm.enableFields('CostasPer', false);


          }
          break;
      }

      const inputFields = document.querySelectorAll('.form-control.form-control-sm');

      console.log(inputFields);
      switch (event.fieldname) {
        case 'Client':
          const inputField0 = inputFields[1] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Client) || this.selectedInfoData.Client == '') {
            console.log(inputField0);
            inputField0.style.border = "1px solid red";
          }
          else {
            inputField0.style.border = "";
          }
          break;
        case 'DealName':
          const inputField1 = inputFields[2] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.DealName) || this.selectedInfoData.DealName == '') {

            console.log(inputField1);
            inputField1.style.border = "1px solid red";
          }
          else {
            inputField1.style.border = "";
          }
          break;

        case 'ThorId':
          const inputField3 = inputFields[0] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.ThorId) || this.selectedInfoData.ThorId == '') {
            inputField3.style.border = "1px solid red";
          }
          else {
            inputField3.style.border = "";
          }
          break;


        case 'DealTerm':
          const inputField4 = inputFields[3] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.DealTerm) || this.selectedInfoData.DealTerm == '') {
            inputField4.style.border = "1px solid red";
          }
          else {
            inputField4.style.border = "";
          }
          break;

        case 'TransitionPer':
          const inputField5 = inputFields[4] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.TransitionPer) || this.selectedInfoData.TransitionPer == '') {
            inputField5.style.border = "1px solid red";
          }
          else {
            inputField5.style.border = "";
          }
          break;


        case 'GovernancePer':
          const inputField6 = inputFields[5] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.GovernancePer) || this.selectedInfoData.GovernancePer == '') {
            inputField6.style.border = "1px solid red";
          }
          else {
            inputField6.style.border = "";
          }
          break;


        case 'ServiceManagementPer':
          const inputField7 = inputFields[6] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.ServiceManagementPer) || this.selectedInfoData.ServiceManagementPer == '') {
            inputField7.style.border = "1px solid red";
          }
          else {
            inputField7.style.border = "";
          }
          break;

        case 'SpecifyCompetitorName':
          const inputField8 = inputFields[7] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.SpecifyCompetitorName) || this.selectedInfoData.SpecifyCompetitorName == '') {
            inputField8.style.border = "1px solid red";
          }
          else {
            inputField8.style.border = "";
          }
          break;

        default:
          break;
      }

      const selectBoxes = document.querySelectorAll('.ng-select-container');

      switch (event.fieldname) {

        case 'Country':
          const selectBox4 = selectBoxes[0] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Country) || this.selectedInfoData.Country == '') {

            selectBox4.style.border = "1px solid red";
          }
          else {
            selectBox4.style.border = "";
          }

          break;


        case 'Region':
          const selectBoxRegion = selectBoxes[1] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Region) || this.selectedInfoData.Region == '') {

            selectBoxRegion.style.border = "1px solid red";
          }
          else {
            selectBoxRegion.style.border = "";
          }

          break;
        case 'Currency':
          const selectBox5 = selectBoxes[2] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Currency) || this.selectedInfoData.Currency == '') {

            selectBox5.style.border = "1px solid red";
          }
          else {
            selectBox5.style.border = "";
          }

          break;
        case 'Competitors':
          const selectBox_comp = selectBoxes[3] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Competitors) || this.selectedInfoData.Competitors == '') {

            selectBox_comp.style.border = "1px solid red";
          }
          else {
            selectBox_comp.style.border = "";
          }
          break;

        case 'Benchmark':
          const selectBox6 = selectBoxes[4] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Benchmark) || this.selectedInfoData.Benchmark == '') {

            selectBox6.style.border = "1px solid red";
          }
          else {
            selectBox6.style.border = "";
          }

          break;

        // case 'BenchmarkType':
        //   const selectBox7 = selectBoxes[5] as HTMLInputElement;
        //   if (isNullOrUndefined(this.selectedInfoData.BenchmarkType) || this.selectedInfoData.BenchmarkType == '') {

        //     selectBox7.style.border = "1px solid red";
        //   }
        //   else {
        //     selectBox7.style.border = "";
        //   }

        //   break;


        // case 'BenchmarkSubType':
        //   const selectBox8 = selectBoxes[6] as HTMLInputElement;
        //   if (isNullOrUndefined(this.selectedInfoData.BenchmarkSubType) || this.selectedInfoData.BenchmarkSubType == '') {

        //     selectBox8.style.border = "1px solid red";
        //   }
        //   else {
        //     selectBox8.style.border = "";
        //   }

        //   break;
        // case 'ContractType':
        // const selectBox9 = selectBoxes[5] as HTMLInputElement;
        // if (isNullOrUndefined(this.selectedInfoData.ContractType) || this.selectedInfoData.ContractType == '') {

        //   selectBox9.style.border = "1px solid red";
        // }
        // else {
        //   selectBox9.style.border = "";
        // }

        // break;
        case 'ProductivityType':
          const selectBox10 = selectBoxes[8] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.ProductivityType) || this.selectedInfoData.ProductivityType == '') {

            selectBox10.style.border = "1px solid red";
          }
          else {
            selectBox10.style.border = "";
          }

          break;


        case 'Services':
          const selectBox11 = selectBoxes[10] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.Services) || this.selectedInfoData.Services == '') {

            selectBox11.style.border = "1px solid red";
          }
          else {
            selectBox11.style.border = "";
          }

          break;


        case 'PurposeofBenchmarking':
          const selectBox12 = selectBoxes[10] as HTMLInputElement;
          if (isNullOrUndefined(this.selectedInfoData.PurposeofBenchmarking) || this.selectedInfoData.PurposeofBenchmarking == '') {

            selectBox12.style.border = "1px solid red";
          }
          else {
            selectBox12.style.border = "";
          }

          break;


      }




      this.createDealForm.setDispcols();
    }
  }






  GetToolService(odata: any) {
    let subscribe$: Subscription = this.dealservice.GetToolService(odata).subscribe(result => {
      // if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {



      // }

      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$)
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {
      case 'DEALSUC':
        if (event.result.toUpperCase() === 'OK') {
          //this._router.navigate(['app/home']);  
          this._appSharedService.setSelectedTab(this.selectedInfoData.Services);
          this._router.navigate([`app/servicetyps/${this.dealHeaderId}`]);

        }
        break;
      case 'DEALSUCAM':
        if (event.result.toUpperCase() === 'OK') {
          this._router.navigate([`app/model-config/${this.dealHeaderId}`]);
        }
        break;
      case 'BACKDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this._router.navigate(['app/home']);

        }
        break;
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;

    if (this.navigate == true) {
      if (this.ModuleName == 'CIS') {
        this._router.navigate([`app/servicetyps/${this.dealHeaderId}`]);
      }

      if (this.ModuleName == 'AM') {
        this._router.navigate([`app/model-config/${this.dealHeaderId}`]);
      }
    }

  }

  validateAMMandatoryFields(modifieditems: any): boolean {
    console.log(modifieditems);
    let valid: boolean = true;
    let formatType = /^[-+]?[0-9]+\.[0-9]+$/
    let dealTermTest = formatType.test(modifieditems.DealTerm);
    let thorIdTest = formatType.test(modifieditems.ThorId);


    if (isNullOrUndefined(modifieditems)) {
      this.showNotificationMessage('All fields are mandatory', 2, '', null, '', '', 1000);
      this.navigate = false;
      valid = false;
    }


    if (dealTermTest == true || thorIdTest == true) {
      this.showNotificationMessage('Decimal values are not allowed', 2, '', null, '', '', 1200);
      this.navigate = false;
      valid = false;
    }



    else if ((isNullOrUndefined(modifieditems.Client)) || (isNullOrUndefined(modifieditems.ClientIndustry)) || (isNullOrUndefined(modifieditems.AmApproach)) ||
      (isNullOrUndefined(modifieditems.DealName)) || (isNullOrUndefined(modifieditems.SBU)) || (isNullOrUndefined(modifieditems.ThorId)) ||
      (isNullOrUndefined(modifieditems.Country)) || (isNullOrUndefined(modifieditems.Currency)) || (isNullOrUndefined(modifieditems.ContractStartDate)) || (isNullOrUndefined(modifieditems.DealTerm)) || (isNullOrUndefined(modifieditems.RateModellingOption)) ||
      (modifieditems.Client == '') || (modifieditems.ClientIndustry == '') || (modifieditems.Currency == '') || (modifieditems.ContractStartDate == '') ||
      (modifieditems.SBU == '') || (modifieditems.Country == '') || (modifieditems.DealName == '') || (modifieditems.AmApproach == '') || (modifieditems.ThorId == '') ||
      (modifieditems.DealTerm == '') || (modifieditems.RateModellingOption == '')) {
      this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
      this.createTemplateNonMandatory = this.CreateDealTemplate.filter(x => x.EditValidate.Required == false);
      this.createTemplateMandatory = this.CreateDealTemplate.filter(x => x.EditValidate.Required == true);
      for (let index = 0; index <= this.createTemplateMandatory.length; index++) {
        this.indexid = index;
        let LinkedField: any = this.createTemplateMandatory[index].FieldName;
        if (modifieditems[LinkedField] == null || modifieditems[LinkedField] == "") {
          this.createTemplateMandatory[index].Style = {};
          this.createTemplateMandatory[index].Style.border = "1px solid red";
          // select the select box with indexs
          const selectBoxes = document.querySelectorAll('.ng-select-container');
          const datePicker = document.querySelector('.datepick.form-control.form-control-sm.ng-untouched.ng-pristine.ng-valid') as HTMLInputElement;
          switch (LinkedField) {

            case 'AmApproach':
              const selectBox0 = selectBoxes[0] as HTMLInputElement;
              if (isNullOrUndefined(modifieditems.AmApproach) || modifieditems.AmApproach == '') {
                console.log(selectBox0);
                selectBox0.style.border = "1px solid red";
              }
              else {
                selectBox0.style.border = "";
              }

              break;

            case 'RateModellingOption':
              const selectBox1 = selectBoxes[1] as HTMLInputElement;
              if (isNullOrUndefined(modifieditems.RateModellingOption) || modifieditems.RateModellingOption == '') {

                selectBox1.style.border = "1px solid red";
              }
              else {
                selectBox1.style.border = "";
              }

              break;

            case 'ClientIndustry':
              const selectBox2 = selectBoxes[2] as HTMLInputElement;
              if (isNullOrUndefined(modifieditems.ClientIndustry) || modifieditems.ClientIndustry == '') {

                selectBox2.style.border = "1px solid red";
              }
              else {
                selectBox2.style.border = "";
              }

              break;


            case 'SBU':
              const selectBox3 = selectBoxes[5] as HTMLInputElement;
              if (isNullOrUndefined(modifieditems.SBU) || modifieditems.SBU == '') {

                selectBox3.style.border = "1px solid red";
              }
              else {
                selectBox3.style.border = "";
              }

              break;

            case 'Country':
              const selectBox4 = selectBoxes[8] as HTMLInputElement;
              if (isNullOrUndefined(modifieditems.Country) || modifieditems.Country == '') {

                selectBox4.style.border = "1px solid red";
              }
              else {
                selectBox4.style.border = "";
              }

              break;
            case 'Currency':
              const selectBox5 = selectBoxes[9] as HTMLInputElement;
              if (isNullOrUndefined(modifieditems.Currency) || modifieditems.Currency == '') {

                selectBox5.style.border = "1px solid red";
              }
              else {
                selectBox5.style.border = "";
              }

              break;
            case 'ContractStartDate':
              if (isNullOrUndefined(modifieditems.ContractStartDate) || modifieditems.ContractStartDate == '') {
                if (datePicker) {
                  datePicker.style.border = "1px solid red";
                }

              }
              else {
                datePicker.style.border = "";
              }

              break;

            default:
              break;
          }
          // if (LinkedField == 'SBU') {


          //   console.log(selectBoxes);
          //   const selectBox0 = selectBoxes[0] as HTMLInputElement;
          //   console.log(selectBox0);
          //   selectBox0.style.border = "1px solid red";

          //   const selectBox1 = selectBoxes[1] as HTMLInputElement;

          //   selectBox1.style.border = "1px solid red";

          //   const selectBox2 = selectBoxes[2] as HTMLInputElement;

          //   selectBox2.style.border = "1px solid red";
          //   const selectBox3 = selectBoxes[5] as HTMLInputElement;

          //   selectBox3.style.border = "1px solid red";
          //   const selectBox4 = selectBoxes[8] as HTMLInputElement;

          //   selectBox4.style.border = "1px solid red";
          //   const selectBox5 = selectBoxes[9] as HTMLInputElement;

          //   selectBox5.style.border = "1px solid red";
          // }


          //  loop through each select box and apply the border style
          // selectBoxes.forEach(selectBox => {
          //   const element = selectBox as HTMLInputElement;
          //   console.log(element)
          //   element.style.border = "1px solid red";
          // });

          // Apply the border style to the element




          valid = false;
          this.count = this.count + 1;
        }







        // // Loop through each select box and apply the border style
        // selectBoxes.forEach(selectBox => {
        //   const element = selectBox as HTMLInputElement;
        //   const fieldName = element.getAttribute('formcontrolname');
        //   const isRequired = this.CreateDealTemplate.some(x => x.FieldName === fieldName && x.EditValidate.Required);
        //   if (isRequired) {
        //     element.style.border = "1px solid red";
        //   }
        // });


        // Loop through each select box and apply the border style




        else {
          this.createTemplateMandatory[index].Style = '';
          valid = true;

        }

      }



      // this.navigate = false;
      // valid = false;
      // this.count = this.count + 1;
    }

    else {
      // this.createTemplateMandatory[this.indexid].Style = "";
      const selectBoxes = document.querySelectorAll('.ng-select-container');
      selectBoxes.forEach(selectBox => {
        const element = selectBox as HTMLInputElement;
        element.style.border = "";
      });

      valid = true;
    }




    return valid;
  }

  validateMandatoryFields(modifieditems: any): boolean {
    let valid: boolean = true;
    let formatType = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    formatType.test(modifieditems.Rundurationmonths)


    if (isNullOrUndefined(modifieditems)) {
      this.showNotificationMessage('All fields are mandatory', 2, '', null, '', '', 1500);
      this.navigate = false;
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems)) {

      if ((isNullOrUndefined(modifieditems.Client)) ||
        (isNullOrUndefined(modifieditems.DealName)) ||
        (isNullOrUndefined(modifieditems.Country)) ||
        (isNullOrUndefined(modifieditems.Region)) ||
        (isNullOrUndefined(modifieditems.DealTerm)) ||
        (isNullOrUndefined(modifieditems.Currency)) || (isNullOrUndefined(modifieditems.TransitionPer)) ||
        (isNullOrUndefined(modifieditems.GovernancePer)) || (isNullOrUndefined(modifieditems.ServiceManagementPer)) ||
        (isNullOrUndefined(modifieditems.Competitors)) || (isNullOrUndefined(modifieditems.SpecifyCompetitorName)) ||
        (isNullOrUndefined(modifieditems.Benchmark)) ||
        (isNullOrUndefined(modifieditems.ProductivityType)) ||
        (isNullOrUndefined(modifieditems.Services)) ||
        (isNullOrUndefined(modifieditems.PurposeofBenchmarking)) ||
      //  (isNullOrUndefined(modifieditems.Tool)) ||
        (isNullOrUndefined(modifieditems.CapgeminiSectors)) ||
        (modifieditems.Client == '') ||
        (modifieditems.DealName == '') ||
        (modifieditems.Country == '') ||
        (modifieditems.Region == '') ||
        (modifieditems.DealTerm == 0) ||
        (modifieditems.Currency == '') || (modifieditems.TransitionPer === '') ||
        (modifieditems.GovernancePer === '') || (modifieditems.ServiceManagementPer === '') ||
        (modifieditems.Competitors == '') || (modifieditems.SpecifyCompetitorName == '') ||
        (modifieditems.Benchmark == '') ||
        (modifieditems.ProductivityType == '') ||
        (modifieditems.PurposeofBenchmarking == '') ||
        (modifieditems.Services == '') ||
      //  (modifieditems.Tool == '') ||
        (modifieditems.CapgeminiSectors == '')
      ) {
        this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 2000);
        this.navigate = false;
        valid = false;
        this.createTemplateNonMandatory = this.CreateDealTemplate.filter(x => x.EditValidate.Required == false);
        this.createTemplateMandatory = this.CreateDealTemplate.filter(x => x.EditValidate.Required == true);
        for (let index = 0; index <= this.createTemplateMandatory.length; index++) {
          this.indexid = index;
          let LinkedField: any = this.createTemplateMandatory[index].FieldName;
          if (modifieditems[LinkedField] == null || modifieditems[LinkedField] == "") {
            this.createTemplateMandatory[index].Style = {};
            this.createTemplateMandatory[index].Style.border = "1px solid red";
            // select the select box with indexs
            const selectBoxes = document.querySelectorAll('.ng-select-container');
            const datePicker = document.querySelector('.datepick.form-control.form-control-sm.ng-untouched.ng-pristine.ng-valid') as HTMLInputElement;
            // switch (LinkedField) {

            //   case 'AmApproach':
            //     const selectBox0 = selectBoxes[0] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.AmApproach) || modifieditems.AmApproach == '') {
            //       console.log(selectBox0);
            //       selectBox0.style.border = "1px solid red";
            //     }
            //     else {
            //       selectBox0.style.border = "";
            //     }

            //     break;

            //   case 'RateModellingOption':
            //     const selectBox1 = selectBoxes[1] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.RateModellingOption) || modifieditems.RateModellingOption == '') {

            //       selectBox1.style.border = "1px solid red";
            //     }
            //     else {
            //       selectBox1.style.border = "";
            //     }

            //     break;

            //   case 'ClientIndustry':
            //     const selectBox2 = selectBoxes[2] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.ClientIndustry) || modifieditems.ClientIndustry == '') {

            //       selectBox2.style.border = "1px solid red";
            //     }
            //     else {
            //       selectBox2.style.border = "";
            //     }

            //     break;


            //   case 'SBU':
            //     const selectBox3 = selectBoxes[5] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.SBU) || modifieditems.SBU == '') {

            //       selectBox3.style.border = "1px solid red";
            //     }
            //     else {
            //       selectBox3.style.border = "";
            //     }

            //     break;

            //   case 'Country':
            //     const selectBox4 = selectBoxes[8] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.Country) || modifieditems.Country == '') {

            //       selectBox4.style.border = "1px solid red";
            //     }
            //     else {
            //       selectBox4.style.border = "";
            //     }

            //     break;
            //   case 'Currency':
            //     const selectBox5 = selectBoxes[9] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.Currency) || modifieditems.Currency == '') {

            //       selectBox5.style.border = "1px solid red";
            //     }
            //     else {
            //       selectBox5.style.border = "";
            //     }

            //     break;
            //   case 'ContractStartDate':
            //     if (isNullOrUndefined(modifieditems.ContractStartDate) || modifieditems.ContractStartDate == '') {
            //       if (datePicker) {
            //         datePicker.style.border = "1px solid red";
            //       }

            //     }
            //     else {
            //       datePicker.style.border = "";
            //     }

            //     break;

            //   default:
            //     break;
            // }

            // const inputFields = document.querySelectorAll('.form-control.form-control-sm');

            // console.log(inputFields);
            // switch (LinkedField) {
            //   case 'Client':
            //     const inputField0 = inputFields[1] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.Client) || modifieditems.Client == '') {
            //       console.log(inputField0);
            //       inputField0.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField0.style.border = "";
            //     }
            //     break;
            //   case 'DealName':
            //     const inputField1 = inputFields[2] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.DealName) || modifieditems.DealName == '') {

            //       console.log(inputField1);
            //       inputField1.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField1.style.border = "";
            //     }
            //     break;
            //   case 'ThorId':
            //     const inputField3 = inputFields[0] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.ThorId) || modifieditems.ThorId == '') {
            //       inputField3.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField3.style.border = "";
            //     }
            //     break;

            //   case 'DealTerm':
            //     const inputField4 = inputFields[3] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.DealTerm) || modifieditems.DealTerm == '') {
            //       inputField4.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField4.style.border = "";
            //     }
            //     break;

            //   case 'TransitionPer':
            //     const inputField5 = inputFields[4] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.TransitionPer) || modifieditems.TransitionPer == '') {
            //       inputField5.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField5.style.border = "";
            //     }
            //     break;


            //   case 'GovernancePer':
            //     const inputField6 = inputFields[5] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.GovernancePer) || modifieditems.GovernancePer == '') {
            //       inputField6.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField6.style.border = "";
            //     }
            //     break;


            //   case 'ServiceManagementPer':
            //     const inputField7 = inputFields[6] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.ServiceManagementPer) || modifieditems.ServiceManagementPer == '') {
            //       inputField7.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField7.style.border = "";
            //     }
            //     break;

            //   case 'SpecifyCompetitorName':
            //     const inputField8 = inputFields[7] as HTMLInputElement;
            //     if (isNullOrUndefined(modifieditems.SpecifyCompetitorName) || this.selectedInfoData.SpecifyCompetitorName == '') {
            //       inputField8.style.border = "1px solid red";
            //     }
            //     else {
            //       inputField8.style.border = "";
            //     }
            //     break;

            //   default:
            //     break;
            // }



            switch (LinkedField) {

              case 'Country':
                const selectBox4 = selectBoxes[0] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Country) || modifieditems.Country == '') {

                  selectBox4.style.border = "1px solid red";
                }
                else {
                  selectBox4.style.border = "";
                }

                break;


              case 'Region':
                const selectBoxRegion = selectBoxes[1] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Region) || modifieditems.Region == '') {

                  selectBoxRegion.style.border = "1px solid red";
                }
                else {
                  selectBoxRegion.style.border = "";
                }

                break;
              case 'Currency':
                const selectBox5 = selectBoxes[2] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Currency) || modifieditems.Currency == '') {

                  selectBox5.style.border = "1px solid red";
                }
                else {
                  selectBox5.style.border = "";
                }

                break;
              case 'Competitors':
                const selectBox_comp = selectBoxes[3] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Competitors) || modifieditems.Competitors == '') {

                  selectBox_comp.style.border = "1px solid red";
                }
                else {
                  selectBox_comp.style.border = "";
                }
                break;
              case 'Benchmark':
                const selectBox6 = selectBoxes[4] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Benchmark) || modifieditems.Benchmark == '') {

                  selectBox6.style.border = "1px solid red";
                }
                else {
                  selectBox6.style.border = "";
                }

                break;

              // case 'BenchmarkType':
              //   const selectBox7 = selectBoxes[5] as HTMLInputElement;
              //   if (isNullOrUndefined(modifieditems.BenchmarkType) || modifieditems.BenchmarkType == '') {

              //     selectBox7.style.border = "1px solid red";
              //   }
              //   else {
              //     selectBox7.style.border = "";
              //   }

              //   break;


              // case 'BenchmarkSubType':
              //   const selectBox8 = selectBoxes[6] as HTMLInputElement;
              //   if (isNullOrUndefined(modifieditems.BenchmarkSubType) || modifieditems.BenchmarkSubType == '') {

              //     selectBox8.style.border = "1px solid red";
              //   }
              //   else {
              //     selectBox8.style.border = "";
              //   }

              //   break;
              // case 'ContractType':
              //   const selectBox9 = selectBoxes[7] as HTMLInputElement;
              //   if (isNullOrUndefined(modifieditems.ContractType) || modifieditems.ContractType == '') {

              //     selectBox9.style.border = "1px solid red";
              //   }
              //   else {
              //     selectBox9.style.border = "";
              //   }

              //   break;
              case 'ProductivityType':
                const selectBox10 = selectBoxes[8] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.ProductivityType) || modifieditems.ProductivityType == '') {

                  selectBox10.style.border = "1px solid red";
                }
                else {
                  selectBox10.style.border = "";
                }

                break;


              case 'Services':
                const selectBox11 = selectBoxes[10] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Services) || modifieditems.Services == '') {

                  selectBox11.style.border = "1px solid red";
                }
                else {
                  selectBox11.style.border = "";
                }

                break;


                
              case 'Tool':
                const selectBox_tool = selectBoxes[11] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.Tool) || modifieditems.Tool == '') {

                  selectBox_tool.style.border = "1px solid red";
                }
                else {
                  selectBox_tool.style.border = "";
                }

                break;


              case 'PurposeofBenchmarking':
                const selectBox12 = selectBoxes[12] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.PurposeofBenchmarking) || modifieditems.PurposeofBenchmarking == '') {

                  selectBox12.style.border = "1px solid red";
                }
                else {
                  selectBox12.style.border = "";
                }

                break;


              case 'CapgeminiSectors':
                const selectBox13 = selectBoxes[13] as HTMLInputElement;
                if (isNullOrUndefined(modifieditems.CapgeminiSectors) || modifieditems.CapgeminiSectors == '') {

                  selectBox13.style.border = "1px solid red";
                }
                else {
                  selectBox13.style.border = "";
                }

                break;


            }

            valid = false;
            this.count = this.count + 1;
          }












          else {
            this.createTemplateMandatory[index].Style = '';
            valid = true;

          }

        }


      }

      else if (modifieditems.TransitionPer > 100) {
        this.showNotificationMessage('Transition % should not more than 100%', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }
      else if (modifieditems.TransitionPer < 0) {
        this.showNotificationMessage('Transition % should not less than 0%', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }

      else if (modifieditems.GovernancePer > 100) {
        this.showNotificationMessage('Governance % should not more than 100%', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }
      else if (modifieditems.GovernancePer < 0) {
        this.showNotificationMessage('Governance % should not less than 0%', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }

      else if (modifieditems.ServiceManagementPer > 100) {
        this.showNotificationMessage('Service Management % should not more than 100%', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }
      else if (modifieditems.ServiceManagementPer < 0) {
        this.showNotificationMessage('Service Management % should not less than 0%', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }

      else if (modifieditems.DealTerm > 120) {
        this.showNotificationMessage('Run duration should not more than 120', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }
      else if (modifieditems.DealTerm < 0) {
        this.showNotificationMessage('Run duration should not less than 0', 2, '', null, '', '', 2500);
        this.navigate = false;
        valid = false;
      }

      // else if(modifieditems.Tool=='COS002' && (modifieditems.CostasPer!=0)){
      //   this.showNotificationMessage('If Tool is Cost Manually, Then Cost% should be 0', 2, '', null, '', '', 2500);
      //   this.navigate = false;
      //   valid = false;
      // }

      else {
        valid = true;
      }
    }

    return valid;
  }


  actionButtonClick(data: any) {
    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        case 'SUBMIT':
          if (this.ModuleName == 'CIS') {
            this.insertorUpdateDeals();
          }

          break;
        case 'SAVE':

          if (this.ModuleName == 'AM') {
            this.insertorUpdateAMDeals();
          }
          break;
        case 'BACK':
          if (this.OnDealChange == true) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'BackDeal', null, 'Yes', 'No', 10000);
            this.navigate = false;
          } else {
            this._router.navigate(['app/home']);
          }


          break;
      }
    }
  }

  setCellLock(fieldname: string, item: any, parent: CreateDealComponent): boolean {

    let bLock = false;
    let BlkCols: any[] = [];


    if (!isNullOrUndefined(parent.CreateDealProperties.BlockCols) && parent.CreateDealProperties.BlockCols.length > 0) {
      parent.CreateDealProperties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value <= item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }
    return bLock;

  }

  GetAmDealDetails() {
    let subscribe$: Subscription = this.dealservice.GetAmDealDetails(this.dealHeaderId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        this.DealCreationData.IsEditable = 1;
        this.DealCreationData = result.Data[0];
        // this.service = result.Data[0].Services;
        this.selectedInfoData = result.Data[0];
        this._appSharedService.currentDealData = this.selectedInfoData;
        this.Approach = this._appSharedService.currentDealData.AmApproach;
        this.GetAmDealProgressBarPer(this.dealHeaderId, this.ModuleName, this.Approach);
        this.getSelectedDeal(this.selectedInfoData);
        //this.selectedInfoData.DealProgress = 'AM1';

        if (this.selectedInfoData.DealStatus != "1") {
          this.selectedInfoData.IsEditable = 0;
          this.DealCreationData.IsEditable = 0;
        }
        else {
          this.selectedInfoData.IsEditable = 1;
          this.DealCreationData.IsEditable = 1;
        }


      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  GetAmDealProgressBarPer(id: number, Module: any, Approach: any) {
    this.IsLoading = true;
    let subscribe$: Subscription = this.dealservice.GetAmDealProgressBarPer(id, Module, Approach).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.GetAmDealProgressBarPerData = result.Data;
        this.dealProgressMeter = this.GetAmDealProgressBarPerData[0].ProgressBarPer;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  getDealDetails() {
    
    // this.IsLoading = true;
    let subscribe$: Subscription = this.dealservice.getDealDetails(this.dealHeaderId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        this.DealCreationData.IsEditable = 1;
        this.DealCreationData = result.Data[0];

     

      
        this.service = result.Data[0].Services;
        this.selectedInfoData = result.Data[0];

        if (this.selectedInfoData.DealStatus != "1") {
          this.selectedInfoData.IsEditable = false;
          this.DealCreationData.IsEditable = false;
        }

        let services = (this.selectedInfoData.Services).split(',')
        this.servicesLength = services.length;

        console.log(services.length);
       

        // setTimeout(() => {
        //   let RegionCode : string = this.DealCreationData.Region;
        //   let RegionDesc : string = this.DealCreationData.RegionDesc;

        //   this.DealCreationData.Region = null;
        //   this.DealCreationData.RegionDesc = null;

        //   this.DealCreationData.Region = RegionCode;
        //   this.DealCreationData.RegionDesc = RegionDesc;   

        //   this.createDealForm.setDispcols();
        // }, 1000);

        // this.DealCreationData.IsEditable = 1;
        //   if (this.DealCreationData.Tool == 'COS002') {
        //   this.createDealForm.enableFields('CostasPer', true);
        //   this.DealCreationData.CostasPer = 0;
        // }
      
        // if (this.DealCreationData.Tool == 'COS001') {
        //   this.createDealForm.enableFields('CostasPer', false);
        // }
      }

      this.IsLoading = false;

    },
      err => {
        console.log('Error on getting GetDealDetails.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  enableSubmit(): boolean {
    if (this.DealSearchCreationData.DealStatus !== '2' && this.createDealForm !== undefined) {
      return this.createDealForm.validateForm();
    }
    else {
      return false;
    }
  }

  getSelectedDeal(data: any) {
    this._appSharedService.setSelectedDeal(data);
  }

  navigatetoservicepage() {
    this.getSelectedDeal(this.selectedInfoData);
    this._router.navigate([`app/servicetyps/${this.dealHeaderId}`]);
  }
  navigatetoreportspage() {
    this.getSelectedDeal(this.selectedInfoData);
    this._router.navigate([`app/reports/${this.dealHeaderId}/0`]);
  }
  navigatetoLotsolutionModelling() {
    // this.getSelectedDeal(this.selectedInfoData);
    this._router.navigate([`app/create-deal/lot-solution-modelling`]);
  }
  insertorUpdateAMDeals() {
    this.getSelectedDeal(this.selectedInfoData);

    this._appSharedService.currentDealData = this.selectedInfoData;

    if ((this.selectedInfoData.IsEditable == true && this.OnDealChange == true) || this.Mode == 'CREATE') {

      if (this.validateAMMandatoryFields(this.selectedInfoData)) {
        this.IsLoading = true;

        let subscribe$: Subscription = this.dealservice.insertorUpdateAMDeals(this.selectedInfoData).subscribe(result => {
          if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {
            if (result.Data[0] != null) {
              this.dealHeaderId = result.Data[0].DealHeaderId;
              this.selectedInfoData.DealId = result.Data[0].DealId;
              this.selectedInfoData.DealStatus = result.Data[0].DealStatus;
              this.getSelectedDeal(this.selectedInfoData);
              this._appSharedService.currentDealData.DealHeaderId = result.Data[0].DealHeaderId;
              this._appSharedService.currentDealData.DealProgressOrder = result.Data[0].DealProgressOrder;

            }
            if (this.Mode == 'UPDATE') {
              this.showNotificationMessage('Deal Updated Successfully!', 1, 'DEALSUCAM', this.selectedInfoData, 'OK', '', 10000);
              this.navigate = true;
              this.OnDealChange = false;
              this._appSharedService.IsCreateMode = false;
              this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);
            }
            if (this.Mode == 'CREATE') {
              this.showNotificationMessage('Deal Created Successfully!', 1, 'DEALSUCAM', this.selectedInfoData, 'OK', '', 10000);
              this.navigate = true;
              this.OnDealChange = false;
              this._appSharedService.IsCreateMode = true;
              this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);
            }

            //this.showNotificationMessage('Deal Created Successfully!', 1, '', null, '', '');
            //this._router.navigate(['app/home']);
          }

          this.IsLoading = false;

        },
          err => {
            this.IsLoading = false;
          });

        this.subscriptionList$.push(subscribe$);
        console.log(this.subscriptionList$);


      }

    } else {
      this._appSharedService.IsCreateMode = false;
      this._appSharedService.IsCreateMode$.next(this._appSharedService.IsCreateMode);

      //Temprorary Code to lot
      // this._router.navigate([`app/lot-solution-pricing-model/othercost/nonstandardhardwaresoftware/1382/1/477`]);

      // this._router.navigate([`app/lot-solution-modelling/DeliveryModel/${this.dealHeaderId}/0/0`]);

      //Need to below code
      this._router.navigate([`app/model-config/${this.dealHeaderId}`]);

    }
  }

  insertorUpdateDeals() {
    this.getSelectedDeal(this.selectedInfoData);

    this._appSharedService.currentDealData = this.selectedInfoData;

    if ((this.selectedInfoData.IsEditable == true && this.OnDealChange == true) || this.Mode == 'CREATE') {

      if (this.validateMandatoryFields(this.selectedInfoData)) {
        if (this.Format == true) {
          this.IsLoading = true;

          let subscribe$: Subscription = this.dealservice.InsertorUpdateDeals(this.selectedInfoData).subscribe(result => {
            if(result.Status == ProcessStatus.Success && result.Data==null && result.Message!=''){
             this.toastr.warning(result.Message);
            }
            else{
              if (!isNullOrUndefined(result) && result.Status == ProcessStatus.Success) {
                if (result.Data[0] != null) {
                  this.dealHeaderId = result.Data[0].DealHeaderId;
                  this.selectedInfoData.DealId = result.Data[0].DealId;
                  this.selectedInfoData.DealStatus = result.Data[0].DealStatus;
                  this.getSelectedDeal(this.selectedInfoData);
                  this._appSharedService.currentDealData.DealHeaderId = result.Data[0].DealHeaderId;
                  this._appSharedService.currentDealData.DealProgressOrder = result.Data[0].DealProgressOrder;
                  if (this.Mode == 'UPDATE') {
                    this.showNotificationMessage('Deal Updated Successfully!', 1, 'DEALSUC', this.selectedInfoData, 'OK', '', 10000);
                    this.navigate = true;
                    this._appSharedService.setSelectedTab(this.selectedInfoData.Services);
                    // this._router.navigate([`app/servicetyps/${this.dealHeaderId}`]);
                  }
                  if (this.Mode == 'CREATE') {
                    this.showNotificationMessage('Deal Created Successfully!', 1, 'DEALSUC', this.selectedInfoData, 'OK', '', 10000);
                    this.navigate = true;
                    this._appSharedService.setSelectedTab(this.selectedInfoData.Services);
                    // this._router.navigate([`app/servicetyps/${this.dealHeaderId}`]);
                  }
                }
                //this.showNotificationMessage('Deal Created Successfully!', 1, '', null, '', '');
                //this._router.navigate(['app/home']);
              }
            }
           
            

            this.IsLoading = false;

          },
            err => {
              this.IsLoading = false;
            });

          this.subscriptionList$.push(subscribe$);
          console.log(this.subscriptionList$);
        }
        else {
          this.showNotificationMessage('Please fill correct values for Run Duration', 2, '', null, '', '', 1000);
          this.navigate = false;
        }

      }

    } else {
      //this.showNotificationMessage('No Record modified', 1, '', null, '', '');  

      // let navigationExtras: NavigationExtras = {
      //   queryParams: {
      //       "dealHeaderId": JSON.stringify(this.dealHeaderId)
      //   }
      // };

      // this._router.navigate(["app/servicetyps"],  navigationExtras);
      this._appSharedService.setSelectedTab(this.selectedInfoData.Services);
      this._router.navigate([`app/servicetyps/${this.dealHeaderId}`]);
    }
  }

}




// getDetails() {
//   let array: any = [];
//   let dealArray: any = [];


//   let type = this.selectedInfoData.Benchmarkspecifictoaccount;
//   let value = this.selectedInfoData.Benchmarkspecifictocountry;
//   let benchmarktype: any;
//   let benchmarkvalue: any;

//   if (type == "ACC") {
//     benchmarktype = type;
//     benchmarkvalue = value;
//   }

//   if (type == "CON") {
//     benchmarktype = type;
//     benchmarkvalue = value;
//   }

//   (this.selectedInfoData.Services.split(',')).map((e: any) => {

//     array.push({
//       'DealLineitemId': "0",
//       'ServiceType': e,
//       'DealTerm': this.selectedInfoData.Rundurationmonths,
//       'TransitionPercentage': this.selectedInfoData.TransitionPer,
//       'GovernancePercentage': this.selectedInfoData.GovernancePer,
//       'ServiceManagementPercentage': this.selectedInfoData.ServiceManagementPer,
//     });


//   })
//   console.log(array, "servicearraycheck***");

//   dealArray = [{
//     'DealHeaderId': 0,
//     'ThorId': this.selectedInfoData.ThorID,
//     'Client': this.selectedInfoData.ClientName,
//     'DealName': this.selectedInfoData.OpportunityName ? this.selectedInfoData.OpportunityName : "",
//     'Country': this.selectedInfoData.ContractingCountry,
//     'Region': this.selectedInfoData.Region ? this.selectedInfoData.Region : "",
//     'Currency': this.selectedInfoData.Currency,
//     'Competitors': this.selectedInfoData.Competitors,
//     'SpecifyCompetitorName': this.selectedInfoData.SpecifyCompetitorNames,
//     'Benchmark': this.selectedInfoData.ExternalBenchmarksource,
//     "BenchmarkType": benchmarktype,
//     'BenchmarkSubType': benchmarkvalue,
//     // "BenchmarkType": "Country",
//     //'BenchmarkSubType':this.selectedInfoData.Benchmarkspecifictocountry,
//     'ContractType': this.selectedInfoData.ContractType ? this.selectedInfoData.ContractType : "",
//     'ProductivityType': this.selectedInfoData.YOYProductivity,
//     'DealStage': this.selectedInfoData.CurrentDealStage ? this.selectedInfoData.CurrentDealStage : "",


//     "DealLineItem": array

//   }]
//   return dealArray;

// }