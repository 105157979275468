import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../shared/app.sharedservice';
import { environment } from '../../environments/environment';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from './home.service';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus, ReferenceValueType } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormComponent } from 'src/modules/form/form.component';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

@AutoUnsubscribe
export class HomeComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;
  @ViewChild('DealCloneForm', { static: false }) DealCloneForm!: FormComponent;

  public ModuleName: string = '';
  public compTitle: string = 'Home';
  public selectedView: string = 'dashboard';
  public Dealstatus: any = '';
  public dashbaord: boolean = true;
  public selectedBtn: string = 'Recent';
  public CISDatabaseDealUpdateComments: any = '';
  public _userId: any;
  public dealclone: any = '';
  public dealclose: any = '';
  public DashboardData: any = {};
  public createdBy: any = '';
  public SearchTemplate: FieldTemplate[] = [];
  public cloneDealFormTemplate: FieldTemplate[] = [];
  public cloneDealFormTemplate_CIS: FieldTemplate[] = [];
  public OldCISpopupTemplate: FieldTemplate[] = [];
  public AmDealReplicaOptionTemplate: FieldTemplate[] = [];
  public SearchButtons: any = [];
  public QuickLinksButton: any = [];
  public SearchPermission: any = [];
  public CaseInitiateButtons: any = [];
  public SelectedCloneDetailsData: any = [];
  public SelectedCloneDetailsData_CIS: any = [];
  public CloneDetailsData: any = [];
  public CloneDetailsData_CIS: any = [];
  public SaveDealReplicaData: any = [];
  public GlobalConfig: any = {};
  public UpdateInitiateCaseflag: boolean = false;
  public initiateFormData: any = {};
  public onholdcount: any;
  public cancelcount: any;
  public progresscount: any;
  public completedcount: any;
  public selectedDeal: any;
  public clickData: any;

  public modalInf: ModalInterface = new ModalInterface();

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public dealListTemplate: FieldTemplate[] = [];
  public dealListData: any[] = [];
  public dealListDataALL: any[] = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public dealheaderid: any;
  public dealitem: any;
  public DealName: any;

  public infoListTemplate: FieldTemplate[] = [];
  public selectedInfoData: any;

  public curItemsPerPage: number = 10;

  public ModelBaselining: any = { fieldname: 'ModelBaselining', value: '' };
  public LotSolutionEffortModelling: any = { fieldname: 'LotSolutionEffortModelling', value: '' };
  public PricingModeLabourCosts: any = { fieldname: 'PricingModeLabourCosts', value: '' };
  public CapgeminiSolutionOtherCosts: any = { fieldname: 'CapgeminiSolutionOtherCosts', value: '' };
  public ClientCosts: any = { fieldname: 'ClientCosts', value: '' };

  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    IsColResizable: false
  }

  public InfoFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': false
  };

  public Config: any = {
    ActionButtons: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public Title: any = 'Dashboard';
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];
  public SelectedButtonValue: any;
  public searchEvent: any = { 'item': {}, 'action': 'Clear' };

  public ShareDealTemplate: any[] = [];
  public displayShareDealNotifybox: boolean = true;
  public ShareDealData: any = {
    Code: null,
    CodeDescription: null,
    IsEditable: 1,
    ShareDealCode: null,
    ShareDealDesc: null
  };

  //public ShareDealData: any = [{ 'Code': '', 'CodeDescription': '' }];

  public ShareDealFormProp: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridDisplayControlComponent;


  constructor(public datepipe: DatePipe, private title: Title, private _router: Router, private _route: ActivatedRoute,
    public _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private homeService: HomeService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      console.log("app shared");
      console.log(this._appSharedService.currentRoleData$);


      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
    this.ModuleName = this._appSharedService.currentUserModuleData.Code;

  }

  ngOnInit() {
    this.SelectedCloneDetailsData_CIS.IsEditable = 1;
    this.title.setTitle(this.Title);


    if (this.Title === 'Dashboard') {
      this._appSharedService.currentDealData = null;
    }

    if (this.ModuleName == 'CIS') {
      this.getSearchTemplate();
      this.getDealCount('CIS');
    }
    if (this.ModuleName == 'AM') {
      this.getAMDashboardTemplate();
      this.getDealCount('AM');
    }

  }

  getSearchTemplate(): void {

    this.SearchTemplate = [];
    this.dealListTemplate = [];
    // this.searchEvent = { 'item': {}, 'action': 'Clear' };
    this.searchEvent = {
      'item': {
        "UserId": this._appSharedService.UserRoleList[0].UserId,
        "RoleId": "1",
        "Module": "CIS"

      }, 'action': 'Clear'
    };
    let odata: ComponentConfig = {
      Component: 'SearchBy', ComponentCode: ComponentCodes.SearchComponent, ReferenceValueType: "1"
    };

    this.IsLoading = true;

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.SearchPermission = res.Data.Permission;

        this.SearchTemplate = template.filter(x => x.Group === 'SearchFilter');
        this.dealListTemplate = template.filter(x => x.Group === 'SearchResult');
        this.ShareDealTemplate = template.filter(x => x.Group === 'ShareDeal');
        this.cloneDealFormTemplate_CIS = template.filter(x => x.Group === 'CloneDealCIS');
        this.OldCISpopupTemplate = template.filter(x => x.Group === 'CISCloseAndCloneDeal');
        this.ShareDealData.IsEditable = 1;
        this.SelectedCloneDetailsData_CIS.IsEditable = 1;
        this.setCompProperties(res.Data.Properties!);

        console.log("BUUTONS FOR CIS")
        console.log(this.Config.ActionButtons);
        this.SearchButtons = this.Config.ActionButtons.filter((x: any) => x.Group === 'Search');
        this.QuickLinksButton = this.Config.ActionButtons.filter((x: any) => x.Group === 'QuickLinks');
        this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons.filter((x: any) => x.Group === 'SearchGrid');
        this.setActionButtonPermissions();
        this.SearchData(this.searchEvent, true, false, 'CIS');
      }


    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }
  ////AM DASHBOARD///


  getAMDashboardTemplate(): void {

    this.SearchTemplate = [];
    this.dealListTemplate = [];
    this.searchEvent = {
      'item': {
        "UserId": this._appSharedService.UserRoleList[0].UserId,
        "RoleId": "1",
        "Module": "AM"

      }, 'action': 'Clear'
    };

    let odata: ComponentConfig = {
      Component: 'AMDashboard', ComponentCode: ComponentCodes.AMDashboard, ReferenceValueType: "1"
    };

    this.IsLoading = true;

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.SearchPermission = res.Data.Permission;

        this.SearchTemplate = template.filter(x => x.Group === 'ResultValueAM');
        this.dealListTemplate = template.filter(x => x.Group === 'FilterAMDashboard');
        this.ShareDealTemplate = template.filter(x => x.Group === 'ShareDeal');
        this.cloneDealFormTemplate = template.filter(x => x.Group === 'CloneDealAM');

        console.log("CLONE CIS");
        console.log(this.cloneDealFormTemplate);
        this.AmDealReplicaOptionTemplate = template.filter(x => x.Group === 'AmDealReplicaOption');
        this.ShareDealData.IsEditable = 1;
        console.log("^^^")
        console.log(this.ShareDealData);
        this.setCompProperties(res.Data.Properties!);
        console.log("BUUTONS FOR AM")
        console.log(this.Config.AMActionButtons);
        this.SearchButtons = this.Config.AMActionButtons.filter((x: any) => x.Group === 'AMSrchButton');
        this.QuickLinksButton = this.Config.AMActionButtons.filter((x: any) => x.Group === 'AMLinks');
        this.GridProp.ActionBarConfig.ActionBarList = this.Config.AMActionButtons.filter((x: any) => x.Group === 'AMGridSearch');
        this.setActionButtonPermissions();
        this.SearchData(this.searchEvent, true, false, 'AM');
      }


    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      if (this.ModuleName == 'CIS') {
        this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
          JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
        this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      }
      if (this.ModuleName == 'AM') {
        this.Config.AMActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'AMActionButtons'.toLowerCase()).length > 0 ?
          JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'AMActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
        this.GridProp.ActionBarConfig.ActionBarList = this.Config.AMActionButtons;
      }



      // 2) Blocking Cols based on custom values
      this.Config.BlockCols = (Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter((x: any) => x.Name.toLowerCase() === 'BlockCols'.toLowerCase())[0].JSONData.toString()) : []);

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }

  SearchData(event: any, allPendingFlag: boolean, searchClicked: boolean, Module: any): void {

    if (searchClicked == true && !isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' &&
      (isNullOrUndefined(event.item.Value) || isNullOrUndefined(event.item.Select)
        || event.item.Select.length == 0 || event.item.Value.trim().length == 0)) {

      //  alert("Please select both to proceed search")

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please select a column and value to proceed with Search.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null,
        timeout: 2000
      };

      this.displayNotifybox = true;

    }

    if (Module == 'CIS') {
      this.searchEvent = event;
      this.searchEvent.Module = 'CIS';
      event.item.UserId = this._appSharedService.UserRoleList[0].UserId;
      event.item.RoleId = "1";
      event.item.Module = this.searchEvent.Module;
    }
    if (Module == 'AM') {
      this.searchEvent = event;
      this.searchEvent.Module = 'AM';
      event.item.UserId = this._appSharedService.UserRoleList[0].UserId;
      event.item.RoleId = "1";
      event.item.Module = this.searchEvent.Module;
    }
    //this.searchEvent = event;

    // this.IsLoading = true;

    this.dealListData = [];
    this.dealListDataALL = [];
    this.dataTotalCount = 0;

    let odata = event.item;
    console.log("######")
    console.log(odata);
    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'CLEAR') {
      allPendingFlag = true;

      if (Module == 'AM') {
        odata = {
          "UserId": this._appSharedService.UserRoleList[0].UserId,
          "RoleId": "1",
          "Module": "AM"
        }
      }
      else {
        odata = {};
      }

    }

    let subscribe$: Subscription = this.homeService.getDealDetailsBySearch(odata, allPendingFlag).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (!isNullOrUndefined(result.Data) && result.Data.length > 0) {
          //   result.Data.forEach((item : any) => {
          //     item.Status = "Completed";
          //   });
          // }

          // result.Data.forEach((item: any) => {
          //   item.Action = '1';
          // });

          this.dealListData = result.Data;
          //console.log(this.dealListData);
          //this.inprogressdata = this.dealListData.filter((x: any) => x.DealStatusDesc === 'In Progress');
          //this.completeddata = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Completed');
          this.dataTotalCount = this.dealListData.length;
          this.dealListDataALL = this.dealListData;

        }
        /* if(this.selectedBtn == "Cancelled"){
          this.dataTotalCount=this.dealListData.filter((x: any) => x.DealStatusDesc === 'Cancelled').length;
          console.log()
        } */

        // this._toastr.success('Request stopped successfully', this.CompTitle);

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }

        }, 50);

      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  // AMDetails() {
  //   this.dealListData = [];
  //   this.dealListDataALL = [];
  //   this.dataTotalCount = 0;

  //   let odata = {



  //     "UserId": this._appSharedService.UserRoleList[0].UserId,

  //     "RoleId": "1",

  //     "Module": "AM"


  //   }



  //   let subscribe$: Subscription = this.homeService.getAMDetails(odata).subscribe(result => {
  //     if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

  //       // if(!isNullOrUndefined(result.Data) && result.Data.length > 0){
  //       //   result.Data.forEach((item : any) => {
  //       //     item.Status = "Completed";
  //       //   });
  //       // }

  //       // result.Data.forEach((item: any) => {
  //       //   item.Action = '1';
  //       // });

  //       this.dealListData = result.Data;
  //       //console.log(this.dealListData);
  //       //this.inprogressdata = this.dealListData.filter((x: any) => x.DealStatusDesc === 'In Progress');
  //       //this.completeddata = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Completed');
  //       this.dataTotalCount = this.dealListData.length;
  //       this.dealListDataALL = this.dealListData;


  //       /* if(this.selectedBtn == "Cancelled"){
  //         this.dataTotalCount=this.dealListData.filter((x: any) => x.DealStatusDesc === 'Cancelled').length;
  //         console.log()
  //       } */

  //       // this._toastr.success('Request stopped successfully', this.CompTitle);

  //       setTimeout(() => {
  //         if (!isNullOrUndefined(this.overallgrid)) {
  //           this.overallgrid.Pagination.CurrentPage = 1;
  //         }

  //       }, 50);

  //     }

  //     this.IsLoading = false;

  //   },

  //     err => {
  //       this.IsLoading = false;
  //     });

  //   this.subscriptionList$.push(subscribe$);
  // }

  // setCellLock(fieldname: string, item: any, parent: HomeComponent): boolean {
  //   let bLock = false;

  //   if (!isNullOrUndefined(parent.Config.BlockCols) && parent.Config.BlockCols.length > 0) {
  //     parent.Config.BlockCols.forEach((blockitem: any) => {
  //       if (blockitem.Value === item[blockitem.Field] && (!isNullOrUndefined(blockitem.BlockCols)
  //         && blockitem.BlockCols.length > 0 && blockitem.BlockCols.filter((x: any) => x === fieldname.toUpperCase()).length > 0)) {
  //         bLock = true;
  //       }
  //     });
  //   }

  //   /// any other conditions
  //   // if (item.OfferStatus === 'OF003' &&
  //   //   (fieldname.toUpperCase() =onClick= 'OFFERSTATUS' || fieldname.toUpperCase() === 'EXPECTEDDOJ')) {
  //   //   bLock = true;

  //   // }


  //   return bLock;
  // }

  actButtonClick(event: any): void {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;
    }
  }

  countClick(bttn: any): void {
    switch (bttn) {
      case 'All':
        this.dashbaord = true;
        this.selectedBtn = "Recent";
        this.dataTotalCount = this.dealListData.length;
        break;
      case 'onHold':

        this.dashbaord = false;
        this.selectedBtn = "On Hold";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'On Hold').length;
        break;
      case 'cancel':

        this.dashbaord = false;
        this.selectedBtn = "Cancelled";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Cancelled').length;
        break;
      case 'Inprogress':

        this.dashbaord = false;
        this.selectedBtn = "In Progress";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'In Progress').length;
        break;
      case 'complete':

        this.dashbaord = false;

        this.selectedBtn = "Completed";
        this.dataTotalCount = this.dealListData.filter((x: any) => x.DealStatusDesc === 'Completed').length;
        break;
    }

  }

  btnClick(butt: any): void {
    console.log(JSON.stringify(butt) + "button name");
    switch (butt.Name.toUpperCase()) {
      case 'CREATENEWDEAL':
        this._router.navigate(['app/create-deal']);

        break;
      case 'QUICKDEALUPLOAD':
        this._router.navigate(['app/am-downloads/quickdealupload']);

        break;

    }
  }

  exportToExcel() {

    if (this.dealListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = 'APP-Home ' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()


      this.Config.DownloadColumns = JSON.parse(`[{"FieldName":"DealId","SeqOrder":1},{"FieldName":"ThorId","SeqOrder":2},{"FieldName":"Client","SeqOrder":3},{"FieldName":"DealName","SeqOrder":4},{"FieldName":"Country","SeqOrder":5},{"FieldName":"Region","SeqOrder":6},{"FieldName":"DealStatusDesc","SeqOrder":7}]`);
      console.log(this.Config.DownloadColumns, "columns****", this.dealListData, "****", this.dealListTemplate);
      let tempdata = [];
      // tempdata.push({ Name: 'Search Data', Data: this.reqListData });

      tempdata.push({
        Name: 'Search Data',
        Data: this.dealListData,
        ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null,
        fldtemplate: this.dealListTemplate
      });

      // this.excelexport.generateExcelTable(tempdata);
      this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }

  getLoginId() {
    let email: string = '';
    email = this._appSharedService.UserInfo.email;
    let subscribe$: Subscription = this._commonAPIService.getUserRole(email).subscribe(async (result: any) => {
      let x = result.Data
      x.map((e: any) => {
        this._userId = e.UserId;

        console.log(this._userId, "***");
        return this._userId;

      })

    })
    this.subscriptionList$.push(subscribe$);



  }

  /*  getUserList(){
               let subscribe$: Subscription = this.homeService.GetUserDetails(this._userId).subscribe(result => {
               console.log(result.Data,"*********");
               if (!isNullOrUndefined(result) && result.Status == 200) {
                 let x= result.Data;
                 x.map((e:any)=>{
                 this.ShareDealData=e.CodeDescription
                 })
         
                 this.IsLoading = false;
               }
             },
         
               err => {
                 this.IsLoading = false;
               });
             this.subscriptionList$.push(subscribe$); 
 
   } */

  getDealCount(Module: any) {
    //this.getLoginId();
    let subscribe$: Subscription = this.homeService.GetDealStatusCount("4", Module).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status == 200) {
        let data = result.Data;
        (data).map((e: any) => {

          if (e.DealStatus == "-1") { this.onholdcount = e.Count; }
          if (e.DealStatus == "0") { this.cancelcount = e.Count; }
          if (e.DealStatus == "1") { this.progresscount = e.Count; }
          if (e.DealStatus == "2") { this.completedcount = e.Count; }


        })
      }
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  getUserList() {

    let subscribe$: Subscription = this.homeService.GetUserDetails(this.dealheaderid).subscribe(result => {

      if (!isNullOrUndefined(result) && result.Status == 200) {
        let x = result.Data;
        this.ShareDealData = result.Data;

        // x.map((e: any) => {
        //   this.ShareDealData.dealdes = e.CodeDescription
        //   this.ShareDealData.ShareDealCode = e.Code;
        //   this.ShareDealData.ShareDealDesc = e.CodeDescription;
        //   this.ShareDealData.Code = e.Code;
        //   this.ShareDealData.CodeDescription = e.CodeDescription;
        // })

        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  clonedeal() {
    let subscribe$: Subscription = this.homeService.ClnDeal(this.dealheaderid, this.ModuleName).subscribe(result => {
      if (!isNullOrUndefined(result)) {
        this.showNotificationMessage('Deal cloned successfully', 1, 'Success', '', '', 'ok', 1000);
        this.ngOnInit();
      }
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  ClnDeal() {
    let subscribe$: Subscription = this.homeService.ClnDeal(this.dealheaderid, this.ModuleName).subscribe(result => {
      if (!isNullOrUndefined(result)) {
        console.log("working......");
        //this.showNotificationMessage('Deal cloned successfully', 1, 'Success', '', '', 'ok',1000);
        //this.ngOnInit();
      }
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  onClick(event: any): void {
    console.log("******");
    this.clickData = {};
    let selitem = event.item;
    this.clickData = selitem;
    if (event.item.DealHeaderId != undefined) {
      this.dealheaderid = event.item.DealHeaderId;
    }

    //console.log(this.dealheaderid)
    console.log(event.fieldname);
    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        switch (event.icon.Value) {
          case '0':
            // Clone 
            if (this.ModuleName == 'AM') {
              this.CloneButtonClick();
            }
            else {
              //this.CloneButtonClick_CIS();
              this.showNotificationMessage('Are you sure you want to Clone the Deal?', 2, 'CLONEDEAL', selitem, 'Yes', 'No', 10000);
            }

            break;
          case '1':
            this.ShareDealData = [];
            this.ShareDealData.ShareDealCode = null;
            this.ShareDealData.DealHeaderId = this.dealheaderid;
            this.displayNotifybox = false;
            // this.getUserList();
            this.openModal('ShareDeal')
            break;
          case '2':
            // Cancel 
            this.showNotificationMessage('Are you sure you want to Cancel the Deal?', 2, 'CANCELDEAL', selitem, 'Yes', 'No', 10000);

            break;
          case '3':
            // On Hold 
            this.showNotificationMessage('Are you sure you want to On Hold the Deal?', 2, 'ONHOLD', selitem, 'Yes', 'No', 10000);

            break;
          case '4':
            // Resume 

            if (selitem.IsCISDealOld == '1') {
              this.openModal('OldCISCheck_resume');

              this.modalInf =
              {
                header: '',
                size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
                width: 'auto',
                height: 'auto'
              };
            }
            else {
              this.showNotificationMessage('Are you sure you want to Resume the Deal?', 2, 'RESUMEDEAL', selitem, 'Yes', 'No', 10000);
            }


            break;
          case '5':
            this.DownloadExcel(selitem.DealId);
            break;
          case '6':

            this.showNotificationMessage('Do you want to delete the Record ??', 2, 'DELETEDEAL', selitem, 'Yes', 'No', 10000);
            // this.DeleteDeal(selitem,this.ModuleName);
            break;
          case '7':
            if (this.ModuleName == 'AM') {
              this.showNotificationMessage('Do you want to delete the Record ??', 2, 'DELETEDEAL', selitem, 'Yes', 'No', 10000);
            }
            else {
              this._appSharedService.currentDealData = selitem;
              this._appSharedService.currentDealData.openFromHome = true;
              this._router.navigate([`app/create-deal/${this.dealheaderid}`], { state: { dealData: selitem } });
            }
        }
        break;
      case 'DEALNAME':
        if (event.item.ProjectApproach != undefined && event.item.DealStatusDesc != undefined) {
          this._appSharedService.currentDealData = selitem;
          this._appSharedService.currentDealData.openFromHome = true;
          this._router.navigate([`app/create-deal/${this.dealheaderid}`], { state: { dealData: selitem } });
        }
        break;
      case 'DEALID':
        this._appSharedService.currentDealData = selitem;

        if (this.ModuleName != 'CIS') {
          console.log(selitem);
          this._appSharedService.currentDealData.openFromHome = true;
          this._router.navigate([`app/create-deal/${this.dealheaderid}`], { state: { dealData: selitem } });
        }

        if (this.ModuleName == 'CIS') {
          this.CISDatabaseDealUpdateComments = '';
          this.Dealstatus = selitem.DealStatus;
          this.createdBy = selitem.CreatedBy;
          this.CISDatabaseDealUpdateComments = selitem.CISDatabaseDealUpdateComments;
          if (selitem.IsCISDealOld == '1') {
            switch (selitem.DealStatus) {
              case '1':
                this.openModal('OldCISCheck');

                this.modalInf =
                {
                  header: '',
                  size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
                  width: 'auto',
                  height: 'auto'
                };
                break;
              case '2':
                this.openModal('OldCISCheck');

                this.modalInf =
                {
                  header: '',
                  size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
                  width: 'auto',
                  height: 'auto'
                };
                break;

              case '-1':
                this.openModal('OldCISCheck');

                this.modalInf =
                {
                  header: '',
                  size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
                  width: 'auto',
                  height: 'auto'
                };
                break;
              case '0':
                this._appSharedService.currentDealData.openFromHome = true;
                this._router.navigate([`app/create-deal/${this.dealheaderid}`], { state: { dealData: selitem } });
                break;
            }
          }
          else {
            this._appSharedService.currentDealData.openFromHome = true;
            this._router.navigate([`app/create-deal/${this.dealheaderid}`], { state: { dealData: selitem } });
          }
        }

        break;
      case 'SHAREDEALCODE':

        break;

    }
  }

  onChange(event: any): void {
    let selitem: any = event.item;
    this.dealitem = event.item.ShareDealCode;
    console.log(event.item.ShareDealCode)
    // Emitting onchange event of resource plan to parent container
    selitem.IsModified = 1;
    selitem.IsRowHighlight = selitem.IsModified;

    switch (event.fieldname.toUpperCase()) {
      case 'STATUS':
        if ((isNullOrUndefined(selitem.OfferStatus) || selitem.OfferStatus === '') && !isNullOrUndefined(selitem.OldOfferStatus) && selitem.OldOfferStatusDesc !== '') {
          setTimeout(() => {
            selitem.Status = selitem.OldStatus
            selitem.StatusDesc = selitem.OldStatusDesc
            selitem.IsModified = 0;
            selitem.IsRowHighlight = selitem.IsModified;
          }, 50);
        }
        break;
      case 'SHAREDEALCODE':
        if (isNullOrUndefined(selitem.ShareDealCode != '' && selitem.ShareDealCode != null)) {
          setTimeout(() => {
            this.ShareDealData = selitem;
          }, 50);
        }
        break;

      case 'MODELBASELINING':
        if (event.item.ModelBaselining == 'CHKYES') {
          this.SelectedCloneDetailsData.LotSolutionEffortModelling = 'CHKYES';
          this.LotSolutionEffortModelling.value = 'CHKYES';
          this.SelectedCloneDetailsData.PricingModeLabourCosts = 'CHKYES';
          this.PricingModeLabourCosts.value = this.SelectedCloneDetailsData.PricingModeLabourCosts;
          this.SelectedCloneDetailsData.CapgeminiSolutionOtherCosts = 'CHKYES';
          this.CapgeminiSolutionOtherCosts.value = this.SelectedCloneDetailsData.CapgeminiSolutionOtherCosts;
          this.SelectedCloneDetailsData.ClientCosts = 'CHKYES';
          this.ClientCosts.value = this.SelectedCloneDetailsData.ClientCosts;
        } else if (event.item.ModelBaselining == 'CHKNO') {
          this.SelectedCloneDetailsData.LotSolutionEffortModelling = 'CHKNO';
          this.LotSolutionEffortModelling.value = this.SelectedCloneDetailsData.LotSolutionEffortModelling;
          this.SelectedCloneDetailsData.PricingModeLabourCosts = 'CHKNO';
          this.PricingModeLabourCosts.value = this.SelectedCloneDetailsData.PricingModeLabourCosts;
          this.SelectedCloneDetailsData.CapgeminiSolutionOtherCosts = 'CHKNO';
          this.CapgeminiSolutionOtherCosts.value = this.SelectedCloneDetailsData.CapgeminiSolutionOtherCosts;
          this.SelectedCloneDetailsData.ClientCosts = 'CHKNO';
          this.ClientCosts.value = this.SelectedCloneDetailsData.ClientCosts;
        }
        break;

      case 'LOTSOLUTIONEFFORTMODELLING':
        if (event.item.LotSolutionEffortModelling == 'CHKYES') {
          this.SelectedCloneDetailsData.ModelBaselining = 'CHKYES';
          this.ModelBaselining.value = this.SelectedCloneDetailsData.ModelBaselining;
        } else if (event.item.LotSolutionEffortModelling == 'CHKNO') {
          this.SelectedCloneDetailsData.PricingModeLabourCosts = 'CHKNO';
          this.PricingModeLabourCosts.value = this.SelectedCloneDetailsData.PricingModeLabourCosts;
          this.SelectedCloneDetailsData.CapgeminiSolutionOtherCosts = 'CHKNO';
          this.CapgeminiSolutionOtherCosts.value = this.SelectedCloneDetailsData.CapgeminiSolutionOtherCosts;
        }
        break;

      case 'PRICINGMODELABOURCOSTS':
        if (event.item.PricingModeLabourCosts == 'CHKYES') {
          this.SelectedCloneDetailsData.ModelBaselining = 'CHKYES';
          this.ModelBaselining.value = this.SelectedCloneDetailsData.ModelBaselining;
          this.SelectedCloneDetailsData.LotSolutionEffortModelling = 'CHKYES';
          this.LotSolutionEffortModelling.value = this.SelectedCloneDetailsData.LotSolutionEffortModelling;
        }
        break;

      case 'CAPGEMINISOLUTIONOTHERCOSTS':
        if (event.item.CapgeminiSolutionOtherCosts == 'CHKYES') {
          this.SelectedCloneDetailsData.ModelBaselining = 'CHKYES';
          this.ModelBaselining.value = this.SelectedCloneDetailsData.ModelBaselining;
          this.SelectedCloneDetailsData.LotSolutionEffortModelling = 'CHKYES';
          this.LotSolutionEffortModelling.value = this.SelectedCloneDetailsData.LotSolutionEffortModelling;
        }
        break;

      case 'CLIENTCOSTS':
        if (event.item.ClientCosts == 'CHKYES') {
          this.SelectedCloneDetailsData.ModelBaselining = 'CHKYES';
          this.ModelBaselining.value = this.SelectedCloneDetailsData.ModelBaselining;
        }
        break;

      case 'COUNTRY':

        let controlArray: any = [];
        controlArray = this.cloneDealFormTemplate[2].Control.List;
        let filteredcountryCode = controlArray.filter((x: any) => x.Code == event.item.Country);
        console.log(filteredcountryCode);
        console.log(this.SelectedCloneDetailsData)
        this.SelectedCloneDetailsData.Currency = filteredcountryCode[0].UserDef1;
        break;

      case 'DEALCLONE':
        this.dealclone = event.item.DealClone;
        break;

      case 'DEALCLOSE':
        this.dealclose = event.item.DealClose;
        break;
    }
  }

  setActionButtonPermissions(): void {
    if (this.ModuleName == 'CIS') {
      if (!isNullOrUndefined(this.Config.ActionButtons) && this.Config.ActionButtons.length > 0) {
        this.Config.ActionButtons.forEach((item: any) => {
          if (!isNullOrUndefined(item.Name) && item.Name !== '') {
            item.HasPermission = false;
            item.IsApplicable = false;
            let peritem = this.SearchPermission.indexOf(item.Name);
            if (peritem >= 0) {
              item.HasPermission = true;
              item.IsApplicable = true;
            }
          }
        });
      }
    }
    if (this.ModuleName == 'AM') {
      if (!isNullOrUndefined(this.Config.AMActionButtons) && this.Config.AMActionButtons.length > 0) {
        this.Config.AMActionButtons.forEach((item: any) => {
          if (!isNullOrUndefined(item.Name) && item.Name !== '') {
            item.HasPermission = false;
            item.IsApplicable = false;
            let peritem = this.SearchPermission.indexOf(item.Name);
            if (peritem >= 0) {
              item.HasPermission = true;
              item.IsApplicable = true;
            }
          }
        });
      }
    }

  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
    //this.displayShareDealNotifybox = true;
  }

  validateMandatoryFields(modifieditems: any[]): boolean {
    let valid: boolean = true;

    if (isNullOrUndefined(modifieditems) || modifieditems.length === 0) {
      this.showNotificationMessage('No rows are modified.', 2, '', null, '', '', 1000);
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems) && modifieditems.length > 0) {
      let OfferStatusList = modifieditems.filter(x => ((isNullOrUndefined(x.OfferStatus)) || x.OfferStatus === ''));

      if (!isNullOrUndefined(OfferStatusList) && OfferStatusList.length > 0) {
        this.showNotificationMessage('Please select offer status for modified rows.', 2, '', null, '', '', 1000);
        valid = false;
      }

    }

    return valid;
  }

  /// Filter related functions ///
  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.dealListData = asEnumerable(this.dealListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.dealListData.length;
    }
    else {
      this.dealListData = this.dealListDataALL;
      this.dataTotalCount = this.dealListData.length;
    }
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;

    switch (event.action.toUpperCase()) {
      case 'ONHOLD':
        if (event.result.toUpperCase() === 'YES') {
          this.updateDealStatus(event.item, '-1');
        }
        break;
      case 'CANCELDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this.updateDealStatus(event.item, '0');
        }
        break;
      case 'RESUMEDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this.updateDealStatus(event.item, '1');
        }
        break;
      case 'CLONEDEAL':
        if (event.result.toUpperCase() === 'YES') {
          this.clonedeal();

        }
        break;
      case 'DELETEDEAL':
        if (event.result.toUpperCase() === 'YES') {
          //  this.clonedeal();
          this.DeleteDeal(this.clickData, this.ModuleName);

        }
        break;

      case 'CLONEDEALAMALERT':
        if (event.result.toUpperCase() === 'YES') {
          this.saveCloneDealAM();
        }
        break;

      case 'CLONEDEALAM':
        if (event.result.toUpperCase() === 'OK') {
          this.closeModal('DealReplica');
          this._router.navigate(["app/home"]);
          this.ngOnInit();
        }
        break;



    }
    if (canClose) {
      this.closeNotifybox();
      this.closeShareDealNotifybox();
    }
  }

  closeShareDealNotifybox(): void {
    this.displayShareDealNotifybox = false;
    this.ShareDealData = [];
    // this.ShareDealData.ShareDealCode = null;
    // this.ShareDealData.ShareDealDesc = null;
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  openModal(id: string) {
    this.modalService.open(id);

    //this.getUserList();

  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  updateDealStatus(dealItem: any, dealStatus: string) {

    this.IsLoading = true;

    let odata: any = {
      DealHeaderId: dealItem.DealHeaderId,
      DealStatus: dealStatus,
    };
    let subscribe$: Subscription = this.homeService.updateDealStatus(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Successfully Updated.', 1, '', null, '', '', 1000);

        // dealItem.DealStatus = dealStatus;
        // dealItem.DealStatusDesc = 'On Hold';

        this.SearchData(this.searchEvent, true, false, 'CIS');
        this.getDealCount('CIS');

        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  validateShareDeal(): void {
    if (!isNullOrUndefined(this.ShareDealData) && !isNullOrUndefined(this.ShareDealData.ShareDealCode) && this.ShareDealData.ShareDealCode !== '') {
      this.updateShareDeal(this.dealheaderid, this.ShareDealData.ShareDealCode, this.ModuleName);

    } else {
      this.showShareDealNotificationMessage('Please select the deal.', 2, '', null, '', '', 1000);
    }

  }

  showShareDealNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayShareDealNotifybox = true;
  }

  updateShareDeal(id: any, dealItem: any, Module: any) {

    this.IsLoading = true;

    let subscribe$: Subscription = this.homeService.updateShareDeal(id, dealItem, Module).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Deal shared successfully', 1, '', null, '', '', 1000);
        this.IsLoading = false;
        this.closeModal('ShareDeal');
      }
    },

      err => {
        this.IsLoading = false;
        this.closeModal('ShareDeal');
      });
    this.subscriptionList$.push(subscribe$);
  }

  GetCloneDealAM(id: any, Module: any) {
    this.IsLoading = true;
    let subscribe$: Subscription = this.homeService.GetCloneDealAM(id, Module).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.CloneDetailsData = result.Data;
        this.SelectedCloneDetailsData = result.Data[0];
        this.DealName = this.SelectedCloneDetailsData.DealName;
        this.SelectedCloneDetailsData.DealHeaderId = this.dealheaderid;
        this.SelectedCloneDetailsData.Module = this.ModuleName;
        this.SelectedCloneDetailsData.IsEditable = 1;
        this.dataTotalCount = this.CloneDetailsData.length;
      }
      this.IsLoading = false;
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }





  ////////CIS/////////
  // GetCloneDealCIS(id: any, Module: any) {
  //   this.IsLoading = true;
  //   let subscribe$: Subscription = this.homeService.GetCloneDealCIS(id, Module).subscribe(result => {
  //     if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
  //       this.CloneDetailsData_CIS = result.Data;
  //       this.SelectedCloneDetailsData_CIS = result.Data[0];
  //       console.log(this.SelectedCloneDetailsData_CIS);
  //       this.DealName = this.SelectedCloneDetailsData_CIS.DealName;
  //       this.SelectedCloneDetailsData_CIS.DealHeaderId = this.dealheaderid;
  //       this.SelectedCloneDetailsData_CIS.Module = this.ModuleName;

  //      // this.dataTotalCount = this.CloneDetailsData_CIS.length;
  //     }
  //     this.IsLoading = false;
  //   },

  //     err => {
  //       this.IsLoading = false;
  //     });
  //   this.subscriptionList$.push(subscribe$);
  // }
  DownloadExcel(DealId: any) {
    this.IsLoading = true;

    let odata = {
      DealHeaderId: this.dealheaderid,
    }
    let subscribe$: Subscription = this.homeService.DownloadExcel(odata).subscribe(res => {
      let date: any = new Date();
      let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
      console.log('TCV' + '_' + DealId + '_' + latest_date)
      this.downloadFile(res, 'TCV' + '_' + DealId + '_' + latest_date);


      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  CloneButtonClick() {

    this.openModal('DealReplica');
    this.GetCloneDealAM(this.dealheaderid, this.ModuleName);
    this.modalInf =
    {
      header: 'Clone Deal',
      size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
      width: 'auto',
      height: 'auto'
    };
  }
  ///CIS///
  // CloneButtonClick_CIS() {

  //   this.openModal('DealReplicaCIS');
  //   this.GetCloneDealCIS(this.dealheaderid, this.ModuleName);
  //   this.modalInf =
  //   {
  //     header: 'Clone Deal',
  //     size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
  //     width: 'auto',
  //     height: 'auto'
  //   };
  // }

  validateDealReplica(modifieditems: any): boolean {
    let valid: boolean = false;

    if (isNullOrUndefined(modifieditems)) {
      this.showNotificationMessage('All fields are mandatory', 2, '', null, '', '', 1000);
      valid = false;
    }

    if (!isNullOrUndefined(modifieditems)) {
      if ((isNullOrUndefined(modifieditems.Client)) || (isNullOrUndefined(modifieditems.Country)) || (isNullOrUndefined(modifieditems.Currency)) || (isNullOrUndefined(modifieditems.DealName)) || (isNullOrUndefined(modifieditems.DealTerm)) || (isNullOrUndefined(modifieditems.RateModellingOption))) {
        this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
        valid = false;
      }
      else {
        valid = true;
      }
    }
    return valid
  }

  SubmitCloneDealAM() {
    this.showNotificationMessage('Are you sure to create clone for Deal ' + this.DealName + ' ?', 2, 'CLONEDEALAMALERT', '', 'Yes', 'No', 10000);
  }

  saveCloneDealAM() {
    if (this.validateDealReplica(this.SelectedCloneDetailsData)) {
      let subscribe$: Subscription = this.homeService.saveCloneDealAM(this.SelectedCloneDetailsData).subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          this.SaveDealReplicaData = result.Data;
          this.showNotificationMessage('Clone Created Successfully for ' + this.DealName, 1, 'CLONEDEALAM', '', 'OK', '', 10000);
        }
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting saveCloneDealAM');
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }
  }

  cancelCloneDeal() {
    this.closeModal('DealReplica');
  }

  cancelCISOldDeal() {
    this.closeModal('OldCISCheck');
  }
  saveCISOldDealChange() {


    let odata: any = {
      DealHeaderId: this.dealheaderid,
      DealCloseInput: this.dealclose,
      DealCloneInput: this.dealclone,
      UserId: this._appSharedService.UserRoleList[0].UserId,
      Module: this.ModuleName,

    };
    if (!isNullOrUndefined(this.dealclose) && !isNullOrUndefined(this.dealclone) && this.dealclone != '' && this.dealclose != '') {
      this.CISCloseAndCloneDeal(odata);

    }
    else {
      this.showNotificationMessage('Please fill required fields', 2, '', null, '', '', 5000);
      //this._toastr.error('Request stopped successfully');
    }

  }


  // downloadFile(data: Blob) {
  //   const contentType = 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet';
  //   const blob = new Blob([data], { type: contentType });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
  public downloadFile(response: any, fileName: string) {
    fileName = fileName;
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');

  }
  //////AM DASHBORAD DELETE DEAL/////

  deleteconfirm(dealItem: any, Module: any) {
    this.DeleteDeal(dealItem, Module);
  }

  DeleteDeal(dealItem: any, Module: any) {

    this.IsLoading = true;

    let odata: any = {
      DealHeaderId: dealItem.DealHeaderId,
      Module: Module,
      UserId: this._appSharedService.UserRoleList[0].UserId,
    };
    let subscribe$: Subscription = this.homeService.DeleteDeal(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        this.showNotificationMessage('Successfully Deleted.', 1, '', null, '', '', 1000);
        this.ngOnInit();

        this.displayNotifybox = true;

      }
      // dealItem.DealStatus = dealStatus;
      // dealItem.DealStatusDesc = 'On Hold';

      // this.SearchData(this.searchEvent, true, false, 'CIS');
      // this.getDealCount('CIS');

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }




  setCellLock(fieldname: string, item: any, parent: HomeComponent): boolean {

    let bLock = false;
    let BlkCols: any[] = [];


    return bLock;

  }


  CISCloseAndCloneDeal(odata: any) {
    this.IsLoading = true;


    let subscribe$: Subscription = this.homeService.CISCloseAndCloneDeal(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        if (this.dealclone == "CHKNO" && this.dealclose == "CHKYES") {
          this.showNotificationMessage('Deal closed successfully', 1, '', null, '', '', 1000);
          this.SelectedCloneDetailsData_CIS = [];
          this.SelectedCloneDetailsData_CIS.IsEditable = 1;
        }
        else {
          this.showNotificationMessage('Deal closed and cloned successfully', 1, '', null, '', '', 1000);
          this.SelectedCloneDetailsData_CIS = [];
          this.SelectedCloneDetailsData_CIS.IsEditable = 1;
        }


        this.closeModal('OldCISCheck');
        this.closeModal('OldCISCheck_resume');
        this.IsLoading = false;

        /////get api////

        let subscribe$: Subscription = this.homeService.getDealDetailsBySearch(odata, '').subscribe(result => {
          if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

            if (!isNullOrUndefined(result.Data) && result.Data.length > 0) {

              this.dealListData = result.Data;

              this.dataTotalCount = this.dealListData.length;
              this.dealListDataALL = this.dealListData;
              this.getDealCount('CIS');
              this.closeModal('OldCISCheck');
              this.closeModal('OldCISCheck_resume');

            }

            // setTimeout(() => {
            //   if (!isNullOrUndefined(this.overallgrid)) {
            //     this.overallgrid.Pagination.CurrentPage = 1;
            //   }

            // }, 50);
            this.IsLoading = false;
            this.closeModal('OldCISCheck');
            this.closeModal('OldCISCheck_resume');
          }
        },

          err => {
            this.IsLoading = false;
            //  this.closeModal('ShareDeal');
          });
        this.subscriptionList$.push(subscribe$);





        this.closeModal('OldCISCheck');
        this.closeModal('OldCISCheck_resume');
      }
    },

      err => {
        this.IsLoading = false;
        //  this.closeModal('ShareDeal');
      });
    this.subscriptionList$.push(subscribe$);
  }

}