<!-- <ng-container *ngIf="GraphName=='ModelSolutionStaffingView'">
    
</ng-container>  -->



<!-- 
      <ng-container *ngIf="Graphtype =='BARGRAPH'">
        <div id={{graphPlotid}} style="width: 100%; height: 10px" #chartElement></div>
      </ng-container>
      <ng-container *ngIf="Graphtype =='LINEGRAPH'">
        <div id={{graphPlotid}} style="width: 100%; height: 10px" #chartElement></div>
      </ng-container>
      <ng-container *ngIf="Graphtype =='STACKEDBARGRAPH'">
        <div id={{graphPlotid}} style="width: 100%; height: 10px" #chartElement></div>
      </ng-container> -->
<!-- <ng-container *ngIf="ReportType == 'AMRT001'"> -->
<ng-container *ngIf="ModuleName!='modelcomparison'&& ModuleName!='PastDealComparison'&& ModuleName!='whatifswithadmt'" >
  <div  id="chartdiv" class="P2WCharts"></div>
</ng-container>




<div  *ngIf="ModuleName=='modelcomparison'"class="card bg-white mb-0">
  <div class="card-header rounded-1 text-center pt-2">
      <h5>
          <span>{{graph_DATAHEADING | translate}}</span>
      </h5>
  </div>
  <div class="card-body p-1">
    <div id="chartdivmodelcomparison" class="P2WCharts"></div>
  </div>
</div>



<div  *ngIf="ModuleName=='PastDealComparison'"class="card bg-white mb-0">
  <!-- <div class="card-header rounded-1 text-center pt-2">
      <h5>
          <span>{{graph_DATAHEADING | translate}}</span>
      </h5>
  </div> -->
  <div class="card-body p-1">
    <div id="chartpdcomparison" class="P2WCharts"></div>
  </div>
</div>

<div  *ngIf="ModuleName=='whatifswithadmt'"class="card bg-white mb-0">
  <div class="card-header rounded-1 text-center pt-2">
      <h5>
          <span>{{graph_DATAHEADING | translate}}</span>
      </h5>
  </div> 
  <div class="card-body p-1">
    <div id="chartdiv_ADMTthirdreport" class="P2WCharts"></div>
  </div>
</div>



<!-- </ng-container> -->

<!-- <div [attr.id]=GraphName class="P2WCharts"></div> -->

<!-- <ng-container *ngIf="ReportType != 'AMRT001'">
  <div [id]="GraphName"></div>
</ng-container> -->

<!-- <ng-container *ngIf="Graphtype =='LINEGRAPH'">
        <div id={{GraphName}} style="width: 100%; height: 10px"></div>
      </ng-container>

      
      <ng-container *ngIf="Graphtype =='STACKEDBARGRAPH'">
        <div id={{GraphName}} style="width: 100%; height: 10px"></div>
      </ng-container> -->

<!-- <app-charts #TowerGraphData [graphData]="TowerGraphData"></app-charts> -->