export class ReportFormDetails {
    SBU: any = null;
    CapgeminiSectors: any = null;
    DealCreatedDateFrom: any = null;
    DealCreatedDateTo: any = null;
    DealStatus: any = null;
    DealName: any = null;
    ModelIdDesc: any = null;
    ReportType: any = null;
    Currency: any = null;
    IsEditable: number = 1;
    MasterData: any = null;
    ModelId: any;
    MasterDataDesc: any = null;;
}
export class ChartDetails {
    XFieldName: string = '';
    YFieldName: string = '';
    GraphHeading: string = '';
    ChartOutput: any = [];
}