import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse, ReportModel } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class AMReportsService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private ReportUrl: string;
  private ServiceUrl: string;
  constructor(private _http: HttpClient) {
    this.ReportUrl = environment.apiURL.ReportURL;
    this.ServiceUrl = environment.apiURL.ServiceTypeURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  //   getMasterReportDetails(odata: any): Observable<ProcessResponse<any>> {
  //     //let Params = new HttpParams();

  //     return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'GetMasterReportDetails', odata,
  //       { headers: this.headers })
  //       .pipe(
  //         catchError(this.handleError)
  //       );
  //   }
  GetModelSolutionStaffingView(odata: any) {

    let Params = new HttpParams();
    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('ModelId', odata.ModelId);
    Params = Params.append('ReportType', odata.ReportType);
    Params = Params.append('OutputType', odata.OutputType);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetModelSolutionStaffingView',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }


  GetLocationRatioReport(odata: any) {

    let Params = new HttpParams();
    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('ModelId', odata.ModelId);
    Params = Params.append('ReportType', odata.ReportType);
    Params = Params.append('OutputType', odata.OutputType);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetLocationRatio',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }

  GetProductivityReport(odata: any) {

    let Params = new HttpParams();
    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('ModelId', odata.ModelId);
    Params = Params.append('ReportType', odata.ReportType);
    Params = Params.append('OutputType', odata.OutputType);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetProductivityReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }

  GetPyramidViewReport(odata: any) {

    let Params = new HttpParams();
    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('ModelId', odata.ModelId);
    Params = Params.append('ReportType', odata.ReportType);
    Params = Params.append('OutputType', odata.OutputType);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetPyramidViewReport',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }

  DownloadExcel(odata: any) {
    let Params = new HttpParams();

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetExportedData',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  GetReportDetails(odata: any, APIName: string, module: string) {

    let Params = new HttpParams();

    if (module === ReportModel.Solution_summary) {
      Params = Params.append('DealName', odata.DealName);
      Params = Params.append('ModelId', odata.ModelId);
      Params = Params.append('ReportType', odata.ReportType);
      Params = Params.append('TabName', odata.TabName);
    } 
    else if (module === ReportModel.Pricing_summary) {
      Params = Params.append('DealName', odata.DealName);
      Params = Params.append('ModelId', odata.ModelId);
      Params = Params.append('ReportType', odata.ReportType);
      Params = Params.append('Currency', odata.Currency);
      Params = Params.append('TabName', odata.TabName);
    }
    else if (module === ReportModel.Business_summary) {
      Params = Params.append('DealName', odata.DealName);
      Params = Params.append('ModelId', odata.ModelId);
   
      Params = Params.append('Currency', odata.Currency);
      Params = Params.append('COLAYear2', odata.COLAYear2);
      Params = Params.append('COLAYear3', odata.COLAYear3);
      Params = Params.append('COLAYear4', odata.COLAYear4);
      Params = Params.append('COLAYear5', odata.COLAYear5);
      Params = Params.append('COLAYear6', odata.COLAYear6);
      Params = Params.append('COLAYear7', odata.COLAYear7);
  

    }
    else if (module === ReportModel.modelcomparison) {
      Params = Params.append('Currency', odata.Currency);
      Params = Params.append('DealName', odata.DealName);
    
    }
    else if (module === ReportModel.whatifswithmodel) {
      Params = Params.append('Currency', odata.Currency);
      Params = Params.append('DealName', odata.DealName);
    
    }

    return this._http.get<ProcessResponse<any>>(this.ReportUrl + APIName,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }


  GetRevenueComparison(odata: any) {

    let Params = new HttpParams();
    Params = Params.append('DealName', odata.DealName);
    Params = Params.append('Currency', odata.Currency);
    Params = Params.append('ModelIdSelectedList', odata.ModelIdSelectedList);
    console.log("****");
    console.log(odata);
    console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetModelComparisonSummary',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );

  }


  GetWhatifsModelSummary(odata: any) {

    // let Params = new HttpParams();
    // Params = Params.append('DealName', odata.DealName);
    // Params = Params.append('Currency', odata.Currency);
    // Params = Params.append('ModelIdSelectedList', odata.ModelIdSelectedList);

    console.log("****");
    console.log(odata);
   // console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetWhatifsModelSummary',
      { headers: this.headers, params: odata })
      .pipe(
        catchError(this.handleError)
      );

  }

  GetSolutionSummaryCountryFTEPopup(odata: any) {

    // let Params = new HttpParams();
    // Params = Params.append('DealName', odata.DealName);
    // Params = Params.append('Currency', odata.Currency);
    // Params = Params.append('ModelIdSelectedList', odata.ModelIdSelectedList);

    console.log("****");
    console.log(odata);
   // console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetSolutionSummaryCountryFTE',
      { headers: this.headers, params: odata })
      .pipe(
        catchError(this.handleError)
      );

  }

  GetPriceSummaryCountryPrice(odata:any){
    
    console.log("****");
    console.log(odata);
   // console.log(Params);
    return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetPriceSummaryCountryPrice',
      { headers: this.headers, params: odata })
      .pipe(
        catchError(this.handleError)
      );
  }



  
  InsertWhatifswithModelTransactionLog(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(this.ReportUrl + 'InsertWhatifswithModelTransactionLog', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
////past deal comparison///////////


GetDisplayDealsInPastDealComparison(odata:any){
    
  console.log("****");
  console.log(odata);
 // console.log(Params);
  return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetDisplayDealsInPastDealComparison',
    { headers: this.headers, params: odata })
    .pipe(
      catchError(this.handleError)
    );
}



GetPastDealComparisonSummary(odata:any){
    
  console.log("****");
  console.log(odata);
 // console.log(Params);
  return this._http.get<ProcessResponse<any>>(this.ReportUrl + 'GetPastDealComparisonSummary',
    { headers: this.headers, params: odata })
    .pipe(
      catchError(this.handleError)
    );
}


GetWhatifswithModelExcel(odata:any){
    
  console.log("****");
  console.log(odata);
  let Params = new HttpParams();

  Params = Params.append('Currency', odata.Currency);
  Params = Params.append('ModelIdSelectedList', odata.ModelIdSelectedList);
  // return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetWhatifswithModelExcel',
  //   { headers: this.headers, params: odata })
  //   .pipe(
  //     catchError(this.handleError)
  //   );

    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };
  
    return this._http.get<ProcessResponse<any>>(this.ServiceUrl + 'GetWhatifswithModelExcel',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );


}





}


