import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class DealService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
  private HomeURL: string;
  private DealURL: string;

  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.HomeURL = environment.apiURL.HomeURL;
    this.DealURL = environment.apiURL.DealURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  insertorUpdateAMDeals(odata: any): Observable<ProcessResponse<any>> {   
  let Params = new HttpParams();
  //Params = Params.append('DealHeaderId', 0);

  return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertorUpdateAmDeals', odata,
    { headers: this.headers, params: Params })
    .pipe(
      catchError(this.handleError)
    );
}

  InsertorUpdateDeals(odata: any): Observable<ProcessResponse<any>> {
      console.log(odata,'saveddata$$$$$$$$$$$$$$')
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertUpdateDeal', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAmDealProgressBarPer(dealHeaderId: number, Module:any, Approach: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('Module', Module);
    Params = Params.append('Approach', Approach);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmDealProgressBarPer',
      { headers: this.headers, params: Params });
    return response;
  }

  getDealDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDealDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAmDealDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmDealDetails',
      { headers: this.headers, params: Params });
    return response;
  }



  GetToolService(odata:any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('codeType', '');
    Params = Params.append('CostPer', odata.CostPer);
    Params = Params.append('CostPerManually', odata.CostPerManually);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetToolService',
      { headers: this.headers, params: Params });
    return response;
  }

}
