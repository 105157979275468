<ng-container *ngIf="!LandingPage">
    <ng-container *ngIf="(this._appSharedService.UserInfo != null && this._appSharedService.UserInfo != undefined 
    && this._appSharedService.UserInfo.email.length > 0)">

        <!-- Navbar Starts Here -->

        <!-- Header Section -->
        <nav id="topNav" class="navbar navbar-expand-md fixed-top ps-0">
            <!-- Logo Section -->

            <!-- hamburger Icon button -->
            <button class="btn sidebar-container border-0 rounded-0" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" (click)="onSideBarButtonclick()">
                <span><img src="assets/images/hamburger.svg" style="width: 48px; height: 48px;"></span>
            </button>
            <!-- Brand -->
            <!-- <a class="navbar-brand" routerLink="home"><img [src]="ImagePath" /></a> -->
            <a class="navbar-brand ps-4 ms-3" aria-label="Capgemini Logo Redirect to Home Page"
                href="javascript:this.blur();" (click)="onlogoclick()">
                <img src="assets/images/P2WLogo.png" alt="Redirect to Home Page" />
                <ng-container *ngIf="IsCIS">
                    <p class="ps-4"><span>CIS</span> Price Benchmarking</p>
                </ng-container>
                <!--  -->
            </a>

            <!-- Toggler/collapsibe Button -->
            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-target="#navbar-collapse-1"
                aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>


            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="navbar-collapse-1">

                <!-- Title -->
                <!-- <a class="navbar-brand ms-auto" aria-label="Application Name" href="javascript:this.blur();"
                    routerLink="home"><h1 class="d-inline-block">{{ 'Application Name' | translate }}</h1></a> -->

                <!-- Links -->
                <ul class="navbar-nav ms-auto">

                </ul>

                <!-- User Profile -->
                <ul class="navbar-nav userProfile ms-auto">
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="javascript:this.blur();" role="button" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="true">
                          <i class="fa fa-language"></i>{{translate.currentLang}}
                          <i class="fa fa-caret-down ml-2"></i>
                        </a>
                        
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li *ngFor="let lang of translate.getLangs()">
                            <a class="dropdown-item" (click)="onLanguageClick(lang)">{{ lang }}</a>
                          </li>
                        </ul>
                    </li> -->

                    <li class="nav-item dropdown">
                        <h2 class="sr-only">User Navigation</h2>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <!-- <a class="navbar-brand ms-auto ms-auto" href="index.html">Background Verification</a> -->
                            <!--   <ul class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript:this.blur();" role="button" data-toggle="modal"
                                        data-target="benchmark"><i class="fa fa-home" aria-hidden="true"></i><span>Benchmark
                                            Guidelines</span></a>
                                </li>
                            </ul> -->
                            <ul class="navbar-nav ms-auto" *ngIf="IsCIS">
                                <a href="#" routerLink="/app/cis-home-page" class="nav-link">
                                    <img alt="User Avatar" src="assets/images/t7.png"
                                        class="img-avatar img-avatar32 align-self-center mx-1" />
                                    <span>Home</span>
                                </a>
                            </ul>

                            <ul class="navbar-nav ms-auto">
                                <!-- <li class="nav-item dropdown" role="button" *ngIf="false" -->
                                <li class="nav-item dropdown" *ngIf="_appSharedService.UserModuleList.length > 1">

                                    <a class="nav-link dropdown-toggle"
                                        [attr.aria-label]="'Switch Role [' + (_appSharedService?.currentUserModuleData?.CodeDescription) + ']'"
                                        href="javascript:this.blur();" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                        id="{{fieldRandomId}}-{{'Switch Module [' + (_appSharedService.currentUserModuleData.CodeDescription) + ']'}}"
                                        role="button">
                                        {{'Switch Module [' + (_appSharedService.currentUserModuleData.CodeDescription)
                                        +
                                        ']'}}
                                        <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                                    </a>

                                    <ul class="dropdown-menu">
                                        <ng-container
                                            *ngFor="let roleData of _appSharedService.UserModuleList; let i = index">
                                            <li>
                                                <a id="{{fieldRandomId}}-{{roleData.CodeDescription}}"
                                                    [attr.aria-label]="roleData?.CodeDescription"
                                                    href="javascript:this.blur();" class="dropdown-item"
                                                    (click)="ModuleChange(roleData)"
                                                    [ngClass]="[(_appSharedService.currentUserModuleData.CodeDescription == roleData.CodeDescription) ?  'active': '' ]">{{roleData.CodeDescription}}</a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </li>
                            </ul>

                            <!-- User Profile -->
                            <ul class="navbar-nav userProfile">
                                <li class="nav-item">


                                    <ng-container
                                        *ngIf="(userAccessInfo != null && userAccessInfo != undefined && (userAccessInfo.email == userAccessInfo.actingemail))">
                                        <a id="{{fieldRandomId}}-userProfile" class="nav-link dropdown-toggle"
                                            aria-label="User Info" href="javascript:this.blur();" role="button"
                                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">


                                            <img alt="User Avatar" src="assets/images/npphoto.png"
                                                class="img-avatar-thumb img-avatar img-avatar32 align-self-center" />
                                            <span>{{userinfo['DisplayName'] == undefined? '':
                                                userinfo['DisplayName']}}</span>
                                        </a>
                                    </ng-container>

                                    <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                                && (userAccessInfo.email != userAccessInfo.actingemail))">
                                        <a id="{{fieldRandomId}}-User Access Info" class="nav-link dropdown-toggle"
                                            aria-label="User Access Info Link" href="javascript:this.blur();"
                                            role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">

                                            <img alt="User Avatar" src="assets/images/npphoto.png"
                                                class="img-avatar-thumb img-avatar img-avatar32 align-self-center" />
                                            <span>{{userAccessInfo['name'] == undefined? '':
                                                userAccessInfo['name']}}</span>
                                        </a>
                                    </ng-container>

                                    <ul class="dropdown-menu">
                                        <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                                            && userAccessInfo.email.length > 0)">

                                            <li *ngIf="userAccessInfo['canswitchuser']=='True'">
                                                <label for="Switch User Dialog" class="sr-only">Switch User
                                                    Dialog</label>
                                                <a aria-label="Switch User Dialog" href="javascript:this.blur();"
                                                    class="dropdown-item" (click)="openSwitchUser()">{{'Switch User' |
                                                    translate}}</a>
                                            </li>
                                        </ng-container>

                                    </ul>


                                </li>
                            </ul>
                        </div>


                        <!-- <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                                && (userAccessInfo.email != userAccessInfo.actingemail))">
                            <a id="{{fieldRandomId}}-User Access Info" class="nav-link dropdown-toggle" aria-label="User Access Info Link" href="javascript:this.blur();"
                                role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                title="{{userAccessInfo['name'] == undefined? '': userAccessInfo['name']}}">
    
                                <img alt="User Avatar" src="assets/images/npphoto.png"
                                    class="img-avatar-thumb img-avatar img-avatar32 align-self-center" />
                                <span>{{userAccessInfo['name'] == undefined? '': userAccessInfo['name']}}</span> -->
                        <!-- <span>{{ ' [Proxy: ' + userAccessInfo['name'] + ']' }}</span> -->
                        <!-- </a>
                        </ng-container> -->



                        <!-- 
                        <ul class="dropdown-menu">
                            <ng-container *ngIf="(userAccessInfo != null && userAccessInfo != undefined 
                                            && userAccessInfo.email.length > 0)">
                                <li  id="Switch User Dialog" *ngIf="userAccessInfo['canswitchuser']=='True'">
                                    <a aria-labelledby="Switch User Dialog" aria-label="Switch User Dialog" href="javascript:this.blur();"   class="dropdown-item" (click)="openSwitchUser()">{{'Switch User' |
                                        translate}}</a>
                                </li>
                            </ng-container>
                        </ul> -->
                    </li>
                </ul>
            </div>
            <!-- /.navbar-collapse -->

        </nav>
        <!-- Navbar Ends Here -->


        <!--  Main Container Starts Here -->
        <div id="bodyContainer" class="bodyContainer">

            <!-- <ng-container>
                <div class="d-flex h-100" *ngIf="(showNoMenuMessage == false && showNoDataMessage == false)">
                    <div>
                        <ng-container *ngIf="(menuList != null && menuList != undefined && menuList.length > 0)">
                            <menubar id="tabMain" [defualtTab] = "dftTab" [menuType]="'sidebar'"
                                [menuList]="(menuList | filterBy : {HasPermission :true})"
                                (menuClick)="menuTabClick($event)"></menubar>
                        </ng-container>
                    </div>
    
                    <div class="w-100 h-100">
                        <div class="tab-content h-100">
                            <div class="tab-pane fade active show">
                                <div class="h-100">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container> -->
            <!-- Nav tabs -->
            <ng-container
                *ngIf="(menuList != null && menuList != undefined && menuList.length > 0) && (showNoMenuMessage == false && showNoDataMessage == false)">
                <menubar id="tabMain" [menuType]="'sidebar'" [defualtTab]="dftTab"
                    [menuList]="(menuList | filterBy : {HasPermission :true})" (menuClick)="menuTabClick($event)">
                </menubar>
            </ng-container>

            <!-- Tab panes -->

            <ng-container>
                <footer  *ngIf="!IsCIS" class="footer mt-auto bg-light">
                    <div class="d-flex align-items-center mx-3">
                        <img src="assets/images/CGLogo.svg" alt="Glossary">
                        <!-- <div class="ms-auto">
                            <a class="me-3" href="javascript:this.blur();">Group IT Intranet</a>
                            <a class="me-3" href="javascript:this.blur();">Data Protection Policy</a>
                            <a class="me-3" href="javascript:this.blur();">Contact Group IT</a>
                            <a class="me-3" href="javascript:this.blur();">Provide Your Feedback</a>
                        </div> -->
                        <ng-container *ngIf="IsCIS">
                            <div class="ms-auto">
                                <a class="me-3"
                                    tooltip="The information provided in the tool is privileged or confidential and is the property of the Capgemini Group, not to be circulated outside of the Capgemini Sales/Presales team working on the deal">Disclaimer</a>
                                <a class="me-3" href="mailto:cisbbs.in@capgemini.com?subject=Contact Us">Contact Us</a>
                            </div>
                        </ng-container>
                    </div>
                </footer>

                <footer class="footer mt-auto p-2" style="background-color: #2b0a3d;" *ngIf="IsCIS">
                    <div class="d-flex align-items-center mx-4 whitefontColorCIS">
                        <span>This website is best viewed in Google Chrome<br>2023 Capgemini All rights reserved</span>
                        <div class="ms-auto">
                            <a class="me-4 whitefontColorCIS"
                                href="mailto:cisbbs.in@capgemini.com?subject=Contact Us">CONTACT US</a>
                            <span class="me-4 whitefontColorCIS">||</span>
                            <a class="me-4 whitefontColorCIS" aria-label="Click here"
                                href="https://capgemini.sharepoint.com/sites/CLIKH/SitePages/NewRequirement/Clikh.aspx"
                                target="_blank" rel = "noopener noreferrer">CLIKH</a>
                        </div>
                    </div>
                </footer> 
            </ng-container>
            <ng-container *ngIf="showNoDataMessage">
                <div class="position-absolute top-50 start-50 translate-middle">
                    <h5>Your login is not mapped to any role and hence no data is displayed. Please click Contact Us.
                    </h5>
                </div>
            </ng-container>

            <ng-container *ngIf="showNoMenuMessage">
                <div class="position-absolute top-50 start-50 translate-middle">
                    <h5>Your login role is not mapped to any menu(s) and hence no data is displayed. Please click
                        Contact
                        Us.</h5>
                </div>
            </ng-container>

        </div>
        <!--  Main Container Ends Here -->

    </ng-container>
</ng-container>


<ng-container *ngIf="LandingPage">
    <!-- <div id="wrapper">
        <div id="header">
            <div class="header-left">
                <a href="" class="capgemini"></a>
                <span class="p2w"></span>
            </div>
            <div class="header-right">
                <h1 class="">
                    <span class="wc">Welcome to</span>
                    <span>Price to Win (P2W)</span>
                </h1>
                <p>ADDRESSING THE DYNAMIC GLOBAL PRICING ENVIRONMENT <br /> THROUGH STATE -OF THE ART PRICING PLATFORM
                </p>
            </div>
        </div>
        <div id="container">
            <div id="panel-holder">
                <div id="panel-left">
                    <div id="panel-left-content">
                        <div class="heading">
                            <h2>PRICE TO WIN</h2>
                        </div>
                        <ul class="list-vertical">
                            <li>
                                <span class="lv-num">1</span>
                                <div class="lv-img"><img src="assets/images/img-list-part-01.jpg" alt="" /></div>
                                <div class="lv-txt">
                                    <p>Platform to deliver <b>competitive price guidance</b> to all Capgemini strategic
                                        pursuits globally supported by Price to Win specialists</p>
                                </div>
                            </li>
                            <li>
                                <span class="lv-num">2</span>
                                <div class="lv-img"><img src="assets/images/img-list-part-02.jpg" alt="" /></div>
                                <div class="lv-txt">
                                    <p>Build a <b>richer collaboration across regions and SBUs</b> to enable the shared
                                        service to <b>develop and maintain</b> competitive pricing and increase win rate
                                    </p>
                                </div>
                            </li>
                            <li>
                                <span class="lv-num">3</span>
                                <div class="lv-img"><img src="assets/images/img-list-part-03.jpg" alt="" /></div>
                                <div class="lv-txt">
                                    <p><b>Improving our Win rate</b> by having pulse on competitive pricing through
                                        comparisons with industry benchmarks</p>
                                </div>
                            </li>
                        </ul>
                        <div class="panel-notes">
                            <p>For any technical or access related issues please reach out to <a
                                    href="mailto:kalpesh.tukrul@capgemini.com?subject=Technical or access related issues!&body=Hi Kalpesh,"
                                    target="_blank">Kalpesh Tukrul</a></p>
                        </div>
                    </div>
                </div>
                <div id="panel-right">
                    <div id="panel-right-content">
                        <div class="heading">
                            <h3>P2W PLATFORM</h3>
                        </div>
                        <ul class="list-segment">
                            <li class="pointer" (click)="ModuleChange_Mainscreen('AM')">
                                <div class="list-segment-img"><img src="assets/images/img-p2w-ams.jpg" alt="p2w-ams" />
                                </div>
                                <div class="list-segment-txt">
                                    <h4>P2W AMS</h4>
                                    <p>Competitive price <br />guidance for AM Deals</p>
                                </div>
                            </li>
                            <li class="pointer" (click)="ModuleChange_Mainscreen('CIS')">
                                <div class="list-segment-img"><img src="assets/images/img-p2w-cis.jpg" alt="p2w-cis" />
                                </div>
                                <div class="list-segment-txt">
                                    <h4>P2W CIS</h4>
                                    <p>CIS Module in the P2W <br />for Infra related Deals</p>
                                </div>
                            </li>
                            <li class="pointer" (click)="ModuleChange_Mainscreen('AD')">
                                <div class="list-segment-img"><img src="assets/images/img-p2w-ad-project.jpg"
                                        alt="p2w-ad-project" /></div>
                                <div class="list-segment-txt">
                                    <h4>P2W AD/PROJECT</h4>
                                    <p>Competitive Pricing for <br />AD / Project Deals</p>
                                </div>
                            </li>
                            <li class="pointer" (click)="ModuleChange_Mainscreen('LITE')">
                                <div class="list-segment-img"><img src="assets/images/img-p2w-lite.jpg"
                                        alt="p2w-lite" /></div>
                                <div class="list-segment-txt">
                                    <h4>P2W LITE</h4>
                                    <p>Quick & Easy price <br />guidance for AM Deals</p>
                                </div>
                            </li>
                            <li class="pointer" (click)="ModuleChange_Mainscreen('LITE-OLD')">
                                <div class="list-segment-img"><img src="assets/images/img-p2w-lite-pcp-module.jpg"
                                        alt="p2w-lite-pcp-module" /></div>
                                <div class="list-segment-txt">
                                    <h4>P2W LITE PCP MODULE</h4>
                                    <p>Pricing guidance further enhancing <br />the P2W Lite to provide Competitive
                                        <br />Benchmarking Information
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="price-deal">
                        <h3>Pricing Guidance for more than 1000+ Deals so far<span>… (source till Jan 2023)</span></h3>
                        <ul>
                            <li><i></i><span class="pd-text">#870+ Deals – Americas</span></li>
                            <li><i></i><span class="pd-text">#100+ Deals – Other SBU’s</span></li>
                            <li><i></i><span class="pd-text">#100+ Deals - CIS</span></li>
                        </ul>
                    </div>


                    <div class="panel-notes">
                        <p>For further details on NA GTM Team & detailed Tools Landscape click <a
                                href="https://nagtmtoolsdashboard.in.capgemini.com/" target="_blank">here</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div id="wrapper" class="p2w-landing-page">
        <div id="header"><a href="" class="capgemini"></a></div>
        <div id="banner">
            <a href="" class="p2w"></a>
            <div class="clear"></div>
            <div class="jumbotron">
                <h1 class="">
                    <span class="wc">Welcome to</span>
                    <span>Price to Win (P2W)</span>
                </h1>
                <p>Addressing the dynamic global pricing environment through <br> state-of-the-art pricing platform.</p>
            </div>
        </div>
        <div id="container">
            <div id="platform">
                <div class="heading"><h3>P2W Platform</h3></div>
                <ul class="list-segment">
                    <li class="pointer">
                        <div class="list-segment-img bg1"></div>
                        <div class="list-segment-txt">
                            <h4>P2W AMS</h4>
                            <p>Competitive price guidance for AM Deals</p>
                            <button (click)="ModuleChange_Mainscreen('AM')">Go to App<i class="icon-arrow"></i></button>
                        </div>
                    </li>
                    <li class="pointer">
                        <div class="list-segment-img bg2"></div>
                        <div class="list-segment-txt">
                            <h4>P2W CIS</h4>
                            <p>CIS Module in the P2W for Infra related Deals</p>
                            <button (click)="ModuleChange_Mainscreen('CIS')">Go to App<i class="icon-arrow"></i></button>
                        </div>
                    </li>
                    <li class="pointer">
                        <div class="list-segment-img bg3"></div>
                        <div class="list-segment-txt">
                            <h4>P2W AD/PROJECT</h4>
                            <p>Competitive Pricing for AD / Project Deals</p>
                            <button (click)="ModuleChange_Mainscreen('AD')">Go to App<i class="icon-arrow"></i></button>
                        </div>
                    </li>
                    <li class="pointer">
                        <div class="list-segment-img bg4"></div>
                        <div class="list-segment-txt">
                            <h4>P2W LITE</h4>
                            <p>Quick & Easy price guidance for AM Deals</p>
                            <button (click)="ModuleChange_Mainscreen('LITE')">Go to App<i class="icon-arrow"></i></button>
                        </div>
                    </li>
                    <li class="pointer">
                        <div class="list-segment-img bg5"></div>
                        <div class="list-segment-txt">
                            <h4>P2W LITE PCP MODULE</h4>
                            <p>Pricing guidance further enhancing the P2W Lite to provide Competitive Benchmarking Information</p>
                            <button (click)="ModuleChange_Mainscreen('LITE-OLD')">Go to App<i class="icon-arrow"></i></button>
                        </div>
                    </li>
                </ul>
            </div>
            <div id="partition">
                <div class="partition-holder">
                    <div class="partition-box">
                        <div class="partition-img"><img src="assets/images/img-list-part-01.jpg" alt="p2w-ams"></div>
                        <div class="partition-hold">
                            <div class="partition-txt">
                                <p>Platform to deliver competitive price guidance to all Capgemini strategic pursuits globally supported by Price to Win specialists.</p>
                            </div>
                        </div>
                    </div>
                    <div class="partition-box">
                        <div class="partition-img"><img src="assets/images/img-list-part-02.jpg" alt="p2w-ams"></div>
                        <div class="partition-hold">
                            <div class="partition-txt">
                                <p>Build a richer collaboration across regions and SBUs to enable the shared service to develop and maintain competitive pricing and increase win rate.</p>
                            </div>
                        </div>
                    </div>
                    <div class="partition-box">
                        <div class="partition-img"><img src="assets/images/img-list-part-03.jpg" alt="p2w-ams"></div>
                        <div class="partition-hold">
                            <div class="partition-txt">
                                <p>Improving our Win rate by having pulse on competitive pricing through comparisons with industry benchmarks.</p>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="price-deal">
                <h3>Pricing Guidance for more than 1000+ Deals so far… <span>(source till Jan 2023)</span></h3>
                <ul>
                    <li>
                        <span class="deal-count">870+</span>
                        <i></i>
                        <span class="deal-zone">Deals – Americas</span>
                    </li>
                    <li>
                        <span class="deal-count">100+</span>
                        <i></i>
                        <span class="deal-zone">Deals –  Other SBU’s</span>
                    </li>
                    <li>
                        <span class="deal-count">100+</span>
                        <i></i>
                        <span class="deal-zone">Deals - CIS</span>
                    </li>
                </ul>
            </div>
            <div class="footer-content">
                <div class="footer-row">
                    <p class="lefter">For any technical related issues, please reach out to the <a href="mailto:p2wadmin.cor@capgemini.com?subject=Technical related issues &body=Hello Team," target="_blank" rel = "noopener noreferrer"><u>Team</u></a></p>
                    <p class="righter"> For further details click <a href="https://nagtmtoolsdashboard.in.capgemini.com/" target="_blank" rel = "noopener noreferrer"><u>NA GTM Team & detailed Tools Landscape</u></a></p>
                </div>
                <div class="footer-row">
                    <p class="lefter">All rights reserved by Capgemini. Copyright © 2023</p>
                    <nav class="righter">
                        <a href="">Group IT</a>
                        <a href="">Privacy policy</a>
                    </nav>
                </div>
            </div>
        </div>
    </div>


</ng-container>












<!-- SwitchUser section -->
<div *ngIf="displaySwitchUser">
    <app-switchuser (close)="closeSwitchUser($event)"></app-switchuser>
</div>

<!-- Session Expiry section -->
<div *ngIf="displaySessionExp">
    <session-modal [countMinutes]="session['countMinutes']" [countSeconds]="session['countSeconds']"
        [progressCount]="session['progressCount']" [maxCount]="session['maxCount']" (close)="closeSessionPopup($event)">
    </session-modal>
</div>

<!-- Loader for entire app -->
<div>
    <loading [IsLoading]="AppIsLoading" [IsFullScreen]="true"></loading>
</div>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [curaction]="notify.action" [timeout]="notify.timeout"
        [curitem]="notify.item" (close)="closeNotifybox() " (selected)="setNotifyRes($event) "></notification>
</div>