import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../app.interface';
import { environment } from '../../environments/environment';


@Injectable()
export class HomeService {

  private headers = new HttpHeaders(
    {
      'Content-Type': 'application/json',
      // 'X-Frame-Options': 'DENY',
      // 'X-XSS-Protection': '1; mode=block',
      // 'X-Content-Type-Options': 'nosniff',
      // 'Content-Security-Policy': "frame-ancestors 'none'",
      // 'X-Permitted-Cross-Domain-Policies': 'none',
      // 'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
      // 'Referrer-Policy': 'no-referrer',
      // 'Permission-Policy': "geolocation 'none';midi 'none';notifications 'none';push 'none';sync-xhr 'none';microphone 'none';camera 'none';magnetometer 'none';gyroscope 'none';speaker 'self';vibrate 'none';fullscreen 'self';payment 'none';",
      // 'Feature-Policy': "accelerometer 'none'; camera 'none'; geolocation 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; payment 'none';",
      // 'Cache-Control': 'no-cache',
    });
  private CommAPIUrl: string;
  private HomeURL: string;
  private DealURL: string;
  private serviceURL: string;

  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.HomeURL = environment.apiURL.HomeURL;
    this.DealURL = environment.apiURL.DealURL;
    this.serviceURL = environment.apiURL.ServiceTypeURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }

  getDealDetailsBySearch(odata: any, allPendingFlag: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //  Params = Params.append('DealHeaderId', 1);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'GetDealDetailsBySearch', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateDealStatus(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', odata.DealHeaderId);
    Params = Params.append('dealStatus', odata.DealStatus);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'UpdateDealStatus', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  updateShareDeal(dealHeaderId: any, UserId: any, Module: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', dealHeaderId);
    Params = Params.append('UserId', UserId);
    Params = Params.append('Module', Module);
    return this._http.get<ProcessResponse<any>>(this.DealURL + 'Sharedeal',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  // GetExportedData(dealHeaderId: any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('dealHeaderId', dealHeaderId);

  //   return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetExportedData',
  //     { headers: this.headers, params: Params })
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  ClnDeal(id: any, Module: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', id);
    Params = Params.append('Module', Module);

    return this._http.get<ProcessResponse<any>>(this.DealURL + 'ClnDeal',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetUserDetails(id: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', id);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetUserDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetCloneDealAM(dealHeaderId: any, Module: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', dealHeaderId);
    Params = Params.append('Module', Module);
    return this._http.get<ProcessResponse<any>>(this.DealURL + 'GetCloneDealAM',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  // GetCloneDealCIS(dealHeaderId: any,Module:any): Observable<ProcessResponse<any>> {
  //   let Params = new HttpParams();
  //   Params = Params.append('dealHeaderId', dealHeaderId);
  //   Params = Params.append('Module', Module);
  //   return this._http.get<ProcessResponse<any>>(this.DealURL + 'GetCloneDealCIS',
  //     { headers: this.headers, params: Params })
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  CISCloseAndCloneDeal(odata: any) {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealCloseInput', odata.DealCloseInput);
    Params = Params.append('DealCloneInput', odata.DealCloneInput);
    Params = Params.append('Module', odata.Module);
    Params = Params.append('UserId', odata.UserId);
    return this._http.get<ProcessResponse<any>>(this.DealURL + 'CISCloseAndCloneDeal',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }




  saveCloneDealAM(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //  Params = Params.append('DealHeaderId', 1);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'ClnDealAM', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetDealStatusCount(id: any, Module: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('UserId', id);
    Params = Params.append('Module', Module);
    //http://localhost:7000/Deal/GetDealStatusCount?UserId=12

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDealStatusCount',
      { headers: this.headers, params: Params });
    return response;

  }

  DownloadExcel(odata: any) {
    let Params = new HttpParams();

    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    // let download_headers = new HttpHeaders({'responseType': 'application/octet-stream' });
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };

    return this._http.get<ProcessResponse<any>>(this.serviceURL + 'GetExportedData',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }
  ////////FOR AM DASHBOARD//////
  DeleteDeal(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    // Params = Params.append('DealHeaderId', odata.DealHeaderId);
    //Params = Params.append('Module', odata.Module);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'DeleteDeal', odata,
      // { headers: this.headers, params: Params })
      // .pipe(
      //   catchError(this.handleError)
      // );

      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


}
//}
