import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { asEnumerable } from 'linq-es2015';
import { ComponentCodes, ComponentConfig, ProcessStatus } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isEmail, isNullOrUndefined, isStringNullOrEmpty } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AdminService } from '../admin.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { TabSetComponent } from 'src/modules/tabset/tabset.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
@AutoUnsubscribe
export class ReportsComponent implements OnInit {

  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 3,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    IsSortable:false
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;

  public ExcelFileName: string = '';

  public ListTemplate: FieldTemplate[] = [];
  public Permissions: any = [];
  public ListData: any[] = [];
  public ListDataALL: any[] = [];

  public ListDataCloneALL: any[] = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedTab: string = "";
  public total: any = {};



  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridDisplayControlComponent;

  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private modalService: ModalService,
    public tabs: TabSetComponent) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);

  }

  ngOnInit() {

    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.selectedTab = "RoleWiseUserCountReport";
      this.getTemplate();
    }
  }

  getTemplate(): void {
    this.IsLoading = true;

    this.ListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        let template = res.Data.FieldTemplate!;

        //this.ListTemplate = template.filter(x => x.Group === (this.selectedTab + "List"));
        this.ListTemplate = template;

        this.setCompProperties(res.Data.Properties!);

        this.Permissions = res.Data.Permission;

        this.GetData();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      this.Config.ReportTabs = (Properties.filter(x => x.Name.toLowerCase() === 'ReportTabs'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ReportTabs'.toLowerCase())[0].JSONData.toString()) : []);

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }

    this.Config.DownloadColumns = [];
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ReportTabs) && this.Config.ReportTabs.length > 0) {

      this.Config.ReportTabs.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;

          let peritem = this.Permissions.indexOf(item.Name);
          // if (peritem >= 0 && item.Group === this.selectedTab) {
          if (peritem >= 0) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  setSelectedTab(event: any) {
    this.selectedTab = event.selectedTab
    this.GetData();
  }

  GetData(): void {

    this.IsLoading = true;

    this.ListData = [];
    this.ListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getReportDetails(this.selectedTab).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (result.Data != null) {
          this.ListData = result.Data;
          console.log(this.ListData);

         
          var totalValue = asEnumerable(this.ListData);          
          this.ListDataALL = totalValue.ToArray();

           if(this.selectedTab == 'CompletedDealsConsolidatedReport'){

          }
          else{
          this.total.ReportTypeName = "Total";
          this.total.Active = totalValue.Sum(s => s.Active);
          this.total.InActive = totalValue.Sum(s => s.InActive);
          this.total.OnHold = totalValue.Sum(s => s.OnHold);
          this.total.InProgress = totalValue.Sum(s => s.InProgress);
          this.total.Cancelled = totalValue.Sum(s => s.Cancelled);
          this.total.Completed = totalValue.Sum(s => s.Completed);
          this.total.Total = totalValue.Sum(s => s.Total);

          this.ListData.push(this.total);
          }

          this.dataTotalCount = this.ListData.length;

          this.setActionButtonPermissions();
        }

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  onClick(event: any): void {
    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        switch (event.icon.Value) {
        }
        break;
    }
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {

    this.ListData = [];

    if (event.sCondn.length > 0) {
      this.ListData = asEnumerable(this.ListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
    }
    else {
      this.ListData = asEnumerable(this.ListDataALL).ToArray();
    }
    
    var totalValue = asEnumerable(this.ListData);

    this.total.ReportTypeName = "Total";
    this.total.Active = totalValue.Sum(s => s.Active);
    this.total.InActive = totalValue.Sum(s => s.InActive);
    this.total.OnHold = totalValue.Sum(s => s.OnHold);
    this.total.InProgress = totalValue.Sum(s => s.InProgress);
    this.total.Cancelled = totalValue.Sum(s => s.Cancelled);
    this.total.Completed = totalValue.Sum(s => s.Completed);
    this.total.Total = totalValue.Sum(s => s.Total);

    this.ListData.push(this.total);
    this.dataTotalCount = this.ListData.length;
  }

  exportToExcel() {

    if (this.ListData.length === 0) {
      // this.toastr.warning("Report Data is empty");
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      let name;
      if(this.selectedTab == 'CompletedDealsConsolidatedReport'){
        name= "CompletedDealsReport"
      }
      else {
        name= this.selectedTab;
      }
      this.ExcelFileName = 'P2W-' + name + '-Details' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];

      tempdata.push({
        Name: name + ' Data',
        Data: this.ListData,
        ColumnList: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null,
        fldtemplate: this.ListTemplate.filter(x => x.Group === this.selectedTab)
      });

   //  this.excelexport.generateExcelTable_old(tempdata,this.ExcelFileName);

   this.excelexport.generateExcelTable_fromJSON(tempdata, this.ExcelFileName);

      this.IsLoading = false;
    }
  }
}