import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { asEnumerable } from 'linq-es2015';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, isEmail, isNullOrUndefined, isStringNullOrEmpty } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AdminService } from '../admin.service';
import { UserRoleDetail } from './user-role-manage.interface';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';


@Component({
  selector: 'app-user-role-manage',
  templateUrl: './user-role-manage.component.html'
})


export class UserRoleManageComponent implements OnInit {

  @ViewChild('infoform', { static: false }) infoform!: ModalComponent;

  public FilterData: any = {};

  public GlobalConfig: any = {};

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public FormProp: FormProperty = {
    'ColSize': 3,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public ListTemplate: FieldTemplate[] = [];
  public EditTemplate: FieldTemplate[] = [];
  public CIS_EditTemplate: FieldTemplate[] = [];
  public ListData: any[] = [];
  public ListDataALL: any[] = [];
  public Permissions: any = [];
  public dataTotalCount: number = 0;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public selectedInfoData: any;
  public curItemsPerPage: number = 10;
  public editData = new UserRoleDetail();
  public selectedTab: string = "";
  public viewName: string = "ListView";
  public Title: string = "";
  public tabName: string = "";

  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    ActionBarConfig: {
      ActionBarList: null
    }
  }
  public Config: any = {
    ActionButtons: [],
    ActionButtonsBottom: []
  };

  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public ModuleName: any;
  // public DispLoader: boolean = false;

  public ExcelFileName: string = '';
  // public ExcelData: any = [];

  @ViewChild('excelexport', { static: false }) excelexport!: ExcelExportComponent;
  @ViewChild('overallgrid', { static: false }) overallgrid!: GridDisplayControlComponent;


  constructor(private _route: ActivatedRoute, private _router: Router, private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService, private adminService: AdminService, private modalService: ModalService) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);


    this.ModuleName = this._appSharedService.currentUserModuleData.Code;
    console.log(this.ModuleName);



  }

  ngOnInit() {

    this.selectedTab = this._route.snapshot.data['selectTab'];
    this.tabName = this.selectedTab.charAt(0).toUpperCase() + this.selectedTab.slice(1);

    this.Title = this.tabName + " Management";


    if (!isNullOrUndefined(this._appSharedService.currentRoleData)) {
      this.Config.ActionButtons = JSON.parse(`[{"Name":"ExportToExcel","Icon":"fa fa-file-excel-o","DisplayName":"Dow<u>n</u>load Excel","AccessKey":"N","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":1},{"Name":"Filter","Icon":"fa fa-filter","DisplayName":"F<u>i</u>lter","AccessKey":"I","IsApplicable":true,"HasPermission":true,"IsEnabled":true,"Confirmation":{"IsEnabled":false,"Message":null,"Action":null},"SeqOrder":2}]`);
      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
      this.getTemplate();
    }
  }

  getTemplate(): void {
    this.IsLoading = true;

    this.ListTemplate = [];

    let odata: ComponentConfig = {
      Component: 'Admin', ComponentCode: ComponentCodes.AdminComponent
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        let template = res.Data.FieldTemplate!;

        this.ListTemplate = template.filter(x => x.Group === (this.selectedTab + "List"));
        this.EditTemplate = template.filter(x => x.Group === (this.selectedTab + "Edit"));
        this.CIS_EditTemplate = template.filter(x => x.Group === 'CISUserEdit');
        this.setCompProperties(res.Data.Properties!);

        this.Permissions = res.Data.Permission;

        this.GetData();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);

      this.Config.ActionButtonsBottom = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtonsBottom'.toLowerCase())[0].JSONData.toString()) : []);

      this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;

      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });

      this.Config.DownloadColumns = [];
    }
  }

  setActionButtonPermissions(): void {
    if (!isNullOrUndefined(this.Config.ActionButtonsBottom) && this.Config.ActionButtonsBottom.length > 0) {

      this.Config.ActionButtonsBottom.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {
          item.HasPermission = false;
          //item.IsApplicable = false;

          let peritem = this.Permissions.indexOf(item.Name);
          if (peritem >= 0 && (item.Group === (this.selectedTab + "List") || item.Group === (this.selectedTab + "Edit"))) {
            item.HasPermission = true;
          }
        }
      });
    }
  }

  GetData(): void {

    this.IsLoading = true;

    this.ListData = [];
    this.ListDataALL = [];
    this.dataTotalCount = 0;

    let subscribe$: Subscription = this.adminService.getDetails(this.selectedTab,this.ModuleName).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        if (result.Data != null) {
          this.ListData = result.Data;
          console.log(this.ListData);
          this.dataTotalCount = this.ListData.length;

          this.ListDataALL = this.ListData;

          this.ListData.forEach((item) => {
            item.Action = item.RowStatus == "I" ? '2' : ((this.selectedTab + "List") == "roleList" && item.RoleId == 1) ? "0" : '0,1';
            item.IsEditable = 1;
          });
        }

        this.setActionButtonPermissions();

        setTimeout(() => {
          if (!isNullOrUndefined(this.overallgrid)) {
            this.overallgrid.Pagination.CurrentPage = 1;
          }
        }, 50);

      }
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  Delete(deleteData: any): void {

    this.IsLoading = true;

    let subscribe$: Subscription = this.adminService.delete(this.selectedTab, deleteData).subscribe(result => {
      if (!isNullOrUndefined(result)) {

        this.showNotificationMessage
          (
            result.Message,
            result.Status === ProcessStatus.Success ? 1
              : result.Status === ProcessStatus.NoContent ? 2 : 3,
            '', null, '', '', 1000
          );
      }
      this.IsLoading = false;
      if (result.Status === ProcessStatus.Success) {
        this.getTemplate();
      }
    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);

  }

  getDetailsById(userId: number): void {

    this.IsLoading = true;

    this.editData = new UserRoleDetail();

    let subscribe$: Subscription = this.adminService.getDetailsById(this.selectedTab, userId).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {

        this.editData = result.Data;

        this.editData.IsEditable = 1;

        console.log(this.editData);
      }

      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Queued) {
        //this._toastr.warning(result.Message, this.CompTitle);
      }

      this.IsLoading = false;

    },

      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);
  }

  SaveEdit(): void {

    if (this.validateSave()) {

      this.IsLoading = true;

      let subscribe$: Subscription = this.adminService.updateDetails(this.selectedTab, this.editData).subscribe(result => {
        if (!isNullOrUndefined(result)) {

          this.showNotificationMessage
            (
              result.Message,
              result.Status === ProcessStatus.Success && result.Message.match("exists") ? 2
                : result.Status === ProcessStatus.Success ? 1 : 3,
              '', null, '', '', 1000
            );

          if (ProcessStatus.Success && !result.Message.match("exists")) {
            setTimeout(() => {
              this.ReloadGetData();
              this.IsLoading = false;
            }, 2000);
          } else {
            this.IsLoading = false;
          }
        }
      },

        err => {
          this.IsLoading = false;
        });

      this.subscriptionList$.push(subscribe$);
      console.log(this.subscriptionList$);

    }
  }

  validateSave(): boolean {

    if (this.selectedTab == "user") {

      if (isStringNullOrEmpty(this.editData.EmailId) || !isEmail(this.editData.EmailId)) {
        this.showNotificationMessage('Please provide valid EmailID', 2, '', null, '', '', 1000);
        return false;
      }

      if (isStringNullOrEmpty(this.editData.UserName)) {
        this.showNotificationMessage('Please provide User Name', 2, '', null, '', '', 1000);
        return false;
      }

      if (isStringNullOrEmpty(this.editData.Designation)) {
        this.showNotificationMessage('Please provide Designation', 2, '', null, '', '', 1000);
        return false;
      }

      if (isNullOrUndefined(this.editData.RoleId) || this.editData.RoleId == 0) {
        this.showNotificationMessage('Please select Role', 2, '', null, '', '', 1000);
        return false;
      }

      if (isNullOrUndefined(this.editData.SBUId) || this.editData.SBUId == 0) {
        this.showNotificationMessage('Please select SBU', 2, '', null, '', '', 1000);
        return false;
      }

      if (isNullOrUndefined(this.editData.RegionId) || this.editData.RegionId == 0) {
        this.showNotificationMessage('Please select Region', 2, '', null, '', '', 1000);
        return false;
      }

      if (isNullOrUndefined(this.editData.CountryId) || this.editData.CountryId == 0) {
        this.showNotificationMessage('Please select Country', 2, '', null, '', '', 1000);
        return false;
      }

      if (isStringNullOrEmpty(this.editData.Location)) {
        this.showNotificationMessage('Please provide User Name', 2, '', null, '', '', 1000);
        return false;
      }
    }
    if (this.selectedTab == "role") {

      if (isStringNullOrEmpty(this.editData.RoleName)) {
        this.showNotificationMessage('Please provide Role Name', 2, '', null, '', '', 1000);
        return false;
      }
    }
    return true;
  }

  ReloadGetData(): void {
    this.Title = this.tabName + " Managment";
    this.viewName = "ListView";
    this.getTemplate();
  }

  pageItemsChange() {

    if (this.curItemsPerPage > 0) {
      this.GridProp.PaginationConfig.ItemsPerPage = this.curItemsPerPage;
    }
    else {
      this.GridProp.PaginationConfig.ItemsPerPage = 100;
    }
    // reload grid data
    this.GetData();
  }

  actionButtonClick(data: any) {
    let valid: boolean = true;

    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.split('_')[0].toUpperCase()) {
        case 'ADD':
          this.editData = new UserRoleDetail();
          this.Title = "Add " + this.tabName;
          this.viewName = "EditView";
          break;
        case 'CANCEL':
          if (this.editData.IsModified == 1) {
            this.showNotificationMessage('Are you sure you want to cancel un-saved data?',
              2, 'CancelEdit', null, 'Yes', 'No', 10000);
          }
          else {
            this.ReloadGetData();
          }
          break;
        case 'SAVE':
          this.SaveEdit();
          break;
      }
    }
  }

  onClick(event: any): void {
    let selitem = event.item;

    switch (event.fieldname.toUpperCase()) {
      case 'ACTION':
        switch (event.icon.Value) {
          case '0':
            // View 
            // this.Title = "User Role Edit";
            // this.getDetailsById(selitem.UserId);

            // if (selitem.UserId > 0) {
            //   if (this.ListTemplate.filter((x: any) => (x.FieldName == "UserId" && x.Group === "UserRoleEdit")).length > 0) {

            //     this.ListTemplate.filter((x: any) => (x.FieldName == "UserId") && x.Group === "UserRoleEdit").forEach((x: any) => {
            //       x.Editable = false;
            //     });
            //   }
            // }

            // this.selectedTab = "UserRoleEdit";

            this.Title = this.tabName + " Edit";
            this.getDetailsById
              (
                this.selectedTab == "user" ? selitem.UserId :
                  this.selectedTab == "role" ? selitem.RoleId : 0
              );
            this.viewName = "EditView";

            break;
          case '1':
            if (selitem.IsHaveActiveUsers == '1' && this.selectedTab == "role") {
              this.showNotificationMessage('Kindly re-map the user(s) to other roles before disable, if you still want to continue the associated users will be disabled, click yes for disable.',
                2, 'Delete', selitem, 'Yes', 'No', 10000);
            }
            else {
              this.showNotificationMessage('Are you sure you want to disable?',
                2, 'Delete', selitem, 'Yes', 'No', 10000);
            }
            break;
          case '2':
            if (selitem.IsRoleDisabled == '1' && this.selectedTab == "user") {
              this.showNotificationMessage('You can not enable this user untill unless role to be activated, if you want to assign other role please click yes.',
                2, 'Edit', selitem, 'Yes', 'No', 10000);
            }
            else {
              this.showNotificationMessage('Are you sure you want to enable?',
                2, 'Enable', selitem, 'Yes', 'No', 10000);
            }
            break;
        }
        break;
    }
  }

  onChange(event: any): void {
    let selitem: any = event.item;
    event.item.IsModified = 1;
  }

  actButtonClick(event: any) {
    switch (event.buttonname.toUpperCase()) {
      case 'EXPORTTOEXCEL':
        this.exportToExcel();
        break;
      case 'FILTER':
        this.showFilter();
        break;

    }
  }

  SearchData(event: any): void {

    console.log(event)

    if (!isNullOrUndefined(event.action) && event.action.toUpperCase() === 'SEARCH' && event.item.UserId.length < 3) {

      this.notify = {
        info: {
          header: 'Search Alert',
          message: 'Please provide minimum 3 characters for user field.',
          yes: '',
          no: '',

          IsHeaderHidden: true,
          HeaderIcon: 'fa fa-exclamation-circle text-warning',
          IsCrossEnable: true,
          SizeClass: 'modal-sm'
        },

        action: '',
        item: null
      };

      this.displayNotifybox = true;

      return;
    }
  }

  exportToExcel() {

    if (this.ListData.length === 0) {
    }
    else {
      this.IsLoading = true;

      let month = new Date().getMonth() + 1;
      this.ExcelFileName = 'P2W-' + this.tabName + '-Details' + new Date().getDate() + '-' + month + '-' + new Date().getFullYear() + ' ' + new Date().toLocaleTimeString()

      let tempdata = [];
      tempdata.push({
        Name: this.tabName + ' Data', Data: this.ListData
        , Columns: !isNullOrUndefined(this.Config.DownloadColumns) && this.Config.DownloadColumns.length > 0 ? this.Config.DownloadColumns.map((x: any) => x.FieldName) : null
        , fldtemplate: this.ListTemplate.filter(x => x.Group === (this.selectedTab) + "List")
      });

      this.excelexport.generateExcelTable(tempdata);

      this.IsLoading = false;
    }
  }

  showFilter(event?: any): void {
    this.DispFilter = !this.DispFilter;

    if (!isNullOrUndefined(event)) {
      this.curFiltCondn = event.curFiltCondn;
    }
  }

  setFilter(event: any) {
    if (event.sCondn.length > 0) {
      this.ListData = asEnumerable(this.ListDataALL)
        .Where($ => (eval(event.sCondn)))
        .ToArray();
      this.dataTotalCount = this.ListData.length;
    }
    else {
      this.ListData = this.ListDataALL;
      this.dataTotalCount = this.ListData.length;
    }
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'DELETE':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "I";
          this.Delete(event.item);
        }
        break;
      case 'ENABLE':
        if (event.result.toUpperCase() === 'YES') {
          event.item.RowStatus = "A";
          this.Delete(event.item);
        }
        break;
      case 'CANCELEDIT':
        if (event.result.toUpperCase() === 'YES') {
          this.ReloadGetData();
        }
        break;
      case 'EDIT':
        if (event.result.toUpperCase() === 'YES') {
          this.Title = this.tabName + " Edit";
          this.getDetailsById
            (
              this.selectedTab == "user" ? event.item.UserId :
                this.selectedTab == "role" ? event.item.RoleId : 0
            );
          this.viewName = "EditView";
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }
}
