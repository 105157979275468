<div class="h-100">
    <nav aria-label="breadcrumb" class="breadcrumbSection">
        <div class="d-flex w-100 justify-content-between">
            <!-- <h1 class="sr-only">Deal Parameters</h1>
            <h2 class="sr-only">Deal Parameters</h2> -->
            <ng-container>
                <ul class="breadcrumb m-0 flex-fill">
                    <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page"><a
                            routerLink="/app/create-deal/{{DealHeaderId}}">Deal Configuration</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page"><a
                            routerLink="/app/model-config/{{DealHeaderId}}">Model Configuration</a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page"><a
                            routerLink="/app/model-config/ModelBaseline/{{DealHeaderId}}">Model Baselining</a>
                    </li>
    
                    <li class="breadcrumb-item active" aria-current="page">Lot Solution Effort Modelling</li>
                    <li class="breadcrumb-item" aria-current="page">
                        <a [routerLink]="['/app/lot-solution-pricing-model/pricingmodel', DealHeaderId, DealLineItemId, DealSubLineItemId]"
                            [state]="{ selectedData: DealData }">Lot Solution Price Modelling</a>
                    </li>
    
                </ul>
    
                <ul class="flex-fill m-0 navbar-nav p-3 breadcrumb" *ngIf="MainModule =='AM'">
                    <li>
                        <span class="progress headerProgress ms-auto">
                            <span class="progress-bar bg-warning headerProgressBar"
                                [ngStyle]="{'width':DealProgressMeter+'%'}">{{DealProgressMeter}}%</span>
                        </span>
                    </li>
                </ul>
            </ng-container>
    
        </div>
    </nav>
    <div class="contentScroll p-3 simpleCard myDealCard">
        <div class="card h-100 m-0">
            <div class="p-1">
                <div class="row mx-2 deal-details planInfoWraper filters">
                    <div class="col-2">
                        <label for="clientname" class="fw-bold">Client Name:&nbsp;&nbsp;</label>
                        {{DealData.Client}}
                    </div>
                    <div class="col-2">
                        <label for="dealname" class="fw-bold">Deal Name:&nbsp;&nbsp;</label>
                        {{DealData.DealName}}
                    </div>
                    <div class="col-2">
                        <label for="thorid" class="fw-bold">Thor Opportunity ID:&nbsp;&nbsp;</label>
                        {{DealData.ThorId}}
                    </div>
                    <div class="col-2">
                        <label for="contracttype" class="fw-bold">Contract Type:&nbsp;&nbsp;</label>
                        {{DealData.ContractTypeDesc}}
                    </div>
                    <div class="col-2">
                        <label for="contracttype" class="fw-bold">Model ID:&nbsp;&nbsp;</label>
                        {{DealData.ModelID}}
                    </div>
                    <div class="col-2">
                        <label for="dealterm" class="fw-bold">Deal Term (in Years):&nbsp;&nbsp;</label>
                        {{DealData.DealTerm}}
                    </div>
                    <div class="col-2">
                        <label for="dealterm" class="fw-bold">Model Configuration Approach:&nbsp;&nbsp;</label>
                        {{DealData.AmApproachDesc}}
                    </div>
                    <div class="col-2">
                        <label for="dealcountry" class="fw-bold">Deal Contracting Country:&nbsp;&nbsp;</label>
                        <span *ngIf="DealData.CountryDesc">{{DealData.CountryDesc}}</span>
                        <span *ngIf="!DealData.CountryDesc">{{DealData.DealContractCountrydesc}}</span>
                    </div>
                    <div class="col-2">
                        <label for="currency" class="fw-bold">Currency:&nbsp;&nbsp;</label>
                        {{DealData.Currency}}
                    </div>
                    <div class="col-2">
                        <label for="rateoption" class="fw-bold">Rate Modelling Option:&nbsp;&nbsp;</label>
                        <span *ngIf="DealData.RateModellingOptionDesc">{{DealData.RateModellingOptionDesc}}</span>
                        <span *ngIf="!DealData.RateModellingOptionDesc">{{DealData.RateModellingDesc}}</span>
                    </div>
                </div>
            </div>

            <div class="card-header">
                <h3>
                    <span>{{ModuleHeaderName | translate}}</span>
                    <a href="javascript:void(0);" (click)="showHelpIconMessage()" class="HeaderHelpIconTooltip">
                        <i class="fa fa-question-circle fa-ques" data-toggle="tooltip"></i>
                    </a>
                </h3>
            </div>

            <div class="card-body overflow-auto">

                <p class="mb-1">
                    <span><i>Note:  Negative values for inputs are not allowed.</i></span>
                </p>
                <br>

                <p class="mb-1">
                    <span>Please click on the lot to enter details</span>
                </p>

                <ng-container
                    *ngIf="(TowerDetailsTemplate != null && TowerDetailsTemplate != undefined && TowerDetailsTemplate.length > 0)">
                    <grid-dispctrl #TowerDetailsGrid [FieldTemplate]="TowerDetailsTemplate" [GridData]="TowerDetails"
                        [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
                        [IsPagination]="IsPagination"   (FieldChange)="onChange($event)"></grid-dispctrl>
                </ng-container>

                <br>

                <ng-container *ngIf="DealSubLineItemId > 0">
                    <ng-container
                        *ngIf="(ServiceDomainFormTemplate != null && ServiceDomainFormTemplate != undefined && ServiceDomainFormTemplate.length > 0
                    && ServiceDomainFormDetails != null && ServiceDomainFormDetails != undefined && serviceDomainLoad)">
                        <customform #serviceDomainForm [FormTemplate]="ServiceDomainFormTemplate"
                            [FormData]="ServiceDomainFormDetails" [FormProp]="FormProp" [FormConfig]="FormConfig"
                            [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                            (FieldChange)="onChange($event)">
                        </customform>
                    </ng-container>

                    <br>
                    <br>

                    <ng-container
                        *ngIf="SelectedTowerDetails != null && SelectedTowerDetails != undefined && SelectedTowerDetails.DealSubLineItemId > 0">
                        <delivery-model *ngIf="ModuleName == 'DeliveryModel'"
                            [selectedLineItemData]="SelectedTowerDetails"
                            [ServiceDomainDetails]="ServiceDomainFormDetails"></delivery-model>

                        <location-mix *ngIf="ModuleName == 'LocationMix'" [selectedLineItemData]="SelectedTowerDetails"
                            [ServiceDomainDetails]="ServiceDomainFormDetails"></location-mix>

                        <productivty *ngIf="ModuleName == 'ProductivityImpact'"
                            [selectedLineItemData]="SelectedTowerDetails"
                            [ServiceDomainDetails]="ServiceDomainFormDetails"></productivty>

                        <staffing *ngIf="ModuleName == 'StaffingModel'"
                            [selectedLineItemData]="SelectedTowerDetails"
                            [ServiceDomainDetails]="ServiceDomainFormDetails"></staffing>
                    </ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="DealSubLineItemId == 0">
                <div class="filters buttonHolder" id="create-deal">
                    <div class="d-flex justify-content-end">
                        <ng-container *ngIf="ActionButtons != null && ActionButtons != undefined">
                            <ng-container *ngFor="let actionBar of ActionButtons 
                  | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                                <button type="button" [ngClass]="actionBar.CustomClass"
                                    (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                                    value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                                    | translate}}
                                </button>
                            </ng-container>



                        </ng-container>

                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>

<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<modal-popup id="OpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>This panel is part of the core build-up of the model within the construct defines earlier in profiles. This is
        where each lot under sub-tower/tower is modeled for the proposed solution resource efforts. The sub-panels are
        Delivery Location modeling, effort distribution between locations, productivity % for annuity/managed
        services/projects/enhancements, and staffing model as per best practice/country-level grade pyramids
        adjustments.</p>
</modal-popup>

<modal-popup id="HeaderOpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
    <p>This panel is part of the core build-up of the model within the construct defines earlier in profiles. This is
        where each lot under sub-tower/tower is modeled for the proposed solution resource efforts. The sub-panels are
        Delivery Location modeling, effort distribution between locations, productivity % for annuity/managed
        services/projects/enhancements, and staffing model as per best practice/country-level grade pyramids
        adjustments.</p>
</modal-popup>