<ng-container>
  <!-- Header Section -->
  <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
    <!-- Logo Section -->
    <a class="navbar-brand" aria-label="Capgemini Logo Redirect to Home Page" href="index.html">
        <img src="assets/images/Capgemini-Logo.svg" alt="Capgemini" /></a>
  </nav>


  <!-- Body Section -->
  <div id="bodyContainer" class="bodyContainer login">
    <div class="contentScroll full">
        <div class="d-flex flex-column justify-content-between h-100">
            <div class="line-image"><span class="sr-only">Capgemini Line image canvas</span></div>
            <div class="h-100">
                <div class="row align-items-stretch g-0 h-100">
                    <div class="col-7">
                        <div class="bgWrapper">
                            <span class="sr-only">Background image canvas</span>
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="row g-0 justify-content-center align-items-center h-100">
                            <div class="col-9 simpleCard customPosition">
                                <div class="loginContainer">
                                    <h2 class="text-center">Background Verification</h2>
                                    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
                                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>
                                                    <span>Register</span>
                                                </h3>
                                            </div>
                                            <div class="card-body overflow-auto py-4">
                                                <p class="small text-secondary mb-2">You can fill all your details below
                                                    to register</p>
                                                <div class="row small-gutter form-inline">
                                                    <ng-container *ngIf="!(showOTP || validOTP)">
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label for="ed1" class="required">Email Address</label>
                                                                <input id="ed1" type="text"
                                                                    class="form-control form-control-sm" 
                                                                    formControlName="email"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />

                                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="(showOTP && !validOTP)">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="emp1" class="required">Enter OTP</label>
                                                                <div>
                                                                    <div class="input-group">
                                                                        <span class="input-group-text"
                                                                            id="basic-addon1"><i
                                                                                class="ion ion-md-key"></i></span>
                                                                        <input type="text" id="txtOTP"
                                                                            class="form-control form-control-sm"
                                                                            aria-label="Mail OTP"
                                                                            aria-describedby="basic-addon1">
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="text-danger" >
                                                                {{showOTP}}
                                                            </div> -->
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="validOTP">
                                                        <!-- <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label for="ed2" class="required">First Name</label>
                                                                <input id="ed2" type="text"
                                                                    class="form-control form-control-sm" 
                                                                    formControlName="firstName"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />

                                                                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label for="ed3" class="required">Last Name</label>
                                                                <input id="ed3" type="text"
                                                                    class="form-control form-control-sm" 
                                                                    formControlName="lastName" 
                                                                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />

                                                                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label for="ed4" class="required">Password</label>
                                                                <!-- <input type="password" placeholder="********"
                                                                    class="form-control form-control-sm" 
                                                                    formControlName="password" 
                                                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" /> -->

                                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                                                    <div *ngIf="f.password.errors.minlength">Password must be at least 8 characters</div>
                                                                    <div *ngIf="f.password.errors.maxlength">Password must not exceed 20 characters</div>
                                                                </div>

                                                                <!-- <textarea class="form-control" readonly rows="3">{{RegistryData.EncryptedPassword}}</textarea> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label for="ed5" class="required">Confirm Password</label>
                                                                <!-- <input type="password" placeholder="********"
                                                                    class="form-control form-control-sm" 
                                                                    formControlName="confirmPassword"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" /> -->

                                                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                                                    <!-- <div *ngIf="f.confirmPassword.errors.mustMatch">Password and confirm not match</div> -->
                                                                    <div *ngIf="f.confirmPassword.errors.matchingPasswords">Password and Confirm Password does not match</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    
                                                </div>
                                            </div>
                                            <div class="filters buttonHolder px-3">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <a aria-label="Back To Login" href="#" [routerLink]="['/login']"
                                                            class="text-decoration-underline align-self-center">Back to Login</a>
                                                    </div>
                                                    <div>
                                                        <ng-container *ngIf="!(showOTP || validOTP)">
                                                            <button type="button" class="btnCustom btnPrimary rounded-pill"
                                                                (click)="generateOTP()">
                                                                <span>Send OTP</span><i class="ion ion-md-arrow-forward"
                                                                    aria-hidden="true"></i>
                                                            </button>
                                                        </ng-container>

                                                        <ng-container *ngIf="(showOTP && !validOTP)">
                                                            <button type="button" class="btnCustom btnPrimary rounded-pill"
                                                                (click)="validateOTP()">
                                                                <span>Verify OTP</span><i class="ion ion-md-arrow-forward"
                                                                    aria-hidden="true"></i>
                                                            </button>
                                                        </ng-container>

                                                        <ng-container *ngIf="validOTP">
                                                            <button type="button" class="btnCustom btnPrimary rounded-pill"
                                                                (click)="onSubmit()">
                                                                <span>Register</span><i class="ion ion-md-arrow-forward"
                                                                    aria-hidden="true"></i>
                                                            </button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="d-flex justify-content-center bg-light p-2">
                    <div class="small align-self-center">Copyright © 2022 Capgemini All rights reserved.
                    </div>

                </div>
            </div>
        </div>
    </div>
  </div>

</ng-container>



<!-- Loader section -->
<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>


<!-- Notification section -->
<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action" [curitem]="notify.item"
        (selected)="setNotifyRes($event) " (close)="closeNotifybox() "></notification>
</div>
