<!-- Master Report Component -->

<div class="h-100">
  <nav aria-label="breadcrumb" class="breadcrumbSection">
    <div class="d-flex w-100 justify-content-between">
      <h1 class="sr-only">Reports</h1>
      <h2 class="sr-only">Reports</h2>
      <ul class="breadcrumb m-0 flex-fill">

        <li class="breadcrumb-item" aria-current="page"><a routerLink="/app/home">Dashboard</a>
        </li>
        <li class="breadcrumb-item" aria-current="page"><a href="javascript: void(0);"
            (click)="navigatetoDealPage()">Deal
            Parameters</a></li>
        <li class="breadcrumb-item" aria-current="page"><a href="javascript:void(0);"
            (click)="navigatetoservicepage()">Services</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Output Screens</li>
      </ul>
    </div>
  </nav>

  <div class="p-2 contentScroll full">
    <div class="row mx-2 my-4 deal-details planInfoWraper filters">
      <div class="col-3">
        <label for="dealid" class="fw-bold">Deal Id:&nbsp;&nbsp; </label>{{selectedDeal.DealId}}
      </div>
      <div class="col-3">
        <label for="dealname" class="fw-bold">Opportunity Name:&nbsp;&nbsp;
        </label>{{selectedDeal.DealName}}
      </div>
      <div class="col-3">
        <label for="acc" class="fw-bold">Client Name:&nbsp;&nbsp;</label>{{selectedDeal.Client}}
      </div>

      <div class="col-3">
        <label for="region" class="fw-bold">Region:&nbsp;&nbsp; </label>{{selectedDeal.RegionDesc}}
      </div>

      <div class="col-3">
        <label for="coun" class="fw-bold">Contracting Country:&nbsp;&nbsp;
        </label>{{selectedDeal.CountryDesc}}
      </div>
      <div class="col-3">
        <label for="region" class="fw-bold">Competitors:&nbsp;&nbsp;
        </label>{{selectedDeal.CompetitorsDesc}}
      </div>
      <div class="col-3">
        <label for="region" class="fw-bold">Currency:&nbsp;&nbsp; </label>{{selectedDeal.CurrencyDesc}}
      </div>
      <div class="col-3">
        <label for="region" class="fw-bold">Run Duration in Months:&nbsp;&nbsp; </label>{{selectedDeal.DealTerm}}
      </div>
      <div class="col-3">
        <!-- <i class="fa fa-download fa_custom">
          <span>
            Download TCV Estimator
          </span>
        </i> -->


        <ul class="actionList">
          <li style="cursor: pointer;" id="2q6fh_Filter" accesskey="I"><a href="javascript:this.blur();" (click)="DownloadExcel()"
              aria-label="Download TCV"><i container="body" placement="top" ng-reflect-container="body"
                ng-reflect-placement="top" class="fa fa-download"
                ng-reflect-tooltip="Download TCV Estimate"></i><span>D<u>o</u>wnload TCV Estimate</span>
              <!--bindings={}-->
            </a></li>
        </ul>

      </div>


    </div>
    <!-- <ul class="nav nav-tabs" id="tabChildView"> -->
    <!-- <ng-container *ngFor="let tab of serviceTabs | filterBy: {CanShow: true}">
        <li class="nav-item">
          <a class="nav-link" href="#tabChildDetails" [ngClass]="{'active': (tab.Name==selectedTab)}"
            data-bs-toggle="tab" role="tab" [attr.aria-selected]="(tab.Name==selectedTab)" [attr.aria-label]="tab.Name"
            (click)="onTabChange(tab)">{{tab.DisplayName |
            translate}}</a>
        </li>
      </ng-container> -->

    <div>
      <p class="mb-3">Disclaimer : The information provided in the tool is privileged or confidential and is the property of the Capgemini Group, not to be circulated outside of the Capgemini Sales/Presales team working on the deal.</p>
    </div>
    <ng-container *ngIf="(reportTabs != null && reportTabs != undefined 
                      && reportTabs.length > 0
                     )">
                     <div class="OP-tabs">
      <tabset [tabList]="reportTabs" [tabType]="'plaintype'" (selected)="setSelectedTab($event)">
        <ng-container *ngFor="let tab of reportTabs">

          <ng-container *ngIf="selectedTab==tab.Name">



            <!-- <ng-container *ngIf="(_Template != null && _Template != undefined && _Template.length > 0 
                          && (_Template | filterBy: {Group: ServicesTabs.Name}).length>0)">
                      <grid #ManagedServiceGrid
                          [FieldTemplate]="(_Template | filterBy: {Group: tab.Name})"
                          [GridData]="_Data" [GridProp]="_GridProp" [GridConfig]="_GridConfig"
                          [GridDataTotalCount]="_DataTotalCount" (ActionBarClick)="actButtonClick($event)" (FieldClick)="onClick($event)"
                          (FieldChange)="onChange($event)" [parent]="this">
                      </grid>

                  </ng-container> -->

            <ng-container *ngIf="selectedTab=='TotalTCV'">
              <br>
              <div class="row">
                <div class="col-8">
                  <!--Chart code starts-->
                  <div class="card bg-white">
                    <div class="card-header bg-primary text-white">
                      <h3>
                        <span>Total Estimated Contract Value - In Millions ({{Currency}})</span>
                      </h3>
                    </div>
                    <div class="card-body overflow-auto">
                      <!-- <request #requestcmp [caseId]="caseId">
                      </request> -->
                      <ng-container *ngIf="TotaltcvGraphData!=null && TotaltcvGraphData!=''">
                        <app-charts #totaltcvgraph [graphData]="TotaltcvGraphData"></app-charts>
                      </ng-container>

                      <!-- <div id="chart-totalTCV" class="P2WCharts"></div> -->
                    </div>
                  </div>
                  <!--Chart code ends-->
                </div>
              </div>
              <div class="card-body overflow-auto">
                <ng-container
                  *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
                  <grid-dispctrl #TotaltcvReportData
                    [FieldTemplate]="(repListTemplate | filterBy: {Group: 'TotalTCVReport'})"
                    [GridData]="repListData_TCV" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
                    (ActionBarClick)="actButtonClick($event,repListData_TCV,'TotalTCVReport')"
                    (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                  </grid-dispctrl>
                </ng-container>
              </div>

            </ng-container>

            <ng-container *ngIf="selectedTab=='ManagedServices'">
              <br>
              <div class="row">
                <div class="col-8">
                  <!--Chart code starts-->
                  <div class="card bg-white">
                    <div class="card-header bg-primary text-white">
                      <h3>
                        <span>Total Estimated Contract Value - In Millions ({{Currency}})</span>
                      </h3>
                    </div>
                    <div class="card-body overflow-auto">
                      <ng-container *ngIf="MSGraphData!=null && MSGraphData!=''">
                        <app-charts #totaltcvgraph [graphData]="MSGraphData"></app-charts>
                      </ng-container>

                      <!-- <div id="chart-totalTCV" class="P2WCharts"></div> -->
                    </div>
                  </div>
                  <!--Chart code ends-->
                </div>
              </div>
              <div class="card-body overflow-auto">
                <ng-container
                  *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
                  <grid-dispctrl #TotaltcvReportData [FieldTemplate]="(repListTemplate | filterBy: {Group: 'MSReport'})"
                    [GridData]="repListData_MS" [GridProp]="GridProp" [GridDataTotalCount]="repListData_MS_Count"
                    (ActionBarClick)="actButtonClick($event,repListData_MS,'MSReport')" (FieldClick)="onClick($event)"
                    (FieldChange)="onChange($event)">
                  </grid-dispctrl>
                </ng-container>
              </div>

              <br>

              <!--ACCORDIAN-->
              <div class="card-body overflow-auto">
                <div>
                  <div class="form-group">
                    <!-- <label for="sel-id" class="pe-2">Percentile</label>
                  <select id="sel-id" class="form-select w-25">
                    <option value="sel-id1" disabled="">--Select--</option>
                    <option value="sel-id2" selected="">Internal 25th Percentile</option>
                    <option value="sel-id3">Internal 50th Percentile</option>
                    <option value="sel-id4">External 25th Percentile</option>
                    <option value="sel-id5">External 50th Percentile</option>
                  </select> -->

                    <!-- <customform  [FormTemplate]="(repListTemplate | filterBy: {Group: 'TCVPercentile'})"
                      [FormData]="" [FormProp]="ReportFormProp" [FormConfig]="FormConfig"
                      [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                    </customform> -->

                    <ng-container *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                      && (repListTemplate | filterBy: {Group: 'TCVPercentile'}).length > 0)">
                      <customform #PercentileForm
                        [FormTemplate]="(repListTemplate | filterBy: {Group: 'TCVPercentile'})"
                        [FormData]="InternalPercentileDropdown" [FormProp]="ReportFormProp" [FormConfig]="FormConfig"
                        [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                      </customform>

                    </ng-container>
                  </div>
                </div>


              </div>
              <div class="card-body overflow-auto">


                <div class="accordion" [id]="'_ManagedservicesReports_Tower_Year'">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'_Tower_Year'">
                      <button (click)="accordianclick('istoweryearCollapsed')"
                        class="accordion-button collapsed justify-content-between" type="button"
                        data-bs-toggle="collapse" [attr.data-bs-target]="'#__Tower_Year_H'" aria-expanded="false"
                        [attr.aria-controls]="'__Tower_Year_H'">
                        <div>
                          Tower Wise Year Wise - In Thousands ({{Currency}})
                        </div>
                      </button>
                    </h2>
                    <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                        [attr.aria-labelledby]="sectionData.Name"> -->
                    <div [id]="'__Tower_Year_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'S__Tower_Year_'">
                      <div class="accordion-body">
                        <div>
                          <div class="form-group">
                            <!-- <label for="sel-id" class="pe-2">Tower</label>
                            <select id="sel-id" class="form-select w-25">
                              <option value="sel-id1" disabled="">--Select--</option>
                              <option value="sel-id2" selected="">Tower 1</option>
                              <option value="sel-id3">Tower 2</option>
                              <option value="sel-id4">Tower 3</option>
                              <option value="sel-id5">Tower 4</option>
                            </select> -->

                            <ng-container *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                                          && (repListTemplate | filterBy: {Group: 'TCVTower'}).length > 0)">

                              <customform #TowerForm [FormTemplate]="(repListTemplate | filterBy: {Group: 'TCVTower'})"
                                [FormData]="TowerDropdown" [FormProp]="ReportFormProp" [FormConfig]="FormConfig"
                                [GlobalConfig]="GlobalConfig" (FieldClick)="onClick($event)"
                                (FieldChange)="onChange($event)">
                              </customform>

                            </ng-container>

                          </div>
                        </div>
                        <div class="row small-gutter form-inline">
                          <div class="col-6">
                            <!--Chart code starts-->
                            <div class="card bg-white">
                              <div class="card-header bg-primary text-white">


                                <h3>
                                  <span>TOWERWISE BREAKUP - RUN</span>
                                </h3>
                              </div>
                              <div class="card-body overflow-auto">
                                <ng-container
                                  *ngIf="TowerGraphData!=null && TowerGraphData!='' && internalPercentileData != '' && internalPercentileData != undefined &&internalPercentileData!=null">
                                  <div id="TowerWiseChart" style="width: 100%; height: 500px"></div>
                                </ng-container>


                                <!-- <app-charts #TowerGraphData [graphData]="TowerGraphData"></app-charts> -->

                              </div>
                            </div>
                            <!--Chart code ends-->
                          </div>
                          <div class="col-6">
                            <!--Chart code starts-->
                            <div class="card bg-white">
                              <div class="card-header bg-primary text-white">
                                <h3>
                                  <span>TOP 5 TECHNOLOGIES</span>
                                </h3>
                              </div>
                              <div class="card-body overflow-auto">
                                <ng-container
                                  *ngIf="TechnologyGraphData!=null && TechnologyGraphData!=''&& internalPercentileData != '' && internalPercentileData != undefined && internalPercentileData!='' &&TowerDropDownData!=null && TowerDropDownData != undefined && TowerDropDownData!='' ">
                                  <!-- <app-charts #TechnologyGraphData [graphData]="TechnologyGraphData"></app-charts> -->
                                  <div id="TechnologiesWiseChart" style="width: 100%; height: 500px"></div>
                                </ng-container>
                              </div>
                            </div>
                            <!--Chart code ends-->
                          </div>
                        </div>
                        <div class="row small-gutter form-inline">
                          <div class="col-6">
                            <!--Chart code starts-->
                            <div class="card bg-white">
                              <div class="card-header bg-primary text-white">
                                <h3>
                                  <span>Y-O-Y Trend</span>
                                </h3>
                              </div>
                              <div class="card-body overflow-auto">
                                <!-- <app-charts #totaltcvgraph [graphData]="TotaltcvGraphData"></app-charts> -->
                                <ng-container
                                  *ngIf="YOYGraphData!=null && YOYGraphData!='' &&internalPercentileData != '' && internalPercentileData != undefined &&internalPercentileData!=null">
                                  <div id="yoychart" style="width: 100%; height: 500px"></div>
                                </ng-container>
                              </div>
                            </div>
                            <!--Chart code ends-->
                          </div>

                        </div>

                        <div class="pt-4">
                          <ng-container>
                            <ng-container
                              *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                                            && (repListTemplate | filterBy: {Group: 'MSTowerYearwiseReport'}).length > 0)">

                              <grid-dispctrl #GRID_{{sectionData.Name}}
                                [FieldTemplate]="repListTemplate | filterBy: {Group: 'MSTowerYearwiseReport'}"
                                [GridProp]="GridProp" [GridData]="repListData_MS_Tower_YearWise"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_Tower_YearWise,'MSTowerYearwiseReport')"
                                [GridDataTotalCount]="repListData_MS_Tower_YearWise_Count">
                              </grid-dispctrl>
                            </ng-container>

                            <br>
                            <!-- <ng-container
                              *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                                        && (repListTemplate | filterBy: {Group: 'MSTowerwiseSummaryReport'}).length > 0)">

                              <grid-dispctrl #GRID_{{sectionData.Name}}
                                [FieldTemplate]="repListTemplate | filterBy: {Group: 'MSTowerwiseSummaryReport'}"
                                [GridProp]="GridProp" [GridData]="repListData_MS_Tower_Summary"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_Tower_Summary,'MSTowerwiseSummaryReport')"
                                [GridDataTotalCount]="repListData_MS_Tower_Summary_Count">
                              </grid-dispctrl>
                            </ng-container> -->




                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>

                <br>
                <div class="accordion" [id]="'_ManagedservicesReports_Tower_Summary'">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'_Tower_Summary'">
                      <button (click)="accordianclick('istowerwisesummaryCollapsed')"
                        class="accordion-button collapsed justify-content-between" type="button"
                        data-bs-toggle="collapse" [attr.data-bs-target]="'#__Tower_Summary_H'" aria-expanded="false"
                        [attr.aria-controls]="'__Tower_Summary_H'">
                        <div>
                          Tower Wise Summary - In Thousands ({{Currency}})
                        </div>
                      </button>
                    </h2>

                    <div [id]="'__Tower_Summary_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'S__Tower_Summary_'">
                      <div class="accordion-body">
                        <div class="pt-4">
                          <ng-container>
                            <ng-container
                              *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                                          && (repListTemplate | filterBy: {Group: 'MSTowerwiseSummaryReport'}).length > 0)">

                              <grid-dispctrl #GRID_{{sectionData.Name}}
                                [FieldTemplate]="repListTemplate | filterBy: {Group: 'MSTowerwiseSummaryReport'}"
                                [GridProp]="GridProp" [GridData]="repListData_MS_Tower_Summary"
                                [GridDataTotalCount]="repListData_MS_Tower_Summary_Count">
                              </grid-dispctrl>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <br>
                <div class="accordion" [id]="'_ManagedservicesReports_Technology_Summary'">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'_Technology_Summary'">
                      <button (click)="accordianclick('istechnologyCollapsed')"
                        class="accordion-button collapsed justify-content-between" type="button"
                        data-bs-toggle="collapse" [attr.data-bs-target]="'#__Technology_Summary_H'"
                        aria-expanded="false" [attr.aria-controls]="'__Technology_Summary_H'">
                        <div>
                          Technology & Year Wise Summary - In Thousands ({{Currency}})
                        </div>
                      </button>
                    </h2>
                    <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="sectionData.Name"> -->
                    <div [id]="'__Technology_Summary_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'S__Technology_Summary_'">
                      <div class="accordion-body">
                        <div class="pt-4">
                          <ng-container>
                            <ng-container
                              *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                                          && (repListTemplate | filterBy: {Group: 'MSTechnologyYearwiseReport'}).length > 0)">
                              <div class="p-2 contentScroll full simpleCard managedServicesTableCard">
                                <!-- <grid-dispctrl #GRID_{{sectionData.Name}}
                                  [FieldTemplate]="repListTemplate_MSTechnologyYearwiseReport"
                                  [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                  (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                  [GridDataTotalCount]="repListData_MS_Technology_Summary_Count">
                                </grid-dispctrl> -->
                                <ng-container *ngIf="internalPercentileData == '' || internalPercentileData == undefined || internalPercentileData == null">
                                  <ng-container *ngIf="(repListTemplate_unitPriceReport!=null && repListTemplate_unitPriceReport!=undefined && repListTemplate_unitPriceReport.length > 0
                                                )">
                                      <div class="p-2 contentScroll full simpleCard managedServicesTableCard">
                              
                                          <!-- <grid-dispctrl #GRID_{{sectionData.Name}}
                                          [FieldTemplate]="MSTechnologyYearwiseReportInput"
                                          [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                          (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                          [GridDataTotalCount]="repListData_MS_Technology_Summary_Count">
                                        </grid-dispctrl>
                                          <br> -->
                                          <grid-dispctrl #GRID_{{sectionData.Name}} [FieldTemplate]="MSTechnologyYearwiseReportInput_concat"
                                              [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                              (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                              [GridDataTotalCount]="repListData_MS_Technology_Summary_Count">
                                          </grid-dispctrl>
                                      </div>
                                  </ng-container>
                              </ng-container>
                              <ng-container *ngIf="internalPercentileData!='' && internalPercentileData!=undefined">
                                  <ng-container>
                                      <div class="p-2 contentScroll full simpleCard managedServicesTableCard">
                              
<!--                               
                                      <grid-dispctrl #GRID_{{sectionData.Name}}
                                          [FieldTemplate]="MSTechnologyYearwiseReportInput"
                                          [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                          (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                          [GridDataTotalCount]="repListData_MS_Technology_Summary_Count">
                                        </grid-dispctrl>
                                          <br> -->
                                          <ng-container *ngIf="internalPercentileData=='E25th Percentile'">
                                              <grid-dispctrl #ManagedServiceGrid [FieldTemplate]="PercentileFileteredTemplate_E25th_TS_concat"
                                                  [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                                  [GridDataTotalCount]="repListData_MS_Technology_Summary_Count"
                                                  (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                                  (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                              </grid-dispctrl>
                                          </ng-container>
                                          <ng-container *ngIf="internalPercentileData=='I25th Percentile'">
                                              <grid-dispctrl #ManagedServiceGrid [FieldTemplate]="PercentileFileteredTemplate_I25th_TS_concat"
                                                  [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                                  [GridDataTotalCount]="repListData_MS_Technology_Summary_Count"
                                                  (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                                  (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                              </grid-dispctrl>
                                          </ng-container>
                                          <ng-container *ngIf="internalPercentileData=='E50th Percentile'">
                                              <grid-dispctrl #ManagedServiceGrid [FieldTemplate]="PercentileFileteredTemplate_E50th_TS_concat"
                                                  [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                                  [GridDataTotalCount]="repListData_MS_Technology_Summary_Count"
                                                  (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                                  (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                              </grid-dispctrl>
                                          </ng-container>
                                          <ng-container *ngIf="internalPercentileData=='I50th Percentile'">
                                              <grid-dispctrl #ManagedServiceGrid [FieldTemplate]="PercentileFileteredTemplate_I50th_TS_concat"
                                                  [GridProp]="GridProp" [GridData]="repListData_MS_Technology_Summary"
                                                  [GridDataTotalCount]="repListData_MS_Technology_Summary_Count"
                                                  (ActionBarClick)="actButtonClick($event,repListData_MS_Technology_Summary,'MSTechnologyYearwiseReport')"
                                                  (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
                                              </grid-dispctrl>
                                          </ng-container>
                              
                                      </div>
                                  </ng-container>
                              
                              
                              </ng-container>




                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
                <br>
                <div class="accordion" [id]="'_ManagedservicesReports_UnitPrice_Year'">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'_UnitPrice_Year'">
                      <button (click)="accordianclick('unitPriceCollapsed')"
                        class="accordion-button collapsed justify-content-between" type="button"
                        data-bs-toggle="collapse" [attr.data-bs-target]="'#_UnitPrice_Year_H'" aria-expanded="false"
                        [attr.aria-controls]="'_UnitPrice_Year_H'">
                        <div>
                          Unit Price Year Wise ({{Currency}})
                        </div>
                      </button>
                    </h2>
                    <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="sectionData.Name"> -->
                    <div [id]="'_UnitPrice_Year_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'S_UnitPrice_Year_'">
                      <div class="accordion-body">
                        <div class="pt-4">
                          <ng-container *ngIf="internalPercentileData == '' || internalPercentileData == undefined ||internalPercentileData == null">
                            <ng-container
                              *ngIf="(repListTemplate_unitPriceReport!=null && repListTemplate_unitPriceReport!=undefined && repListTemplate_unitPriceReport.length > 0
                                          )">
                              <div class="p-2 contentScroll full simpleCard managedServicesTableCard">
                               
                               
                                <grid-dispctrl #ManagedServiceGrid
                                [FieldTemplate]="repListTemplate_unitPriceReport_concat"
                                [GridProp]="GridProp" [GridData]="repListData_MS_UnitPrice_Year"
                                [GridDataTotalCount]="repListData_MS_UnitPrice_Year_Count"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_UnitPrice_Year,'MSYearwiseUnitPriceReport')"
                                (FieldClick)="onClick($event)"    (FieldChange)="onChange($event)" >
                                </grid-dispctrl>
                              </div>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="internalPercentileData!=''&& internalPercentileData!=undefined">
                            <ng-container
                            >
                            <div class="p-2 contentScroll full simpleCard managedServicesTableCard">
                              
                              
                
                              <ng-container *ngIf="internalPercentileData=='E25th Percentile'">
                                <grid-dispctrl #ManagedServiceGrid
                                [FieldTemplate]="PercentileFileteredTemplate_E25th_concat"
                                [GridProp]="GridProp" [GridData]="repListData_MS_UnitPrice_Year"
                                [GridDataTotalCount]="repListData_MS_UnitPrice_Year_Count"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_UnitPrice_Year,'MSYearwiseUnitPriceReport')"
                                (FieldClick)="onClick($event)"    (FieldChange)="onChange($event)" >
                                </grid-dispctrl>
                              </ng-container>
                              <ng-container *ngIf="internalPercentileData=='I25th Percentile'">
                                <grid-dispctrl #ManagedServiceGrid
                                [FieldTemplate]="PercentileFileteredTemplate_I25th_concat"
                                [GridProp]="GridProp" [GridData]="repListData_MS_UnitPrice_Year"
                                [GridDataTotalCount]="repListData_MS_UnitPrice_Year_Count"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_UnitPrice_Year,'MSYearwiseUnitPriceReport')"
                                (FieldClick)="onClick($event)"    (FieldChange)="onChange($event)" >
                                </grid-dispctrl>
                              </ng-container>
                              <ng-container *ngIf="internalPercentileData=='E50th Percentile'">
                                <grid-dispctrl #ManagedServiceGrid
                                [FieldTemplate]="PercentileFileteredTemplate_E50th_concat"
                                [GridProp]="GridProp" [GridData]="repListData_MS_UnitPrice_Year"
                                [GridDataTotalCount]="repListData_MS_UnitPrice_Year_Count"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_UnitPrice_Year,'MSYearwiseUnitPriceReport')"
                                (FieldClick)="onClick($event)"    (FieldChange)="onChange($event)" >
                                </grid-dispctrl>
                              </ng-container>
                              <ng-container *ngIf="internalPercentileData=='I50th Percentile'">
                                <grid-dispctrl #ManagedServiceGrid
                                [FieldTemplate]="PercentileFileteredTemplate_I50th_concat"
                                [GridProp]="GridProp" [GridData]="repListData_MS_UnitPrice_Year"
                                [GridDataTotalCount]="repListData_MS_UnitPrice_Year_Count"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_UnitPrice_Year,'MSYearwiseUnitPriceReport')"
                                (FieldClick)="onClick($event)"    (FieldChange)="onChange($event)" >
                                </grid-dispctrl>
                              </ng-container>
                             
                            </div>
                          </ng-container>


                          </ng-container>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>

                <br>
                <div class="accordion" [id]="'_ManagedservicesReports_YoYPerReduction'">
                  <div class="accordion-item">
                    <h2 class="accordion-header" [id]="'_YoYPerReduction'">
                      <button (click)="accordianclick('yoyCollapsed')"
                        class="accordion-button collapsed justify-content-between" type="button"
                        data-bs-toggle="collapse" [attr.data-bs-target]="'#__YoYPerReduction_H'" aria-expanded="false"
                        [attr.aria-controls]="'__YoYPerReduction_H'">
                        <div>
                          Y-o-Y Contract Value Reduction
                        </div>
                      </button>
                    </h2>
                    <!-- <div [id]="'_'+sectionData.Name+'_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="sectionData.Name"> -->
                    <div [id]="'__YoYPerReduction_H'" class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'S__YoYPerReduction_'">
                      <div class="accordion-body">
                        <div class="pt-4">
                          <ng-container>
                            <ng-container
                              *ngIf="(repListTemplate!=null && repListTemplate!=undefined && repListTemplate.length > 0
                                          && (repListTemplate | filterBy: {Group: 'MSYoYPerReductionReport'}).length > 0)">

                              <grid-dispctrl #GRID_{{sectionData.Name}}
                                [FieldTemplate]="repListTemplate | filterBy: {Group: 'MSYoYPerReductionReport'}"
                                [GridProp]="GridProp" [GridData]="repListData_MS_YoYReduction"
                                (ActionBarClick)="actButtonClick($event,repListData_MS_YoYReduction,'MSYoYPerReductionReport')"
                                [GridDataTotalCount]="repListData_MS_YoYReduction_Count">
                              </grid-dispctrl>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>


              </div>

            </ng-container>

            <ng-container *ngIf="selectedTab=='RateCard'">
              <br>
              <div class="card bg-white">
                <div class="card-header bg-primary text-white">
                  <h3>
                    <span>TCV - In Millions ({{Currency}})</span>
                  </h3>
                </div>
                <div class="card-body overflow-auto">
                  <ng-container
                    *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
                    <grid-dispctrl #TotaltcvReportData
                      [FieldTemplate]="(repListTemplate | filterBy: {Group: 'RCReport'})" [GridData]="repListData_RC"
                      [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount_RC"
                      (ActionBarClick)="actButtonClick($event,repListData_RC,'RCReport')" (FieldClick)="onClick($event)"
                      (FieldChange)="onChange($event)">
                    </grid-dispctrl>
                  </ng-container>
                </div>
              </div>
            </ng-container>


            <ng-container *ngIf="selectedTab=='PandC'">
              <br>
              <div class="card bg-white">
                <div class="card-header bg-primary text-white">
                  <h3>
                    <span>TCV - In Millions ({{Currency}})</span>
                  </h3>
                </div>
                <div class="card-body overflow-auto">
                  <ng-container
                    *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
                    <grid-dispctrl #TotaltcvReportData
                      [FieldTemplate]="(repListTemplate | filterBy: {Group: 'PCReport'})" [GridData]="repListData_PC"
                      [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount_PC"
                      (ActionBarClick)="actButtonClick($event,repListData_PC,'PCReport')" (FieldClick)="onClick($event)"
                      (FieldChange)="onChange($event)">
                    </grid-dispctrl>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="selectedTab=='Tool'">
              <br>
              <div class="card bg-white">
                <div class="card-header bg-primary text-white">
                  <h3>
                    <span>TCV - In Millions ({{Currency}})</span>
                  </h3>
                </div>
                <div class="card-body overflow-auto">
                  <ng-container
                    *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
                    <grid-dispctrl 
                      [FieldTemplate]="(repListTemplate | filterBy: {Group: 'TOOLREPORT'})" [GridData]="repListData_TOOLS"
                      [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount_Tools"
                      (ActionBarClick)="actButtonClick($event,repListData_TOOLS,'TOOLREPORT')" (FieldClick)="onClick($event)"
                      (FieldChange)="onChange($event)">
                    </grid-dispctrl>
                  </ng-container>
                </div>
              </div>
            </ng-container>


            <ng-container *ngIf="selectedTab=='Hardware'">
              <br>
              <div class="card bg-white">
                <div class="card-header bg-primary text-white">
                  <h3>
                    <span>TCV - In Millions ({{Currency}})</span>
                  </h3>
                </div>
                <div class="card-body overflow-auto">
                  <ng-container
                    *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
                    <grid-dispctrl 
                      [FieldTemplate]="(repListTemplate | filterBy: {Group: 'HARDWAREREPORT'})" [GridData]="repListData_HARDWARE"
                      [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount_HW"
                      (ActionBarClick)="actButtonClick($event,repListData_HARDWARE,'HARDWAREREPORT')" (FieldClick)="onClick($event)"
                      (FieldChange)="onChange($event)">
                    </grid-dispctrl>
                  </ng-container>
                </div>
              </div>
            </ng-container>

          </ng-container>

        </ng-container>
      </tabset>
      </div>

    </ng-container>
    <!-- </ul> -->
    <!-- <div class="tab-content">
      <div class="tab-pane fade show active" id="tabChildDetails" role="tabpanel" aria-labelledby="tabChildDetails">
        <div class="row">
          <div class="col-8"> -->
    <!--Chart code starts-->
    <!-- <div class="card bg-white">
              <div class="card-header bg-primary text-white">
                <h3>
                  <span>Total Estimated Contract Value - In Millions (USD)</span>
                </h3>
              </div>
              <div class="card-body overflow-auto">
                <div id="chart-totalTCV" class="P2WCharts"></div>
              </div>
            </div> -->
    <!--Chart code ends-->
    <!-- </div>
        </div> -->

    <!-- <ng-container>
          <ng-container *ngIf="(repListTemplate != null && repListTemplate != undefined && repListTemplate.length > 0)">
            <grid-dispctrl #TotaltcvReportData [FieldTemplate]="repListTemplate" [GridData]="TotaltcvReportData"
              [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)"
              (FieldChange)="onChange($event)">
            </grid-dispctrl>
          </ng-container>
        </ng-container> -->
    <!-- <div class="gridContainer">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered tblGrid fixed columnBorderBlack">
                    <thead>
                      <tr>
                        <td width="420"></td>
                        <th class="text-center columnBorderWhite" scope="col" colspan="4">In Million USD
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" width="100">Benchmarking Estimation for "Client Name" THOR ID-OP xxxx</th>
                        <th scope="col" width="60" rowspan="2">Internal 25th Percentile</th>
                        <th scope="col" width="60" rowspan="2">Internal 50th Percentile</th>
                        <th scope="col" width="60" rowspan="2">External 25th Percentile</th>
                        <th scope="col" width="60" rowspan="2">External 50th Percentile</th>
                      <tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="col" width="80">Managed Services Year-1 CV</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="80">Managed Services contract duration CV</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="80">Transformation(P&C) Total CV</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="80">FTE ratecard CV</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="80">Transition Charges</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="80">Governance Charges</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" width="80">Service Management Charges</th>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th scope="col" width="80">Estimated Total Contract Value</th>
                        <td aria-label="No Value"></td>
                        <td aria-label="No Value"></td>
                        <td aria-label="No Value"></td>
                        <td aria-label="No Value"></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div> -->
  </div>
</div>

<!-- </div>
</div> -->

<!-- Loader section -->
<div [hidden]="!IsLoading">
  <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<!-- Filter Pop-up -->
<div *ngIf="DispFilter">
  <filter *ngIf="(repListTemplate!= undefined && repListTemplate!= null && repListTemplate.length > 0)"
    [FieldTemplate]="repListTemplate" [GridData]="repListDataALL" (close)="showFilter($event)"
    (selected)="setFilter($event)" [InitialCondn]="curFiltCondn">
  </filter>
</div>


<!-- Excel Download -->
<excel-export #excelexport [FileName]="ExcelFileName"></excel-export>

<!-- Notification section -->
<div *ngIf="displayNotifybox">
  <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action" [curitem]="notify.item"
    (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>