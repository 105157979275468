import { Component, EventEmitter, Input, OnInit, Output, ElementRef, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'src/app/shared/app.sharedservice';
// import { isNullOrUndefined } from 'util';
import { Info } from './notification.interface';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit, AfterViewInit {
  @Input() notifyinfo: any; //Info = new Info();
  @Input() curaction: any = {};
  @Input() curitem: any = {};
  @Input() timeout: any = {};

  @Output() close = new EventEmitter();
  @Output() selected = new EventEmitter();

  public focusableEles!: HTMLElement[];
  public firstFocusableEle!: HTMLElement;
  public lastFocusableEle!: HTMLElement;
  public element:any;

  constructor(private toastr: ToastrService, private eleRef: ElementRef) {
    this.element = eleRef.nativeElement;
   }

  ngOnInit() {
    this.closeNotifybox();
    // close modal on background click
    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'modal') {
          this.closenotification();
      }
  });
  }

  ngAfterViewInit() {
    setTimeout(() => {

      this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
         input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
         button:not([disabled]), [tabindex="0"]`);
      // this.focusableEles = Array.prototype.slice.call(this.focusableEles);

      if (this.focusableEles.length > 0) {
        this.firstFocusableEle = this.focusableEles[0];
        this.lastFocusableEle = this.focusableEles[this.focusableEles.length - 1];

        this.firstFocusableEle.focus();
      }

      let yesctrl = this.eleRef.nativeElement.querySelector('input[name="btnYes"]') as HTMLElement;
      if (!isNullOrUndefined(yesctrl)) {
        yesctrl.focus();
      }
      else {
        let noctrl = this.eleRef.nativeElement.querySelector('input[name="btnNo"]') as HTMLElement;
        if (!isNullOrUndefined(noctrl)) {
          noctrl.focus();
        }
      }
      let divele: HTMLDivElement = this.eleRef.nativeElement.querySelector('.modal-dialog');
      if (!isNullOrUndefined(divele)) {
        divele.addEventListener('keydown', (e) => { this.handleKeyDown(e); });
      }

    }, 5);
  }

  handleKeyDown(event: any) {
    let KEY_TAB = 9;
    let KEY_ESC = 27;

    switch (event.keyCode) {
      case KEY_TAB:
        if (this.focusableEles.length === 1) {
          event.preventDefault();
          break;
        }

        if (event.shiftKey) {
          if (document.activeElement === this.firstFocusableEle) {
            event.preventDefault();
            this.lastFocusableEle.focus();
          }
        }
        else {
          if (document.activeElement === this.lastFocusableEle) {
            event.preventDefault();
            this.firstFocusableEle.focus();
          }
        }
        break;
      // case KEY_ESC:
      //   this.close();
      //   break;
      default:
        break;
    }

  }

  closenotification():void{
    this.close.emit({ result: this.notifyinfo.no, item: this.curitem, action: this.curaction, info: this.notifyinfo });
  }

  closeNotifybox(): void {
    setTimeout(() =>{
      this.close.emit({ result: this.notifyinfo.no, item: this.curitem, action: this.curaction, info: this.notifyinfo });
    },this.timeout);
  }

  btnYesClick(result: string): void {
    if (!isNullOrUndefined(this.notifyinfo.IsRemarksApplicable) && this.notifyinfo.IsRemarksApplicable === true) {
      if (!isNullOrUndefined(this.notifyinfo.IsRemarksMandatory) &&
        this.notifyinfo.IsRemarksMandatory && !isNullOrUndefined(this.notifyinfo.Remarks) && (this.notifyinfo.Remarks!.trim().length > 0)) {
        this.selected.emit({ result: result, item: this.curitem, action: this.curaction, info: this.notifyinfo });
      }
      else {
        this.toastr.warning('please enter the remarks', this.notifyinfo.header);
      }
    }
    else {
      this.selected.emit({ result: result, item: this.curitem, action: this.curaction, info: this.notifyinfo });
    }
  }

  btnNoClick(result: string): void {

    this.selected.emit({ result: result, item: this.curitem, action: this.curaction, info: this.notifyinfo });
  }
}
