import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppSharedService, AutoUnsubscribe } from '../shared/app.sharedservice';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
@AutoUnsubscribe
export class AdminComponent implements OnInit {

  public subscriptionList$: Subscription[] = [];
  public Title: any = 'Admin';

  constructor(public _appSharedService: AppSharedService,private title: Title, private _route: ActivatedRoute, private eleRef: ElementRef, private _router: Router) {

    let subscribe$: Subscription = this._router.events.pipe(
      // filter(event => event instanceof NavigationStart)
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // change in routing
      console.log('change in routing >> ');
      console.log(event.url);
    });

    this.subscriptionList$.push(subscribe$);



   

  }

  ngOnInit(): void {
    this.title.setTitle(this.Title);
  }

}