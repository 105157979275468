<ng-container>
  <div class="gridAction">
    <div class="d-flex justify-content-between">
      <ng-container *ngIf="IsPagination">
      <label class="d-flex">
        <span class="pe-2">Show</span>
        <select class="form-select form-select-sm align-self-center" name="complex-table1_length"
          aria-controls="complex-table1" [(ngModel)]="curItemsPerPage" (change)="pageItemsChange($event)">
          <option value=10>10</option>
          <option value=25>25</option>
          <option value=50>50</option>
          <option value=100>100</option>
          <!-- <option value=-1>All</option> -->
        </select>
        <span class="ps-2">entries</span>
      </label>
      </ng-container>
      <ng-container *ngIf="ActionBarConfig.ActionBarList!=null && ActionBarConfig.ActionBarList!=undefined && ActionBarConfig.ActionBarList.length>0">
          <actionbar [actionList]="ActionBarConfig.ActionBarList" (butClick)="actButtonClick($event)"
            [noActionClass]="ActionBarConfig.noActionClass">
          </actionbar>
      </ng-container>
    </div>
  </div>

  <div class="gridContainer">
    <div class="table-responsive">
      <table class="table table-hover table-bordered tblGrid fixed" [ngClass]="TableCSS"
        [ngStyle]="{'min-width': TableWidth+'%'}" (keydown)="cellKey($event)">
        <caption class="sr-only">Grid Data</caption>
        <thead *ngIf="DisplayHeader" [ngClass]="HeaderCSS">
          <tr>
            <!-- [ngStyle]="{ 'width': setGridColWidth(key.FieldName, key.ColumnWidth), 'text-align':'center', 'padding-right': '2px'}" 
                            (click)="sortGridCol(key.FieldName)"
                        -->
            <th *ngIf="IsParallelEditEnabled" class="ParallelEditHeader" data-resize="false" scope="col"></th>
            <th *ngIf="IsNested" class="NestedHeader" data-resize="false" scope="col">&nbsp;</th>
            <th *ngFor="let key of FieldTemplate" scope="col"
                [ngClass]="{'sticky-column-left': (key.ColumnFixed != null && key.ColumnFixed != undefined && key.ColumnFixed.toLowerCase() == 'left'),
                          'sticky-column-right': (key.ColumnFixed != null && key.ColumnFixed != undefined && key.ColumnFixed.toLowerCase() == 'right'),
                          'd-none': (key.ResponsiveMediaSize != null && key.ResponsiveMediaSize != undefined && key.ResponsiveMediaSize.length > 0
                                      && ((screenSize == 0 && key.ResponsiveMediaSize.indexOf('xs') < 0) || (screenSize == 1 && key.ResponsiveMediaSize.indexOf('sm') < 0) 
                                          || (screenSize == 2 && key.ResponsiveMediaSize.indexOf('md') < 0) || (screenSize == 3 && key.ResponsiveMediaSize.indexOf('lg') < 0) 
                                          || (screenSize == 4 && key.ResponsiveMediaSize.indexOf('xl') < 0)))}"
              [ngStyle]="{ 'width': ((key.Control?.Type == 'checkbox' && key.DisplayName.length == 0 )? '32px': setGridColWidth(key.FieldName, key.ColumnWidth)),
                            'min-width': (key.Control?.Type == 'checkbox'? '32px': '100px')}"
              [hidden]="!showGridCol(key.FieldName)"
              [attr.data-resize]="(key.Control?.Type == 'checkbox'? false : (key.IsColResizable == null || key.IsColResizable == undefined)? true: key.IsColResizable)"
              [attr.data-fixedcol]="((key.ColumnFixed != null && key.ColumnFixed != undefined && key.ColumnFixed.length > 0)? true: false)">

              <div (click)="sort(key)" [ngClass]="{'text-right': key.Control?.InputType == 'number'}">
                <div *ngIf="key.HeaderTemplate == undefined || key.HeaderTemplate.length == 0">
                  <p class="text-truncate my-0">
                    <span
                      [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                      container="body" placement="bottom">{{key.DisplayName | translate}}</span>
                      <span class="sr-only">sort</span>
                    <i class="fa"
                      [ngClass]="{'fa-sort-up': (sortcolumn == (key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0? key.FieldName: key.LinkedField) && !isDesc), 
                                   'fa-sort-down': (sortcolumn == (key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0? key.FieldName: key.LinkedField) && isDesc) }"
                      aria-hidden="true"> </i>
                  </p>
                </div>

                <div *ngIf="key.HeaderTemplate != undefined && key.HeaderTemplate.length > 0"
                  [innerHtml]="key.HeaderTemplate" class="text-truncate"
                  [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                  container="body" placement="bottom">
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <!-- [ngStyle]="{ 'overflow': 'hidden', 'background-color': ((IsEditable == 'false' && (item_indx+1)%2 == 0) ? AlternateRowColor.even: AlternateRowColor.odd)}" -->
        <tbody *ngIf="isValidDataType()">
          <!-- <ng-template ngFor let-item [ngForOf]="GridData | filterBy: {FilterField: 0}" let-item_indx = "index"> -->

          <!-- <ng-template ngFor let-item [ngForOf]="GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
                      | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage):(Pagination.CurrentPage*Pagination.ItemsPerPage)"
                        let-item_indx="index"> -->
          <ng-template ngFor let-item
            [ngForOf]="PaginationConfig.ServerLoad? (GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
                        | filterBy: DataFilter | filterBy: {PageIndex: (Pagination.CurrentPage - 1)}) : 
                      (GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
                        | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage):(Pagination.CurrentPage*Pagination.ItemsPerPage))"
            let-item_indx="index">
            <!-- <ng-template ngFor let-item [ngForOf]="currentDataList" let-item_indx="index"> -->
            <tr
              [ngClass]="{'row-deleted': ((item.IsRevised !=null && item.IsRevised !=undefined && item.IsRevised == true && item.RowStatus != null && item.RowStatus != undefined && item.RowStatus == 'D')? true: false),
                            'text-bold': ((item.IsRowHighlight !=null && item.IsRowHighlight !=undefined && item.IsRowHighlight == true && (item.RowStatus == null || item.RowStatus == undefined || item.RowStatus != 'D'))? true: false)}">
              <!-- Parallel Editing User -->
              <td id="{{gridRandomId}}_Cell_{{item_indx}}--2_" class="ParallelEdit readonly" *ngIf="IsParallelEditEnabled"
                [tabindex]="item_indx" (click)="cellClick($event, item_indx, -2, item)">
                <ng-container
                  *ngIf="item.RowLockStatus != undefined && item.RowLockStatus != null && item.UserLockBy != undefined">
                  <span *ngIf="item.RowLockStatus == 2" class="badge badge-pill badge-success"
                    title="{{item.UserLockBy}}">
                    {{ item.UserLockBy.substring(0,1) }} </span>
                  <span *ngIf="item.RowLockStatus != 2" class="badge badge-pill badge-warning"
                    title="{{item.UserLockBy}}">
                    {{ item.UserLockBy.substring(0,1) }} </span>
                </ng-container>
              </td>
              <!-- Expand/Collapse Icon for Nested -->
              <td id="{{gridRandomId}}_Cell_{{item_indx}}--1_" class="Nested p-0" *ngIf="IsNested" [tabindex]="item_indx"
                (click)="cellClick($event, item_indx, -1, item)">
                <!-- Nested Data already loaded  -->
                <ng-container *ngIf="!IsNestedDynamicData">
                  <div *ngIf="(GridData | filterBy: getNestedData(item[ParentField])).length > 0">
                    <div class="text-center" *ngIf="this.selectedNest.indexOf(item[ParentField]) < 0"
                      (click)="setDispNested(true, item[ParentField])">
                      <span class="sr-only">caret-right</span>
                      <i class="fa fa-caret-right"></i>
                    </div>
                    <div class="text-center" *ngIf="this.selectedNest.indexOf(item[ParentField]) >= 0"
                      (click)="setDispNested(false, item[ParentField])">
                      <span class="sr-only">caret-down</span>
                      <i class="fa fa-caret-down"></i>
                    </div>
                  </div>
                </ng-container>

                <!-- Nested Data loaded dynamically on expand arrow click -->
                <ng-container *ngIf="IsNestedDynamicData">
                  <!-- (click)="getDynamicNestedData(item)" -->
                  <div *ngIf="item[LeafCountField] > 0">
                    <div class="text-center" *ngIf="this.selectedNest.indexOf(item[ParentField]) < 0"
                      (click)="setDispNested(true, item[ParentField]);getDynamicNestedData(item)">
                      <span class="sr-only">caret-right</span>
                      <i class="fa fa-caret-right"></i>
                    </div>
                    <div class="text-center" *ngIf="this.selectedNest.indexOf(item[ParentField]) >= 0"
                      (click)="setDispNested(false, item[ParentField])">
                      <span class="sr-only">caret-down</span>
                      <i class="fa fa-caret-down"></i>
                    </div>
                  </div>
                </ng-container>

              </td>
              <!-- Template columns -->
              <td *ngFor="let key of FieldTemplate;let key_indx= index" id="{{gridRandomId}}_Cell_{{item_indx}}-{{key_indx}}_" [ngClass]="{'readonly': getReadOnly(key, item), 'required': getRequired(key, item), 
                    'sticky-column-left': (key.ColumnFixed != null && key.ColumnFixed != undefined && key.ColumnFixed.toLowerCase() == 'left'),
                    'sticky-column-right': (key.ColumnFixed != null && key.ColumnFixed != undefined && key.ColumnFixed.toLowerCase() == 'right'),
                    'd-none': (key.ResponsiveMediaSize != null && key.ResponsiveMediaSize != undefined && key.ResponsiveMediaSize.length > 0
                                      && ((screenSize == 0 && key.ResponsiveMediaSize.indexOf('xs') < 0) || (screenSize == 1 && key.ResponsiveMediaSize.indexOf('sm') < 0) 
                                          || (screenSize == 2 && key.ResponsiveMediaSize.indexOf('md') < 0) || (screenSize == 3 && key.ResponsiveMediaSize.indexOf('lg') < 0) 
                                          || (screenSize == 4 && key.ResponsiveMediaSize.indexOf('xl') < 0)))}"
                [class]="getCustomClass(key, item)" [ngStyle]="{'overflow': 'hidden'}" [tabindex]="item_indx"
                (click)="cellClick($event, item_indx, key_indx, item)"
                [attr.rowspan]="(key.RowMerge? getRowSpan(key, item): 1)"
                [hidden]="!showGridCol(key.FieldName) || (key.RowMerge? !showMergeCell(key, item, item_indx) : false)"
                [attr.data-thtitle]="key.DisplayName">
                <!-- (dblclick)="cellDblClick($event, item_indx, key_indx)" -->

                <!-- <p>{{item_indx + '-' + key_indx}}</p> -->
                <field-control [parentRandomId]="gridRandomId" [item_indx]="item_indx" [key_indx]="key_indx" [item]="item" [key]="key"
                  [cellLock]="cellLock" [parent]="this.parent" [parentNode]="this" [selectedCell]="selectedCell"
                  (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"
                  (fldonEditStart)="fldonEditStart($event)" [attr.data-ctrltype]="key.Control?.Type"></field-control>
                  <!-- [showcontrolalways]="key.ShowControlAlways" [initedit]="IsEditable"></field-control> -->

                <!--<p [innerHtml]="key.LabelTemplate"></p>-->
                <!--<p *compile="key.LabelTemplate; context: {item : item, item_indx: item_indx, parent: this, key:key}"></p>-->
              </td>
            </tr>

            <!-- New row added for showing the details of the column hidden because of ui responsiveness -->
            <tr class="row-detail border-top-0" *ngIf="(getColumnCount()-datacellindx) != FieldTemplate.length"
              [ngClass]="{'row-deleted': ((item.IsRevised !=null && item.IsRevised !=undefined && item.IsRevised == true && item.RowStatus != null && item.RowStatus != undefined && item.RowStatus == 'D')? true: false),
              'text-bold': ((item.IsRowHighlight !=null && item.IsRowHighlight !=undefined && item.IsRowHighlight == true && (item.RowStatus == null || item.RowStatus == undefined || item.RowStatus != 'D'))? true: false)}">

              <td class="border-top-0" [attr.colspan]="getColumnCount()">
                <div>
                  <ng-container *ngFor="let key of FieldTemplate;let key_indx= index">
                    <div *ngIf="(key.ResponsiveMediaSize != null && key.ResponsiveMediaSize != undefined && key.ResponsiveMediaSize.length > 0
                                && ((screenSize == 0 && key.ResponsiveMediaSize.indexOf('xs') < 0) || (screenSize == 1 && key.ResponsiveMediaSize.indexOf('sm') < 0) 
                                    || (screenSize == 2 && key.ResponsiveMediaSize.indexOf('md') < 0) || (screenSize == 3 && key.ResponsiveMediaSize.indexOf('lg') < 0) 
                                    || (screenSize == 4 && key.ResponsiveMediaSize.indexOf('xl') < 0)))"
                      id="{{gridRandomId}}_Cell_{{item_indx}}-{{key_indx}}_"
                      [ngClass]="{'readonly': getReadOnly(key, item), 'required': getRequired(key, item), 'row pb-2': true}"
                      [class]="getCustomClass(key, item)" [ngStyle]="{'overflow': 'hidden'}" [tabindex]="item_indx"
                      (click)="cellClick($event, item_indx, key_indx, item)" [hidden]="!showGridCol(key.FieldName)"
                      [attr.data-thtitle]="key.DisplayName">

                      <div class="col-4">
                        <p class="text-truncate text-bold my-0">
                          <span
                            [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                            container="body" placement="bottom">{{key.DisplayName | translate}}:</span>
                        </p>
                      </div>

                      <div class="col-8">
                        <field-control [parentRandomId]="gridRandomId" [item_indx]="item_indx" [key_indx]="key_indx" [item]="item" [key]="key"
                          [cellLock]="cellLock" [parent]="this.parent" [parentNode]="this" [selectedCell]="selectedCell"
                          (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"
                          (fldonEditStart)="fldonEditStart($event)" [attr.data-ctrltype]="key.Control?.Type"></field-control>
                          <!-- [showcontrolalways]="key.ShowControlAlways" [initedit]="IsEditable"></field-control> -->
                      </div>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>

            <!-- SubGrid -->
            <tr class="subgrid"
              *ngIf="IsNested && !NestedTemplate.Inline && (this.selectedNest.indexOf(item[ParentField]) >= 0)">
              <!-- Dummy cell -->
              <td class="ParallelEdit readonly" *ngIf="IsParallelEditEnabled" [tabindex]="item_indx"></td>

              <td class="Nested"></td>
              <!-- [GridData]="GridData | filterBy: { FilterField: item[ParentField]}" -->
              <!-- <td [attr.colspan]="FieldTemplate.length + 1" style="padding: 0px !important"> -->
              <td [tabindex]="item_indx" *ngIf="!NestedTemplate.Inline" [attr.colspan]="getColumnCount()-datacellindx">

                <ng-container *ngIf="NestedTemplate.Type == 1">
                  <!-- Nested Data already loaded  -->
                  <ng-container *ngIf="!IsNestedDynamicData">
                    <grid [FieldTemplate]="NestedTemplate.Template" [GridData]="getNestedDataForHierarchy(item)"
                      [GridProp]="NestedTemplate.Property" [GridConfig]="NestedTemplate.Config"
                      [GlobalConfig]="GlobalConfig" [NestedTemplate]="NestedTemplate"
                      [IsParallelEditEnabled]="IsParallelEditEnabled" (FieldClick)="fldOnClick($event)"
                      (FieldChange)="fldOnChange($event)" [cellLock]="cellLock" [parent]="this.parent"
                      [cellRequired]="cellRequired" (FieldEditStart)="fldonEditStart($event)"
                      (PageChange)="pageChanged($event)" [HierarchyParentData]="item[ParentField]"></grid>
                  </ng-container>

                  <!-- Nested Data loaded dynamically on expand arrow click -->
                  <ng-container *ngIf="IsNestedDynamicData">
                    <!-- [GridDataTotalCount]="NestedGridDataTotalCount[item[this.ParentField]]" -->
                    <grid [FieldTemplate]="NestedTemplate.Template" [GridData]="getNestedDataForHierarchy(item)"
                      [GridProp]="NestedTemplate.Property" [GridConfig]="NestedTemplate.Config"
                      [GlobalConfig]="GlobalConfig" [NestedTemplate]="NestedTemplate"
                      [IsParallelEditEnabled]="IsParallelEditEnabled" (FieldClick)="fldOnClick($event)"
                      (FieldChange)="fldOnChange($event)" [cellLock]="cellLock" [parent]="this.parent"
                      [cellRequired]="cellRequired" (FieldEditStart)="fldonEditStart($event)"
                      (PageChange)="pageChanged($event)" [HierarchyParentData]="item[ParentField]"></grid>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="NestedTemplate.Type == 2">
                  <!-- <form *ngIf="NestedTemplate.Type == 2" ></form> -->
                </ng-container>

              </td>
            </tr>

            <!-- Subitems as part of Grid -->
            <ng-container
              *ngIf="(IsNested && NestedTemplate.Inline && (this.selectedNest.indexOf(item[ParentField]) >= 0))">
              <tr class="subitem"
                *ngFor="let subitem of (GridData | filterBy: getNestedData(item[ParentField])); let subitem_indx = index"
                [hidden]="!(IsNested && NestedTemplate.Inline && (this.selectedNest.indexOf(item[ParentField]) >= 0))"
                [ngClass]="{'row-deleted': ((subitem.IsRevised !=null && subitem.IsRevised !=undefined && subitem.IsRevised == true && subitem.RowStatus != null && subitem.RowStatus != undefined && subitem.RowStatus == 'D')? true: false),
                        'text-bold': ((subitem.IsRowHighlight !=null && subitem.IsRowHighlight !=undefined && subitem.IsRowHighlight == true && (subitem.RowStatus == null || subitem.RowStatus == undefined || subitem.RowStatus != 'D'))? true: false)}">
                <!-- Dummy cell -->
                <td id="{{gridRandomId}}_Cell_{{item_indx}}-{{subitem_indx}}--2_" class="ParallelEdit readonly"
                  *ngIf="IsParallelEditEnabled" [tabindex]="item_indx"
                  (click)="cellClick($event, item_indx+'-'+subitem_indx, -2, subitem)"></td>
                <td id="{{gridRandomId}}_Cell_{{item_indx}}-{{subitem_indx}}--1_" class="Nested readonly"
                  style="background-color: rgba(0, 0, 0, 0.1);" [tabindex]="item_indx" *ngIf="subitem_indx == 0"
                  [attr.rowspan]="(GridData | filterBy: getNestedData(item[ParentField])).length"
                  (click)="cellClick($event, item_indx+'-'+subitem_indx, -1, subitem)"></td>

                <td *ngFor="let innerkey of NestedTemplate.Template;let innerkey_indx= index"
                  id="{{gridRandomId}}_Cell_{{item_indx}}-{{subitem_indx}}-{{innerkey_indx}}_"
                  [ngClass]="{'readonly': getReadOnly(innerkey, subitem), 'required': getRequired(innerkey, subitem),
                            'sticky-column-left': (innerkey.ColumnFixed != null && innerkey.ColumnFixed != undefined && innerkey.ColumnFixed.toLowerCase() == 'left'),
                            'sticky-column-right': (innerkey.ColumnFixed != null && innerkey.ColumnFixed != undefined && innerkey.ColumnFixed.toLowerCase() == 'right')}"
                  [ngStyle]="{ 'overflow': 'hidden'}" [class]="getCustomClass(innerkey, item)"
                  [hidden]="!showGridCol(innerkey.FieldName)" [tabindex]="item_indx"
                  (click)="cellClick($event, item_indx+'-'+subitem_indx, innerkey_indx, subitem)">
                  <!-- (dblclick)="cellDblClick($event, item_indx+'-'+subitem_indx, innerkey_indx)" -->

                  <!-- <p>{{item_indx+'-'+subitem_indx + '-' + innerkey_indx}}</p> -->
                  <field-control [parentRandomId]="gridRandomId" [item_indx]="item_indx+'-'+subitem_indx" [key_indx]="innerkey_indx" [item]="subitem"
                    [key]="innerkey" [cellLock]="cellLock" [parent]="this.parent" [parentNode]="this"
                    [selectedCell]="selectedCell" (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"
                    (fldonEditStart)="fldonEditStart($event)" [attr.data-ctrltype]="innerkey.Control?.Type"
                    [showcontrolalways]="innerkey.ShowControlAlways"></field-control>

                </td>
              </tr>
            </ng-container>
          </ng-template>
        </tbody>
        <tbody *ngIf="GridData.length == 0">
          <tr>
            <td class="text-center" style="background-color: #ffffff" [attr.colspan]="getColumnCount()">
              -- No Record Found --
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!--<pagination *ngIf="isValidDataType() && PaginationConfig.Required" class="d-flex justify-content-start" [totalItems]="(GridData | filterBy: DataFilter).length"
  [itemsPerPage]="Pagination.ItemsPerPage" maxSize="1" [boundaryLinks]="true" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
  lastText="&raquo;" [rotate]="false" [(ngModel)]="Pagination.CurrentPage" (pageChanged)="pageChanged($event)"></pagination>-->
  <ng-container *ngIf="IsPagination">
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="align-self-center" *ngIf="GridData.length > 0">
        <!-- <ng-container > -->
        <!-- <span class="px-2">Showing 1 to 6 of 6 entries</span> -->
        <!-- <span class="px-2">Showing {{((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)+1}} to {{(Pagination.CurrentPage*Pagination.ItemsPerPage)}} of {{(GridDataTotalCount)}} entries</span> -->
        <span class="px-2">Showing {{((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)+1}} to
          {{((Pagination.CurrentPage-1)*Pagination.ItemsPerPage) + getCurrentDataList().length}} of
          {{(GridDataTotalCount)}} entries</span>
        <!-- </ng-container> -->
      </li>
      <li class="ms-auto page-item">
        <pagination *ngIf="isValidDataType() && PaginationConfig.Required" [totalItems]="GridDataTotalCount"
          [itemsPerPage]="Pagination.ItemsPerPage" [maxSize]="5" [boundaryLinks]="true" 
          previousText="&lsaquo; Previous" nextText="Next &rsaquo;"
          firstText="&laquo; First" lastText="Last &raquo;" [rotate]="false"
          [(ngModel)]="Pagination.CurrentPage" (pageChanged)="pageChanged($event)"
          (numPages)="totalPagesChanged($event)"></pagination>
      </li>
    </ul>
  </nav>
</ng-container>

  <!--<div *ngIf="IsEditable && GridData.length > 0">
            <!-- [dismissOnTimeout]="6000"
        <alert type="info" >
            <strong>Keys for Navigating/Editing Grid:</strong> 
            <li>Tab/Right Arrow - Move focus to next cell in the right.</li>
            <li>Shift+Tab/Left Arrow - Move focus to previous cell in the left.</li>
            <li>Up Arrow - Move focus to the cell above.</li>
            <li>Down Arrow - Move focus to the cell below.</li>
            <li>F2/Spacebar - Editing a cell.</li>
            <li>F2 - Editing a cell.</li>
            <li>Esc/Enter - Switching out of cell edit.</li>
        </alert>
    </div>-->

</ng-container>