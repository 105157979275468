<!-- <ng-container>
    <div class="col-12">
        <span>Delivery Location Modelling (Lot:{{LotNameforGrid}})</span>
        <a href="javascript:void(0);" (click)="HelpIconClick('subpanel_deliveryModel')" class="HeaderHelpIconTooltip">
            <i class="fa fa-question-circle fa-ques" data-toggle="tooltip"></i>
        </a>
    </div>
    <br>
    <ng-container *ngIf="(DeliveryModelTemplate != null && DeliveryModelTemplate != undefined && DeliveryModelTemplate.length > 0
                && DeliveryModelData != null && DeliveryModelData != undefined && DeliveryModelData.length > 0)">

        <grid-dispctrl #DeliveryModelGrid
            [FieldTemplate]="DeliveryModelTemplate"
            [GridData]="DeliveryModelData" [GridProp]="GridProp" [GridDataTotalCount]="dataTotalCount"
            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)">
        </grid-dispctrl>
    </ng-container>

</ng-container>

<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
</div>

<div *ngIf="displayNotifybox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
        [curitem]="notify.item" (selected)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div> -->

<ng-container *ngIf="DealSubLineItemId > 0">

    <div *ngIf="LotName != null && LotName != ''" class="col-12"
         class="HeaderHelpIconTooltip">
        <span>Delivery Location Modelling (Lot:{{LotName}})</span>
        <i class="fa fa-question-circle fa-ques" data-toggle="tooltip" (click)="HelpIconClick()"></i>
    </div>

    <br>
    <grid-dispctrl *ngIf="DeliveryModelTemplate?.length && DeliveryModelData?.length" #DeliveryModelGrid
    [GridId]="'DL_locationgrid'"  [FieldTemplate]="DeliveryModelTemplate" [GridData]="DeliveryModelData" [GridProp]="GridProp"
        [GridDataTotalCount]="dataTotalCount" (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [cellLock]="setCellLock" [parent]="this">
    </grid-dispctrl>

    <div class="filters buttonHolder" id="create-deal">
        <div class="d-flex justify-content-end">
            <ng-container *ngIf="ActionButtons != null && ActionButtons != undefined">
                <ng-container *ngFor="let actionBar of ActionButtons 
      | orderBy: {property: 'SeqOrder', direction: '1' , type: 'number'}; let i = index">
                    <button type="button" [ngClass]="actionBar.CustomClass"
                        (click)="actionButtonClick(actionBar)" [disabled]="!actionBar.IsEnabled"
                        value="{{actionBar.DisplayName | translate }}">{{actionBar.DisplayName
                        | translate}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>


    <!-- Loader section -->
    <div [hidden]="!IsLoading">
        <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>
    </div>

    <notification *ngIf="displayNotifybox" [notifyinfo]="notify?.info" [timeout]="notify.timeout"
        [curaction]="notify.action" [curitem]="notify.item" (selected)="setNotifyRes($event)"
        (close)="closeNotifybox()">
    </notification>

    <modal-popup id="SubOpenTooltip" style="display: none; font-size: x-small" [modaldetail]="modalInf">
        <p>
            This sub-panel is to define the delivery locations by lot - which locations the services will be delivered
            from
            and where the services will be delivered to. Also, in case of multi-shore locations, identifying the type of
            location – onshore, nearshore, offshore etc. Also, each lot is defined with its attributes in terms of types
            of
            services (Service Domain), technology profile and which practices / delivery unit will be delivering the
            services.
        </p>
    </modal-popup>

</ng-container>