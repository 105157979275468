import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule,Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { FilterModule } from 'src/modules/filter/filter.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { FormModule } from 'src/modules/form/form.module';
import { GridReadonlyModule } from 'src/modules/grid-readonly/grid-readonly.module';
import { GridModule } from 'src/modules/grid/grid.module';
import { ActionBarModule } from 'src/modules/actionbar/actionbar.module';
import { NotificationModule } from 'src/modules/notification/notification.module';
import { ModalModule } from 'src/modules/modal/modal.module';
import { TabSetModule } from 'src/modules/tabset/tabset.module';
import { AmDownloadsComponent } from './am-downloads.component';
import { AmDownloadsService } from './am-downloads.service';

import { SearchByModule } from 'src/modules/searchby/searchby.module';
import { DashboardModule } from 'src/modules/dashboard/dashboard.module';
import { ExcelExportModule } from 'src/modules/excelexport/excelexport.module';
import { AMChartsModule } from '../am-charts/am-charts.module';
import { AmChartsMultipleModule } from '../am-charts-multiple/am-charts-multiple.module';

import { GridDisplayControlModule } from 'src/modules/grid-dispctrl/grid-dispctrl.module';

@NgModule({
  declarations: [   
    AmDownloadsComponent
  ],
  imports: [
    BrowserModule, FormsModule, NgbModule,TabSetModule, TranslateModule,
    LoadingModule, FilterModule, CustomPipeModule, FormModule,
    GridReadonlyModule, FilterPipeModule, GridModule, RouterModule, 
    ActionBarModule, NotificationModule, ModalModule,AMChartsModule,AmChartsMultipleModule,
    
    DashboardModule, SearchByModule,ModalModule,GridDisplayControlModule
  ],
  providers: [Title,AmDownloadsService],
  bootstrap: [AmDownloadsComponent],

})
export class AmDownloadsModule { }
