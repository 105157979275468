import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../shared/_services/utility.service';


export class ExcelExportData {
    Name: string = '';
    Data?: any[] = [];
    ColumnList?: any[] = [];
    FieldTemplate?: any[] = [];

    constructor(init?: Partial<ExcelExportData>) {
        Object.assign(this, init);
    }
}


@Component({
    selector: 'excel-export',
    templateUrl: './excelexport.html'
})

export class ExcelExportComponent implements OnInit {

    @Input() FileName: any;
    @Input() CompTitle!: string;

    public ColumnNames: any;
    public RowData: any;
    public DispLoader: boolean = false;

    @ViewChild('Exceltable', { static: false }) Exceltable!: ElementRef;

    constructor(private toastr: ToastrService, private renderer2: Renderer2) { }

    ngOnInit() { }

    generateExcelTable_old(ExportData: ExcelExportData[], fileName: string) {
        this.DispLoader = true;

        if (!isNullOrUndefined(ExportData) && ExportData.length > 0) {

            const workbook: XLSX.WorkBook = XLSX.utils.book_new();

            ExportData.forEach((sheet: any, index: number) => {
                this.ColumnNames = [];
                this.RowData = [];

                if (sheet.Data.length > 0) {

                    let rowData = JSON.parse(JSON.stringify(sheet.Data));

                    let colFieldNameList = Object.keys(sheet.Data[0]);
                    let colDisplayNameList = Object.keys(sheet.Data[0]);

                    if (!isNullOrUndefined(sheet.ColumnList) && sheet.ColumnList.length > 0) {
                        // colNameList = colNameList.filter((x: any) => (sheet.ColumnList.indexOf(x)) >= 0);
                        colFieldNameList = [...sheet.ColumnList];
                        colDisplayNameList = [...sheet.ColumnList];
                    }

                    if (!isNullOrUndefined(sheet.FieldTemplate) && sheet.FieldTemplate.length > 0) {

                        let colFieldList: any[] = [];
                        let colDispList: any[] = [];

                        colFieldNameList.forEach((value: any) => {
                            let columnName: any = '';

                            let temp = sheet.FieldTemplate.filter((x: any) => x.FieldName === value)
                            if (temp.length > 0) {
                                columnName = temp[0].DisplayName;
                            }

                            if (!isNullOrUndefined(columnName) && columnName !== '') {
                                colFieldList.push(value)
                                colDispList.push(columnName);
                            }

                        });

                        colFieldNameList = colFieldList;
                        colDisplayNameList = colDispList;
                    }

                    rowData = sheet.Data.map((x: any) => colFieldNameList.reduce((o: any, k: any) => (o[k] = x[k], o), {}));

                    this.ColumnNames = colDisplayNameList;
                    this.RowData = JSON.parse(JSON.stringify(rowData));

                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.RowData);

                    XLSX.utils.book_append_sheet(workbook, worksheet, sheet.Name);

                    /* fix headers */
                    XLSX.utils.sheet_add_aoa(worksheet, [this.ColumnNames], { origin: "A1" });

                }
                else {
                    this.toastr.warning('No Data Found!', this.CompTitle);
                    this.DispLoader = false;
                    return;
                }
            });

            XLSX.writeFile(workbook, fileName + '.xlsx');

            this.DispLoader = false;

        }
        else {
            this.DispLoader = false;
            this.toastr.warning('No Data Found!', this.CompTitle);
        }
    }


    generateExcelTable(ExportData: any) {
        this.DispLoader = true;
        if (!isNullOrUndefined(ExportData) && ExportData.length > 0) {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            let table: any;
            // let alphabetArray: any = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            ExportData.forEach((sheet: any, index: number) => {
                this.ColumnNames = [];
                this.RowData = [];

                if (sheet.Data.length > 0) {
                    table = this.renderer2.createElement('table');
                    let tr = table.appendChild(this.renderer2.createElement('tr'));

                    this.ColumnNames = Object.keys(sheet.Data[0]);

                    if (!isNullOrUndefined(sheet.Columns) && sheet.Columns.length > 0) {
                        // this.ColumnNames = this.ColumnNames.filter((x: any) => (sheet.Columns.indexOf(x)) >= 0);
                        this.ColumnNames = sheet.Columns;
                    }

                    this.RowData = sheet.Data;

                    this.ColumnNames.forEach((value: any) => {
                        let columnName: any;

                        if (!isNullOrUndefined(sheet.fldtemplate) && sheet.fldtemplate.length > 0) {
                            if (sheet.fldtemplate.filter((x: any) => x.FieldName === value).length > 0) {
                                columnName = sheet.fldtemplate.filter((x: any) => x.FieldName === value)[0].DisplayName;
                            } else {
                                columnName = '';
                            }
                        } else {
                            columnName = value
                        }

                        if (!isNullOrUndefined(columnName) && columnName !== '') {

                            let th = tr.appendChild(this.renderer2.createElement('th'));
                            th.appendChild(this.renderer2.createText(columnName));
                        }
                        //this.renderer2.setStyle(th,'background-color','#00b4b9')
                    });

                    // let projIdFlag: number = -1;

                    this.RowData.forEach((rows: any) => {

                        let ltr = table.appendChild(this.renderer2.createElement('tr'));

                        this.ColumnNames.forEach((ColumnName: any, colindex: number) => {

                            if (!isNullOrUndefined(sheet.fldtemplate) && sheet.fldtemplate.length > 0) {
                                if (sheet.fldtemplate.filter((x: any) => x.FieldName === ColumnName).length === 0) {
                                    ColumnName = '';
                                }
                            }

                            if (!isNullOrUndefined(ColumnName) && ColumnName !== '') {
                                let td = ltr.appendChild(this.renderer2.createElement('td'));
                                // if (ColumnName == 'Project') {
                                //     rows[ColumnName] = rows[ColumnName].replace(/-/g, '~');
                                //     // rows[ColumnName] = rows[ColumnName]+'----';

                                //     projIdFlag = colindex;
                                // }
                                td.appendChild(this.renderer2.createText(rows[ColumnName]));
                            }

                        });
                        this.renderer2.appendChild(this.Exceltable.nativeElement, table);
                    });

                    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
                    // let  ws1: any = XLSX.utils.table_to_sheet(table);


                    // if (projIdFlag >= 0) {
                    //     // replace back '~' with '-'
                    //     // identity the column name in excel object and then replace.

                    //     let ref: any = ws["!ref"].split(':');

                    //     let loopcount: number = ref[1].match(/\d+/g).map(Number)[0];
                    //     let loopaphbt = ref[1].replace(/[^a-z]/gi, '');

                    //     var index = alphabetArray.findIndex(item => item == loopaphbt);

                    //     alphabetArray = alphabetArray.slice(0, index + 1);

                    //     // 

                    //     alphabetArray.forEach(element => {

                    //         for (var i = 1; i <= loopcount; i++) {
                    //             let rownumb = element + i;
                    //             if ((!isNullOrUndefined(ws[rownumb].v) || ws[rownumb].v != '') && ws[rownumb].t !== 'n') {
                    //                 (ws[rownumb].v) = (ws[rownumb].v).replace(/~/g, '-');
                    //             } 
                    //         }
                    //     });

                    // }

                    XLSX.utils.book_append_sheet(wb, ws, sheet.Name);
                }
                else {
                    this.toastr.warning('No Data Found!', this.CompTitle);
                    this.DispLoader = false;
                    return;
                }
            });
            setTimeout(() => {
                this.DispLoader = false;
                XLSX.writeFile(wb, this.FileName + '.xlsx');
                this.Exceltable.nativeElement.remove(table);
            }, 500);
        }
        else {
            this.DispLoader = false;
            this.toastr.warning('No Data Found!', this.CompTitle);
        }
    }

    generateExcelTable_fromJSON(ExportData: ExcelExportData[], fileName: string) {
        this.DispLoader = true;

        if (!isNullOrUndefined(ExportData) && ExportData.length > 0) {

            const workbook: XLSX.WorkBook = XLSX.utils.book_new();

            ExportData.forEach((sheet: any, index: number) => {
                this.ColumnNames = [];
                this.RowData = [];

                if (sheet.Data.length > 0) {

                    let rowData = JSON.parse(JSON.stringify(sheet.Data));

                    let colFieldNameList = Object.keys(sheet.Data[0]);
                    let colDisplayNameList = Object.keys(sheet.Data[0]);

                    if (!isNullOrUndefined(sheet.ColumnList) && sheet.ColumnList.length > 0) {
                        // colNameList = colNameList.filter((x: any) => (sheet.ColumnList.indexOf(x)) >= 0);
                        colFieldNameList = [...sheet.ColumnList];
                        colDisplayNameList = [...sheet.ColumnList];
                    }

                    if (!isNullOrUndefined(sheet.FieldTemplate) && sheet.FieldTemplate.length > 0) {

                        let colFieldList: any[] = [];
                        let colDispList: any[] = [];

                        colFieldNameList.forEach((value: any) => {
                            let columnName: any = '';

                            let temp = sheet.FieldTemplate.filter((x: any) => x.FieldName === value)
                            if (temp.length > 0) {
                                columnName = temp[0].DisplayName;
                            }

                            if (!isNullOrUndefined(columnName) && columnName !== '') {
                                colFieldList.push(value)
                                colDispList.push(columnName);
                            }

                        });

                        colFieldNameList = colFieldList;
                        colDisplayNameList = colDispList;
                    }

                    rowData = sheet.Data.map((x: any) => colFieldNameList.reduce((o: any, k: any) => (o[k] = x[k], o), {}));

                    this.ColumnNames = colDisplayNameList;
                    this.RowData = JSON.parse(JSON.stringify(rowData));

                    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.RowData);

                    XLSX.utils.book_append_sheet(workbook, worksheet, sheet.Name);

                    /* fix headers */
                    XLSX.utils.sheet_add_aoa(worksheet, [this.ColumnNames], { origin: "A1" });

                }
                else {
                    this.toastr.warning('No Data Found!', this.CompTitle);
                    this.DispLoader = false;
                    return;
                }
            });

            XLSX.writeFile(workbook, fileName + '.xlsx');

            this.DispLoader = false;

        }
        else {
            this.DispLoader = false;
            this.toastr.warning('No Data Found!', this.CompTitle);
        }
    }

}