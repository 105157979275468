import { Component, Input, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild } from '@angular/core'; // Import OnDestroy
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../../../shared/app.sharedservice';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AMApproach, ComponentCodes, ComponentConfig, GuideLinesParam, ProcessStatus, ServiceDomain } from '../../../app.interface';
import { LotSolutionService } from './../lot-solution-modelling.service';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { environment } from 'src/environments/environment';
import { FormComponent } from 'src/modules/form/form.component';

@Component({
  selector: 'staffing',
  templateUrl: './staffing.component.html'
})
@AutoUnsubscribe
export class StaffingComponent implements OnInit {
  @ViewChild('LotStaffingPyramidForm', { static: false }) LotStaffingPyramidForm!: FormComponent;

  @ViewChildren('StaffingPyramidGrid') StaffingPyramidGrid!: QueryList<GridDisplayControlComponent>;
  @Input() selectedLineItemData: any;
  @Input() ServiceDomainDetails: any;

  DealData: any = {};
  public IsLoading: boolean = false;
  public DealHeaderId: any;
  public DealLineItemId: any;
  public DealSubLineItemId: any;
  public subscriptionList$: Subscription[] = [];
  public notify: NotificationData = new NotificationData();
  public displayNotifybox: boolean = false;
  public modalInf: ModalInterface = new ModalInterface();
  public Permission: any = [];
  public Properties: any = [];
  public totalGriddata: any;
  public SavePyramidPopupDetailsData: any = [];
  public LotName: any;
  public ActionButtons: any[] = [];
  public EffortMixButtons: any[] = [];
  public Config: any;
  public StandardYoeDesc: any;
  public StandardRoleTypeDesc: any;
  public StandardRoleType: any;
  public StaffingTemplate: FieldTemplate[] = [];
  public StaffingRegionTemplate: FieldTemplate[] = [];
  public GovernancePercentageTemplate: FieldTemplate[] = [];
  public GradeAllocationTemplate: FieldTemplate[] = [];
  public PyramidTemplate: FieldTemplate[] = [];
  public PyramidPopupDetailsTemplate: FieldTemplate[] = [];
  public LocationType: any;
  public StaffingRegionData: any[] = [];
  public GovernancePercentagenData: any[] = [];
  public GradeAllocationData: any[] = [];
  public PyramidData: any[] = [];
  public PyramidPopupDetailsData: any = [];
  public SelectedPyramidPopupDetailsData: any = {};
  public IsPagination: boolean = false;

  public StaffingRegionData_DataCount: number = 0;
  public GovernancePercentagenData_DataCount: number = 0;
  public GradeAllocationData_DataCount: number = 0;
  public PyramidData_DataCount: number = 0;
  public dataTotalCount: number = 0;

  public DeliveredFromCountryDesc: any;
  public DeliveredFromRegionDesc: any;
  public DeliveredToCountryDesc: any;
  public DeliveredToRegionDesc: any;
  public selectedRegion: any = {};


  public pyramidCustom: any = [];
  public pyramid_custom: any = [
    { Name: "PyramidCustom1", id: 1, fieldname: 'PyramidCustom1', value: 0 },
    { Name: "PyramidCustom2", id: 2, fieldname: 'PyramidCustom2', value: 0 },
    { Name: "PyramidCustom3", id: 3, fieldname: 'PyramidCustom3', value: 0 },
    { Name: "PyramidCustom4", id: 4, fieldname: 'PyramidCustom4', value: 0 },
    { Name: "PyramidCustom5", id: 5, fieldname: 'PyramidCustom5', value: 0 },
    { Name: "PyramidCustom6", id: 6, fieldname: 'PyramidCustom6', value: 0 },
    { Name: "PyramidCustom7", id: 7, fieldname: 'PyramidCustom7', value: 0 },
  ];

  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable: false
  };

  public PyramidGridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable: false,
    PaginationConfig: {
      ItemsPerPage: 100
    }
  };

  public FormProp: FormProperty = {
    ColSize: 3,
    CaptionBold: false,
    CaptionTop: true,
    IsEditable: true,
    ShowControlAlways: true
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  public GlobalConfig: any = {};

  constructor(
    private toastr: ToastrService,
    private _router: Router,
    private LotSolutionService: LotSolutionService,
    private modalService: ModalService,
    private _appSharedService: AppSharedService,
    private _commonAPIService: CommonAPIService
  ) { }

  ngOnInit(): void {
    // Initialize component properties
    this.DealHeaderId = this.selectedLineItemData.DealHeaderId ?? 0;
    this.DealLineItemId = this.selectedLineItemData.DealLineItemId ?? 0;
    this.DealSubLineItemId = this.selectedLineItemData.DealSubLineItemId ?? 0;
    this.LotName = this.selectedLineItemData.LotName;

    // Get the current deal data from the shared service
    this.DealData = this._appSharedService.currentDealData;

    // Fetch Location Mix Template
    if (!isNullOrUndefined(this.selectedLineItemData)) {
      this.getStaffingTemplate();
    }
  }

  // ngOnDestroy(): void {
  //   // Unsubscribe from subscriptions to prevent memory leaks
  //   this.subscriptionList$.forEach((subscription) => subscription.unsubscribe());
  // }

  private getStaffingTemplate(): void {
    // Show loading indicator
    this.IsLoading = true;

    // Define the component configuration
    const odata: ComponentConfig = {
      Component: 'LotSolutionModelling',
      ComponentCode: ComponentCodes.Staffing,
      ReferenceValueType: '1'
    };

    // Subscribe to the component configuration API call
    const subscribe$ = this._commonAPIService.getComponentConfig(odata).subscribe(
      (res) => {
        // Handle the response
        if (res !== undefined && res !== null && res.Status === 200) {
          this.StaffingTemplate = res.Data.FieldTemplate!;
          this.Permission = res.Data.Permission;
          this.Properties = res.Data.Properties;
          this.PyramidPopupDetailsTemplate = this.StaffingTemplate.filter(x => x.Group === 'SelectSpecificPyramid');
          //Set Properties
          this.setCompProperties(this.Properties);
          this.ActionButtons = this.Config.StaffingButtons.filter((x: any) => x.Group === 'Staffing');

          this.GetStaffingRegionDetails(0);
        }
      },
      (err) => {
        // Handle errors
        this.IsLoading = false;
        console.log('Error on getProductivityTemplate.');
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }

  GetStaffingRegionDetails(lsDeliveryLocationModellingId: any, bLoad: boolean = false) {

    if (!bLoad) {
      this.IsLoading = true;
    }

    this.StaffingRegionTemplate = [];

    let subscribe$: Subscription = this.LotSolutionService.GetStaffingModellingFirstGrid(this.DealHeaderId, this.DealSubLineItemId, lsDeliveryLocationModellingId).subscribe(result => {
      if (result.Data !== undefined && result.Data !== null && result.Status === ProcessStatus.Success) {

        let template: FieldTemplate[] = [];

        if (this.DealData.RateModellingOption === 'RTM001') {
          template = this.StaffingTemplate.filter(x => x.Group === 'StaffingModelFirstGrid');
        } else {
          template = this.StaffingTemplate.filter(x => x.Group === 'StaffingModelExternalFirstGrid');
        }

        for (let index = 0; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();

          let fieldData = template.filter(x => x.FieldName === fieldName);

          if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
            fieldData[0].IsHidden = false;
          }
        }

        this.StaffingRegionData = result.Data;
        //  this.StaffingRegionData[0].IsEditable = 1;
        // this.StaffingRegionData.forEach((element: any) => {
        //   if(element)
        //   element.IsEditable = 1;
        // });
        this.StaffingRegionData_DataCount = this.StaffingRegionData?.length ?? 0;

        if (this.GovernancePercentagenData.length > 0) {
          this.rowHighlight();
        }


        this.StaffingRegionTemplate = template;
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetStaffingRegionDetails.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }


  setCompProperties(Properties: any[]) {
    this.Config = {}; // Initialize Config as an empty object

    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      this.Config.ProductivityBlockCols = Properties
        .filter(x => x.Name.toLowerCase() === 'ProductivityBlockCols'.toLowerCase())
        .map(x => JSON.parse(x.JSONData));

      Properties.forEach(prop => {
        const name = prop.Name.toLowerCase();

        if (name !== 'defaultrow' && name !== 'blockcols' && name !== 'productivityblockcols' && name !== 'paginationconfig') {
          this.Config[prop.Name] = JSON.parse(prop.JSONData);
        }
      });
    }
  }



  showNotificationMessage(msg: string, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    //console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    if (canClose) {
      this.closeNotifybox();
    }
    switch (event.action.toUpperCase()) {
      case 'STAFFINGMODEL':
        if (event.result.toUpperCase() === 'YES') {
          if (this.DealData.AmApproach === AMApproach.Users_Based_Approach) {
            this.navigateToLocationMix();
          } else {
            this.navigateToProductivity();
          }
        }
        break;
    }

  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  onClick(event: any) {

    const selitem = event.item;

    if ((event.fieldname.toUpperCase() == 'REGION' ||
      event.fieldname.toUpperCase() == 'COUNTRY'
      || event.fieldname.toUpperCase() == 'YEAR0'
      || event.fieldname.toUpperCase() == 'YEAR1'
      || event.fieldname.toUpperCase() == 'YEAR2'
      || event.fieldname.toUpperCase() == 'YEAR3'
      || event.fieldname.toUpperCase() == 'YEAR4'
      || event.fieldname.toUpperCase() == 'YEAR5'
      || event.fieldname.toUpperCase() == 'YEAR6'
      || event.fieldname.toUpperCase() == 'YEAR7'
    ) && (!isNullOrUndefined(selitem.StaffingFirstGrid) && selitem.StaffingFirstGrid === 1)
      && (!isNullOrUndefined(selitem.Region) && selitem.Region !== 'Overall Total')) {
      this.pyramidCustom = [];
      this.selectedRegion = selitem;
      this.rowHighlight();

      this.DeliveredFromCountryDesc = selitem.CountryDesc;
      this.DeliveredFromRegionDesc = selitem.RegionDesc;
      this.DeliveredToCountryDesc = selitem.DeliveredToCountryDesc;
      this.DeliveredToRegionDesc = selitem.DeliveredToRegionDesc;
      this.LocationType = selitem.LocationType;
      let template = JSON.parse(JSON.stringify(this.StaffingTemplate.filter(x => x.Group === 'StaffingModelFourthGrid'
        || x.Group === 'StaffingModelFourthGridOFFNEAR'
        || x.Group === 'StaffingModelExternalFourthGrid'
        || x.Group === 'StaffingModelExternalFourthGridOFFNEAR')));

      let resourcePyramid: FieldTemplate[] = [];

      if (this.DealData.RateModellingOption !== 'RTM001' && selitem.LocationType == 'ONSH') {
        resourcePyramid = template.filter((x: any) => x.Group === 'StaffingModelExternalFourthGrid');
      } if (this.DealData.RateModellingOption !== 'RTM001' && selitem.LocationType != 'ONSH') {
        resourcePyramid = template.filter((x: any) => x.Group === 'StaffingModelExternalFourthGridOFFNEAR');
      } if (this.DealData.RateModellingOption === 'RTM001' && selitem.LocationType != 'ONSH') {
        resourcePyramid = template.filter((x: any) => x.Group === 'StaffingModelFourthGridOFFNEAR');
      } if (this.DealData.RateModellingOption === 'RTM001' && selitem.LocationType == 'ONSH') {
        resourcePyramid = template.filter((x: any) => x.Group === 'StaffingModelFourthGrid');
      }

      for (let index = 1; index <= this.DealData.DealTerm; index++) {

        (this.pyramidCustom).push(this.pyramid_custom[index - 1]);


        let fieldName: string = 'Year' + index.toString() + 'Per';
        let fieldData = resourcePyramid.filter(x => x.FieldName === fieldName);
        if (!isNullOrUndefined(fieldData) && fieldData.length > 0) {
          fieldData[0].IsHidden = false;
        }
      }

      this.PyramidTemplate = resourcePyramid;

      this.GetGovernancePercentagenData(selitem.LSDeliveryLocationModellingId);

    }

    if (event.fieldname.toUpperCase() == 'SPECIFICPYRAMID') {
      this.openModal('PyramidPopupDetails');
      this.GetSpecificPyramidPopUp(event.item.LSDeliveryLocationModellingId);
      this.modalInf =
      {
        header: 'Select Specific Pyramid',
        size: 'modal-md modal-dialog-centered modal-dialog-scrollable',
        width: 'auto',
        height: 'auto'
      };

    }
  }

  onChange(event: any) {
    const selitem = event.item;
    selitem.IsModified = 1;
    const fieldname = event.fieldname.toUpperCase();
    let itemindex: any = event.item_index;
    // this.selectedInfoData = event.item;
    // if (event.fieldname.toUpperCase() == 'YEAR1' ||
    //   event.fieldname.toUpperCase() == 'YEAR2' ||
    //   event.fieldname.toUpperCase() == 'YEAR3' ||
    //   event.fieldname.toUpperCase() == 'YEAR4' ||
    //   event.fieldname.toUpperCase() == 'YEAR5' || event.fieldname.toUpperCase() == 'YEAR6' || event.fieldname.toUpperCase() == 'YEAR7') {
    //   this.onStaffingSecondGridChange = true;
    //   this.onStaffingThirdGridChange = true;
    // }
    // if ((event.item.Grade != undefined) && (event.fieldname.toUpperCase() == 'YEAR1PER' || event.fieldname.toUpperCase() == 'YEAR2PER' || event.fieldname.toUpperCase() == 'YEAR3PER' || event.fieldname.toUpperCase() == 'YEAR4PER' || event.fieldname.toUpperCase() == 'YEAR5PER' || event.fieldname.toUpperCase() == 'YEAR6PER' || event.fieldname.toUpperCase() == 'YEAR7PER')) {

    //     this.calculationStaffingThirdGrid();
    //   }
    // else if ((event.fieldname.toUpperCase() == 'SPECIFICPYRAMIDSELECTION' || event.fieldname.toUpperCase() == 'SBU')) {
    //   this.onStaffingPyramidChange = true;
    // }
    // else {
    //   this.onStaffingFourthGridChange = true;
    // }


    //   if (this.onStaffingFourthGridChange) {
    // this.IsLoading = true;
    // setTimeout(() => {
    event.item.IsModified = 1;

    if (
      (event.item.Grade === undefined) &&
      (
        event.fieldname.toUpperCase() === 'YEAR1PER' ||
        event.fieldname.toUpperCase() === 'YEAR2PER' ||
        event.fieldname.toUpperCase() === 'YEAR3PER' ||
        event.fieldname.toUpperCase() === 'YEAR4PER' ||
        event.fieldname.toUpperCase() === 'YEAR5PER' ||
        event.fieldname.toUpperCase() === 'YEAR6PER' ||
        event.fieldname.toUpperCase() === 'YEAR7PER' ||
        event.fieldname.toUpperCase() === 'STANDARDROLE'
      )
    ) {
      if (event.fieldname.toUpperCase() === 'STANDARDROLE') {
        if (!isNullOrUndefined(event.item.StandardRoleDesc) && event.item.StandardRoleDesc !== '') {
          event.item.PyramidStatus = 1;
        } else {
          event.item.PyramidStatus = 0;
        }
      }

      // Calculate pyramid values only if necessary
      let calculatePyramid = (
        event.fieldname.toUpperCase() === 'YEAR1PER' ||
        event.fieldname.toUpperCase() === 'YEAR2PER' ||
        event.fieldname.toUpperCase() === 'YEAR3PER' ||
        event.fieldname.toUpperCase() === 'YEAR4PER' ||
        event.fieldname.toUpperCase() === 'YEAR5PER' ||
        event.fieldname.toUpperCase() === 'YEAR6PER' ||
        event.fieldname.toUpperCase() === 'YEAR7PER'
      );

      if (calculatePyramid) {
        this.calculationStaffingPyramid_onchange(event.fieldname.toUpperCase());
        //  this.calculationStaffingPyramid();
      }
      if (event.item.PyramidStatus == 0 && !calculatePyramid) {
        this.calculationStaffingPyramid();
      }
      if (event.item.PyramidStatus == 1 && !calculatePyramid) {
        this.calculationStaffingPyramid();
      }
    }

    //   this.IsLoading = false;
    // }, 1000);

    //   }

    switch (event.fieldname.toUpperCase()) {
      case 'SPECIFICPYRAMIDSELECTION':
        // setTimeout(() => {
        if (event.item.SpecificPyramidSelection == 'SPS2') {
          this.LotStaffingPyramidForm.enableFields('SBU', false);
        }
        else {
          this.LotStaffingPyramidForm.enableFields('SBU', true);
        }
        this.LotStaffingPyramidForm.setDispcols();
        // }, 1000);
        break;
      case 'STANDARDROLE':
        let y: any;
        y = this.PyramidTemplate.filter(x => (x.FieldName === event.fieldname))[0];
        // if (((this.selectedDeal.RateModellingDesc == 'External') || (this.selectedDeal.RateModellingOptionDesc == 'External')) && (this.LocationType == 'ONSH')) {
        //   y = this.StaffingModelFourthGridExternalTemplate.filter(x => (x.FieldName === event.fieldname))[0];
        // } if (((this.selectedDeal.RateModellingDesc == 'External') || (this.selectedDeal.RateModellingOptionDesc == 'External')) && (this.LocationType != 'ONSH')) {
        //   y = this.StaffingModelExternalFourthOFFNEARTemplate.filter(x => (x.FieldName === event.fieldname))[0];
        // } if (((this.selectedDeal.RateModellingDesc == 'Capgemini') || (this.selectedDeal.RateModellingOptionDesc == 'Capgemini')) && (this.LocationType != 'ONSH')) {
        //   y = this.StaffingModelFourthOFFNEARTemplate.filter(x => (x.FieldName === event.fieldname))[0];
        // } if (((this.selectedDeal.RateModellingDesc == 'Capgemini') || (this.selectedDeal.RateModellingOptionDesc == 'Capgemini')) && (this.LocationType == 'ONSH')) {
        //   y = this.StaffingModelFourthGridTemplate.filter(x => (x.FieldName === event.fieldname))[0];
        // }
        if (!isNullOrUndefined(y.Control)) {
          this.StandardYoeDesc = y.Control.List.filter((x: any) => x.CodeDescription === event.item.StandardRoleDesc)[0].UserDef1;
          //this.StandardRoleTypeDesc = y.Control.List.filter((x:any) => x.CodeDescription === event.item.StandardRoleDesc)[0].UserDef2;
          this.StandardRoleType = y.Control.List.filter((x: any) => x.CodeDescription === event.item.StandardRoleDesc)[0].UserDef3;
          event.item.StandardYoeDesc = this.StandardYoeDesc;
          event.item.StandardYoe = this.StandardYoeDesc;
          //event.item.StandardRoleTypeDesc = this.StandardRoleTypeDesc;
          event.item.StandardRoleTypeDesc = this.StandardRoleType;
          event.item.StandardRoleType = this.StandardRoleType;
        }
        break;

      case 'RESOURCETYPE':
        this.changeFourthGridFlag(event.item, itemindex, 'Fourth_locationgrid');
        break;

    }





  }


  changeFourthGridFlag(selitem: any, itemindex: any, gridName?: any) {


    console.log('changeFourthGridFlag enter');
    let griditem = this.StaffingPyramidGrid.filter((x: any) =>
      x.GridId === gridName);
    if (griditem.length > 0 && !isNullOrUndefined(griditem[0])) {
      // setTimeout(() => {

      let temp: any = [];
      temp.push(selitem);
      griditem[0].initCellLock(temp);
      griditem[0].refreshAllFieldsForRow(selitem, itemindex);

      // }, 50);

    }
    console.log('changeFourthGridFlag exit');
  }
  calculationStaffingPyramid() {

    console.log("calculationStaffingPyramid method enter");
    if (!isNullOrUndefined(this.PyramidData) && this.PyramidData.length > 0) {
      let pyramidValues = [0, 0, 0, 0, 0, 0, 0];

      this.PyramidData.forEach((item: any) => {
        if (item.PyramidStatus === 1) {
          pyramidValues[0] += item.Year1Per;
          pyramidValues[1] += item.Year2Per;
          pyramidValues[2] += item.Year3Per;
          pyramidValues[3] += item.Year4Per;
          pyramidValues[4] += item.Year5Per;
          pyramidValues[5] += item.Year6Per;
          pyramidValues[6] += item.Year7Per;
        }
      });

      for (let i = 0; i < pyramidValues.length; i++) {
        let value: any = pyramidValues[i].toFixed(1);
        this.pyramid_custom[i].value = (value % 2 === 0) ? Math.floor(value) : value;
      }
      let Year1Per: any = pyramidValues[0].toFixed(1);
      this.totalGriddata[0].Year1Per = (Year1Per % 2 === 0) ? Math.floor(Year1Per) : Year1Per;
      let Year2Per: any = pyramidValues[1].toFixed(1);
      this.totalGriddata[0].Year2Per = (Year2Per % 2 === 0) ? Math.floor(Year2Per) : Year2Per;

      let Year3Per: any = pyramidValues[2].toFixed(1);
      this.totalGriddata[0].Year3Per = (Year3Per % 2 === 0) ? Math.floor(Year3Per) : Year3Per;

      let Year4Per: any = pyramidValues[3].toFixed(1);
      this.totalGriddata[0].Year4Per = (Year4Per % 2 === 0) ? Math.floor(Year4Per) : Year4Per;

      let Year5Per: any = pyramidValues[4].toFixed(1);
      this.totalGriddata[0].Year5Per = (Year5Per % 2 === 0) ? Math.floor(Year5Per) : Year5Per;

      let Year6Per: any = pyramidValues[5].toFixed(1);
      this.totalGriddata[0].Year6Per = (Year6Per % 2 === 0) ? Math.floor(Year6Per) : Year6Per;


      let Year7Per: any = pyramidValues[6].toFixed(1);
      this.totalGriddata[0].Year7Per = (Year7Per % 2 === 0) ? Math.floor(Year7Per) : Year7Per;

      // this.fourthGridResult = true;
    }

    console.log("calculationStaffingPyramid method exit");
  }



  HelpIconClick() {

    this.openModal('LotResourceDetails');
    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };

  }

  ResourcePyramidHelpIconClick() {
    this.openModal('ResourcePyramidDetails');
    this.modalInf =
    {
      header: '',
      size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
    };
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  actionButtonClick(data: any): void {
    const name = data.Name?.toUpperCase();

    if (name !== undefined && name !== null) {
      if (name === 'SAVE') {

        const modifiedGovernanceData = this.GovernancePercentagenData.filter(x => x.IsModified === 1);
        const modifiedAllocationData = this.GradeAllocationData.filter(x => x.IsModified === 1);
        const modifiedPyramidData = this.PyramidData.filter(x => x.IsModified === 1);

        if (modifiedGovernanceData.length > 0 || modifiedAllocationData.length > 0 || modifiedPyramidData.length > 0) {
          this.showNotificationMessage('There is some data modification. Please click save before clicking Save & Next.', 2, '', null, '', '', 1000);
        }
        else {
          this.navigateToPricing();
        }

      } else if (name === 'BACK') {

        const modifiedGovernanceData = this.GovernancePercentagenData.filter(x => x.IsModified === 1);
        const modifiedAllocationData = this.GradeAllocationData.filter(x => x.IsModified === 1);
        const modifiedPyramidData = this.PyramidData.filter(x => x.IsModified === 1);

        if (modifiedGovernanceData.length > 0 || modifiedAllocationData.length > 0 || modifiedPyramidData.length > 0) {
          this.showNotificationMessage('Are you sure you want to cancel unsaved data?',
            2, 'STAFFINGMODEL', null, 'Yes', 'No', 10000);
        }
        else {
          if (this.DealData.AmApproach === AMApproach.Users_Based_Approach) {
            this.navigateToLocationMix();
          } else {
            this.navigateToProductivity();
          }
        }
      }
    }
  }

  private navigateToLocationMix(): void {
    const url = `app/lot-solution-modelling/LocationMix/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToProductivity(): void {
    const url = `app/lot-solution-modelling/productivityImpact/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToStaffingModel(): void {
    const url = `app/lot-solution-modelling/StaffingModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToDeliveryModel(): void {
    const url = `app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.selectedLineItemData } });
  }

  private navigateToPricing(): void {
    const url = `app/lot-solution-pricing-model/pricingmodel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url]);
  }

  private rowHighlight(): void {

    if (this.StaffingRegionData.length > 0 && this.DealSubLineItemId > 0) {

      this.StaffingRegionData.forEach(item => {
        item.IsRowHighlight = 0;
      });

      const selitem_highlight = this.StaffingRegionData.find(x =>
        x.LSDeliveryLocationModellingId === this.selectedRegion.LSDeliveryLocationModellingId
      );

      if (selitem_highlight) {
        selitem_highlight.IsRowHighlight = 1;
      }
    }
  }

  GetGovernancePercentagenData(lsDeliveryLocationModellingId: any) {

    this.GovernancePercentageTemplate = [];

    let subscribe$: Subscription = this.LotSolutionService.GetStaffingModellingSecondGrid(this.DealHeaderId, this.DealSubLineItemId, lsDeliveryLocationModellingId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        let template: FieldTemplate[] = [];

        template = this.StaffingTemplate.filter(x => x.Group === 'StaffingModelSecondGrid');


        for (let index = 1; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString();
          let fieldData = template.find(x => x.FieldName === fieldName);

          if (fieldData) {
            fieldData.IsHidden = false;
          }
        }

        const id = this.DealData.DealTerm + 1;

        for (let index = id; index <= 10; index++) {
          let fieldName: string = 'Year' + index.toString();

          result.Data.forEach((item: any) => {
            item[fieldName] = 0;
          });
        }

        this.GovernancePercentagenData = result.Data;
        this.GovernancePercentagenData_DataCount = this.GovernancePercentagenData?.length ?? 0;
        this.GovernancePercentageTemplate = template;
        // this.selectedSecondGridData = this.StaffingModellingSecondGridData[0];
      } else {
        this.GovernancePercentagenData = [];

        let template: FieldTemplate[] = [];

        template = this.StaffingTemplate.filter(x => x.Group === 'StaffingModelSecondGrid');
        this.GovernancePercentageTemplate = template;
      }



      this.GetGradeAllocationData(lsDeliveryLocationModellingId);

    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);

  }

  GetGradeAllocationData(lsDeliveryLocationModellingId: any) {

    this.GradeAllocationTemplate = [];

    let subscribe$: Subscription = this.LotSolutionService.GetStaffingModellingThirdGrid(this.DealHeaderId, this.DealSubLineItemId, lsDeliveryLocationModellingId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        let template: FieldTemplate[] = [];

        template = this.StaffingTemplate.filter(x => x.Group === 'StaffingModelThirdGrid');


        for (let index = 1; index <= this.DealData.DealTerm; index++) {
          let fieldName: string = 'Year' + index.toString() + 'Per';
          let fieldData = template.find(x => x.FieldName === fieldName);

          if (fieldData) {
            fieldData.IsHidden = false;
          }
        }

        const id = this.DealData.DealTerm + 1;

        for (let index = id; index <= 10; index++) {
          let fieldName: string = 'Year' + index.toString();

          result.Data.forEach((item: any) => {
            item[fieldName] = 0;
          });
        }

        this.GradeAllocationData = result.Data;
        this.GradeAllocationData_DataCount = this.GradeAllocationData?.length ?? 0;
        // this.calculationGradeAllocation();

        this.GradeAllocationTemplate = template;

      } else {
        this.GradeAllocationData = [];

        let template: FieldTemplate[] = [];

        template = this.StaffingTemplate.filter(x => x.Group === 'StaffingModelThirdGrid');
        this.GradeAllocationTemplate = template;
      }

      this.GetPyramidData(lsDeliveryLocationModellingId);
      // this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);

  }

  GetPyramidData(lsDeliveryLocationModellingId: any) {
    let subscribe$: Subscription = this.LotSolutionService.GetStaffingModellingFourthGrid(this.DealHeaderId, this.DealSubLineItemId, lsDeliveryLocationModellingId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        this.PyramidData = result.Data;
        this.PyramidData_DataCount = this.PyramidData?.length ?? 0;
        this.totalGriddata = this.PyramidData.filter(x => x.StandardYoe === 'Total');
        console.log(this.totalGriddata);
       
        if (!isNullOrUndefined(this.PyramidData) && this.PyramidData.length > 0) {
          this.PyramidData.forEach((item: any) => {
            if (!isNullOrUndefined(item.StandardRoleDesc) && item.StandardRoleDesc !== '') {
              item.PyramidStatus = 1;
            }
          });
        }
        else {
          this.PyramidData = [];
        }

        this.calculationStaffingPyramid();
      } else {
        this.PyramidData = [];
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);

  }

  GetSpecificPyramidPopUp(lsDeliveryLocationModellingId: any) {
    console.log("GetSpecificPyramidPopUp method enter");

    let subscribe$: Subscription = this.LotSolutionService.GetSpecificPyramidPopUp(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId, lsDeliveryLocationModellingId).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.PyramidPopupDetailsData = result.Data;
        this.SelectedPyramidPopupDetailsData = result.Data[0];
        this.PyramidPopupDetailsData.IsEditable = 1;
        this.dataTotalCount = result.Data.length;
        setTimeout(() => {
          if (!isNullOrUndefined(this.LotStaffingPyramidForm)) {

            if (this.SelectedPyramidPopupDetailsData.SpecificPyramidSelection == 'SPS2') {
              this.LotStaffingPyramidForm.enableFields('SBU', false);
            }
            else {
              this.LotStaffingPyramidForm.enableFields('SBU', true);
            }
            this.LotStaffingPyramidForm.setDispcols();

          }
        }, 500);

        // this.cpyPyramidPopupDetailsData = JSON.parse(JSON.stringify(this.PyramidPopupDetailsData));
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting GetSpecificPyramidPopUp.');
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);

    console.log("GetSpecificPyramidPopUp method exit");
  }

  SaveStaffingModel() {

    if (this.validateStaffing()) {

      const modifiedGovernanceData = this.GovernancePercentagenData.filter(x => x.IsModified === 1);

      if (modifiedGovernanceData.length > 0) {
        this.InsertUpdateDealGovernance();
      }

      const modifiedAllocationData = this.GradeAllocationData.filter(x => x.IsModified === 1);

      if (modifiedAllocationData.length > 0) {
        this.InsertUpdateGradeGovernance();
      }
      this.InsertUpdateResourcePyramid();
    }

  }

  validateStaffing(): boolean {
    // const modifiedGovernanceData = this.GovernancePercentagenData.filter(x => x.IsModified === 1);

    if (this.GovernancePercentagenData.length > 0) {
      for (const item of this.GovernancePercentagenData) {
        if (
          [item.Year1, item.Year2, item.Year3, item.Year4, item.Year5, item.Year6, item.Year7].some(isNullOrUndefined)
        ) {
          this.toastr.warning("Operational Level governance cannot be blank");
          return false;
        }
      }
    }
    for (let i = 0; i < this.pyramidCustom.length; i++) {
      if (Number(this.pyramidCustom[i].value !== 100)) {
        this.toastr.warning("FTE % Total Should be equal to 100%");
        return false;
        //  this.Count = this.Count + 1;
        //  break;
      }
    }

    if ((this.PyramidData.filter((x: any) => (x.GlobalGrade == 'F'))).length != 0) {
      if ((this.PyramidData[0].DealGovernanceFlag == 1) && (this.PyramidData[0].SBU != 7) && (this.PyramidData.filter((x: any) => (x.GlobalGrade == 'F' && x.StandardRole == null)).length > 0) && ((this.DealData.RateModellingOption === 'RTM001'))) {
        // valid = false;
        // this.Count = this.Count + 1;
        this.toastr.warning("Billing Rates for F Grade for all countries across the lots cannot be blank");
        // break;
        return false;
      }
    }





    // const modifiedAllocationData = this.GradeAllocationData.filter(x => x.IsModified === 1);

    if (
      this.GradeAllocationData.length > 0 &&
      this.GovernancePercentagenData?.length > 0
    ) {
      const sums = {
        Year1Per: this.calculateSum(this.GradeAllocationData, 'Year1Per'),
        Year2Per: this.calculateSum(this.GradeAllocationData, 'Year2Per'),
        Year3Per: this.calculateSum(this.GradeAllocationData, 'Year3Per'),
        Year4Per: this.calculateSum(this.GradeAllocationData, 'Year4Per'),
        Year5Per: this.calculateSum(this.GradeAllocationData, 'Year5Per'),
        Year6Per: this.calculateSum(this.GradeAllocationData, 'Year6Per'),
        Year7Per: this.calculateSum(this.GradeAllocationData, 'Year7Per')
      };

      if (sums.Year1Per !== 100 && this.GovernancePercentagenData[0].Year1 != null && this.GovernancePercentagenData[0].Year1 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 1) cannot be blank OR should be equal to 100");
        return false;
      } else if (sums.Year2Per !== 100 && this.GovernancePercentagenData[0].Year2 != null && this.GovernancePercentagenData[0].Year2 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 2) cannot be blank OR should be equal to 100");
        return false;
      } else if (sums.Year3Per !== 100 && this.GovernancePercentagenData[0].Year3 != null && this.GovernancePercentagenData[0].Year3 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 3) cannot be blank OR should be equal to 100");
        return false;
      } else if (sums.Year4Per !== 100 && this.GovernancePercentagenData[0].Year4 != null && this.GovernancePercentagenData[0].Year4 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 4) cannot be blank OR should be equal to 100");
        return false;
      } else if (sums.Year5Per !== 100 && this.GovernancePercentagenData[0].Year5 != null && this.GovernancePercentagenData[0].Year5 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 5) cannot be blank OR should be equal to 100");
        return false;
      } else if (sums.Year6Per !== 100 && this.GovernancePercentagenData[0].Year6 != null && this.GovernancePercentagenData[0].Year6 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 6) cannot be blank OR should be equal to 100");
        return false;
      } else if (sums.Year7Per !== 100 && this.GovernancePercentagenData[0].Year7 != null && this.GovernancePercentagenData[0].Year7 > 0) {
        this.toastr.warning("Grade allocation for E & D (Year 7) cannot be blank OR should be equal to 100");
        return false;
      }
    }

    return true;
  }

  calculateSum(data: any[], prop: string): number {
    return data.map((x: any) => x[prop]).reduce((a: number, b: number) => a + b, 0);
  }

  getYearWithAllocation(data: any): number {
    for (let i = 1; i <= 7; i++) {
      if (data[`Year${i}`] !== null && data[`Year${i}`] > 0) {
        return i;
      }
    }
    return 0; // Default case, if no year with allocation is found
  }


  InsertUpdateDealGovernance(bLoad: boolean = false) {

    if (!bLoad) {
      this.IsLoading = true;
    }

    let subscribe$: Subscription = this.LotSolutionService.InsertUpdateDealGovernance(this.GovernancePercentagenData[0]).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        if (!isNullOrUndefined(this.GovernancePercentagenData) && this.GovernancePercentagenData.length > 0) {
          this.GovernancePercentagenData.forEach((item: any) => {

            item.IsModified = 0;

          });
        }
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  InsertUpdateGradeGovernance(bLoad: boolean = false) {

    if (!bLoad) {
      this.IsLoading = true;
    }

    let odata = {
      'listStaffingModuleThirdGrid': this.GradeAllocationData
    }
    let subscribe$: Subscription = this.LotSolutionService.InsertUpdateGradeGovernance(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        // this.InsertUpdateGradeGovernanceData = result.Data;
        // this.insertGridTwo = true;
        // if (this.CalculateCount == 0) {
        //   this.CallingCalculateStaffing();
        // }

        if (!isNullOrUndefined(this.GradeAllocationData) && this.GradeAllocationData.length > 0) {
          this.GradeAllocationData.forEach((item: any) => {

            item.IsModified = 0;

          });
        }
      }
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }

  InsertUpdateResourcePyramid(bLoad: boolean = false) {

    if (!bLoad) {
      this.IsLoading = true;
    }

    let odata: any = {
      'listStaffingModuleFourthGrid': this.PyramidData
    }
    let subscribe$: Subscription = this.LotSolutionService.InsertUpdateResourcePyramid(odata).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {

        this.showNotificationMessage('Saved Successfully!', 1, '', '', '', '', 1000);

        if (!isNullOrUndefined(this.PyramidData) && this.PyramidData.length > 0) {
          this.PyramidData.forEach((item: any) => {

            item.IsModified = 0;

          });
        }
        this.GetStaffingRegionDetails(0, true);
      }
      // this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
  }







  calculationStaffingPyramid_onchange(fieldname: any) {
    console.log('calculationStaffingPyramid_onchange');

    if (!isNullOrUndefined(this.PyramidData) && this.PyramidData.length > 0) {
      let pyramidValues = [0, 0, 0, 0, 0, 0, 0];

      this.PyramidData.forEach((item: any) => {
        if (item.PyramidStatus === 1) {
          switch (fieldname) {
            case 'YEAR1PER':

              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year1Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[0].value = (value % 2 === 0) ? Math.floor(value) : value;
              }
              let Year1Per: any = pyramidValues[0].toFixed(1);
              this.totalGriddata[0].Year1Per = (Year1Per % 2 === 0) ? Math.floor(Year1Per) : Year1Per;

              break;
            case 'YEAR2PER':
              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year2Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[1].value = (value % 2 === 0) ? Math.floor(value) : value;
              }

              let Year2Per: any = pyramidValues[1].toFixed(1);
              this.totalGriddata[0].Year2Per = (Year2Per % 2 === 0) ? Math.floor(Year2Per) : Year2Per;


              break;
            case 'YEAR3PER':

              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year3Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[2].value = (value % 2 === 0) ? Math.floor(value) : value;
              }


              let Year3Per: any = pyramidValues[2].toFixed(1);
              this.totalGriddata[0].Year3Per = (Year3Per % 2 === 0) ? Math.floor(Year3Per) : Year3Per;

              break;
            case 'YEAR4PER':

              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year4Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[3].value = (value % 2 === 0) ? Math.floor(value) : value;
              }

              let Year4Per: any = pyramidValues[3].toFixed(1);
              this.totalGriddata[0].Year4Per = (Year4Per % 2 === 0) ? Math.floor(Year4Per) : Year4Per;

              break;
            case 'YEAR5PER':

              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year5Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[4].value = (value % 2 === 0) ? Math.floor(value) : value;
              }

              let Year5Per: any = pyramidValues[4].toFixed(1);
              this.totalGriddata[0].Year5Per = (Year5Per % 2 === 0) ? Math.floor(Year5Per) : Year5Per;
              break;
            case 'YEAR6PER':

              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year6Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[5].value = (value % 2 === 0) ? Math.floor(value) : value;
              }


              let Year6Per: any = pyramidValues[5].toFixed(1);
              this.totalGriddata[0].Year6Per = (Year6Per % 2 === 0) ? Math.floor(Year6Per) : Year6Per;

              break;
            case 'YEAR7PER':

              for (let i = 0; i < pyramidValues.length; i++) {
                pyramidValues[i] += item.Year7Per;
                let value: any = pyramidValues[i].toFixed(1);
                this.pyramid_custom[6].value = (value % 2 === 0) ? Math.floor(value) : value;
              }
              let Year7Per: any = pyramidValues[6].toFixed(1);
              this.totalGriddata[0].Year7Per = (Year7Per % 2 === 0) ? Math.floor(Year7Per) : Year7Per;
              break;
          }



        }
      });



      //this.fourthGridResult = true;
    }
    console.log('calculationStaffingPyramid_onchange exit');
  }

  setCellLock(fieldname: string, item: any, parent: StaffingComponent): boolean {
    let bLock = false;
    let BlkCols: any[] = [];
    ////StaffingModel///

    if (!isNullOrUndefined(parent.Config.POLBlockCols) && parent.Config.POLBlockCols.length > 0) {
      parent.Config.POLBlockCols.forEach((blockitem: any) => {
        if (blockitem.Value == item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
        if (item.ResourceType == 'RESTY2') {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (!isNullOrUndefined(parent.Properties.BlockCols) && parent.Properties.BlockCols.length > 0) {
      parent.Properties.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value >= item[blockitem.Field]) {
          blockitem.BlockCols.forEach((item: any) => {
            BlkCols.push(item);
          });
        }
      });
    }

    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }
    return bLock;

  }

  saveSpecificPyramid() {
    if (this.validateSpecificPyramid(this.SelectedPyramidPopupDetailsData)) {
      let subscribe$: Subscription = this.LotSolutionService.UpdateSpecificPyramidPopUp(this.SelectedPyramidPopupDetailsData).subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          this.SavePyramidPopupDetailsData = result.Data;



          //  if (this.onStaffingPyramidChange == true) {
          this.showNotificationMessage('Pyramic Details Saved Successfully!', 1, '', '', '', '', 1000);
          //     this.closeModal('PyramidPopupDetails');
          //   }
          //   else {
          //     this.closeModal('PyramidPopupDetails');
          //   }
        }
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting UpdatePyramidPopupDetails');
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }
  }

  cancelSpecificPyramid() {
    this.closeModal('PyramidPopupDetails');
  }
  validateSpecificPyramid(modifieditems: any): boolean {
    let valid: boolean = false;

    if (!isNullOrUndefined(modifieditems)) {
      if ((modifieditems.SpecificPyramidSelectionDesc == 'Other SBU') && ((isNullOrUndefined(modifieditems.SBUDesc)) || (modifieditems.SBUDesc == ""))) {
        this.showNotificationMessage('Please Select Other SBU', 2, '', null, '', '', 1000);
        valid = false;
      }
      else if ((isNullOrUndefined(modifieditems.SpecificPyramidSelectionDesc)) || modifieditems.SpecificPyramidSelectionDesc == "") {
        this.showNotificationMessage('Please fill all mandatory fields', 2, '', null, '', '', 1000);
        valid = false;
      }
      else {
        valid = true;
      }
    }
    return valid
  }



  isCapgeminiSelected(): boolean {
    return (this.DealData.RateModellingOption === 'RTM001' || this.DealData.RateModellingOptionDesc === 'Capgemini');
  }

  isFourthGridValid(): boolean {
    return this.PyramidTemplate != null && this.PyramidTemplate !== undefined && this.PyramidTemplate.length > 0;
  }

  isStaffingDataValid(): boolean {
    return this.PyramidData != null && this.PyramidData !== undefined && this.PyramidData.length > 0;
  }

  isNearshOrOffsh(): boolean {
    return this.LocationType === 'NEARSH' || this.LocationType === 'OFFSH';
  }

  isExternalSelected(): boolean {
    return (this.DealData.RateModellingOption !== 'RTM001' || this.DealData.RateModellingOptionDesc === 'External');
  }


  isLocationTypeOnsh(): boolean {
    return this.LocationType === 'ONSH';
  }

  isLocationTypeNotOnsh(): boolean {
    return this.LocationType !== 'ONSH';
  }










}
