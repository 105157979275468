
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../shared/app.sharedservice';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus, ReferenceValueType } from '../app.interface';
import { CommonAPIService } from '../shared/app.commonservice';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { OffshorePerInterface, ProductivityInterface, FTEInterface, ServicetypeInfo } from './servicetyps.interface';
import { GenericComponent } from '../app.genericcomponent';
import { ServicetypsService } from './servicetyps.service';
import { GridComponent } from 'src/modules/grid/grid.component';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { TabSetComponent } from 'src/modules/tabset/tabset.component';
import { environment } from '../../environments/environment';
import { TabItem } from 'src/modules/tabset/tabset.interface';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-servicetyps',
  templateUrl: './servicetyps.component.html',
  styleUrls: ['./servicetyps.component.css']
})

@AutoUnsubscribe
export class ServicetypsComponent extends GenericComponent<ServicetypeInfo> implements OnInit {

  @Output() public servicetypsOnChange = new EventEmitter<any>();

  @ViewChild('ManagedServiceGrid', { static: false }) ManagedServiceGrid!: GridComponent;
  @ViewChild('offshorepopup', { static: false }) offshorepopup!: ModalComponent;
  @ViewChild('productivitypopup', { static: false }) productivitypopup!: ModalComponent;
  @ViewChild('ftecount', { static: false }) ftecount!: ModalComponent;
  public Title: any = 'Services';
  public selectedView: string = 'ServicetypsComponent';
  public ManageServicesTemplate: FieldTemplate[] = [];
  public ServicesMSReport: FieldTemplate[] = [];
  public ServicesToolsReport: FieldTemplate[] = [];
  public ServicesHardwareReport: FieldTemplate[] = [];
  public ServicesPCReport: FieldTemplate[] = [];
  public ServicesRCReport: FieldTemplate[] = [];
  public ManagedServiceButtons: any = [];
  public ManageServicesPermission: any = [];
  public STATUSDATA: any = [];
  public filterdata: any = [];
  public GlobalConfig: any = {};
  public modifiedItem: any;
  public OffshoreObj: any = {};
  public IsSupportTypeOnsiteflag: boolean = false;
  public Toolsflag: boolean = false;
  public productivityObj: any = {};
  public FTEcountObj: any = {};
  public ManagedServicesObj: any = {};
  public outputscreenDropdown: any = {};
  public OffshoreObjArray: any = [];
  public selectedTab: string = '';
  public ManagedServiceData: any = {};
  public ManagedServiceListData: any[] = [];
  public OffshoreManagedServiceData: any[] = [];
  public productivityData: any[] = [];
  public Flagoutput: boolean = false;
  public _Data_Copy: any[] = [];
  public _Data_TCVReport: any[] = [];
  public _Data_SPLUNKFORM: any[] = [];
  public productivitysavedData: any[] = [];
  public FTECountData: any[] = [];
  public FTEsavedData: any[] = [];
  public OffshoreManagedServiceALLData: any[] = [];
  public OffshoreManagedSavedData: any[] = [];
  public ManagedServiceListDataALL: any[] = [];
  public selectedDealTabs: any[] = [];
  public ServiceTabsNames: any[] = [];
  public selectedItem: any[] = [];
  public copylist: any = [];
  public dataTotalCount: number = 0;
  CalculationsCount: any;
  public DataInput: object = { PageIndex: 0, PageSize: 0 };
  public serviceTabs: any[] = [];
  public selectedInfoData: any;
  public curItemsPerPage: number = 25;
  public IsLoading: boolean = false;
  public nextFlag: boolean = false;
  public backFlag: boolean = false;
  public Format: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public displayNotifybox: boolean = false;
  public displayoutputNotifybox: boolean = false;
  public saveOffshore: boolean = false;
  public notify: NotificationData = new NotificationData();
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public ExcelFileName: string = '';
  public SelectedButtonValue: any;
  public CompTitle = 'Service Type';
  public year1offShoreFlag: boolean = false;
  public offShoreFlagSaveClick: boolean = false;
  public ftepopupflag: boolean = false;
  public productivitypopupflag: boolean = false;
  public modalInf: ModalInterface = new ModalInterface();
  public selectedDeal: any;
  public selectedpopup: any;
  public offshoreLength: number = 2;
  public productivitylength: number = 2;
  public ftelength: number = 1;
  public saveProductivity: any;
  public saveftecount: any;
  public DeleteDataList: any = [];
  public configtabs: any = [];
  public existingRows: boolean = false;
  public outputscreenNavigate: boolean = false;
  public rangeValidation: any;
  public rangearray: any[] = [];
  public saveModifieditems: any = [];
  public supportTeam: any;
  public FromNavigateFlag: boolean = false;
  public completedDeal: boolean = false;
  public modifiedflag: boolean = false;

  public TOOLCISSUBFIELDS: any = {};
  public onInitData: any = {
    ServiceType: '',
    DealHeaderId: '',
    DealLIneitemId: ''
  };
  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    AddNewRow: false,
    DefaultRow: [],
    DataObject: {},
    UniqueKeyName: ''
  }

  public ReportFormProp: FormProperty = {
    'ColSize': 20,
    'CaptionBold': false,
    // 'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };

  public ReportFormProp1: FormProperty = {
    'ColSize': 1,
    'CaptionBold': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };
  // public ReportFormProp1: FormProperty = {
  //   'ColSize': 100,
  //   'CaptionBold': false,
  //   // 'CaptionTop': true,
  //   'IsEditable': true,
  //   'ShowControlAlways': true
  // };
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };


  public GridPropforoffshorevolume: any = {
    Editable: true,
    // PaginationConfig: {
    //   ItemsPerPage: 10
    // }
    // ActionBarConfig: {
    //   ActionBarList: null
    // },
    // AddNewRow: false,
    // DefaultRow: [],
    // DataObject: {},
    // UniqueKeyName: ''
  }


  public OffShoreProp: any = [

    {
      "FieldName": "Offshore",
      "value": "Offshore%"
    },
    {
      "FieldName": "Volume",
      "value": "Volume"
    },

  ]

  public ProductivityProp: any = [

    {
      "FieldName": "Inflation",
      "value": "Inflation%"
    },
    {
      "FieldName": "Productivity",
      "value": "Productivity%"
    },

  ]


  public Config: any = {
    ActionButtons: []
  };
  public DealHeaderId: any;
  public DealItemId: any;
  public txId: any;
  public ServiceCode: any;
  constructor(private title: Title, private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private modalService: ModalService, private toastr: ToastrService, private _servicetypsService: ServicetypsService, public tabs: TabSetComponent) {
    super(ServicetypeInfo, _commonAPIService, _servicetypsService, null);
    this._Data = [];

    let subscribe$: Subscription = this._appSharedService.containerData$.subscribe((data) => {
      this._Data = [];
      this._Template = [];
      this._Config = [];
      this.ngOnInit();

    });


    this.subscriptionList$.push(subscribe$);
    console.log(this._Template, 'temp')
    this._route.params.subscribe((params: { [x: string]: any; }) => {
      this.DealHeaderId = params['DealHeaderId'];

    });
    console.log(this.DealHeaderId);
    this._GridProp.IsSortable = false;
    // let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
    //   this.ngOnInit();
    // });
    // this.subscriptionList$.push(subscribe$);

  }

  ngOnInit(): void {
    let selTab = this._appSharedService.SelectedTab;
    this._GridProp.IsSortable = false;
    this.TOOLCISSUBFIELDS.IsEditable = 1;
    let result = selTab.slice(0, 6);

    console.log(result, "tabs")
    if (result == "STR002") {
      this.selectedTab = "PandC"
    }
    else if (result == "STR001") {
      this.selectedTab = "ManagedServices"
    }
    else if (result == "STR003") {
      this.selectedTab = "RateCard"
    }

    else if (result == "STR004") {

      this.selectedTab = "Tools"
      // this.filterDataByToolNames()
    }
    else if (result == "STR005") {
      this.selectedTab = "Hardware"
    }
    this._Data = [];

    this.title.setTitle(this.Title);
    this.outputscreenDropdown.IsEditable = 1;
    //this.OffshoreManagedSavedData[0].IsEditable = 0;
    /* this._appSharedService.getSelectedDeal().subscribe((response:any) => {
      console.log(response);  // you will receive the data from sender component here.
    }); */

    if (this._appSharedService.IsEditable == false) {
      this._GridProp.Editable = false;
      this._GridPropoffshoreVolume.Editable = false;

      this.GridProp.Editable = false;
      this.year1offShoreFlag = true;
    }

    this.selectedDeal = this._appSharedService.selectedDeal;
    if (this.selectedDeal.DealStatus === '0' ||
      this.selectedDeal.DealStatus === '-1' || this.selectedDeal.DealStatus === '2') {
      //this.FormProp.IsEditable = false;
      this._appSharedService.IsEditable = false;
      this._appSharedService.IsEditable$.next(this._appSharedService.IsEditable);

    }
    if (this.selectedDeal.DealStatus === '2') {
      this.completedDeal = true;
      this.TOOLCISSUBFIELDS.IsEditable = 0;
    }
    console.log("deal@@@@", this.selectedDeal.DealStatus)
    this.selectedDealTabs = this.selectedDeal.Services.split(',');
    this.ServiceTabsNames = [];



    for (let i = 0; i < this.selectedDealTabs.length; i++) {

      switch (this.selectedDealTabs[i].toUpperCase()) {
        case 'STR001':
          this.ServiceTabsNames.push('ManagedServices');
          break;
        case 'STR003':
          this.ServiceTabsNames.push('RateCard');
          break;
        case 'STR002':
          this.ServiceTabsNames.push('PandC');
          break;
        case 'STR004':
          this.ServiceTabsNames.push('Tools');
          break;
        case 'STR005':
          this.ServiceTabsNames.push('Hardware');
      }



    }
    this.getreportsTemplate();
    this.getDealDetails(this.selectedTab);

    let odata_: any = {
      DealHeaderId: this.DealHeaderId,
      RoleId: 0,
      UserId: this._appSharedService.UserRoleList[0].UserId,
      // DealLineItemId: this.DealItemId,
      // ServiceType: this.ServiceCode

    }
   // this.GetSplunkModuleToolData(odata_);

   

    // let odata_report: any = {
    //   DealHeaderId: this.DealHeaderId,
    //   RoleId: 0,
    //   UserId: this._appSharedService.UserRoleList[0].UserId,
    //   DealLineItemId: '1467',
    //   ServiceType: 'STR001'

    // }
    // this.GetServicesTCVReport(odata_report);


    this._GridProp['TableWidth'] = 150;




    //this._GridProp.Editable = true;

    this._IsReadOnly = false;

    this._GridPropoffshoreVolume.Editable = true;


    // let otmpltdata: ComponentConfig = {

    //   ComponentCode: ComponentCodes.ServicetypsComponent, Component: 'Servicetyps', SubComponent: '',

    // };





    //  let odata: object = {};
    // let odata: object = {
    //   ServiceType: this.onInitData.ServiceType,
    //   DealHeaderId: this.DealHeaderId,
    //   DealLIneitemId: this.onInitData.DealLIneitemId
    // };
    // odata = this.onInitData;
    console.log(this.onInitData)

    // this.initComponent(otmpltdata, odata, null, this.CompTitle, 'TransactionId');
    // console.log(odata, 'iiiiii')



  }


  getreportsTemplate(): void {


    let otmpltdata1: ComponentConfig = {

      ComponentCode: ComponentCodes.ServicetypsComponent, Component: 'Servicetyps', SubComponent: '',

    };

    this.IsLoading = true;

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(otmpltdata1).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        // this.SearchPermission = res.Data.Permission;

        // this.SearchTemplate = template.filter(x => x.Group === 'ResultValueAM');
        this.ServicesMSReport = template.filter(x => x.Group === 'ServicesMSReport');
        this.ServicesPCReport = template.filter(x => x.Group === 'ServicesPCReport');
        this.ServicesRCReport = template.filter(x => x.Group === 'ServicesRCReport');
        this.ServicesToolsReport = template.filter(x => x.Group === 'TCVTOOLSERVICE');
        this.ServicesHardwareReport = template.filter(x => x.Group === 'TCVHARDWARESERVICE');
        // console.log("CLONE CIS");
        // console.log(this.cloneDealFormTemplate);
        // this.AmDealReplicaOptionTemplate = template.filter(x => x.Group === 'AmDealReplicaOption');
        // this.ShareDealData.IsEditable = 1;
        // // console.log("^^^")
        // console.log(this.ShareDealData);
        this.setCompProperties(res.Data.Properties!);
        // console.log("BUUTONS FOR AM")
        // console.log(this.Config.AMActionButtons);
        // this.SearchButtons = this.Config.AMActionButtons.filter((x: any) => x.Group === 'AMSrchButton');
        // this.QuickLinksButton = this.Config.AMActionButtons.filter((x: any) => x.Group === 'AMLinks');
        // this.GridProp.ActionBarConfig.ActionBarList = this.Config.AMActionButtons.filter((x: any) => x.Group === 'AMGridSearch');
        this.setActionButtonPermissions();

      }


    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }


  afterTemplateLoad(bStatus: boolean) {
    this.setActionButtonPermissions();
  }

  goBack() {
    window.history.back();
    // this.location.back();

    console.log('goBack()...');
  }

  setActionButtonPermissions(): void {
    let local: any = [];
    // Service Types Permission
    if (!isNullOrUndefined(this._Config.ServiceTypesTabs) && this._Config.ServiceTypesTabs.length > 0) {

      for (let i = 0; i < this.ServiceTabsNames.length; i++) {

        local = (this._Config.ServiceTypesTabs.filter((x: any) => x.Name == this.ServiceTabsNames[i]));
        this.configtabs = [...this.configtabs, local[0]]
        console.log("fsfsfs")
        console.log(this.configtabs)

      }
      this._Config.ServiceTypesTabs = [];
      this._Config.ServiceTypesTabs = this.configtabs;
      this.configtabs = [];
      console.log("dgsg")
      console.log(this._Config.ServiceTypesTabs);
      this._Config.ServiceTypesTabs.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {

          item.HasPermission = false;
          item.IsApplicable = false;

          let peritem = this._Permission.indexOf(item.Name);

          if (peritem >= 0) {
            switch (item.Name.toUpperCase()) {
              case 'MANAGEDSERVICES':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
              case 'RATECARD':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
              case 'PANDC':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
              case 'TOOLS':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
            }
          }

        }
      });

      if (this._GridProp.Editable == false) {
        this._GridProp.ActionBarConfig.ActionBarList = this._Config.ActionButtons;
        let actnbttns = this._GridProp.ActionBarConfig.ActionBarList;
        (actnbttns).forEach((e: any) => {
          switch (e.Name.toUpperCase()) {
            case 'ADDNEWROW':
              e.IsApplicable = false;
              break;
            case 'SAVE':
              e.IsApplicable = false;
              break;
            case 'COPYROW':
              e.IsApplicable = false;
              break;
            case 'DELETE':
              e.IsApplicable = false;
              break;
            case 'REFRESH':
              e.IsApplicable = false;
              break;
          }
        })

      }


    }

    // Manged Serivice Buttons Permissions
    if (!isNullOrUndefined(this._Config.ManagedServiceButtons) && this._Config.ManagedServiceButtons.length > 0) {
      this._Config.ManagedServiceButtons.forEach((item: any) => {
        if (!isNullOrUndefined(item.Name) && item.Name !== '') {

          item.HasPermission = false;
          item.IsApplicable = false;

          let peritem = this._Permission.indexOf(item.Name);

          if (peritem >= 0) {
            switch (item.Name.toUpperCase()) {
              case 'NEXT':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
              case 'BACK':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
              case 'DONE':
                item.HasPermission = true;
                item.IsApplicable = true;
                break;
            }
          }

        }
      });
    }

  }



  setCellLock(fieldname: string, item: any, parent: ServicetypsComponent): boolean {
    let bLock = false;
    let BlkCols: any[] = [];

    if (!isNullOrUndefined(parent._Config.BlockCols) && parent._Config.BlockCols.length > 0) {
      parent._Config.BlockCols.forEach((blockitem: any) => {
        if (blockitem.Value <= item[blockitem.Field]) {
          blockitem.BlockCols.forEach((element: any) => {
            BlkCols.push(element);
          });
        }
      });
    }

    if (!isNullOrUndefined(parent._Config.ToolBlockCols) && parent._Config.ToolBlockCols.length > 0) {
      parent._Config.ToolBlockCols.forEach((blockitem: any) => {
        if (blockitem.Value <= item[blockitem.Field]) {
          blockitem.ToolBlockCols.forEach((element: any) => {
            BlkCols.push(element);
          });
        }
      });
    }


    if (BlkCols.length > 0) {
      bLock = (BlkCols.indexOf(fieldname.toUpperCase()) >= 0 ? true : false);
    }

    return bLock;
  }











  setGridOnblur() {
    if (!isNullOrUndefined(this.ManagedServiceGrid)) {
      this.ManagedServiceGrid.gridOnblur();
    }
  }

  actButtonClick(event: any): void {
    this.setGridOnblur();
    console.log(event.buttonname);
    switch (event.buttonname.toUpperCase()) {
      case 'ADDNEWROW':
        let emptyrows = this._Data.filter((x: any) => x['IsModified'] === 0 && x[this.UniqueKeyName] <= 0);

        if (emptyrows.length > 0) {
          this.showNotificationMessage('Please fill in the available empty row.', 2, '', null, '', '', 2500);
          return;
        }
        this.addNewRow();

        //////copy row code for mangaed services/////////

        if (this._Data[0].SLADesc != null || this._Data[0].SLADesc != undefined) {

          this._Data[this._DataTotalCount - 1].SLADesc = this._Data[0].SLADesc;
          this._Data[this._DataTotalCount - 1].SLA = this._Data[0].SLA;
        }

        if (this._Data[0].DeliveryModelDesc != null || this._Data[0].DeliveryModelDesc != undefined) {

          this._Data[this._DataTotalCount - 1].DeliveryModelDesc = this._Data[0].DeliveryModelDesc;
          this._Data[this._DataTotalCount - 1].DeliveryModel = this._Data[0].DeliveryModel;
        }

        if (this._Data[0].ServiceWindowDesc != null || this._Data[0].ServiceWindowDesc != undefined) {
          this._Data[this._DataTotalCount - 1].ServiceWindowDesc = this._Data[0].ServiceWindowDesc;
          this._Data[this._DataTotalCount - 1].ServiceWindow = this._Data[0].ServiceWindow;

        }



        if (this._Data[0].OffshoreCountryDesc != null || this._Data[0].OffshoreCountryDesc != undefined) {
          this._Data[this._DataTotalCount - 1].OffshoreCountryDesc = this._Data[0].OffshoreCountryDesc;
          this._Data[this._DataTotalCount - 1].OffshoreCountry = this._Data[0].OffshoreCountry;
        }

        if (this._Data[0].OnsiteCountryDesc != null || this._Data[0].OnsiteCountryDesc != undefined) {
          this._Data[this._DataTotalCount - 1].OnsiteCountryDesc = this._Data[0].OnsiteCountryDesc;
          this._Data[this._DataTotalCount - 1].OnsiteCountry = this._Data[0].OnsiteCountry;
        }


        this._Data_Copy = this._Data.map(obj => ({
          "SLADesc": obj.SLADesc,
          "DeliveryModelDesc": obj.DeliveryModelDesc,
          "ServiceWindowDesc": obj.ServiceWindowDesc,
          "OffshoreCountryDesc": obj.OffshoreCountryDesc,
          "OnsiteCountryDesc": obj.OnsiteCountryDesc,
          "SLA": obj.SLA,
          "DeliveryModel": obj.DeliveryModel,
          "ServiceWindow": obj.ServiceWindow,
          "OffshoreCountry": obj.OffshoreCountry,
          "OnsiteCountry": obj.OnsiteCountry
        }));

        break;

      case 'SAVE':
        this._IsSaving = false;
        this.offShoreFlagSaveClick = false;


        //   if (this.selectedTab != 'Tool') {
        this.saveClick();
        // }


        //  if (this.selectedTab == 'Tool') {
        //   this.saveClickToolsandHardware();
        //  }
        // this.autoSave();
        break;

      case 'DELETE':
        this.deleteResoRows();
        break;

      case 'COPYROW':
        let odata_: any = {
          DealHeaderId: this.DealHeaderId,
        }
        this.getservicedetails_copy(odata_);
        break;
      case 'TCVOUTPUT':
        this.TCVOUTPUT();
        break;

      case 'MANAGEDSERVICESREFFRENCEPDF':
        this.downloadpdf();
        break;

      case 'REFRESH':
        let modifiedRows = this._Data.filter((x: any) => x['IsModified'] === 1);
        if (modifiedRows.length > 0) {
          this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'REFRESH', this.selectedTab, 'Cancel', 'OK', 10000);
        }
        else {
          //this.getDealDetails(this.selectedTab);
          super.getData(true);
          // let odata_report: any = {
          //   DealHeaderId: this.DealHeaderId,
          //   RoleId: 0,
          //   UserId: this._appSharedService.UserRoleList[0].UserId,
          //   DealLineItemId: this.DealItemId,
          //   ServiceType: this.ServiceCode

          // }
          // this.GetServicesTCVReport(odata_report);
          //  this.Flagoutput = 'NO';
          this.showNotificationMessage('Data refreshed!', 1, '', '', '', 'OK', 1000);

        }
        break;

    }
  }

  downloadpdf() {
    // window.open('../../assets/files/ServiceRateCard.pdf'); 
    if (this.selectedTab.toUpperCase() == "MANAGEDSERVICES") {
      let a = document.createElement('a');
      a.href = '../../assets/files/ManagedServicesReferenceFile.xlsx';
      a.target = '_blank';
      a.download = 'ManagedServices.xlsx';
      document.body.appendChild(a);
      a.click();

    }
    if (this.selectedTab.toUpperCase() == "RATECARD") {
      let a = document.createElement('a');
      a.href = '../../assets/files/RateCardReferenceFile.xlsx';
      a.target = '_blank';
      a.download = 'RateCard.xlsx';
      document.body.appendChild(a);
      a.click();

    }
    if (this.selectedTab.toUpperCase() == "PANDC") {
      let a = document.createElement('a');
      a.href = '../../assets/files/PandCReferenceFile.xlsx';
      a.target = '_blank';
      a.download = 'PandC.xlsx';
      document.body.appendChild(a);
      a.click();

    }
    if (this.selectedTab.toUpperCase() == "TOOLS") {
      let a = document.createElement('a');
      a.href = '../../assets/files/Tools Reference File.xlsx';
      a.target = '_blank';
      a.download = 'Tools.xlsx';
      document.body.appendChild(a);
      a.click();

    }
    if (this.selectedTab.toUpperCase() == "HARDWARE") {
      let a = document.createElement('a');
      a.href = '../../assets/files/Hardware Reference File.xlsx';
      a.target = '_blank';
      a.download = 'Hardware.xlsx';
      document.body.appendChild(a);
      a.click();

    }

  }




  GetServicesTCVReport(data: any) {
    this._IsLoading = true; // Show loader when starting data retrieval

    let subscribe$: Subscription = this._servicetypsService.GetServicesTCVReport(data).subscribe(
      result => {
        console.log(result);

        if (!isNullOrUndefined(result) && result.Status == 200) {
          console.log(result);
          this._Data_TCVReport = result.Data;
          this.dataTotalCount = result.Data.length;
        }

        // Hide loader after a short delay to ensure it's visible even for quick data retrievals
        setTimeout(() => {
          this._IsLoading = false; // Hide loader after a brief delay
        }, 1000); // Adjust this delay as needed

      },
      err => {
        this._IsLoading = false; // Hide loader in case of error
      }
    );

    this.subscriptionList$.push(subscribe$);
  }



  GetSplunkModuleToolData(data: any) {
    this._IsLoading = true; // Show loader when starting data retrieval

    let subscribe$: Subscription = this._servicetypsService.GetSplunkModuleToolData(data).subscribe(
      result => {
        console.log(result);

        if (!isNullOrUndefined(result) && result.Status == 200) {
          console.log(result);
          this._Data_SPLUNKFORM = result.Data;
          console.log(this._Data_SPLUNKFORM[0].NumberOfSplunk);
            this.TOOLCISSUBFIELDS.NumberOfSplunk = this._Data_SPLUNKFORM[0].NumberOfSplunk;
            this.TOOLCISSUBFIELDS.NumberOfModule = this._Data_SPLUNKFORM[0].NumberOfModule;
          if (this._Data_SPLUNKFORM[0].NumberOfSplunk == 0 && this._Data_SPLUNKFORM[0].NumberOfModule == 0) {
            this.Toolsflag = false;
          }
          else {
            this.Toolsflag = true;
          }


          // const filteredData = this._Data.filter(item => item.ToolName === 'TOL007' || item.ToolName === 'TOL006');
          // if (filteredData.length > 0) {
           
          //   this.Toolsflag = true; 
          //   this.TOOLCISSUBFIELDS.NumberOfSplunk = this._Data_SPLUNKFORM[0].NumberOfSplunk;
          //   this.TOOLCISSUBFIELDS.NumberOfModule = this._Data_SPLUNKFORM[0].NumberOfModule;
          // }
          // else{
          //   this.Toolsflag = false;
          // }

        }

        // Hide loader after a short delay to ensure it's visible even for quick data retrievals
        setTimeout(() => {
          this._IsLoading = false; // Hide loader after a brief delay
        }, 1000); // Adjust this delay as needed

      },
      err => {
        this._IsLoading = false; // Hide loader in case of error
      }
    );

    this.subscriptionList$.push(subscribe$);
  }









  async saveClick() {

    this.saveModifieditems = [];
    this.existingRows = false;
    console.log('inside save click');
    // setTimeout(() => {

    // this.getDealDetails(this.selectedTab);
    console.log(this._IsSaving);
    if (!this._IsSaving || this.saveOffshore == true || this.saveProductivity == true || this.saveftecount == true) {
      this._IsSaving = true;

      // this.APICallCounter++;
      console.log('after counter increase inside save click');
      console.log(this._Data);
      if ((this._Data.length > 0 && this._Data.filter(x => x.IsModified === 1).length > 0)) {
        let modifieditems = this._Data.filter(x => x.IsModified === 1);
        this.saveModifieditems = modifieditems
        console.log("modifieditems");
        console.log(modifieditems);
        //   this.getDealDetails(this.selectedTab);

        if (modifieditems.length > 0) {

          if (this.ServiceCode == 'STR004' || this.selectedTab == 'Tools') {
            modifieditems.forEach((item: any) => {
              item.DealHeaderId = this.DealHeaderId;
              item.ServiceType = this.ServiceCode;
              item.DealLIneitemId = this.DealItemId;
              item.NumberOfSplunk = this.TOOLCISSUBFIELDS.NumberOfSplunk;
              item.NumberOfModule = this.TOOLCISSUBFIELDS.NumberOfModule;
            });
          }
          else {
            modifieditems.forEach((item: any) => {
              item.DealHeaderId = this.DealHeaderId;
              item.ServiceType = this.ServiceCode;
              item.DealLIneitemId = this.DealItemId;

            });
          }


          console.log("modifieditems");
          console.log(modifieditems);
          if (this.checkValidation(modifieditems)) {
            //  if (this.canSave(modifieditems)) {
            await this.saveResoPlan(modifieditems, 'Save');


            //  this.Flagoutput = 'NO';
            // let odata_report: any = {
            //   DealHeaderId: this.DealHeaderId,
            //   RoleId: 0,
            //   UserId: this._appSharedService.UserRoleList[0].UserId,
            //   DealLineItemId: modifieditems[0].DealLIneitemId,
            //   ServiceType: modifieditems[0].ServiceType

            // }
            //    await super.getData(true);
            //   this.GetServicesTCVReport(odata_report);
            // }
            // else {
            // this.APICallCounter--;
            //    this._IsSaving = false;
            // }
          }
          else {
            // this.APICallCounter--;
            this._IsSaving = false;
          }
        }
        else {
          // this.APICallCounter--;
          this._IsSaving = false;
          this.nextFlag = false;
          this.displayNotifybox = true;
          // this.toastr.warning('No records modified', this.CompTitle);
          this.showNotificationMessage('No rows are modified.', 2, '', null, '', '', 1000);
        }
      }
      else {
        // this.APICallCounter--;

        // let modifieditems = this._Data.filter(x => x.IsModified === 1);

        // let nonmodifieditems = this._Data.filter(x => x.IsModified === 0);
        // if (nonmodifieditems.length > 0) {
        //   this.existingRows = true;
        // }
        // else {
        //    this.showNotificationMessage('Please enter the required fields to continue', 2, '', null, '', '');
        //next}

        this._IsSaving = false;
      }
    }

    // else if(this._Data.filter(x => x.IsModified === 1).length>0){
    //   this.existingRows = true;

    // } 
    else {
      this.displayoutputNotifybox = false;
      this.outputscreenNavigate = false;
      this.showNotificationMessage('Please enter the required fields to continue', 2, '', null, '', '', 2000);
    }
    // }, 1000);
  }




  canSave(modifieditems: ServicetypeInfo[]): boolean {
    console.log("cansave");
    console.log(modifieditems)

    let bSaveflg: boolean = true;

    let mandcols = this._Template.filter(x => x.EditValidate.Required === true);

    if (mandcols.length > 0) {
      modifieditems.forEach((item: any) => {
        item.DealHeaderId = this.DealHeaderId;
        let bCond: boolean = true;

        for (let j: number = 0; j < mandcols.length; j++) {
          let col = mandcols[j];

          // avoid mandatory fields checking if the field is locked due to values in other fields
          let cellLock: boolean = this.setCellLock(col.FieldName, item, this);

          if (!cellLock) {
            bCond = bCond && !(isNullOrUndefined(item[col.FieldName]) || item[col.FieldName].length === 0);
          }

          if (!bCond) { break; }
        }

        item.IsValid = (bCond === true ? true : false);

      });
    }

    return bSaveflg;
  }

  checkValidation(modifieditems: ServicetypeInfo[]): boolean {
    let PTCond: boolean = true;
    console.log("check validation");
    console.log(modifieditems);

    let mandcols = this._Template.filter(x => x.EditValidate.Required === true);
    console.log("check mandatory");
    console.log(mandcols);
    switch (this.selectedTab.toUpperCase()) {
      case 'MANAGEDSERVICES':

        if (modifieditems.length != 0 && modifieditems != null) {
          if (!isNullOrUndefined(modifieditems[0].Offering) &&
            !isNullOrUndefined(modifieditems[0].Tower) &&
            !isNullOrUndefined(modifieditems[0].Technology) &&
            !isNullOrUndefined(modifieditems[0].Complexity) &&
            !isNullOrUndefined(modifieditems[0].PricingUnit) &&
            !isNullOrUndefined(modifieditems[0].SLA) &&
            !isNullOrUndefined(modifieditems[0].DeliveryModel) &&
            !isNullOrUndefined(modifieditems[0].ServiceWindow) &&
            !isNullOrUndefined(modifieditems[0].OnsiteCountry) &&
            !isNullOrUndefined(modifieditems[0].OffshoreCountry)) {
            PTCond = true;
          }
          else {
            PTCond = false;
            this.outputscreenNavigate = false;
            this.showNotificationMessage('To continue, please fill all the mandatory fields', 2, '', null, '', '', 3000);
          }
        }
        else {
          this.existingRows = true;
        }
        break;
      case 'RATECARD':
        if (modifieditems.length != 0 && modifieditems != null) {
          if (!isNullOrUndefined(modifieditems[0].Source) &&
            !isNullOrUndefined(modifieditems[0].Tower) &&
            !isNullOrUndefined(modifieditems[0].Subtower) &&
            !isNullOrUndefined(modifieditems[0].MappedRole) &&
            !isNullOrUndefined(modifieditems[0].Location) &&
            !isNullOrUndefined(modifieditems[0].MappedYoE) &&
            !isNullOrUndefined(modifieditems[0].NoofMonths) &&
            this.Format == true)
          // !isNullOrUndefined(modifieditems[0].HrsDay) 
          // && !isNullOrUndefined(modifieditems[0].NoofDaysinMonth)
          {
            PTCond = true;
          }
          else {
            PTCond = false;
            this.outputscreenNavigate = false;
            /*    if (this.Format == false) {
                 this.showNotificationMessage('To continue, please enter correct values in No of Months', 2, '', null, '', '');
               }
               else { */
            this.showNotificationMessage('To continue, please fill all the mandatory fields', 2, '', null, '', '', 3000);

          }
        }
        else {
          this.existingRows = true;
        }
        break;
      case 'PANDC':
        if (modifieditems.length != 0 && modifieditems != null) {
          if (!isNullOrUndefined(modifieditems[0].Offering) &&
            !isNullOrUndefined(modifieditems[0].Tower) &&
            !isNullOrUndefined(modifieditems[0].Technology) &&
            !isNullOrUndefined(modifieditems[0].Complexity) &&
            !isNullOrUndefined(modifieditems[0].PricingUnit) &&
            !isNullOrUndefined(modifieditems[0].Location) &&
            !isNullOrUndefined(modifieditems[0].Phase) &&
            !isNullOrUndefined(modifieditems[0].Size) &&
            !isNullOrUndefined(modifieditems[0].Volume)
          ) {
            PTCond = true;
          }
          else {
            PTCond = false;
            this.outputscreenNavigate = false;
            this.showNotificationMessage('To continue, please fill all the mandatory fields', 2, '', null, '', '', 3000);
          }
        }
        else {
          this.existingRows = true;
        }
        break;
      case 'TOOLS':


        if (modifieditems.length !== 0 && modifieditems !== null) {
          modifieditems.forEach((item, index) => {
            if (
              !isNullOrUndefined(item.ToolName) && item.ToolName !== '' &&
              !isNullOrUndefined(item.Volume) && item.Volume !== '' &&
              !isNullOrUndefined(item.Complexity) && item.Complexity !== '' &&
              !isNullOrUndefined(item.PricingUnit) && item.PricingUnit !== ''
            ) {
              PTCond = true;
            } else {
              PTCond = false;
              this.outputscreenNavigate = false;
              this.showNotificationMessage('To continue, please fill all the mandatory fields', 2, '', null, '', '', 3000);
            }
          });
        }
        else {
          this.existingRows = true;
        }
        break;




      case 'HARDWARE':


        if (modifieditems.length !== 0 && modifieditems !== null) {
          modifieditems.forEach((item, index) => {
            if (
              !isNullOrUndefined(item.Technology) && item.Technology !== '' &&
              !isNullOrUndefined(item.Volume) && item.Volume !== '' &&
              !isNullOrUndefined(item.Complexity) && item.Complexity !== '' &&
              !isNullOrUndefined(item.PricingUnit) && item.PricingUnit !== ''
            ) {
              PTCond = true;
            } else {
              PTCond = false;
              this.outputscreenNavigate = false;
              this.showNotificationMessage('To continue, please fill all the mandatory fields', 2, '', null, '', '', 3000);
            }
          });
        }
        else {
          this.existingRows = true;
        }
        break;
      // Your code continues...

    }


    return PTCond;
    //    }






  }

  saveResoPlan(modifieditems: ServicetypeInfo[], ActionFlag: string): void {
    let odata_: any = {
      DealHeaderId: this.DealHeaderId,
      // DealLIneitemId: this.DealItemId,
      // ServiceType: this.ServiceCode

    }
    //super.getData(true);
    this.getservicedetails(odata_);
    // let modifieditems = this._Data.filter(x => x.IsModified === 1);


  }


  saveClickData(modifieditems: ServicetypeInfo[], ActionFlag: string): void {
    super.saveData(modifieditems, ActionFlag);
    if (!this.offShoreFlagSaveClick) {
      this.modifiedItem.IsModified = 0;
      this._appSharedService.IsRowModified = false;
      this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
      this.showNotificationMessage('Data Saved Successfully.', 1, '', null, '', '', 1000);
    }


    if (!isNullOrUndefined(this._Data_Copy)) {
      for (let i = 1; i < this._Data.length; i++) {
        if (isNullOrUndefined(this._Data[i].SLA)) {
          this._Data[i].SLADesc = this._Data_Copy[i].SLADesc;
          this._Data[i].SLA = this._Data_Copy[i].SLA;
        }
        if (isNullOrUndefined(this._Data[i].DeliveryModel)) {
          this._Data[i].DeliveryModelDesc = this._Data_Copy[i].DeliveryModelDesc;
          this._Data[i].DeliveryModel = this._Data_Copy[i].DeliveryModel;
        }
        if (isNullOrUndefined(this._Data[i].ServiceWindow)) {
          this._Data[i].ServiceWindow = this._Data_Copy[i].ServiceWindow;
          this._Data[i].ServiceWindowDesc = this._Data_Copy[i].ServiceWindowDesc;
        }
        if (isNullOrUndefined(this._Data[i].OffshoreCountry)) {
          this._Data[i].OffshoreCountry = this._Data_Copy[i].OffshoreCountry;
          this._Data[i].OffshoreCountryDesc = this._Data_Copy[i].OffshoreCountryDesc;
        }
        if (isNullOrUndefined(this._Data[i].OnsiteCountry)) {
          this._Data[i].OnsiteCountry = this._Data_Copy[i].OnsiteCountry;
          this._Data[i].OnsiteCountryDesc = this._Data_Copy[i].OnsiteCountryDesc;
        }

      }








      console.log(this._Data);
    }
  }


  deleteResoRows(): void {
    let deleteditems = this._Data.filter(x => x['IsSelected'] === true);

    if (deleteditems.length > 0) {
      this.showNotificationMessage('Are you sure you want to delete?', 2, 'DELETE', null, 'Yes', 'No', 10000);
    }
    else {
      this.showNotificationMessage('Select atleast one row to delete.', 2, '', null, '', '', 2500);
    }
  }

  copyrow(): void {
    this.copylist = [];
    let items = this._Data.filter(x => x['IsSelected'] === true);
    console.log(items, "items")

    if (items.length > 0) {
      (items).forEach((e: any) => {
        if (e.TransactionId == 0 || e.TransactionId == '' || e.TransactionId == null) {
          this.showNotificationMessage('Please save to continue', 2, '', null, '', '', 1500);
        }
        else {
          this.copylist.push(e);

        }
      })
      this.copyrowevent(this.copylist);

    }
    else {
      this.showNotificationMessage('Select atleast one row to copy.', 2, '', null, '', '', 2500);
    }


  }

  copyrowevent(data: any) {
    let subscribe$: Subscription = this._servicetypsService.copyrow(data).subscribe(result => {
      console.log(result);
      if (!isNullOrUndefined(result) && result.Status == 200) {
        this.modifiedItem.IsModified = 0;
        this._appSharedService.IsRowModified = false;
        this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
        //this.getDealDetails(this.selectedTab);
        //this.getGridData(odata);
        super.getData(true);
        // this.Flagoutput = 'NO';
        // let odata_report: any = {
        //   DealHeaderId: this.DealHeaderId,
        //   RoleId: 0,
        //   UserId: this._appSharedService.UserRoleList[0].UserId,
        //   DealLineItemId: this.DealItemId,
        //   ServiceType: this.ServiceCode

        // }
        // this.GetServicesTCVReport(odata_report);
        this.showNotificationMessage('Row(s) copied successfully.', 1, '', null, '', '', 1500);
      }

    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }


  deleteEvent(): void {
    this.DeleteDataList = [];
    let deleteditems = this._Data.filter(x => x['IsSelected'] === true);
    let transactionId: any = [];
    deleteditems.forEach((element: any) => {
      transactionId.push(element.TransactionId);
    });
    console.log(transactionId);
    console.log("roles")
    console.log(this._appSharedService.UserRoleList[0].UserId);
    super.deleteRows();

    for (let i = 0; i < transactionId.length; i++) {
      if (transactionId[i] != 0) {
        let odata = {
          TransactionId: transactionId[i],
          UserId: this._appSharedService.UserRoleList[0].UserId
        }
        this.DeleteDataList.push(odata);
        console.log("Delete items")
        console.log(this.DeleteDataList)

      }
    }

    this.DeleteManagedServices(this.DeleteDataList);
    // let odata_report: any = {
    //   DealHeaderId: this.DealHeaderId,
    //   RoleId: 0,
    //   UserId: this._appSharedService.UserRoleList[0].UserId,
    //   DealLineItemId: this.DealItemId,
    //   ServiceType: this.ServiceCode

    // }
    // this.GetServicesTCVReport(odata_report);

  }


  btnClick(butt: any): void {
    console.log(JSON.stringify(butt) + "button name");
    switch (butt.Name.toUpperCase()) {

    }
  }



  onClick(event: any): void {
    let selitem = event.item;
    this.txId = selitem.TransactionId;
    console.log(selitem.OffshoreVolumeDesc, "***", event.fieldname);
    //this.selectedpopup=event.fieldname;


    switch (event.fieldname.toUpperCase()) {

      case 'PRODUCTIVITYINFLATIONDETAILS':
        console.log(selitem);
        this.productivitypopupflag = true;
        this.ftepopupflag = false;
        // if (selitem.Source && selitem.Tower && selitem.Subtower && selitem.MappedRole && selitem.Location && selitem.MappedYoe && selitem.UpliftDownSizePer && selitem.Hrsday && selitem.Nofdaysmonth) {
        if (selitem.TransactionId == 0 || selitem.TransactionId == undefined) {
          this.showNotificationMessage('Please Save to Continue', 2, '', null, '', '', 1500);
          return;
          // this.saveClick();
          //  this.offShoreFlagSaveClick = true;
        }

        if (selitem.IsModified == '1') {
          this._IsSaving = false;
          this.modifiedflag = false;
          this.offShoreFlagSaveClick = false;
          this.saveClick();
          this.getProductivityDetails(selitem);
          selitem.IsProductivityFlag = 1;
          this.modalInf =
          {
            header: '',
            size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
            width: 'auto',
            height: 'auto'
          };
          this.openModal('productivitypopup');
        }
        else {
          this.getProductivityDetails(selitem);
          selitem.IsProductivityFlag = 1;
          this.modalInf =
          {
            header: '',
            size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
            width: 'auto',
            height: 'auto'
          };
          this.openModal('productivitypopup');
        }


        break;


      case 'OFFSHOREVOLUME':

        let transid = selitem.TransactionId;
        if (selitem.Offering && selitem.Tower && selitem.Technology && selitem.Complexity && selitem.PricingUnit && selitem.SLA && selitem.DeliveryModel && selitem.ServiceWindow && selitem.OnsiteCountry && selitem.OffshoreCountry) {
          if (selitem.TransactionId == 0 || selitem.TransactionId == undefined) {
            this.showNotificationMessage('Please Save to Continue', 2, '', null, '', '', 1500);
            return;
            // this.saveClick();
            //  this.offShoreFlagSaveClick = true;
          }

          if (selitem.IsModified == '1') {
            this._IsSaving = false;
            this.modifiedflag = false;
            this.offShoreFlagSaveClick = false;
            this.saveClick();



            this.getVolumeOffshoreDetails(selitem);
            selitem.IsOffshoreVolumeFlag = 1;
            this.year1offShoreFlag = true;
            this.modalInf =
            {
              header: 'Offshore % & Volume (Monthly)',
              size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
              width: 'auto',
              height: 'auto'
            };

            this.openModal('offshorepopup');

          }
          else {
            this.getVolumeOffshoreDetails(selitem);
            selitem.IsOffshoreVolumeFlag = 1;
            this.year1offShoreFlag = true;
            this.modalInf =
            {
              header: 'Offshore % & Volume (Monthly)',
              size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
              width: 'auto',
              height: 'auto'
            };

            this.openModal('offshorepopup');
          }

        }

        else {
          this.showNotificationMessage('Please Select Tower,Offerings,Technology,Complexity... and other fields to Continue', 2, '', null, '', '', 4000);
          return;
        }


        break;

      case 'FTECOUNT':
        this.ftepopupflag = true;
        this.productivitypopupflag = false;
        // if (selitem.Source && selitem.Tower && selitem.Subtower && selitem.MappedRole && selitem.Location && selitem.MappedYoe && selitem.UpliftDownSizePer && selitem.Hrsday && selitem.Nofdaysmonth) {
        if (selitem.TransactionId == 0 || selitem.TransactionId == undefined) {
          this.showNotificationMessage('Please Save to Continue', 2, '', null, '', '', 1500);
          return;
          // this.saveClick();
          //  this.offShoreFlagSaveClick = true;
        }

        if (selitem.IsModified == '1') {
          this._IsSaving = false;
          this.modifiedflag = false;
          this.offShoreFlagSaveClick = false;
          this.saveClick();
          this.getFTEcountDetails(selitem);
          selitem.IsFTEcountFlag = 1;
          this.modalInf =
          {
            header: '',
            size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
            width: 'auto',
            height: 'auto'
          };
          this.openModal('ftecount');
        }
        else {
          this.getFTEcountDetails(selitem);
          selitem.IsFTEcountFlag = 1;
          this.modalInf =
          {
            header: '',
            size: 'modal-xl modal-dialog-centered modal-dialog-scrollable',
            width: 'auto',
            height: 'auto'
          };
          this.openModal('ftecount');
        }

        break;



    }
  }

  offShoreModalInitiateData(selitem: any) {
    this.OffshoreManagedServiceData = [];
    selitem.IsOffshoreVolumeFlag = 1;

    this.OffShoreProp.forEach((element: any) => {
      this.productivityObj = new OffshorePerInterface();
      this.productivityObj.OffshoreVolumeDesc = element.value;
      this.productivityObj.Year1 = this.productivityObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year1OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year1Volume : null;

      this.OffshoreObj.Year2 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year2OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year2Volume : null;

      this.OffshoreObj.Year3 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year3OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year3Volume : null;

      this.OffshoreObj.Year4 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year4OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year4Volume : null;

      this.OffshoreObj.Year5 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year5OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year5Volume : null;

      this.OffshoreObj.Year6 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year6OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year6Volume : null;

      this.OffshoreObj.Year7 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year7OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year7Volume : null;

      this.OffshoreObj.Year8 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year8OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year8Volume : null;

      this.OffshoreObj.Year9 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year9OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year9Volume : null;

      this.OffshoreObj.Year10 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? selitem.Year10OffshorePer :
        this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? selitem.Year10Volume : null;

      this.OffshoreObj.IsModified = 0;


    });


  }



  filterDataByToolNames() {
    //const filterToolNames: string[] = ['TOL006', 'TOL007'];

    this.filterdata = this._Data.filter(item => item.ToolName == 'TOL006' || item.ToolName == 'TOL007');
    // this._Data.filter(x => x.IsModified === 1);
    //this._Data.filter(item => filterToolNames.includes(item.ToolName));
    if (this.filterdata.length > 0) {
      this.Toolsflag = true;
    }
    else {
      this.Toolsflag = false;
    }

  }

  onChange(event: any): void {
    let selitem: any = event.item;
    this.selectedpopup = event.fieldname;
    console.log(event.fieldname, selitem);
    let itemindex: any = event.item_index;
    console.log(itemindex);
    this.modifiedItem = selitem;

    // Emitting onchange event of resource plan to parent container
    selitem.IsModified = 1;
    //selitem.IsRowHighlight = selitem.IsModified;
    if (this.modifiedItem && this.modifiedItem.IsModified == 1) {
      this._appSharedService.IsRowModified = true;
      this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
    }
    if (this.selectedTab == 'RateCard') {
      if (event.fieldname == 'MappedRole' ||
        event.fieldname == 'Subtower' ||
        event.fieldname == 'Source' ||
        event.fieldname == 'Tower' ||
        event.fieldname == 'Location' ||
        event.fieldname == 'MappedYoE' ||
        event.fieldname == 'CustomerRoleName' ||
        event.fieldname == 'CustomerYOE' ||
        event.fieldname == 'UpliftDownsize') {
        this.Format = true;
      }
    }
    if (this.selectedTab == 'Tools') {
      if (event.fieldname == 'ToolName') {


        // if (event.item.ToolName == 'TOL007' || event.item.ToolName == 'TOL006') {
        //   this.Toolsflag = true;
        //   //  super.getData(true);

        // }

     




      }

    }



    ////////////////////COPY ROW LOGIC/////////////////////
    if (this.selectedTab == 'ManagedServices') {
      if (event.fieldname == 'Complexity' ||
        event.fieldname == 'Offering' ||
        event.fieldname == 'Technology' ||
        event.fieldname == 'Tower' ||
        event.fieldname == 'PricingUnit'
      ) {
        if (!isNullOrUndefined(this._Data_Copy)) {
          for (let i = 1; i < this._Data.length; i++) {
            if (isNullOrUndefined(this._Data[i].SLA)) {
              this._Data[i].SLADesc = this._Data_Copy[i].SLADesc;
              this._Data[i].SLA = this._Data_Copy[i].SLA;
            }
            if (isNullOrUndefined(this._Data[i].DeliveryModel)) {
              this._Data[i].DeliveryModelDesc = this._Data_Copy[i].DeliveryModelDesc;
              this._Data[i].DeliveryModel = this._Data_Copy[i].DeliveryModel;
            }
            if (isNullOrUndefined(this._Data[i].ServiceWindow)) {
              this._Data[i].ServiceWindow = this._Data_Copy[i].ServiceWindow;
              this._Data[i].ServiceWindowDesc = this._Data_Copy[i].ServiceWindowDesc;
            }
            if (isNullOrUndefined(this._Data[i].OffshoreCountry)) {
              this._Data[i].OffshoreCountry = this._Data_Copy[i].OffshoreCountry;
              this._Data[i].OffshoreCountryDesc = this._Data_Copy[i].OffshoreCountryDesc;
            }
            if (isNullOrUndefined(this._Data[i].OnsiteCountry)) {
              this._Data[i].OnsiteCountry = this._Data_Copy[i].OnsiteCountry;
              this._Data[i].OnsiteCountryDesc = this._Data_Copy[i].OnsiteCountryDesc;
            }

          }





          // this._Data = this._Data_Copy.map(obj => ({

          //   "SLADesc": obj.SLADesc,
          //   "DeliveryModelDesc": obj.DeliveryModelDesc,
          //   "ServiceWindowDesc": obj.ServiceWindowDesc,
          //   "OffshoreCountryDesc": obj.OffshoreCountryDesc,
          //   "OnsiteCountryDesc": obj.OnsiteCountryDesc,
          //   "SLA": obj.SLA,
          //   "DeliveryModel": obj.DeliveryModel,
          //   "ServiceWindow": obj.ServiceWindow,
          //   "OffshoreCountry": obj.OffshoreCountry,
          //   "OnsiteCountry": obj.OnsiteCountry
          // }));



          console.log(this._Data);
        }


        //   if (event.fieldname == 'SLA') {
        //     this._Data[i].SLADesc = event.item.SLADesc;
        //     this._Data[i].SLA = event.item.SLA;
        //   }
        //   if (event.fieldname == 'DeliveryModel') {
        //     this._Data[i].DeliveryModelDesc = event.item.DeliveryModelDesc;
        //     this._Data[i].DeliveryModel = event.item.DeliveryModel;
        //   }
        //   if (event.fieldname == 'ServiceWindow') {
        //     this._Data[i].ServiceWindowDesc = event.item.ServiceWindowDesc;
        //     this._Data[i].ServiceWindow = event.item.ServiceWindow;
        //   }

        //   if (event.fieldname == 'OffshoreCountry') {
        //     this._Data[i].OffshoreCountryDesc = event.item.OffshoreCountryDesc;
        //     this._Data[i].OffshoreCountry = event.item.OffshoreCountry;
        //   }

        //   if (event.fieldname == 'OnsiteCountry') {
        //     this._Data[i].OnsiteCountryDesc = event.item.OnsiteCountryDesc;
        //     this._Data[i].OnsiteCountry = event.item.OnsiteCountry;
        //   }
        // }




      }
      //  else{
      if (isNullOrUndefined(this._Data[1].SLADesc)
        || isNullOrUndefined(this._Data[1].DeliveryModel)
        || isNullOrUndefined(this._Data[1].ServiceWindowDesc)
        || isNullOrUndefined(this._Data[1].OffshoreCountryDesc)
        || isNullOrUndefined(this._Data[1].OnsiteCountryDesc)) {
        for (let i = 1; i < this._Data.length; i++) {
          if (this._Data[0].SLA != null || this._Data[0].SLA != undefined) {
            this._Data[i].SLADesc = this._Data[0].SLADesc;
            this._Data[i].SLA = this._Data[0].SLA;
            // this._Data[i].IsModified = 1;
            //  this._Data_Copy = this._Data;


          }
          if (this._Data[0].DeliveryModel != null || this._Data[0].DeliveryModel != undefined) {
            this._Data[i].DeliveryModelDesc = this._Data[0].DeliveryModelDesc;
            this._Data[i].DeliveryModel = this._Data[0].DeliveryModel;
            //    this._Data_Copy = this._Data;
          }
          if (this._Data[0].ServiceWindow != null || this._Data[0].ServiceWindow != undefined) {
            this._Data[i].ServiceWindowDesc = this._Data[0].ServiceWindowDesc;
            this._Data[i].ServiceWindow = this._Data[0].ServiceWindow;
            //   this._Data_Copy = this._Data;
          }

          if (this._Data[0].OffshoreCountry != null || this._Data[0].OffshoreCountry != undefined) {
            this._Data[i].OffshoreCountryDesc = this._Data[0].OffshoreCountryDesc;
            this._Data[i].OffshoreCountry = this._Data[0].OffshoreCountry;
            //   this._Data_Copy = this._Data;
          }
          if (this._Data[0].OnsiteCountry != null || this._Data[0].OnsiteCountry != undefined) {

            this._Data[i].OnsiteCountryDesc = this._Data[0].OnsiteCountryDesc;
            this._Data[i].OnsiteCountry = this._Data[0].OnsiteCountry;
            //   this._Data_Copy = this._Data;

          }

        }





      }
      //}


      //  super.saveData(this._Data, 'Save');


      console.log("dgdgdfg");
      //  this._Data_Copy = this._Data;


      // const newArray = [];

      // Iterate through the array and extract the desired properties for each object
      // for (const obj of this._Data) {
      //   const newObj = {
      //     "SLADesc": obj.SLADesc,
      //     "DeliveryModelDesc": obj.DeliveryModelDesc,
      //     "ServiceWindowDesc": obj.ServiceWindowDesc,
      //     "OffshoreCountryDesc": obj.OffshoreCountryDesc,
      //     "OnsiteCountryDesc": obj.OnsiteCountryDesc,
      //   };
      //   this._Data_Copy.push(newObj);
      // }


      this._Data_Copy = this._Data.map(obj => ({
        "SLADesc": obj.SLADesc,
        "DeliveryModelDesc": obj.DeliveryModelDesc,
        "ServiceWindowDesc": obj.ServiceWindowDesc,
        "OffshoreCountryDesc": obj.OffshoreCountryDesc,
        "OnsiteCountryDesc": obj.OnsiteCountryDesc,
        "SLA": obj.SLA,
        "DeliveryModel": obj.DeliveryModel,
        "ServiceWindow": obj.ServiceWindow,
        "OffshoreCountry": obj.OffshoreCountry,
        "OnsiteCountry": obj.OnsiteCountry
      }));



      console.log(this._Data_Copy);

    }






    switch (event.fieldname.toUpperCase()) {

      case 'NOOFMONTHS':
        /*  let x = (selitem.NoofMonths).which || (selitem.NoofMonths).keycode;
         let result;
         if((x>=48 && x<=57)){
           result=true;
         }      
         else{
           result=false; 
         }*/
        let formatType = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z]/;
        let res = formatType.test(selitem.NoofMonths);
        console.log(res)

        if (res == true) {
          this.Format = false;
          //this.selectedInfoData.DealTerm = 0;
          this.showNotificationMessage('Special characters & alphabets are not allowed', 2, '', null, '', '', 2500);
        }
        else if (res == false) {
          this.Format = true;
        }

        if (selitem.NoofMonths > this.selectedDeal.DealTerm) {
          this.Format = false;
          this.showNotificationMessage('No of Months cannot be more than the run duration', 2, '', null, '', '', 3000);
        }
        else if (selitem.NoofMonths <= 0) {
          this.Format = false;
          this.showNotificationMessage('No of Months cannot be 0 or less than 0', 2, '', null, '', '', 3000);
        }
        break;

      case 'Y1':
        (this.FTEsavedData).map((e: any) => {
          if (e.Y2 == '0' && e.Y3 == '0' && e.Y4 == '0' && e.Y5 == '0' && e.Y6 == '0' && e.Y7 == '0' && e.Y8 == '0' &&
            e.Y9 == '0' && e.Y10 == '0') {
            (this.FTECountData).map((element: any) => {
              if (element.YearTrend == 'Y2' && element.IsEditable == '1') {
                e.Y2 = e.Y1;
              }
              if (element.YearTrend == 'Y3' && element.IsEditable == '1') {
                e.Y3 = e.Y1;
              }
              if (element.YearTrend == 'Y4' && element.IsEditable == '1') {
                e.Y4 = e.Y1;
              }
              if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
                e.Y5 = e.Y1;
              }
              if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                e.Y6 = e.Y1;
              }
              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Y7 = e.Y1;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Y8 = e.Y1;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Y9 = e.Y1;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Y10 = e.Y1;
              }

            });


          }
        })

        break;

      case 'YEAR2':
        (this.productivitysavedData).map((e: any) => {
          if (e.ProductivityDesc == 'Productivity%' || e.ProductivityDesc == 'Inflation%') {
            if (e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
              e.Year9 == '0' && e.Year10 == '0') {
              (this.productivityData).map((element: any) => {

                if (element.YearTrend == 'Y3' && element.IsEditable == '1') {
                  e.Year3 = e.Year2;
                }
                if (element.YearTrend == 'Y4' && element.IsEditable == '1') {
                  e.Year4 = e.Year2;
                }
                if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
                  e.Year5 = e.Year2;
                }
                if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                  e.Year6 = e.Year2;
                }
                if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                  e.Year7 = e.Year2;
                }
                if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                  e.Year8 = e.Year2;
                }
                if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                  e.Year9 = e.Year2;
                }
                if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                  e.Year10 = e.Year2;
                }

              });
            }
          }
        });



        (this.OffshoreManagedSavedData).map((e: any) => {


          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {
              // if (element.YearTrend == 'Y2' && element.IsEditable == '1') {
              //   e.Year2 = e.Year1;
              // }
              if (element.YearTrend == 'Y3' && element.IsEditable == '1') {
                e.Year3 = e.Year2;
              }
              if (element.YearTrend == 'Y4' && element.IsEditable == '1') {
                e.Year4 = e.Year2;
              }
              if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
                e.Year5 = e.Year2;
              }
              if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                e.Year6 = e.Year2;
              }
              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Year7 = e.Year2;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year2;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year2;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year2;
              }

            });

          }
        })
        break;

      case 'YEAR1':
        (this.OffshoreManagedSavedData).map((e: any) => {
          // if (e.OffshoreVolumeDesc == 'Offshore%') {
          //   if (e.Year2 == '100' && e.Year3 == '100' && e.Year4 == '100' && e.Year5 == '100' && e.Year6 == '100' && e.Year7 == '100' && e.Year8 == '100' &&
          //     e.Year9 == '100' && e.Year10 == '100') {
          //     (this.OffshoreManagedServiceData).map((element: any) => {
          //       if (element.YearTrend == 'Y2' && element.IsEditable == '1') {
          //         e.Year2 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y3' && element.IsEditable == '1') {
          //         e.Year3 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y4' && element.IsEditable == '1') {
          //         e.Year4 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
          //         e.Year5 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
          //         e.Year6 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
          //         e.Year7 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
          //         e.Year8 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
          //         e.Year9 = e.Year1;
          //       }
          //       if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
          //         e.Year10 = e.Year1;
          //       }

          //     });
          //     /*    e.Year2 = e.Year1;
          //        e.Year3 = e.Year1;
          //        e.Year4 = e.Year1;
          //        e.Year5 = e.Year1;
          //        e.Year6 = e.Year1;
          //        e.Year7 = e.Year1;
          //        e.Year8 = e.Year1;
          //        e.Year9 = e.Year1;
          //        e.Year10 = e.Year1; */
          //   }
          // }

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {
              if (element.YearTrend == 'Y2' && element.IsEditable == '1') {
                e.Year2 = e.Year1;
              }
              if (element.YearTrend == 'Y3' && element.IsEditable == '1') {
                e.Year3 = e.Year1;
              }
              if (element.YearTrend == 'Y4' && element.IsEditable == '1') {
                e.Year4 = e.Year1;
              }
              if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
                e.Year5 = e.Year1;
              }
              if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                e.Year6 = e.Year1;
              }
              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Year7 = e.Year1;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year1;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year1;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year1;
              }

            });
            /*    e.Year2 = e.Year1;
               e.Year3 = e.Year1;
               e.Year4 = e.Year1;
               e.Year5 = e.Year1;
               e.Year6 = e.Year1;
               e.Year7 = e.Year1;
               e.Year8 = e.Year1;
               e.Year9 = e.Year1;
               e.Year10 = e.Year1; */
            //  }
          }
        })

        break;

      case 'YEAR3':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {


              if (element.YearTrend == 'Y4' && element.IsEditable == '1') {
                e.Year4 = e.Year3;
              }
              if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
                e.Year5 = e.Year3;
              }
              if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                e.Year6 = e.Year3;
              }
              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Year7 = e.Year3;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year3;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year3;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year3;
              }

            });

          }
        })
        break;

      case 'YEAR4':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {

              if (element.YearTrend == 'Y5' && element.IsEditable == '1') {
                e.Year5 = e.Year4;
              }
              if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                e.Year6 = e.Year4;
              }
              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Year7 = e.Year4;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year4;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year4;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year4;
              }

            });

          }
        })
        break;


      case 'YEAR5':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {


              if (element.YearTrend == 'Y6' && element.IsEditable == '1') {
                e.Year6 = e.Year5;
              }
              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Year7 = e.Year5;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year5;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year5;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year5;
              }

            });

          }
        })
        break;

      case 'YEAR6':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {


              if (element.YearTrend == 'Y7' && element.IsEditable == '1') {
                e.Year7 = e.Year6;
              }
              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year6;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year6;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year6;
              }

            });

          }
        })
        break;


      case 'YEAR7':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {



              if (element.YearTrend == 'Y8' && element.IsEditable == '1') {
                e.Year8 = e.Year7;
              }
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year7;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year7;
              }

            });

          }
        })
        break;

      case 'YEAR8':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {
              if (element.YearTrend == 'Y9' && element.IsEditable == '1') {
                e.Year9 = e.Year8;
              }
              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year8;
              }

            });

          }
        })
        break;


      case 'YEAR9':
        (this.OffshoreManagedSavedData).map((e: any) => {

          if (e.OffshoreVolumeDesc == 'Volume' || e.OffshoreVolumeDesc === 'Offshore%') {
            // if (e.Year2 == '0' && e.Year3 == '0' && e.Year4 == '0' && e.Year5 == '0' && e.Year6 == '0' && e.Year7 == '0' && e.Year8 == '0' &&
            //   e.Year9 == '0' && e.Year10 == '0') {
            (this.OffshoreManagedServiceData).map((element: any) => {

              if (element.YearTrend == 'Y10' && element.IsEditable == '1') {
                e.Year10 = e.Year9;
              }

            });

          }
        })
        break;

      case 'Year1':
      case 'Year2':
      case 'Year3':
      case 'Year4':
      case 'Year5':
      case 'Year6':
      case 'Year7':
      case 'Year8':
      case 'Year9':
      case 'Year10':
        selitem.IsModified = 1;
        this.OffshoreManagedServiceData.forEach((element: any) => {
          // this.OffshoreObj = new ServicetypeInfo();
          if (element.OffshoreVolumeDesc === 'Offshore%') {
            // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;

            element.IsModified = 1;
            this.OffshoreObj.IsModified = 1;

          }
          if (element.OffshoreVolumeDesc === 'Volume') {
            // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
            element.IsModified = 1;

          }


        });
        break;

      case 'SUPPORTTEAM':
        console.log(selitem.SupportTeam);
        this.supportTeam = selitem.SupportTeam;
        break;

    }

    let eventdata = {
      fieldname: event.fieldname
    };

    this.servicetypsOnChange.emit(eventdata);

  }





  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
    if (this.outputscreenNavigate) {
      this.displayoutputNotifybox = true;
    }

  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;
    switch (event.action.toUpperCase()) {
      case 'SUBMIT':
        if (event.result.toUpperCase() === 'SUBMIT') {
          // 
        }
        break;
      case 'DELETE':
        if (event.result.toUpperCase() === 'YES') {
          let odata_: any = {
            DealHeaderId: this.DealHeaderId,
          }

       
          this.getservicedetails_delete(odata_);

         
        }
        break;
      case 'NEXTTAB':
        if (event.result.toUpperCase() === 'OK') {
          this.modifiedItem.IsModified = 0;
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);

          this.selectedTab = event.item.currenttab
          this.getDealDetails(this.selectedTab);
        }
        else if (event.result.toUpperCase() === 'CANCEL') {
          console.log(event.item.prevTab, "previous tab", event.item.currenttab, "current tab", this.selectedTab)
          this.selectedTab = event.item.prevTab
          //this.getDealDetails(this.selectedTab);
        }
        break;
      case 'DASHBOARD':
        if (event.result.toUpperCase() === 'OK') {
          this.modifiedItem.IsModified = 0;
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);

          this._router.navigate(['/app/home'])
        }
        break;

      case 'DEALPAGE':
        if (event.result.toUpperCase() === 'OK') {
          this.modifiedItem.IsModified = 0;
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);

          this._Data = [];
          this.FromNavigateFlag = true;
          this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { dealData: this.selectedDeal } });
          this._appSharedService.setDealHeaderId = this.DealHeaderId;
          this._appSharedService.setDealHeaderId$.next(this._appSharedService.setDealHeaderId);
        }
        break;
      case 'NEXTBTN':
        if (event.result.toUpperCase() === 'OK') {
          this.modifiedItem.IsModified = 0;
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);

          this.selectedTab = event.item.currenttab
          //this.getDealDetails(this.selectedTab);
          if (this.checkValidation(this.saveModifieditems)) {
            //////if tabs length > 1////
            if (this._Config.ServiceTypesTabs.length > 1) {
              let indexofTabs = this._Config.ServiceTypesTabs.findIndex((x: any) => x.Name == this.selectedTab);

              console.log(indexofTabs);

              let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified != 1);
              if (newRecords.length == this._Data.length) {
                this.outputscreenNavigate = false;
                this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '', 2500);


              }

              else {
                if (indexofTabs < this._Config.ServiceTypesTabs.length - 1) {
                  ++indexofTabs;
                  this.selectedTab = this._Config.ServiceTypesTabs[indexofTabs].Name;
                  this._IsSaving = false;
                  this.saveClick();
                  this.offShoreFlagSaveClick = false;
                  this.getDealDetails(this.selectedTab);
                }
                else {
                  let modifieditems = this._Data.filter(x => x.IsModified === 1);
                  this.saveClick();
                  let offshoresaveddetails = this.OffshoreManagedSavedData.filter(x => x.IsModified == 1);
                  let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified != 1);
                  if (newRecords.length == this._Data.length) {
                    this.outputscreenNavigate = false;
                    this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '', 3000);
                  }
                  else if (this.selectedDeal.DealTerm && this.selectedTab == 'ManagedServices' && offshoresaveddetails.length == 0 && this.selectedDeal.DealStatus == 1) {
                    this.outputscreenNavigate = false;
                    this.showNotificationMessage('Please fill the offshore fields to continue..', 2, '', null, '', '', 3000);
                  }

                  else if (this.checkValidation(modifieditems)) {

                    if (this.selectedDeal.DealStatus == 1) {
                      // this.outputscreenNavigate = true;
                      this.modalInf =
                      {
                        header: '',

                      };

                      this.getoutputProgressStatus();
                    }
                    else {

                      this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                    }
                  }

                  else { }


                }
              }


            }
            //////if tabs length ==1////
            else {
              let recordsInprogress = this._Data.filter(x => x.TransactionId != 0);
              let modifieditems = this._Data.filter(x => x.IsModified === 1);
              this.saveClick();
              let offshoresaveddetails = this.OffshoreManagedSavedData.filter(x => x.IsModified == 1);
              let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified != 1);
              let modifiedRecords = this._Data.filter(x => x.IsModified == 1)
              ///////if single record saved but offshore missing////
              if (modifiedRecords.length > 0) {
                if (this.selectedDeal.DealTerm && this.selectedTab == 'ManagedServices' && offshoresaveddetails.length == 0 && this.selectedDeal.DealStatus == 1) {
                  this.outputscreenNavigate = false;
                  this.showNotificationMessage('Please fill the  required fields and offshore fields to continue..', 2, '', null, '', '', 3000);
                }
              }

              if (modifiedRecords.length == 0) {


                /////not a single record////
                if (newRecords.length == this._Data.length) {
                  this.outputscreenNavigate = false;
                  this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '', 3000);

                }



                else {
                  ////in progress deal////
                  if (recordsInprogress.length != 0 && this.selectedDeal.DealStatus == 1 && this.selectedTab == 'ManagedServices') {
                    if (this.selectedDeal.DealStatus == 1) {
                      //  this.outputscreenNavigate = true;
                      this.modalInf =
                      {
                        header: '',

                      };

                      this.getoutputProgressStatus();


                    }
                    else {
                      this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                    }



                  }

                  else {
                    if (offshoresaveddetails.length == 0 && this.selectedDeal.DealStatus == 1 && this.selectedTab == 'ManagedServices') {
                      this.outputscreenNavigate = false;
                      this.showNotificationMessage('Please fill the required fields and offshore fields to continue..', 2, '', null, '', '', 3000);
                    }
                    else if (this.checkValidation(modifieditems)) {
                      if (this.selectedDeal.DealStatus == 1) {
                        // this.outputscreenNavigate = true;
                        this.modalInf =
                        {
                          header: '',

                        };

                        this.getoutputProgressStatus();

                        // this.openModal('outputscreenpopup');
                      }
                      else {
                        this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                      }
                    }

                    else {
                      // this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);

                    }
                  }
                  //  console.log(this.OffshoreObj)



                }
              }
            }
          }
        }
        else if (event.result.toUpperCase() === 'CANCEL') {
          console.log(event.item.prevTab, "previous tab", event.item.currenttab, "current tab", this.selectedTab)
          this.selectedTab = event.item.prevTab
          //this.getDealDetails(this.selectedTab);
        }
        break;

      case 'BACKBTN':
        if (event.result.toUpperCase() === 'OK') {
          this.modifiedItem.IsModified = 0;
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);

          this.selectedTab = event.item.currenttab
          //this.getDealDetails(this.selectedTab);
          if (this._Config.ServiceTypesTabs.length >= 1) {
            let indexofTabs = this._Config.ServiceTypesTabs.findIndex((x: any) => x.Name == this.selectedTab);

            console.log(indexofTabs);

            // let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified!= 1);
            // if (newRecords.length == this._Data.length) {
            //   this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '');
            // }

            // else {
            if (indexofTabs == 0) {

              this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { dealData: this.selectedDeal } });
              this._appSharedService.setDealHeaderId = this.DealHeaderId;
              this._appSharedService.setDealHeaderId$.next(this._appSharedService.setDealHeaderId);
              //  this.offShoreFlagSaveClick = false;
              //  this.saveClick();

              // this.getDealDetails(this.selectedTab);

            }
            else {
              if (indexofTabs > 0) {
                this.offShoreFlagSaveClick = false;
                --indexofTabs;
                this.selectedTab = this._Config.ServiceTypesTabs[indexofTabs].Name;
                this.getDealDetails(this.selectedTab);
              }
            }
          }
        }
        else if (event.result.toUpperCase() === 'CANCEL') {
          console.log(event.item.prevTab, "previous tab", event.item.currenttab, "current tab", this.selectedTab)
          this.selectedTab = event.item.prevTab
          //this.getDealDetails(this.selectedTab);
        }
        break;
      case 'REFRESH':
        if (event.result.toUpperCase() === 'OK') {
          this.modifiedItem.IsModified = 0;
          this._appSharedService.IsRowModified = false;
          this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
          this.getDealDetails(event.item);
        }
        break;
    }
    if (canClose) {
      this.closeNotifybox();
    }
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;

    if (this.outputscreenNavigate) {
      this.displayoutputNotifybox = false;
    }

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    if (this.productivitypopupflag == true) {
      this.SendProductivityModalData();
      //this.SendProductivityData();

    }

    if (this.ftepopupflag == true) {
      this.SendFTEModalData();

    }

    if (this.selectedTab.toUpperCase() == 'MANAGEDSERVICES') {
      this.SendOffshoreModalData();
    }


    this.modalService.close(id);

  }


  SendOffshoreModalData() {
    // if (this.selectedDeal.DealTerm) {
    //   this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', ''); return;
    //   return;
    // }
    let offshoreVolumeitem = this._Data.filter(x => x.IsOffshoreVolumeFlag === 1)

    // this.OffshoreManagedServiceALLData = [];
    //selitem.IsOffshoreVolumeFlag = 0;
    if (!isNullOrUndefined(offshoreVolumeitem) && offshoreVolumeitem.length > 0) {
      this.OffshoreManagedServiceData.forEach((element: any) => {
        // this.OffshoreObj = new ServicetypeInfo();
        if (element.OffshoreVolumeDesc === 'Offshore%') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          offshoreVolumeitem[0].Year1OffshorePer = element.Year1;
          offshoreVolumeitem[0].Year2OffshorePer = element.Year2;
          offshoreVolumeitem[0].Year3OffshorePer = element.Year3;
          offshoreVolumeitem[0].Year4OffshorePer = element.Year4;
          offshoreVolumeitem[0].Year5OffshorePer = element.Year5;
          offshoreVolumeitem[0].Year6OffshorePer = element.Year6;
          offshoreVolumeitem[0].Year7OffshorePer = element.Year7;
          offshoreVolumeitem[0].Year8OffshorePer = element.Year8;
          offshoreVolumeitem[0].Year9OffshorePer = element.Year9;
          offshoreVolumeitem[0].Year10OffshorePer = element.Year10;

        }
        if (element.OffshoreVolumeDesc === 'Volume') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          offshoreVolumeitem[0].Year1Volume = element.Year1;
          offshoreVolumeitem[0].Year2Volume = element.Year2;
          offshoreVolumeitem[0].Year3Volume = element.Year3;
          offshoreVolumeitem[0].Year4Volume = element.Year4;
          offshoreVolumeitem[0].Year5Volume = element.Year5;
          offshoreVolumeitem[0].Year6Volume = element.Year6;
          offshoreVolumeitem[0].Year7Volume = element.Year7;
          offshoreVolumeitem[0].Year8Volume = element.Year8;
          offshoreVolumeitem[0].Year9Volume = element.Year9;
          offshoreVolumeitem[0].Year10Volume = element.Year10;

        }

        offshoreVolumeitem[0].IsModified = 1;
      });

      offshoreVolumeitem[0].IsOffshoreVolumeFlag = 0;
    }
  }


  SendFTEModalData() {
    let ftecountitem = this._Data.filter(x => x.IsFTEcountFlag === 1)
    if (!isNullOrUndefined(ftecountitem) && ftecountitem.length > 0) {
      this.FTECountData.forEach((element: any) => {
        ftecountitem[0].FTECountY1 = element.Y1;
        ftecountitem[0].FTECountY2 = element.Y2;
        ftecountitem[0].FTECountY3 = element.Y3;
        ftecountitem[0].FTECountY4 = element.Y4;
        ftecountitem[0].FTECountY5 = element.Y5;
        ftecountitem[0].FTECountY6 = element.Y6;
        ftecountitem[0].FTECountY7 = element.Y7;
        ftecountitem[0].FTECountY8 = element.Y8;
        ftecountitem[0].FTECountY9 = element.Y9;
        ftecountitem[0].FTECountY10 = element.Y10;

        ftecountitem[0].IsModified = 1;

      });
      ftecountitem[0].IsProductivityFlag = 0;
    }

  }

  SendProductivityModalData() {
    let productivityitem = this._Data.filter(x => x.IsProductivityFlag === 1)

    // this.OffshoreManagedServiceALLData = [];
    //selitem.IsOffshoreVolumeFlag = 0;
    if (!isNullOrUndefined(productivityitem) && productivityitem.length > 0) {
      this.productivityData.forEach((element: any) => {
        // this.OffshoreObj = new ServicetypeInfo();
        if (element.ProductivityDesc === 'Inflation%') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;          
          productivityitem[0].Year2Inflation = element.Year2;
          productivityitem[0].Year3Inflation = element.Year3;
          productivityitem[0].Year4Inflation = element.Year4;
          productivityitem[0].Year5Inflation = element.Year5;
          productivityitem[0].Year6Inflation = element.Year6;
          productivityitem[0].Year7Inflation = element.Year7;
          productivityitem[0].Year8Inflation = element.Year8;
          productivityitem[0].Year9Inflation = element.Year9;
          productivityitem[0].Year10Inflation = element.Year10;

        }
        if (element.OffshoreVolumeDesc === 'Productivity%') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          productivityitem[0].Year2Productivity = element.Year2;
          productivityitem[0].Year3Productivity = element.Year3;
          productivityitem[0].Year4Productivity = element.Year4;
          productivityitem[0].Year5Productivity = element.Year5;
          productivityitem[0].Year6Productivity = element.Year6;
          productivityitem[0].Year7Productivity = element.Year7;
          productivityitem[0].Year8Productivity = element.Year8;
          productivityitem[0].Year9Productivity = element.Year9;
          productivityitem[0].Year10Productivity = element.Year10;

        }

        productivityitem[0].IsModified = 1;
      });

      productivityitem[0].IsProductivityFlag = 0;
    }

  }

  SendFTEData() {
    let ftecountitem = this.FTEsavedData.filter(x => x.IsModified === 1)
    if (!isNullOrUndefined(ftecountitem) && ftecountitem.length > 0) {
      this.FTEsavedData.forEach((element: any) => {

        this.FTECountData[0].FTECount = element.Y1;
        this.FTECountData[0].DealHeaderId = this.DealHeaderId;
        this.FTECountData[0].DealLineitemId = this.DealItemId;
        this.FTECountData[0].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[1].FTECount = element.Y2;
        this.FTECountData[1].DealHeaderId = this.DealHeaderId;
        this.FTECountData[1].DealLineitemId = this.DealItemId;
        this.FTECountData[1].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;


        this.FTECountData[2].FTECount = element.Y3;
        this.FTECountData[2].DealHeaderId = this.DealHeaderId;
        this.FTECountData[2].DealLineitemId = this.DealItemId;
        this.FTECountData[2].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[3].FTECount = element.Y4;
        this.FTECountData[3].DealHeaderId = this.DealHeaderId;
        this.FTECountData[3].DealLineitemId = this.DealItemId;
        this.FTECountData[3].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[4].FTECount = element.Y5;
        this.FTECountData[4].DealHeaderId = this.DealHeaderId;
        this.FTECountData[4].DealLineitemId = this.DealItemId;
        this.FTECountData[4].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[5].FTECount = element.Y6;
        this.FTECountData[5].DealHeaderId = this.DealHeaderId;
        this.FTECountData[5].DealLineitemId = this.DealItemId;
        this.FTECountData[5].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[6].FTECount = element.Y7;
        this.FTECountData[6].DealHeaderId = this.DealHeaderId;
        this.FTECountData[6].DealLineitemId = this.DealItemId;
        this.FTECountData[6].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[7].FTECount = element.Y8;
        this.FTECountData[7].DealHeaderId = this.DealHeaderId;
        this.FTECountData[7].DealLineitemId = this.DealItemId;
        this.FTECountData[7].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[8].FTECount = element.Y9;
        this.FTECountData[8].DealHeaderId = this.DealHeaderId;
        this.FTECountData[8].DealLineitemId = this.DealItemId;
        this.FTECountData[8].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;

        this.FTECountData[9].FTECount = element.Y10;
        this.FTECountData[9].DealHeaderId = this.DealHeaderId;
        this.FTECountData[9].DealLineitemId = this.DealItemId;
        this.FTECountData[9].ServiceTypeTransactionId = ftecountitem[0].ServiceTypeTransactionId;



      });
      ftecountitem[0].IsFTEcountFlag = 0;
    }

    console.log(this.FTEsavedData)

    if (this.FTEsavedData.filter(x => x.IsModified === 1).length == 0) {
      console.log(this.FTEsavedData);

      (this.FTEsavedData).map((e: any) => {
        if (e.Y1 > 0 || e.Y2 > 0 || e.Y3 > 0 || e.Y4 > 0 || e.Y5 > 0 || e.Y6 > 0 || e.Y7 > 0 ||
          e.Y8 > 0 || e.Y9 > 0 || e.Y10 > 0) {
          return;
        }
        else {
          this.showNotificationMessage('Please enter atleast 1 FTE count to continue', 2, '', null, '', '', 3000);
          return;
        }
      })

      return;
    }
    if ((this.FTEsavedData.filter(x => x.IsModified === 1)).length > 0) {
      this.SaveFTECountDetails(this.FTECountData);
      console.log(this.FTECountData)
    }

  }

  SendProductivityData() {
    let productivityitem = this.productivitysavedData.filter(x => x.IsModified === 1)

    // this.OffshoreManagedServiceALLData = [];
    //selitem.IsOffshoreVolumeFlag = 0;
    if (!isNullOrUndefined(productivityitem) && productivityitem.length > 0) {
      this.productivitysavedData.forEach((element: any) => {
        // this.OffshoreObj = new ServicetypeInfo();
        if (element.ProductivityDesc === 'Inflation%') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          this.productivityData[0].Inflation = element.Year2;
          this.productivityData[0].DealHeaderId = this.DealHeaderId;
          this.productivityData[0].DealLineitemId = this.DealItemId;
          this.productivityData[0].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[1].Inflation = element.Year3;
          this.productivityData[1].DealHeaderId = this.DealHeaderId;
          this.productivityData[1].DealLineitemId = this.DealItemId;
          this.productivityData[1].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;


          this.productivityData[2].Inflation = element.Year4;
          this.productivityData[2].DealHeaderId = this.DealHeaderId;
          this.productivityData[2].DealLineitemId = this.DealItemId;
          this.productivityData[2].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[3].Inflation = element.Year5;
          this.productivityData[3].DealHeaderId = this.DealHeaderId;
          this.productivityData[3].DealLineitemId = this.DealItemId;
          this.productivityData[3].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[4].Inflation = element.Year6;
          this.productivityData[4].DealHeaderId = this.DealHeaderId;
          this.productivityData[4].DealLineitemId = this.DealItemId;
          this.productivityData[4].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[5].Inflation = element.Year7;
          this.productivityData[5].DealHeaderId = this.DealHeaderId;
          this.productivityData[5].DealLineitemId = this.DealItemId;
          this.productivityData[5].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[6].Inflation = element.Year8;
          this.productivityData[6].DealHeaderId = this.DealHeaderId;
          this.productivityData[6].DealLineitemId = this.DealItemId;
          this.productivityData[6].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[7].Inflation = element.Year9;
          this.productivityData[7].DealHeaderId = this.DealHeaderId;
          this.productivityData[7].DealLineitemId = this.DealItemId;
          this.productivityData[7].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[8].Inflation = element.Year10;
          this.productivityData[8].DealHeaderId = this.DealHeaderId;
          this.productivityData[8].DealLineitemId = this.DealItemId;
          this.productivityData[8].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;


        }
        if (element.ProductivityDesc === 'Productivity%') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          this.productivityData[0].Productivity = element.Year2;
          this.productivityData[0].DealHeaderId = this.DealHeaderId;
          this.productivityData[0].DealLineitemId = this.DealItemId;
          this.productivityData[0].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;


          this.productivityData[1].Productivity = element.Year3;
          this.productivityData[1].DealHeaderId = this.DealHeaderId;
          this.productivityData[1].DealLineitemId = this.DealItemId;
          this.productivityData[1].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[2].Productivity = element.Year4;
          this.productivityData[2].DealHeaderId = this.DealHeaderId;
          this.productivityData[2].DealLineitemId = this.DealItemId;
          this.productivityData[2].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[3].Productivity = element.Year5;
          this.productivityData[3].DealHeaderId = this.DealHeaderId;
          this.productivityData[3].DealLineitemId = this.DealItemId;
          this.productivityData[3].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[4].Productivity = element.Year6;
          this.productivityData[4].DealHeaderId = this.DealHeaderId;
          this.productivityData[4].DealLineitemId = this.DealItemId;
          this.productivityData[4].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[5].Productivity = element.Year7;
          this.productivityData[5].DealHeaderId = this.DealHeaderId;
          this.productivityData[5].DealLineitemId = this.DealItemId;
          this.productivityData[5].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[6].Productivity = element.Year8;
          this.productivityData[6].DealHeaderId = this.DealHeaderId;
          this.productivityData[6].DealLineitemId = this.DealItemId;
          this.productivityData[6].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[7].Productivity = element.Year9;
          this.productivityData[7].DealHeaderId = this.DealHeaderId;
          this.productivityData[7].DealLineitemId = this.DealItemId;
          this.productivityData[7].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;

          this.productivityData[8].Productivity = element.Year10;
          this.productivityData[8].DealHeaderId = this.DealHeaderId;
          this.productivityData[8].DealLineitemId = this.DealItemId;
          this.productivityData[8].ServiceTypeTransactionId = productivityitem[0].ServiceTypeTransactionId;


        }

        //offshoreVolumeitem[0].IsModified = 1;
      });

      productivityitem[0].IsProductivityFlag = 0;
    }


    /*  if (this.productivitysavedData.filter(x => x.IsModified === 1).length == 0) {
       this.showNotificationMessage('Please enter atleast 1 productivity & inflation details to continue..', 2, '', null, '', '');
       return;
     } */
    if (this.productivitysavedData.filter(x => x.IsModified === 1).length == 0) {
      console.log(this.productivitysavedData);
      /*   (this.productivitysavedData).map((e:any)=>{
          if(e.OffshoreVolumeDesc == 'Offshore%'){
            if(e.Year1 > 0 || e.Year2 > 0 || e.Year3 > 0 || e.Year4 > 0 || e.Year5 > 0 || e.Year6 > 0 || e.Year7 > 0|| 
              e.Year8 > 0 || e.Year9 > 0 || e.Year10 > 0 ){
                if(e.OffshoreVolumeDesc == 'Volume'){
                  if(e.Year1 > 0 || e.Year2 > 0 || e.Year3 > 0 || e.Year4 > 0 || e.Year5 > 0 || e.Year6 > 0 || e.Year7 > 0|| 
                    e.Year8 > 0 || e.Year9 > 0 || e.Year10 > 0 ){
                      return;
                    }
                }
               return;
              }
              else {
                this.outputscreenNavigate = false;
                this.showNotificationMessage('Please enter offshore % and volume details to continue', 2, '', null, '', '');
                return;
              }
          }
       
        }) */

      return;
    }
    if ((this.productivitysavedData.filter(x => x.IsModified === 1)).length > 0) {
      this.SaveProductivityDetails(this.productivityData);
      console.log(this.productivityData)
    }

    // this.SaveVolumeOffshoreDetails(this.OffshoreManagedServiceData);


  }
  dashboardClick() {
    if (this.modifiedItem && this.modifiedItem.IsModified == 1) {
      this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'DASHBOARD', null, 'Cancel', 'OK', 10000);
    }
    else {
      this._router.navigate(['/app/home'])
    }
  }

  actionButtonClick(data: any) {
    this.nextFlag = false;

    let valid: boolean = true;
    console.log(data);
    if (!isNullOrUndefined(data.Name) && data.Name !== '') {
      switch (data.Name.toUpperCase()) {
        case 'SUBMIT':
          this._router.navigate(['app/servicetyps']);
          break;
        case 'SAVE':
          //  this.SaveACDEdit();
          break;
        case 'NEXT':
          this.nextFlag = true;
          this.backFlag = false;
          console.log(this.saveModifieditems);

          let indexofTabs = this._Config.ServiceTypesTabs.findIndex((x: any) => x.Name == this.selectedTab);
          let tabName = { prevTab: this.selectedTab, currenttab: this._Config.ServiceTypesTabs[indexofTabs].Name }
          if (this.modifiedItem && this.modifiedItem.IsModified == 1) {
            //this._appSharedService.IsRowModified = true;
            this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'NEXTBTN', tabName, 'Cancel', 'OK', 10000);
          }
          else {

            //  if (this.saveModifieditems != null && this.saveModifieditems.length > 0) {
            if (this.checkValidation(this.saveModifieditems)) {
              //////if tabs length > 1////
              if (this._Config.ServiceTypesTabs.length > 1) {
                let indexofTabs = this._Config.ServiceTypesTabs.findIndex((x: any) => x.Name == this.selectedTab);

                console.log(indexofTabs);

                let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified != 1);
                if (newRecords.length == this._Data.length) {
                  this.outputscreenNavigate = false;
                  this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '', 3000);


                }

                else {
                  if (indexofTabs < this._Config.ServiceTypesTabs.length - 1) {

                    ++indexofTabs;
                    this.selectedTab = this._Config.ServiceTypesTabs[indexofTabs].Name;
                    this._IsSaving = false;
                    this.saveClick();
                    this.offShoreFlagSaveClick = false;
                    this.getDealDetails(this.selectedTab);
                  }
                  else {
                    let modifieditems = this._Data.filter(x => x.IsModified === 1);
                    this.saveClick();
                    let offshoresaveddetails = this.OffshoreManagedSavedData.filter(x => x.IsModified == 1);
                    let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified != 1);
                    if (newRecords.length == this._Data.length) {
                      this.outputscreenNavigate = false;
                      this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '', 3000);
                    }
                    else if (this.selectedDeal.DealTerm && this.selectedTab == 'ManagedServices' && offshoresaveddetails.length == 0 && this.selectedDeal.DealStatus == 1) {
                      this.outputscreenNavigate = false;
                      this.showNotificationMessage('Please fill the offshore fields to continue..', 2, '', null, '', '', 3000);
                    }

                    // if (this.selectedDeal.DealTerm && this.OffshoreManagedSavedData.filter(x => x.IsModified === 1).length == 0) {
                    //   this.outputscreenNavigate = false;
                    // }
                    else if (this.checkValidation(modifieditems)) {



                      // this.outputscreenNavigate = true;
                      // this.modalInf =
                      // {
                      //   header: '',

                      // };
                      // this.openModal('outputscreenpopup');

                      if (this.selectedDeal.DealStatus == 1) {
                        // this.outputscreenNavigate = true;
                        // this.modalInf =
                        // {
                        //   header: '',

                        // };
                        // this.openModal('outputscreenpopup');
                        this.modalInf =
                        {
                          header: '',

                        };

                        this.getoutputProgressStatus();
                      }
                      else {

                        this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                      }
                    }

                    else {
                      //  this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                    }

                    //  this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                  }
                }

                // if (this.selectedDeal.DealTerm && this.OffshoreManagedSavedData.filter(x => x.IsModified === 1).length == 0) {
                //   this.outputscreenNavigate = false;
                //   this.showNotificationMessage('Please fill the offshore fields to continue..', 2, '', null, '', '');
                //   return;
                // }






              }
              //////if tabs length ==1////
              else {
                let recordsInprogress = this._Data.filter(x => x.TransactionId != 0);
                let modifieditems = this._Data.filter(x => x.IsModified === 1);
                this.saveClick();
                let offshoresaveddetails = this.OffshoreManagedSavedData.filter(x => x.IsModified == 1);
                let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified != 1);
                let modifiedRecords = this._Data.filter(x => x.IsModified == 1)
                ///////if single record saved but offshore missing////
                if (modifiedRecords.length > 0) {
                  if (this.selectedDeal.DealTerm && this.selectedTab == 'ManagedServices' && offshoresaveddetails.length == 0 && this.selectedDeal.DealStatus == 1) {
                    this.outputscreenNavigate = false;
                    this.showNotificationMessage('Please fill the  required fields and offshore fields to continue..', 2, '', null, '', '', 3000);
                  }
                }

                if (modifiedRecords.length == 0) {


                  /////not a single record////
                  if (newRecords.length == this._Data.length) {
                    this.outputscreenNavigate = false;
                    this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '', 3000);

                  }



                  else {
                    ////in progress deal////
                    if (recordsInprogress.length != 0 && this.selectedDeal.DealStatus == 1 && this.selectedTab == 'ManagedServices') {
                      if (this.selectedDeal.DealStatus == 1) {
                        // this.outputscreenNavigate = true;
                        // this.modalInf =
                        // {
                        //   header: '',

                        // };
                        // this.openModal('outputscreenpopup');
                        this.modalInf =
                        {
                          header: '',

                        };


                        this.getoutputProgressStatus();
                      }
                      else {
                        this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                      }



                    }


                    ////completed deal////
                    else {
                      if (offshoresaveddetails.length == 0 && this.selectedDeal.DealStatus == 1 && this.selectedTab == 'ManagedServices') {
                        this.outputscreenNavigate = false;
                        this.showNotificationMessage('Please fill the required fields and offshore fields to continue..', 2, '', null, '', '', 3000);
                      }
                      else if (this.checkValidation(modifieditems)) {
                        if (this.selectedDeal.DealStatus == 1) {
                          this.outputscreenNavigate = true;
                          this.modalInf =
                          {
                            header: '',

                          };
                          this.openModal('outputscreenpopup');
                        }
                        else {
                          this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
                        }
                      }

                      else {
                        // this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);

                      }
                    }
                    //  console.log(this.OffshoreObj)



                  }
                }
              }
            }
          }
          //  }
          // else {
          //   let offshoresaveddetails = this.OffshoreManagedSavedData.filter(x => x.IsModified == 1);
          //   let nonmodifieditems = this._Data.filter(x => x.IsModified === 0 && x.TransactionId != 0);
          //   let modifieditems = this._Data.filter(x => x.IsModified === 1);
          //   let newRecords = this._Data.filter(x => x.TransactionId == 0);
          //   if (nonmodifieditems.length > 0) {
          //     this.existingRows = true;
          //   }

          //   if (modifieditems.length > 0) {
          //     this.saveClick();
          //   }
          //   if (this.existingRows && modifieditems.length == 0) {
          //     // if (this.selectedDeal.DealTerm) {
          //     //   this.outputscreenNavigate = false;
          //     // }
          //     // else {

          //     if (this.selectedDeal.DealStatus == 1) {
          //       this.outputscreenNavigate = true;
          //       this.modalInf =
          //       {
          //         header: '',

          //       };
          //       this.openModal('outputscreenpopup');
          //     }
          //     else {
          //       this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
          //     }
          //     // this.outputscreenNavigate = true;
          //     // this.modalInf =
          //     // {
          //     //   header: '',

          //     // };
          //     // this.openModal('outputscreenpopup');
          //     //  }


          //     // this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
          //   }

          // }

          //  if (selitem.Offering && selitem.Tower && selitem.Technology && selitem.Complexity && selitem.PricingUnit && selitem.SLA && selitem.DeliveryModel && selitem.ServiceWindow && selitem.OnsiteCountry && selitem.OffshoreCountry) {

          break;
        case 'DONE':
          switch (this.selectedTab.toUpperCase()) {
            case 'MANAGEDSERVICES':
              // if (this.OffshoreManagedServiceData[0].IsSupportTypeOnsite == 1) {
              //   (this.OffshoreManagedSavedData).map((e: any) => {
              //     if (e.OffshoreVolumeDesc == 'Offshore%') {
              //       if (e.Year1 > 0 || e.Year2 > 0 || e.Year3 > 0 || e.Year4 > 0 || e.Year5 > 0 || e.Year6 > 0 || e.Year7 > 0 ||
              //         e.Year8 > 0 || e.Year9 > 0 || e.Year10 > 0) {

              //         this.showNotificationMessage('This is an onsite line item. Please change offshore % to 0 to generate rates.', 2, '', null, '', '', 3000);
              //       }
              //       else {
              //         this.SaveVolumeOffshoreDetails(this.OffshoreManagedServiceData);
              //       }
              //     }

              //   }

              //   )
              // }


              // else {
              this.saveOffshore = true;
              this.SendOffshoreModalData();
              this.SendOffshoreData();
              if (this.rangeValidation == true) {
                this.modifiedItem.IsModified = 0;
                this._appSharedService.IsRowModified = false;
                this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
                this.modalService.close('offshorepopup');
              }
              //  }

              break;
            case 'RATECARD':
              if (this.productivitypopupflag == true) {
                this.saveProductivity = true;
                this.SendProductivityModalData();
                this.SendProductivityData();
                this.modifiedItem.IsModified = 0;
                this._appSharedService.IsRowModified = false;
                this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
                this.modalService.close('productivitypopup');

              }
              if (this.ftepopupflag == true) {
                this.saveftecount = true;
                this.SendFTEModalData();
                this.SendFTEData();
                this.modifiedItem.IsModified = 0;
                this._appSharedService.IsRowModified = false;
                this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
                this.modalService.close('ftecount');
              }
              break;

          }


          break;
        case 'BACK':
          //  this.SaveACDEdit();
          this.backFlag = true;
          this.nextFlag = false;

          let indexofTab = this._Config.ServiceTypesTabs.findIndex((x: any) => x.Name == this.selectedTab);
          let tabs = { prevTab: this.selectedTab, currenttab: this._Config.ServiceTypesTabs[indexofTab].Name }

          if (this.modifiedItem && this.modifiedItem.IsModified == 1) {
            //this._appSharedService.IsRowModified = true;
            this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'BACKBTN', tabs, 'Cancel', 'OK', 10000);
          }
          else {
            if (this._Config.ServiceTypesTabs.length >= 1) {
              let indexofTabs = this._Config.ServiceTypesTabs.findIndex((x: any) => x.Name == this.selectedTab);

              console.log(indexofTabs);

              // let newRecords = this._Data.filter(x => x.TransactionId == 0 && x.IsModified!= 1);
              // if (newRecords.length == this._Data.length) {
              //   this.showNotificationMessage('Please fill the required fields to continue..', 2, '', null, '', '');
              // }

              // else {
              if (indexofTabs == 0) {

                this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { dealData: this.selectedDeal } });
                this._appSharedService.setDealHeaderId = this.DealHeaderId;
                this._appSharedService.setDealHeaderId$.next(this._appSharedService.setDealHeaderId);
                //  this.offShoreFlagSaveClick = false;
                //  this.saveClick();

                // this.getDealDetails(this.selectedTab);

              }
              else {
                if (indexofTabs > 0) {
                  this.offShoreFlagSaveClick = false;
                  --indexofTabs;
                  this.selectedTab = this._Config.ServiceTypesTabs[indexofTabs].Name;
                  this.getDealDetails(this.selectedTab);
                }
              }
            }
          }
          //  else {
          // this.saveClick();
          //   this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
          // this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
          //    }


          //  this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { data: this.selectedDeal } });
          // this.closeModal('offshorepopup')
          break;
        case 'OUTPUTSCREEN':
          let odata: any;
          odata = {
            DealHeaderId: Number(this.DealHeaderId),
            UserId: this.supportTeam
          }
          let x = document.getElementById('flexCheckDefault') as HTMLInputElement;
          console.log(x.checked);
          if (odata.UserId == undefined || odata.UserId == null || x.checked == false) {
            this.showNotificationMessage('Please fill the required field to continue..', 2, '', null, '', '', 3000);
            // return;
          }
          else {
            this.openOutputScreenDetails(odata);
          }

          break;

      }
    }
  }

  onTabChange(selTab: TabItem) {
    console.log(this.selectedTab, selTab.Name)
    //this.selectedTab = selTab.Name;
    //this.getDealDetails(this.selectedTab);
    let tabName = { prevTab: this.selectedTab, currenttab: selTab.Name }
    if (this.modifiedItem && this.modifiedItem.IsModified == 1) {
      //this._appSharedService.IsRowModified = true;
      this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'NEXTTAB', tabName, 'Cancel', 'OK', 10000);
    }
    else {
      this.selectedTab = selTab.Name;

      this.Flagoutput = false;

      this.getDealDetails(this.selectedTab);


      let odata_: any = {
        DealHeaderId: this.DealHeaderId,
        RoleId: 0,
        UserId: this._appSharedService.UserRoleList[0].UserId,
        // DealLineItemId: this.DealItemId,
        // ServiceType: this.ServiceCode

      }
    //  this.GetSplunkModuleToolData(odata_);



      // super.getData(true);
      // console.log(this._Data)
      // if (this.selectedTab == 'Tool') {
      //   this.filterDataByToolNames();
      // }
    }
  }
  setSelectedTab(event: any) {
    console.log(event)


    if (this.outputscreenNavigate) {

    }
    else {
      if (this.nextFlag || this.backFlag) {
        this.selectedTab = event;
        this.getDealDetails(this.selectedTab);
      }

      else {
        console.log(event)
        this.selectedTab = event.selectedTab
        this.getDealDetails(this.selectedTab);
      }

    }



  }

  getDealDetails(tab: any) {
    let odata = {
      ServiceType: tab,
      DealHeaderId: this.DealHeaderId
    };

    this._Data = [];
    this.onInitData = [];
    this._DataTotalCount = this._Data.length;
    console.log("DATA COUNT LENGTH before");
    let subscribe$: Subscription = this._servicetypsService.getDealData(odata).subscribe(async result => {
      console.log(result);
      if (!isNullOrUndefined(result) && result.Status == 200) {
        console.log("DATA COUNT LENGTH");
        this._DataTotalCount = this._Data.length;
        let x = result.Data[0];
        this.DealItemId = '';
        this.DealItemId = result.Data[0].UserDef1;
        this.ServiceCode = result.Data[0].Code;
        this.onInitData =
        {
          ServiceType: this.ServiceCode,
          DealHeaderId: this.DealHeaderId,
          DealLIneitemId: this.DealItemId
        };
        console.log(this.onInitData)


        let otmpltdata1: ComponentConfig = {

          ComponentCode: ComponentCodes.ServicetypsComponent, Component: 'Servicetyps', SubComponent: '',

        };
        this.initComponent(otmpltdata1, this.onInitData, null, this.CompTitle, 'TransactionId');
        // this.getreportsTemplate();
        //  super.getData(true);
        //  if (this.selectedTab == 'Tool') {
        //   this.filterDataByToolNames();
        // }
        //   let odata_report: any = {
        //     DealHeaderId: this.DealHeaderId,
        //     RoleId: 0,
        //     UserId: this._appSharedService.UserRoleList[0].UserId,
        //     DealLineItemId: this.DealItemId,
        //     ServiceType: this.ServiceCode

        //   }
        //  this.GetServicesTCVReport(odata_report);
        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  getFTEcountDetails(selecteditem: any) {
    this.FTECountData = [];
    let odata = {
      STTransactionId: selecteditem.TransactionId,
      DealHeaderId: selecteditem.DealHeaderId,
      DealLIneitemId: this.DealItemId
    };
    console.log(odata);


    let subscribe$: Subscription = this._servicetypsService.GetServiceTypeFTECountDetails(odata).subscribe(result => {
      console.log(result);


      if (!isNullOrUndefined(result) && result.Status == 200) {
        this.FTECountData = result.Data;


        this.FTEsavedData = [];


        this.FTEcountObj = new FTEInterface();
        /* if(this.selectedDeal.DealTerm >0 && this.selectedDeal.DealTerm <= 12){
           this.FTEcountObj.Y1 = this.FTECountData[0].FTECount
         }
         if(this.selectedDeal.DealTerm >12 && this.selectedDeal.DealTerm <= 24){
           this.FTEcountObj.Y1 = this.FTECountData[0].FTECount
           this.FTEcountObj.Y2 = this.FTECountData[1].FTECount
         } */
        this.FTEcountObj.Y1 = this.FTECountData[0].FTECount
        this.FTEcountObj.Y2 = this.FTECountData[1].FTECount
        this.FTEcountObj.Y3 = this.FTECountData[2].FTECount
        this.FTEcountObj.Y4 = this.FTECountData[3].FTECount
        this.FTEcountObj.Y5 = this.FTECountData[4].FTECount
        this.FTEcountObj.Y6 = this.FTECountData[5].FTECount
        this.FTEcountObj.Y7 = this.FTECountData[6].FTECount
        this.FTEcountObj.Y8 = this.FTECountData[7].FTECount
        this.FTEcountObj.Y9 = this.FTECountData[8].FTECount
        this.FTEcountObj.Y10 = this.FTECountData[9].FTECount


        this.FTEcountObj.ServiceTypeTransactionId = odata.STTransactionId;
        this.FTEcountObj.DealHeaderId = odata.DealHeaderId;
        this.FTEcountObj.DealLIneitemId = odata.DealLIneitemId;
        this.FTEcountObj.TotalMonths = this.FTECountData[0].TotalMonths;
        this.FTEcountObj.IsModified = 0;
        this.FTEsavedData.push(this.FTEcountObj);


      }


    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);


  }

  getProductivityDetails(selecteditem: any) {
    this.productivityData = [];
    let odata = {
      STTransactionId: selecteditem.TransactionId,
      DealHeaderId: selecteditem.DealHeaderId,
      DealLIneitemId: this.DealItemId
    };
    console.log(odata);


    let subscribe$: Subscription = this._servicetypsService.GetServiceTypeProductivityInflationDetails(odata).subscribe(result => {
      console.log(result);


      if (!isNullOrUndefined(result) && result.Status == 200) {
        this.productivityData = result.Data;


        this.productivitysavedData = [];

        this.ProductivityProp.forEach((element: any) => {
          this.productivityObj = new ProductivityInterface();
          this.productivityObj.ProductivityDesc = element.value;
          this.productivityObj.Year2 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[0].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[0].Productivity : null;
          this.productivityObj.Year3 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[1].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[1].Productivity : null;
          this.productivityObj.Year4 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[2].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[2].Productivity : null;
          this.productivityObj.Year5 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[3].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[3].Productivity : null;
          this.productivityObj.Year6 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[4].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[4].Productivity : null;
          this.productivityObj.Year7 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[5].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[5].Productivity : null;
          this.productivityObj.Year8 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[6].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[6].Productivity : null;
          this.productivityObj.Year9 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[7].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[7].Productivity : null;
          this.productivityObj.Year10 = this.productivityObj.ProductivityDesc === 'Inflation%' ? this.productivityData[8].Inflation :
            this.productivityObj.ProductivityDesc === 'Productivity%' ? this.productivityData[8].Productivity : null;



          this.productivityObj.ServiceTypeTransactionId = odata.STTransactionId;
          this.productivityObj.DealHeaderId = odata.DealHeaderId;
          this.productivityObj.DealLIneitemId = odata.DealLIneitemId;
          this.productivityObj.TotalMonths = this.productivityData[0].TotalMonths;
          this.productivityObj.IsModified = 0;
          this.productivitysavedData.push(this.productivityObj);

        });
      }


    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  getVolumeOffshoreDetails(selecteditem: any) {
    this.OffshoreManagedServiceData = [];
    let odata = {
      STTransactionId: selecteditem.TransactionId,
      DealHeaderId: selecteditem.DealHeaderId,
      DealLIneitemId: this.DealItemId
    };
    console.log(odata);


    let subscribe$: Subscription = this._servicetypsService.getServiceTypeVolumeDetails(odata).subscribe(result => {
      console.log(result);


      if (!isNullOrUndefined(result) && result.Status == 200) {
        this.OffshoreManagedServiceData = result.Data;


        this.OffshoreManagedSavedData = [];


        this.OffShoreProp.forEach((element: any) => {
          this.OffshoreObj = new OffshorePerInterface();
          this.OffshoreObj.OffshoreVolumeDesc = element.value;
          this.OffshoreObj.Year1 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[0].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[0].Volume : null;

          this.OffshoreObj.Year2 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[1].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[1].Volume : null;

          this.OffshoreObj.Year3 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[2].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[2].Volume : null;

          this.OffshoreObj.Year4 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[3].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[3].Volume : null;

          this.OffshoreObj.Year5 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[4].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[4].Volume : null;

          this.OffshoreObj.Year6 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[5].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[5].Volume : null;

          this.OffshoreObj.Year7 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[6].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[6].Volume : null;

          this.OffshoreObj.Year8 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[7].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[7].Volume : null;

          this.OffshoreObj.Year9 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[8].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[8].Volume : null;

          this.OffshoreObj.Year10 = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? this.OffshoreManagedServiceData[9].OffshorePer :
            this.OffshoreObj.OffshoreVolumeDesc === 'Volume' ? this.OffshoreManagedServiceData[9].Volume : null;

          this.OffshoreObj.ServiceTypeTransactionId = odata.STTransactionId;
          this.OffshoreObj.DealHeaderId = odata.DealHeaderId;
          this.OffshoreObj.DealLIneitemId = odata.DealLIneitemId;
          this.OffshoreObj.DealTerm = this.OffshoreManagedServiceData[0].DealTerm;
          this.OffshoreObj.IsModified = 0;


          if (selecteditem.IsSupportTypeOnsite == 1) {
            this.IsSupportTypeOnsiteflag = true;
            this.OffshoreObj.OffshoreVolumeDesc = element.value;
            this.OffshoreObj.IsEditable = this.OffshoreObj.OffshoreVolumeDesc === 'Offshore%' ? 0 : 1

          }
          if (selecteditem.IsSupportTypeOnsite != 1) {
            this.IsSupportTypeOnsiteflag = false;
          }
          this.OffshoreManagedSavedData.push(this.OffshoreObj);

        });





      }


    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  SaveFTECountDetails(odata: any) {
    console.log(odata);
    let subscribe$: Subscription = this._servicetypsService.saveFTECount(odata).subscribe(result => {
      console.log(result);

      if (result.Status == ProcessStatus.Success && result.Data == null && result.Message != '') {
        this.toastr.warning(result.Message);
      }
      else {
        if (!isNullOrUndefined(result) && result.Status == 200) {
          this.showNotificationMessage('FTE Count Data Saved Successfully.', 1, '', null, '', '', 2000);
          //this.getDealDetails(this.selectedTab);
          super.getData(true);
          // this.Flagoutput = 'NO';
          // let odata_report: any = {
          //   DealHeaderId: this.DealHeaderId,
          //   RoleId: 0,
          //   UserId: this._appSharedService.UserRoleList[0].UserId,
          //   DealLineItemId: this.DealItemId,
          //   ServiceType: this.ServiceCode

          // }
          // this.GetServicesTCVReport(odata_report);
        }

      }


    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  SaveProductivityDetails(odata: any) {
    console.log(odata);
    let subscribe$: Subscription = this._servicetypsService.saveProductivityInflation(odata).subscribe(result => {
      console.log(result);

      if (result.Status == ProcessStatus.Success && result.Data == null && result.Message != '') {
        this.toastr.warning(result.Message);
      }
      else {
        if (!isNullOrUndefined(result) && result.Status == 200) {

          this.showNotificationMessage('Productivity & Inflation Data Saved Successfully.', 1, '', null, '', '', 2000);
          super.getData(true);

        }
      }


    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

  }

  validateRange(odata: any) {
    this.rangearray = [];
    (odata).forEach((e: any) => {
      (this.rangearray).push(e.OffshorePer);
    })
    this.rangeValidation = (this.rangearray).every((num: any) => { return num >= 0 && num <= 100 })


  }

  SaveVolumeOffshoreDetails(odata: any) {
    console.log(odata);
    this.validateRange(odata);

    if (this.rangeValidation == false) {
      this.showNotificationMessage('Offshore% should be between 0-100.', 2, '', null, '', '', 2000);
    }
    else {

      let subscribe$: Subscription = this._servicetypsService.saveOffshoreData(odata).subscribe(result => {
        console.log(result);


        if (result.Status == ProcessStatus.Success && result.Data == null && result.Message != '') {
          this.toastr.warning(result.Message);
        }
        else {
          if (!isNullOrUndefined(result) && result.Status == 200) {
            this.showNotificationMessage('Offshore% and Volume Data Saved Successfully.', 1, '', null, '', '', 2500);
            super.getData(true);

          }
        }


      },

        err => {
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }

  }

  getservicedetails(odata: any) {
    console.log(odata);


    let subscribe$: Subscription = this._servicetypsService.GetCISDealStatus(odata).subscribe(result => {
      console.log(result);
      this.STATUSDATA = result.Data;

      let modifieditems = this._Data.filter(x => x.IsModified === 1);
      console.log(this._Data);
      if (this.STATUSDATA[0].DealStatus == '2') {
        this.toastr.warning("Deal is already completed. Please clone the deal in case you have any changes.");
      }
      else {
        this.saveClickData(modifieditems, 'Save');
      }




    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    //  }

  }



  getservicedetails_delete(odata: any) {
    console.log(odata);


    let subscribe$: Subscription = this._servicetypsService.GetCISDealStatus(odata).subscribe(result => {
      console.log(result);
      this.STATUSDATA = result.Data;

      let modifieditems = this._Data.filter(x => x.IsModified === 1);
      console.log(this._Data);
      if (this.STATUSDATA[0].DealStatus == '2') {
        this.toastr.warning("Deal is already completed. Please clone the deal in case you have any changes.");
      }
      else {
        this.deleteEvent();
        this.modifiedItem.IsModified = 0;
        this._appSharedService.IsRowModified = false;
        this._appSharedService.IsRowModified$.next(this._appSharedService.IsRowModified);
      }




    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    //  }

  }
  getservicedetails_copy(odata: any) {
    console.log(odata);


    let subscribe$: Subscription = this._servicetypsService.GetCISDealStatus(odata).subscribe(result => {
      console.log(result);
      this.STATUSDATA = result.Data;

      let modifieditems = this._Data.filter(x => x.IsModified === 1);
      console.log(this._Data);
      if (this.STATUSDATA[0].DealStatus == '2') {
        this.toastr.warning("Deal is already completed. Please clone the deal in case you have any changes.");
      }
      else {
        this.copyrow();
      }

    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);


  }

  openOutputScreenDetails(odata: any) {
    console.log(odata);
    let subscribe$: Subscription = this._servicetypsService.openOutputScreen(odata).subscribe(result => {
      console.log(result);



      if (result.Status == ProcessStatus.Success && result.Data == null && result.Message != '') {
        this.toastr.warning(result.Message);
      }
      else {
        if (!isNullOrUndefined(result) && result.Status == 200) {

          console.log(this._appSharedService.selectedDeal);
          this._appSharedService.selectedDeal.DealStatus = 2;
          this._appSharedService.IsEditable = false;
          this._appSharedService.IsEditable$.next(this._appSharedService.IsEditable);
          this._appSharedService.selectedDeal$.next(this._appSharedService.selectedDeal);
          this._router.navigate([`app/reports/${this.DealHeaderId}/${this.DealItemId}`]);
          //this.showNotificationMessage('Offshore% and Volume Data Saved Successfully.', 1, '', null, '', '');
        }
      }


    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }


  DeleteManagedServices(odata: any) {
    console.log(odata);
    let subscribe$: Subscription = this._servicetypsService.deleteServiceData(odata).subscribe(result => {
      console.log(result);

      // if (this.selectedTab == 'Tools') {
      //   const filteredData = this._Data.filter(item => item.ToolName === 'TOL007' || item.ToolName === 'TOL006');
      //   if (filteredData.length > 0) {
         
      //     this.Toolsflag = true; 
      //    // this.TOOLCISSUBFIELDS.NumberOfSplunk = '';
      //   //  this.TOOLCISSUBFIELDS.NumberOfModule = '';
      //   }
      //   else{
      //     this.Toolsflag = false;
      //   }
        
      // }
      // if (!isNullOrUndefined(result) && result.Status == 200) {
      //   this.showNotificationMessage('Offshore Data DeltSuccessfully.', 1, '', null, '', '');
      // }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  // InsertOrUpdateServiceTypeToolDetails(odata: any) {
  //   console.log(odata);
  //   let subscribe$: Subscription = this._servicetypsService.InsertOrUpdateServiceTypeToolDetails(odata).subscribe(result => {
  //     console.log(result);
  //    // super.getData(true);
  //     // if (!isNullOrUndefined(result) && result.Status == 200) {
  //     //   this.showNotificationMessage('Offshore Data DeltSuccessfully.', 1, '', null, '', '');
  //     // }
  //   },

  //     err => {
  //       this.IsLoading = false;
  //     });
  //   this.subscriptionList$.push(subscribe$);
  // }

  SendOffshoreData() {
    let offshoreVolumeitem = this.OffshoreManagedSavedData.filter(x => x.IsModified === 1)

    // this.OffshoreManagedServiceALLData = [];
    //selitem.IsOffshoreVolumeFlag = 0;
    if (!isNullOrUndefined(offshoreVolumeitem) && offshoreVolumeitem.length > 0) {
      this.OffshoreManagedSavedData.forEach((element: any) => {
        // this.OffshoreObj = new ServicetypeInfo();
        if (element.OffshoreVolumeDesc === 'Offshore%') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          this.OffshoreManagedServiceData[0].OffshorePer = element.Year1;
          this.OffshoreManagedServiceData[0].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[0].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[0].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[1].OffshorePer = element.Year2;
          this.OffshoreManagedServiceData[1].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[1].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[1].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;


          this.OffshoreManagedServiceData[2].OffshorePer = element.Year3;
          this.OffshoreManagedServiceData[2].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[2].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[2].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[3].OffshorePer = element.Year4;
          this.OffshoreManagedServiceData[3].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[3].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[3].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[4].OffshorePer = element.Year5;
          this.OffshoreManagedServiceData[4].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[4].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[4].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[5].OffshorePer = element.Year6;
          this.OffshoreManagedServiceData[5].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[5].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[5].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[6].OffshorePer = element.Year7;
          this.OffshoreManagedServiceData[6].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[6].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[6].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[7].OffshorePer = element.Year8;
          this.OffshoreManagedServiceData[7].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[7].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[7].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[8].OffshorePer = element.Year9;
          this.OffshoreManagedServiceData[8].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[8].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[8].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[9].OffshorePer = element.Year10;
          this.OffshoreManagedServiceData[9].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[9].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[9].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

        }
        if (element.OffshoreVolumeDesc === 'Volume') {
          // this.OffshoreObj.OffshoreVolumeDesc = element.OffshoreVolumeDesc;
          this.OffshoreManagedServiceData[0].Volume = element.Year1;
          this.OffshoreManagedServiceData[0].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[0].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[0].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;


          this.OffshoreManagedServiceData[1].Volume = element.Year2;
          this.OffshoreManagedServiceData[1].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[1].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[1].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[2].Volume = element.Year3;
          this.OffshoreManagedServiceData[2].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[2].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[2].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[3].Volume = element.Year4;
          this.OffshoreManagedServiceData[3].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[3].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[3].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[4].Volume = element.Year5;
          this.OffshoreManagedServiceData[4].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[4].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[4].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[5].Volume = element.Year6;
          this.OffshoreManagedServiceData[5].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[5].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[5].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[6].Volume = element.Year7;
          this.OffshoreManagedServiceData[6].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[6].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[6].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[7].Volume = element.Year8;
          this.OffshoreManagedServiceData[7].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[7].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[7].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[8].Volume = element.Year9;
          this.OffshoreManagedServiceData[8].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[8].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[8].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

          this.OffshoreManagedServiceData[9].Volume = element.Year10;
          this.OffshoreManagedServiceData[9].DealHeaderId = this.DealHeaderId;
          this.OffshoreManagedServiceData[9].DealLineitemId = this.DealItemId;
          this.OffshoreManagedServiceData[9].ServiceTypeTransactionId = offshoreVolumeitem[0].ServiceTypeTransactionId;

        }

        //offshoreVolumeitem[0].IsModified = 1;
      });

      offshoreVolumeitem[0].IsOffshoreVolumeFlag = 0;
    }


    if (this.OffshoreManagedSavedData.filter(x => x.IsModified === 1).length == 0) {
      console.log(this.OffshoreManagedSavedData);
      (this.OffshoreManagedSavedData).map((e: any) => {
        if (e.OffshoreVolumeDesc == 'Offshore%') {
          if (e.Year1 > 0 || e.Year2 > 0 || e.Year3 > 0 || e.Year4 > 0 || e.Year5 > 0 || e.Year6 > 0 || e.Year7 > 0 ||
            e.Year8 > 0 || e.Year9 > 0 || e.Year10 > 0) {

            if (e.OffshoreVolumeDesc == 'Volume') {
              if (e.Year1 > 0 || e.Year2 > 0 || e.Year3 > 0 || e.Year4 > 0 || e.Year5 > 0 || e.Year6 > 0 || e.Year7 > 0 ||
                e.Year8 > 0 || e.Year9 > 0 || e.Year10 > 0) {
                return;
              }
            }
            return;
          }
          else {
            this.outputscreenNavigate = false;
            this.showNotificationMessage('Please enter offshore % and volume details to continue', 2, '', null, '', '', 3000);
            return;
          }
        }

      })

      return;
    }
    if ((this.OffshoreManagedSavedData.filter(x => x.IsModified === 1)).length > 0) {



      this.SaveVolumeOffshoreDetails(this.OffshoreManagedServiceData);
      console.log(this.OffshoreManagedServiceData)
    }

    // if ((this.OffshoreManagedServiceData.filter(x => x.Volume == '')).length > 0) {
    //   this.showNotificationMessage('Offshore volume details cannot be empty', 2, '', null, '', '');
    //   return;
    // }
    //  if ((this.OffshoreManagedServiceData.filter(x => x.Year == '')).length > 0) {
    //   this.showNotificationMessage('Offshore year details cannot be empty', 2, '', null, '', '');
    //   return;
    // }


    //this.SaveVolumeOffshoreDetails(this.OffshoreManagedServiceData);
  }


  navigatetoDealPage() {

    if (this.modifiedItem && this.modifiedItem.IsModified == 1) {
      this.showNotificationMessage('Unsaved data will be lost. Do you want to proceed?', 2, 'DEALPAGE', null, 'Cancel', 'OK', 10000);
    }
    else {
      this._Data = [];
      this.FromNavigateFlag = true;
      this._router.navigate([`app/create-deal/${this.DealHeaderId}`], { state: { dealData: this.selectedDeal } });
      this._appSharedService.setDealHeaderId = this.DealHeaderId;
      this._appSharedService.setDealHeaderId$.next(this._appSharedService.setDealHeaderId);
    }
  }

  //////update deal////
  updateDealStatus() {

    this.IsLoading = true;

    let odata: any = {
      DealHeaderId: this.DealHeaderId,
      DealStatus: '2',
    };
    let subscribe$: Subscription = this._servicetypsService.updateDealStatus(odata).subscribe(result => {
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        // this.showNotificationMessage('Successfully Updated.', 1, '', null, '', '');


        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  getoutputProgressStatus() {

    this.IsLoading = true;

    let odata: any = {
      DealHeaderId: this.DealHeaderId,
      RoleId: 0,
      UserId: this._appSharedService.UserRoleList[0].UserId
    };
    let subscribe$: Subscription = this._servicetypsService.getOutputProgressStatusData(odata).subscribe(result => {
      console.log(result);
      if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
        // this.showNotificationMessage('Successfully Updated.', 1, '', null, '', '');
        this.CalculationsCount = result.Data[0].CalculationsCount;
        if (this.CalculationsCount > 0) {
          this.showNotificationMessage('Calculations are in progress', 2, '', null, '', '', 1500);
        }
        else {
          this.outputscreenNavigate = true;
          this.openModal('outputscreenpopup');
        }
        this.IsLoading = false;
      }
    },

      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }




  TCVOUTPUT() {
    // console.log(data);
    this.Flagoutput = true;


    let odata_report: any = {
      DealHeaderId: this.DealHeaderId,
      RoleId: 0,
      UserId: this._appSharedService.UserRoleList[0].UserId,
      DealLineItemId: this.DealItemId,
      ServiceType: this.ServiceCode

    }


    // this.IsLoading = true;
    // setTimeout(() => {

    this.GetServicesTCVReport(odata_report);
    //   this.IsLoading = false;  
    // }, 2000);


  }






  ///////////////


  validateModifiedItems(modifieditems: any) {
    modifieditems.forEach((item: { ToolName: any; Volume: any; Complexity: any; PricingUnit: any; }, index: number) => {
      if (
        !isNullOrUndefined(item.ToolName) && item.ToolName != '' &&
        !isNullOrUndefined(item.Volume) && item.Volume != '' &&
        !isNullOrUndefined(item.Complexity) && item.Complexity != '' &&
        !isNullOrUndefined(item.PricingUnit) && item.PricingUnit != ''
      ) {
        // Validation successful for currentItem
        // Perform necessary actions here
        console.log(`Item ${index + 1} validated successfully.`);
      } else {
        // Validation failed for currentItem
        console.log(`Validation failed for item ${index + 1}.`);
        // You can handle failures here
      }
    });
  }
  //////////////////

  SaveDataService() {
    let subscribe$: Subscription = this._servicetypsService.saveData(this.selectedInfoData).subscribe((result: { Status: ProcessStatus; Data: null; Message: string; }) => {
      if (result.Status == ProcessStatus.Success && result.Data == null && result.Message != '') {
        this.toastr.warning(result.Message);
      }
      else {

      }



      this.IsLoading = false;

    },
      () => {
        this.IsLoading = false;
      });

    this.subscriptionList$.push(subscribe$);
    console.log(this.subscriptionList$);


  }









}
