import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environments/environment';


@Injectable()
export class ModelConfigService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
  private HomeURL: string;
  private DealURL: string;

  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.HomeURL = environment.apiURL.HomeURL;
    this.DealURL = environment.apiURL.DealURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }


  GetModelConfigurationDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetModelConfigurationDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetITMetricsPopupDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDeatailsForITMetricsOptions',
      { headers: this.headers, params: Params });
    return response;
  }

  GetDeatailsForITMetricsOptionsGrid(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDeatailsForITMetricsOptionsGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetITMetricsOptionsGridDefault(option: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('ITMetricsOption', option);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDetailsForITMetricsOptionsGridDefault',
      { headers: this.headers, params: Params });
    return response;
  }

  GetModelBaseliningDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetModelBaseliningAmDealDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  insertorUpdateModelConfigDeals(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertorUpdateAmDealsModelConfiguration', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertorUpdateAmDealsModelBaselining(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertorUpdateAmDealsModelBaselining', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertorUpdateITMetricsPopupDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertorUpdateAmModelConfigurationITMetricsPopupDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetAnticipatedData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealLineItemId', odata.DealLineItemId);
    Params = Params.append('DealSubLineItemId', odata.DealSubLineItemId);
    // Params = Params.append('RoleId', 0);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAnticipatedFTEGrowth',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAnnualComensationDeatailsForITMetricsPopup(country: any,currency:any): Observable<ProcessResponse<any>> {
    //CountryId=USA&ToCurrencyId=EUR
    let Params = new HttpParams();
    Params = Params.append('CountryId', country);
    Params = Params.append('ToCurrencyId', currency);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAnnualComensationDeatailsForITMetricsPopup',
      { headers: this.headers, params: Params });
    return response;
  }

  ITMetricsPopupChangeGridCal(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(this.DealURL + 'ITMetricsPopupChangeGridCal', odata,
    { headers: this.headers, params: Params })
    .pipe(
      catchError(this.handleError)
    );
  }

  GetModelConfigurationGridCalculationDetails(getModelConfigurationGridCalculation: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(this.DealURL + 'GetModelConfigurationGridCalculationDetails', getModelConfigurationGridCalculation,
    { headers: this.headers, params: Params })
    .pipe(
      catchError(this.handleError)
    );
  }


  GetDeatailsForITMetricsPopup(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('ITMetricsOption', odata.ITMetricsOption);
    Params = Params.append('Totalnumberofemployee', odata.Totalnumberofemployee);
    Params = Params.append('Industry', odata.Industry);
    Params = Params.append('AnnualCompensation', odata.AnnualCompensation);
    Params = Params.append('Yearlyrevenue', odata.Yearlyrevenue);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetDeatailsForITMetricsPopup',
      { headers: this.headers, params: Params });
    return response;
  }

  //////GetClientResourceData////

  GetClientResourceData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealLineItemId', odata.DealLineItemId);
    Params = Params.append('DealSubLineItemId', odata.DealSubLineItemId);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetClientResourceProfile',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAmDealProgressBarPer(dealHeaderId: number, Approach:any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('Module', 'AM');
    Params = Params.append('Approach', Approach);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmDealProgressBarPer',
      { headers: this.headers, params: Params });
    return response;
  }
  
  ///////InsertUpdateClientResourceProfile ////

  InsertUpdateClientResourceProfile(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertUpdateClientResourceProfile', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  ///////Insert anticipated fte///////

  InsertUpdateAnticipatedFTEGrowth(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertUpdateAnticipatedFTEGrowth', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  //////////IT Metrics popup/////////
  ITMetricsFieldsData(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('Industry', odata.Industry);
    Params = Params.append('MatrixInput', odata.MatrixInput);
    Params = Params.append('AnnualCompensation', odata.AnnualCompensation);
    Params = Params.append('ITMetricsOption', odata.ITMetricsOption);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'ITMetricsDrivenSecondAPI',
      { headers: this.headers, params: Params });
    return response;
  }
  ///////Insert Delivery Model///////
  InsertUpdateMBDeliveryLocationModelling(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertUpdateMBDeliveryLocationModelling', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
//////////InsertUpdateServiceProfile////
  InsertUpdateServiceProfile(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertUpdateServiceProfile', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  //////////GetMBDeliveryLocationModelling////
  GetMBDeliveryLocationModelling(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealLineItemId', odata.DealLineItemId);
    Params = Params.append('DealSubLineItemId', odata.DealSubLineItemId);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetMBDeliveryLocationModelling',
      { headers: this.headers, params: Params });
    return response;
  }

  //////IT metrics Grid Data/////////
  PopGridData(odata: any) {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('Industry', odata.Industry);
    Params = Params.append('MatrixInput', odata.MatrixInput);
    Params = Params.append('AnnualCompensation', odata.AnnualCompensation);
    Params = Params.append('ITMetricsOption', odata.ITMetricsOption);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'ITMetricsDrivenThirdAPI',
      { headers: this.headers, params: Params });
    return response;
  }
  /////getserviceApi/////
  GetServiceProfile(odata: any) {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', odata.DealHeaderId);
    Params = Params.append('DealLineItemId', odata.DealLineItemId);
    Params = Params.append('DealSubLineItemId', odata.DealSubLineItemId);
    // Params = Params.append('UserId', 0);
    // Params = Params.append('RoleId', 0);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetServiceProfile',
      { headers: this.headers, params: Params });
    return response;
  }

}