import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './core/auth/auth-guard.service';
import { LoginComponent } from './core/login/login.component';
import { RegistryComponent } from './core/registry/registry.component';

// import { DashBoardComponent } from './home/dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LiteHomeComponent } from './home/lite-home/lite-home.component';
import { CisMainPageComponent } from './home/cis-main-page/cis-main-page.component';
import { ReportComponent } from './report/report.component';
import { CallbackComponent } from './core/callback/callback.component';
import { AppMainComponent } from './app-main.component';
import { ForgotPasswordComponent } from './core/login/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateDealComponent } from './create-deal/create-deal.component';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { ServicetypsComponent } from './servicetyps/servicetyps.component';
import { environment } from 'src/environments/environment';
import { AdminComponent } from './admin/admin.component';
import { UserRoleManageComponent } from './admin/user-role-manage/user-role-manage.component';
import { ActivityLogComponent } from './admin/activity-log/activity-log.component';
import { ReportsComponent } from './admin/report/report.component';
import { RoleWiseMenuAuthComponent } from './admin/role-wise-menu-auth/role-wise-menu-auth.component';
import { ADHomeComponent } from './home/AD-home/ad-home.component';
import { ModelConfigComponent } from './create-deal/model-config/model-config.component';
import { LotSolutionModellingComponent } from './create-deal/lot-solution-modelling/lot-solution-modelling.component';
import { LotSolutionPricingModelComponent } from './create-deal/lot-solution-pricing-model/lot-solution-pricing-model.component';
import { AMReportsComponent } from './am-reports/am-reports.component';
import { AmDownloadsComponent } from './am-downloads/am-downloads.component';
import { AMChartsComponent } from './am-charts/am-charts.component';
import { AmChartsMultipleComponent } from './am-charts-multiple/am-charts-multiple.component';
import { FAQSComponent } from './faqs/faqs.component';



const internalRoutes: Routes = [

 { path: '', redirectTo: 'app', pathMatch: 'full' },

  // { path: '' , loadChildren: '/home/home.module#HomePageModule' },
  {
    path: 'app', component: AppMainComponent,
    children: [
      //  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
      { path: 'benchmark-guidelines', component: BenchmarkComponent, canActivate: [AuthGuardService] },
      { path: 'glossary', component: GlossaryComponent, canActivate: [AuthGuardService] },
      { path: 'faqs', component: FAQSComponent, canActivate: [AuthGuardService] },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'lite-home', component: LiteHomeComponent, canActivate: [AuthGuardService] },
      { path: 'cis-home-page', component: CisMainPageComponent, canActivate: [AuthGuardService] },
      { path: 'AD-home', component: ADHomeComponent, canActivate: [AuthGuardService] },
      { path: 'create-deal', component: CreateDealComponent, canActivate: [AuthGuardService] },
      { path: 'create-deal/:DealHeaderId', component: CreateDealComponent, canActivate: [AuthGuardService] },
      { path: 'servicetyps/:DealHeaderId', component: ServicetypsComponent, canActivate: [AuthGuardService] },
      { path: 'model-config/:DealHeaderId', component: ModelConfigComponent, canActivate: [AuthGuardService], data: { selectTab: 'ModelConfig' } },
      { path: 'model-config/ModelBaseline/:DealHeaderId', component: ModelConfigComponent, canActivate: [AuthGuardService], data: { selectTab: 'ModelBaseline' } },
      { path: 'model-config/client-resource-profile/:DealHeaderId', component: ModelConfigComponent, canActivate: [AuthGuardService], data: { selectTab: 'ClientProfile' } },
      { path: 'model-config/anticipated-FTE-growth/:DealHeaderId', component: ModelConfigComponent, canActivate: [AuthGuardService], data: { selectTab: 'AnticipatedFTE' } },
      { path: 'model-config/service-profile/:DealHeaderId', component: ModelConfigComponent, canActivate: [AuthGuardService], data: { selectTab: 'ServiceProfile' } },
      { path: 'model-config/delivery-location-modeling/:DealHeaderId', component: ModelConfigComponent, canActivate: [AuthGuardService], data: { selectTab: 'DeliveryModel' } },
      { path: 'lot-solution-modelling/:DealHeaderId/:DealLineitemId', component: LotSolutionModellingComponent, canActivate: [AuthGuardService] },
      { path: 'lot-solution-modelling/DeliveryModel/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionModellingComponent, canActivate: [AuthGuardService], data: { selectedTab: 'DeliveryModel' } },
      { path: 'lot-solution-modelling/LocationMix/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionModellingComponent, canActivate: [AuthGuardService], data: { selectedTab: 'LocationMix' } },
      { path: 'lot-solution-modelling/StaffingModel/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionModellingComponent, canActivate: [AuthGuardService], data: { selectedTab: 'StaffingModel' } },
      { path: 'lot-solution-modelling/productivityImpact/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionModellingComponent, canActivate: [AuthGuardService], data: { selectedTab: 'ProductivityImpact' } },
    
      { path: 'lot-solution-pricing-model/pricingmodel/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'PriceModelling' } },
      { path: 'lot-solution-pricing-model/othercost/travelrelatedcosts/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'travelrelatedcosts' } },
      { path: 'lot-solution-pricing-model/othercost/communicationcost/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'communicationcosts' } },
      { path: 'lot-solution-pricing-model/othercost/assettakeovercosts/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'assettakeovercosts' } },
      { path: 'lot-solution-pricing-model/othercost/servicemanagementcost/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'servicemanagementcost' } },
      { path: 'lot-solution-pricing-model/othercost/nonstandardhardwaresoftware/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'nonstandardhardwaresoftware' } },
      { path: 'lot-solution-pricing-model/othercost/rebadgecosts/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'rebadgecosts' } },
      { path: 'lot-solution-pricing-model/othercost/contingencycosts/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'contingencycosts' } },
      { path: 'lot-solution-pricing-model/othercost/misccostsvalues/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'OtherCost', selectedTab: 'misccostsvalues' } },

      { path: 'lot-solution-pricing-model/clientcost/severancepayburden/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'ClientCost', selectedTab: 'severancepayburden' } },
      { path: 'lot-solution-pricing-model/clientcost/governancecosts/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'ClientCost', selectedTab: 'governancecosts' } },
      { path: 'lot-solution-pricing-model/clientcost/retainedfte/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'ClientCost', selectedTab: 'retainedfte' } },
      { path: 'lot-solution-pricing-model/clientcost/miscellaneoscost/:DealHeaderId/:DealLineitemId/:DealSubLineitemId', component: LotSolutionPricingModelComponent, canActivate: [AuthGuardService], data: { selectedModule: 'ClientCost', selectedTab: 'miscellaneoscost' } },
      { path: 'am-reports', component: AMReportsComponent, canActivate: [AuthGuardService] },
      // { path: 'servicetyps', component: ServicetypsComponent, canActivate: [AuthGuardService] },
      { path: 'am-reports/solutionsummary', component: AMReportsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'solutionsummary' } },
      { path: 'am-reports/pricingsummary', component: AMReportsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'pricingsummary' } },
      { path: 'am-reports/businesscasesummary', component: AMReportsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'businesscasesummary' } },
      { path: 'am-reports/modelcomparison', component: AMReportsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'modelcomparison' } },
      { path: 'am-reports/whatifswithmodel', component: AMReportsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'whatifswithmodel' } },
      { path: 'am-reports/pastdealcomparison', component: AMReportsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'PastDealComparison' } },

      { path: 'am-downloads/consolidated', component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'consolidated' } },
      { path: 'am-downloads/dealanalysis', component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'dealanalysis' } },
      { path: 'am-downloads/PegaDealAnalysis', component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'PegaDealAnalysis' } },
      { path: 'am-downloads/masterdatadownload', component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'masterdatadownload' } },
      { path: 'am-downloads/whatifswithadmt', component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'whatifswithadmt' } },
      { path: 'am-downloads/quickdealupload'
      , component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'quickdealupload' } },
      { path: 'am-downloads/DealComparisonUpload'
      , component: AmDownloadsComponent, canActivate: [AuthGuardService], data: { selectedModule: 'DealComparisonUpload' } },
      { path: 'am-charts', component: AMChartsComponent, canActivate: [AuthGuardService] },
      { path: 'am-multiple-charts', component: AmChartsMultipleComponent, canActivate: [AuthGuardService] },
      { path: 'reports/:DealHeaderId/:DealLineitemId', component: ReportComponent, canActivate: [AuthGuardService] },
      
      {
        path: 'admin', component: AdminComponent, canActivate: [AuthGuardService],
        children: [
          { path: '', redirectTo: 'user', pathMatch: 'full' },
          { path: 'user', redirectTo: 'user', pathMatch: 'full' },
          { path: 'user', component: UserRoleManageComponent, data: { selectTab: 'user' }, canActivate: [AuthGuardService] },
          { path: 'role', component: UserRoleManageComponent, data: { selectTab: 'role' }, canActivate: [AuthGuardService] },
          { path: 'rolewisemenuauth', component: RoleWiseMenuAuthComponent, canActivate: [AuthGuardService] },
          { path: 'activitylog', component: ActivityLogComponent, canActivate: [AuthGuardService] },
          { path: 'report', redirectTo: 'report', pathMatch: 'full' },
          { path: 'report', component: ReportsComponent, canActivate: [AuthGuardService] },
        ]
      },
    ]
  },
  { path: 'callback', component: CallbackComponent },
];

const externalRoutes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'signup', component: RegistryComponent },
  { path: 'forgotpswd', component: ForgotPasswordComponent },

  {
    path: 'app', component: AppMainComponent,
    children: [
      { path: '/home', component: HomeComponent },
      { path: 'reports', component: ReportComponent, data: { selectTab: 'masterreport' }, canActivate: [AuthGuardService] },
    ]
  },

];

@NgModule({
// UseHash is set to true
  imports: [RouterModule.forRoot(internalRoutes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
