export class UserRoleDetail {
    UserId: number = 0;
    UserName: string = '';
    EmailId: string = '';
    Department: string = '';
    Designation: string = '';
    RoleId: number = 0;
    SBUId: number = 0;
    RegionId:number=0;
    CountryId:number=0;
    Location: string = '';
    IsEditable: number = 1;
    IsModified: number = 0;
    RoleIds: string = "";
    RoleName: string = "";

    constructor(init?: Partial<UserRoleDetail>) {
        Object.assign(this, init);
    }
}
