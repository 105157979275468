<div class="h-100">
  <div class="p2w-lite contentScroll p-2 pb-0 pt-0">
    <div class="p-2 pt-0 pb-0">
      <div class="" id="dashboardTab">
        <div class="row align-items-center">
          <div class="col-9">
            <!--  <ng-container *ngIf="(SummaryGraphData != null && SummaryGraphData != undefined && SummaryGraphData.length > 0)">
              <ng-container *ngFor="let graphname of (SummaryGraphData | filterBy: {GroupName: 'LiteBanner'})">
              <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
                <div class="d-flex w-100 justify-content-between align-items-center">
                  <div class="bg-dark p-2 rounded-4"><img src="assets/images/dashboard.svg" alt="dashboard icon"></div>
                  <p class="text-bold text-dark m-0">{{graphname.Chart}}</p>
                  <p class="text-bold text-dark px-2 m-0">{{graphname.ChartOutput}}</p>
                </div>
              </div>
              </ng-container>
            </ng-container> -->

            <div class=" list-group list-group-horizontal w-80 mt-1">
            <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="bg-dark p-2 rounded-2">
                  <img src="../../../assets/images/price2win.svg" alt="dashboard icon">
                </div>
                <p class="text-bold text-dark m-0">Current Spend (Overall)</p>
                <p class="text-bold text-dark px-2 m-0">{{currentspendVal}}M</p>
              </div>
            </div>
            <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="bg-dark p-2 rounded-2">
                  <img src="../../../assets/images/price2win.svg" alt="dashboard icon">
                </div>
                <p class="text-bold text-dark m-0">Price2Win</p>
                <p class="text-bold text-dark px-2 m-0">{{p2wVal}}M</p>
              </div>
            </div>
            <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="bg-dark p-2 rounded-2">
                  <img src="../../../assets/images/cm.svg" alt="dashboard icon">
                </div>
                <p class="text-bold text-dark m-0">CM%</p>
                <p class="text-bold text-dark px-2 m-0">{{cmVal}}%</p>
              </div>
            </div>
            <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="bg-dark p-2 rounded-2">
                  <img src="../../../assets/images/savings.svg" alt="dashboard icon">
                </div>
                <p class="text-bold text-dark m-0">Savings%</p>
                <p class="text-bold text-dark px-2 m-0">{{savingsVal}}%</p>
              </div>
            </div>
            <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="bg-dark p-2 rounded-2">
                  <img src="../../../assets/images/productivity.svg" alt="dashboard icon">
                </div>
                <p class="text-bold text-dark m-0">Productivity%</p>
                <p class="text-bold text-dark px-2 m-0">{{productivityVal}}%</p>
              </div>
            </div>
            <div class="list-group-item list-group-item-action p-0 ms-0 mx-4 rounded-2">
              <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="bg-dark p-2 rounded-2">
                  <img src="../../../assets/images/productivity.svg" alt="dashboard icon">
                </div>
                <p class="text-bold text-dark m-0">Blended Rate (Overall)</p>
                <p class="text-bold text-dark px-2 m-0">{{overallblendedrateVal}}</p>
              </div>
            </div>
            </div>
          </div>
          <div class="col-2">
            <div class="justify-content-end">              
            <ng-container
            *ngIf="(outputTemplate != null && outputTemplate != undefined && outputTemplate.length > 0)">
            <div class="">
            <customform #LiteDashboardForm [FormTemplate]="outputTemplate" [FormData]="DashboardData"
            [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
            (FieldClick)="onClick($event)" (FieldChange)="onGeneralInfoChange($event)">
          </customform>
             </div>
            </ng-container>
           
            </div>
          </div>
          <div class="col-1">
            <div class="d-flex align-items-center justify-content-end" id="adamdeal">              
              <div class="col-auto align-self-end ml-auto px-2 d-flex">               
                <div>
                  <button class="btnCustom rounded-pill btnTertiary" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Download
                  </button>
                  <ul class="dropdown-menu download-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:void(0);" (click)="downloadtoExcel()">Excel</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0);" (click)="downloadtoPDF()">Pdf</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="planInfoWraper customForm bg-white m-2 mb-0 p-0">
      <div class="m-0">
        <div class="row align-items-center">
          <div class="col-3">
            <div class="card bg-white mb-0">
              <div class="card-header rounded-1 text-center pt-2">
                <h5>
                  <span>Savings % Summary</span>
                </h5>
              </div>
              <div class="card-body p-1">
                <ng-container *ngIf="summaryData!=null && summaryData!=undefined">
                  <div id="SummaryChart" class="P2WCharts"></div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card bg-white mb-0">
              <div class="card-header rounded-1 text-center pt-2">
                <h5>
                  <span>Productivity (Deal Wise in %)</span>
                </h5>
              </div>
              <div class="card-body p-1">
                <ng-container *ngIf="productivitygraphData!=null && productivitygraphData!=undefined">
                  <div id="chartdiv" class="P2WCharts"></div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card bg-white mb-0">
              <div class="card-header rounded-1 text-center pt-2">
                <h5>
                  <span>Location Ratio (Deal Wise in %)</span>
                </h5>
              </div>
              <div class="card-body p-1">
                <ng-container *ngIf="locationMixData!=null && locationMixData!=undefined">
                  <div id="locationMix" class="P2WCharts"></div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card bg-white mb-0">
              <div class="card-header rounded-1 text-center pt-2">
                <h5>
                  <span>Blended Rate (Deal Wise) (/Hr)</span>
                </h5>
              </div>
              <div class="card-body p-1">
                <ng-container *ngIf="blendedData!=null && blendedData!=undefined">
                  <div id="chartblendedRate" class="P2WCharts"></div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-2 mb-0">

      <div class="col-6 ps-1">
        <div class="card h-100 m-0">
          <div class="card-header rounded-1 ps-1">
            <h3>
              <span>AS IS (Top down- FTE based)</span>
            </h3>
          </div>
          <div class="card-body p-1">
            <p class="text-bold mb-0">
              <span>General Information</span>
            </p>
            <div class="row small-gutter p-1">
              <ng-container
                *ngIf="(GeneralInfoTemplate != null && GeneralInfoTemplate != undefined && GeneralInfoTemplate.length > 0)">
                <customform #LiteDashboardForm [FormTemplate]="GeneralInfoTemplate" [FormData]="DashboardData"
                  [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                  (FieldClick)="onClick($event)" (FieldChange)="onGeneralInfoChange($event)">
                </customform>
              </ng-container>
            </div>
            <div>
              <p class="text-bold mb-0">
                <span>Deal Information</span>
              </p>
              <div class="gridContainer">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered tblGrid fixed" id="fte_table">
                    <thead>
                      <tr>
                        <th>Location <span class="required">*</span></th>
                        <th colspan="2">
                          <ng-select #select [items]="onshoreLocation" class="custom" appendTo="body" bindLabel="label"
                            [searchable]="false" placeholder="Select Onshore" bindValue="id"
                            [(ngModel)]="onshoreLocation.code" (change)="onChange($event)">
                          </ng-select>
                        </th>
                        <th colspan="2">
                          <ng-select #select [items]="offshoreLocation" class="custom" appendTo="body" bindLabel="label"
                            [searchable]="false" placeholder="Select Offshore" bindValue="id"
                            [(ngModel)]="offshoreLocation.code" (change)="onChange($event)">
                          </ng-select>
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th scope="col">FTE</th>
                        <th scope="col">Rate (/hr) <span><img src="../../../assets/images/information-Icons.svg" style="background-color: white;" tooltip="Please Enter Rate in Input Currency"></span></th>
                        <th scope="col">FTE</th>
                        <th scope="col">Rate (/hr) <span><img src="../../../assets/images/information-Icons.svg" style="background-color: white;" tooltip="Please Enter Rate in Input Currency"></span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Client</th>
                        <td *ngFor="let item of client">
                          <input type="number" class="form-control form-control-sm" value="" id="item.id"
                            [(ngModel)]="item.value" (change)="onChange(item)" />
                        </td>
                      </tr>
                      <tr>
                        <th>Vendor</th>
                        <td *ngFor="let item of vendor">
                          <input type="number" class="form-control form-control-sm" value="" id="item.id"
                            [(ngModel)]="item.value" (change)="onChange(item)" />
                        </td>
                      </tr>
                      <tr>
                        <th>Existing CG</th>
                        <td *ngFor="let item of existingcg">
                          <input type="number" class="form-control form-control-sm" value="" id="item.id"
                            [(ngModel)]="item.value" (change)="onChange(item)" />
                        </td>
                      </tr>
                      <!--<tr>
                        <th>Inflation</th>
                         <ng-container *ngFor="let item of Inflation;">
                          <td>
                            <input type="number" class="form-control form-control-sm" value="" id="item.id"
                              [(ngModel)]="item.value" (change)="onChange(item)" placeholder="Enter value" />
                          </td>
                          <td>
                            <span>%Annual</span>
                          </td>
                        </ng-container> 
                        <button type="button" class="rounded-pill btnCustom btnPrimary"  value="">
                          Add Inflation
                        </button>
                      </tr>-->
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="d-flex mt-3 inflation-button">
                <p class="ms-2 align-self-center text-bold">Inflation</p>
              <button type="button" class="btnPrimary" (click)="onInflationClick()" value="">
                Add Inflation
              </button>
            </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-6 px-0 ps-2">
        <div class="card h-100 m-0">
          <div class="card-header ps-2 rounded-1">
            <h3>
              <span>TO BE (Solution Parameters)</span>
            </h3>
          </div>
          <div class="card-body overflow-auto p-1 p2w-lite">
            <ng-container *ngIf="(LiteTabs != null && LiteTabs != undefined && LiteTabs.length > 0)">
              <tabset [tabList]="LiteTabs" [tabType]="'plaintype'" (selected)="setSelectedTab($event)" (click)="tabOnClick($event)">
                <ng-container *ngFor="let tab of LiteTabs">

                  <ng-container *ngIf="selectedTab==tab.Name">
                    <ng-container *ngIf="selectedTab=='Productivity'">
                      <div class="row small-gutter p-2">
                        <div>
                          <label for="customRange3" class="form-label">Select Productivity for the entire Deal Term for
                            the below range:</label>
                          <range-slider [min]="0" [max]="5" [rangeList]="rangeList" [selectedtab]="selectedTab" (rangeClick)="onRangeClick($event)"  [selectedButton]="prodVal">
                          </range-slider>
                        </div>
                        <div *ngIf="IsCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                            <ng-container *ngFor="let el of ProdArray;">
                             <p class="pe-2 text-bold">{{el.Name}}</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                     [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                 
                             </ng-container>                          
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedTab == 'LocationMix'">
                      <div class="row small-gutter p-2">
                        <p class=" mt-2 mb-4">
                          <span>Select the level of Delivery Location from the below range:</span> 
                          <span
                            *ngIf="infoIconDisabled"><img src="../../../assets/images/information-Icons.svg"
                              alt="information icon" (click)="onProdIconClick()"></span>
                        </p>
                        <ng-container
                          *ngIf="(locationMixTemplate != null && locationMixTemplate != undefined && locationMixTemplate.length > 0)">
                          <customform #DealInfoForm [FormTemplate]="locationMixTemplate" [FormData]="DashboardData"
                            [FormProp]="locationprop" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onLocationChange($event)">
                          </customform>


                        <ng-container *ngIf="IsLoconshore || IsLocoffshore">
                          <customform #DealInfoForm [FormTemplate]="locationNearShoreTemplate" [FormData]="DashboardData"
                            [FormProp]="locationprop" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                            (FieldClick)="onClick($event)" (FieldChange)="onOffshoreChange($event)">
                          </customform>
                          </ng-container>
                        </ng-container>
                        <div
                          *ngIf="DashboardData.Onshore!='' && DashboardData.LocationOffshore!='' && (DashboardData.Nearshore==''|| DashboardData.Nearshore==null) ">
                          <ng-container *ngIf="DashboardData.Onshore!=null && DashboardData.LocationOffshore!=null">
                            <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                              (rangeClick)="onLocationClick($event)" [selectedtab]="selectedTab" [selectedButton]="LocVal"></range-slider>
                          </ng-container>                          
                        </div>

                        <div
                          *ngIf="DashboardData.Onshore!='' && DashboardData.Nearshore!='' && (DashboardData.LocationOffshore==''|| DashboardData.LocationOffshore==null) ">
                          <ng-container *ngIf="DashboardData.Onshore!=null && DashboardData.Nearshore!=null">
                            <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                              (rangeClick)="onLocationClick($event)" [selectedtab]="selectedTab" [selectedButton]="LocVal"></range-slider>
                          </ng-container>
                          </div>
                        <div
                          *ngIf="DashboardData.Nearshore!='' && DashboardData.LocationOffshore!='' && (DashboardData.Onshore==''|| DashboardData.Onshore==null)">
                          <ng-container *ngIf="DashboardData.LocationOffshore!=null && DashboardData.Nearshore!=null">
                            <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                              (rangeClick)="onLocationClick($event)" [selectedtab]="selectedTab" [selectedButton]="LocVal"></range-slider>
                          </ng-container>
                          </div>

                        <div
                          *ngIf="DashboardData.Onshore!=''&& DashboardData.Nearshore!='' && DashboardData.LocationOffshore!='' ">
                          <ng-container
                            *ngIf="DashboardData.Onshore!=null && DashboardData.LocationOffshore!=null && DashboardData.Nearshore!=null ">
                            <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                              (rangeClick)="onLocationClick($event)" [selectedtab]="selectedTab" [selectedButton]="LocVal"></range-slider>
                          </ng-container>
                          </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedTab=='Pyramid'">
                      <div class="row small-gutter p-2">
                        <div class="mb-2">
                          <label for="customRange3" class="form-label">Select the Pyramid for the Locations
                            selected:</label> <span *ngIf="pyrinfoIconDisabled"><img
                              src="../../../assets/images/information-Icons.svg" alt="information icon"
                              (click)="onPyrIconClick()"></span>
                          <p class="text-bold">Onshore:</p>

                          <ng-container *ngIf="DashboardData.Onshore!=null && DashboardData.Onshore!=''">
                            <ng-container *ngIf="DashboardData.Onshore=='US'">
                              <ng-container *ngIf="DashboardData.City!='' && DashboardData.City!=null">
                                <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                                  (rangeClick)="onPyramidClick($event,'ONSHORE')" [selectedtab]="selectedTab" [selectedButton]="PyronshoreVal"></range-slider>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="DashboardData.Onshore!='US'">
                              <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                                (rangeClick)="onPyramidClick($event,'ONSHORE')" [selectedtab]="selectedTab" [selectedButton]="PyronshoreVal"></range-slider>
                            </ng-container>
                          </ng-container>
                         <!--  <div *ngIf="IsPyrOnsCustomSelected">
                            <div class="text-start  mb-2 d-flex ">
                              <ng-container *ngFor="let el of PyramidOnsArray;">
                               <p class="pe-2 text-bold">{{el.Name}}</p>
                                    <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                       [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                   
                               </ng-container>                          
                            </div>                          
                          </div> -->
                        </div>
                        <div class="mb-2">
                          <p class="text-bold">Offshore:</p>
                          <ng-container
                            *ngIf="DashboardData.LocationOffshore!=null && DashboardData.LocationOffshore!='' ">
                            <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                              (rangeClick)="onPyramidClick($event,'OFFSHORE')" [selectedtab]="selectedTab" [selectedButton]="PyroffshoreVal"></range-slider>
                          </ng-container>
                          <!-- <div *ngIf="IsPyrOffCustomSelected">
                            <div class="text-start  mb-2 d-flex ">
                              <ng-container *ngFor="let el of PyramidOffArray;">
                               <p class="pe-2 text-bold">{{el.Name}}</p>
                                    <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                       [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                   
                               </ng-container>                          
                            </div>                          
                          </div> -->
                        </div>
                        <div class="mb-0">
                          <p class="text-bold">Nearshore:</p>
                          <ng-container *ngIf="DashboardData.Nearshore!=null && DashboardData.Nearshore!='' ">
                            <range-slider [min]="0" [max]="4" [rangeList]="rangeList"
                              (rangeClick)="onPyramidClick($event,'NEARSHORE')" [selectedtab]="selectedTab" [selectedButton]="PyrnearshoreVal"></range-slider>
                          </ng-container>
                        <!--   <div *ngIf="IsPyrNearshCustomSelected">
                            <div class="text-start  mb-2 d-flex ">
                              <ng-container *ngFor="let el of PyramidNearshArray;">
                               <p class="pe-2 text-bold">{{el.Name}}</p>
                                    <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                       [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                   
                               </ng-container>                          
                            </div>                          
                          </div> -->
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedTab=='Margin'">
                      <div class="row small-gutter p-2">
                        <div class="mb-0">
                          <p class="text-bold">Onshore:</p>
                          <ng-container *ngIf="DashboardData.Onshore!=null && DashboardData.Onshore!='' ">


                            <ng-container *ngIf="DashboardData.Onshore=='US'">

                              <ng-container *ngIf="DashboardData.City!='' && DashboardData.City!=null">
                                <range-slider [min]="0" [max]="3"  [selectedtab]="selectedTab" [rangeList]="rangeList | filterBy: {UserDef4: 'ONSH'}"
                                  (rangeClick)="onMarginClick($event,'ONSHORE')" [selectedButton]="MaronshoreVal"  onkeydown="return false;" ></range-slider>
                              </ng-container>
                            </ng-container>


                            <ng-container *ngIf="DashboardData.Onshore!='US'">
                              <range-slider [min]="0" [max]="3" [selectedtab]="selectedTab" [rangeList]="rangeList | filterBy: {UserDef4: 'ONSH'}"
                                (rangeClick)="onMarginClick($event,'ONSHORE')" [selectedButton]="MaronshoreVal"  onkeydown="return false;" ></range-slider>
                            </ng-container>
                          </ng-container>

                        </div>
                        <div *ngIf="IsOnShoreCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                            <ng-container *ngFor="let el of MarginOnsArray;">
                             <p class="pe-2 text-bold">{{el.Name}}</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                     [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />                                 
                             </ng-container>                          
                          </div>
                         <!--  <div class="text-end  mb-0">
                            <input type="number" value="" [(ngModel)]="odata.Mrg_Ons_Custom" id="custom-mos-id"
                              (change)="CustomValueChange($event)" />
                          </div> -->
                        </div>
                        <div class="mb-0">
                          <p class="text-bold">Offshore:</p>
                          <ng-container
                            *ngIf="DashboardData.LocationOffshore!=null && DashboardData.LocationOffshore!='' ">
                            <range-slider [min]="0" [max]="3" [selectedtab]="selectedTab" [rangeList]="rangeList  | filterBy: {UserDef4: 'OFFSH'}"
                              (rangeClick)="onMarginClick($event,'OFFSHORE')" [selectedButton]="MaroffshoreVal"></range-slider>
                          </ng-container>
                        </div>
                        <div *ngIf="IsOffshoreCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                            <ng-container *ngFor="let el of MarginOffArray;">
                             <p class="pe-2 text-bold">{{el.Name}}</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                     [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                 
                             </ng-container>                          
                          </div>
                          <!-- <div class="text-end  mb-0">
                            <input type="number" value="" [(ngModel)]="odata.Mrg_Off_Custom" id="custom-moffs-id"
                              (change)="CustomValueChange($event)" />
                          </div> -->
                        </div>
                        <div class="mb-0">
                          <p class="text-bold">Nearshore:</p>
                          <ng-container *ngIf="DashboardData.Nearshore!=null && DashboardData.Nearshore!='' ">
                            <range-slider [min]="0" [max]="3" [selectedtab]="selectedTab" [rangeList]="rangeList  | filterBy: {UserDef4: 'NEARSH'}"
                              (rangeClick)="onMarginClick($event,'NEARSHORE')" [selectedButton]="MarnearshoreVal"></range-slider>
                          </ng-container>
                        </div>
                        <div *ngIf="IsNearshoreCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                            <ng-container *ngFor="let el of MarginNearArray;">
                             <p class="pe-2 text-bold">{{el.Name}}</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                     [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                 
                             </ng-container>                          
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="selectedTab=='Others'">
                      <div class="row small-gutter p-2">
                        <div class="mb-0">
                          <p class="text-bold">Transition Cost (% of Year 1 Revenue):
                            <input name="Transition_Billability" ng-control="options" type="radio" [value]="0" [(ngModel)]="odata.Transition_Billability" (change)="getSummaryGraphData(odata)"> Billable
                            <input name="Transition_Billability" ng-control="options" type="radio" [value]="1" [(ngModel)]="odata.Transition_Billability" (change)="getSummaryGraphData(odata)"> Non-Billable
                          </p>
                          <range-slider [min]="0" [max]="5" [rangeList]="rangeList | filterBy: {UserDef4: 'TR001'}"
                            (rangeClick)="onOthersClick($event,'TRANSITION')" [selectedtab]="selectedTab" [selectedButton]="transtionVal"></range-slider>
                        </div>
                        <div *ngIf="IstransCustomSelected">
                          <div class="text-end  mb-2 d-flex ">                            
                             <p class="pe-2 text-bold">Y1</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="customid"
                                     [(ngModel)]="odata.Trn_Custom" (change)="CustomValueChange($event)" placeholder="Enter value" />                                           
                          </div>                          
                        </div>
                        <div class="mb-0">
                          <p class="text-bold">Governance:
                            <input name="Governance_Billability" ng-control="options" type="radio" [value]="0" [(ngModel)]="odata.Governance_Billability" (change)="getSummaryGraphData(odata)"> Billable
                            <input name="Governance_Billability" ng-control="options" type="radio" [value]="1" [(ngModel)]="odata.Governance_Billability" (change)="getSummaryGraphData(odata)"> Non-Billable
                          </p>
                          <range-slider [min]="0" [max]="5" [rangeList]="rangeList  | filterBy: {UserDef4: 'GR001'}"
                            (rangeClick)="onOthersClick($event,'GOVERNANCE')" [selectedtab]="selectedTab" [selectedButton]="governanceVal"></range-slider>
                        </div>
                        <div *ngIf="IsgovCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                            <ng-container *ngFor="let el of GovArray;">
                             <p class="pe-2 text-bold">{{el.Name}}</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                     [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />
                                 
                             </ng-container>                          
                          </div>
                        </div>
                        <div class="mb-0">
                          <p class="text-bold">Other Expenses:
                            <input name="OtherExpenses_Billability" ng-control="options" type="radio" [value]="0" [(ngModel)]="odata.OtherExpenses_Billability" (change)="getSummaryGraphData(odata)"> Billable
                            <input name="OtherExpenses_Billability" ng-control="options" type="radio" [value]="1" [(ngModel)]="odata.OtherExpenses_Billability" (change)="getSummaryGraphData(odata)"> Non-Billable
                          </p>
                          <range-slider [min]="0" [max]="5" [rangeList]="rangeList  | filterBy: {UserDef4: 'EX001'}"
                            (rangeClick)="onOthersClick($event,'OTHERS')" [selectedtab]="selectedTab" [selectedButton]="othersVal"></range-slider>
                        </div>
                        <div *ngIf="IsexpCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                            <ng-container *ngFor="let el of ExpArray;">
                             <p class="pe-2 text-bold">{{el.Name}}</p>
                                  <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                     [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />                                 
                             </ng-container>                          
                          </div>
                        </div>
                        <div class="mb-0">
                          <p class="text-bold">Contingency Cost:</p>
                          <range-slider [min]="0" [max]="5" [rangeList]="rangeList  | filterBy: {UserDef4: 'CO001'}"
                            (rangeClick)="onOthersClick($event,'CONTINGENCY')" [selectedtab]="selectedTab" [selectedButton]="contingencyVal"></range-slider>
                        </div>
                        <div *ngIf="IsConCustomSelected">
                          <div class="text-start  mb-2 d-flex ">
                          <ng-container *ngFor="let el of ConArray;">
                            <p class="pe-2 text-bold">{{el.Name}}</p>
                                 <input type="number" class="form-control form-control-sm me-2" value="" id="el.id"
                                    [(ngModel)]="el.value" (change)="CustomValueChange(el)" placeholder="Enter value" />                                 
                            </ng-container> 
                          </div> 
                        </div>

                        <div class="row small-gutter p-1">
                          <ng-container
                            *ngIf="(OtherExpenseTemp != null && OtherExpenseTemp != undefined && OtherExpenseTemp.length > 0)">
                            <customform #LiteDashboardForm [FormTemplate]="OtherExpenseTemp" [FormData]="DashboardData"
                              [FormProp]="FormProp" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                              (FieldClick)="onClick($event)" (FieldChange)="onGeneralInfoChange($event)">
                            </customform>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="selectedTab=='Summary'">
                      <div class="row small-gutter p-2">
                        <p class="mb-2 mt-1">
                          <span>Summary of Solution Parameters selected:</span>
                        </p>
                        <div class="gridContainer mb-2">
                          <div class="table-responsive">
                            <table class="table table-hover table-bordered tblGrid fixed">
                              <thead>
                                <tr>
                                  <th>Productivity</th>
                                  <td>{{prodVal}}</td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                        <ng-container
                          *ngIf="(summaryTemplate1 != null && summaryTemplate1 != undefined && summaryTemplate1.length > 0)">
                          <grid-dispctrl #summaryGrid [FieldTemplate]="summaryTemplate1" [IsPagination]="IsPagination"
                            [GridData]="summaryData1" [GridProp]="GridProp" [GridDataTotalCount]="datalength"
                            (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [parent]="this">
                          </grid-dispctrl>
                        </ng-container>
                        <ng-container
                          *ngIf="(summaryTemplate2 != null && summaryTemplate2 != undefined && summaryTemplate2.length > 0)">
                          <div class="mt-2">
                            <grid-dispctrl #summaryGrid [FieldTemplate]="summaryTemplate2" [IsPagination]="IsPagination"
                              [GridData]="summaryData2" [GridProp]="GridProp" [GridDataTotalCount]="summarylength"
                              (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [parent]="this">
                            </grid-dispctrl>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="(summaryTemplate3 != null && summaryTemplate3 != undefined && summaryTemplate3.length > 0)">
                          <div class="mt-2">
                            <grid-dispctrl #summaryGrid [FieldTemplate]="summaryTemplate3" [IsPagination]="IsPagination"
                              [GridData]="summaryData3" [GridProp]="GridProp" [GridDataTotalCount]="summarylength"
                              (FieldClick)="onClick($event)" (FieldChange)="onChange($event)" [parent]="this">
                            </grid-dispctrl>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>




                  </ng-container>
                </ng-container>
              </tabset>
            </ng-container>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<modal-popup id="locationmixpopup" style="display: none;" [modaldetail]="modalInf">

  <ng-container
    *ngIf="(locationMixInfoTemplate != null && locationMixInfoTemplate != undefined && locationMixInfoTemplate.length > 0)">
    <grid-dispctrl #locInfoGrid [FieldTemplate]="locationMixInfoTemplate" [GridData]="locationMixInfoDetail"
      [GridDataTotalCount]="locMixLength" [parent]="this">
    </grid-dispctrl>
  </ng-container>

</modal-popup>

<modal-popup id="pyramidInfopopup" style="display: none;" [modaldetail]="modalInf">

  <ng-container
    *ngIf="(pyramidInfoTemplate != null && pyramidInfoTemplate != undefined && pyramidInfoTemplate.length > 0)">
    <grid-dispctrl #locInfoGrid [FieldTemplate]="pyramidInfoTemplate" [GridData]="pyramidInfoDetail"
      [GridDataTotalCount]="pyrMixLength" [parent]="this">
    </grid-dispctrl>
  </ng-container>

</modal-popup>

<modal-popup id="LocCustomPopUp" style="display: none;" [modaldetail]="modalInf">
  <div class="gridContainer mb-3 mt-3">
    <div class="table-responsive">
      <table class="table table-hover table-bordered tblGrid fixed">
         <thead>
          <tr>
            <th>Parameter</th>
            <th>Shore</th>
            <ng-container *ngFor="let el of locYears;">
              <th>{{el}} (%)</th>
            </ng-container>           
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="IsLoconshore">
            <td>Custom</td>
            <td>Onsite</td>
            <ng-container *ngFor="let el of locOnsCustom;">
              <td>
                <input type="number" class="form-control form-control-sm" value="" id="el.id"
                [(ngModel)]="el.value" (change)="onChange(el)" />
              </td>
            </ng-container> 
          </tr>
          <tr *ngIf="IsLocoffshore">
            <td>Custom</td>
            <td>Offshore</td>
            <ng-container *ngFor="let el of locOffCustom;">
              <td>
                <input type="number" class="form-control form-control-sm" value="" id="el.id"
                [(ngModel)]="el.value" (change)="onChange(el)" />
              </td>
            </ng-container>
          </tr>
          <tr *ngIf="IsLocnearshore">
            <td>Custom</td>
            <td>Nearshore</td>
            <ng-container *ngFor="let el of locNearCustom;">
              <td>
                <input type="number" class="form-control form-control-sm" value="" id="el.id"
                [(ngModel)]="el.value" (change)="onChange(el)"/>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="">
   <!--  <div class="d-flex justify-content-end p-2">
        <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="CloseCustom()" value="">
            OK
        </button>      
    </div> -->
    <div class="d-flex">
      <img src="../../../assets/images/information-Icons.svg" alt="information icon" class="pe-1">
      <p class="align-self-center">Close the popup to update the data.</p>
    </div>
</div>

</modal-popup>

<modal-popup id="inflationpopup" style="display: none;" [modaldetail]="modalInf">
  <div class="gridContainer mb-3 mt-3">
    <div class="table-responsive">
      <table class="table table-hover table-bordered tblGrid fixed">
         <thead>
          <tr>
            <th></th>
            <th colspan="2">Onshore</th>
            <th colspan="2">Offshore</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let el of Inflation;">
         <tr>
            <th>{{el[0]}}</th>             
                <td>
             <input type="number" class="form-control form-control-sm" value="" id="el[1].id"
                [(ngModel)]="el[1].value" (change)="onChange(el[1])" placeholder="Enter value" />
              </td>
                <td>
              <span>%Annual</span>
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm" value="" id="el[2].id"
                     [(ngModel)]="el[2].value" (change)="onChange(el[2])" placeholder="Enter value" />
                   </td>
                     <td>
                   <span>%Annual</span>
                     </td>             
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="">
    <!-- <div class="d-flex justify-content-end p-2">
        <button type="button" class="rounded-pill btnCustom btnPrimary" (click)="CloseInflation()" value="">
            OK
        </button>      
    </div> -->
    <div class="d-flex">
      <img src="../../../assets/images/information-Icons.svg" alt="information icon" class="pe-1">
      <p class="align-self-center">Close the popup to update the data.</p>
    </div>
</div>

</modal-popup>

<div *ngIf="displayNotifybox">
  <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action" [curitem]="notify.item"
    (close)="closeNotifybox()"></notification>
</div>
