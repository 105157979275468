import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation,
  OnChanges, SimpleChanges, SimpleChange, AfterViewInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../shared/_services/utility.service';
import { RowLockStatus, SCREEN_SIZE } from '../../app/app.interface';
import {
  ActionBarConfig,
  FieldTemplate, GridConfig, GridConst, GridProperty, NestedTemplate,
  Pagination, PaginationConfig
} from '../shared/_classes/utility.interface';
// import { ElioScopeService, ElioUtilityService} from 'fieldcontrol-lib';
import { ElioScopeService } from '../shared/_services/ElioScope.service';
import { UtilityService } from '../shared/_services/utility.service';

import { FilterPipe } from 'ngx-filter-pipe';
import { OrderByPipe, LocalizedDatePipe, LocalizedNumberPipe } from '../custompipe/custompipe.module';
import { ResizeService } from 'src/app/core/screen-size-detector/resize.service';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'grid-readonly',
  templateUrl: './grid-readonly.html',
  encapsulation: ViewEncapsulation.None,
  providers: [OrderByPipe]
})

export class GridReadonlyComponent implements OnInit, AfterViewInit {
  // export class GridComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() FieldTemplate: Array<FieldTemplate> = [];
  @Input() GridData: any;
  @Input() GridDataTotalCount: number = 0;
  @Input() GridProp: GridProperty = {};
  @Input() GridConfig: GridConfig = {};
  // @Input('IsParallelEditEnabled') IsParallelEditEnabled: boolean = false;
  @Input() GlobalConfig: any = {};
  @Input() NestedTemplate?: NestedTemplate;
  // @Input('cellLock') cellLock: Function;
  // @Input('cellRequired') cellRequired: Function;
  @Input() CustomCss!: Function;
  @Input() parent: any;

  @Output() FieldClick = new EventEmitter<any>();
  @Output() FieldChange = new EventEmitter<any>();
  // @Output() FieldEditStart = new EventEmitter<any>();
  @Output() PageChange = new EventEmitter<any>();
  @Output() public ActionBarClick = new EventEmitter<any>();
  // Grid properties
  // Default values
  public TableCSS: string = '';
  public HeaderCSS: string = '';
  public AlternateRowColor: any = { odd: 'white', even: 'white' };
  public IsEditable: boolean = true;
  public DisplayHeader: boolean = true;
  public TableWidth: number = 100;
  public PaginationConfig: PaginationConfig = {
    Required: true,
    ItemsPerPage: GridConst.DEFAULTITEMSPERPAGE,
    PageList: false,
    ServerLoad: false
  };
  public IsSortable: boolean = true;
  public IsColResizable: boolean = true;

  public DisplayColumns: string = '';
  public GridTotalWidth: number = 0;
  public selectedCell: string = '';
  public prevSelectedCell: string = '';

  public IsNested: boolean = false;
  public ParentField: string = '';
  public FilterField: string = '';
  public selectedNest: any[] = [];

  public DataFilter: any = {};

  public Pagination: Pagination = {
    ItemsPerPage: GridConst.DEFAULTITEMSPERPAGE,
    CurrentPage: 1,
    TotalItems: 0,
    TotalPages: 0
  };
  public ActionBarConfig: ActionBarConfig = {
    ActionBarList: null,
    noActionClass: false
  };
  public dispFilt: boolean = false;

  public editMode: boolean = false;
  public currCell!: HTMLTableDataCellElement;

  public datacellindx = 0;
  public sortdirection: number = -1;
  public sortcolumn: string = '';
  public sortcolumntype: string = '';
  public isDesc: boolean = false;

  public currentDataList: any;

  public thElm: any;
  public startOffset: any;

  public screenSize!: SCREEN_SIZE;
  public curItemsPerPage: number = 10;
  public pagesPerchange?: any;

  public gridRandomId: string = '';
  

  constructor(private _elioScopeService: ElioScopeService, private _elioUtilityService: UtilityService,
    public translate: TranslateService, private eleRef: ElementRef,
    private filterByPipe: FilterPipe, private orderByPipe: OrderByPipe,
    private dp: LocalizedDatePipe, private np: LocalizedNumberPipe,
    public sanitizer: DomSanitizer, private resizeSvc: ResizeService) {

    this.gridRandomId = Math.random().toString(36).substr(2, 5);

    // Subscription for Resize 
    this.resizeSvc.onResize$
      .pipe(delay(0))
      .subscribe(x => {
        this.screenSize = x;
      });

    // To initialise screenSize
    window.dispatchEvent(new Event('resize'));

  }

  ngOnInit() {

    if (this.GridProp !== undefined) {
      this.TableCSS = (this.GridProp.TableCSS === undefined ? this.TableCSS : this.GridProp.TableCSS);
      this.HeaderCSS = (this.GridProp.HeaderCSS === undefined ? this.HeaderCSS : this.GridProp.HeaderCSS);
      this.AlternateRowColor = (this.GridProp.AlternateRowColor === undefined ? this.AlternateRowColor : this.GridProp.AlternateRowColor);
      this.IsEditable = (!isNullOrUndefined(this.GridProp.Editable) ? this.IsEditable : this.GridProp.Editable!);
      this.DisplayHeader = (this.GridProp.DisplayHeader === undefined ? this.DisplayHeader : this.GridProp.DisplayHeader);
      this.TableWidth = (this.GridProp.TableWidth === undefined ? this.TableWidth : this.GridProp.TableWidth);
      this.IsSortable = (this.GridProp.IsSortable === undefined ? this.IsSortable : this.GridProp.IsSortable);
      this.IsColResizable = (this.GridProp.IsColResizable === undefined ? this.IsColResizable : this.GridProp.IsColResizable);

      if (!isNullOrUndefined(this.GridProp.PaginationConfig)) {
        this.PaginationConfig.Required = (isNullOrUndefined(this.GridProp.PaginationConfig?.Required) ?
          this.PaginationConfig.Required : this.GridProp.PaginationConfig?.Required);
        this.PaginationConfig.ItemsPerPage = (isNullOrUndefined(this.GridProp.PaginationConfig?.ItemsPerPage) ?
          this.PaginationConfig.ItemsPerPage : this.GridProp.PaginationConfig?.ItemsPerPage);
        this.PaginationConfig.PageList = (isNullOrUndefined(this.GridProp.PaginationConfig?.PageList) ?
          this.PaginationConfig.PageList : this.GridProp.PaginationConfig?.PageList);
        this.PaginationConfig.ServerLoad = (isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) ?
          this.PaginationConfig.PageList : this.GridProp.PaginationConfig?.ServerLoad);
      }

      this.Pagination.ItemsPerPage = (this.PaginationConfig.Required && this.PaginationConfig.ItemsPerPage) ? this.PaginationConfig.ItemsPerPage : GridConst.DEFAULTITEMSPERPAGE;
      this.Pagination.CurrentPage = 1;
      if (!isNullOrUndefined(this.GridProp.ActionBarConfig)) {
        this.ActionBarConfig.ActionBarList = (isNullOrUndefined(this.GridProp.ActionBarConfig?.ActionBarList) ?
          this.ActionBarConfig.ActionBarList : this.GridProp.ActionBarConfig?.ActionBarList);
        this.ActionBarConfig.noActionClass = (isNullOrUndefined(this.GridProp.ActionBarConfig?.noActionClass) ?
          this.ActionBarConfig.noActionClass : this.GridProp.ActionBarConfig?.noActionClass);

      }

    }

    this.DisplayColumns = this.setDispcols();
    this.GridTotalWidth = this.getGridWidth();

    if (this.GridConfig !== undefined && this.GridConfig.BaseUrl !== undefined && this.GridConfig.BaseUrl !== null) {
      this._elioScopeService.setBaseURL(this.GridConfig.BaseUrl);
    }

    if (this.GlobalConfig !== undefined) {
      let GlobalKeys: Array<string> = [];
      // Object.entries(this.GlobalConfig).forEach(
      //   ([key, value]) => {console.log(key, value); GlobalKeys.push(key); }
      // );
      for (let key in this.GlobalConfig) { GlobalKeys.push(key); }

      this._elioUtilityService.PreProcessTemplate(this.FieldTemplate, GlobalKeys, this.GlobalConfig);
    }

    if (!isNullOrUndefined(this.NestedTemplate)) {
      this.IsNested = true;
      this.ParentField = this.NestedTemplate!.ParentFieldName;
      this.FilterField = this.NestedTemplate!.FilterFieldName;

      this.DataFilter[this.FilterField] = { '$or': [0, null, ''] };

    }

    // if (this.IsParallelEditEnabled) {
    //   this.datacellindx += 1;
    // }
    if (this.IsNested) {
      this.datacellindx += 1;
    }

    this.getCurrentDataList();

  }

  ngAfterViewInit() {
    // Enabling column resize in Grid
    if (this.IsColResizable) {
      let allthElms: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll('table tr th');
      if (!isNullOrUndefined(allthElms)) {
        allthElms.forEach((th) => {

          // Column resize property added as part of FieldTemplate. If no property defined, then default is true.
          if (th.dataset.resize?.toString() === 'true') {
            // if (!(th.classList.contains('ParallelEditHeader') || th.classList.contains('NestedHeader'))) {
            th.style.position = 'relative';

            let grip: HTMLDivElement = document.createElement('div');
            grip.classList.add('tbl-column-resizer');

            grip.addEventListener('mousedown', (e) => {
              this.thElm = th;
              this.startOffset = th.offsetWidth - e.pageX;

              // make grid cell non-editable when resizing any column
              if (this.editMode === true && this.selectedCell.length > 0) {
                let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
                let currCell: HTMLTableCellElement = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_')!;

                let x = currCell.querySelector('div.reseteditdiv') as HTMLElement;
                if (!isNullOrUndefined(x)) {
                  x.click();
                  currCell.focus();
                }
              }

              this.preventEvent(e);
            });

            grip.addEventListener('click', (e) => {
              this.preventEvent(e);
            });

            th.appendChild(grip);
            // }

          }

        });

        document.addEventListener('mousemove', (e) => {
          if (!isNullOrUndefined(this.thElm)) {
            let colwidth = this.startOffset + e.pageX;

            // set column minwidth if applicable
            let colminwidth = parseInt(this.thElm.style.minWidth) || 0;
            if (colminwidth > colwidth) { colwidth = colminwidth; }

            let tablele: HTMLDivElement = this.eleRef.nativeElement.querySelector('table');
            if (!isNullOrUndefined(tablele)) {
              colwidth = colwidth / tablele.offsetWidth * 100;
            }

            // this.thElm.style.width = this.startOffset + e.pageX + 'px';
            this.thElm.style.width = colwidth + '%';

            this.preventEvent(e);
          }
        });

        document.addEventListener('mouseup', (e) => {
          this.thElm = undefined;

          this.preventEvent(e);
        });

      }

    }

  }

  preventEvent(e: any) {
    let ev = e || window.event;

    if (ev.stopPropagation) {
      ev.stopPropagation();
    }

    if (ev.preventDefault) {
      ev.preventDefault();
    }

    return false;
  }


  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('changes >> ');
  //   console.log(changes);
  //
  //   if (!isNullOrUndefined(changes.GridData)) {
  //     this.getCurrentDataList();
  //   }
  // }

  // Special handling of Grid data for Milkyway as the URL string is passed for loading data
  // isValidDataType(): boolean {
  //   let flag: boolean = false;

  //   if (!isNullOrUndefined(this.GridData) && typeof (this.GridData) === 'object' && this.GridData.length > 0) {
  //     flag = true;
  //   }

  //   return flag;
  // }

  // ReloadGrid(): void {
  //   this.ngOnInit();
  // }

  getColumnCount(): number {
    let colCnt = 0;
    let allthElms: HTMLHeadElement[] = this.eleRef.nativeElement.querySelector('table thead tr').querySelectorAll('th:not(.d-none)');
    if (!isNullOrUndefined(allthElms)) {
      colCnt = allthElms.length;
    }

    return colCnt;
  }

  setDispcols(): string {
    let dispcols: string = '';
    for (let i = 0; i < Object.keys(this.FieldTemplate).length; i++) {
      if (!this.FieldTemplate[i].IsHidden) {
        dispcols = dispcols + ',' + this.FieldTemplate[i].FieldName;
      }
    }
    return dispcols.length > 0 ? dispcols + ',' : '';
  }

  getGridWidth(): number {
    let nTotalWidth: number = 0;
    for (let i = 0; i < Object.keys(this.FieldTemplate).length; i++) {
      if (this.DisplayColumns.indexOf(',' + this.FieldTemplate[i].FieldName + ',') >= 0) {
        nTotalWidth += parseFloat(this.FieldTemplate[i].ColumnWidth);
      }
    }
    return nTotalWidth;
  }

  // showGridCol(FieldName: string): boolean {
  //   return this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0 ? true : false;
  // }

  // setGridColWidth(FieldName: string, ColumnWidth: number): string {
  setGridColWidth(FieldName: string, ColumnWidth: any): string {
    let width = this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0 ? ColumnWidth / this.GridTotalWidth * 100 : 0;
    return width.toString() + '%';

    // // Convert the width in percentage to px
    // let tblele: HTMLTableElement = this.eleRef.nativeElement.querySelector('table');
    // let tblwidth = 0;

    // if (!isNullOrUndefined(tblele)) {
    //   tblwidth = tblele.offsetWidth;
    // }

    // width = (tblwidth * (width / 100));

    // return width.toString() + 'px';
  }

  // setTileHeight(): number {
  //   let otherHt = 56 + 15 + 36 + 7;
  //   return 290 + 20;
  // }

  // fldonEditStart(event): void {
  //   if (this.IsParallelEditEnabled) {
  //     this.FieldEditStart.emit(event);
  //   }
  // }

  // fldOnClick(event): void {
  //   this.FieldClick.emit(event);
  // }

  // fldOnChange(event): void {
  //   this.FieldChange.emit({ fieldname: event.fieldname, item: event.item });
  // }

  // cellOnClick(item, key, item_indx, key_indx, event): void {
  //    this.selectedCell = item_indx + '-' + key_indx;
  // }

  // cellOnFocusOut(): void { };

  // setSelectedCell(cell): void {

  //   console.log('prev cell > ' + this.selectedCell);

  //   this.prevSelectedCell = this.selectedCell;

  //   if (cell !== undefined || cell !== null) {

  //     // different cell, then reset edit mode
  //     if (this.editMode && this.selectedCell !== (cell.row + '-' + cell.col)) {
  //       // this.editMode = false;

  //       this.resetPrevCell();
  //     }

  //     this.selectedCell = cell.row + '-' + cell.col;

  //   }
  //   else {
  //     // this.editMode = false;
  //     this.selectedCell = '';
  //   }

  //   console.log('now cell > ' + this.selectedCell);
  // }

  // SetBackGroundColor(cell: any): void {

  // }

  setDispNested(bExpand: boolean, id: any): void {
    if (bExpand) {
      this.selectedNest.push(id);
    }
    else {
      let indx = this.selectedNest.indexOf(id);
      if (indx >= 0) { this.selectedNest.splice(indx, 1); }
    }
  }

  getNestedData(value: any): any {
    let NestedDataFilter: any = {};

    NestedDataFilter[this.FilterField] = value;

    return NestedDataFilter;
  }

  // getReadOnly(key: FieldTemplate, item: any): boolean {
  //   let bFlag = false; /// defaulting to editable

  //   if (!this.IsEditable) {
  //     // If grid is not editable, then set readonly true.
  //     bFlag = true;
  //   }
  //   else {
  //     bFlag = !key.Editable;

  //     if (!bFlag && !isNullOrUndefined(key.DataCell) && !isNullOrUndefined(key.DataCell.CustomLock) && this.cellLock !== undefined) {
  //       bFlag = this.cellLock(key.FieldName, item, this.parent);
  //     }

  //     // if (!bFlag && !isNullOrUndefined(item['CellLock'])) {
  //     //   let temp = item['CellLock'].filter(x=> x.cell === key.FieldName);
  //     //   if (temp.length > 0) {
  //     //     bFlag = temp[0].lock;
  //     //   }
  //     // }

  //     /// Checking parallel edit access and not locked by user
  //     if (!bFlag && !isNullOrUndefined(item.RowLockStatus) && item.RowLockStatus === RowLockStatus.Locked) {
  //       bFlag = true; /// reset as non editable
  //     }
  //     // if (!bFlag && !isNullOrUndefined(item.DataRowlock) && item.DataRowlock.RowLockStatus === RowLockStatus.Locked) {
  //     //   bFlag = true; /// reset as non editable
  //     // }
  //     // if (!this.IsEditable) {
  //     //   bFlag = true;
  //     // }
  //   }

  //   return bFlag;
  // }

  // getRequired(key: FieldTemplate, item: any): boolean {
  //   let bFlag = false;

  //   // ReadOnly has higher precedence over Required
  //   if (!this.getReadOnly(key:any, item:any)) {
  //     bFlag = (key.EditValidate.Required && (isNullOrUndefined(item[key.FieldName]) || item[key.FieldName].length === 0));

  //     if (!bFlag && (!isNullOrUndefined(key.EditValidate.ConditionalRequired)) && key.EditValidate.ConditionalRequired &&
  //       !isNullOrUndefined(this.cellRequired)) {

  //       bFlag = this.cellRequired(key.FieldName, item, this.parent);

  //     }

  //   }

  //   return bFlag;
  // }

  // getCustomClass(key: FieldTemplate, item: any): string {
  //   let customclass: string = '';
  //   if (!isNullOrUndefined(this.CustomCss)) {
  //     customclass = this.CustomCss(key.FieldName, item, this.parent);
  //   }

  //   return customclass;
  // }

  pageChanged(event: any): void {
    console.log('pagechanged evnt');
    console.log(event.page);

    this.Pagination.CurrentPage = event.page;

    this.prevSelectedCell = '';
    this.selectedCell = '';
    if (this.PaginationConfig.ServerLoad) {
      this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
    }
  }

  totalPagesChanged(event: number): void {
    let prevPages = this.Pagination.TotalPages;
    let curPage = this.Pagination.CurrentPage;

    this.Pagination.TotalPages = event;
  }

  resetPrevCell() {

    // if (this.prevSelectedCell !== this.selectedCell) {
    if (this.prevSelectedCell.length > 0) {
      // let s = this.prevSelectedCell.split('-');
      // let item_indx = parseInt(s[0]);
      // let key_indx = parseInt(s[1]);

      let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
      // let prevCell = table.children[item_indx].children[key_indx + this.datacellindx] as HTMLTableDataCellElement;
      let prevCell: any = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.prevSelectedCell + '_');

      let x = prevCell.querySelector('div.reseteditdiv') as HTMLElement;

      if (!isNullOrUndefined(x)) {
        x.click();
      }

    }
  }

  gridOnblur() {
    if (!isNullOrUndefined(this.currCell)) {
      this.cellKey({ keyCode: 27 });
    }
  }

  cellClick(event: any, item_indx: any, key_indx: number) {
    console.log('td click event row=' + item_indx + '; col=' + key_indx);
    this.prevSelectedCell = this.selectedCell;
    this.selectedCell = item_indx + '-' + key_indx;

    // event.preventDefault();

    console.log('prevSelectedCell=' + this.prevSelectedCell);
    console.log('selectedCell=' + this.selectedCell);

    if (this.selectedCell !== this.prevSelectedCell) {
      this.resetPrevCell();

      let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
      // this.currCell = table.children[item_indx].children[key_indx + this.datacellindx] as HTMLTableDataCellElement;
      this.currCell = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_') as HTMLTableDataCellElement;

      this.cellDblClick(event, item_indx, key_indx);
    }
    else {

      // // Patch fix to prevent refresh while showing dropdown list
      // if (this.FieldTemplate[key_indx].Control.Type === 'dropdown') {
      //   event.stopPropagation();
      //   event.preventDefault();
      // }

      // Same cell but not in editable, then make it editable
      if (this.editMode === false) {
        let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
        this.currCell = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_') as HTMLTableDataCellElement;

        this.cellDblClick(event, item_indx, key_indx);
      }
      // else {
      //   // Patch fix to prevent refresh while showing dropdown list
      //   if (this.FieldTemplate[key_indx].Control.Type === 'textbox'
      //         && this.FieldTemplate[key_indx].Control.InputType === 'number' ) {
      //     event.stopPropagation();
      //     event.preventDefault();
      //     event.stopImmediatePropagation();
      //   }
      // }
    }
  }

  cellDblClick(event: any, item_indx: any, key_indx: number) {
    console.log('td double click event row=' + item_indx + '; col=' + key_indx);
    // this.prevSelectedCell = this.selectedCell;
    // this.selectedCell = item_indx + '-' + key_indx;

    // if (this.selectedCell !== this.prevSelectedCell) {
    //   this.resetPrevCell();
    // }

    // let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
    // // this.currCell = table.children[item_indx].children[key_indx + this.datacellindx] as HTMLTableDataCellElement;
    // this.currCell = table.querySelector('#'+ this.gridRandomId +'_Cell_' + this.selectedCell + '_') as HTMLTableDataCellElement;

    let classlist: DOMTokenList = this.currCell.classList;
    // Parallel Edit cell or Nested cell
    if (classlist.contains('ParallelEdit') || classlist.contains('Nested')) {
      this.currCell.focus();
      return;
    }

    // Enter, Spacebar, F2 - edit cell
    if (this.IsEditable) {
      this.editMode = true;

      let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
      let ctrltype = fldctrl.dataset.ctrltype;

      // event.preventDefault();

      // let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
      // x.click();

      switch (ctrltype?.toLowerCase()) {
        case 'checkbox': {
          // let a = this.currCell.querySelector('input[type=checkbox]') as HTMLElement;
          // a.click();

          // // reset mode after click
          // this.editMode = false;

          break;
        }
        case 'datepicker': {
          let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
          x.click();

          // to show the datepicker pop-up
          setTimeout(() => {
            let b = this.currCell.querySelector('input.datepick') as HTMLElement;
            if (!isNullOrUndefined(b)) {
              b.click();
            }
          }, 50);

          break;
        }
        case 'icon': {
          // let e = this.currCell.querySelector('i.fa') as HTMLElement;
          // e.click();

          break;
        }
        case 'dropdown': {
          let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
          x.click();

          // // to show the dropdown list
          // setTimeout(() => {
          //   // let b = this.currCell as HTMLElement;
          //   // if (!isNullOrUndefined(b)) {
          //   //   b.click();
          //   // }

          //   // // Showing Dropdown list
          //   // // if (!isNullOrUndefined(this.key.Control) && this.key.Control.Type === 'dropdown') {
          //   //   // let t = this.hostElement.nativeElement.querySelector('select') as HTMLSelectElement;

          //   //   let t = this.currCell.querySelector('select') as HTMLSelectElement;

          //   //   if (!isNullOrUndefined(t)) {
          //   //     // t.size = (isNullOrUndefined(this.FieldTemplate[key_indx].Control.List) ?
          //   //     //    1 : this.FieldTemplate[key_indx].Control.List.length);
          //   //     t.dispatchEvent(new Event('mousedown'));
          //   //   }
          //   // // }

          //   this.cellKey({ keyCode: 32 });

          // }, 500);

          // // to set focus on the select's input
          // setTimeout(() => {
          //   let t = this.currCell.querySelector('input[type=text]') as HTMLElement;
          //   if (!isNullOrUndefined(t)) {
          //     t.focus();
          //   }
          // }, 50);

          break;
        }
        default: {
          let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
          x.click();

          break;
        }
      }
    }

    this.currCell.focus();
  }

  cellKey(event: any) {
    const TAB = 9;
    const ENTER = 13;
    const ESC = 27;
    const SPACEBAR = 32;
    const LEFT_ARROW = 37;
    const UP_ARROW = 38;
    const RIGHT_ARROW = 39;
    const DOWN_ARROW = 40;
    const F2 = 113;

    console.log('onkey event with code - ' + event.keyCode);
    console.log('key - ' + event.key);

    let newtd!: HTMLTableDataCellElement;
    let bfocus: boolean = true;

    switch (event.keyCode) {
      case ESC: {
        newtd = this.currCell as HTMLTableDataCellElement;

        this.editMode = false;
        let x = this.currCell.querySelector('div.reseteditdiv') as HTMLElement;
        x.click();

        break;
      }
      // case ENTER: {
      //   if (!isNullOrUndefined(event.shiftKey) && !event.shiftKey) {
      //     newtd = this.currCell as HTMLTableDataCellElement;

      //     this.editMode = false;
      //     let x = this.currCell.querySelector('div.reseteditdiv') as HTMLElement;
      //     x.click();
      //   }
      //   break;
      // }
      case RIGHT_ARROW: {
        if (!this.editMode) {
          newtd = this.currCell.nextElementSibling as HTMLTableDataCellElement;

          while (!isNullOrUndefined(newtd) && newtd.hasAttribute('hidden')) {
            newtd = newtd.nextElementSibling as HTMLTableDataCellElement;
          }
          if (!isNullOrUndefined(newtd)) {
            newtd.click();
          }
        }
        break;
      }
      case LEFT_ARROW: {
        if (!this.editMode) {
          newtd = this.currCell.previousElementSibling as HTMLTableDataCellElement;

          while (!isNullOrUndefined(newtd) && newtd.hasAttribute('hidden')) {
            newtd = newtd.previousElementSibling as HTMLTableDataCellElement;
          }
          if (!isNullOrUndefined(newtd)) {
            newtd.click();
          }
        }
        break;
      }
      case UP_ARROW: {
        if (!this.editMode) {
          let newtr = this.currCell.parentElement?.previousElementSibling as HTMLTableRowElement;

          if (!isNullOrUndefined(newtr) && !isNullOrUndefined(this.currCell.parentElement)) {

            while (newtr.hasAttribute('hidden')) {
              newtr = newtr.previousElementSibling as HTMLTableRowElement;
            }

            let cellindx = this.currCell.cellIndex;
            let delta = this.currCell.parentElement!.childElementCount - newtr.childElementCount;

            let tempindx = this.currCell.id.indexOf('--1_');
            if (tempindx >= 0) {
              // Required only for Nested cells
              while (delta !== 0) {
                newtr = newtr.previousElementSibling as HTMLTableRowElement;
                delta = this.currCell.parentElement!.childElementCount - newtr.childElementCount;
              }
            }
            else {
              if (!this.currCell.classList.contains('ParallelEdit')) {
                cellindx = (delta === 0 ? cellindx : (cellindx - delta));
              }
            }

            newtd = newtr.children[cellindx] as HTMLTableDataCellElement;

            if (!isNullOrUndefined(newtd)) {
              newtd.click();
            }
          }
        }
        break;
      }
      case DOWN_ARROW: {
        if (!this.editMode) {
          let newtr = this.currCell.parentElement?.nextElementSibling as HTMLTableRowElement;

          if (!isNullOrUndefined(newtr) && !isNullOrUndefined(this.currCell.parentElement)) {
            while (newtr.hasAttribute('hidden')) {
              newtr = newtr.nextElementSibling as HTMLTableRowElement;
            }

            let cellindx = this.currCell.cellIndex;

            if (this.currCell.classList.contains('Nested') || this.currCell.classList.contains('ParallelEdit')) {
              let i = this.currCell.rowSpan;
              while (i && i > 1) {
                newtr = newtr.nextElementSibling as HTMLTableRowElement;
                i--;
              }
            }
            else {
              let delta = this.currCell.parentElement!.childElementCount - newtr.childElementCount;
              cellindx = (delta === 0 ? cellindx : (cellindx - delta));
            }

            newtd = newtr.children[cellindx] as HTMLTableDataCellElement;

            if (!isNullOrUndefined(newtd)) {
              newtd.click();
            }
          }
        }
        break;
      }
      case SPACEBAR: {

        // console.log('inside spacebar');

        let classlist: DOMTokenList = this.currCell.classList;
        // Parallel Edit cell or Nested cell
        if (classlist.contains('ParallelEdit')) {
          // do nothing
          break;
        }

        // Nested cell
        if (classlist.contains('Nested')) {
          let w = this.currCell.querySelector('i.fa') as HTMLElement;
          if (!isNullOrUndefined(w)) {
            w.click();
          }

          this.currCell.focus();
          break;
        }

        // Other data cells
        if (this.IsEditable) {
          this.editMode = true;

          let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
          let ctrltype = fldctrl.dataset.ctrltype;

          switch (ctrltype?.toLowerCase()) {
            case 'checkbox': {
              let a = this.currCell.querySelector('input[type=checkbox]') as HTMLElement;
              a.click();

              break;
            }
            default: {
              break;
            }
          }

          // Special handling for icon-button
          let iconbutton = this.currCell.querySelector('icon-button div') as HTMLElement;
          if (!isNullOrUndefined(iconbutton)) {
            iconbutton.click();
          }

        }
        else {
          // let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
          // Special handling for icon-button
          let iconbutton = this.currCell.querySelector('icon-button div') as HTMLElement;
          if (!isNullOrUndefined(iconbutton)) {
            iconbutton.click();
          }

        }

        break;
      }
      case F2: {
        // F2, Spacebar - edit cell
        newtd = this.currCell as HTMLTableDataCellElement;

        let classlist: DOMTokenList = this.currCell.classList;
        // Parallel Edit - name display cell
        if (classlist.contains('ParallelEdit')) {
          // do nothing
          break;
        }

        // Nested cell
        if (classlist.contains('Nested')) {
          let w = this.currCell.querySelector('i.fa') as HTMLElement;
          if (!isNullOrUndefined(w)) {
            w.click();
          }

          this.currCell.focus();
          break;
        }

        // Other data cells
        if (this.IsEditable) {
          // if (!this.editMode) {
          this.editMode = true;

          let fldctrl = this.currCell.querySelector('field-control') as HTMLElement;
          let ctrltype = fldctrl.dataset.ctrltype;

          event.preventDefault();

          switch (ctrltype?.toLowerCase()) {
            case 'checkbox': {
              // let a = this.currCell.querySelector('input[type=checkbox]') as HTMLElement;
              // a.click();

              // // reset mode after click
              // this.editMode = false;

              break;
            }
            case 'datepicker': {
              let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
              x.click();

              // to show the datepicker pop-up
              setTimeout(() => {
                let b = this.currCell.querySelector('input.datepick') as HTMLElement;
                if (!isNullOrUndefined(b)) {
                  b.click();
                }
              }, 50);

              break;
            }
            case 'icon': {
              // let e = this.currCell.querySelector('i.fa') as HTMLElement;
              // e.click();

              break;
            }
            default: {
              let x = this.currCell.querySelector('div.seteditdiv') as HTMLElement;
              x.click();

              break;
            }
          }
          // }
          // else {
          //   // already in edit mode
          if (this.gridRandomId +'_Cell_' + this.selectedCell + '_' === newtd.id) {
            bfocus = false;
          }
          // }

        }

        break;
      }
      case TAB: {

        // if (this.editMode) {
        //   this.editMode = false;
        // }

        event.preventDefault();

        // this.prevSelectedCell = this.selectedCell;

        if (!event.shiftKey) {  // TAB
          newtd = this.currCell.nextElementSibling as HTMLTableDataCellElement;
          // this.selectedCell = newtd.tabIndex + '-' + (newtd.cellIndex - this.datacellindx);

          while (!isNullOrUndefined(newtd) && newtd.hasAttribute('hidden')) {
            newtd = newtd.nextElementSibling as HTMLTableDataCellElement;
          }

          // if no next column, then move to next row
          if (isNullOrUndefined(newtd)) {
            let newtr = this.currCell.parentElement?.nextElementSibling as HTMLTableRowElement;

            if (!isNullOrUndefined(newtr)) {
              newtd = newtr.firstElementChild as HTMLTableDataCellElement;
            }
            else {  // if no next row, then move to first row first column in grid
              newtr = this.currCell.parentElement?.parentElement?.firstElementChild as HTMLTableRowElement;

              if (!isNullOrUndefined(newtr)) {
                newtd = newtr.firstElementChild as HTMLTableDataCellElement;
              }
            }
          }

        }
        else {    // SHIFT + TAB
          newtd = this.currCell.previousElementSibling as HTMLTableDataCellElement;
          // this.selectedCell = newtd.tabIndex + '-' + (newtd.cellIndex - this.datacellindx);

          while (!isNullOrUndefined(newtd) && newtd.hasAttribute('hidden')) {
            newtd = newtd.previousElementSibling as HTMLTableDataCellElement;
          }

          // if no prev column, then move to prev row
          if (isNullOrUndefined(newtd)) {
            let newtr = this.currCell.parentElement?.previousElementSibling as HTMLTableRowElement;

            if (!isNullOrUndefined(newtr)) {
              newtd = newtr.lastElementChild as HTMLTableDataCellElement;
            }
            else { // if no prev row, then move to last row last column in grid
              newtr = this.currCell.parentElement?.parentElement?.lastElementChild as HTMLTableRowElement;

              if (!isNullOrUndefined(newtr)) {
                newtd = newtr.lastElementChild as HTMLTableDataCellElement;
              }
            }
          }

        }

        if (!isNullOrUndefined(newtd)) {

          // let tempid = newtd.id.replace(this.gridRandomId +'_Cell_', '');
          // tempid = tempid.replace('_', '');
          // this.selectedCell = tempid;

          // if (this.selectedCell !== this.prevSelectedCell) {
          //   this.resetPrevCell();
          // }

          newtd.click();
        }
        // }

        break;
      }
    }

    // If we didn't hit a boundary, update the current cell
    if (!isNullOrUndefined(newtd)) {
      this.currCell = newtd;

      if (bfocus) {
        this.currCell.focus();
      }
    }
  }

  setCellFocus() {
    let newtd = this.currCell as HTMLTableDataCellElement;

    if (!isNullOrUndefined(newtd)) {
      newtd.focus();
    }
  }

  // sort function
  // sort(property) {
  sort(key: FieldTemplate) {

    if (this.IsSortable) {
      // Don't allow to sort grid if cells as merged
      if ((this.FieldTemplate.filter(x => x.RowMerge === true)).length === 0) {

        let property: any = (!isNullOrUndefined(key.LinkedField) && key.LinkedField!.length > 0 ? key.LinkedField : key.FieldName);

        if (this.sortcolumn === property) {
          // Same column
          this.isDesc = !this.isDesc; // change the direction
        }
        else {
          this.sortcolumn = property;
          this.isDesc = false;
        }
        this.sortdirection = this.isDesc ? -1 : 1;

        // Available type
        // 'label'
        // 'textbox' => text, number
        // 'textarea'
        // 'dropdown'
        // 'typeahead'
        // 'link'
        // 'datepicker'
        // 'checkbox'
        // 'button'
        // 'icon'
        this.sortcolumntype = (key.Control?.Type === 'textbox' ? key.Control.InputType : key.Control?.Type) || '';
      }
    }

  }

  // Enabling Row Merge in Grid columns
  getCurrentDataList(): any {
    if (!isNullOrUndefined(this.GridData) && this.GridData.length > 0) {
      // PaginationConfig.ServerLoad? (GridData | orderBy:
      //       {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype}
      //     | filterBy: DataFilter | filterBy: {PageIndex: (Pagination.CurrentPage - 1)})
      //  : (GridData | orderBy:
      //       {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype}
      //     | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)
      //                             :(Pagination.CurrentPage*Pagination.ItemsPerPage))

      let orderedData = this.orderByPipe.transform(this.GridData,
        { property: this.sortcolumn, direction: this.sortdirection, ignorecase: 'true', type: this.sortcolumntype });

      let filteredData = this.filterByPipe.transform(orderedData, this.DataFilter);

      let currentData = filteredData;

      if (this.PaginationConfig.ServerLoad) {
        currentData = filteredData.filter((x: any) => x.PageIndex === (this.Pagination.CurrentPage - 1));
      }
      else {
        currentData = filteredData.slice((this.Pagination.CurrentPage - 1) * this.Pagination.ItemsPerPage,
          (this.Pagination.CurrentPage * this.Pagination.ItemsPerPage));
      }

      this.currentDataList = currentData;

      // console.log('currentDataList >> ' + JSON.stringify(this.currentDataList));
    }
    return this.currentDataList;
  }

  getRowSpan(key: FieldTemplate, item: any): number {
    let rowcount: number = 1;

    // let temprows = this.currentDataList.filter(x => x[key.FieldName] === item[key.FieldName]);
    let currentData = this.getCurrentDataList();
    let temprows = currentData.filter((x: any) => x[key.FieldName] === item[key.FieldName]);
    rowcount = temprows.length;

    return rowcount;
  }

  showMergeCell(key: FieldTemplate, item: any, item_indx: number): boolean {
    let bShow: boolean = true;

    let currentData = this.getCurrentDataList();
    // if (item_indx > 0 && this.currentDataList[item_indx - 1][key.FieldName] === item[key.FieldName]) {
    if (item_indx > 0 && currentData[item_indx - 1][key.FieldName] === item[key.FieldName]) {
      bShow = false;
    }

    return bShow;
  }


  parseURL(key: any, item: any): string {

    if (!isNullOrUndefined(key.Control) && !isNullOrUndefined(key.Control.Source) &&
      !isNullOrUndefined(key.Control.Source.Url)) {

      return this._elioUtilityService.ParsingURL(key.Control.Source.Url, key.Control.Source.UrlAttribute, item);
    }
    else {
      return '';
    }
  }

  getIconCSSClass(key: any, item: any): any {
    let iconclass: string = '';

    if (key.Control.IconList.length > 0) {
      let temp = key.Control.IconList.filter((x: any) => x.Value === item[key.FieldName]);

      if (temp.length > 0) {
        iconclass = temp[0].IconCSS;
      }
    }

    return iconclass;
  }

  getIconCSSClassList(key: any, item: any): any {
    let icondetail: any[] = [];

    if (!isNullOrUndefined(item[key.FieldName])) {
      let valuelist: string[] = (item[key.FieldName]).toString().split(',');

      // let valuearray: any[] = [];
      // valuelist.forEach(val => { valuearray.push({ Value: val }) });

      if (key.Control.IconList.length > 0) {
        icondetail = key.Control.IconList.filter((x: any) => valuelist.includes(x.Value));
        // icondetail = key.Control.IconList.filter((x:any) => { valuearray.includes(x.Value) });
      }

    }

    return icondetail;
  }

  getToolTipContent(key: any, item: any): string {
    // let tooltiphtml = '<h6>hello world - ' + item.StartDate + ' to ' +  item.EndDate + '</h6>';
    let tooltiphtml: string;
    if (isNullOrUndefined(key.ToolTipTemplate) || key.ToolTipTemplate.length === 0) {
      let val = item[key.FieldName];

      // Show Linkedfield value if present
      if (!isNullOrUndefined(key.LinkedField) && key.LinkedField.length > 0) {
        val = item[key.LinkedField];
      }
      else {
        // Format Date
        if (key.Control.Type === 'datepicker') {
          // val = this.dp.transform(val, key.Control.Format);
          val = this.dp.transform(val);
        }

        if (key.Control.Type === 'textbox' && key.Control.InputType === 'number'
          && (isNullOrUndefined(key.Control.Format) ||
            (key.Control.Format.length > 0 && key.Control.Format.toLowerCase() === 'true'))) {
          val = this.np.transform(val);
        }

      }

      tooltiphtml = val;
    }
    else {
      tooltiphtml = this.frameTemplate(key.ToolTipTemplate, item);
    }

    return tooltiphtml;
  }

  getLabelContent(key: any, item: any): string {
    return (this.frameTemplate(key.LabelTemplate, item));
  }

  frameTemplate(template: any, item: any): string {
    let keys: Array<string> = [];
    let temp = template;
    while (temp.indexOf('{{$Item.') >= 0) {
      let name = temp.substring(temp.indexOf('{{$Item.'), temp.indexOf('}}') + 2);
      name = name.replace('{{$Item.', '');
      name = name.replace('}}', '');
      keys.push(name);
      temp = temp.substr(temp.indexOf('}}') + 2);
    }

    let templatestr = template;
    // loop for each Key to be replaced
    for (let j = 0; j < keys.length; j++) {
      templatestr = templatestr.replace('{{$Item.' + keys[j] + '}}', item[keys[j]]);
    }

    return templatestr;
  }





  actButtonClick(event: any) {
    // let eventdata = { buttonname: event.buttonname, actionbut: event.actionbut.Name };
    // this.butClick.emit(eventdata);
    this.ActionBarClick.emit(event);
    // this.ButtonSelectedData = eventdata;
    console.log(event)
  }


  pageItemsChange(event: any) {
    let pagesPerchange = event.target.value;
    this.pagesPerchange = pagesPerchange;
    if (this.curItemsPerPage > 0 && this.pagesPerchange != null && this.pagesPerchange > 0) {
      //   this.curItemsPerPage = this.curItemsPerPage > 10 ? pagesPerchange : 10;
      this.Pagination.ItemsPerPage = this.curItemsPerPage;
      this.Pagination.CurrentPage = 1;
      // this.prevSelectedCell = '';
      // this.selectedCell = '';
      // this.selectedCellItemData = null;

      if (this.PaginationConfig.ServerLoad) {
        this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
      }
      // this.curItemsPerPage=pagesPerchange;
      console.log(this.Pagination.ItemsPerPage);
      console.log(this.Pagination.CurrentPage)

    }

    else {
      this.Pagination.ItemsPerPage = 100;

    }
  }
}
