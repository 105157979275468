// Import statements
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ComponentCodes, ComponentConfig, LotSolutionModule, ProcessStatus, ServiceDomain } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { environment } from 'src/environments/environment';
import { FormComponent } from 'src/modules/form/form.component';
import { GridComponent } from 'src/modules/grid/grid.component';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
// import { DeliveryModelComponent } from './delivery-model/delivery-model.component';
import { ServiceDomainInf } from './lot-solution-modeliling.interface';
import { LotSolutionService } from './lot-solution-modelling.service';

@Component({
  selector: 'app-lot-solution-modelling',
  templateUrl: './lot-solution-modelling.component.html'
})
@AutoUnsubscribe
export class LotSolutionModellingComponent implements OnInit {
  // View child
  @ViewChild('TowerDetailsGrid', { static: false }) TowerDetailsGrid!: GridComponent;
  @ViewChild('serviceDomainForm', { static: false }) serviceDomainForm!: FormComponent;
  // @ViewChild('deliveryModelComponent', { static: false }) deliveryModelComponent!: DeliveryModelComponent;

  // Class properties
  DealHeaderId: any;
  DealLineItemId: any;
  DealSubLineItemId: any;
  DealData: any = {};
  MainModule: string = '';
  public Approach: any;
  DealProgressMeter: number = 0;
  SelectedDeal: any;
  ModuleName: string = 'DeliveryModel';
  ModuleHeaderName: string = '';
  modalInf: ModalInterface = {
    header: '',
    size: 'modal-xs modal-dialog-centered modal-dialog-scrollable'
  };

  public Templates: FieldTemplate[] = [];
  public Permission: any = [];
  public Properties: any = [];

  public TowerDetailsTemplate: FieldTemplate[] = [];
  public TowerDetails: any[] = [];
  public SelectedTowerDetails: any = {};

  public ServiceDomainFormTemplate: FieldTemplate[] = [];
  public ServiceDomainFormDetails = new ServiceDomainInf();


  public GetAmDealProgressBarPerData: any = {};
  public GlobalConfig: any = {};
  public dataTotalCount: number = 0;
  public IsLoading: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public Config: any;
  public ActionButtons: any[] = [];
  public IsPagination: boolean = false;
  serviceDomainLoad: boolean = false;

  public GridProp: any = {
    Editable: true,
    IsSortable: false,
    IsColResizable:false,
    PaginationConfig: {
      ItemsPerPage: 25
    }
  };

  public FormProp: FormProperty = {
    ColSize: 3,
    CaptionBold: false,
    CaptionTop: true,
    IsEditable: true,
    ShowControlAlways: true
  };

  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };

  constructor(
    private modalService: ModalService,
    private _commonAPIService: CommonAPIService,
    private _lotSolutionService: LotSolutionService,
    private _appSharedService: AppSharedService,
    private _route: ActivatedRoute, private _router: Router
  ) { }

  ngOnInit(): void {
    // Set the module name
    this.ModuleName = this._route.snapshot.data['selectedTab'];
    this.Approach = this._appSharedService.selectedDeal.AmApproach;
    this.MainModule = this._appSharedService.currentUserModuleData.Code;
    // Get the current deal data from the shared service
    this.DealData = this._appSharedService.currentDealData;

    // Check if the deal data is not null or undefined
    if (this.DealData) {
      // Assign the deal header id, line item id, and subline item id with a default value of 0 if they are null or undefined
      this.DealHeaderId = this.DealData.DealHeaderId ?? 0;
      this.DealLineItemId = this.DealData.DealLineItemId ?? 0;
      this.DealSubLineItemId = this.DealData.DealSubLineItemId ?? 0;
    }

    // Initialize the component
    this.initialize();
  }

  private initialize(): void {
    // Get the Lot Solution template
    this.getLotSolutionTemplate();

    //Frame Module Name
    this.showModuleName();
  }

  private getLotSolutionTemplate(): void {
    // Show loading indicator
    this.IsLoading = true;

    // Define the component configuration
    const odata: ComponentConfig = {
      Component: 'LotSolutionModelling',
      ComponentCode: ComponentCodes.LotSolutionModel,
      ReferenceValueType: '1'
    };

    // Subscribe to the component configuration API call
    const subscribe$ = this._commonAPIService.getComponentConfig(odata).subscribe(
      (res) => {
        // Handle the response
        if (res !== undefined && res !== null && res.Status === 200) {
          this.Templates = res.Data.FieldTemplate!;
          this.Permission = res.Data.Permission;
          this.Properties = res.Data.Properties;
          this.TowerDetailsTemplate = this.Templates.filter((x) => x.Group === 'EffortModellingHeader');
          this.ServiceDomainFormTemplate = this.Templates.filter((x) => x.Group === 'EffortModellingDropdown');

          //Set Properties
          this.setCompProperties(this.Properties);
          this.ActionButtons = this.Config.LotSolutionButtons.filter((x: any) => x.Group === 'LocationModelling');

          this.getTowerDetails();
        }
      },
      (err) => {
        // Handle errors
        this.IsLoading = false;
        console.log('Error on getLotSolutionTemplate.');
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // Other component specific props
      Properties.forEach(prop => {
        // avoiding generic props
        if (!isNullOrUndefined(prop.Name) &&
          prop.Name.toLowerCase() !== 'DefaultRow'.toLowerCase() && prop.Name.toLowerCase() !== 'BlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'ProductivityBlockCols'.toLowerCase()
          && prop.Name.toLowerCase() !== 'PaginationConfig'.toLowerCase()) {
          if (isNullOrUndefined(this.Config)) {
            this.Config = {};
          }
          this.Config[prop.Name] = JSON.parse(prop.JSONData.toString());
        }
      });
    }
  }


  private getTowerDetails(bLoad: boolean = false): void {
    if (!bLoad) {
      this.IsLoading = true;
    }

    // Subscribe to the baselined details API call
    const subscribe$ = this._lotSolutionService.getBaselinedDetails(this.DealHeaderId).subscribe(
      (result) => {
        // Handle the result
        if (result && result.Status === ProcessStatus.Success) {
          this.TowerDetails = result.Data;
          this.dataTotalCount = this.TowerDetails.length;
          this.getAmDealProgressBarPer(this.DealHeaderId);
          this._appSharedService.currentDealData.DealProgressOrder = this.TowerDetails[0].DealProgress;
          this._appSharedService.currentDealData.DealProgress = this.TowerDetails[0].DealProgressStatus;

          if (this.TowerDetails.length > 0) {
            const selitem = this.TowerDetails.find(x =>
              x.DealHeaderId === this.DealHeaderId &&
              x.DealLineItemId === this.DealLineItemId &&
              x.DealSubLineItemId === this.DealSubLineItemId
            );
            this.GetServiceDomainDetails(true, selitem, true);
          } else {
            this.IsLoading = false;
          }
        } else {
          this.IsLoading = false;
        }
      },
      (err) => {
        // Handle errors
        console.log('Error on getting GetTowerDetails.');
        this.IsLoading = false;
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }


  private getAmDealProgressBarPer(id: number): void {
    // Subscribe to the GetAmDealProgressBarPer API call
    const subscribe$ = this._lotSolutionService.GetAmDealProgressBarPer(id, this._appSharedService.selectedDeal.AmApproach).subscribe(
      (result) => {
        // Handle the result
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
          this.GetAmDealProgressBarPerData = result.Data;
          this.DealProgressMeter = this.GetAmDealProgressBarPerData[0].ProgressBarPer;
        }
      },
      (err) => {
        // Handle errors
        this.IsLoading = false;
      }
    );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }

  showModuleName() {
    switch (this.ModuleName) {
      case LotSolutionModule.DeliveryModel:
        this.ModuleHeaderName = 'Lot Solution Effort Modelling | Delivery Location Modelling';
        break;
      case LotSolutionModule.LocationMix:
        this.ModuleHeaderName = 'Lot Solution Effort Modelling | Location Effort % Mix';
        break;
      case LotSolutionModule.ProductivityImpact:
        this.ModuleHeaderName = 'Lot Solution Effort Modelling | Productivity Impact';
        break;
      case LotSolutionModule.StaffingModel:
        this.ModuleHeaderName = 'Lot Solution Effort Modelling | Staffing Model';
        break;
      default:
        // Handle the case when ModuleName doesn't match any of the above values
        break;
    }
  }

  showHelpIconMessage() {
    const moduleLookup: { [key: string]: string } = {
      [LotSolutionModule.DeliveryModel]: 'panel',
      [LotSolutionModule.LocationMix]: 'panel',
      [LotSolutionModule.ProductivityImpact]: 'panel',
      [LotSolutionModule.StaffingModel]: 'subpanel_Staffing'
    };

    const section = moduleLookup[this.ModuleName];
    if (section) {
      this.helpIconClick(section);
    }
  }

  helpIconClick(section: string) {
    // Consolidate repetitive code and reduce conditional statements
    let modalId = '';
    let header = '';

    switch (section) {
      case 'panel':
        modalId = 'OpenTooltip';
        break;
      case 'subpanel_deliveryModel':
        modalId = 'SubOpenTooltip';
        break;
      case 'subpanel_locationmix':
        modalId = 'SubOpenTooltipLocationMix';
        break;
      case 'subpanel_Staffing':
        modalId = 'HeaderOpenTooltip';
        break;
    }

    // Set the header and size properties directly in the modalInf object
    this.modalInf.header = header;

    this.openModal(modalId);
  }

  openModal(id: string) {
    this.modalService.open(id);
    // Perform any additional logic after opening the modal if needed
  }

  closeModal(id: string) {
    this.modalService.close(id);
    // Perform any additional logic after closing the modal if needed
  }

  onClick(event: any) {
    const selitem = event.item;
    const fieldName = event.fieldname.toUpperCase();

    if (['LOTNAME', 'SUBTOWERNAME', 'TOWERNAME', 'DERIVEDRESOURCE'].includes(fieldName)) {

      let isnavigateSamePage: boolean = false;

      isnavigateSamePage = this.DealSubLineItemId === selitem.DealSubLineItemId ? true : false;

      this.DealLineItemId = selitem.DealLineItemId;
      this.DealSubLineItemId = selitem.DealSubLineItemId;
      this._appSharedService.currentDealData.DealLineItemId = this.DealLineItemId;
      this._appSharedService.currentDealData.DealSubLineItemId = this.DealSubLineItemId;
      this._appSharedService.currentDealData$.next(this._appSharedService.currentDealData);
      this._appSharedService.currentDealData.ServiceDomain =
        !isNullOrUndefined(this.ServiceDomainFormDetails) &&
          !isNullOrUndefined(this.ServiceDomainFormDetails.ServiceDomain) &&
          this.ServiceDomainFormDetails.ServiceDomain === ServiceDomain.Enhancement
          ? this.ServiceDomainFormDetails.ServiceDomain
          : '';

      if (this.DealHeaderId > 0 && this.DealLineItemId > 0 && this.DealSubLineItemId > 0) {
        this.SelectedTowerDetails = {};
        this.GetServiceDomainDetails(false, selitem, isnavigateSamePage);
      }
    }
  }

  onChange(event: any) {
    let selitem: any = event.item;
    selitem.IsModified = 1;
  }

  private GetServiceDomainDetails(bLoad: boolean = false, selitem: any, isnavigateSamePage: boolean): void {

    this.ServiceDomainFormDetails = new ServiceDomainInf();

    this.serviceDomainLoad = false;

    if (!bLoad) {
      this.IsLoading = true;
    }

    const subscribe$: Subscription = this._lotSolutionService
      .GetServiceDomainDetails(this.DealHeaderId, this.DealLineItemId, this.DealSubLineItemId)
      .subscribe(
        (result) => {
          // Handle the result
          if (result && result.Status === ProcessStatus.Success) {
            // this.ServiceDomainFormDetails = result.Data;

            if (!isnavigateSamePage) {
              this.ModuleName = LotSolutionModule.DeliveryModel;
              this.navigateToDeliveryModel();
              this.showModuleName();
            }


            this.SelectedTowerDetails = selitem;
            this.ServiceDomainFormDetails = result.Data;

            // if(this.ServiceDomainFormDetails.LSDeliveryLocationModellingId > 0){
            //   this.FormProp.IsEditable = false;
            // }
            setTimeout(() => {
              if (!isNullOrUndefined(this.serviceDomainForm)) {
                if (this.ServiceDomainFormDetails.LSDeliveryLocationModellingId > 0) {
                  this.serviceDomainForm.enableFields('TechnologyProfile,DeliveryUnit,TowerType,ServiceDomain', true);
                } else {
                  this.serviceDomainForm.enableFields('TechnologyProfile,DeliveryUnit,TowerType,ServiceDomain', false);
                }
              }
            }, 1000);


            this.rowHighlight();
            this.serviceDomainLoad = true;
          }
          this.IsLoading = false;
        },
        (err) => {
          // Handle errors
          console.log('Error on getting GetServiceDomainDetails.');
          this.IsLoading = false;
        }
      );

    // Add the subscription to the list
    this.subscriptionList$.push(subscribe$);
  }

  private rowHighlight(): void {

    if (this.TowerDetails.length > 0 && this.DealSubLineItemId > 0) {

      this.TowerDetails.forEach(item => {
        item.IsRowHighlight = 0;
      });

      const selitem = this.TowerDetails.find(x =>
        x.DealHeaderId === this.DealHeaderId &&
        x.DealLineItemId === this.DealLineItemId &&
        x.DealSubLineItemId === this.DealSubLineItemId
      );

      if (selitem) {
        selitem.IsRowHighlight = 1;
      }
    }
  }

  actionButtonClick(data: any): void {
    const name = data.Name?.toUpperCase();

    if (name) {
      switch (name) {
        case 'NEXT':
          if (this.ModuleName === LotSolutionModule.DeliveryModel) {
            this.navigateToLocationMix();
          } else if (this.ModuleName === LotSolutionModule.LocationMix) {
            this.navigateToProductivityImpact();
          } else if (this.ModuleName === LotSolutionModule.ProductivityImpact) {
            if (this.ServiceDomainFormDetails.ServiceDomain !== ServiceDomain.Enhancement) {
              this.navigateToStaffingModel();
            } else {
              this.navigateToPricingModel();
            }
          } else if (this.ModuleName === LotSolutionModule.StaffingModel) {
            this.navigateToPricingModel();
          }
          break;

        case 'BACK':
          if (this.ModuleName === LotSolutionModule.DeliveryModel) {
            this.navigateToModelBaseline();
          } else if (this.ModuleName === LotSolutionModule.LocationMix) {
            this.navigateToDeliveryModel();
          } else if (this.ModuleName === LotSolutionModule.ProductivityImpact) {
            this.navigateToLocationMix();
          } else if (this.ModuleName === LotSolutionModule.StaffingModel) {
            this.navigateToLocationMix();
          }
          break;
      }
    }
  }

  private navigateToLocationMix(): void {
    const url = `app/lot-solution-modelling/LocationMix/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.SelectedTowerDetails } });
  }

  private navigateToProductivityImpact(): void {
    const url = `app/lot-solution-modelling/productivityImpact/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.SelectedTowerDetails } });
  }

  private navigateToStaffingModel(): void {
    const url = `app/lot-solution-modelling/StaffingModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url]);
  }

  private navigateToPricingModel(): void {
    const url = `app/lot-solution-pricing-model/pricingmodel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.SelectedTowerDetails } });
  }

  private navigateToModelBaseline(): void {
    const url = `app/model-config/ModelBaseline/${this.DealHeaderId}`;
    this._router.navigate([url], { state: { selectedData: this.SelectedTowerDetails } });
  }

  private navigateToDeliveryModel(): void {
    const url = `app/lot-solution-modelling/DeliveryModel/${this.DealHeaderId}/${this.DealLineItemId}/${this.DealSubLineItemId}`;
    this._router.navigate([url], { state: { selectedData: this.SelectedTowerDetails } });
  }

}

