
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, NgZone, QueryList, AfterViewInit } from '@angular/core';
import { FieldTemplate, FormProperty } from 'src/modules/shared/_classes/utility.interface';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined } from '../../shared/app.sharedservice';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as Excel from "exceljs";
import * as fs from "file-saver";
import { ComponentCodes, ComponentConfig, ProcessStatus, ReferenceValueType } from '../../app.interface';
import { CommonAPIService } from '../../shared/app.commonservice';
import { ExcelExportComponent } from 'src/modules/excelexport/excelexport.component';
import { asEnumerable } from 'linq-es2015';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { RangeSliderComponent } from 'src/modules/range-slider/range-slider.component';
import { ModalComponent } from 'src/modules/modal/modal.component';
import { GridDisplayControlComponent } from 'src/modules/grid-dispctrl/grid-dispctrl.component';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { BrowserModule, Title } from '@angular/platform-browser';
import { DatePipe, isPlatformBrowser } from '@angular/common'
import { LiteHomeService } from './lite-home.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { TabItem } from 'src/modules/tabset/tabset.interface';
import { FormComponent } from 'src/modules/form/form.component';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { isEmpty, startWith } from 'rxjs/operators';
import { GridComponent } from 'src/modules/grid/grid.component';
import { dataPropInterface, summaryInterface1, summaryInterface2, summaryInterface3, formData } from './lite-home.interface';
import { GenericComponent } from '../../app.genericcomponent';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//@ViewChild('childform', { static: false }) childform: FormComponent;
@Component({
  selector: 'app-lite-home',
  templateUrl: './lite-home.component.html',
  styleUrls: ['./lite-home.component.css']
})

@AutoUnsubscribe
export class LiteHomeComponent extends GenericComponent<dataPropInterface> implements OnInit {
  @ViewChild('LiteDashboardForm', { static: false }) LiteDashboardForm!: FormComponent;
  @ViewChild('DealInfoForm', { static: false }) DealInfoForm!: FormComponent;
  @ViewChild('InflationForm', { static: false }) InflationForm!: FormComponent;
  @ViewChild('FTErategrid', { static: false }) FTErategrid!: GridComponent;
  @ViewChild('summaryGrid', { static: false }) summaryGrid!: GridComponent;
  @ViewChild('locInfoGrid', { static: false }) locInfoGrid!: GridComponent;
  @ViewChild('Range', { static: false }) Range!: RangeSliderComponent;
  @ViewChild('locationmixpopup', { static: false }) locationmixpopup!: ModalComponent;
  @ViewChild('pyramidInfopopup', { static: false }) pyramidInfopopup!: ModalComponent;
  @ViewChild('select', { static: false }) selectctrl!: NgSelectComponent;
  //@ViewChild(ChartsComponent, { static: true })  charts!: ChartsComponent;

  public IsLoading: boolean = false;
  public SummaryGraphData: any;
  private rootsummary!: am5.Root;
  private rootproductivity!: am5.Root;
  private rootblendedRate!: am5.Root;
  private rootLocationMix!: am5.Root;
  public summaryData: any;
  public yearName: any;
  public productivitygraphData: any;
  public locationMixData: any;
  public blendedData: any;



  public CompTitle: string = 'Lite-Home';
  public Title: any = 'Lite-Dashboard';
  public GeneralInfoTemplate: FieldTemplate[] = [];
  public locationMixTemplate: FieldTemplate[] = [];
  public OtherExpenseTemp: FieldTemplate[] = [];
  public outputTemplate: FieldTemplate[] = [];
  public locationNearShoreTemplate: FieldTemplate[] = [];
  public DealInfoTemplate: FieldTemplate[] = [];
  public FTERateTemplate: FieldTemplate[] = [];
  public summaryTemplate1: FieldTemplate[] = [];
  public summaryTemplate2: FieldTemplate[] = [];
  public summaryTemplate3: FieldTemplate[] = [];
  public InflationTemplate: FieldTemplate[] = [];
  public pyramidInfoTemplate: FieldTemplate[] = [];
  public locationMixInfoTemplate: FieldTemplate[] = [];
  public modalInf: ModalInterface = new ModalInterface();
  public notify: NotificationData = new NotificationData();
  public DashboardData: formData = new formData();
  public productivityDataExporting: any;
  public blendedRateDataExporting: any;
  public locationMixDataExporting: any;
  public dealterm: any;
  public locationMixInfoDetail: any = {};
  public pyramidInfoDetail: any = {};
  public GlobalConfig: any = {};
  public datalength: number = 3;
  public summarylength: number = 1;
  public currentspendVal: number = 0;
  public p2wVal: number = 0;
  public blendedOverall: any;
  public blendedYear: any;
  public blendedYear1: any;
  public blendedYear2: any;
  public blendedYear3: any;
  public blendedYear4: any;
  public blendedYear5: any;
  public revCostOverall: any;
  public revCostYear1: any;
  public revCostYear2: any;
  public revCostYear3: any;
  public revCostYear4: any;
  public revCostYear5: any;
  public revCostYear: any;
  public cmVal: number = 0;
  public savingsVal: number = 0;
  public productivityVal: number = 0;
  public overallblendedrateVal: number = 0;
  public IsCustomSelected: boolean = false;
  public IstransCustomSelected: boolean = false;
  public IsgovCustomSelected: boolean = false;
  public IsexpCustomSelected: boolean = false;
  public displayNotifybox: boolean = false;
  public IsOnShoreCustomSelected: boolean = false;
  public IsOffshoreCustomSelected: boolean = false;
  public IsNearshoreCustomSelected: boolean = false;
  public IsLocoffshore: boolean = false;
  public IsLoconshore: boolean = false;
  public IsLocnearshore: boolean = false;
  public rangeList: any;
  public overallFTEcount: number = 0;
  public prodTooltip: any;
  public minimum: number = 0;
  public maximum: number = 5;
  public fteonshore: any;
  public IsLocCustomSelected: boolean = false;
  public fteoffshore: any;
  public fteoffshoreclient: number = 0;
  public fteonshoreclient: number = 0;
  public fteonshorecg: number = 0;
  public fteonshorevendor: number = 0;
  public fteoffshorecg: number = 0;
  public fteoffshorevendor: number = 0;
  public locMixLength: any;
  public pyrMixLength: any;
  public infoIconDisabled: boolean = false;
  public pyrinfoIconDisabled: boolean = false;
  public IsConCustomSelected: boolean = false;
  public IsPyrOnsCustomSelected: boolean = false;
  public IsPyrOffCustomSelected: boolean = false;
  public IsPyrNearshCustomSelected: boolean = false;
  public summaryDataExporting: any;
  public prodVal: string = '';
  public LocVal: string = '';
  public selectedBtn: any;
  public PyronshoreVal: string = '';
  public PyroffshoreVal: string = '';
  public PyrnearshoreVal: string = '';
  public MaronshoreVal: string = '';
  public MaroffshoreVal: string = '';
  public MarnearshoreVal: string = '';
  public transtionVal: string = '';
  public governanceVal: string = '';
  public othersVal: string = '';
  public contingencyVal: string = '';
  public Marval: string = '';
  public selectedTab: string = 'Productivity';
  public location1: any;
  public location2: any;
  public IsPagination: boolean = false;
  public onshoreLocation: any = [
    { id: 1, label: 'Canada', code: 'CAN', fieldname: 'ons_location' },
    { id: 2, label: 'US', code: 'US', fieldname: 'ons_location' },
    { id: 3, label: 'Austria', code: 'AUT', fieldname: 'ons_location' },
    { id: 4, label: 'Belgium', code: 'BEL', fieldname: 'ons_location' },
    { id: 5, label: 'Switzerland', code: 'CHE', fieldname: 'ons_location' },
    { id: 6, label: 'Czech Republic', code: 'CZE', fieldname: 'ons_location' },
    { id: 7, label: 'Germany', code: 'DEU', fieldname: 'ons_location' },
    { id: 8, label: 'Denmark', code: 'DNK', fieldname: 'ons_location' },
    { id: 9, label: 'Spain', code: 'ESP', fieldname: 'ons_location' },
    { id: 10, label: 'Finland', code: 'FIN', fieldname: 'ons_location' },
    { id: 11, label: 'France', code: 'FRA', fieldname: 'ons_location' },
    { id: 12, label: 'Hungary', code: 'HUN', fieldname: 'ons_location' },
    { id: 13, label: 'Ireland', code: 'IRL', fieldname: 'ons_location' },
    { id: 14, label: 'Italy', code: 'ITA', fieldname: 'ons_location' },
    { id: 15, label: 'Luxembourg', code: 'LUX', fieldname: 'ons_location' },
    { id: 16, label: 'Netherlands', code: 'NLD', fieldname: 'ons_location' },
    { id: 17, label: 'Norway', code: 'NOR', fieldname: 'ons_location' },
    { id: 18, label: 'Poland', code: 'POL', fieldname: 'ons_location' },
    { id: 19, label: 'Portugal', code: 'PRT', fieldname: 'ons_location' },
    { id: 20, label: 'Romania', code: 'ROU', fieldname: 'ons_location' },
    { id: 21, label: 'Slovakia', code: 'SVK', fieldname: 'ons_location' },
    { id: 22, label: 'Sweden', code: 'SWE', fieldname: 'ons_location' },
    { id: 23, label: 'UK', code: 'UK', fieldname: 'ons_location' },
  ];
  public offshoreLocation: any = [
    { id: 1, label: 'India', code: 'IND', fieldname: 'off_location' }
  ];

  public client: any = [
    { id: 1, fieldname: 'fteonshore', value: 0, itemDesc: "Client" },
    { id: 2, fieldname: 'rateonshore', value: 0, itemDesc: "Client" },
    { id: 3, fieldname: 'fteoffshore', value: 0, itemDesc: "Client" },
    { id: 4, fieldname: 'rateoffshore', value: 0, itemDesc: "Client" }
  ];
  public vendor: any = [
    { id: 1, fieldname: 'fteonshore', value: 0, itemDesc: "Vendor" },
    { id: 2, fieldname: 'rateonshore', value: 0, itemDesc: "Vendor" },
    { id: 3, fieldname: 'fteoffshore', value: 0, itemDesc: "Vendor" },
    { id: 4, fieldname: 'rateoffshore', value: 0, itemDesc: "Vendor" }
  ];
  public existingcg: any = [
    { id: 1, fieldname: 'fteonshore', value: 0, itemDesc: "CG" },
    { id: 2, fieldname: 'rateonshore', value: 0, itemDesc: "CG" },
    { id: 3, fieldname: 'fteoffshore', value: 0, itemDesc: "CG" },
    { id: 4, fieldname: 'rateoffshore', value: 0, itemDesc: "CG" }
  ];

  public Inflation: any = [];
  public PyramidOnsArray: any = [];
  public pyr_ons_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Pyramid_Ons_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Pyramid_Ons_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Pyramid_Ons_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Pyramid_Ons_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Pyramid_Ons_Year5', value: 0 },
  ];
  public PyramidOffArray: any = [];
  public pyr_off_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Pyramid_Off_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Pyramid_Off_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Pyramid_Off_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Pyramid_Off_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Pyramid_Off_Year5', value: 0 },
  ];
  public PyramidNearshArray: any = [];
  public pyr_nearsh_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Pyramid_Nearsh_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Pyramid_Nearsh_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Pyramid_Nearsh_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Pyramid_Nearsh_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Pyramid_Nearsh_Year5', value: 0 },
  ];
  public TransArray: any = [];
  public Trans_Array: any = [
    { Name: "Y1", id: 1, fieldname: 'Transition_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Transition_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Transition_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Transition_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Transition_Year5', value: 0 },
  ];
  public GovArray: any = [];
  public Gov_Array: any = [
    { Name: "Y1", id: 1, fieldname: 'Governance_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Governance_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Governance_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Governance_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Governance_Year5', value: 0 },
  ];
  public ExpArray: any = [];
  public Exp_Array: any = [
    { Name: "Y1", id: 1, fieldname: 'Expense_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Expense_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Expense_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Expense_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Expense_Year5', value: 0 },
  ];
  public MarginOnsArray: any = [];
  public mar_ons_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Margin_Ons_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Margin_Ons_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Margin_Ons_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Margin_Ons_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Margin_Ons_Year5', value: 0 },
  ];
  public MarginOffArray: any = [];
  public mar_off_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Margin_Off_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Margin_Off_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Margin_Off_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Margin_Off_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Margin_Off_Year5', value: 0 },
  ];
  public MarginNearArray: any = [];
  public mar_near_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Margin_Near_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Margin_Near_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Margin_Near_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Margin_Near_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Margin_Near_Year5', value: 0 },
  ];
  public locYears: any = [];
  public locOnsCustom: any = [];
  public loc_ons_custom: any = [
    { Name: "Year1", id: 1, fieldname: 'LocOnsYear1', value: 0 },
    { Name: "Year2", id: 2, fieldname: 'LocOnsYear2', value: 0 },
    { Name: "Year3", id: 3, fieldname: 'LocOnsYear3', value: 0 },
    { Name: "Year4", id: 4, fieldname: 'LocOnsYear4', value: 0 },
    { Name: "Year5", id: 5, fieldname: 'LocOnsYear5', value: 0 },
  ];
  public locOffCustom: any = [];
  public loc_off_custom: any = [
    { Name: "Year1", id: 1, fieldname: 'LocOffYear1', value: 0 },
    { Name: "Year2", id: 2, fieldname: 'LocOffYear2', value: 0 },
    { Name: "Year3", id: 3, fieldname: 'LocOffYear3', value: 0 },
    { Name: "Year4", id: 4, fieldname: 'LocOffYear4', value: 0 },
    { Name: "Year5", id: 5, fieldname: 'LocOffYear5', value: 0 },
  ];
  public locNearCustom: any = [];
  public loc_nearsh_custom: any = [
    { Name: "Year1", id: 1, fieldname: 'LocNearshYear1', value: 0 },
    { Name: "Year2", id: 2, fieldname: 'LocNearshYear2', value: 0 },
    { Name: "Year3", id: 3, fieldname: 'LocNearshYear3', value: 0 },
    { Name: "Year4", id: 4, fieldname: 'LocNearshYear4', value: 0 },
    { Name: "Year5", id: 5, fieldname: 'LocNearshYear5', value: 0 },
  ]
  public ConArray: any = [];
  public con_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Con_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Con_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Con_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Con_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Con_Year5', value: 0 },
  ];
  public LocArray: any = [];
  public loc_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Loc_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Loc_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Loc_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Loc_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Loc_Year5', value: 0 },
  ];
  public ProdArray: any = [];
  public prod_arr: any = [
    { Name: "Y1", id: 1, fieldname: 'Prod_Year1', value: 0 },
    { Name: "Y2", id: 2, fieldname: 'Prod_Year2', value: 0 },
    { Name: "Y3", id: 3, fieldname: 'Prod_Year3', value: 0 },
    { Name: "Y4", id: 4, fieldname: 'Prod_Year4', value: 0 },
    { Name: "Y5", id: 5, fieldname: 'Prod_Year5', value: 0 },
  ];
  public Infl_arr: any = [
    ["Year 1",
      { Name: "Year1", id: 1, fieldname: 'inflationonshore1', value: 0 },
      { Name: "Year1", id: 2, fieldname: 'inflationoffshore1', value: 0 },
    ],
    ["Year 2",
      { Name: "Year2", id: 3, fieldname: 'inflationonshore2', value: 0 },
      { Name: "Year2", id: 4, fieldname: 'inflationoffshore2', value: 0 },
    ],
    ["Year 3",
      { Name: "Year3", id: 5, fieldname: 'inflationonshore3', value: 0 },
      { Name: "Year3", id: 6, fieldname: 'inflationoffshore3', value: 0 },
    ],
    ["Year 4",
      { Name: "Year4", id: 7, fieldname: 'inflationonshore4', value: 0 },
      { Name: "Year4", id: 7, fieldname: 'inflationoffshore4', value: 0 },
    ],
    ["Year 5",
      { Name: "Year5", id: 9, fieldname: 'inflationonshore5', value: 0 },
      { Name: "Year5", id: 10, fieldname: 'inflationoffshore5', value: 0 },
    ],
  ]

  inputDetails: any = [];
  apiURL: any;
  overallPrice2Win: any;
  overallCM: any;
  overallSavings: any;
  overallProductivity: any;
  price2winImage: any;
  cmImage: any;
  savingsImage: any;
  productivityImage: any;
  revCM1: any;
  revRev1: any;
  revCost1: any;
  revCM2: any;
  revRev2: any;
  revCost2: any;
  revCM3: any;
  revRev3: any;
  revCost3: any;
  rev_CM1: any;
  rev_Rev1: any;
  rev_Cost1: any;
  rev_CM2: any;
  rev_Rev2: any;
  rev_Cost2: any;
  rev_CM3: any;
  rev_Rev3: any;
  rev_Cost3: any;
  ////YEAR2////
  rev_CM1_year2: any;              // --- nearshore
  rev_Rev1_year2: any;
  rev_Cost1_year2: any;

  rev_CM2_year2: any;            ///-- offshore
  rev_Rev2_year2: any;
  rev_Cost2_year2: any;

  rev_CM3_year2: any;
  rev_Rev3_year2: any;
  rev_Cost3_year2: any;           ///    --onshore
  ////YEAR3////
  rev_CM1_year3: any;             // --- nearshore
  rev_Rev1_year3: any;
  rev_Cost1_year3: any;

  rev_CM2_year3: any;            ///-- offshore
  rev_Rev2_year3: any;
  rev_Cost2_year3: any;

  rev_CM3_year3: any;
  rev_Rev3_year3: any;
  rev_Cost3_year3: any;          ///    --onshore

  ////YEAR4////

  rev_CM1_year4: any;             // --- nearshore
  rev_Rev1_year4: any;
  rev_Cost1_year4: any;

  rev_CM2_year4: any;            ///-- offshore
  rev_Rev2_year4: any;
  rev_Cost2_year4: any;

  rev_CM3_year4: any;
  rev_Rev3_year4: any;
  rev_Cost3_year4: any;
  ///    --onshore


  ///YEAR5///
  rev_CM1_year5: any;             // --- nearshore
  rev_Rev1_year5: any;
  rev_Cost1_year5: any;

  rev_CM2_year5: any;             ///-- offshore
  rev_Rev2_year5: any;
  rev_Cost2_year5: any;

  rev_CM3_year5: any;
  rev_Rev3_year5: any;
  rev_Cost3_year5: any;          ///    --onshore



  blendedOverall1: any;
  blendedOverall2: any;
  blendedOverall3: any;

  blendedOverall1_Y2: any;
  blendedOverall2_Y2: any;
  blendedOverall3_Y2: any;


  blendedOverall1_Y3: any;
  blendedOverall2_Y3: any;
  blendedOverall3_Y3: any;


  blendedOverall1_Y4: any;
  blendedOverall2_Y4: any;
  blendedOverall3_Y4: any;

  blendedOverall1_Y5: any;
  blendedOverall2_Y5: any;
  blendedOverall3_Y5: any;

  blendedOver: any;


  blendedyear1: any;
  blendedyear2: any;
  blendedyear3: any;


  blendedyear1_Y2: any;
  blendedyear2_Y2: any;
  blendedyear3_Y2: any;

  blendedyear1_Y3: any;
  blendedyear2_Y3: any;
  blendedyear3_Y3: any;

  blendedyear1_Y4: any;
  blendedyear2_Y4: any;
  blendedyear3_Y4: any;

  blendedyear1_Y5: any;
  blendedyear2_Y5: any;
  blendedyear3_Y5: any;


  blendedyear: any;

  blendedyear_2: any;
  blendedyear_3: any;
  blendedyear_4: any;
  blendedyear_5: any;
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 25
    },
    ActionBarConfig: {
      ActionBarList: null
    },
    AddNewRow: false,
    DefaultRow: [],
    DataObject: {},
    UniqueKeyName: ''
  };
  public dataObj: any = {};

  /* public odata: any =
    {
      "DealTerm": 0,
      "Client":'',
      "SBU":'',
      "ASISCountry":'',
      "FTE":0,
      "discount":0,
      "negotiation":0,
      "connectivity":0,
      "ipcurrency":0,
      "opcurrency":0,
      "Ons_Country": 0,
      "Ons_Client_FTE": 0,
      "Ons_Client_Rate": 0,
      "Ons_Vendor_FTE": 0,
      "Ons_Vendor_Rate": 0,
      "Ons_CG_FTE": 0,
      "Ons_CG_Rate": 0,
      "Prod_custom_Y1":0,
      "Prod_custom_Y2":0,
      "Prod_custom_Y3":0,
      "Prod_custom_Y4":0,
      "Prod_custom_Y5":0,
      "LocOns_custom_y1":0,
      "LocOns_custom_y2":0,
      "LocOns_custom_y3":0,
      "LocOns_custom_y4":0,
      "LocOns_custom_y5":0,
      "LocOff_custom_y1":0,
      "LocOff_custom_y2":0,
      "LocOff_custom_y3":0,
      "LocOff_custom_y4":0,
      "LocOff_custom_y5":0,
      "LocNearsh_custom_y1":0,
      "LocNearsh_custom_y2":0,
      "LocNearsh_custom_y3":0,
      "LocNearsh_custom_y4":0,
      "LocNearsh_custom_y5":0,
      "Margin_Ons_custom_Y1":0,
      "Margin_Ons_custom_Y2":0,
      "Margin_Ons_custom_Y3":0,
      "Margin_Ons_custom_Y4":0,
      "Margin_Ons_custom_Y5":0,
      "Margin_Off_custom_Y1":0,
      "Margin_Off_custom_Y2":0,
      "Margin_Off_custom_Y3":0,
      "Margin_Off_custom_Y4":0,
      "Margin_Off_custom_Y5":0,
      "Margin_Nearsh_custom_Y1":0,
      "Margin_Nearsh_custom_Y2":0,
      "Margin_Nearsh_custom_Y3":0,
      "Margin_Nearsh_custom_Y4":0,
      "Margin_Nearsh_custom_Y5":0,
      "Pyr_Ons_custom_Y1":0,
      "Pyr_Ons_custom_Y2":0,
      "Pyr_Ons_custom_Y3":0,
      "Pyr_Ons_custom_Y4":0,
      "Pyr_Ons_custom_Y5":0,
      "Pyr_Off_custom_Y1":0,
      "Pyr_Off_custom_Y2":0,
      "Pyr_Off_custom_Y3":0,
      "Pyr_Off_custom_Y4":0,
      "Pyr_Off_custom_Y5":0,
      "Pyr_Nearsh_custom_Y1":0,
      "Pyr_Nearsh_custom_Y2":0,
      "Pyr_Nearsh_custom_Y3":0,
      "Pyr_Nearsh_custom_Y4":0,
      "Pyr_Nearsh_custom_Y5":0,
      "Trn_Custom":0,     
      "Governance_custom_Y1":0,
      "Governance_custom_Y2":0,
      "Governance_custom_Y3":0,
      "Governance_custom_Y4":0,
      "Governance_custom_Y5":0,
      "Expense_custom_Y1":0,
      "Expense_custom_Y2":0,
      "Expense_custom_Y3":0,
      "Expense_custom_Y4":0,
      "Expense_custom_Y5":0,
      "Contigency_custom_Y1":0,
      "Contigency_custom_Y2":0,
      "Contigency_custom_Y3":0,
      "Contigency_custom_Y4":0,
      "Contigency_custom_Y5":0,
      "Ons_Inflation_y1": 0,
      "Ons_Inflation_y2": 0,
      "Ons_Inflation_y3": 0,
      "Ons_Inflation_y4": 0,
      "Ons_Inflation_y5": 0,
      "Off_Country": 0,
      "Off_Client_FTE": 0,
      "Off_Client_Rate": 0,
      "Off_Vendor_FTE": 0,
      "Off_Vendor_Rate": 0,
      "Off_CG_FTE": 0,
      "Off_CG_Rate": 0,
      "Off_Inflation_y1": 0,
      "Off_Inflation_y2": 0,
      "Off_Inflation_y3": 0,
      "Off_Inflation_y4": 0,
      "Off_Inflation_y5": 0,
      "Prd_Id": 0,
      "Prd_Custom": 0,
      "Loc_Ons_Sel": 0,
      "Loc_Ons_Country": 0,
      "Loc_Ons_Hub": 0,
      "Loc_Off_Sel": 0,
      "Loc_Nea_Sel": 0,
      "Loc_Nea_Country": 0,
      "Loc_Id": "0",
      "Pym_Ons_Id": 0,
      "Pyr_Ons_Custom:":0,
      "Pym_Off_Id": 0,
      "Pym_Nea_Id": 0,
      "Mrg_Ons_Id": 0,
      "Mrg_Ons_Custom": 0,
      "Mrg_Off_Id": 0,
      "Mrg_Off_Custom": 0,
      "Mrg_Nea_Id": 0,
      "Mrg_Nea_Custom": 0,
      "Exp_Id": 0,      
      "Gov_Id": 0,      
      "Trn_Id": 0,      
      "contigency_Id":0
    } */


  public odata: any =
    {
      "Client": '',
      "SBU": '',
      "ASISCountry": '',
      "DealTerm": 0,
      "FTE": 0,
      "ipcurrency": 0,
      "Ons_Country": 0,
      "Ons_Client_FTE": 0,
      "Ons_Client_Rate": 0,
      "Ons_Vendor_FTE": 0,
      "Ons_Vendor_Rate": 0,
      "Ons_CG_FTE": 0,
      "Ons_CG_Rate": 0,
      "Ons_Inflation_Y1": 0,
      "Ons_Inflation_Y2": 0,
      "Ons_Inflation_Y3": 0,
      "Ons_Inflation_Y4": 0,
      "Ons_Inflation_Y5": 0,
      "Off_Country": 0,
      "Off_Client_FTE": 0,
      "Off_Client_Rate": 0,
      "Off_Vendor_FTE": 0,
      "Off_Vendor_Rate": 0,
      "Off_CG_FTE": 0,
      "Off_CG_Rate": 0,
      "Off_Inflation_Y1": 0,
      "Off_Inflation_Y2": 0,
      "Off_Inflation_Y3": 0,
      "Off_Inflation_Y4": 0,
      "Off_Inflation_Y5": 0,
      "Prd_Id": 0,
      // "Prd_Custom": 0,
      "Prod_custom_Y1": 0,
      "Prod_custom_Y2": 0,
      "Prod_custom_Y3": 0,
      "Prod_custom_Y4": 0,
      "Prod_custom_Y5": 0,
      "Loc_Ons_Sel": 0,
      "Loc_Ons_Country": 0,
      "Loc_Ons_City": 0,
      "Loc_Ons_Hub": 0,
      "Loc_Off_Sel": 0,
      "Loc_Nea_Sel": 0,
      "Loc_Nea_Country": 0,
      "Loc_Nea_City": 0,
      "Loc_Id": "0",
      "LocOns_custom_Y1": 0,
      "LocOns_custom_Y2": 0,
      "LocOns_custom_Y3": 0,
      "LocOns_custom_Y4": 0,
      "LocOns_custom_Y5": 0,
      "LocOff_custom_Y1": 0,
      "LocOff_custom_Y2": 0,
      "LocOff_custom_Y3": 0,
      "LocOff_custom_Y4": 0,
      "LocOff_custom_Y5": 0,
      "LocNearsh_custom_Y1": 0,
      "LocNearsh_custom_Y2": 0,
      "LocNearsh_custom_Y3": 0,
      "LocNearsh_custom_Y4": 0,
      "LocNearsh_custom_Y5": 0,
      "Pym_Ons_Id": 0,
      "Pym_Off_Id": 0,
      "Pym_Nea_Id": 0,
      // "Pyr_Ons_Custom:":0,
      "Pyr_Ons_custom_Y1": 0,
      "Pyr_Ons_custom_Y2": 0,
      "Pyr_Ons_custom_Y3": 0,
      "Pyr_Ons_custom_Y4": 0,
      "Pyr_Ons_custom_Y5": 0,
      "Pyr_Off_custom_Y1": 0,
      "Pyr_Off_custom_Y2": 0,
      "Pyr_Off_custom_Y3": 0,
      "Pyr_Off_custom_Y4": 0,
      "Pyr_Off_custom_Y5": 0,
      "Pyr_Nearsh_custom_Y1": 0,
      "Pyr_Nearsh_custom_Y2": 0,
      "Pyr_Nearsh_custom_Y3": 0,
      "Pyr_Nearsh_custom_Y4": 0,
      "Pyr_Nearsh_custom_Y5": 0,
      "Mrg_Ons_Id": 0,
      // "Mrg_Ons_Custom": 0,
      "Mrg_Off_Id": 0,
      // "Mrg_Off_Custom": 0,
      "Mrg_Nea_Id": 0,
      // "Mrg_Nea_Custom": 0,
      "Margin_Ons_custom_Y1": 0,
      "Margin_Ons_custom_Y2": 0,
      "Margin_Ons_custom_Y3": 0,
      "Margin_Ons_custom_Y4": 0,
      "Margin_Ons_custom_Y5": 0,
      "Margin_Off_custom_Y1": 0,
      "Margin_Off_custom_Y2": 0,
      "Margin_Off_custom_Y3": 0,
      "Margin_Off_custom_Y4": 0,
      "Margin_Off_custom_Y5": 0,
      "Margin_Nearsh_custom_Y1": 0,
      "Margin_Nearsh_custom_Y2": 0,
      "Margin_Nearsh_custom_Y3": 0,
      "Margin_Nearsh_custom_Y4": 0,
      "Margin_Nearsh_custom_Y5": 0,
      "Trn_Id": 0,
      "Trn_Custom": 0,
      "Gov_Id": 0,
      "Governance_custom_Y1": 0,
      "Governance_custom_Y2": 0,
      "Governance_custom_Y3": 0,
      "Governance_custom_Y4": 0,
      "Governance_custom_Y5": 0,
      "Exp_Id": 0,
      "Expense_custom_Y1": 0,
      "Expense_custom_Y2": 0,
      "Expense_custom_Y3": 0,
      "Expense_custom_Y4": 0,
      "Expense_custom_Y5": 0,
      "contigency_Id": 0,
      "Contigency_custom_Y1": 0,
      "Contigency_custom_Y2": 0,
      "Contigency_custom_Y3": 0,
      "Contigency_custom_Y4": 0,
      "Contigency_custom_Y5": 0,
      "discount": 0,
      "negotiation": 0,
      "connectivity": 0,
      "opcurrency": 0,
      "OnsLocationCard": 0,
      "OffLocationCard": 0,
      "NeaLocationCard": 0,
      "Transition_Billability": 0,
      "Governance_Billability": 0,
      "OtherExpenses_Billability": 0
    }

  public LiteTabs: any;
  public subscriptionList$: Subscription[] = [];
  public Config: any = {
    ActionButtons: []
  };
  public TabName: string = 'Productivitylite';

  public gridData: any = {};
  public summaryData1: any;
  public summaryObj1: any = {};
  public summaryData2: any;
  public summaryObj2: any = {};
  public summaryData3: any;
  public summaryObj3: any = {};
  public gridDataProp: any = [

    {
      "FieldName": "Client",
      "value": "Client"
    },
    {
      "FieldName": "Vendor",
      "value": "Vendor"
    },
    {
      "FieldName": "Existing CG",
      "value": "Existing CG"
    },


  ]
  public summaryProp1: any = [

    {
      "FieldName": "Location Mix",
      "value": "Location Mix"
    },
    {
      "FieldName": "Pyramid",
      "value": "Pyramid"
    },
    {
      "FieldName": "Margin",
      "value": "Margin"
    },


  ]

  public locationprop: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };
  public FormProp: FormProperty = {
    'ColSize': 2,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };
  Locval_Off: any;
  Locval_Ons: any;
  Locval_Nea: any;

  constructor(public datepipe: DatePipe, private title: Title, private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private litehomeService: LiteHomeService, private modalService: ModalService, @Inject(PLATFORM_ID) private platformId: any, private zone: NgZone) {

    super(dataPropInterface, _commonAPIService, litehomeService, null);
    let subscribe$: Subscription = this._appSharedService.containerData$.subscribe((data) => {
      this._Data = [];
      this._Template = [];
      this._Config = [];
      this.ngOnInit();
      this.subscriptionList$.push(subscribe$);
      console.log("TEMPLATE")
      console.log(this._Template)
    });

    // this.DealInfoForm.enableFields('Nearshore', true);
  }
  actButtonClick(event: any): void {

    console.log(event.buttonname);
    // switch (event.buttonname.toUpperCase()) {
    //   case 'ADDNEWROW':
    //     let emptyrows = this._Data.filter((x: any) => x['IsModified'] === 0 && x[this.UniqueKeyName] <= 0);

    //     if (emptyrows.length > 0) {
    //       this.showNotificationMessage('Please fill in the available empty row.', 2, '', null, '', '', 1000);
    //       return;
    //     }
    //     this.addNewRow();
    //     break;

    //   case 'SAVE':
    //     this._IsSaving = false;
    //     this.offShoreFlagSaveClick = false;
    //     this.saveClick();
    //     // this.autoSave();
    //     break;

    //   case 'DELETE':
    //     this.deleteResoRows();
    //     break;

    //   case 'COPYROW':
    //     this.copyrow();
    //     break;

    //   case 'MANAGEDSERVICESREFFRENCEPDF':
    //     this.downloadpdf();
    //     break;

    //   case 'REFRESH':
    //     this.getDealDetails(this.selectedTab);
    //     break;

    // }
  }



  ngOnInit(): void {

    this.title.setTitle(this.Title);
    this._IsReadOnly = false;
    this._GridPropoffshoreVolume.Editable = true;


    this.getTemplate();
    this.odata.FTE = 0;

    this._GridProp['TableWidth'] = 150;

  }

  getSummaryDashbaord() {
    this.summaryTemplate1 = [];
    this.summaryTemplate2 = [];
    this.summaryTemplate3 = [];
    let odata: ComponentConfig = {
      Component: 'LiteDashboard', ComponentCode: ComponentCodes.LiteComponent, ReferenceValueType: "1"
    };

    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.summaryTemplate1 = template.filter(x => x.Group === 'ShoreTobeSolution');
        this.summaryTemplate2 = template.filter(x => x.Group === 'ExpensesCost');
        this.summaryTemplate3 = template.filter(x => x.Group === 'AdditionalCosts');

        this.summaryData1 = [];
        this.summaryProp1.forEach((element: any) => {
          this.summaryObj1 = new summaryInterface1();
          this.summaryObj1.ShoreDesc = element.value;
          this.summaryObj1.Onshore = this.summaryObj1.ShoreDesc === 'Location Mix' ? this.Locval_Ons :
            this.summaryObj1.ShoreDesc === 'Pyramid' ? this.PyronshoreVal : this.summaryObj1.ShoreDesc === 'Margin' ? this.MaronshoreVal : null;
          this.summaryObj1.Offshore = this.summaryObj1.ShoreDesc === 'Location Mix' ? this.Locval_Off :
            this.summaryObj1.ShoreDesc === 'Pyramid' ? this.PyroffshoreVal : this.summaryObj1.ShoreDesc === 'Margin' ? this.MaroffshoreVal : null;
          this.summaryObj1.Nearshore = this.summaryObj1.ShoreDesc === 'Location Mix' ? this.Locval_Nea :
            this.summaryObj1.ShoreDesc === 'Pyramid' ? this.PyrnearshoreVal : this.summaryObj1.ShoreDesc === 'Margin' ? this.MarnearshoreVal : null;

          this.summaryData1.push(this.summaryObj1);


          this.summaryData2 = [];
          this.summaryObj2 = new summaryInterface2();
          this.summaryObj2.OthersDesc = "Others";
          this.summaryObj2.TransitionCost = this.transtionVal;
          this.summaryObj2.Governance = this.governanceVal;
          this.summaryObj2.OtherExpenses = this.othersVal;
          this.summaryObj2.ContingencyCost = this.contingencyVal;

          this.summaryData2.push(this.summaryObj2);

          this.summaryData3 = [];
          this.summaryObj3 = new summaryInterface3();
          this.summaryObj3.OtherCosts = "Other Costs";
          this.summaryObj3.Discount = this.odata.discount;
          this.summaryObj3.Negotiation = this.odata.negotiation;
          this.summaryObj3.Connectivity = this.odata.connectivity;

          this.summaryData3.push(this.summaryObj3);


        });

      }
    },
      err => {
        this.IsLoading = false;
      }
    );
    this.subscriptionList$.push(subscribe$);
  }

  getTemplate() {

    // this.initComponent(otmpltdata, odata, null, this.CompTitle, 'TransactionId');
    // console.log(odata, 'iiiiii')
    this.GeneralInfoTemplate = [];
    this.DealInfoTemplate = [];
    this.FTERateTemplate = [];
    this.locationMixTemplate = [];

    let odata: ComponentConfig = {
      Component: 'LiteDashboard', ComponentCode: ComponentCodes.LiteComponent, ReferenceValueType: "1"
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      console.log(res);
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.setCompProperties(res.Data.Properties!);
        //this.LitePermission = res.Data.Permission;
        //this.LiteProperties = res.Data.Properties;
        this.GeneralInfoTemplate = template.filter(x => x.Group === 'DashboardresultLite');
        this.DealInfoTemplate = template.filter(x => x.Group === 'DashboardDealInformation');
        this.FTERateTemplate = template.filter(x => x.Group === 'FTERateAsIs');
        this.InflationTemplate = template.filter(x => x.Group === 'InflationDashboard');
        this.locationMixTemplate = template.filter(x => x.Group === 'LocationMixDashboard');
        this.locationNearShoreTemplate = template.filter(x => x.Group === 'ParameterSolutionNShore');
        this.OtherExpenseTemp = template.filter(x => x.Group === 'OtherExpenses');
        this.outputTemplate = template.filter(x => x.Group === 'OPCurrency');

        this.locationMixInfoTemplate = template.filter(x => x.Group === 'OnOffshoreLocDetails');
        this.pyramidInfoTemplate = template.filter(x => x.Group === 'PyramidMixDetails');
        this.DashboardData.IsEditable = 1;
        // this.DashboardData.IsSelectedOnshore = false;

        this.gridData = [];
        //  this.gridData.IsEditable = 1;



        this.gridDataProp.forEach((element: any) => {

          this.dataObj = new dataPropInterface();
          this.dataObj.FTERateDesc = element.value;
          this.dataObj.FTEOnshore = this.dataObj.FTERateDesc === 'Client' ? '0' :
            this.dataObj.FTERateDesc === 'Vendor' ? '0' : this.dataObj.FTERateDesc === 'Existing CG' ? '0' : null;
          this.dataObj.RateOnshore = this.dataObj.FTERateDesc === 'Client' ? '0' :
            this.dataObj.FTERateDesc === 'Vendor' ? '0' : this.dataObj.FTERateDesc === 'Existing CG' ? '0' : null;
          this.dataObj.FTEOffshore = this.dataObj.FTERateDesc === 'Client' ? '0' :
            this.dataObj.FTERateDesc === 'Vendor' ? '0' : this.dataObj.FTERateDesc === 'Existing CG' ? '0' : null;
          this.dataObj.RateOffshore = this.dataObj.FTERateDesc === 'Client' ? '0' :
            this.dataObj.FTERateDesc === 'Vendor' ? '0' : this.dataObj.FTERateDesc === 'Existing CG' ? '0' : null;
          this.dataObj.IsEditable = 1;
          this.gridData.push(this.dataObj);
        });
        console.log("grid data");
        console.log(this.gridData);
        this.GridProp.Editable = true;
        //this.setCompProperties(res.Data.Properties!);

        //this.setActionButtonPermissions();

      }
    },
      err => {
        this.IsLoading = false;
      }
    );
    this.subscriptionList$.push(subscribe$);

    this.getRangeSlider(this.TabName);

  }

  Exportexcel(savingsImage: string, productivityImage: string, locationMixImage: string,
    blendedRateImage: string, price2winSummary: string, cmSummary: string,
    savingsSummary: string, productivitySummary: string) {
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('P2W Lite');

    worksheet.mergeCells('G1', 'L2');
    let titleRow = worksheet.getCell('G1');
    titleRow.value = "Price 2 Win Lite";
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    titleRow.border = {
      top: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      left: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      bottom: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      right: {
        style: 'medium',
        color: { argb: 'FF333333' },
      }
    }

    worksheet.mergeCells('A4:D5');
    let price2winrow = worksheet.getCell('A4');
    price2winrow.value = "Price2Win : " + price2winSummary;
    price2winrow.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    price2winrow.alignment = { vertical: 'middle', horizontal: 'center' }

    price2winrow.border = {
      top: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      left: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      bottom: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      right: {
        style: 'medium',
        color: { argb: 'FF333333' },
      }
    }

    worksheet.mergeCells('F4:I5');
    let cmrow = worksheet.getCell('F4');
    cmrow.value = "CM% : " + cmSummary;
    cmrow.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    cmrow.alignment = { vertical: 'middle', horizontal: 'center' }

    cmrow.border = {
      top: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      left: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      bottom: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      right: {
        style: 'medium',
        color: { argb: 'FF333333' },
      }
    }

    worksheet.mergeCells('K4:N5');
    let savingsrow = worksheet.getCell('K4');
    savingsrow.value = "Savings% : " + savingsSummary;
    savingsrow.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    savingsrow.alignment = { vertical: 'middle', horizontal: 'center' }

    savingsrow.border = {
      top: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      left: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      bottom: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      right: {
        style: 'medium',
        color: { argb: 'FF333333' },
      }
    }

    worksheet.mergeCells('P4:S5');
    let productivityrow = worksheet.getCell('P4');
    productivityrow.value = "Productivity% : " + productivitySummary;
    productivityrow.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    productivityrow.alignment = { vertical: 'middle', horizontal: 'center' }

    productivityrow.border = {
      top: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      left: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      bottom: {
        style: 'medium',
        color: { argb: 'FF333333' },
      },
      right: {
        style: 'medium',
        color: { argb: 'FF333333' },
      }
    }

    worksheet.mergeCells('D11:F11');
    let savingstext = worksheet.getCell('D11');
    savingstext.value = "Savings % Summary";
    savingstext.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    savingstext.alignment = { vertical: 'middle', horizontal: 'center' }

    let savings = workbook.addImage({
      base64: savingsImage,
      extension: 'png',
    });

    worksheet.mergeCells('B14:H26');
    worksheet.addImage(savings, 'B14:H26');

    let productivity = workbook.addImage({
      base64: productivityImage,
      extension: 'png',
    });

    worksheet.mergeCells('K11:O11');
    let productivitytext = worksheet.getCell('K11');
    productivitytext.value = "Productivity (Deal Wise in %)";
    productivitytext.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    productivitytext.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.mergeCells('K15:O24');
    worksheet.addImage(productivity, 'K15:O24');

    worksheet.mergeCells('B29:H29');
    let locationmixtext = worksheet.getCell('B29');
    locationmixtext.value = "Location Ratio (Deal Wise in %)";
    locationmixtext.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    locationmixtext.alignment = { vertical: 'middle', horizontal: 'center' }

    let locationMix = workbook.addImage({
      base64: locationMixImage,
      extension: 'png',
    });

    worksheet.mergeCells('C31:G44');
    worksheet.addImage(locationMix, 'C31:G44');

    worksheet.mergeCells('K29:O29');
    let blendedratetext = worksheet.getCell('L29');
    blendedratetext.value = "Blended Rate (Deal Wise in USD)";
    blendedratetext.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    blendedratetext.alignment = { vertical: 'middle', horizontal: 'center' }

    let blendedRate = workbook.addImage({
      base64: blendedRateImage,
      extension: 'png',
    });

    worksheet.mergeCells('K32:O43');
    worksheet.addImage(blendedRate, 'K32:O43');

    worksheet.mergeCells('C47:G47');
    let ASIStext = worksheet.getCell('D47');
    ASIStext.value = "AS IS (Top down- FTE based)";
    ASIStext.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    ASIStext.alignment = { vertical: 'middle', horizontal: 'center' }

    //worksheet.mergeCells('C49:075');   
    let gen_info = worksheet.getCell('E49');
    gen_info.value = "General Information";
    gen_info.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    gen_info.alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell('D50').value = "Client:";
    worksheet.getCell('E50').value = this.odata.Client;
    worksheet.getCell('D51').value = "Deal Term:(y)";
    worksheet.getCell('E51').value = this.odata.DealTerm;
    worksheet.getCell('D52').value = "SBU:";
    worksheet.getCell('E52').value = this.odata.SBU;
    worksheet.getCell('D53').value = "FTE:";
    worksheet.getCell('E53').value = this.odata.FTE;

    worksheet.getCell('G50').value = "Input Currency : ",
      worksheet.getCell('I50').value = this.odata.ipcurrency,

      worksheet.getCell('G51').value = "Output Currency : ",
      worksheet.getCell('I51').value = this.odata.opcurrency

    worksheet.getCell('G52').value = "Country : ",
      worksheet.getCell('I52').value = this.odata.ASISCountry

    let deal_info = worksheet.getCell('E55');
    deal_info.value = "Deal Information";
    deal_info.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    deal_info.alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.getCell('D56').value = "Onshore Country :";
    worksheet.getCell('G56').value = this.odata.Ons_Country;

    worksheet.getCell('D57').value = "Offshore Country :";
    worksheet.getCell('G57').value = this.odata.Off_Country;

    worksheet.getCell('D58').value = "Client_FTE_Onshore :";
    worksheet.getCell('G58').value = this.odata.Ons_Client_FTE;

    worksheet.getCell('D59').value = "Client_FTE_Offshore :";
    worksheet.getCell('G59').value = this.odata.Off_Client_FTE;

    worksheet.getCell('D60').value = "Client_Rate_Onshore :",
      worksheet.getCell('G60').value = this.odata.Ons_Client_Rate,

      worksheet.getCell('D61').value = "Client_Rate_Offshore :",
      worksheet.getCell('G61').value = this.odata.Off_Client_Rate,

      worksheet.getCell('D62').value = "Vendor FTE Onshore :",
      worksheet.getCell('G62').value = this.odata.Ons_Vendor_FTE,

      worksheet.getCell('D63').value = "Vendor FTE Offshore : ",
      worksheet.getCell('G63').value = this.odata.Off_Vendor_FTE,

      worksheet.getCell('D64').value = "Vendor Rate Onshore :",
      worksheet.getCell('G64').value = this.odata.Ons_Vendor_Rate,

      worksheet.getCell('D65').value = "Vendor Rate Offshore :",
      worksheet.getCell('G65').value = this.odata.Off_Vendor_Rate,

      worksheet.getCell('D66').value = "ExistingCG FTE Onshore :",
      worksheet.getCell('G66').value = this.odata.Ons_CG_FTE,

      worksheet.getCell('D67').value = "ExistingCG FTE Offshore : ",
      worksheet.getCell('G67').value = this.odata.Off_CG_FTE,

      worksheet.getCell('D68').value = "ExistingCG Rate Onshore :",
      worksheet.getCell('G68').value = this.odata.Ons_CG_Rate,

      worksheet.getCell('D69').value = "ExistingCG Rate Offshore :",
      worksheet.getCell('G69').value = this.odata.Off_CG_Rate,

      worksheet.getCell('D70').value = "Onshore Inflation Y1 : ",
      worksheet.getCell('G70').value = this.odata.Ons_Inflation_Y1,

      worksheet.getCell('D71').value = "Offshore Inflation Y1 : ",
      worksheet.getCell('G71').value = this.odata.Off_Inflation_Y1,

      worksheet.getCell('D72').value = "Onshore Inflation Y2 : ",
      worksheet.getCell('G72').value = this.odata.Ons_Inflation_Y2,

      worksheet.getCell('D73').value = "Offshore Inflation Y2 : ",
      worksheet.getCell('G73').value = this.odata.Off_Inflation_Y2,

      worksheet.getCell('D74').value = "Onshore Inflation Y3 : ",
      worksheet.getCell('G74').value = this.odata.Ons_Inflation_Y3,

      worksheet.getCell('D75').value = "Offshore Inflation Y3 : ",
      worksheet.getCell('G75').value = this.odata.Off_Inflation_Y3,

      worksheet.getCell('D76').value = "Onshore Inflation Y4 : ",
      worksheet.getCell('G76').value = this.odata.Ons_Inflation_Y4,

      worksheet.getCell('D77').value = "Offshore Inflation Y4 : ",
      worksheet.getCell('G77').value = this.odata.Off_Inflation_Y4,

      worksheet.getCell('D78').value = "Onshore Inflation Y5 : ",
      worksheet.getCell('G78').value = this.odata.Ons_Inflation_Y5,

      worksheet.getCell('D79').value = "Offshore Inflation Y5 : ",
      worksheet.getCell('G79').value = this.odata.Off_Inflation_Y5

    let Rev_info = worksheet.getCell('E82');
    Rev_info.value = "Revenue, CM%, Blended Rate Details";
    Rev_info.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    Rev_info.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getCell('D83').value = "Overall CM% Onshore : ",
      worksheet.getCell('G83').value = this.revCM3;

    worksheet.getCell('D84').value = "Overall CM% Offshore : ",
      worksheet.getCell('G84').value = this.revCM2;

    worksheet.getCell('D85').value = "Overall CM% Nearshore : ",
      worksheet.getCell('G85').value = this.revCM1;

    worksheet.getCell('D86').value = "Overall Revenue Onshore : ",
      worksheet.getCell('G86').value = this.revRev3;

    worksheet.getCell('D87').value = "Overall Revenue Offshore : ",
      worksheet.getCell('G87').value = this.revRev2;

    worksheet.getCell('D88').value = "Overall Revenue Nearshore : ",
      worksheet.getCell('G88').value = this.revRev1;

    worksheet.getCell('D89').value = "Year1 CM% Onshore : ",
      worksheet.getCell('G89').value = this.rev_CM3;

    worksheet.getCell('D90').value = "Year1 CM% Offshore : ",
      worksheet.getCell('G90').value = this.rev_CM2;

    worksheet.getCell('D91').value = "Year1 CM% Nearshore : ",
      worksheet.getCell('G91').value = this.rev_CM1;

    worksheet.getCell('D92').value = "Year1 Revenue Onshore : ",
      worksheet.getCell('G92').value = this.rev_Rev3;

    worksheet.getCell('D93').value = "Year 1 Revenue Offshore : ",
      worksheet.getCell('G93').value = this.rev_Rev2;

    worksheet.getCell('D94').value = "Year 1 Revenue Nearshore : ",
      worksheet.getCell('G94').value = this.rev_Rev1;

    worksheet.getCell('D95').value = "Year 1 Cost Onshore : ",
      worksheet.getCell('G95').value = this.rev_Cost3;

    worksheet.getCell('D96').value = "Year 1 Cost Offshore : ",
      worksheet.getCell('G96').value = this.rev_Cost2;

    worksheet.getCell('D97').value = "Year 1 Cost Nearshore : ",
      worksheet.getCell('G97').value = this.rev_Cost1;

    //////////Y2/////////////////
    worksheet.getCell('D98').value = "Year 2 CM% Onshore : ",
      worksheet.getCell('G98').value = this.rev_CM3_year2;

    worksheet.getCell('D99').value = "Year 2 CM% Offshore : ",
      worksheet.getCell('G99').value = this.rev_CM2_year2;

    worksheet.getCell('D100').value = "Year 2 CM% Nearshore : ",
      worksheet.getCell('G100').value = this.rev_CM1_year2;

    worksheet.getCell('D101').value = "Year 2 Revenue Onshore : ",
      worksheet.getCell('G101').value = this.rev_Rev3_year2;

    worksheet.getCell('D102').value = "Year 2 Revenue Offshore : ",
      worksheet.getCell('G102').value = this.rev_Rev2_year2;

    worksheet.getCell('D103').value = "Year 2 Revenue Nearshore : ",
      worksheet.getCell('G103').value = this.rev_Rev1_year2;

    worksheet.getCell('D104').value = "Year 2 Cost Onshore : ",
      worksheet.getCell('G104').value = this.rev_Cost3_year2;

    worksheet.getCell('D105').value = "Year 2 Cost Offshore : ",
      worksheet.getCell('G105').value = this.rev_Cost2_year2;

    worksheet.getCell('D106').value = "Year2 Cost Nearshore : ",
      worksheet.getCell('G106').value = this.rev_Cost1_year2;

    /////////Y3///////////
    worksheet.getCell('D107').value = "Year 3 CM% Onshore : ",
      worksheet.getCell('G107').value = this.rev_CM3_year3;

    worksheet.getCell('D108').value = "Year 3 CM% Offshore : ",
      worksheet.getCell('G108').value = this.rev_CM2_year3;

    worksheet.getCell('D109').value = "Year 3 CM% Nearshore : ",
      worksheet.getCell('G109').value = this.rev_CM1_year3;

    worksheet.getCell('D110').value = "Year 3 Revenue Onshore : ",
      worksheet.getCell('G110').value = this.rev_Rev3_year3;

    worksheet.getCell('D111').value = "Year 3 Revenue Offshore : ",
      worksheet.getCell('G111').value = this.rev_Rev2_year3;

    worksheet.getCell('D112').value = "Year 3 Revenue Nearshore : ",
      worksheet.getCell('G112').value = this.rev_Rev1_year3;

    worksheet.getCell('D113').value = "Year 3 Cost Onshore : ",
      worksheet.getCell('G113').value = this.rev_Cost3_year3;

    worksheet.getCell('D114').value = "Year3 Cost Offshore : ",
      worksheet.getCell('G114').value = this.rev_Cost2_year3;

    worksheet.getCell('D115').value = "Year3 Cost Nearshore : ",
      worksheet.getCell('G115').value = this.rev_Cost1_year3;

    /////Y4//////////////////
    worksheet.getCell('D116').value = "Year 4 CM% Onshore : ",
      worksheet.getCell('G116').value = this.rev_CM3_year4;

    worksheet.getCell('D117').value = "Year 4 CM% Offshore : ",
      worksheet.getCell('G117').value = this.rev_CM2_year4;

    worksheet.getCell('D118').value = "Year 4 CM% Nearshore : ",
      worksheet.getCell('G118').value = this.rev_CM1_year4;

    worksheet.getCell('D119').value = "Year 4 Revenue Onshore : ",
      worksheet.getCell('G119').value = this.rev_Rev3_year4;

    worksheet.getCell('D120').value = "Year 4 Revenue Offshore : ",
      worksheet.getCell('G120').value = this.rev_Rev2_year4;

    worksheet.getCell('D121').value = "Year 4 Revenue Nearshore : ",
      worksheet.getCell('G121').value = this.rev_Rev1_year4;

    worksheet.getCell('D122').value = "Year 4 Cost Onshore : ",
      worksheet.getCell('G122').value = this.rev_Cost3_year4;

    worksheet.getCell('D123').value = "Year 4 Cost Offshore : ",
      worksheet.getCell('G123').value = this.rev_Cost2_year4;

    worksheet.getCell('D124').value = "Year 4 Cost Nearshore : ",
      worksheet.getCell('G124').value = this.rev_Cost1_year4;

    /////Y5///////

    worksheet.getCell('D125').value = "Year 5 CM% Onshore : ",
      worksheet.getCell('G125').value = this.rev_CM3_year5;

    worksheet.getCell('D126').value = "Year 5 CM% Offshore : ",
      worksheet.getCell('G126').value = this.rev_CM2_year5;

    worksheet.getCell('D127').value = "Year 5 CM% Nearshore : ",
      worksheet.getCell('G127').value = this.rev_CM1_year5;

    worksheet.getCell('D128').value = "Year 5 Revenue Onshore : ",
      worksheet.getCell('G128').value = this.rev_Rev3_year5;

    worksheet.getCell('D129').value = "Year 5 Revenue Offshore : ",
      worksheet.getCell('G129').value = this.rev_Rev2_year5;

    worksheet.getCell('D130').value = "Year 5 Revenue Nearshore : ",
      worksheet.getCell('G130').value = this.rev_Rev1_year5;

    worksheet.getCell('D131').value = "Year 5 Cost Onshore : ",
      worksheet.getCell('G131').value = this.rev_Cost3_year5;

    worksheet.getCell('D132').value = "Year 5 Cost Offshore : ",
      worksheet.getCell('G132').value = this.rev_Cost2_year5;

    worksheet.getCell('D133').value = "Year 5 Cost Nearshore : ",
      worksheet.getCell('G133').value = this.rev_Cost1_year5;


    /////////////////////////////////////////////////////////////
    worksheet.getCell('D134').value = "BlendedRate Overall Onshore : ",
      worksheet.getCell('G134').value = this.blendedOverall3;

    worksheet.getCell('D135').value = "BlendedRate Overall Offshore : ",
      worksheet.getCell('G135').value = this.blendedOverall2;

    worksheet.getCell('D136').value = "BlendedRate Overall Nearshore : ",
      worksheet.getCell('G136').value = this.blendedOverall1;

    worksheet.getCell('D137').value = "BlendedRate Overall : ",
      worksheet.getCell('G137').value = this.blendedOver;

    worksheet.getCell('D138').value = "BlendedRate Year1 Onshore : ",
      worksheet.getCell('G138').value = this.blendedyear3;

    worksheet.getCell('D139').value = "BlendedRate Year1 Offshore : ",
      worksheet.getCell('G139').value = this.blendedyear2;

    worksheet.getCell('D140').value = "BlendedRate Year1 Nearshore : ",
      worksheet.getCell('G140').value = this.blendedyear1;

    /////////////////////////Y2////////////////////

    worksheet.getCell('D141').value = "BlendedRate Year2 Onshore : ",
      worksheet.getCell('G141').value = this.blendedyear3_Y2;

    worksheet.getCell('D142').value = "BlendedRate Year2 Offshore : ",
      worksheet.getCell('G142').value = this.blendedyear2_Y2;

    worksheet.getCell('D143').value = "BlendedRate Year2 Nearshore : ",
      worksheet.getCell('G143').value = this.blendedyear1_Y2;
    /////////////////////////Y3///////////////////////////////////////
    worksheet.getCell('D144').value = "BlendedRate Year3 Onshore : ",
      worksheet.getCell('G144').value = this.blendedyear3_Y3;

    worksheet.getCell('D145').value = "BlendedRate Year3 Offshore : ",
      worksheet.getCell('G145').value = this.blendedyear2_Y3;

    worksheet.getCell('D146').value = "BlendedRate Year3 Nearshore : ",
      worksheet.getCell('G146').value = this.blendedyear1_Y3;

    /////////////////////////////Y4//////////////////////////////////
    worksheet.getCell('D147').value = "BlendedRate Year4 Onshore : ",
      worksheet.getCell('G147').value = this.blendedyear3_Y4;

    worksheet.getCell('D148').value = "BlendedRate Year4 Offshore : ",
      worksheet.getCell('G148').value = this.blendedyear2_Y4;

    worksheet.getCell('D149').value = "BlendedRate Year4 Nearshore : ",
      worksheet.getCell('G149').value = this.blendedyear1_Y4;

    /////////////////////////Y5////////////////////////////////////
    worksheet.getCell('D150').value = "BlendedRate Year5 Onshore : ",
      worksheet.getCell('G150').value = this.blendedyear3_Y5;

    worksheet.getCell('D151').value = "BlendedRate Year5 Offshore : ",
      worksheet.getCell('G151').value = this.blendedyear2_Y5;

    worksheet.getCell('D152').value = "BlendedRate Year5 Nearshore : ",
      worksheet.getCell('G152').value = this.blendedyear1_Y5;

    ///////////////////////////Y1////////////////////////////////
    worksheet.getCell('D153').value = "BlendedRate Overall Year 1 : ",
      worksheet.getCell('G153').value = this.blendedyear;
    //////////////////////////Y2/////////////////////////////////
    worksheet.getCell('D154').value = "BlendedRate Overall Year 2 : ",
      worksheet.getCell('G154').value = this.blendedyear_2;
    //////////////////////////Y3/////////////////////////////////
    worksheet.getCell('D155').value = "BlendedRate Overall Year 3 : ",
      worksheet.getCell('G155').value = this.blendedyear_3;
    //////////////////////////Y4/////////////////////////////////
    worksheet.getCell('D156').value = "BlendedRate Overall Year 4 : ",
      worksheet.getCell('G156').value = this.blendedyear_4;
    //////////////////////////Y5/////////////////////////////////
    worksheet.getCell('D157').value = "BlendedRate Overall Year 5 : ",
      worksheet.getCell('G157').value = this.blendedyear_5;
    ///////////////////////////////////////////////////////////////////
    let Tobe_info = worksheet.getCell('L47');
    Tobe_info.value = "TO BE (Solution Parameters)";
    Tobe_info.font = {
      size: 12,
      bold: true,
      color: { argb: '0085A3' }
    }
    Tobe_info.alignment = { vertical: 'middle', horizontal: 'center' }

    worksheet.getCell('K49').value = "Productivity %:  ",
      worksheet.getCell('N49').value = this.prodVal,

      worksheet.getCell('K50').value = "Onshore Location Mix :   ",
      worksheet.getCell('N50').value = this.Locval_Ons,

      worksheet.getCell('K51').value = "Offshore Location Mix:   ",
      worksheet.getCell('N51').value = this.Locval_Off,

      worksheet.getCell('K52').value = "Nearshore Location Mix: :  ",
      worksheet.getCell('N52').value = this.Locval_Nea,

      worksheet.getCell('K53').value = "Onshore Pyramid :  ",
      worksheet.getCell('N53').value = this.PyronshoreVal,

      worksheet.getCell('K54').value = "Offshore Pyramid:  ",
      worksheet.getCell('N54').value = this.PyroffshoreVal,

      worksheet.getCell('K55').value = "Nearshore Pyramid:  ",
      worksheet.getCell('N55').value = this.PyrnearshoreVal,

      worksheet.getCell('K56').value = "Onshore Margin :  ",
      worksheet.getCell('N56').value = this.MaronshoreVal,

      worksheet.getCell('K57').value = "Offshore Margin:  ",
      worksheet.getCell('N57').value = this.MaroffshoreVal,

      worksheet.getCell('K58').value = "Nearshore Margin: :  ",
      worksheet.getCell('N58').value = this.MarnearshoreVal,

      worksheet.getCell('K59').value = "Transition Cost : ",
      worksheet.getCell('N59').value = this.transtionVal,

      worksheet.getCell('K60').value = "Governance Cost:  ",
      worksheet.getCell('N60').value = this.governanceVal,

      worksheet.getCell('K61').value = "Other Expenses : ",
      worksheet.getCell('N61').value = this.othersVal,

      worksheet.getCell('K62').value = "Contingency Cost:  ",
      worksheet.getCell('N62').value = this.contingencyVal,

      worksheet.getCell('K63').value = "Discount:  ",
      worksheet.getCell('N63').value = this.odata.discount,

      worksheet.getCell('K64').value = "Negotiation Buffer:  ",
      worksheet.getCell('N64').value = this.odata.negotiation,

      worksheet.getCell('K65').value = "Internet/ Bandwidth/ Connectivity:  ",
      worksheet.getCell('N65').value = this.odata.connectivity,

      worksheet.getCell('K66').value = "Productivity Custom Y1",
      worksheet.getCell('N66').value = this.odata.Prod_custom_Y1,

      worksheet.getCell('K67').value = "Productivity Custom Y2",
      worksheet.getCell('N67').value = this.odata.Prod_custom_Y2,

      worksheet.getCell('K68').value = "Productivity Custom Y3",
      worksheet.getCell('N68').value = this.odata.Prod_custom_Y3,

      worksheet.getCell('K69').value = "Productivity Custom Y4",
      worksheet.getCell('N69').value = this.odata.Prod_custom_Y4,

      worksheet.getCell('K70').value = "Productivity Custom Y5",
      worksheet.getCell('N70').value = this.odata.Prod_custom_Y5,

      worksheet.getCell('K71').value = "Locationmix Onsite Custom Y1",
      worksheet.getCell('N71').value = this.odata.LocOns_custom_Y1,

      worksheet.getCell('K72').value = "Locationmix Onsite Custom Y2",
      worksheet.getCell('N72').value = this.odata.LocOns_custom_Y2,

      worksheet.getCell('K73').value = "Locationmix Onsite Custom Y3",
      worksheet.getCell('N73').value = this.odata.LocOns_custom_Y3,

      worksheet.getCell('K74').value = "Locationmix Onsite Custom Y4",
      worksheet.getCell('N74').value = this.odata.LocOns_custom_Y4,

      worksheet.getCell('K75').value = "Locationmix Onsite Custom Y5",
      worksheet.getCell('N75').value = this.odata.LocOns_custom_Y5,

      worksheet.getCell('K76').value = "Locationmix Offshore Custom Y1",
      worksheet.getCell('N76').value = this.odata.LocOff_custom_Y1,

      worksheet.getCell('K77').value = "Locationmix Offshore Custom Y2",
      worksheet.getCell('N77').value = this.odata.LocOff_custom_Y2,

      worksheet.getCell('K78').value = "Locationmix Offshore Custom Y3",
      worksheet.getCell('N78').value = this.odata.LocOff_custom_Y3,

      worksheet.getCell('K79').value = "Locationmix Offshore Custom Y4",
      worksheet.getCell('N79').value = this.odata.LocOff_custom_Y4,

      worksheet.getCell('K80').value = "Locationmix Offshore Custom Y5",
      worksheet.getCell('N80').value = this.odata.LocOff_custom_Y5,

      worksheet.getCell('K81').value = "Locationmix Nearshore Custom Y1",
      worksheet.getCell('N81').value = this.odata.LocNearsh_custom_Y1,

      worksheet.getCell('K82').value = "Locationmix Nearshore Custom Y2",
      worksheet.getCell('N82').value = this.odata.LocNearsh_custom_Y2,

      worksheet.getCell('K83').value = "Locationmix Nearshore Custom Y3",
      worksheet.getCell('N83').value = this.odata.LocNearsh_custom_Y3,

      worksheet.getCell('K84').value = "Locationmix Nearshore Custom Y4",
      worksheet.getCell('N84').value = this.odata.LocNearsh_custom_Y4,

      worksheet.getCell('K85').value = "Locationmix Nearshore Custom Y5",
      worksheet.getCell('N85').value = this.odata.LocNearsh_custom_Y5,

      worksheet.getCell('K86').value = "Margin Onsite Custom Y1",
      worksheet.getCell('N86').value = this.odata.Margin_Ons_custom_Y1,

      worksheet.getCell('K87').value = "Margin Onsite Custom Y2",
      worksheet.getCell('N87').value = this.odata.Margin_Ons_custom_Y2,

      worksheet.getCell('K88').value = "Margin Onsite Custom Y3",
      worksheet.getCell('N88').value = this.odata.Margin_Ons_custom_Y3,

      worksheet.getCell('K89').value = "Margin Onsite Custom Y4",
      worksheet.getCell('N89').value = this.odata.Margin_Ons_custom_Y4,

      worksheet.getCell('K90').value = "Margin Onsite Custom Y5",
      worksheet.getCell('N90').value = this.odata.Margin_Ons_custom_Y5,

      worksheet.getCell('K91').value = "Margin Offshore Custom Y1",
      worksheet.getCell('N91').value = this.odata.Margin_Off_custom_Y1,

      worksheet.getCell('K92').value = "Margin Offshore Custom Y2",
      worksheet.getCell('N92').value = this.odata.Margin_Off_custom_Y2,

      worksheet.getCell('K93').value = "Margin Offshore Custom Y3",
      worksheet.getCell('N93').value = this.odata.Margin_Off_custom_Y3,

      worksheet.getCell('K94').value = "Margin Offshore Custom Y4",
      worksheet.getCell('N94').value = this.odata.Margin_Off_custom_Y4,

      worksheet.getCell('K95').value = "Margin Offshore Custom Y5",
      worksheet.getCell('N95').value = this.odata.Margin_Off_custom_Y5,

      worksheet.getCell('K96').value = "Margin Nearshore Custom Y1",
      worksheet.getCell('N96').value = this.odata.Margin_Nearsh_custom_Y1,

      worksheet.getCell('K97').value = "Margin Nearshore Custom Y2",
      worksheet.getCell('N97').value = this.odata.Margin_Nearsh_custom_Y2,

      worksheet.getCell('K98').value = "Margin Nearshore Custom Y3",
      worksheet.getCell('N98').value = this.odata.Margin_Nearsh_custom_Y3,

      worksheet.getCell('K99').value = "Margin Nearshore Custom Y4",
      worksheet.getCell('N99').value = this.odata.Margin_Nearsh_custom_Y4,

      worksheet.getCell('K100').value = "Margin Nearshore Custom Y5",
      worksheet.getCell('N100').value = this.odata.Margin_Nearsh_custom_Y5,

      worksheet.getCell('K101').value = "Transition Custom",
      worksheet.getCell('N101').value = this.odata.Trn_Custom,

      worksheet.getCell('K102').value = "Governance Custom Y1",
      worksheet.getCell('N102').value = this.odata.Governance_custom_Y1,

      worksheet.getCell('K103').value = "Governance Custom Y2",
      worksheet.getCell('N103').value = this.odata.Governance_custom_Y2,

      worksheet.getCell('K104').value = "Governance Custom Y3",
      worksheet.getCell('N104').value = this.odata.Governance_custom_Y3,

      worksheet.getCell('K105').value = "Governance Custom Y4",
      worksheet.getCell('N105').value = this.odata.Governance_custom_Y4,

      worksheet.getCell('K106').value = "Governance Custom Y5",
      worksheet.getCell('N106').value = this.odata.Governance_custom_Y5,

      worksheet.getCell('K107').value = "Other Expenses Custom Y1",
      worksheet.getCell('N107').value = this.odata.Expense_custom_Y1,

      worksheet.getCell('K108').value = "Other Expenses  Custom Y2",
      worksheet.getCell('N108').value = this.odata.Expense_custom_Y2,

      worksheet.getCell('K109').value = "Other Expenses  Custom Y3",
      worksheet.getCell('N109').value = this.odata.Expense_custom_Y3,

      worksheet.getCell('K110').value = "Other Expenses  Custom Y4",
      worksheet.getCell('N110').value = this.odata.Expense_custom_Y4,

      worksheet.getCell('K111').value = "Other Expenses  Custom Y5",
      worksheet.getCell('N111').value = this.odata.Expense_custom_Y5,

      worksheet.getCell('K112').value = "Contingency Custom Y1",
      worksheet.getCell('N112').value = this.odata.Contigency_custom_Y1,

      worksheet.getCell('K113').value = "Contingency Custom Y2",
      worksheet.getCell('N113').value = this.odata.Contigency_custom_Y2,

      worksheet.getCell('K114').value = "Contingency Custom Y3",
      worksheet.getCell('N114').value = this.odata.Contigency_custom_Y3,

      worksheet.getCell('K115').value = "Contingency Custom Y4",
      worksheet.getCell('N115').value = this.odata.Contigency_custom_Y4,

      worksheet.getCell('K116').value = "Contingency Custom Y5",
      worksheet.getCell('N116').value = this.odata.Contigency_custom_Y5,




      workbook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'P2WLiteSummary.xlsx');
      });
  }

  downloadtoExcel() {
    let p2wper = this.p2wVal ? this.p2wVal + 'M$' : "0" + 'M$';
    let cmper = this.cmVal ? this.cmVal + '%' : "0" + '%';
    let savper = this.savingsVal ? this.savingsVal + '%' : "0" + '%';
    let prodper = this.productivityVal ? this.productivityVal + '%' : "0" + '%';


    Promise.all([
      (this.summaryDataExporting).getXLSX(),
      (this.summaryDataExporting).export("png"),
      (this.productivityDataExporting).export("png"),
      (this.locationMixDataExporting).export("png"),
      (this.blendedRateDataExporting).export("png"),
    ]).then((res: any) => {
      console.log(res);

      let pdfMake = res[0];
      let array: any[] = [];

      if (this.p2wVal > 0) {

        this.Exportexcel(res[1], res[2], res[3], res[4], p2wper, cmper, savper, prodper);
      }

    })



  }

  downloadtoPDF() {
    let p2wper = this.p2wVal ? this.p2wVal + 'M$' : "0" + 'M$';
    let cmper = this.cmVal ? this.cmVal + '%' : "0" + '%';
    let savper = this.savingsVal ? this.savingsVal + '%' : "0" + '%';
    let prodper = this.productivityVal ? this.productivityVal + '%' : "0" + '%';

    Promise.all([
      (this.summaryDataExporting).getPdfmake(),
      (this.summaryDataExporting).export("png"),
      (this.productivityDataExporting).export("png"),
      (this.locationMixDataExporting).export("png"),
      (this.blendedRateDataExporting).export("png"),
    ]).then((res: any) => {
      console.log(res);

      let pdfMake = res[0];
      let array: any[] = [];


      let doc = {
        pageSize: "A4",
        pageOrientation: "portrait",
        pageMargins: [30, 30, 30, 30],
        content: array
      };

      doc.content.push({
        columns: [{
          text: "Price2Win : " + p2wper,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "CM% : " + cmper,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Savings% : " + savper,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Productivity% : " + prodper,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });

      doc.content.push({
        columns: [{
          text: "Overall CM% Onshore : " + this.revCM3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Overall CM% Offshore : " + this.revCM2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Overall CM% Nearshore : " + this.revCM1,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Overall Revenue Onshore : " + this.revRev3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });
      doc.content.push({
        columns: [{
          text: "Overall Revenue Offshore : " + this.revRev2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Overall Revenue Nearshore : " + this.revRev3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 1 CM% Onshore : " + this.rev_CM3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 1 CM% Offshore : " + this.rev_CM2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });


      doc.content.push({
        columns: [{
          text: "Year 1 CM% Nearshore : " + this.rev_CM1,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 1 Revenue Onshore : " + this.rev_Rev3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 1 Revenue Offshore : " + this.rev_Rev2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 1 Revenue Nearshore : " + this.rev_Rev1,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });


      doc.content.push({
        columns: [{
          text: "Year 1 Cost Nearshore : " + this.rev_Cost1,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 1 Cost Onshore: " + this.rev_Cost3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 1 Cost Offshore : " + this.rev_Cost2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }
        ],
        columnGap: 15
      });

      /////////////Y2///////////////////////////////////////////////////
      doc.content.push({
        columns: [{
          text: "Year 2 CM% Nearshore : " + this.rev_CM1_year2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 2 Revenue Onshore : " + this.rev_Rev3_year2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 2 Revenue Offshore : " + this.rev_Rev2_year2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }

        ],
        columnGap: 15
      });

      doc.content.push({
        columns: [{
          text: "Year 2 Cost Nearshore : " + this.rev_Cost1_year2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 2 Cost Onshore: " + this.rev_Cost3_year2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 2 Cost Offshore : " + this.rev_Cost2_year2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }
        ],
        columnGap: 15
      });
      /////////////////////Y3///////////////////////////////////////////
      doc.content.push({
        columns: [{
          text: "Year 3 CM% Nearshore : " + this.rev_CM1_year3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 3 Revenue Onshore : " + this.rev_Rev3_year3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 3 Revenue Offshore : " + this.rev_Rev2_year3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }

        ],
        columnGap: 15
      });

      doc.content.push({
        columns: [{
          text: "Year 3 Cost Nearshore : " + this.rev_Cost1_year3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 3 Cost Onshore: " + this.rev_Cost3_year3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 3 Cost Offshore : " + this.rev_Cost2_year3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }
        ],
        columnGap: 15
      });
      //////////////////Y4////////////////////////////////////////////////////

      doc.content.push({
        columns: [{
          text: "Year 4 CM% Nearshore : " + this.rev_CM1_year4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 4 Revenue Onshore : " + this.rev_Rev3_year4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 4 Revenue Offshore : " + this.rev_Rev2_year4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }

        ],
        columnGap: 15
      });

      doc.content.push({
        columns: [{
          text: "Year 4 Cost Nearshore : " + this.rev_Cost1_year4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 4 Cost Onshore: " + this.rev_Cost3_year4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 4 Cost Offshore : " + this.rev_Cost2_year4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }
        ],
        columnGap: 15
      });
      /////////////////////////////Y5//////////////////////////////////////////
      doc.content.push({
        columns: [{
          text: "Year 5 CM% Nearshore : " + this.rev_CM1_year5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 5 Revenue Onshore : " + this.rev_Rev3_year5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 5 Revenue Offshore : " + this.rev_Rev2_year5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }

        ],
        columnGap: 15
      });

      doc.content.push({
        columns: [{
          text: "Year 5 Cost Nearshore : " + this.rev_Cost1_year5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "Year 5 Cost Onshore: " + this.rev_Cost3_year5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "Year 5 Cost Offshore : " + this.rev_Cost2_year5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }
        ],
        columnGap: 15
      });
      /////////////////////////////////////////////////////////////////////////////
      doc.content.push({
        columns: [{
          text: "BlendedRate Overall Onshore: " + this.blendedyear3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "BlendedRate Overall Offshore: " + this.blendedyear2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Overall Nearshore: " + this.blendedyear1,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Overall : " + this.blendedyear,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });

      doc.content.push({
        columns: [{
          text: "BlendedRate Year1 Onshore: " + this.blendedyear1,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "BlendedRate Year1 Offshore: " + this.blendedyear2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year1 Nearshore: " + this.blendedyear3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year1 Overall : " + this.blendedyear,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });
      ////////////////Y2////////////////////////////

      doc.content.push({
        columns: [{
          text: "BlendedRate Year 2 Onshore: " + this.blendedyear1_Y2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "BlendedRate Year 2 Offshore: " + this.blendedyear2_Y2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 2 Nearshore: " + this.blendedyear3_Y2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 2 Overall : " + this.blendedyear_2,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });
      //////////////////////Y3////////////////////////////////////
      doc.content.push({
        columns: [{
          text: "BlendedRate Year 3  Onshore: " + this.blendedyear1_Y3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "BlendedRate Year 3  Offshore: " + this.blendedyear2_Y3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 3  Nearshore: " + this.blendedyear3_Y3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 3  Overall : " + this.blendedyear_3,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });
      //////////////Y4///////////////////////////////////

      doc.content.push({
        columns: [{
          text: "BlendedRate Year 4  Onshore: " + this.blendedyear1_Y4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "BlendedRate Year 4 Offshore: " + this.blendedyear2_Y4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 4 Nearshore: " + this.blendedyear3_Y4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 4 Overall : " + this.blendedyear_4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });
      //////////////////Y5///////////////////////////////
      doc.content.push({
        columns: [{
          text: "BlendedRate Year 5 Onshore: " + this.blendedyear1_Y5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        }, {
          text: "BlendedRate Year 5 Offshore: " + this.blendedyear2_Y5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 5 Nearshore: " + this.blendedyear3_Y4,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        {
          text: "BlendedRate Year 5 Overall : " + this.blendedyear_5,
          fontSize: 12,
          width: 125,
          bold: true,
          margin: [0, 15, 0, 15]
        },
        ],
        columnGap: 15
      });
      doc.content.push({
        columns: [{
          text: "Savings % Summary",
          fontSize: 12,
          width: 250,
          bold: true,
          margin: [0, 20, 0, 15]
        }, {
          text: "Productivity (Deal Wise in %)",
          fontSize: 12,
          width: 250,
          bold: true,
          margin: [0, 20, 0, 15]
        }
        ],
        columnGap: 30
      });


      doc.content.push({
        columns: [{
          image: res[1],
          width: 250,
          height: 270
        }, {
          image: res[2],
          width: 250,
          height: 270
        }
        ],
        columnGap: 30
      });

      doc.content.push({
        columns: [{
          text: "Location Ratio (Deal Wise in %)",
          fontSize: 12,
          width: 250,
          bold: true,
          margin: [0, 20, 0, 15]
        }, {
          text: "Blended Rate (Deal Wise in USD)",
          fontSize: 12,
          width: 250,
          bold: true,
          margin: [0, 20, 0, 15]
        }
        ],
        columnGap: 30
      });
      doc.content.push({
        columns: [{
          image: res[3],
          width: 250,
          height: 270
        }, {
          image: res[4],
          width: 250,
          height: 270
        }
        ],
        columnGap: 30
      });
      doc.content.push({
        columns: [{
          text: "AS IS (Top down- FTE based) - General Information",
          fontSize: 12,
          width: 250,
          bold: true,
          margin: [0, 100, 0, 15]
        }
        ],
        columnGap: 30
      });
      doc.content.push({
        columns: [{
          text: "Client :  " + this.odata.Client,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: " Deal Term : (y) " + this.odata.DealTerm,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "SBU :" + this.odata.SBU,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: " Overall FTE : " + this.odata.FTE,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Country : " + this.odata.ASISCountry,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Input Currency : " + this.odata.ipcurrency,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Output Currency : " + this.odata.opcurrency,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "AS IS (Top down- FTE based) - Deal Information",
          fontSize: 12,
          width: 250,
          bold: true,
          margin: [0, 20, 0, 15]
        }
        ],
        columnGap: 30
      });
      doc.content.push({
        columns: [
          {
            text: "Onsite Country : " + this.odata.Ons_Country,
            fontSize: 10,
            width: 125,
            bold: false,
            margin: [0, 20, 0, 5]
          },
          {
            text: "Offshore Country : " + this.odata.Off_Country,
            fontSize: 10,
            width: 125,
            bold: false,
            margin: [0, 20, 0, 5]
          },
          {
            text: "Client FTE Onsite : " + this.odata.Ons_Client_FTE,
            fontSize: 10,
            width: 125,
            bold: false,
            margin: [0, 20, 0, 5]
          },
          {
            text: "Client FTE Offshore : " + this.odata.Off_Client_FTE,
            fontSize: 10,
            width: 125,
            bold: false,
            margin: [0, 20, 0, 5]
          },
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Client Rate Onsite : " + this.odata.Ons_Client_Rate,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Client Rate Offshore : " + this.odata.Off_Client_Rate,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Vendor FTE Onsite : " + this.odata.Ons_Vendor_FTE,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Vendor FTE Offshore : " + this.odata.Off_Vendor_FTE,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Vendor Rate Onsite : " + this.odata.Ons_Vendor_Rate,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Vendor Rate Offshore : " + this.odata.Off_Vendor_Rate,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Existing CG FTE Onsite : " + this.odata.Ons_CG_FTE,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Existing CG FTE Offshore : " + this.odata.Off_CG_FTE,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Existing CG Rate Onsite : " + this.odata.Ons_CG_Rate,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Existing CG Rate Offshore : " + this.odata.Off_CG_Rate,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Inflation Y1 : " + this.odata.Ons_Inflation_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Offshore Inflation Y1 : " + this.odata.Off_Inflation_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Onsite Inflation Y2 : " + this.odata.Ons_Inflation_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Offshore Inflation Y2 : " + this.odata.Off_Inflation_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Inflation Y3 : " + this.odata.Ons_Inflation_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Offshore Inflation Y3 : " + this.odata.Off_Inflation_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Onsite Inflation Y4 : " + this.odata.Ons_Inflation_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Offshore Inflation Y4 : " + this.odata.Off_Inflation_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Inflation Y5 : " + this.odata.Ons_Inflation_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Offshore Inflation Y5 : " + this.odata.Off_Inflation_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "TO BE (Solution Parameters) ",
          fontSize: 10,
          width: 250,
          bold: true,
          margin: [0, 20, 0, 15]
        }
        ],
        columnGap: 30
      });
      doc.content.push({
        columns: [{
          text: "Productivity % : " + this.prodVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Offshore Pyramid : " + this.PyroffshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Pyramid : " + this.PyronshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Nearshore Pyramid : " + this.PyrnearshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Offshore Location Mix : " + this.Locval_Off,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Nearshore Location Mix : " + this.Locval_Nea,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Location Mix : " + this.Locval_Ons,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Margin : " + this.MaronshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Offshore Margin : " + this.MaroffshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Nearshore Margin : " + this.MarnearshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Onsite Margin : " + this.MaronshoreVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Transiton Cost : " + this.transtionVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Governance Cost : " + this.governanceVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Expenses : " + this.othersVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Contingency Cost : " + this.contingencyVal,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Discount : " + this.odata.discount,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Negotiation Buffer: " + this.odata.negotiation,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Internet/ Bandwidth/ Connectivity : " + this.odata.connectivity,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Productivity Custom Y1 : " + this.odata.Prod_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Productivity Custom Y2 : " + this.odata.Prod_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "Productivity Custom Y3 : " + this.odata.Prod_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Productivity Custom Y4" + this.odata.Prod_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Productivity Custom Y5 : " + this.odata.Prod_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Onsite Y1 : " + this.odata.LocOns_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });
      doc.content.push({
        columns: [{
          text: "LocationMix Custom Onsite Y2 : " + this.odata.LocOns_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Onsite Y3 : " + this.odata.LocOns_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "LocationMix Custom Onsite Y4 : " + this.odata.LocOns_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Onsite Y5 : " + this.odata.LocOns_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "LocationMix Custom Offshore Y1 : " + this.odata.LocOff_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Offshore Y2 : " + this.odata.LocOff_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "LocationMix Custom Offshore Y5 : " + this.odata.LocOff_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Nearshore Y1 : " + this.odata.LocNearsh_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "LocationMix Custom Nearshore Y2 : " + this.odata.LocNearsh_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Nearshore Y3 : " + this.odata.LocNearsh_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "LocationMix Custom Nearshore Y4 : " + this.odata.LocNearsh_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "LocationMix Custom Nearshore Y5 : " + this.odata.LocNearsh_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Margin Custom Onsite Y1 : " + this.odata.Margin_Ons_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Onsite Y2 : " + this.odata.Margin_Ons_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Margin Custom Onsite Y3 : " + this.odata.Margin_Ons_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Onsite Y4 : " + this.odata.Margin_Ons_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Margin Custom Onsite Y5 : " + this.odata.Margin_Ons_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Offshore Y1 : " + this.odata.Margin_Off_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Margin Custom Offshore Y2 : " + this.odata.Margin_Ons_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Offshore Y3 : " + this.odata.Margin_Off_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Margin Custom Offshore Y4 : " + this.odata.Margin_Ons_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Offshore Y5 : " + this.odata.Margin_Off_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Margin Custom Nearshore Y1 : " + this.odata.Margin_Nearsh_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Nearshore Y2 : " + this.odata.Margin_Nearsh_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Margin Custom Nearshore Y3 : " + this.odata.Margin_Nearsh_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Margin Custom Nearshore Y4 : " + this.odata.Margin_Nearsh_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Margin Custom Nearshore Y5 : " + this.odata.Margin_Nearsh_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Transition Custom : " + this.odata.Trn_Custom,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Governance Custom Y1 : " + this.odata.Governance_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Governance Custom Y2 : " + this.odata.Governance_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Governance Custom Y3 : " + this.odata.Governance_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Governance Custom Y4 : " + this.odata.Governance_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Governance Custom Y5 : " + this.odata.Governance_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Other Expense Custom Y1 : " + this.odata.Expense_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Other Expense Custom Y2 : " + this.odata.Expense_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Other Expense Custom Y3 : " + this.odata.Expense_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Other Expense Custom Y4 : " + this.odata.Expense_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Other Expense Custom Y5 : " + this.odata.Expense_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Contingency Cost Custom Y1 : " + this.odata.Contigency_custom_Y1,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Contingency Cost Custom Y2 : " + this.odata.Contigency_custom_Y2,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      doc.content.push({
        columns: [{
          text: "Contingency Cost Custom Y3 : " + this.odata.Contigency_custom_Y3,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        },
        {
          text: "Contingency Cost Custom Y4 : " + this.odata.Contigency_custom_Y4,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }, {
          text: "Contingency Cost Custom Y5 : " + this.odata.Contigency_custom_Y5,
          fontSize: 10,
          width: 125,
          bold: false,
          margin: [0, 20, 0, 5]
        }
        ],
        columnGap: 20
      });

      if (this.p2wVal > 0) {
        pdfMake.createPdf(doc).download("P2WLiteSummary.pdf");
      }

    })

  }

  getRangeSlider(Tname: any) {
    let tabname = Tname;
    let subscribe$: Subscription = this.litehomeService.getSliderRange(tabname).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.rangeList = result.Data;
      }

      this.IsLoading = false;
    },

      err => {
        console.log('Error on getting range details.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
    // this.DealInfoForm.enableFields('Nearshore', true);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  onInflationClick() {
    this.Inflation = [];
    this.yearName = [];

    if (!isNullOrUndefined(this.dealterm) && this.dealterm != "") {

      switch (this.dealterm) {
        case "1":
          (this.Inflation).push(this.Infl_arr[0])
          break;
        case "2":
          (this.Inflation).push(this.Infl_arr[0], this.Infl_arr[1])
          break;
        case "3":
          (this.Inflation).push(this.Infl_arr[0], this.Infl_arr[1], this.Infl_arr[2])
          break;
        case "4":
          (this.Inflation).push(this.Infl_arr[0], this.Infl_arr[1], this.Infl_arr[2], this.Infl_arr[3])
          break;
        case "5":
          (this.Inflation).push(this.Infl_arr[0], this.Infl_arr[1], this.Infl_arr[2], this.Infl_arr[3], this.Infl_arr[4])
          break;
      }
      this.openModal('inflationpopup');
    }
    else {
      this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
    }

  }

  onPyrIconClick() {
    let onshorecode = "";
    let offshorecode = "";
    let nearshorecode = "";
    if (this.IsLocnearshore == true) {
      nearshorecode = 'NEARSH'
    }
    if (this.IsLocoffshore == true) {
      offshorecode = 'OFFSH'
    }
    if (this.IsLoconshore == true) {
      onshorecode = 'ONSIT'
    }

    let subscribe$: Subscription = this.litehomeService.getPyrMixInfo(onshorecode, offshorecode, nearshorecode).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.pyramidInfoDetail = result.Data;
        this.pyrMixLength = this.pyramidInfoDetail.length;
      }

      this.IsLoading = false;
    },

      err => {
        console.log('Error on getting range details.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

    this.modalInf =
    {
      header: '',
      size: 'modal-lg modal-dialog-centered modal-dialog-scrollable',
      width: 'auto',
      height: 'auto'
    };
    this.openModal('pyramidInfopopup');

  }

  onProdIconClick() {
    this.locationMixInfoDetail = {};
    let onshorecode = "";
    let offshorecode = "";
    let nearshorecode = "";
    if (this.IsLocnearshore == true) {
      nearshorecode = 'NEARSH'
    }
    if (this.IsLocoffshore == true) {
      offshorecode = 'OFFSH'
    }
    if (this.IsLoconshore == true) {
      onshorecode = 'ONSIT'
    }

    let subscribe$: Subscription = this.litehomeService.getLocationMixInfo(onshorecode, offshorecode, nearshorecode).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.locationMixInfoDetail = result.Data;
        this.locMixLength = this.locationMixInfoDetail.length;
      }

      this.IsLoading = false;
    },

      err => {
        console.log('Error on getting range details.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);

    this.modalInf =
    {
      header: '',
      size: 'modal-lg modal-dialog-centered modal-dialog-scrollable',
      width: 'auto',
      height: 'auto'
    };
    this.openModal('locationmixpopup');
  }

  onProdCustomChange(event: any) {
    let val = (document.getElementById('custom-id') as HTMLInputElement).value;
    //this.odata.Prd_Custom=val;
    console.log(val)
  }

  onRangeClick(event: any) {
    console.log(event);
    this.IsCustomSelected = true;
    this.prodVal = event.CodeDescription;
    if (event.CodeDescription == 'Custom') {
      this.IsCustomSelected = true;
      this.odata.Prd_Id = event.Code;
      this.odata.Prd_Custom = this.odata.Prd_Custom;

      // this.ProdArray=[];
      if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
        this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);

      }


    }
    else {
      this.IsCustomSelected = false;
      this.odata.Prd_Id = event.Code;
      this.odata.Prd_Custom = 0;
      this.getSummaryGraphData(this.odata);
    }
    this.FTEValidation();
    this.LocationValidation();
  }

  onLocationClick(event: any) {
    console.log(event.item)
    //this.LocVal = "";
    // this.odata.Loc_Ons_Country = event.Onshore;
    this.odata.Loc_Ons_Hub = "234"
    // this.odata.Loc_Nea_Country = event.Nearshore
    this.odata.Loc_Id = event.Code;
    // if(event.Onshore!='' || event.Offshore!=''){
    //   this.DashboardData.IsEditable=1;
    // }

    debugger
    if (this.odata.Loc_Ons_Country == 'US' && (this.odata.Loc_Ons_City == '' || this.odata.Loc_Ons_City == null)) {
      this.showNotificationMessage('Please select the Onshore City', 2, 'Error', '', '', 'ok', 5000);
    }
    else {
      this.getSummaryGraphData(this.odata);
    }

    if (event.CodeDescription == 'Custom') {
      if (!isNullOrUndefined(this.dealterm) && this.dealterm != "") {
        this.modalService.open('LocCustomPopUp')
      }
      else {
        this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
      }

    }


    if (this.odata.Loc_Off_Sel == 1) {
      this.Locval_Off = event.CodeDescription;
    }
    if (this.odata.Loc_Ons_Sel == 1) {
      this.Locval_Ons = event.CodeDescription;
    }
    if (this.odata.Loc_Nea_Sel == 1) {
      this.Locval_Nea = event.CodeDescription;
    }
    this.LocVal = event.CodeDescription;
    this.FTEValidation();
  }

  onPyramidClick(event: any, type: string) {
    console.log(event);

    if (type == 'ONSHORE') {
      this.PyronshoreVal = "";
      this.PyronshoreVal = event.CodeDescription;
      if (event.CodeDescription == 'Custom') {
        // this.getSummaryGraphData(this.odata);
        this.odata.Pym_Ons_Id = event.Code;
        this.odata.Pyr_Ons_Custom = this.odata.Pyr_Ons_Custom;
        this.IsPyrOnsCustomSelected = true;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.odata.Pym_Ons_Id = event.Code;
        this.odata.Pyr_Ons_Custom = 0
        this.getSummaryGraphData(this.odata);
        this.IsPyrOnsCustomSelected = false;
      }
    }
    if (type == 'OFFSHORE') {
      this.PyroffshoreVal = "";
      this.PyroffshoreVal = event.CodeDescription;
      if (event.CodeDescription == 'Custom') {
        // this.getSummaryGraphData(this.odata);
        this.odata.Pym_Off_Id = event.Code;
        this.odata.Pyr_Off_Custom = this.odata.Pyr_Off_Custom;
        this.IsPyrOffCustomSelected = true;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.odata.Pym_Off_Id = event.Code;
        this.odata.Pyr_Off_Custom = 0
        this.getSummaryGraphData(this.odata);
        this.IsPyrOffCustomSelected = false;
      }
    }
    if (type == 'NEARSHORE') {

      this.PyrnearshoreVal = "";
      this.PyrnearshoreVal = event.CodeDescription;
      if (event.CodeDescription == 'Custom') {
        // this.getSummaryGraphData(this.odata);
        this.odata.Pym_Nea_Id = event.Code;
        this.odata.Pyr_Nearsh_Custom = this.odata.Pyr_Off_Custom;
        this.IsPyrNearshCustomSelected = true;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.odata.Pym_Nea_Id = event.Code;
        this.odata.Pyr_Nearsh_Custom = 0
        this.getSummaryGraphData(this.odata);
        this.IsPyrNearshCustomSelected = false;
      }
    }
    this.FTEValidation();
  }

  onMarginClick(event: any, type: string) {

    if (type == 'ONSHORE') {
      this.MaronshoreVal = "";
      this.MaronshoreVal = event.CodeDescription;
      if (event.CodeDescription == 'Custom') {
        // this.getSummaryGraphData(this.odata);
        this.odata.Mrg_Ons_Id = event.Code;
        this.odata.Mrg_Ons_Custom = this.odata.Mrg_Ons_Custom;
        this.IsOnShoreCustomSelected = true;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.odata.Mrg_Ons_Id = event.Code;
        this.odata.Mrg_Ons_Custom = 0
        this.getSummaryGraphData(this.odata);
        this.IsOnShoreCustomSelected = false;
      }

    }
    if (type == 'OFFSHORE') {
      this.MaroffshoreVal = "";
      this.MaroffshoreVal = event.CodeDescription;
      if (event.CodeDescription == 'Custom') {
        // this.getSummaryGraphData(this.odata);
        this.odata.Mrg_Off_Id = event.Code;
        this.odata.Mrg_Off_Custom = this.odata.Mrg_Off_Custom;
        this.IsOffshoreCustomSelected = true;

        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.odata.Mrg_Off_Id = event.Code;
        this.odata.Mrg_Off_Custom = 0
        this.getSummaryGraphData(this.odata);
        this.IsOffshoreCustomSelected = false;
      }

    }
    if (type == 'NEARSHORE') {
      this.MarnearshoreVal = "";
      this.MarnearshoreVal = event.CodeDescription;
      if (event.CodeDescription == 'Custom') {
        // this.getSummaryGraphData(this.odata);
        this.odata.Mrg_Nea_Id = event.Code;
        this.odata.Mrg_Nea_Custom = this.odata.Mrg_Nea_Custom;
        this.IsNearshoreCustomSelected = true;

        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.odata.Mrg_Nea_Id = event.Code;
        this.odata.Mrg_Nea_Custom = 0;
        this.getSummaryGraphData(this.odata);
        this.IsNearshoreCustomSelected = false;
      }

    }
    this.FTEValidation();
  }

  onOthersClick(event: any, type: string) {
    console.log(event);

    if (type == 'TRANSITION') {
      this.odata.Trn_Id = event.Code;
      this.odata.Trn_Custom = 0;

      this.transtionVal = event.CodeDescription;

      if (event.CodeDescription == "Custom") {
        this.IstransCustomSelected = true;
        this.odata.Trn_Custom = this.odata.Trn_Custom;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.IstransCustomSelected = false;
        this.odata.Trn_Custom = 0;
        this.getSummaryGraphData(this.odata);
      }
    }
    if (type == 'GOVERNANCE') {
      this.odata.Gov_Id = event.Code;
      this.odata.Gov_Custom = 0;

      this.governanceVal = event.CodeDescription;

      if (event.CodeDescription == "Custom") {
        this.IsgovCustomSelected = true;
        this.odata.Gov_Custom = this.odata.Gov_Custom;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.IsgovCustomSelected = false;
        this.odata.Gov_Custom = 0;
        this.getSummaryGraphData(this.odata);
      }
    }

    if (type == 'CONTINGENCY') {
      this.odata.contigency_Id = event.Code;
      this.odata.Con_Custom = 0;

      this.contingencyVal = event.CodeDescription;

      if (event.CodeDescription == "Custom") {
        this.IsConCustomSelected = true;
        this.odata.Con_Custom = this.odata.Exp_Custom;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.IsConCustomSelected = false;
        this.odata.Con_Custom = 0;
        this.getSummaryGraphData(this.odata);
      }

    }
    if (type == 'OTHERS') {
      this.odata.Exp_Id = event.Code;
      this.odata.Exp_Custom = 0;

      this.othersVal = event.CodeDescription;

      if (event.CodeDescription == "Custom") {
        this.IsexpCustomSelected = true;
        this.odata.Exp_Custom = this.odata.Exp_Custom;
        if (isNullOrUndefined(this.dealterm) || this.dealterm == "") {
          this.showNotificationMessage('Please Enter The Deal Term.', 2, '', null, '', '', 1000);
        }
      }
      else {
        this.IsexpCustomSelected = false;
        this.odata.Exp_Custom = 0;
        this.getSummaryGraphData(this.odata);
      }
    }
    this.FTEValidation();
  }

  setCompProperties(Properties: any[]) {
    if (!isNullOrUndefined(Properties) && Properties.length > 0) {
      // 1) ActionButtons field
      /*   this.Config.ActionButtons = (Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase()).length > 0 ?
          JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'ActionButtons'.toLowerCase())[0].JSONData.toString()) : []);
        console.log(this.Config.ActionButtons) */

      this.LiteTabs = (Properties.filter(x => x.Name.toLowerCase() === 'P2WLiteTabs'.toLowerCase()).length > 0 ?
        JSON.parse(Properties.filter(x => x.Name.toLowerCase() === 'P2WLiteTabs'.toLowerCase())[0].JSONData.toString()) : []);

      console.log(this.LiteTabs)
      //this.GridProp.ActionBarConfig.ActionBarList = this.Config.ActionButtons;
    }

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.DealInfoForm == undefined) {
        console.log(true);
      } else {
        console.log(false);
      }
    }, 1000);
  }
  //   ngAfterViewInit() {

  //     @ViewChild(FormComponent) DealInfoForm!: FormComponent;









  //     // this.DealInfoForm.changes.subscribe((comps: QueryList<FormComponent>) =>
  //     // {
  //       console.log("++++");
  //     console.log(this.DealInfoForm);
  //       this.DealInfoForm.enableFields('Nearshore', true);
  //           // Now you can access to the child component
  //    // });
  //     // setTimeout(() => {

  //     //   //console.log(this.f.form.get('subscription')); // Works
  //     // }, 500); 
  //  }

  onOffshoreChange(event: any) {
    //  this.DashboardData.Nearshore = "";
    let selitem = event.item;
    if (selitem.Nearshore == null) {
      this.Locval_Nea = "";
    }

    console.log(selitem.Nearshore);
    this.odata.Loc_Nea_Country = selitem.Nearshore;
    this.DashboardData.Nearshore = selitem.Nearshore;
    this.odata.NeaLocationCard = selitem.NearshoreLocationCard;

    //this.odata.Loc_Nea_Sel = event.fieldname;
    if (selitem.NearshoreDesc !== "" && selitem.NearshoreDesc !== null) {
      this.IsLocnearshore = true;
      this.odata.Loc_Nea_Sel = 1;

      this.getSummaryGraphData(this.odata);
    }
    else {
      this.IsLocnearshore = false;
      this.odata.Loc_Nea_Sel = 0;

      this.getSummaryGraphData(this.odata);
    }



  }
  onLocationChange(event: any) {


    // setTimeout(() => {
    //   if (this.DealInfoForm == undefined) {
    //     console.log(true);
    //   } else {
    //     console.log(false);
    //   }
    // }, 1000);

    let selitem = event.item;

    if (selitem.Onshore == null && selitem.LocationOffshore == null) {
      this.IsLocnearshore = false;
      this.odata.Loc_Nea_Sel = 0;
      selitem.NearshoreDesc = null;
      selitem.Nearshore = null;

    }

    if (selitem.Onshore == null || selitem.LocationOffshore == null || selitem.Nearshore == null) {
      this.LocVal = "";
      this.Locval_Nea = "";
      this.Locval_Ons = "";
      this.Locval_Off = "";
      this.PyrnearshoreVal = "";
      this.PyronshoreVal = "";
      this.PyroffshoreVal = "";
      this.MarnearshoreVal = "";
      this.MaroffshoreVal = "";
      this.MaronshoreVal = "";
      this.odata.Mrg_Off_Custom = 0;
      this.odata.Mrg_Ons_Custom = 0;
      this.odata.Mrg_Nea_Custom = 0;
      this.IsOffshoreCustomSelected = false;
      this.IsOnShoreCustomSelected = false;
      this.IsNearshoreCustomSelected = false;

      this.summaryData1 = [];
    }
    if (selitem.Onshore == null) {
      this.Locval_Ons = "";

    }
    if (selitem.LocationOffshore == null) {
      this.Locval_Off = "";
    }
    if (selitem.Nearshore == null) {
      this.Locval_Nea = "";
    }
    // if(selitem.Onshore==undefined|| selitem.LocationOffshore==undefined){

    // }
    //  this.DealInfoForm.enableFields('Nearshore', true);
    console.log(event);
    console.log(this.DashboardData);



    switch (event.fieldname.toUpperCase()) {
      //  this.DealInfoForm.enableFields('Nearshore', true);
      // case 'NEARSHORE':

      // this.odata.Loc_Nea_Country = selitem.Nearshore;

      // //this.odata.Loc_Nea_Sel = event.fieldname;
      // if (selitem.NearshoreDesc !== "" && selitem.NearshoreDesc !== null) {
      //   this.IsLocnearshore = true;
      //   this.odata.Loc_Nea_Sel = 1;

      //   this.getSummaryGraphData(this.odata);
      // }
      // else {
      //   this.IsLocnearshore = false;
      //   this.odata.Loc_Nea_Sel = 0;

      //   this.getSummaryGraphData(this.odata);
      // }
      // break;
      case 'ONSHORE':

        this.odata.Loc_Ons_Country = selitem.Onshore;

        //this.odata.Loc_Ons_Sel = event.fieldname;
        if (selitem.OnshoreDesc !== "" && selitem.OnshoreDesc !== null) {
          this.IsLoconshore = true;
          this.odata.Loc_Ons_Sel = 1;
          this.getSummaryGraphData(this.odata);
        }
        else {
          this.IsLoconshore = false;
          this.odata.Loc_Ons_Sel = 0;
          this.getSummaryGraphData(this.odata);
        }
        break;

      case 'CITY':
        this.odata.City = selitem.City;
        this.odata.Loc_Ons_City = selitem.City;
        break;

      case 'LOCATIONOFFSHORE':
        //  this.odata.Loc_Off_Country = selitem.LocationOffshore;

        //this.odata.Loc_Off_Sel = event.fieldname;
        if (selitem.LocationOffshore !== "" && selitem.LocationOffshore !== null) {
          this.IsLocoffshore = true;
          this.odata.Loc_Off_Sel = 1;
          this.getSummaryGraphData(this.odata);
        }
        else {
          this.IsLocoffshore = false;
          this.odata.Loc_Off_Sel = 0;
          this.getSummaryGraphData(this.odata);
        }
        break;
      
      case 'ONSHORELOCATIONCARD':
        this.odata.OnsLocationCard = selitem.OnshoreLocationCard;
        this.getSummaryGraphData(this.odata);
        break;
      case 'OFFSHORELOCATIONCARD':
        this.odata.OffLocationCard = selitem.OffshoreLocationCard;
        this.getSummaryGraphData(this.odata);
        break;
    }

    // if (this.IsLocoffshore || this.IsLoconshore) {
    //   this.DealInfoForm.enableFields('Nearshore', false);
    // }
    this.EnableInfoIcon();
  }

  EnableInfoIcon() {
    if ((this.IsLocnearshore && this.IsLoconshore) || (this.IsLocnearshore && this.IsLocoffshore) ||
      (this.IsLocoffshore && this.IsLoconshore)
    ) {
      this.infoIconDisabled = true;
    }
    else {
      this.infoIconDisabled = false;
    }
    if (this.IsLocnearshore || this.IsLocoffshore || this.IsLoconshore) {
      this.pyrinfoIconDisabled = true;
    }
    else {
      this.pyrinfoIconDisabled = false;
    }
  }

  CloseInflation() {
    this.getSummaryGraphData(this.odata);
    this.closeModal('inflationpopup');
  }

  CloseCustom() {
    this.getSummaryGraphData(this.odata);
    this.closeModal('LocCustomPopUp');
  }

  onGeneralInfoChange(event: any) {
    console.log(event, this.odata, this.DashboardData)

    switch (event.fieldname) {
      case 'FTE':
        this.overallFTEcount = event.item.FTE;
        if(this.overallFTEcount == null){
          this.overallFTEcount=0;
        }
        this.odata.FTE = this.overallFTEcount
        break;
      case "Discount":
        if(event.item.Discount == null){
          this.odata.discount = 0;
        }
        else{
          this.odata.discount = event.item.Discount;
        }       
        this.getSummaryGraphData(this.odata);
        break;
      case "Negotiation":       
        if(event.item.Negotiation == null){
          this.odata.negotiation = 0;
        }
        else{
          this.odata.negotiation = event.item.Negotiation;
        } 
        this.getSummaryGraphData(this.odata);
        break;
      case "Connectivity":        

        if(event.item.Connectivity == null){
          this.odata.connectivity = 0;
        }
        else{
          this.odata.connectivity = event.item.Connectivity;
        } 
        this.getSummaryGraphData(this.odata);
        break;
      case "IPCurrency":
        this.odata.ipcurrency = event.item.IPCurrencyDesc;
        this.getSummaryGraphData(this.odata);
        break;
      case "OPCurrency":
        this.odata.opcurrency = event.item.OPCurrencyDesc;
        this.getSummaryGraphData(this.odata);
        break;
      case 'DealTerm':
        this.dealterm = event.item.DealTermDesc;
        this.odata.DealTerm = this.dealterm;        

        this.ProdArray = [];
        this.LocArray = [];
        this.PyramidOnsArray = [];
        this.PyramidOffArray = [];
        this.PyramidNearshArray = [];
        this.MarginOnsArray = [];
        this.MarginOffArray = [];
        this.MarginNearArray = [];
        this.TransArray = [];
        this.GovArray = [];
        this.ExpArray = [];
        this.ConArray = [];
        this.locOnsCustom = [];
        this.locOffCustom = [];
        this.locNearCustom = [];
        this.locYears = [];

        (this.Infl_arr).map((e: any) => {
          e[1].value = 0;
          e[2].value = 0;
        });
        (this.prod_arr).map((e: any) => {
          e.value = 0;
          this.odata.Prod_custom_Y1 = 0;
          this.odata.Prod_custom_Y2 = 0;
          this.odata.Prod_custom_Y3 = 0;
          this.odata.Prod_custom_Y4 = 0;
          this.odata.Prod_custom_Y5 = 0;
        });
        (this.loc_arr).map((e: any) => {
          e.value = 0;                  
        });
        (this.pyr_ons_arr).map((e: any) => {
          e.value = 0;
          this.odata.Pyr_Ons_custom_Y1=0;
          this.odata.Pyr_Ons_custom_Y2=0;
          this.odata.Pyr_Ons_custom_Y3=0;
          this.odata.Pyr_Ons_custom_Y4=0;
          this.odata.Pyr_Ons_custom_Y5=0;
        });
        (this.pyr_off_arr).map((e: any) => {
          e.value = 0;
          this.odata.Pyr_Off_custom_Y1=0;
          this.odata.Pyr_Off_custom_Y2=0;
          this.odata.Pyr_Off_custom_Y3=0;
          this.odata.Pyr_Off_custom_Y4=0;
          this.odata.Pyr_Off_custom_Y5=0;
        });
        (this.pyr_nearsh_arr).map((e: any) => {
          e.value = 0;
          this.odata.Pyr_Nearsh_custom_Y1=0;
          this.odata.Pyr_Nearsh_custom_Y2=0;
          this.odata.Pyr_Nearsh_custom_Y3=0;
          this.odata.Pyr_Nearsh_custom_Y4=0;
          this.odata.Pyr_Nearsh_custom_Y5=0;
        });
        (this.mar_ons_arr).map((e: any) => {
          e.value = 0;
          this.odata.Margin_Ons_custom_Y1=0;
          this.odata.Margin_Ons_custom_Y2=0;
          this.odata.Margin_Ons_custom_Y3=0;
          this.odata.Margin_Ons_custom_Y4=0;
          this.odata.Margin_Ons_custom_Y5=0;
        });
        (this.mar_off_arr).map((e: any) => {
          e.value = 0;
          this.odata.Margin_Off_custom_Y1=0;
          this.odata.Margin_Off_custom_Y2=0;
          this.odata.Margin_Off_custom_Y3=0;
          this.odata.Margin_Off_custom_Y4=0;
          this.odata.Margin_Off_custom_Y5=0;
        });
        (this.mar_near_arr).map((e: any) => {
          e.value = 0;
          this.odata.Margin_Nearsh_custom_Y1=0;
          this.odata.Margin_Nearsh_custom_Y2=0;
          this.odata.Margin_Nearsh_custom_Y3=0;
          this.odata.Margin_Nearsh_custom_Y4=0;
          this.odata.Margin_Nearsh_custom_Y5=0;
        });
        (this.Trans_Array).map((e: any) => {
          e.value = 0;
        });
        (this.Gov_Array).map((e: any) => {
          e.value = 0;
          this.odata.Governance_custom_Y1=0;
          this.odata.Governance_custom_Y2=0;
          this.odata.Governance_custom_Y3=0;
          this.odata.Governance_custom_Y4=0;
          this.odata.Governance_custom_Y5=0;
        });
        (this.Exp_Array).map((e: any) => {
          e.value = 0;
          this.odata.Expense_custom_Y1=0;
          this.odata.Expense_custom_Y2=0;
          this.odata.Expense_custom_Y3=0;
          this.odata.Expense_custom_Y4=0;
          this.odata.Expense_custom_Y5=0;
        });
        (this.ConArray).map((e: any) => {
          e.value = 0;
          this.odata.Contigency_custom_Y1=0;
          this.odata.Contigency_custom_Y2=0;
          this.odata.Contigency_custom_Y3=0;
          this.odata.Contigency_custom_Y4=0;
          this.odata.Contigency_custom_Y5=0;
        });
        (this.loc_ons_custom).map((e: any) => {
          e.value = 0;
          this.odata.LocOns_custom_Y1=0;
          this.odata.LocOns_custom_Y2=0;
          this.odata.LocOns_custom_Y3=0;
          this.odata.LocOns_custom_Y4=0;
          this.odata.LocOns_custom_Y5=0;
        });
        (this.loc_off_custom).map((e: any) => {
          e.value = 0;
          this.odata.LocOff_custom_Y1=0;
          this.odata.LocOff_custom_Y2=0;
          this.odata.LocOff_custom_Y3=0;
          this.odata.LocOff_custom_Y4=0;
          this.odata.LocOff_custom_Y5=0;          
        });
        (this.loc_nearsh_custom).map((e: any) => {
          e.value = 0;
          this.odata.LocNearsh_custom_Y1=0;
          this.odata.LocNearsh_custom_Y2=0;
          this.odata.LocNearsh_custom_Y3=0;
          this.odata.LocNearsh_custom_Y4=0;
          this.odata.LocNearsh_custom_Y5=0;         
        });


        switch (this.dealterm) {
          case "1":
            (this.ProdArray).push(this.prod_arr[0]);
            (this.LocArray).push(this.loc_arr[0]);
            (this.MarginOnsArray).push(this.mar_ons_arr[0]);
            (this.MarginOffArray).push(this.mar_off_arr[0]);
            (this.MarginNearArray).push(this.mar_near_arr[0]);
            (this.PyramidOnsArray).push(this.pyr_ons_arr[0]);
            (this.PyramidOffArray).push(this.pyr_off_arr[0]);
            (this.PyramidNearshArray).push(this.pyr_nearsh_arr[0]);
            (this.TransArray).push(this.Trans_Array[0]);
            (this.GovArray).push(this.Gov_Array[0]);
            (this.ExpArray).push(this.Exp_Array[0]);
            (this.ConArray).push(this.con_arr[0]);
            (this.locOnsCustom).push(this.loc_ons_custom[0]);
            (this.locOffCustom).push(this.loc_off_custom[0]);
            (this.locNearCustom).push(this.loc_nearsh_custom[0]);
            (this.locYears).push("Year1");
            break;
          case "2":
            (this.ProdArray).push(this.prod_arr[0], this.prod_arr[1]);
            (this.LocArray).push(this.loc_arr[0], this.loc_arr[1]);
            (this.MarginOnsArray).push(this.mar_ons_arr[0], this.mar_ons_arr[1]);
            (this.MarginOffArray).push(this.mar_off_arr[0], this.mar_off_arr[1]);
            (this.MarginNearArray).push(this.mar_near_arr[0], this.mar_near_arr[1]);
            (this.PyramidOnsArray).push(this.pyr_ons_arr[0], this.pyr_ons_arr[1]);
            (this.PyramidOffArray).push(this.pyr_off_arr[0], this.pyr_off_arr[1]);
            (this.PyramidNearshArray).push(this.pyr_nearsh_arr[0], this.pyr_nearsh_arr[1]);
            (this.TransArray).push(this.Trans_Array[0], this.Trans_Array[1]);
            (this.GovArray).push(this.Gov_Array[0], this.Gov_Array[1]);
            (this.ExpArray).push(this.Exp_Array[0], this.Exp_Array[1]);
            (this.ConArray).push(this.con_arr[0], this.con_arr[1]);
            (this.locOnsCustom).push(this.loc_ons_custom[0], this.loc_ons_custom[1]);
            (this.locOffCustom).push(this.loc_off_custom[0], this.loc_off_custom[1]);
            (this.locNearCustom).push(this.loc_nearsh_custom[0], this.loc_nearsh_custom[1]);
            (this.locYears).push("Year1", "Year2");
            break;
          case "3":
            (this.ProdArray).push(this.prod_arr[0], this.prod_arr[1], this.prod_arr[2]);
            (this.LocArray).push(this.loc_arr[0], this.loc_arr[1], this.loc_arr[2]);
            (this.MarginOnsArray).push(this.mar_ons_arr[0], this.mar_ons_arr[1], this.mar_ons_arr[2]);
            (this.MarginOffArray).push(this.mar_off_arr[0], this.mar_off_arr[1], this.mar_off_arr[2]);
            (this.MarginNearArray).push(this.mar_near_arr[0], this.mar_near_arr[1], this.mar_near_arr[2]);
            (this.PyramidOnsArray).push(this.pyr_ons_arr[0], this.pyr_ons_arr[1], this.pyr_ons_arr[2]);
            (this.PyramidOffArray).push(this.pyr_off_arr[0], this.pyr_off_arr[1], this.pyr_off_arr[2]);
            (this.PyramidNearshArray).push(this.pyr_nearsh_arr[0], this.pyr_nearsh_arr[1], this.pyr_nearsh_arr[2]);
            (this.TransArray).push(this.Trans_Array[0], this.Trans_Array[1], this.Trans_Array[2]);
            (this.GovArray).push(this.Gov_Array[0], this.Gov_Array[1], this.Gov_Array[2]);
            (this.ExpArray).push(this.Exp_Array[0], this.Exp_Array[1], this.Exp_Array[2]);
            (this.ConArray).push(this.con_arr[0], this.con_arr[1], this.con_arr[2]);
            (this.locOnsCustom).push(this.loc_ons_custom[0], this.loc_ons_custom[1], this.loc_ons_custom[2]);
            (this.locOffCustom).push(this.loc_off_custom[0], this.loc_off_custom[1], this.loc_off_custom[2]);
            (this.locNearCustom).push(this.loc_nearsh_custom[0], this.loc_nearsh_custom[1], this.loc_nearsh_custom[2]);
            (this.locYears).push("Year1", "Year2", "Year3");
            break;
          case "4":
            (this.ProdArray).push(this.prod_arr[0], this.prod_arr[1], this.prod_arr[2], this.prod_arr[3]);
            (this.LocArray).push(this.loc_arr[0], this.loc_arr[1], this.loc_arr[2], this.loc_arr[3]);
            (this.MarginOnsArray).push(this.mar_ons_arr[0], this.mar_ons_arr[1], this.mar_ons_arr[2], this.mar_ons_arr[3]);
            (this.MarginOffArray).push(this.mar_off_arr[0], this.mar_off_arr[1], this.mar_off_arr[2], this.mar_off_arr[3]);
            (this.MarginNearArray).push(this.mar_near_arr[0], this.mar_near_arr[1], this.mar_near_arr[2], this.mar_near_arr[3]);
            (this.PyramidOnsArray).push(this.pyr_ons_arr[0], this.pyr_ons_arr[1], this.pyr_ons_arr[2], this.pyr_ons_arr[3]);
            (this.PyramidOffArray).push(this.pyr_off_arr[0], this.pyr_off_arr[1], this.pyr_off_arr[2], this.pyr_off_arr[3]);
            (this.PyramidNearshArray).push(this.pyr_nearsh_arr[0], this.pyr_nearsh_arr[1], this.pyr_nearsh_arr[2], this.pyr_nearsh_arr[3]);
            (this.TransArray).push(this.Trans_Array[0], this.Trans_Array[1], this.Trans_Array[2], this.Trans_Array[3]);
            (this.GovArray).push(this.Gov_Array[0], this.Gov_Array[1], this.Gov_Array[2], this.Gov_Array[3]);
            (this.ExpArray).push(this.Exp_Array[0], this.Exp_Array[1], this.Exp_Array[2], this.Exp_Array[3]);
            (this.ConArray).push(this.con_arr[0], this.con_arr[1], this.con_arr[2], this.con_arr[3]);
            (this.locOnsCustom).push(this.loc_ons_custom[0], this.loc_ons_custom[1], this.loc_ons_custom[2], this.loc_ons_custom[3]);
            (this.locOffCustom).push(this.loc_off_custom[0], this.loc_off_custom[1], this.loc_off_custom[2], this.loc_off_custom[3]);
            (this.locNearCustom).push(this.loc_nearsh_custom[0], this.loc_nearsh_custom[1], this.loc_nearsh_custom[2], this.loc_nearsh_custom[3]);
            (this.locYears).push("Year1", "Year2", "Year3", "Year4");
            break;
          case "5":
            (this.ProdArray).push(this.prod_arr[0], this.prod_arr[1], this.prod_arr[2], this.prod_arr[3], this.prod_arr[4]);
            (this.LocArray).push(this.loc_arr[0], this.loc_arr[1], this.loc_arr[2], this.loc_arr[3], this.loc_arr[4]);
            (this.MarginOnsArray).push(this.mar_ons_arr[0], this.mar_ons_arr[1], this.mar_ons_arr[2], this.mar_ons_arr[3], this.mar_ons_arr[4]);
            (this.MarginOffArray).push(this.mar_off_arr[0], this.mar_off_arr[1], this.mar_off_arr[2], this.mar_off_arr[3], this.mar_off_arr[4]);
            (this.MarginNearArray).push(this.mar_near_arr[0], this.mar_near_arr[1], this.mar_near_arr[2], this.mar_near_arr[3], this.mar_near_arr[4]);
            (this.PyramidOnsArray).push(this.pyr_ons_arr[0], this.pyr_ons_arr[1], this.pyr_ons_arr[2], this.pyr_ons_arr[3], this.pyr_ons_arr[4]);
            (this.PyramidOffArray).push(this.pyr_off_arr[0], this.pyr_off_arr[1], this.pyr_off_arr[2], this.pyr_off_arr[3], this.pyr_off_arr[4]);
            (this.PyramidNearshArray).push(this.pyr_nearsh_arr[0], this.pyr_nearsh_arr[1], this.pyr_nearsh_arr[2], this.pyr_nearsh_arr[3], this.pyr_nearsh_arr[4]);
            (this.TransArray).push(this.Trans_Array[0], this.Trans_Array[1], this.Trans_Array[2], this.Trans_Array[3], this.Trans_Array[4]);
            (this.GovArray).push(this.Gov_Array[0], this.Gov_Array[1], this.Gov_Array[2], this.Gov_Array[3], this.Gov_Array[4]);
            (this.ExpArray).push(this.Exp_Array[0], this.Exp_Array[1], this.Exp_Array[2], this.Exp_Array[3], this.Exp_Array[4]);
            (this.ConArray).push(this.con_arr[0], this.con_arr[1], this.con_arr[2], this.con_arr[3], this.con_arr[4]);
            (this.locOnsCustom).push(this.loc_ons_custom[0], this.loc_ons_custom[1], this.loc_ons_custom[2], this.loc_ons_custom[3], this.loc_ons_custom[4]);
            (this.locOffCustom).push(this.loc_off_custom[0], this.loc_off_custom[1], this.loc_off_custom[2], this.loc_off_custom[3], this.loc_off_custom[4]);
            (this.locNearCustom).push(this.loc_nearsh_custom[0], this.loc_nearsh_custom[1], this.loc_nearsh_custom[2], this.loc_nearsh_custom[3], this.loc_nearsh_custom[4]);
            (this.locYears).push("Year1", "Year2", "Year3", "Year4", "Year5");
            break;
        }

        this.getProductivityDetails();
        // this.getProductivityDetails();

        break;

      case 'Client':
        this.odata.Client = event.item.Client;
        break;

      case 'SBU':
        this.odata.SBU = event.item.SBU
        break;

      case 'Country':
        this.odata.ASISCountry = event.item.CountryDesc;
    }
  }

  getProductivityDetails() {
    let subscribe$: Subscription = this.litehomeService.getProdItems(this.odata.DealTerm).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        this.rangeList = result.Data;
        this.getSummaryGraphData(this.odata);
      }
      this.IsLoading = false;
    },
      err => {
        console.log('Error on getting range details.');
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  setSelectedTab(event: any) {
    console.log(event)
    this.selectedTab = event.selectedTab
    setTimeout(() => {
      if (this.DealInfoForm == undefined) {
        console.log(true);
      } else {
        console.log(false);
      }
    }, 1000);
    switch (this.selectedTab.toUpperCase()) {
      case 'PRODUCTIVITY':
        this.TabName = "Productivitylite";
        this.getRangeSlider(this.TabName);
        this.getProductivityDetails();
        break;
      case 'LOCATIONMIX':

        this.TabName = "LocationMixPer";
        setTimeout(() => {
          if (this.DealInfoForm == undefined) {
            console.log(true);
          } else {
            console.log(false);
          }
        }, 1000);
        this.getRangeSlider(this.TabName);
        break;
      case 'PYRAMID':
        this.TabName = "PyramidPer";
        this.getRangeSlider(this.TabName);
        break;
      case 'MARGIN':
        this.TabName = "MarginPer";
        this.getRangeSlider(this.TabName);
        break;
      case 'OTHERS':
        this.TabName = "OtherExpenses";
        this.getRangeSlider(this.TabName);
        break;
      case 'SUMMARY':
        this.getSummaryDashbaord();
        break;
    }


  }

  onClick(event: any) {

  }

  tabOnClick(event: any) {
    // this.FTEValidation();
    //this.DealInfoForm.enableFields('Nearshore', true);
  }


  /*   onFTEChange(event: any) {
      console.log(event)
  
      switch (event.fieldname) {
        case 'FTEOnshore':
          if (event.item.FTERateDesc == 'Client') {
            this.fteonshoreclient = event.item.FTEOnshore;
          }
          if (event.item.FTERateDesc == 'Vendor') {
            this.fteonshorevendor = event.item.FTEOnshore;
          }
          if (event.item.FTERateDesc == 'Existing CG') {
            this.fteonshorecg = event.item.FTEOnshore;
          }
          break;
        case 'FTEOffshore':
          if (event.item.FTERateDesc == 'Client') {
            this.fteoffshoreclient = event.item.FTEOffshore;
          }
          if (event.item.FTERateDesc == 'Vendor') {
            this.fteoffshorevendor = event.item.FTEOffshore;
          }
          if (event.item.FTERateDesc == 'Existing CG') {
            this.fteoffshorecg = event.item.FTEOffshore;
          }
          break;
      }
      this.FTEValidation();
    } */

  FTEValidation() {
    let total = this.fteonshoreclient + this.fteonshorevendor + this.fteonshorecg + this.fteoffshoreclient + this.fteoffshorevendor + this.fteoffshorecg
    if (total != this.overallFTEcount) {
      this.showNotificationMessage('Client/Vendor/Existing CG FTE does not match with the Overall FTE', 2, 'Error', '', '', 'ok', 5000);
    }
  }
  LocationValidation() {
    if(this.odata.FTE == null || this.odata.FTE == ""){
      this.odata.FTE = 0
    }
    if (this.odata.Off_Country == "" || this.odata.Ons_Country == "" || this.odata.DealTerm == "" ||
    this.odata.Off_Country == undefined || this.odata.Ons_Country == undefined|| this.odata.DealTerm == undefined) {
      this.showNotificationMessage('Please select Onshore/Offshore location/Deal Term/FTE', 2, 'Error', '', '', 'ok', 5000);
    }   
  }

  FTEChange(event: any) {
    console.log(event);
  }

  onChange(event: any) {
    console.log(event, this.odata);
    switch (event.fieldname) {
      case 'LocOnsYear1':
        this.odata.LocOns_custom_Y1 = event.value;
        if (this.loc_ons_custom[0].value + this.loc_off_custom[0].value + this.loc_nearsh_custom[0].value > 100) {
          this.odata.LocOns_custom_Y1 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOnsYear2':
        this.odata.LocOns_custom_Y2 = event.value;
        if (this.loc_ons_custom[1].value + this.loc_off_custom[1].value + this.loc_nearsh_custom[1].value > 100) {
          this.odata.LocOns_custom_Y2 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOnsYear3':
        this.odata.LocOns_custom_Y3 = event.value;
        if (this.loc_ons_custom[2].value + this.loc_off_custom[2].value + this.loc_nearsh_custom[2].value > 100) {
          this.odata.LocOns_custom_Y3 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOnsYear4':
        this.odata.LocOns_custom_Y4 = event.value;
        if (this.loc_ons_custom[3].value + this.loc_off_custom[3].value + this.loc_nearsh_custom[3].value > 100) {
          this.odata.LocOns_custom_Y4 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOnsYear5':
        this.odata.LocOns_custom_Y5 = event.value;
        if (this.loc_ons_custom[4].value + this.loc_off_custom[4].value + this.loc_nearsh_custom[4].value > 100) {
          this.odata.LocOns_custom_Y5 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOffYear1':
        this.odata.LocOff_custom_Y1 = event.value;
        if (this.loc_ons_custom[0].value + this.loc_off_custom[0].value + this.loc_nearsh_custom[0].value > 100) {
          this.odata.LocOff_custom_Y1 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOffYear2':
        this.odata.LocOff_custom_Y2 = event.value;
        if (this.loc_ons_custom[1].value + this.loc_off_custom[1].value + this.loc_nearsh_custom[1].value > 100) {
          this.odata.LocOff_custom_Y2 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOffYear3':
        this.odata.LocOff_custom_Y3 = event.value;
        if (this.loc_ons_custom[2].value + this.loc_off_custom[2].value + this.loc_nearsh_custom[2].value > 100) {
          this.odata.LocOff_custom_Y3 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOffYear4':
        this.odata.LocOff_custom_Y4 = event.value;
        if (this.loc_ons_custom[3].value + this.loc_off_custom[3].value + this.loc_nearsh_custom[3].value > 100) {
          this.odata.LocOff_custom_Y4 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocOffYear5':
        this.odata.LocOff_custom_Y5 = event.value;
        if (this.loc_ons_custom[4].value + this.loc_off_custom[4].value + this.loc_nearsh_custom[4].value > 100) {
          this.odata.LocOff_custom_Y5 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocNearshYear1':
        this.odata.LocNearsh_custom_Y1 = event.value;
        if (this.loc_ons_custom[0].value + this.loc_off_custom[0].value + this.loc_nearsh_custom[0].value > 100) {
          this.odata.LocNearsh_custom_Y1 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocNearshYear2':
        this.odata.LocNearsh_custom_Y2 = event.value;
        if (this.loc_ons_custom[1].value + this.loc_off_custom[1].value + this.loc_nearsh_custom[1].value > 100) {
          this.odata.LocNearsh_custom_Y2 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocNearshYear3':
        this.odata.LocNearsh_custom_Y3 = event.value;
        if (this.loc_ons_custom[2].value + this.loc_off_custom[2].value + this.loc_nearsh_custom[2].value > 100) {
          this.odata.LocNearsh_custom_Y3 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocNearshYear4':
        this.odata.LocNearsh_custom_Y4 = event.value;
        if (this.loc_ons_custom[3].value + this.loc_off_custom[3].value + this.loc_nearsh_custom[3].value > 100) {
          this.odata.LocNearsh_custom_Y4 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'LocNearshYear5':
        this.odata.LocNearsh_custom_Y5 = event.value;
        if (this.loc_ons_custom[4].value + this.loc_off_custom[4].value + this.loc_nearsh_custom[4].value > 100) {
          this.odata.LocNearsh_custom_Y5 = 0;
          event.value = 0;
          this.showNotificationMessage('Custom Location Values should not be more than 100', 2, '', '', '', '', 2000);
        } else {
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'inflationoffshore1':
        this.odata.Off_Inflation_Y1 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationonshore1':
        this.odata.Ons_Inflation_Y1 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationonshore2':
        this.odata.Ons_Inflation_Y2 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationoffshore2':
        this.odata.Off_Inflation_Y2 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationonshore3':
        this.odata.Ons_Inflation_Y3 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationoffshore3':
        this.odata.Off_Inflation_Y3 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationonshore4':
        this.odata.Ons_Inflation_Y4 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationoffshore4':
        this.odata.Off_Inflation_Y4 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationonshore5':
        this.odata.Ons_Inflation_Y5 = event.value;
        this.getSummaryGraphData(this.odata);
        break;
      case 'inflationoffshore5':
        this.odata.Off_Inflation_Y5 = event.value;
        this.getSummaryGraphData(this.odata);
        break;

      case 'fteonshore':
        if (event.itemDesc == 'Client') {
          this.fteonshoreclient = event.value;
        }
        if (event.itemDesc == 'Vendor') {
          this.fteonshorevendor = event.value;
        }
        if (event.itemDesc == 'CG') {
          this.fteonshorecg = event.value;
        }
        // this.FTEValidation();
        break;

      case 'fteoffshore':
        if (event.itemDesc == 'Client') {
          this.fteoffshoreclient = event.value;
        }
        if (event.itemDesc == 'Vendor') {
          this.fteoffshorevendor = event.value;
        }
        if (event.itemDesc == 'CG') {
          this.fteoffshorecg = event.value;
        }

        // this.FTEValidation();
        break;


    }

    switch (event.fieldname.toUpperCase()) {
      case 'FTEONSHORE':

        if (event.itemDesc == 'Vendor') {
          if (event.value == null) {
            this.odata.Ons_Vendor_FTE = 0;
            event.value = 0;
          }
          else {
            this.odata.Ons_Vendor_FTE = event.value;
          }
          //this.odata.Ons_Vendor_FTE = odata.Ons_Vendor_FTE;
          //this.odata.Ons_Vendor_Rate = odata.Ons_Vendor_Rate;

          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'Client') {
          if (event.value == null) {
            this.odata.Ons_Client_FTE = 0;
            event.value = 0;
          }
          else {
            this.odata.Ons_Client_FTE = event.value;
          }
          //this.odata.Ons_Client_FTE = odata.Ons_Client_FTE;
          //this.odata.Ons_Client_Rate = odata.Ons_Client_Rate;
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'CG') {
          if (event.value == null) {
            this.odata.Ons_CG_FTE = 0;
            event.value = 0;
          }
          else {
            this.odata.Ons_CG_FTE = event.value;
          }
          //this.odata.Ons_CG_FTE = odata.Ons_CG_FTE;
          //this.odata.Ons_CG_Rate = odata.Ons_CG_Rate;
          this.getSummaryGraphData(this.odata);
        }


        break;
      case 'RATEONSHORE':
        if (event.itemDesc == 'Vendor') {
          if (event.value == null) {
            this.odata.Ons_Vendor_Rate = 0;
            event.value = 0;
          }
          else {
            this.odata.Ons_Vendor_Rate = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'Client') {
          if (event.value == null) {
            this.odata.Ons_Client_Rate = 0;
            event.value = 0;
          }
          else {
            this.odata.Ons_Client_Rate = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'CG') {
          if (event.value == null) {
            this.odata.Ons_CG_Rate = 0;
            event.value = 0;
          }
          else {
            this.odata.Ons_CG_Rate = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'FTEOFFSHORE':
        if (event.itemDesc == 'Vendor') {

          if (event.value == null) {
            this.odata.Off_Vendor_FTE = 0;
            event.value = 0;
          }
          else {
            this.odata.Off_Vendor_FTE = event.value;
          }
          //this.odata.Off_Vendor_FTE = odata.Off_Vendor_FTE;
          //this.odata.Off_Vendor_Rate = odata.Off_Vendor_Rate;
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'Client') {
          if (event.value == null) {
            this.odata.Off_Client_FTE = 0;
            event.value = 0;
          }
          else {
            this.odata.Off_Client_FTE = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'CG') {
          if (event.value == null) {
            this.odata.Off_CG_FTE = 0;
            event.value = 0;
          }
          else {
            this.odata.Off_CG_FTE = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'RATEOFFSHORE':
        if (event.itemDesc == 'Vendor') {
          if (event.value == null) {
            this.odata.Off_Vendor_Rate = 0;
            event.value = 0;
          }
          else {
            this.odata.Off_Vendor_Rate = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'Client') {
          if (event.value == null) {
            this.odata.Off_Client_Rate = 0;
            event.value = 0;
          }
          else {
            this.odata.Off_Client_Rate = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        if (event.itemDesc == 'CG') {
          if (event.value == null) {
            this.odata.Off_CG_Rate = 0;
            event.value = 0;
          }
          else {
            this.odata.Off_CG_Rate = event.value;
          }
          this.getSummaryGraphData(this.odata);
        }
        break;
      case 'SBU':
        break;
      case 'CLIENT':
        // this.getSummaryGraphData(odata);
        break;
      case 'DEALTERM':
        this.odata.DealTerm = event.item.DealTerm;
        // this.odata.DealTerm = this.odata.DealTerm;
        this.getSummaryGraphData(this.odata);
        break;
      case 'FTE':
        break;
      case 'ONS_LOCATION':
        this.odata.Ons_Country = event.code;
        //this.odata.Ons_Country = event.item.Location;
        this.getSummaryGraphData(this.odata);
        break;
      case 'OFF_LOCATION':
        this.odata.Off_Country = event.code;
        //this.odata.Off_Country = event.item.Offshore;
        this.getSummaryGraphData(this.odata);
        break;
      case 'INFLATIONOFFSHORE':
        this.odata.Off_Inflation = event.value;
        //this.odata.Off_Inflation = this.odata.Off_Inflation ;
        this.getSummaryGraphData(this.odata);
        break;
      case 'INFLATIONONSHORE':
        this.odata.Ons_Inflation = event.value;
        //this.odata.Ons_Inflation = odata.Ons_Inflation;
        this.getSummaryGraphData(this.odata);
        break;
      case 'ONSHORE':
        this.odata.Loc_Ons_Country = event.item.Onshore;
        //this.odata.Loc_Ons_Country =  odata.Loc_Ons_Country;
        this.getSummaryGraphData(this.odata);
        break;
      case 'OFFSHORE':
        this.odata.Loc_Nea_Country = event.item.Offshore;
        this.getSummaryGraphData(this.odata);
        break;
    }

    this.LiteDashboardForm.setDispcols();
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  getSummaryGraphData(odata: any): void {
    this.IsLoading = true;

    let subscribe$: Subscription = this.litehomeService.P2wliteGraphSummary(odata).subscribe(res => {
      console.log("GRAPHS")
      console.log(res);
      if (res !== undefined && res !== null) {
        this.SummaryGraphData = res;
        console.log("SummaryGraphData")
        console.log(this.SummaryGraphData);

        if (this.SummaryGraphData != null || this.SummaryGraphData != undefined || this.SummaryGraphData != '') {

          if (this.rootsummary) {
            this.rootsummary.dispose();
          }

          if (this.rootLocationMix) {
            this.rootLocationMix.dispose();
          }
          if (this.rootblendedRate) {
            this.rootblendedRate.dispose();
          }

          if (this.rootproductivity) {
            this.rootproductivity.dispose();
          }



          this.IsLoading = true;

          (this.SummaryGraphData.Data).map((e: any) => {
            console.log(e.Chart)
            let name = e.Chart;

            switch (name) {
              case 'Price2Win':
                this.p2wVal = e.ChartOutput
                break;
              case 'CM %':
                this.cmVal = e.ChartOutput
                break;
              case 'Savings %':
                this.savingsVal = e.ChartOutput
                break;
              case 'Productivity %':
                this.productivityVal = e.ChartOutput
                break;
              case 'Overall Blended Rate':
                this.overallblendedrateVal = e.ChartOutput
                break;
              case 'CurrentSpend':
                this.currentspendVal = e.ChartOutput
                break;
              case 'RevCostMarginSummary':
                let value = JSON.parse(e.ChartOutput);
                console.log(value);
                this.revCostOverall = value.filter((x: any) => x.Year === 'Overall');
                this.revCostYear1 = value.filter((x: any) => x.Year === 'Year1');
                this.revCostYear2 = value.filter((x: any) => x.Year === 'Year2');
                this.revCostYear3 = value.filter((x: any) => x.Year === 'Year3');
                this.revCostYear4 = value.filter((x: any) => x.Year === 'Year4');
                this.revCostYear5 = value.filter((x: any) => x.Year === 'Year5');

                (this.revCostOverall).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.revCM1 = e.CMPercentage;
                      this.revRev1 = e.Revenue;
                      this.revCost1 = e.Cost;
                      break;
                    case "Offshore":
                      this.revCM2 = e.CMPercentage;
                      this.revRev2 = e.Revenue;
                      this.revCost2 = e.Cost;
                      break;
                    case "Onshore":
                      this.revCM3 = e.CMPercentage;
                      this.revRev3 = e.Revenue;
                      this.revCost3 = e.Cost;
                      break;
                  }
                });

                (this.revCostYear1).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.rev_CM1 = e.CMPercentage;
                      this.rev_Rev1 = e.Revenue;
                      this.rev_Cost1 = e.Cost;
                      break;
                    case "Offshore":
                      this.rev_CM2 = e.CMPercentage;
                      this.rev_Rev2 = e.Revenue;
                      this.rev_Cost2 = e.Cost;
                      break;
                    case "Onshore":
                      this.rev_CM3 = e.CMPercentage;
                      this.rev_Rev3 = e.Revenue;
                      this.rev_Cost3 = e.Cost;
                      break;
                  }
                });
                (this.revCostYear2).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.rev_CM1_year2 = e.CMPercentage;
                      this.rev_Rev1_year2 = e.Revenue;
                      this.rev_Cost1_year2 = e.Cost;
                      break;
                    case "Offshore":
                      this.rev_CM2_year2 = e.CMPercentage;
                      this.rev_Rev2_year2 = e.Revenue;
                      this.rev_Cost2_year2 = e.Cost;
                      break;
                    case "Onshore":
                      this.rev_CM3_year2 = e.CMPercentage;
                      this.rev_Rev3_year2 = e.Revenue;
                      this.rev_Cost3_year2 = e.Cost;
                      break;
                  }
                });
                (this.revCostYear3).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.rev_CM1_year3 = e.CMPercentage;
                      this.rev_Rev1_year3 = e.Revenue;
                      this.rev_Cost1_year3 = e.Cost;
                      break;
                    case "Offshore":
                      this.rev_CM2_year3 = e.CMPercentage;
                      this.rev_Rev2_year3 = e.Revenue;
                      this.rev_Cost2_year3 = e.Cost;
                      break;
                    case "Onshore":
                      this.rev_CM3_year3 = e.CMPercentage;
                      this.rev_Rev3_year3 = e.Revenue;
                      this.rev_Cost3_year3 = e.Cost;
                      break;
                  }
                });
                (this.revCostYear4).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.rev_CM1_year4 = e.CMPercentage;
                      this.rev_Rev1_year4 = e.Revenue;
                      this.rev_Cost1_year4 = e.Cost;
                      break;
                    case "Offshore":
                      this.rev_CM2_year4 = e.CMPercentage;
                      this.rev_Rev2_year4 = e.Revenue;
                      this.rev_Cost2_year4 = e.Cost;
                      break;
                    case "Onshore":
                      this.rev_CM3_year4 = e.CMPercentage;
                      this.rev_Rev3_year4 = e.Revenue;
                      this.rev_Cost3_year4 = e.Cost;
                      break;
                  }
                });
                (this.revCostYear5).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.rev_CM1_year5 = e.CMPercentage;
                      this.rev_Rev1_year5 = e.Revenue;
                      this.rev_Cost1_year5 = e.Cost;
                      break;
                    case "Offshore":
                      this.rev_CM2_year5 = e.CMPercentage;
                      this.rev_Rev2_year5 = e.Revenue;
                      this.rev_Cost2_year5 = e.Cost;
                      break;
                    case "Onshore":
                      this.rev_CM3_year5 = e.CMPercentage;
                      this.rev_Rev3_year5 = e.Revenue;
                      this.rev_Cost3_year5 = e.Cost;
                      break;
                  }
                });

                break;
              case 'BlendedRateSummary':
                let val = JSON.parse(e.ChartOutput);
                this.blendedOverall = val.filter((x: any) => x.Year === 'Overall');
                this.blendedYear1 = val.filter((x: any) => x.Year === 'Year1');
                this.blendedYear2 = val.filter((x: any) => x.Year === 'Year2');
                this.blendedYear3 = val.filter((x: any) => x.Year === 'Year3');
                this.blendedYear4 = val.filter((x: any) => x.Year === 'Year4');
                this.blendedYear5 = val.filter((x: any) => x.Year === 'Year5');
                (this.blendedOverall).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.blendedOverall1 = e.BlendedRate
                      break;
                    case "Offshore":
                      this.blendedOverall2 = e.BlendedRate
                      break;
                    case "Onshore":
                      this.blendedOverall3 = e.BlendedRate
                      break;
                    case "Overall":
                      this.blendedOver = e.OverallBlendedRate
                      break;
                  }
                });


                (this.blendedYear1).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.blendedyear1 = e.BlendedRate
                      break;
                    case "Offshore":
                      this.blendedyear2 = e.BlendedRate
                      break;
                    case "Onshore":
                      this.blendedyear3 = e.BlendedRate
                      break;
                    case "Overall":
                      this.blendedyear = e.OverallBlendedRate
                      break;
                  }
                });
                (this.blendedYear2).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.blendedyear1_Y2 = e.BlendedRate
                      break;
                    case "Offshore":
                      this.blendedyear2_Y2 = e.BlendedRate
                      break;
                    case "Onshore":
                      this.blendedyear3_Y2 = e.BlendedRate
                      break;
                    case "Overall":
                      this.blendedyear_2 = e.OverallBlendedRate
                      break;
                  }
                });
                (this.blendedYear3).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.blendedyear1_Y3 = e.BlendedRate
                      break;
                    case "Offshore":
                      this.blendedyear2_Y3 = e.BlendedRate
                      break;
                    case "Onshore":
                      this.blendedyear3_Y3 = e.BlendedRate
                      break;
                    case "Overall":
                      this.blendedyear_3 = e.OverallBlendedRate
                      break;
                  }
                });

                (this.blendedYear4).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.blendedyear1_Y4 = e.BlendedRate
                      break;
                    case "Offshore":
                      this.blendedyear2_Y4 = e.BlendedRate
                      break;
                    case "Onshore":
                      this.blendedyear3_Y4 = e.BlendedRate
                      break;
                    case "Overall":
                      this.blendedyear_4 = e.OverallBlendedRate
                      break;
                  }
                });
                (this.blendedYear5).map((e: any) => {
                  switch (e.Shore) {
                    case "Nearshore":
                      this.blendedyear1_Y5 = e.BlendedRate
                      break;
                    case "Offshore":
                      this.blendedyear2_Y5 = e.BlendedRate
                      break;
                    case "Onshore":
                      this.blendedyear3_Y5 = e.BlendedRate
                      break;
                    case "Overall":
                      this.blendedyear_5 = e.OverallBlendedRate
                      break;
                  }
                });
                break;
            }
          });


          this.summaryData = JSON.parse(res.Data[0].ChartOutput);
          this.productivitygraphData = JSON.parse(res.Data[1].ChartOutput);
          this.locationMixData = JSON.parse(res.Data[2].ChartOutput);
          this.blendedData = JSON.parse(res.Data[3].ChartOutput);


          if (this.summaryData != null || this.summaryData != undefined) {

            this.getSummaryGraph(this.summaryData);

          }


          if (this.productivitygraphData != null || this.productivitygraphData != undefined) {

            this.getProductivity(this.productivitygraphData);

          }
          if (this.locationMixData != null || this.locationMixData != undefined) {

            this.getLocationMix(this.locationMixData);

          }
          if (this.blendedData != null || this.blendedData != undefined) {

            this.getBlendedRate(this.blendedData);

          }




          this.IsLoading = false;

          //let headerval=  this.SummaryGraphData.filter((x:any) => x.GroupName === 'LiteBanner');

        }
        // useEffect(() => {


        //   return () => {  this.root.dispose();  };
        //   })
      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getReportTemplate.');
      });

    this.subscriptionList$.push(subscribe$);

  }

  getSummaryGraph(graphData: any) {
    if (this.rootsummary) {
      this.rootsummary.dispose();
    }


    setTimeout(() => {
      let root = am5.Root.new("SummaryChart");

      /* Imports */
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      // root.setThemes([
      //   am5themes_Animated.new(root)
      // ]);

      root.setThemes([
        am5themes_Responsive.new(root)
      ]);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,

        layout: root.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      let data = graphData;
      // let data = [
      //   {
      //     "Id": 0,
      //     "Current Spend": 29235,
      //     "Projected Spend": 12400,
      //     "Savings": 12343,
      //     "Year": "Year1"

      //   },
      //   {
      //     "Id": 0,
      //     "Current Spend": 2942,
      //     "Projected Spend": 14566,
      //     "Savings": 35433,
      //     "Year": "Year2"
      //   },
      //   {
      //     "Id": 0,
      //     "Current Spend": 2290,
      //     "Projected Spend": 1890,
      //     "Savings": 8989,
      //     "Year": "Year3"
      //   },
      //   {
      //     "Id": 0,
      //     "Current Spend": 5566,
      //     "Projected Spend": 8787,
      //     "Savings": 3535,
      //     "Year": "Year4"
      //   },
      //   {
      //     "Id": 0,
      //     "Current Spend": 5868,
      //     "Projected Spend": 1243,
      //     "Savings": 7979,
      //     "Year": "Year5"
      //   }

      // ]








      // graphData;

      // [{
      //   "year": "2021",
      //   "europe": 2.5,
      //   "namerica": 2.5,
      //   "asia": 2.1,
      //   "lamerica": 1,
      //   "meast": 0.8,
      //   "africa": 0.4
      // }, {
      //   "year": "2022",
      //   "europe": 2.6,
      //   "namerica": 2.7,
      //   "asia": 2.2,
      //   "lamerica": 0.5,
      //   "meast": 0.4,
      //   "africa": 0.3
      // }, {
      //   "year": "2023",
      //   "europe": 2.8,
      //   "namerica": 2.9,
      //   "asia": 2.4,
      //   "lamerica": 0.3,
      //   "meast": 0.9,
      //   "africa": 0.5
      // }]
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "Year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xAxis.data.setAll(data);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          min: 0,
          "extraMax": 0
        })
      );
      // let xRenderer = am5xy.AxisRendererX.new(root, {
      //   minGridDistance: 40
      // });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      }));


      let series3 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Savings %",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Savings %",
          categoryXField: "Year",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series3.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });

      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Savings",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Savings",
          categoryXField: "Year",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series2.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });


      series2.data.setAll(data);
      series3.data.setAll(data);

      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            stroke: series2.get("stroke"),
            radius: 5,
            fill: root.interfaceColors.get("background")
          })
        });
      });


      series3 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Savings %",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Savings %",
          categoryXField: "Year",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series3.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            stroke: series2.get("stroke"),
            radius: 5,
            fill: root.interfaceColors.get("background")
          })
        });
      });

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "Year"
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });
        series.data.setAll(data);







        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }

      makeSeries("Current Spend", "Current Spend");
      makeSeries("Projected Spend", "Projected Spend");




      // Make stuff animate on load
      chart.set("cursor", am5xy.XYCursor.new(root, {}));
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      this.rootsummary = root;


      this.summaryDataExporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: graphData,
        pdfOptions: {
          includeData: true,
          addURL: false,
        },
        xlsxOptions: {

        }

      });

      console.log(this.summaryDataExporting, "$$$")

    }, 1000);



  }

  getProductivity(graphData: any) {



    console.log("graph data");
    console.log(graphData);

    if (this.rootproductivity) {
      this.rootproductivity.dispose();
    }

    setTimeout(() => {

      // Create root and chart
      //   let root: any = null;
      // root = this.rootproductivity;
      this.rootproductivity = am5.Root.new("chartdiv");

      this.rootproductivity.setThemes([
        am5themes_Responsive.new(this.rootproductivity)
      ]);
      let chart: any = null;
      chart = this.rootproductivity.container.children.push(
        am5xy.XYChart.new(this.rootproductivity, {
          panY: false,
          layout: this.rootproductivity.verticalLayout
        })
      );

      // Define data

      let data: any = null;
      data = graphData;
      //   var data = [
      //     {
      //        "Year":"Overall",
      //        "Productivity":30
      //     },
      //     {
      //        "Year":"Year1",
      //        "Productivity":6
      //     },
      //     {
      //        "Year":"Year2",
      //        "Productivity":9
      //     },
      //     {
      //        "Year":"Year3",
      //        "Productivity":9
      //     }
      //  ]

      // Craete Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.rootproductivity, {
          renderer: am5xy.AxisRendererY.new(this.rootproductivity, {}),

        })
      );
      let xRenderer = am5xy.AxisRendererX.new(this.rootproductivity, {
        minGridDistance: 30
      });
      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(this.rootproductivity, {
          renderer: xRenderer,
          categoryField: "Year",

        })
      );
      xAxis.data.setAll(data);


      let series2 = chart.series.push(
        am5xy.LineSeries.new(this.rootproductivity, {
          name: "Productivity",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "Productivity",
          categoryXField: "Year",
          tooltip: am5.Tooltip.new(this.rootproductivity, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series2.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });


      series2.data.setAll(data);
      let root = this.rootproductivity
      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            stroke: series2.get("stroke"),
            radius: 5,
            fill: root.interfaceColors.get("background")
          })
        });
      });

      chart.set("cursor", am5xy.XYCursor.new(this.rootproductivity, {}));
      series2.appear();
      //   this.rootproductivity = root;

      this.productivityDataExporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: graphData,
        pdfOptions: {
          includeData: true,
          addURL: false,
        },
        xlsxOptions: {

        }
      });

    }, 1000);



  }


  getBlendedRate(graphData: any) {
    if (this.rootblendedRate) {
      this.rootblendedRate.dispose();
    }

    setTimeout(() => {

      // Create root and chart
      this.rootblendedRate = am5.Root.new("chartblendedRate");

      this.rootblendedRate.setThemes([
        am5themes_Responsive.new(this.rootblendedRate)
      ]);

      var chart = this.rootblendedRate.container.children.push(
        am5xy.XYChart.new(this.rootblendedRate, {
          panY: false,
          layout: this.rootblendedRate.verticalLayout
        })
      );

      // Define data

      var data = graphData;
      // var data = [{
      //   "year": "Year1",
      //   "BlendedRate": 5,

      // }, {
      //   "year": "Year2",
      //   "BlendedRate": 2.6,

      // }, {
      //   "year": "Year3",
      //   "BlendedRate": 4.8,

      // },
      // {
      //   "year": "Year4",
      //   "BlendedRate": 1,

      // },
      // {
      //   "year": "Year5",
      //   "BlendedRate": 8,

      // },
      // {
      //   "year": "Overall",
      //   "BlendedRate": 9,

      // }
      // ]

      // Craete Y-axis
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.rootblendedRate, {
          renderer: am5xy.AxisRendererY.new(this.rootblendedRate, {})
        })
      );
      let xRenderer = am5xy.AxisRendererX.new(this.rootblendedRate, {
        minGridDistance: 30
      });
      // Create X-Axis
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(this.rootblendedRate, {
          renderer: xRenderer,
          categoryField: "Year",
          tooltip: am5.Tooltip.new(this.rootblendedRate, {})
        })
      );
      xAxis.data.setAll(data);


      let series2 = chart.series.push(
        am5xy.LineSeries.new(this.rootblendedRate, {
          name: "BlendedRate",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "BlendedRate",
          categoryXField: "Year",
          tooltip: am5.Tooltip.new(this.rootblendedRate, {
            labelText: "{name} in {categoryX}: {valueY} "
          })

        })
      );

      series2.strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });


      series2.data.setAll(data);
      let rootblended = this.rootblendedRate
      series2.bullets.push(function () {
        return am5.Bullet.new(rootblended, {
          sprite: am5.Circle.new(rootblended, {
            strokeWidth: 3,
            stroke: series2.get("stroke"),
            radius: 5,
            fill: rootblended.interfaceColors.get("background")
          })
        });
      });

      chart.set("cursor", am5xy.XYCursor.new(this.rootblendedRate, {}));
      series2.appear();

      let root = this.rootblendedRate;

      this.blendedRateDataExporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: graphData,
        pdfOptions: {
          includeData: true,
          addURL: false,
        },
        xlsxOptions: {

        }
      });
      // Create series
      // function createSeries(name: any, field: any) {
      //   var series = chart.series.push(
      //     am5xy.LineSeries.new(this.rootblendedRate, {
      //       name: name,
      //       xAxis: xAxis,
      //       yAxis: yAxis,
      //       valueYField: field,
      //       categoryXField: "Year",
      //       stacked: true
      //     })
      //   );

      //   series.bullets.push(function () {
      //     return am5.Bullet.new(this.rootblendedRate, {
      //       sprite: am5.Circle.new(root, {
      //         radius: 5,
      //         fill: series.get("fill")
      //       })
      //     });
      //   });
      //   var tooltip = am5.Tooltip.new(root, {
      //     pointerOrientation: "horizontal"
      //   });
      //   tooltip.label.set("text", "{valueY}");
      //   series.set("tooltip", tooltip);



      //   series.data.setAll(data);
      // }

      // createSeries("Blended Rate", "Blended Rate");
      //  this.rootblendedRate = root;

      /**
       * ========================================================
       * Enabling responsive features
       * ========================================================
       */

    }, 1000);

  }


  getLocationMix(graphData: any) {
    if (this.rootLocationMix) {
      this.rootLocationMix.dispose();
    }


    setTimeout(() => {
      //   let root = am5.Root.new("locationMix");
      this.rootLocationMix = am5.Root.new("locationMix");
      /* Imports */
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      // root.setThemes([
      //   am5themes_Animated.new(root)
      // ]);

      const myTheme = am5.Theme.new(this.rootLocationMix);

      myTheme.rule("ColorSet").set("colors", [        
        am5.color(0x8080ff),
        am5.color(0x00e6e6),
        am5.color(0x008ae6),        
        am5.color(0x86a873),
        am5.color(0xbb9f06)
      ]);

      this.rootLocationMix.setThemes([
        am5themes_Animated.new(this.rootLocationMix),
        myTheme
      ]);

     /*  this.rootLocationMix.setThemes([
        am5themes_Animated.new(this.rootLocationMix)
      ]); */

      
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      let chart = this.rootLocationMix.container.children.push(am5xy.XYChart.new(this.rootLocationMix, {
        panX: false,
        panY: false,

        layout: this.rootLocationMix.verticalLayout
      }));

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set("scrollbarX", am5.Scrollbar.new(root, {
      //   orientation: "horizontal"
      // }));
      var data
        = graphData;
      // let data = [
      //   {
      //     "Id": 0,
      //     "Onshore": 29235,
      //     "Offshore": 12400,
      //     "Nearshore": 12343,
      //     "Year": "Year1"

      //   },
      //   {
      //     "Id": 0,
      //     "Onshore": 2942,
      //     "Offshore": 14566,
      //     "Nearshore": 35433,
      //     "Year": "Year2"
      //   },
      //   {
      //     "Id": 0,
      //     "Onshore": 2290,
      //     "Offshore": 1890,
      //     "Nearshore": 8989,
      //     "Year": "Year3"
      //   },
      //   {
      //     "Id": 0,
      //     "Onshore": 5566,
      //     "Offshore": 8787,
      //     "Nearshore": 3535,
      //     "Year": "Year4"
      //   },
      //   {
      //     "Id": 0,
      //     "Onshore": 5868,
      //     "Offshore": 1243,
      //     "Nearshore": 7979,
      //     "Year": "Year5"
      //   }

      // ]


      let xRenderer = am5xy.AxisRendererX.new(this.rootLocationMix, {
        minGridDistance: 20
      });
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.rootLocationMix, {
        categoryField: "Year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.rootLocationMix, {})
      }));

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.rootLocationMix, {
        min: 0,
        max: 100,
        renderer: am5xy.AxisRendererY.new(this.rootLocationMix, {})
      }));


      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.children.push(am5.Legend.new(this.rootLocationMix, {
        centerX: am5.p50,
        x: am5.p50
      }));

      let rootlocation: any = this.rootLocationMix;
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function makeSeries(name: any, fieldName: any) {
        let series = chart.series.push(am5xy.ColumnSeries.new(rootlocation, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "Year"
        }));

        series.columns.template.setAll({
          tooltipText: "{name}, {categoryX}: {valueY}",
          tooltipY: am5.percent(100)
        });
        series.data.setAll(data);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(rootlocation, {
            sprite: am5.Label.new(rootlocation, {
              text: "{valueY}",
              fill: rootlocation.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              populateText: true
            })
          });
        });

        legend.data.push(series);
      }

      makeSeries("Onshore", "Onshore");
      makeSeries("Offshore", "Offshore");
      makeSeries("Nearshore", "Nearshore");



      // Make stuff animate on load

      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      //  this.rootLocationMix = root;

      let root = this.rootLocationMix

      this.locationMixDataExporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: graphData,
        pdfOptions: {
          includeData: true,
          addURL: false,
        },
        xlsxOptions: {

        }
      });
    }, 1000);
  }




  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }



  ngOnDestroy() {


    // Clean up chart when the component is removed
    //  this.browserOnly(() => {
    if (this.rootsummary) {
      this.rootsummary.dispose();
    }
    if (this.rootproductivity) {
      this.rootproductivity.dispose();
    }
    if (this.rootblendedRate) {
      this.rootblendedRate.dispose();
    }
    if (this.rootLocationMix) {
      this.rootLocationMix.dispose();
    }
    //  });
  }

  CustomValueChange(event: any) {
    console.log(event);

    switch (event.fieldname) {
      case "Prod_Year1":
        this.odata.Prod_custom_Y1 = event.value;
        break;
      case "Prod_Year2":
        this.odata.Prod_custom_Y2 = event.value;
        break;
      case "Prod_Year3":
        this.odata.Prod_custom_Y3 = event.value;
        break;
      case "Prod_Year4":
        this.odata.Prod_custom_Y4 = event.value;
        break;
      case "Prod_Year5":
        this.odata.Prod_custom_Y5 = event.value;
        break;
      case "Loc_Year1":
        this.odata.Loc_custom_Y1 = event.value;
        break;
      case "Loc_Year2":
        this.odata.Loc_custom_Y2 = event.value;
        break;
      case "Loc_Year3":
        this.odata.Loc_custom_Y3 = event.value;
        break;
      case "Loc_Year4":
        this.odata.Loc_custom_Y4 = event.value;
        break;
      case "Loc_Year5":
        this.odata.Loc_custom_Y5 = event.value;
        break;
      case "Margin_Ons_Year1":
        this.odata.Margin_Ons_custom_Y1 = event.value;
        break;
      case "Margin_Ons_Year2":
        this.odata.Margin_Ons_custom_Y2 = event.value;
        break;
      case "Margin_Ons_Year3":
        this.odata.Margin_Ons_custom_Y3 = event.value;
        break;
      case "Margin_Ons_Year4":
        this.odata.Margin_Ons_custom_Y4 = event.value;
        break;
      case "Margin_Ons_Year5":
        this.odata.Margin_Ons_custom_Y5 = event.value;
        break;
      case "Margin_Off_Year1":
        this.odata.Margin_Off_custom_Y1 = event.value;
        break;
      case "Margin_Off_Year2":
        this.odata.Margin_Off_custom_Y2 = event.value;
        break;
      case "Margin_Off_Year3":
        this.odata.Margin_Off_custom_Y3 = event.value;
        break;
      case "Margin_Off_Year4":
        this.odata.Margin_Off_custom_Y4 = event.value;
        break;
      case "Margin_Off_Year5":
        this.odata.Margin_Off_custom_Y5 = event.value;
        break;
      case "Margin_Near_Year1":
        this.odata.Margin_Nearsh_custom_Y1 = event.value;
        break;
      case "Margin_Near_Year2":
        this.odata.Margin_Nearsh_custom_Y2 = event.value;
        break;
      case "Margin_Near_Year3":
        this.odata.Margin_Nearsh_custom_Y3 = event.value;
        break;
      case "Margin_Near_Year4":
        this.odata.Margin_Nearsh_custom_Y4 = event.value;
        break;
      case "Margin_Near_Year5":
        this.odata.Margin_Nearsh_custom_Y5 = event.value;
        break;
      case "Pyramid_Ons_Year1":
        this.odata.Pyr_Ons_custom_Y1 = event.value;
        break;
      case "Pyramid_Ons_Year2":
        this.odata.Pyr_Ons_custom_Y2 = event.value;
        break;
      case "Pyramid_Ons_Year3":
        this.odata.Pyr_Ons_custom_Y3 = event.value;
        break;
      case "Pyramid_Ons_Year4":
        this.odata.Pyr_Ons_custom_Y4 = event.value;
        break;
      case "Pyramid_Ons_Year5":
        this.odata.Pyr_Ons_custom_Y5 = event.value;
        break;
      case "Pyramid_Off_Year1":
        this.odata.Pyr_Off_custom_Y1 = event.value;
        break;
      case "Pyramid_Off_Year2":
        this.odata.Pyr_Off_custom_Y2 = event.value;
        break;
      case "Pyramid_Off_Year3":
        this.odata.Pyr_Off_custom_Y3 = event.value;
        break;
      case "Pyramid_Off_Year4":
        this.odata.Pyr_Off_custom_Y4 = event.value;
        break;
      case "Pyramid_Off_Year5":
        this.odata.Pyr_Off_custom_Y5 = event.value;
        break;
      case "Pyramid_Nearsh_Year1":
        this.odata.Pyr_Nearsh_custom_Y1 = event.value;
        break;
      case "Pyramid_Nearsh_Year2":
        this.odata.Pyr_Nearsh_custom_Y2 = event.value;
        break;
      case "Pyramid_Nearsh_Year3":
        this.odata.Pyr_Nearsh_custom_Y3 = event.value;
        break;
      case "Pyramid_Nearsh_Year4":
        this.odata.Pyr_Nearsh_custom_Y4 = event.value;
        break;
      case "Pyramid_Nearsh_Year5":
        this.odata.Pyr_Nearsh_custom_Y5 = event.value;
        break;
      case "Transition_Year1":
        this.odata.Trn_Custom = event.value;
        break;
      case "Governance_Year1":
        this.odata.Governance_custom_Y1 = event.value;
        break;
      case "Governance_Year2":
        this.odata.Governance_custom_Y2 = event.value;
        break;
      case "Governance_Year3":
        this.odata.Governance_custom_Y3 = event.value;
        break;
      case "Governance_Year4":
        this.odata.Governance_custom_Y4 = event.value;
        break;
      case "Governance_Year5":
        this.odata.Governance_custom_Y5 = event.value;
        break;
      case "Expense_Year1":
        this.odata.Expense_custom_Y1 = event.value;
        break;
      case "Expense_Year2":
        this.odata.Expense_custom_Y2 = event.value;
        break;
      case "Expense_Year3":
        this.odata.Expense_custom_Y3 = event.value;
        break;
      case "Expense_Year4":
        this.odata.Expense_custom_Y4 = event.value;
        break;
      case "Expense_Year5":
        this.odata.Expense_custom_Y5 = event.value;
        break;
      case "Con_Year1":
        this.odata.Contigency_custom_Y1 = event.value;
        break;
      case "Con_Year2":
        this.odata.Contigency_custom_Y2 = event.value;
        break;
      case "Con_Year3":
        this.odata.Contigency_custom_Y3 = event.value;
        break;
      case "Con_Year4":
        this.odata.Contigency_custom_Y4 = event.value;
        break;
      case "Con_Year5":
        this.odata.Contigency_custom_Y5 = event.value;
        break;
    }
    this.getSummaryGraphData(this.odata);
  }

}