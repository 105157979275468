import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environments/environment';


@Injectable()
export class LotSolutionService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private CommAPIUrl: string;
  private HomeURL: string;
  private DealURL: string;
  private LotURL: string;

  constructor(private _http: HttpClient) {
    this.CommAPIUrl = environment.baseURL + '/Common/';
    this.HomeURL = environment.apiURL.HomeURL;
    this.DealURL = environment.apiURL.DealURL;
    this.LotURL = environment.apiURL.LotURL;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }


  GetModelConfigurationDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetModelConfigurationDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetEffortModellingDetails(dealHeaderId: number, dealLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetEffortModellingDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetAmDealProgressBarPer(dealHeaderId: number, Approach: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('Module', 'AM');
    Params = Params.append('Approach', Approach);
    let response = this._http.get<ProcessResponse<any>>(this.DealURL + 'GetAmDealProgressBarPer',
      { headers: this.headers, params: Params });
    return response;
  }

  insertorUpdateModelConfigDeals(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.DealURL + 'InsertorUpdateAmDealsModelConfiguration', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetEffortModelling(dealHeaderId: number, dealLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetEffortModelling',
      { headers: this.headers, params: Params });
    return response;
  }

  GetLSDeliveryLocationModelling(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetLSDeliveryLocationModelling',
      { headers: this.headers, params: Params });
    return response;
  }



  InsertUpdateLSDeliveryLocationModelling(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateLSDeliveryLocationModelling', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  //staffing model changes starts here
  GetStaffingModellingFirstGrid(dealHeaderId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', 0);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetStaffingModellingFirstGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetStaffingModellingSecondGrid(dealHeaderId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetStaffingModellingSecondGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetStaffingModellingThirdGrid(dealHeaderId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetStaffingModellingThirdGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetStaffingModellingFourthGrid(dealHeaderId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetStaffingModellingFourthGrid',
      { headers: this.headers, params: Params });
    return response;
  }

  GetSpecificPyramidPopUp(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, lsDeliveryLocationModellingId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('LSDeliveryLocationModellingId', lsDeliveryLocationModellingId);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetSpecificPyramidPopUp',
      { headers: this.headers, params: Params });
    return response;
  }



  GetEnhancementPyramid(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number, Model: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    Params = Params.append('Model', Model);
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetEnhancementPyramid',
      { headers: this.headers, params: Params });
    return response;
  }


  InsertUpdateEnhancementPyramid(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateEnhancementPyramid', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  InsertUpdateDealGovernance(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateDealGovernance', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertUpdateGradeGovernance(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateGradeGovernance', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertUpdateResourcePyramid(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateResourcePyramid', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  UpdateSpecificPyramidPopUp(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'UpdateSpecificPyramidPopUp', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  //staffing model changes ends here




  ////////LOCATION MIX API////////

  GetLocationInfluencers(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetLocationInfluencers',
      { headers: this.headers, params: Params });
    return response;

  }


  InsertUpdateLocationInfluencers(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateLocationInfluencers', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  /////////////Grid 1/////////////////////

  GetOnshoreLocationEffortPer(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetOnshoreLocationEffortPer',
      { headers: this.headers, params: Params });
    return response;

  }

  InsertUpdateOnshoreLocationEffortPer(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateOnshoreLocationEffortPer', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }
  ////////////////Grid2////////////////////////


  GetEffortsDistributionPer(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetEffortsDistributionPer',
      { headers: this.headers, params: Params });
    return response;

  }

  InsertUpdateEffortsDistributionPer(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateEffortsDistributionPer', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }


  //#region Productivity  Impact
  getProductivityImpactDetails(serviceDomain: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', serviceDomain.DealHeaderId);
    Params = Params.append('dealLineItemId', serviceDomain.DealLineItemId);
    Params = Params.append('dealSubLineItemId', serviceDomain.DealSubLineItemId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetProductivityImpactDetails',
      { headers: this.headers, params: Params });
    return response;

  }

  getProductivityGuideLinesDetails(serviceDomain: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('dealHeaderId', serviceDomain.DealHeaderId);
    Params = Params.append('dealLineItemId', serviceDomain.DealLineItemId);
    Params = Params.append('dealSubLineItemId', serviceDomain.DealSubLineItemId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetProductivityGuideLinesDetails',
      { headers: this.headers, params: Params });
    return response;

  }

  insertUpdateProductivityDetails(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    //Params = Params.append('DealHeaderId', 0);

    return this._http.post<ProcessResponse<any>>(this.LotURL + 'InsertUpdateProductivityDetails', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getBaselinedDetails(dealHeaderId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);

    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetBaselinedDetails',
      { headers: this.headers, params: Params });
    return response;
  }

  GetServiceDomainDetails(dealHeaderId: number, dealLineItemId: number, dealsubLineItemId: number): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('DealHeaderId', dealHeaderId);
    Params = Params.append('DealLineItemId', dealLineItemId);
    Params = Params.append('DealSubLineItemId', dealsubLineItemId);
    //http://localhost:7000/LotSolution/GetEffortModellingDetails?DealHeaderId=882&DealLineItemId=0
    let response = this._http.get<ProcessResponse<any>>(this.LotURL + 'GetServiceDomainDetails',
      { headers: this.headers, params: Params });
    return response;
  }
  //#endregion

}